
.featured12 {
    z-index: 0 !important;
    margin-bottom: 1rem;
    /* margin-top: -190px; */
  }
  
  .featuredItem12 {
    position: relative;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
    width: 290px;
    flex: 1;
    cursor: pointer;
    box-shadow: 0px 0px 28px 0px rgba(211, 211, 211, 0.5);
  }
  
  .featuredItem12:hover {
    transition: all ease-in 0.5s;
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.4);
  }
  
  .featuredImg12 {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .row13 {
    --mdb-gutter-x: 1.5rem;
    --mdb-gutter-y: 0;
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    margin-top: calc(var(--mdb-gutter-y)*-1);
    margin-right: calc(var(--mdb-gutter-x)*-0.5);
    margin-left: calc(var(--mdb-gutter-x)*-0.5);
}
  .featuredTitles12 {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    
    padding: 5px;
  }
  
  .featuredTitles12 h1 {
    font-size: 25px;
    color:white;
  }
  
  .featuredTitles12 h2 {
    font-size: 20px;
    color:white;
  }
  
  @media (max-width: 576px) {
    .featuredItem12 {
      height: 180px;
     width:270px !important;
    }
  
  
    .featured12 .featuredItem12 .featuredTitles12 h1 {
      font-size: 22px;
    }
  
    .featured12 .featuredItem12 .featuredTitles12 h2 {
      font-size: 17px;
    }
  }
  
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: black;
    margin: -4px 0 0 -4px;
  }
  
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  
  /* ... (Similar styles for other lds-roller divs) ... */
  
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: black;
  }
  
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  
  /* ... (Similar styles for other lds-spinner divs) ... */
  
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  