.rowbook {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  padding: 1rem;
}
#cap {
  text-transform: uppercase;
}
.img {
  width: 50%;
}
#contactimg {
  width: 100%;
  height: 100%;
}

.contact h2 {
  text-align: center;
  margin: 2rem 0;
  font-size: 3rem;
  font-weight: 300;
  word-spacing: 5px;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
}

.book-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.book-info p{
  font-family: "Urbanist", sans-serif;
  letter-spacing: 1px;
}

@media only screen and (max-width: 600px){
  .row{
    flex-direction: column;
  }
  .row .image{
    width: 50%;
  }
  .row .box{
    width: 50%;
  }
}