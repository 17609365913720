.listContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
@media only screen and (max-width: 768px) {
.listWrapper {
    width: 400px !important;
    /* margin-left: -175px; */
  justify-content: space-between;
  
    flex-direction: column;
   
}
.siDetails {
    
    border-top: 1px solid grey;
  padding-top: 10px;
    
}
.siImg{
    width: 400px !important;
}
.listSearch {
    width: 380px;
}
}
.listWrapper {
    width: 100%;
       max-width: 1024px; 
    display: flex;
    gap: 20px;
}
.available .first {
    font-weight: 500;
}
.available .second {
    color: black;
    font-style: italic;
}
.listSearch {
    /* flex: 1; */
    /* background-color: rgba(103,71,54,.1); */
    background-color: #FEBE00;

    padding: 10px;
    border-radius: 10px;
    /* position: sticky; */
    top: 10px;
    /* height: max-content; */
}

.lsTitle {
    font-size: 20px;
    /* color: #555; */
    color:black;
    margin-bottom: 10px;
}

.lsItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.lsItem>label {
    font-size: 12px;
}

.lsItem>input {
    height: 30px;
    border: none;
    padding: 5px;
}

.lsItem>span {
    height: 30px;
    padding: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.lsOptions {
    padding: 10px;
}

.lsOptionItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #555;
    font-size: 12px;
}

.lsOptionInput {
    width: 50px;
}

.listSearch>button {
    padding: 10px;
    background-color: #70bbe9;
    color: white;
    border: none;
    width: 100%;
    font-weight: 500;
    cursor: pointer;
}

.listResult {
    flex: 3;
}
.homeContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.homeTitle {
    width: 1024px;
    font-size: 20px;
}
.homeTitle1 {
    width: 1024px;
    font-size: 16px;
    margin-top: -20px;
}
.header1 {
    background-color: white;
    color: white;
    display: flex;
    justify-content: center;
    position: relative;
}

.headerContainer1 {
    width: 100%;
       max-width: 1024px; 
    margin: 20px 0px 100px 0px;
}

.headerContainer1.listMode {
    margin: 20px 0px 0px 0px;
}

.headerList1 {
    display: flex;
    gap: 40px;
    margin-bottom: -50px;
}

.headerListItem1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerListItem1.active {
    border: 1px solid #0f7cba;
    padding: 10px;
    border-radius: 20px;
}

.headerDesc {
    margin: 20px 0px;
}

.headerBtn {
    background-color: #70bbe9;
    color: white;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.headerSearch {
    height: 30px;
    background-color: white;
    border: 3px solid #febb02;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0;
    border-radius: 5px;
    position: absolute;
    bottom: -25px;
    width: 100%;
       max-width: 1024px; 
}

.headerSearchItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerIcon {
    color: lightgray;
}

.headerSearchInput {
    border: none;
    outline: none;
}

.headerSearchText {
    color: lightgray;
    cursor: pointer;
}

.date {
    position: absolute;
    top: 53px;
    z-index: 2;
}
.mar {
    margin-left: 120px;
}
.options {
    z-index: 2;
    position: absolute;
    top: 53px;
    right: 178px;
    background-color: white;
    color: black;
    border-radius: 5px;
}

.optionItem {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: black;
}

.optionCounterButton {
    width: 30px;
    height: 30px;
    border: 1px solid #70bbe9;
    color: #70bbe9;
    cursor: pointer;
    background-color: white;
}

.optionCounterButton:disabled {
    cursor: not-allowed;
}

.rdrCalendarWrapper {
    color: #000000;
    font-size: 12px;
    display: flex;
    left: 270px;
}
.listSearch .filterRow {
    padding: 20px 0;
}
.makeFlex.hrtlCenter {
    align-items: center;
}
.blackText {
    color: #000;
}
.font16 {
    font-size: 16px;
    line-height: 16px;
}
.latoBold {
    font-weight: 700;
}
.makeFlex {
    display: flex;
}
.blackText {
    color: #000;
}
.latoBold {
    font-weight: 700;
}
.font16 {
    font-size: calc(var(1, 1)*16px);
    line-height: 16px;
}
.appendBottom15 {
    margin-bottom: 15px;
}
.font16 {
    font-size: 16px;
}

element.style {
}
.listSearch .filterList {
    display: flex;
    flex-direction: column;
}
h1, h2, h3, h4, h5, h6, p, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.listSearch .filterList li {
    align-items: center !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 12px !important;
    /* margin-left: -20px; */
}
.latoBold {
    font-weight: 700;
}
.listSearch .filterList ul {
    margin: 0px !important;
}
.listSearch .filterList li .checkmarkOuter {
    flex-shrink: unset;
}
.listSearch .filterList li .checkmarkOuter {
    flex: 1;
}
.checkmarkOuter {
    display: inline-flex;
    flex-shrink: 0;
    min-height: 18px;
    position: relative;
}
.checkmarkOuter [type=checkbox]:checked, .checkmarkOuter [type=checkbox]:not(:checked) {
    left: -9999px;
    opacity: 0;
    position: absolute;
}
[type=checkbox]:checked+label:before, .checkmarkOuter [type=checkbox]:not(:checked)+label:before {
    background: #fff;
    /* border: 1px solid #9b9b9b; */
    border-radius: 2px;
    content: "";
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    width: 18px;
}
.checkmarkOuter [type=checkbox]:checked+label:before {
    background: #008cff;
    border: 1px solid #008cff;
    border-radius: 2px;
    content: "";
    height: 18px;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 18px;
}
.checkmarkOuter [type=checkbox]:checked+label, .checkmarkOuter [type=checkbox]:not(:checked)+label {
    cursor: pointer;
    display: inline-flex;
    line-height: 20px;
    padding-left: 28px;
    position: relative;
}
.font14 {
    font-size: 14px;
    line-height: 14px;
}
.listSearch .range {
    align-items: center;
    display: flex;
    flex-direction: unset;
    padding: 13px 0 0;
}
.listSearch .rangeInput {
    border: 1px solid #d2d6d9;
    border-radius: 4px;
    color: #4a4a4a;
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    outline: 0;
    padding: 0 5px;
    width: 60px;
}
html[dir=ltr] .appendLeft5 {
    margin-left: 5px;
}
.appendLR10 {
    margin: 0 10px;
}
html[dir=ltr] .appendLeft5 {
    margin-left: 5px;
}
.listSearch .btnRangeGo {
    align-items: center;
    background-color: #08f;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    width: 36px;
}
.fadeEff {
    opacity: .5;
    pointer-events: none;
}
.appendLeft5 {
    margin-left: 5px;
}


.searchItem {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}
.searchItem:hover {
    border: 1px solid #2d86f8;
   
}
.marvideo {
    width:300px;
    height:300px;
}
@media only screen and (max-width: 768px) {
.searchItem {
  
    flex-direction: column;
   
}
.mar {
    margin-left: 0px;
    margin: 10px;

    width:400px;
}
.marvideo{
 
    margin-left:50px ;
}
}
.siImg {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.siDesc {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1.5;
}

.siTitle {
    font-size: 20px;
    color: #0B58B4;
    margin: 0;
}

.siDistance {
    font-size: 12px;
    color:black;
}

.siTaxiOp {
    font-size: 12px;
    background-color: #008009;
    color: white;
    width: max-content;
    padding: 3px;
    border-radius: 5px;
}

.siSubtitle {
    font-size: 12px;
    font-weight: bold;
    color:black;
}

.siFeatures {
    font-size: 12px;
}

.siCancelOp {
    font-size: 12px;
    color: #008009;
    font-weight: bold;
}

.siCancelOpSubtitle {
    font-size: 12px;
    color: #008009;
}

.siDetails {
    flex: 1;
    display: flex;
    border-left: 1px solid grey;
    padding-left: 10px ;
    flex-direction: column;
    /* justify-content: space-between */
    
}

.siRating {
    display: flex;
    justify-content: space-around
   
}

.siRating>span {
    font-weight: 500;
}

.siRating>button {
    background-color: #0B58B4;
    color: white;
    padding: 5px;
    font-weight: bold;
    border: none;
}

.siDetailTexts {
    margin-top: 20px;
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.dropdownList {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
  }
  .dropdownList .dropdownOption {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    /* Optional: Add hover effect */
   
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s;
  }
  
  .dropdown-item:hover {
    background-color: #f9f9f9;
  }
  .dropdownList .dropdownOption:hover {
  
        background-color: #f0f0f0;
      }
.siPrice {
    font-size: 24px;
    color: #000;
    font-weight: bolder;
}

.siTaxOp {
    font-size: 12px;
    color: black;
}

.siCheckButton {
    background-color: #0B58B4;
    color: white;
    font-weight: bold;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}



