:root {
    --color-212529: #212529;
    --color-ffd333: #ffd333;
    --color-47ad24: #47ad24;
    --color-e52e2e: #e52e2e;
    --color-828599: #828599;
    --color-3d464d: #ed464d;
    --color-c3d4da: #ced4da;
  }
  .custom-modal {
    max-width: 90%;
    margin: 1.75rem auto;
  }
  .green {
    color: var(--color-47ad24);
    font-size: 14px;
    font-family: Roboto !important;
  }
  .red {
    color: var(--color-212529);
    font-size: 14px;
    font-family: Roboto !important;
  }
  .quill {
    background-color: white;
  }
  
  .form-control {
    box-shadow: none;
    border-color: var(--color-c3d4da);
  }
  .title {
    font: 28px;
    font-family: Roboto !important;
    color: var(--color-212529);
  }
  
  .sub-title {
    font: 24px;
    font-family: Roboto !important;
    color: var(--color-212529);
  }
  .desc {
    font: 14px;
    font-family: Roboto !important;
    color: var(--color-828599);
  }
  /* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #ec3198;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ----------- */

p {
  margin-bottom: 0px !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif !important;
  line-height: normal !important;
}

.margin-bottom {
  margin-bottom: 60px;
}

/* ------------ */

/* Navbar Start*/

body {
  font-family: 'Roboto', sans-serif;
}
h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
}
.nic .bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgb(216 29 74) !important;
}

.navigation .nav-link {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  padding-right: 14px !important;
}

.navigation .nav-link:hover {
  color: #000 !important;
}

.navigation .nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  transition: width .3s;
}

.navigation .nav-link:hover::after {
  width: 100%;
}

.login-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.5s ease 0s;
  animation: 5s ease 0s infinite normal both running roatepro;
}

@keyframes roatepro {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.small-text {
  font-size: 10px;
  color: black;
}

.advison-name {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  text-overflow: ellipsis;
}

.top-navbar {
  font-size: 12px;
  /* background: rgb(0, 0, 0); */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  cursor: pointer;
}

.top-navbar-btn {
  padding: 5px 5px !important;
  height: 30px;
  font-size: 13px !important;
  border: none !important;
}

.sign-in-btn {
  background-color: rgb(216 29 74) !important;
}

/* Navbar End */

/* Header Start */
.tourz-search {
  text-align: center;
}

.tourz-search h1 {
  font-size: 56px;
  padding-bottom: 10px;
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.tourz-search p {
  font-size: 20px;
}

.boxes {
  display: flex;
  gap: 15px;
  align-items: flex-end;
}

.picker {
  height: 55px;
  width: 118px;
  padding: 14px;
}

.header-search1 {
  /* background: linear-gradient(39deg, rgb(216, 29, 74), rgb(249 3 162) 80%); */
  background: red;
  
  color: rgb(255, 255, 255);
  border: 0px solid rgb(255, 167, 120) !important;
  padding: 5px 15px;
  font-size: 18px;
  letter-spacing: 1px;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
  font-weight: 600;
  font-family: "Noto Sans";
  text-align: center;
  width: 134px;
  height: 55px;
  display: block;
  line-height: 38px;
  outline: none;
}

.form-field {
  /* float: left; */
  /* padding: 20px 15px; */
  border-right: 1px solid #e6e6e6;
  font-size: 18px;
}

.field-input {
  position: relative;
  z-index: 2;
  width: 100%;
  border: 0;
  outline: none;
  color: #0f7cba;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 1px dashed #0f7cba;
}

.field-select {
  position: relative;
  z-index: 2;
  width: 20%;
  border: 0;
  outline: none;
  color: #0f7cba;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 1px dashed #0f7cba;
}

.field-select-1 {
  position: relative;
  z-index: 2;
  width: 27%;
  border: 0;
  outline: none;
  color: #0f7cba;
  background-color: transparent;
  font-weight: 600;
  border-bottom: 1px dashed #0f7cba;
}

.form-field label span {
  font-size: 15px;
  color: #0f7cba;
  font-weight: 600;
}

.form-field label {
  font-size: 13px;
}

.field-destination {
  width: 350px;
}

.field-destination-1 {
  width: 250px;
}

.field-date {
  width: 120px;
}

.form-field .field-select .select span {
  color: #0f7cba;
  font-weight: 600;
  border-bottom: 1px dashed #4da981;
  line-height: 31px;
}

.form-field .field-select .select select {
  font-weight: 600;
  color: #0f7cba;
}

.select span {
  display: block;
  padding-right: 20px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select select {
  cursor: pointer;
  /* position: absolute; */
  width: 100%;
  border: none;
  outline: none;
  background: none;
  color: #0f7cba;

}


.form-cn {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 20px 30px 30px 30px;
  border-top: 5px solid #0f7cba;
}


/* .mainbutton div {
position: absolute;
    bottom: -17px;
    left: 50%;
    content: '';
    border: 17px solid transparent;
    border-bottom-color: #59c45a;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
} */

/* 
.mainbuttonn.btn.btn-primary {
  position: absolute;
  bottom: -17px;
  left: 50%;
  content: '';
  border: 17px solid transparent;
  border-bottom-color: #59c45a;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
} */

.cat {
  display: flex;
  /* gap: 10px; */
  position: absolute;
  top: -34px;
  right: 286px;
  z-index: 9;
}

.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.cat div {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: 5px;
  width: 145px;
  align-items: center;
  border-right: 1px solid #fff;
  cursor: pointer;
}

.cat p {
  color: #fff;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cat span {
  color: #fff;
  font-size: 20px;
}

.carousel-indicators {
  display: none !important;
}

.arrow-icon-1 {
  position: absolute;
  top: -27px;
  right: 72.2%;
  color: #0f7cba;
}

.arrow-icon-2 {
  position: absolute;
  top: -27px;
  right: 57%;
  color: #0f7cba;
}


.arrow-icon-3 {
  position: absolute;
  top: -27px;
  right: 43%;
  color: #0f7cba;
}

.arrow-icon-4 {
  position: absolute;
  top: -27px;
  right: 29%;
  color: #0f7cba;
}

/* Header End */

/* Home start */

.package-btn {
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 60px;
  border-radius: 2px;
  text-decoration: none;
  display: inline-block;
  color: rgb(255, 255, 255);
  font-size: 13px;
  transition: all 0.5s ease 0s;
  background: rgb(0, 0, 0);
  border: 2px solid rgb(0, 0, 0);
}

.package-btn:hover {
  background-color: #fff;
  color: #000;
}

.sub-head {
  font-size: 25px;
  margin-bottom: 20px;
}

.main-head {
  font-size: 32px;
  font-weight: 500;
  text-overflow: ellipsis;
}

.package-img {
  width: 200px;
  margin-top: 23px;
  height: 150px;
  border-radius: 20px;
}

.packages {
  position: relative;
  border-radius: 45px;
  padding: 13px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 12px -3px;
  width: 245px;
}

.package-1 {
  background-image: linear-gradient(225deg, rgb(240, 240, 255) 0%, rgb(227, 210, 255) 99%);
}

.package-2 {
  background-image: linear-gradient(225deg, rgb(255, 237, 254) 0%, rgb(255, 210, 210) 99%);
}

.package-3 {
  background-image: linear-gradient(135deg, rgb(253, 252, 251) 0%, rgb(226, 209, 195) 100%);
}

.package-4 {
  background-image: linear-gradient(to top, rgb(204, 237, 208) 0%, rgb(238, 255, 236) 100%);
}

.package-5 {
  background-image: linear-gradient(to top, rgb(234, 241, 184) 0%, rgb(250, 255, 218) 100%);
}

.home-all-headings {

  text-transform: capitalize;
  font-size: 44px;
  color: rgb(37, 61, 82);
}

.home-all-headings span {
  color: rgb(244, 54, 79);
  font-size: 44px;
  font-weight: 600;
}

.destination-img {
  width: 100%;
  border-radius: 15px;
}

.gnjet {
  right: 26px;
  position: absolute;
  bottom: 12px;
  color: white;
  text-align: end;
}

.kaka::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.82) 10%, rgba(84, 84, 84, 0.35) 80%);
  top: 0px;
  bottom: 0px;
  left: 11px;
  width: 96%;
  border-radius: 10px;
}

.mamu::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.82) 10%, rgba(84, 84, 84, 0.35) 80%);
  top: 0px;
  bottom: 0px;
  left: 11px;
  width: 92%;
  border-radius: 10px;
}


.chacha::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgb(0 0 0 / 54%) 10%, rgba(84, 84, 84, 0.35) 80%);
  top: 0px;
  bottom: 0px;
  width: 32%;
  border-radius: 10px;
}

.sc-dnqmqq.bVoXXg .chacha-text {
  position: relative;
  padding-top: 225px;
}

.chacha-text span {
  font-size: 18px;
  font-weight: 600;
  background: rgb(239, 149, 15);
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.color-btn {
  background: linear-gradient(39deg, rgb(68, 68, 189), rgb(3, 158, 249) 80%);
  color: rgb(255, 255, 255);
  border: 0px solid rgb(255, 167, 120);
  padding: 5px 15px;
  font-size: 18px;
  letter-spacing: 1px;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
  font-weight: 600;
  margin: auto;
  width: 65%;
  display: block;
  line-height: 38px;
  outline: none;
}

.chacha {
  overflow: hidden;
}

.box-1:hover {
  scale: 1.2;
  transition: all 1s ease 0s;
}

.discount-bg::before {
  content: "";
  position: absolute;
  background: linear-gradient(to right, rgba(22, 40, 105, 0.91) 46%, rgba(0, 0, 0, 0.41) 100%);
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;

}

.discount-text {
  position: relative;
  color: #fff;
}

.discount-head {
  font-size: 50px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 100%;
  display: block;
  line-height: 58px;
  padding-top: 30px;
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
  text-align: center;
}

.discount-price {
  font-size: 66px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  width: 100%;
  display: block;
  line-height: 46px;
  margin-bottom: 30px;
  text-align: center;
}

.discount-icon {
  cursor: pointer;
  text-align: center;
}

.discount-icon img {
  padding: 12px;
  border-radius: 15px;
  width: 60px;
  height: 60px;
  background: rgb(244, 54, 79);
  display: inline-block;
}

.discount-icon span {
  color: rgb(236, 222, 224);
}

.discount-star {
  background: rgb(244, 54, 79);
  height: 46px;
  width: 145px;
  padding-top: 0px;
  padding-left: 5px;
  border-radius: 2px;
  margin: auto;
}

.go-bg {
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  width: 150px;
  height: 150px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 48px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.52) 0px 1px 20px;
  transition: all 0.5s ease 0s;
  margin: auto;
}

.go-bg:hover {
  transform: rotate(360deg);
  transition: all 0.5s ease 0s;
  cursor: pointer;
}

.go {
  font-size: 45px;
  text-transform: capitalize;
  color: rgb(244, 54, 79);
  font-weight: 600;
}

.go-stay {
  line-height: 20px;
  letter-spacing: 5px;
  border-bottom: 1px solid;
  color: rgb(51, 51, 51);
  margin-top: 25px;
}

.discount-offer {
  color: rgb(255, 255, 255);
  font-weight: 600;
  display: inline-block;
  line-height: 46px;
  width: 300px;
  height: 300px;
  background: rgb(244, 54, 79);
  border-radius: 50%;
  padding: 50px;
  text-align: center;
  border: 7px solid rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.52) 0px 1px 20px;
  transition: all 0.5s ease 0s;

  position: absolute;
  right: 50px;
  top: 114px;
}

.discount-offer:hover {
  background: rgb(244, 186, 54);
  transition: all 0.5s ease 0s;
}

.or-1 {
  font-size: 34px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-2 {
  font-size: 75px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-3 {
  font-size: 34px;
  text-transform: capitalize;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.or-4 {
  font-size: 14px;
  /* text-transform: capitalize; */
  color: rgb(255, 255, 255);
  font-weight: 600;
  border: 1px solid rgb(251, 175, 185);
  padding: 6px;
  border-radius: 25px;
}

/* top sight */
.top-sight img {
  width: 270px;
  height: 230px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.29) 1px 3px 10px;
}

.top-sight h3 {
  color: rgb(37, 61, 82);
  font-size: 18px;
  text-transform: uppercase;
  line-height: 34px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}

.top-sight h3 span {
  color: rgb(37, 61, 82);
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14.5px;
}

.top-sight p {
  color: rgb(37, 61, 82);
  padding: 10px 0px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
}

.pink-btn {
  font-weight: 500;
  padding: 8px 25px;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  color: rgb(255, 255, 255);
  border: 1px solid #e92c45 !important;
  background: #e92c45;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.pink-btn:hover {
  color: #e92c45;
  border: 1px solid #e92c45;
  background-color: #fff;
}

/* TOP BRANDING */

.brand-head {
  background: linear-gradient(39deg, rgb(0, 184, 255), rgb(1, 161, 255) 80%);
  color: rgb(255, 255, 255);
  padding: 10px 0px;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
}

.brand-head h5 span {
  color: rgb(255, 193, 7);
  font-weight: 600;
  font-size: 18px;
}

.top-brand {
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top-brand img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.top-brand h5 {
  margin-top: 0px;
  margin-bottom: 2px;
  color: rgb(52, 60, 66);
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  transition: all 0.5s ease 0s;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.top-brand span {
  font-size: 13px;
  color: rgb(153, 153, 153);
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.rate {
  border: 1px solid rgb(139, 155, 169);
  padding: 0px 4px;
  border-radius: 2px;
  font-size: 12px;
  color: rgb(37, 61, 82);
  font-weight: 600;
  text-shadow: rgba(0, 0, 0, 0.28) 0px -1px 0px;
  height: 22px;
}

/* MOBILE APP */

.mobile-app h1 {
  font-size: 48px;
  font-weight: 600;
}

.mobile-app p {
  font-size: 15px;
}

.mobile-app ul {
  list-style: none;
  padding: 5px;
}

.mobile-app ul li i {
  color: rgb(255, 255, 255);
  width: 31px;
  /* height: 18px; */
  background: rgb(141, 206, 212);
  border-radius: 3px;
  text-align: center;
  padding: 0px;
  font-size: 20px;
  vertical-align: text-bottom;
}

.img,
svg {
  vertical-align: none;
}

/* Blue bg Content */
.tips-head {
  background: rgb(46, 165, 211);
  display: inline-block;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  padding: 5px 8px 3px;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
}

.tips img {
  width: 42%;
  height: 100%;
}

.aggreement-img {
  border-radius: 15px;
}


/* Home End */


/* Footer Start */

.footer-1 {
  background: rgb(0, 0, 0);
  padding: 100px 0px 50px;
}

.disco h3 {
  color: rgb(255, 235, 59);
  font-size: 38px;
  margin-top: 0px;
  font-weight: 700;

}

.disco h3 span {
  color: rgb(255, 255, 255);
  font-size: 16px;
}

.location {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.location li {
  margin: 0px 0px 7px;
}

.location li a {
  color: rgb(209 195 195);
  text-decoration: none;
  background: rgb(45, 45, 45);
  padding: 5px;
}

.footer-nav li a {
  text-decoration: none;
  color: rgb(172 173 175 / 52%);
}


.copy p {
  text-align: center;
  padding: 5px;
  font-size: 14px;
  color: rgb(0, 0, 0);
}

/* Footer End */


/* Login Start */
.login-bg {
  margin: auto;
  width: 50%;
  background: rgb(255, 255, 255);
  padding: 40px 50px 50px;
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 4px 20px;
  margin-top: var(--topspac);
  max-width: 900px;
}

.login {
  text-align: center;
}

.login h4 {
  font-size: 42px;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 700;
  color: #0000007c;
}

.login-input {
  border: 1px solid #000000a6 !important;
}

.login-social {
  padding: 5px;
  list-style: none;
  display: flex;
  gap: 10px;
}

.login-social li {
  width: 33.333%;
}

.login-social li a {
  display: block;
  color: rgb(255, 255, 255);
  padding: 7px 18px;
  text-align: center;
  border-radius: 2px;
  text-decoration: none;
}

.login-social li .fb {
  background: rgb(59, 89, 152);
}

.login-social li .twit {
  background: rgb(0, 172, 237);
}

.login-social li .gogle {
  background: rgb(211, 72, 54);
}

/* Login End */

/* Activity Start */

.activity-img {
  width: auto;
  height: 500px;
}

.activity-cart {
  background-color: #f9f9f9;
  padding: 17px 22px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 15px;
}

.activity-cart p {
  text-overflow: ellipsis;
}

.activity-table tr th {
  background-color: #0f7cba !important;
  color: #fff;
  border-bottom: none !important;
}


.activity-bg::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 60%);
  top: 0px;
  bottom: -27px;
  left: 0px;
  width: 100%;
  height: 472px;
}

.activity-text {
  position: relative;
  color: #fff;
}

.bread {
  position: absolute;
  left: 150px;
  top: 250px;
}

.breadcrumb-active {
  text-decoration: none;
  color: #fff;
}


/* 
.bg-yellow {
  position: relative;
}

.activity-img::after {
  content: "";
  position: absolute;
  width: 88%;
  height: 75%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  animation-duration: 1s;
  animation-name: float;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-play-state: running;
  background-color: #0f7cba;
} */

/* Activity End */

/* Booking Page Start */
.siri h5 {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 4px;
  font-weight: 100;
}

.siri h1 {
  margin: 0px 0px 20px;
  color: rgb(255, 255, 255);
  font-size: 48px;
  font-weight: 600;
}

.siri p {
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 100;
  line-height: 30px;
}

/* Booking Page End */


/* Hotel Starts here */

.hotel-cat {
  display: flex;
  position: absolute;
  top: 76px;
  z-index: 9;
  left: 43%;
}

.hotel-cat div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 145px;
  align-items: center;
  /* border-right: 1px solid #fff; */
  cursor: pointer;
}

.hotel-cat p {
  color: #fff;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.hotel-cat span {
  color: #fff;
  font-size: 20px;
}

.hotel-arrow-icon-1 {
  position: absolute;
  top: -27px;
  right: 50.5%;
  color: #0f7cba;
}

.hotel_1 {
  position: relative;
  margin-bottom: 53rem;
}

.hotel-filter {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.hotel-filter img {
  width: auto;
  height: 30px;
}

.hotel-filter span {
  font-weight: 600;
}

.hotel-filter-btn {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 190px;
  height: 42px;
  padding: 0px !important;
}

.urus_0 {
  width: 300px;
  height: 170px;
}

.breadcums ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}

.breadcrumbs {
  display: flex;
  list-style: none;
}

.breadcrumbs li {
  font-size: 18px;
  color: #888888;
  font-style: italic;
  border-bottom: 1px solid #888888;
}

.breadcrumbs a {
  text-decoration: none;
  color: #888888;
}

.main_hotel {
  border: 1px solid #888888;
  position: absolute;
  top: 20rem;
  width: 90%;
  left: 6%;
}

/* 
.search-result {
  background-color: #111111;
  padding: 10px 30px;
  width: 100%;
  height: 110px;
  position: relative;
}

.search-result p {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  font-family: "Georgia";
  font-style: italic;
}

.search-result p ins {
  font-size: 30px;
  text-decoration: none;
  color: #59c45a;
}

.search-result p span {
  font-size: 13px;
} */

.hotel_content section {
  border-bottom: 1px dotted #888888;
}

.star_rating select {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-bottom: 1px dotted #db1b52 !important;
  padding-bottom: 5px;
}

.vi_0 {
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-bottom: 1px dotted #0f7cba !important;
  outline-width: 0px;
  padding: 7px;
  border-radius: 5px;
  width: 100%;
}


.sidebar {
  border-right: 1px dotted black;
  /* padding-right: 10px; */
}

.hotel-name a {
  color: #111111;
  font-size: 30px;
  line-height: 32px;
  font-weight: 300;
  text-decoration: none;

}

.hotel-star-address span {
  font-size: 15px;
}

.vi_1 {
  border: 1px solid #888888;
  outline-width: 0px;
  padding: 7px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.hotel-head {
  color: #0f7cba;
  font-weight: 600;
}

.out_0 h6 {
  color: #0f7cba;
  font-weight: 600;
  font-size: 30px;
}

.out_0 h6 span {
  color: #888888;
  font-size: 16px;
}

.out_0 p {
  font-size: 18px;
  color: #000000ad;
}

.out_0 p s {
  font-size: 14px;
}

.warner {
  border: 1px solid #0000002e;
  padding: 5px;
  border-radius: 7px;
  width: 155px;
}

.warner label {
  font-weight: 600;
  color: #0f7cba;
}

.warner-out {
  border: 1px solid #0000002e;
  padding: 5px;
  border-radius: 7px;
  margin-bottom: 10px;
}

.warner-out div {
  cursor: pointer;
}

.warner-out p {
  font-weight: 600;
  color: #0f7cba;
}

.increase-icon {
  cursor: pointer;
  border: 1px solid #0000007d;
  border-radius: 50%;
}

/* Hotel Ends here */


/* Taxi start from here */

.banner_hero {
  position: relative;

}

.jitotrg {
  margin-bottom: 344px;
}

.local-00 {
  border: 1px solid rgb(255, 255, 255);
  width: inherit;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 13%) 0px 0.2rem 1.2rem 0px;
  position: sticky;
  top: 0;
  z-index: 6;
  border-radius: 1.2rem;
  padding: 1.5rem;
  margin: auto;
  height: auto;
}

.ul_wrap {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  flex-direction: row;
  border-radius: 30px;
  padding: 0px 6px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin: 11px;
  cursor: pointer;
}

.banner_hero {
  position: relative;
}

.geh {
  position: absolute;
  top: -90px;
  width: 85%;

}

.tab-active {
  background-color: #0f7cba;
  border-radius: 30px;
  color: #fff !important;
  padding: 2px 11px;
}

.ul_wrap li {
  margin: 15px 0px 15px;
  font-size: 16px;
  font-weight: 700;
}

.docab {
  font-size: 30px;
  font-weight: 600;
  color: #000;
  text-align: center;
}

.wr-9 tr th {
  background-color: #0f7cba;
  color: #fff;
}

.hotel-details-img {
  display: flex;
  gap: 10px;
  align-items: center;
}

.hotel-details-img img {
  width: auto;
  height: 120px;
  border-radius: 15px;
}

.hotel-details-img p {
  color: #111111;
  font-size: 18px;
  line-height: 20px;
}

.hotel-details-img span {
  font-size: 12px;
}

.smith {
  border: 1px solid #0000002e;
  padding: 5px;
  border-radius: 7px;
  height: 65px;
}

.smith label {
  font-weight: 600;
  color: #0f7cba;
}

.ola-box {
  /* width: 500px; */
  border: 1px solid #00000040;
  padding: 10px;
  height: fit-content;
}

.ola {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* gap: 50px; */
}

.ola img {
  width: auto;
  height: 50px;
}

.ola p {
  font-size: 14px;
  color: #000000b4;
  text-overflow: ellipsis;
}

.ola span {
  font-weight: 600;
  font-size: 18px;
}

.ola-link {
  text-decoration: none;
  color: #000;
}

.ola-content {
  width: 200px;
}

.taxi-book {
  display: flex;
  gap: 30px;
  line-height: 40px;
  justify-content: space-between;
  align-items: center;
}

.taxi-book span {
  font-size: 12px;
  color: #000000c4;
  text-overflow: ellipsis;
}

.taxi-book p {
  text-overflow: ellipsis;
}

.lalit {
  border: 1px solid #00000052;
  margin-top: 75px !important;
  height: fit-content;
}

.lalit-left {
  padding: 0px !important;
  border-right: 1px solid #00000052;
}

.lalit h5 {
  font-size: large;
  background: #0f7cba;
  padding: 8px;
  color: #fff;
  text-overflow: ellipsis;
}


.munna img {
  width: 100%;
  height: 160px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.munna span {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.munna p {
  text-align: justify;
  color: rgb(134, 134, 134);
}

/* Taxi End */

/* Profile Start */

.profile-name {
  font-weight: 600;
  font-size: 27px;
  text-align: center;
  line-height: 26px;
}

.wad_0 h4 {
  background: #0f7cba;
  padding: 15px;
  color: rgb(255, 255, 255);
  border-radius: 3px 3px 0px 0px;
}

.pay-done {
  background: rgb(62, 142, 65) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 4px;
  width: 40px;
}

.pay-more {
  background: rgb(216 29 74) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 4px;
  width: 68px;
  cursor: pointer;
}

.pay-pending {
  background: rgb(244, 163, 33) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 4px;
  width: 56px;
}

.yue_0 {
  list-style: none;
  padding: 5px;
}

.yue_0 li {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #00000049;
  padding: 10px 0px;
}

.yue_0 li img {
  width: auto;
  height: 30px;
  border-radius: 50%;
}

.yue_0 li span {
  font-size: 12px;
  text-overflow: ellipsis;
  color: #000000a8;
}

/* Profile End */

/*Tour Packages start  */
.trip-img {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.trip h4 {
  color: #0f7cba;
  font-family: math !important;
  font-weight: 600;
  font-size: 30px;
}

.trip p {
  color: #00000080;
}

.trip-amount {
  display: flex;
  justify-content: center;
  position: relative;
}

.trip-amount p {
  color: #00000080;
}

.trip-amount span {
  color: #0f7cba;
  font-weight: 600;
  font-size: 30px;
}

.band .trip-offer {
  background: rgb(255, 152, 0);
  position: absolute;
  padding: 4px 8px;
  right: 0px;
  border-radius: 1px;
  color: rgb(255, 255, 255);
  top: 28px;
  font-size: 13px;
}

.ousf_0 {
  border: 1px solid rgb(222, 222, 222);
  background: rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease 0s;
  margin-bottom: 20px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.ousf_0:hover {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 5px 19px -3px;
  transform: scale(1.02);
}


.trip-i {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  padding: 5px;
  list-style: none;
}

.trip-i img {
  width: 20px;
}

.trip-i li {
  text-align: center;
  padding: 6px;
  border: 1px solid #00000040;
  width: fit-content;
}

.trip-i p {
  color: #00000073;
  font-size: 12px;
}

.css-wjh20t-MuiPagination-ul {
  justify-content: end;
  margin-bottom: 10px !important;
}

.umbrella {
  width: 20px;
  height: fit-content;
}

.trip-gallery {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 10px;
}

.small_imgA {
  display: flex;
  width: 100% !important;
  overflow-x: scroll;
  white-space: nowrap;

}
.small_imgA div{
  display: flex;
  width: 100% !important;
flex-direction: row;
  justify-content: space-between;
  
}
.small_imgA div img {
  width: 200px !important;
  height: 100px;
 
  margin-left: 15px;
  border-radius: 10px;
  cursor: pointer;
}

.owl-mama {
  width: 100% !important;
}

.sjrhg_0 tr td {
  /* color: #00000096; */
  text-overflow: ellipsis;
}

/*Tour Packages End  */

/* Communtiy start  */
.facebook {
  /* position: fixed; */
  /* bottom: 0; */
  left: 0;
  /* width: 100vw; */
  /* background-color: #0f7cba; */
  height: 80px;
  margin-bottom: 30px;
}

.face-search {
  height: 38px;
  width: 194px;
}

.total-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center !important;
  padding: 12px 26px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #fff;
  gap: 10px;
}

.total-post span {
  font-size: 20px;
  font-weight: 600;
}

.face-profile {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
}

.face-profile p {
  color: #fff;
  font-size: 17px;
}

.face-profile span {
  color: #ffffff81;
}

.active-dot {
  position: absolute;
  /* right: 14px; */
  left: 35px;
  top: 5px;
  width: 12px;
  height: 12px;
  border: 3px solid #ffffff;
  border-radius: 100%;
  background: green;
}

.facebook-left {
  width: 82px;
  background-color: #0f7cba;
  padding: 36px 16px;
  text-align: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 5px;
  position: -webkit-sticky;
  /* position: sticky;
  z-index: 2;
  top: 100px; */
  margin-bottom: 30px;
}

.facebook-left ul {
  padding: 5px;
  list-style: none;
}

.facebook-left ul li {
  width: 45px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 20px;
}

.face-story {
  padding: 7px;
  border: 2px solid rgba(3, 137, 201, 0.15);
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ohim {
  background-color: rgb(216 29 74 / 42%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ohim input {
  position: absolute;
  bottom: 41px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
  width: 200px;
}

.ohim p {
  width: 50px;
  height: 50px;
  margin: 73px;
  background-color: rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.face-story-1 {
  padding: 7px;
  border: 2px solid rgba(3, 137, 201, 0.15);
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ohim-1 {
  background: linear-gradient(0deg, #e18d1e 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fb-name {
  position: absolute;
  bottom: 0;
  padding: 7px 20px;
  color: #fff;
}

.fb-name span {
  font-size: 12px;
  letter-spacing: 1px;
}

.ohim-2 {
  background: linear-gradient(0deg, #ed8079 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ohim-3 {
  background: linear-gradient(0deg, #a38a7b 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ohim-4 {
  background: linear-gradient(0deg, #fcd101 0%, rgba(0, 212, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.jkghsidf_0 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
}

.feel {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.album {
  display: flex;
  gap: 7px;
  padding: 8px;
  align-items: center;
  font-size: 15px;
  color: black;
  border: 1px solid #0000002f;
  border-radius: 10px;
  cursor: pointer;
}

.jgashgd_1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 14px;
  border-radius: 5px;
}

.fb-gallery {
  width: 97px;
  height: 80px;
  object-fit: cover;
}

.save-tag {
  padding: 10px 10px;
  background-color: #d7e8ef;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.kalin {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.emoji {
  padding: 14px 0px;
  border-bottom: 1px solid #e1e7e9;
  border-top: 1px solid #e1e7e9;
  background-color: rgba(237, 247, 251, 0.25);
  display: flex;
  justify-content: space-between;
}

.emoji img {
  width: 20px;
  height: 20px;
}

.react-0 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 14px 0px;
  position: relative;
}

.react-0 span {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: black;
  cursor: pointer;
}

.chat {
  font-size: 15px;
}

.chat-online {
  font-size: 12px;
}

.chat-box li {
  border-bottom: 1px solid #0000002b;
  padding: 6px;
  cursor: pointer;
}

.fb-notification h3 {
  padding: 15px 15px 15px;
  border-bottom: 1px solid #c3c3c3;
  font-size: 20px;
}

.fb-notification {
  width: max-content;
  height: 390px;
  overflow: auto;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: absolute;
  background: #ffe6ec;
  border-radius: 11px;
  transform: translate(-299px, 0px);
}

.fb-notification ul {
  list-style: none;
  padding: 5px;
}

.fb-notification ul li {
  border-bottom: 1px solid #c3c3c3;
  padding: 5px;
}

.jhdjs_0 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.jhdjs_0 span {
  color: #fff;
  background-color: #0f7cba;
  padding: 2px 4px;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid;
  transition: 0.8s ease all;
  border-radius: 5px;
}

.jhdjs_0 span:hover {
  color: #0f7cba;
  background-color: #ffffff;
}

.fb-profile {
  position: relative;
}

.fb-profile span {
  font-size: 28px;
  color: #0f7cba;
  cursor: pointer;
}

.fb-profile input {
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 57px;
  bottom: 22px;
  cursor: pointer;
}


.react-multiple-carousel__arrow {
  display: none !important;
}

.open-emoji {
  position: absolute;
  bottom: 39px;
  left: 35px;
  background-color: #ffffff;
  padding: 12px 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  transition: 1s all ease;
}

.open-emoji img {
  width: 20px;
  height: 20px;
  animation: emoji1 0.7333s;
  cursor: pointer;
}

@keyframes emoji1 {
  0% {
    opacity: 0.3374;
    transform: translateY(35.7785px) scale(0.3374, 0.3374);
  }
}

.login-eye {
  position: relative;
}

.login-1-eye {
  position: absolute;
  top: 9px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
}

/* Communtiy End  */

/* about-us Start */
.about-us h3 {
  color: rgb(37, 61, 82);
  font-weight: 600;
}

.about-us h3 span {
  color: #0f7cba;
}

.about-us p {
  color: rgb(136, 136, 136);
  line-height: 30px;
  margin-bottom: 15px !important;
}

.about-us button {
  letter-spacing: 1.5px;
}

.about-counter {
  background: #00afeb;
  padding: 50px 0px 50px;
  background-image: linear-gradient(to top, #4481eb 0%, #00afeb 100%);
}

.about-counter span {
  font-size: 42px;
  font-weight: 600;
  color: rgb(255, 194, 16);
  text-shadow: rgba(0, 0, 0, 0.43) 0px 1px 0px;
}

.about-counter h4 {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.43) 0px 1px 0px;
  color: rgb(255, 255, 255);
  font-family: math !important;
}

.about-counter p {
  color: rgb(237 247 255);
  font-size: 18px;
  font-weight: 300;
}

/* about-us End */

/* Vendor start */
.stay-div {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
}

.passbtn {
  border: 1px solid dimgray;
  background-color: transparent;
  border-radius: 0px 5px 5px 0px;
}

/* Vendor End */

@media only screen and (max-width: 768px) {}
.dashboard-card12 {
  background: linear-gradient(to right, #001529, #002240, #00315e);;

  /* background: linear-gradient(to right, #3498db, #5dade2, #85c1e9);; */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

width:270px;
  overflow: hidden;
  margin: 10px;
  transition: transform 0.3s ease-in-out;
}

.dashboard-card12:hover {
  transform: scale(1.02);
}

.card-header12 {
  /* background-color: #f2f2f2; */
  background: linear-gradient(to right, #001529, #002240, #00315e);;

  padding: 15px;
  display: flex;
 
  align-items: center;
}

.card-icon12 {
  margin-right: 10px;
}

.card-title12 {
  margin: 0;
  color: white !important;

}

.card-content12 {
  padding: 15px;

}

.card-value12 {
  font-size: 24px;
  color: white !important;
  margin: 0;
}
.fontColor{
  color:black !important;
}
.tour_head1 h3 {
  background: url(../public/img/umbrella.png) left center / 16px no-repeat;
  padding-left: 35px;
  color: rgb(33, 61, 68);
  margin-bottom: 20px;
  margin-top: 50px;
  font-weight: 600;
  font-size: 22px;
}
.Pil {
 
  background: url(../public/img/trip-package-bg.jpg);
              background-position: initial;
              background-size: cover;
              background-repeat: no-repeat;
              background-attachment: fixed;
              height: 350px;
              width: 100%;
}
.l-info-pack-days ul li i {
  width: 35px;
  height: 35px;
  background: rgb(223, 59, 119);
  border-radius: 50%;
  text-align: center;
  padding: 9px 10px;
  margin-left: -68px;
  position: absolute;
  z-index: 99;
  color: rgb(255, 255, 255);
  font-size: 18px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.l-info-pack-plac::after {
  content: "";
  background: rgb(206, 206, 206);
  position: absolute;
  width: 1px;
  top: 0px;
  bottom: 0px;
  height: 100%;
  left: 19px;
}
.days h4 span {
  color: #DF3B77;
}

a, p, li, td, span {
  font-size: 14.5px;
  line-height: 24px;
  font-weight: 400;
}
.l-info-pack-days ul li {
  position: relative;
  overflow: hidden;
  padding-left: 70px;
}

.com-colo-abou p, li {
  color: black;
}
a, p, li, td, span {
  font-size: 14.5px;
  line-height: 24px;
  font-weight: 400;
}
.fa-clock-o:before {
  content: "\f017";
}
.l-info-pack-days ul li p {
    padding-top: 0px;
    font-size: 13px;
}
.tour_head1 p {
    color: rgb(127, 127, 127);
    font-weight: 500;
}
a, p, li, td, span {
    font-size: 14.5px;
    line-height: 24px;
    font-weight: 400;
}
p {
    margin: 0 0 10px;
}
.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 32px; /* Increase the font size for larger buttons */
  color: #000;
}

.owl-prev {
  left: 10px;
}

.owl-next {
  right: 10px;
}
.inner_banner_3 {
  background: url(./assets/trip.jpg) center center / cover no-repeat;
}
.inner_banner {
  /* background: url(../images/list-p-bg.jpg) center center / cover no-repeat; */
  padding: 55px 0px 10px;
  position: relative;
  height: 400px;
  /* margin-top: 70px; */
}
.inner_banner::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgb(0 0 0 / 70%) 15%, rgb(0 0 0 / 28%) 100%);
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (min-width: 1200px){
.containerpil {
    width: 1170px;
}
}
@media (min-width: 992px){
.containerpil {
    width: 90%;
}
}
@media (min-width: 768px){
.containerpil {
    width: 90%;
}
}
.containerpil {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.inner_banner div.spe-title, .inner_banner div ul {
  position: relative;
  margin-bottom: 20px;
}
.spe-title {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}
.inner_banner h1 {
  color: rgb(255, 46, 74);
  font-size: 36px;
  /* display: inline-block; */
  font-weight: 600;
  text-align: center;
  padding-bottom: 20px;
  margin-top: 100px;
}
.tit-inn-pg h1 {
}
h1, h2 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
}
.title-line {
  margin-top: -10px;
}
.inner_banner p {
  color: white !important;
  font-size: 20px;
  font-weight: 100;
  text-align: center;
  padding: 15px 0 20px 0;
}
.spe-title p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 300;
  padding-top: 12px;
  color: white;
  line-height: 30px;
}
a, p, li, td, span {
  font-size: 14.5px;
  line-height: 24px;
  font-weight: 400;
}
p {
  margin: 0 0 10px;
}
.inner_banner h1 span {
  color: rgb(255, 255, 255);
  font-size: 36px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}
.tit-inn-pg h1 span {
}
a, p, li, td, span {
  font-size: 14.5px;
  line-height: 24px;
  font-weight: 400;
}
.p2_2 {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  width: 100%;
}
.band {
  width: 50px;
  height: auto;
  position: absolute;
  z-index: 9;
  left: 29px;
  top: -6px;
}
.to-ho-hotel-con {
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(223, 223, 223);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 4px 7px;
  margin-bottom: 30px;
  border-radius: 20px;
}
.pack-new-box * {
  outline: none;
}
.to-ho-hotel-con-1 {
  position: relative;
}
.pack-new-box .to-ho-hotel-con-1::before {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.68) 15%, rgba(0, 0, 0, 0) 87%);
}
.to-ho-hotel-con-1::before {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.72) 14%, rgba(0, 0, 0, 0.08) 35%);
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
}
.pack-new-box .to-ho-hotel-con-1 img {
  height: 300px;
}
.p2_1 img {
  width: 100%;
}
.to-ho-hotel-con-1 img {
  width: 100%;
  height: 325px;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
  transition: all 0.5s ease 0s;
}
.pack-new-box * {
  outline: none;
}
.hom-pack-deta {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  color: rgb(255, 255, 255);
  width: 100%;
  text-align: center;
  padding: 0px 50px 30px;
  z-index: 2;
}
.pack-new-box * {
  outline: none;
}
.hom-pack-deta h2 {
  margin: 0px 0px 20px;
  color:white;
  font-size: 28px;
}
.pack-new-box * {
  outline: none;
}
.hom-pack-deta h4 {
  font-weight: 500;
  color: white;
  padding-bottom: 5px;
}
.pack-new-box * {
  outline: none;
}
.hom-pack-deta h4 span {
  font-size: 18px;
  font-weight: 600;
  background: rgb(239, 149, 15);
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
}
.pack-new-box * {
  outline: none;
}
.p2_2 .p2 {
  padding-left: 40px;
}


.p2_2 .p2 {
    padding-left: 40px;
}

@media (min-width: 992px){
.col-md-6 {
    width: 50%;
}
}
@media (min-width: 992px){
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
}
}
@media (min-width: 768px){
.col-sm-6 {
    width: 50%;
}
}
@media (min-width: 768px){
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
}
.col-xs-12 {
    width: 100%;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
}
.p2 h3 {
  color: #000;
  margin-top: 0px;
}
.p2 h3 span {
  display: block;
  margin-top: 12px;
}
.p2 p {
  text-align: left;
  color: #373748;
  line-height: 25px;
  font-size: 16px;
  margin-bottom: 25px;
}
.ticket {
  margin-bottom: 10px;
}
.ticket ul {
  padding: 0px;
  margin-bottom: 5px;
}
.ticket ul li:nth-child(1) {
  background: rgb(1 244 10);
}
.ticket ul li {
  display: inline-block;
  background: rgb(239, 239, 239);
  padding: 4px 12px;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  margin-right: 1px;
  border-radius: 15px;
}
.com-colo-abou p, li {
  color: black;
}
.featur {
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
}
.featur h4 {
  background: url(./assets/flag.png) 0px 0px / contain no-repeat;
  padding-left: 25px;
  margin-top: 15px;
  color: rgb(37, 61, 82);
  text-transform: uppercase;
  font-size: 14px;
}
.featur ul {
  padding: 0px;
  margin-bottom: 0px;
}
.featur ul li {
  background: url(./assets/tick.png) left center no-repeat;
  display: inline-block;
  padding: 3px 3px 3px 24px;
  color: rgb(37, 61, 82);
  font-size: 13px;
  font-weight: 600;
}
.p2_book ul {
  padding: 0px;
}
.p2_book ul li a {
  display: table;
}
.link-btn {
  font-weight: 600;
  padding: 8px 25px;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease 0s;
  color: rgb(255, 255, 255);
  border: 0px solid #e92c45;
  background: #e92c45;
}
.p2_book ul li {
  display: inline-block;
}
.com-colo-abou p, li {
  color: black;
}
.p2_book ul li:nth-child(2) a {
  background: #fff;
  border: 2px solid #000;
  color: #000;
  margin-left: 5px;
}
.p2_book ul li a {
  display: table;
}
.pad-bot-redu-5 {
  padding-bottom: 50px;
}
.tb-space {
  padding: 60px 15px 50px 15px;
}
.inn-page-con-bg {
  position: relative;
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-shadow: rgba(150, 150, 150, 0.4) 0px -2px 7px;
  padding: 70px 20px 30px;
}
.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* RSA */
/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.owl-nav {
  display: none;
}
.owl-prev {
  display: none;
}
.owl-next {
  display: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  margin-bottom: 0px !important;
}

.letter-space {
  letter-spacing: 1px;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #ec3198;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-color {
  background-color: #0a0a0a;
}

.nav-link {
  color: white !important;
}

.Button {
  color: pink;
}

@media only screen and (max-width: 768px) {
  .header-text {
    text-align: center;
  }
}

.renew-btn {
  background: #da291c;
    color: #fff;
    border: none;
    padding: 6px;
    letter-spacing: 1px;
    font-size: 14px !important;
    height: 32px;
    border-radius: 10px;
}

.buy-btn {
  background: #fff;
  color: #000;
  border: none;
  padding: 6px;
  letter-spacing: 1px;
  height: 35px;
}

.header-text {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.more-info {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  font-weight: 500;
}

.tab-011 {
  background: none !important;
  color: #000 !important;
  border-bottom: 1px solid #00000073 !important;
  border-radius: 0px !important;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
}

.cat_00 h4 {
  font-weight: 700;
  padding: 10px;
}
.cat_00 p {
  font-weight: 500;
  padding: 10px;
}
.cat-first-col {
  background: #d5d5d5 !important;
}

.Accord_00 {
  border-bottom: 1px solid #0000007a !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.activation-00 .nav-link.active {
  color: #da291c !important;
}

/* Footer Start */

.footer-1 {
  background: #e5e3e3;
  padding: 100px 0px 50px;
}

.disco h3 {
  color: rgb(255, 235, 59);
  font-size: 38px;
  margin-top: 0px;
  font-weight: 700;
}

.disco h3 span {
  color: rgb(0, 0, 0);
  font-size: 16px;
}

.location {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.location li {
  margin: 0px 0px 7px;
}

.location li a {
  color: rgba(0, 0, 0, 0.74);
  text-decoration: none;
  background: rgb(45, 45, 45);
  padding: 5px;
}

.footer-nav li a {
  text-decoration: none;
  color: rgba(5, 5, 5, 0.664);
}

.footter-up {
  padding: 15px 0 5px;
  background: #212121;
}

.footter-up img {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

/* Footer End */
.bike {
  margin-bottom: 15px;
}
.bike img {
  width: 100%;
  height: 300px;
  image-rendering: pixelated;
}

.service-input {
  background-color: #fff !important;
  font-size: 14px;
  font-family: montserrat-semibold;
  letter-spacing: 0.31px;
  position: relative;
  min-height: 50px;
  border: none;
  padding: 14px;
  width: 100%;
  margin-bottom: 20px;
}

.shop_00 {
  background: #000000;
  padding: 20px;
}

.shop_00 img{
  width: 100px;
  height: 100px;
}

/* mine  */

/* .card{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
padding: 14px 80px 18px 36px;
cursor: pointer;
}

.card:hover{
   transform: scale(1.05);
box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card h3{
font-weight: 600;
}

.card img{
position: absolute;
top: 20px;
right: 15px;
max-height: 120px;
}

.card-1{
background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
    background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
} */


.jh{
  display: flex;
    justify-content: flex-end;
    
    padding: 0 20px;
    /* position: fixed; */
    /* top: 400px; */
    /* right: 0; */

}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E) !important;
}
.form-check-input:checked {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}
.custom-table .ant-table-thead > tr > th {
  border-right: 1px solid #000;
}

.custom-table .ant-table-tbody > tr > td {
  border-right: 1px solid #000;
}
.custom-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #000;
}
.owl-dots {
  display: none !important;
}
.owl-carousel .owl-dot {
  display: none !important;

}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  display: none !important;

}
.fs-4 {
  color:white !important;
}
.ant-menu-title-content {
  color:white !important;
}
.selected {
  border: 2px solid #067CF7;
  width:100px;
  height:100px /* Example styling for selected images */
}
.fc-daygrid-day.fc-day {
  background-color: white !important;
  
}
.past-date {
  background-color: grey !important;
  
}
.red-cell {
  background-color: red !important;
  color:white !important
}
.green-cell {
  background-color: green !important;
  color:white !important
}
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  display: none;
}
.event-title {
  width:5px;
  font-size: 7px; 
}
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%; 
}


.messages-container {
  
  max-width: 100%;
  padding: 10px;
  border-radius: 10px;
 
  margin: 5px;
}

.messagess {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: white;
}

/* Style for the input and send button */
.message-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
}

.message-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
}

.send-button {
  padding: 8px 20px;
  background-color: #005c4b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #004438;
}
/* Story container */
.story-container {
  width: 100%;
  overflow-x: auto;
  padding: 15px 0;
}

.story-wrapper {
  display: flex;
  overflow-x: scroll; /* Allows horizontal scrolling */
    /* Hides scrollbar in Firefox */
}

.story-wrapper::-webkit-scrollbar {
  display: none;  /* Hides scrollbar in Chrome, Safari, and Opera */
}

/* Story card */
.story-card {
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

/* Story image */
.story-image {
  width: 150px;
  height: 200px;
  border-radius: 20px;
}

/* Video player container */
.video-playerstory {
  width: 150px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
}

/* Video player */
.videodstory {
  width: 100%;
  height: 100%;
}

/* Profile image */
.profile-imagestory {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.friends-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.friends-list {
  width: 100%;
  max-width: 600px;
}

.friend-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}

.friend-avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 1rem;
}

.friend-name {
  font-weight: bold;
}

.friend-status {
  font-size: 0.9rem;
  color: #666;
}

.friend-statusaccepted {
  margin-top: 20px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  background-color: blue;
  color: white;
}

.friend-statuspending {
  margin-top: 20px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  background-color: orange;
  color: white;
}

.friend-action-btn {
  margin-top: 20px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  background-color: green;
  color: white;
}

.friend-action-btn.add {
  background-color: green;
  color: white;
}
.friend-request-container {
  padding: 1rem;
}

.headerreq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.friend-requests {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.friend-request {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
}

.friend-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.friend-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.friend-details h3 {
  margin: 0;
  font-size: 1.2rem;
}

.friend-details p {
  margin: 0;
  color: #666;
}

.friend-actions {
  display: flex;
  gap: 0.5rem;
}

.accept-btn,
.reject-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
}

.accept-btn {
  background-color: #23d2e2;
  color: #fff;
}

.reject-btn {
  background-color: #e23e3e;
  color: #fff;
}

.accepted,
.rejected {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.accepted {
  background-color: #23d2e2;
  color: #fff;
}

.rejected {
  background-color: #e23e3e;
  color: #fff;
}
.form-control-lg::placeholder {
  color: black;
  font-weight: bolder;
  font-size: 16px; /* Change this to your preferred color */
  opacity: 1; /* Ensures the color is fully applied */
}
.black-placeholder::placeholder {
  color: black;
  opacity: 1; /* Ensure the color is fully opaque */
}
.add-profile-inpt-field::placeholder {
  color: black;
  opacity: 1;
}
.f-title1 {
  color: #ff7d26;
  font-weight: bolder;
  font-size: 14px;
  opacity: 1;
}
.fc .fc-button-primary:disabled {
    background-color: var(--fc-button-bg-color);
    border-color: var(--fc-button-border-color);
    color: var(--fc-button-text-color);
    width: 70px !important;
   text-transform: uppercase !important;
}
.fc .fc-button-primary {
 
  
  width: 70px !important;
 text-transform: uppercase !important;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}