.homeContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.homeTitle {
    width: 1024px;
    font-size: 20px;
}
.homeTitle1 {
    width: 1024px;
    font-size: 16px;
    margin-top: -20px;
}
.header {
    background-color: white;
    color: white;
    display: flex;
    justify-content: center;
    position: relative;
}

.headerContainer {
    width: 100%;
       max-width: 1024px; 
    margin: 20px 0px 100px 0px;
}

.headerContainer.listMode {
    margin: 20px 0px 0px 0px;
}

.headerList {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    /* margin: 30px 20px 20px 55px; */
    margin: 0px 0px 0px 30px;

}
@media only screen and (max-width: 768px) {
    .headerList {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: center; /* Center items on smaller screens */
        gap: 20px; /* Reduce the gap between items on smaller screens */
    }
    .headerDesc {
        width:100%;
        /* margin-left: 20px; */

    }
 
.headerSearch {   
    width: 100% !important;
    height: 300px !important;
    margin-left: 10px;
    flex-direction: column;
    justify-content: space-around;
    position:relative !important;

}

.options {
    top: 20px;
    right: 10px;
}
.rdrCalendarWrapper {
  
    left: 0px !important;
    top : 159px !important;
}
}
.headerListItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerListItem.active {
    /* border: 1px solid #0f7cba; */
    border:none;
    background-color:#0f7cba;
    padding: 10px;
    border-radius: 20px;
}

.headerDesc {
    margin: 20px 0px;
}

.headerBtn {
    background-color: #70bbe9;
    color: white;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.headerSearch {
    height: 30px;
    background-color: white;
    border: 3px solid #febb02;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0;
    border-radius: 5px;
    position: absolute;
    bottom: -25px;
    width: 100%;
       max-width: 1024px; 
}

.headerSearchItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.headerIcon {
    color: lightgray;
}

.headerSearchInput {
    border: none;
    outline: none;
}

.headerSearchText {
    color: lightgray;
    cursor: pointer;
}

.date {
    position: absolute;
    top: 53px;
    z-index: 2;
}

.options {
    z-index: 2;
    position: absolute;
    top: 53px;
    right: 178px;
    background-color: white;
    color: black;
    border-radius: 5px;
}

.optionItem {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: black;
}

.optionCounterButton {
    width: 30px;
    height: 30px;
    border: 1px solid #70bbe9;
    color: #70bbe9;
    cursor: pointer;
    background-color: white;
}

.optionCounterButton:disabled {
    cursor: not-allowed;
}

.rdrCalendarWrapper {
    color: #000000;
    font-size: 12px;
    display: flex;
    left: 270px;
}