.hotelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
  background-color: #f0f0f0;
}
@media only screen and (max-width: 768px) {
  .hotelAddress {
    width: 230px;
    margin-left: 20px;
  }
  .geodir-category-location a {
    float: none;
    text-align: center;}  .mt-rating-percentage-wrapper {
    width: 350px !important;
    
  }
  div#mt-rating-stars-parent .mt-rating-stars {
    width:100% !important;
  }
  .containers {
    margin: -230px auto 0 auto !important;
  }
  .headerList {
    display: none !important;
  }
  .header-sec-link {
    display: none !important;
  }
  .hotelImgWrapper {
    width: 100% !important;
  }

  .bookNow {
    position: relative !important;
    max-width: 280px !important;
    margin-left: 20px;
  }
  .hotelDistance {
    margin-top: 30px;
    width: 400px;
    margin-left: 20px;
  }
  .hotelTitle {
    width: 400px !important;
    margin-left: 20px;
    color: #030b54;
  }
  .hotelDesc {
    margin-left: 20px;

    width: 380px !important;
  }

  .HotelSeeAvialability_Description_Most_popular_category {
    width: 400px !important;
    margin-left: 20px;
  }

  .hotelDetails {
    flex-direction: column;
  }
  .hotelDetailsPrice {
    width: 380px !important ;
    margin-left: 10px  !important ;
    margin-top: 10px  !important ;
    margin-bottom: 20px  !important ;
  }
  .e50d7535fa {
    margin-left: 20px;
  }
}
.hotelWrapper {
  gap: 10px;
  margin-top: 70px;
  max-width: 1200px;
  position: relative;
  width: 100%;
}
.hotelImgWrapper {
  width: 33%;
}
.headerList1 {
  display: flex;
  gap: 40px;
  margin-bottom: -50px;
}

.bookNow {
  position: absolute;
  top: 10px;
  right: 0;
  border: none;
  padding: 10px 20px;
  background-color: #70bbe9;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.hotelTitle {
  font-size: 24px;
  width: 600px;
}

.hotelAddress {
  font-size: 12px;
  /* display: flex; */
  /* align-items: center; */
  gap: 10px;
}

.hotelDistance {
  color: #70bbe9;
  font-weight: 500;
}

.hotelPriceHighlight {
  color: #008009;
  font-weight: 500;
}

.hotelImages {
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
}

.hotelImg {
  width: 330px;
  height: 200px;
  margin: 5px;
  object-fit: cover;
  cursor: pointer;
}

.hotelDetails {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.hotelDetailsTexts {
  flex: 3;
}

.hotelDesc {
  font-size: 14px;
  margin-top: 20px;
}

.hotelDetailsPrice {
  flex: 1;
  /* height: 350px ; */
  /* background-color: #ebf3ff; */
  background-color: white;

  border: 1px solid grey;
  padding: 20px;
  display: flex;
  margin-top: -20px;
  margin-left: 80px;
  margin-bottom: 10px;
  flex-direction: column;
  gap: 10px;
}

.hotelDetailsPrice > h1 {
  font-size: 18px;
  color: #555;
}

.hotelDetailsPrice > span {
  font-size: 14px;
}

.hotelDetailsPrice > h2 {
  font-weight: 300;
}

.hotelDetailsPrice > button {
  border: none;
  padding: 10px 20px;
  background-color: #70bbe9;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.slider {
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.613);
  z-index: 999;
  display: flex;
  align-items: center;
}

.sliderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderImg {
  width: 80%;
  height: 80vh;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: lightgray;
  cursor: pointer;
}

.arrow {
  margin: 20px;
  font-size: 50px;
  color: lightgray;
  cursor: pointer;
}
.HotelSeeAvialability_Main {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.HotelSeeAvialability_Conatiner {
  /* border: 1px solid blue; */
  width: 80%;
  margin: 0 auto;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}
.HotelSeeAvialability_SearchBox_ImageGrid_Conatiner {
  /* border: 1px solid rgb(10, 7, 7); */
  width: 100%;
  display: flex;
}
.HotelSeeAvialability_SearchBox_Conatiner {
  /* border: 1px solid red; */
  width: 25%;
}
.HotelSeeAvialability_ImageGrid_Conatiner {
  /* border: 1px solid red; */
  width: 75%;
}
.HotelSeeAvialability_ImageGrid_Deatils {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.HotelSeeAvialability_ImageGrid_Image {
  /* border: 1px solid red; */
  margin: 5px;
}
.HotelSeeAvialability_Description_Property_Highlights_Main {
  width: 100%;
  margin-top: 50px;
  display: flex;
  /* padding: 10px; */
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Property_Highlights_content {
  /* border: 1px solid red; */
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 0 auto;
  padding: 10px;
  gap: 8px;
  background-color: #ebf3ff;
}
.HotelSeeAvialability_Property_Highlights_content div {
  /* border: 1px solid red; */
  margin: 5px;
}
.HotelSeeAvialability_Description {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  gap: 10px;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Property_Highlights {
  width: 30%;

  color: #262626;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
  letter-spacing: normal;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Availability_Main {
  width: 100%;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Availability_Change_Search {
  width: 70%;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Availability_Form {
  width: 100%;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Availability_Form_Top_Navbar {
  width: 100%;
  display: flex;
  background-color: #0562e4dc;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.925);
  padding: 10px;
  position: sticky;
  z-index: 1;
  top: 0;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Availability_Form_Top_Navbar_content {
  width: 80%;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Availability_Form_Data_Container {
  width: 80%;

  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_Availability_Form_Will_Reserve {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* border: 1px solid red;  */
  padding: 5px;
}
.HotelSeeAvialability_ImageGrid_Deatils_Top {
  display: flex;
  margin: 5px 5px 0 5px;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_ImageGrid_Deatils_Bottom {
  margin: 0 5px 0 5px;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_ImageGrid_Deatils_Middle {
  display: flex;
  justify-content: space-between;
  margin: 0 5px 0 5px;
  /* border: 1px solid red; */
}
.HotelSeeAvialability_ImageGrid_Deatils_Top_right {
  width: 30%;
  font-size: 25px;
  color: rgb(0, 89, 255);
}
.HotelSeeAvialability_ImageGrid_Deatils_Top_right div {
  display: flex;
  justify-content: space-between;
}
.HotelSeeAvialability_ImageGrid_Deatils_Top_left {
  width: 70%;
}
.HotelSeeAvialability_Description div {
  color: #262626;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
  letter-spacing: normal;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}
.HotelSeeAvialability_Property_Highlights_location {
  display: flex;
}
.HotelSeeAvialability_Description_Most_popular_category {
  display: flex;
  flex-direction: column;
}
.HotelSeeAvialability_Description_Most_popular_category_content_top {
  display: flex;
  margin-top: 5px;

  padding: 5px;
  justify-content: space-between;
}
.HotelSeeAvialability_Description_Most_popular_category_content_top_content {
  display: flex;
}
.HotelSeeAvialability_Description_Most_popular_category_content_top_content
  div {
  color: green;
}
.HotelSeeAvialability_Property_Highlights_Room_with_content {
  display: flex;
}
.HotelSeeAvialability_Availability_Form_Top_Navbar_content_roomtype {
  width: 30%;
  /* border: 1px solid white; */
}
.HotelSeeAvialability_Availability_Form_Top_Navbar_content_sleep {
  width: 10%;
  /* border: 1px solid white; */
}
.HotelSeeAvialability_Availability_Form_Top_Navbar_content_Today_price {
  width: 20%;
  /* border: 1px solid white; */
}
.HotelSeeAvialability_Availability_Form_Top_Navbar_content_Your_Choice {
  width: 25%;
  /* border: 1px solid white; */
}
.HotelSeeAvialability_Availability_Form_Top_Navbar_content_Your_Select_rooms {
  width: 10%;
  /* border: 1px solid white; */
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room_roomtype {
  /* border: 1px solid rgb(9, 52, 245); */
  display: flex;
  flex-direction: column;
  width: 30%;
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room_roomtype_Top {
  /* border: 1px solid rgb(9, 52, 245); */
  display: flex;
  margin-top: 5px;
  flex-direction: column;
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room {
  /* border: 2px solid rgb(225, 9, 245); */
  display: flex;
  padding: 8px;
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room_roomtype_Top_data {
  display: flex;
  justify-content: space-around;
}
.HotelSeeAvialability_Right_tick {
  color: rgb(4, 172, 4);
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room_sleeps_select_rooms {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: space-between;
  /* border: 1px solid rgb(0, 115, 223); */
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room_sleeps_select_rooms_Top {
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: space-around;
  /* border: 1px solid rgb(1, 96, 238); */
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room_sleeps_select_rooms_Top
  div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room_sleeps_select_rooms_bottom {
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: space-around;
  /* border: 1px solid rgb(1, 96, 238); */
}
.HotelSeeAvialability_Availability_Form_Data_Delux_double_room_sleeps_select_rooms_bottom
  div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.HotelSeeAvialability_Availability_Form_Data_Superior_double_room {
  display: flex;
  padding: 8px;
}
.HotelSeeAvialability_Availability_Form_Data_Delux_Family_room {
  display: flex;
  padding: 8px;
}
.HotelSeeAvialability_Availability_Form_Data_Delux_Triple_room {
  display: flex;
  padding: 8px;
}
.HotelSeeAvialability_Availability_Form_Data_Family_Suite_room {
  display: flex;
  padding: 8px;
}
.HotelSeeAvialability_Availability_Form_Data_person_icons {
  font-size: 20px;
}
.HotelSeeAvialability_Availability_Form_Data_Container_reserve {
  display: flex;
  /* border: 1px solid rgb(14, 13, 13); */
}
.HotelSeeAvialability_Availability_Form_below_banner {
  border: 1px solid rgb(255, 255, 255);
}
.HotelSeeAvialability_Availability_Banner_Below_FourReasons_Choose {
  border: 1px solid rgb(255, 255, 255);
}
.HotelSeeAvialability_Availability_Guest_Reviews_Main {
  border: 1px solid rgb(255, 255, 255);
}
.HotelSeeAvialability_Availability_Facilities_At_Hotels {
  border: 1px solid rgb(255, 255, 255);
}
.HotelSeeAvialability_Availability_House_Rules {
  border: 1px solid rgb(255, 254, 254);
}
.HotelSeeAvialability_Availability_The_Fine_Print {
  /* border: 1px solid rgb(14, 13, 13); */
  display: flex;
  justify-content: space-between;
}
.HotelSeeAvialability_Availability_House_Rules_top {
  /* border: 1px solid rgb(14, 13, 13); */
  display: flex;
  justify-content: space-between;
}
.HotelSeeAvialability_Availability_Facilities_At_Hotels_Top {
  /* border: 1px solid rgb(14, 13, 13); */
  display: flex;
  justify-content: space-between;
}
.HotelSeeAvialability_Availability_FAQs {
  /* border: 1px solid rgb(14, 13, 13); */
  display: flex;
}
.HotelSeeAvialability_Availability_FAQs_left {
  width: 30%;
  background-color: #ebf3ff;
  /* border: 1px solid rgb(14, 13, 13); */
}
.HotelSeeAvialability_Availability_FAQs_right {
  width: 70%;
  /* border: 1px solid rgb(14, 13, 13); */
}
.HotelSeeAvialability_Availability_Guest_Reviews_Top {
  /* border: 1px solid rgb(14, 13, 13); */
  display: flex;
  justify-content: space-between;
}
.HotelSeeAvialability_Availability_Guest_Reviews_Middle {
  /* border: 1px solid rgb(14, 13, 13); */
  display: flex;
  width: 25%;
  margin-left: 5px;
  justify-content: space-between;
}
.All_image_screenshot {
  width: 100%;
  /* border: 1px solid rgb(14, 13, 13); */
}
.All_Coontent_below_form_data {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  gap: 15px;
  padding: 5px;
}
.page-section {
  margin: 0 0 var(--bui_spacing_8x);
  padding: 0;
}

.page-section--inner-spacing {
  margin: 0 0 var(--bui_spacing_4x);
}

.page-section--inner-spacing:last-child {
  margin: 0;
}

.d1a624a1cc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.d1a624a1cc .c56ea7427a {
  margin: 0 var(--bui_spacing_3x) var(--bui_spacing_3x) 0;
}

.a7e70905d3 {
  display: flex;
  flex-wrap: wrap;
  gap: var(--bui_spacing_3x);
}

.a7e70905d3 .c56ea7427a {
  flex: calc(50% - var(--bui_spacing_3x));
}

.c1f85371f5 {
  display: flex;
  align-items: center;
}

.f8e1212c4d {
  display: flex;
  align-self: center;
}

.c7b8885eb0 {
  margin-right: var(--bui_spacing_1x);
}

.c207318488 {
  margin-right: var(--bui_spacing_2x);
}

.c9bc6a17b4 {
  margin-right: var(--bui_spacing_3x);
}

.dc2d7e0744 {
  margin-right: var(--bui_spacing_4x);
}

.dc6c58be0b {
  display: inline-flex;
  flex-wrap: wrap;
}

.fe975f7f02 {
  display: inline-block;
  margin-right: var(--bui_spacing_1x);
}

.a5a5a75131 {
  display: inline-block;
  vertical-align: middle;
}

.d95e17509c {
  border-bottom: 1px dashed var(--bui_color_foreground);
  cursor: help;
}

.c274e8fe17 .a5a5a75131 {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
  text-underline-offset: var(--bui_spacing_1x);
}

.d9a5b87e39 {
  margin-right: var(--bui_spacing_1x);
}

.d9a5b87e39:last-child {
  margin-right: 0;
}

.c1a1257a9c {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.ee3e4fdef3 {
  margin-right: var(--bui_spacing_1x);
}

.eef13349fd {
  padding-top: var(--bui_spacing_4x);
}

.a5dba5cdb1 {
  margin: 0 0 var(--bui_spacing_4x);
}

.c3517dc35b {
  padding-top: var(--bui_spacing_8x);
  padding-bottom: var(--bui_spacing_8x);
}

.e1a6df845d {
  margin: 0 0 var(--bui_spacing_8x);
}

.e1a6df845d .propertyBannerSpacing {
  margin: 0 0 var(--bui_spacing_4x);
}

.e1a6df845d .propertyBannerSpacing:last-child {
  margin: 0;
}

.e1a6df845d h2 {
  padding: 0;
}

.aaa19a85b8 {
  margin-bottom: var(--bui_spacing_1x);
}

.d1ca9115fe {
  display: inline-flex;
  align-items: center;
}

.de55c5a312 {
  margin-right: var(--bui_spacing_3x);
}

.de7e8330e9 {
  margin-left: var(--bui_spacing_1x);
}

.d527ce92c6 {
  margin-left: var(--bui_spacing_8x);
}

.d527ce92c6 .d527ce92c6:not(:last-child) {
  margin-bottom: var(--bui_spacing_4x);
}

.d6969cbc27 {
  margin-left: var(--bui_spacing_8x);
}

.da08adf9d2 li {
  margin-bottom: var(--bui_spacing_1x);
}

.f1e6195c8b {
  display: block;
}

.d4f5f4db7f {
  display: inline-block;
  margin-bottom: var(--bui_spacing_8x);
}

.bd948ef1e2 {
  display: inline-block;
  margin-bottom: var(--bui_spacing_4x);
}

.c2a47324a8 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c2a47324a8 li {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--bui_spacing_2x);
}

.e0c88bc3b3 {
  font-weight: 700;
  margin-right: var(--bui_spacing_3x);
}

@media (min-width: 576px) {
  .e50d7535fa {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: var(--bui_spacing_8x);
    column-gap: var(--bui_spacing_8x);
  }
}

.a6541fb018 {
  margin-bottom: var(--bui_spacing_4x);
}

/*======================================
 [ -Main Stylesheet-
  Theme: EasyBook
  Version: 1.0
  Last change: 16/08/2018 ]
++ Fonts ++
++ General Style ++
++ Typography ++
++ loader ++
++ Content Styles ++
 
/*-------------Fonts---------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900|Nunito:400,600,700,800,900&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese");

/*-------------General Style---------------------------------------*/

/*--------------Typography--------------------------------------*/
p {
  font-size: 13px;
  line-height: 24px;
  padding-bottom: 10px;
  font-weight: 500;
  /* color: #878c9f; */
}
blockquote {
  float: left;
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 15px solid #eee;
  position: relative;
}
blockquote:before {
  font-family: Font Awesome\5 Pro;
  content: "\f10e";
  position: absolute;
  color: #ccc;
  bottom: 3px;
  font-size: 43px;
  right: 6px;
  opacity: 0.4;
}
blockquote p {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  color: #494949;
}
/*------ loader-------------------------------------------------*/
.loader-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #18458b;
}
.pin,
.ajax-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  border: 4px solid;
  border-radius: 100%;
  margin: -20px 0 0 -20px;
}
.pulse,
.ajax-loader-cirle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  animation: spin 1000ms linear infinite;
}
.pulse:before,
.ajax-loader-cirle:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 4px;
  border-radius: 4px;
  background: #fff;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -12px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* ---------Content Styles--------------------------------------*/
#main {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
.containers {
  max-width: 1224px;
  width: 92%;
  margin: 0px auto 0 auto;
  position: relative;
  z-index: 5;
}
.containers1 {
  max-width: 1224px;
  width: 92%;
  margin: 0 auto 0 auto;
  position: relative;
  z-index: 5;
}
.big-container {
  max-width: 1500px;
}
.small-container {
  max-width: 1024px;
}
#wrapper {
  float: left;
  width: 100%;
  position: relative;
  z-index: 5;
  padding-top: 110px;
}
#wrapper.home-wrap {
  padding-top: 0;
}
.content {
  width: 100%;
  position: relative;
  z-index: 4;
  float: left;
  background: #fff;
  overflow: hidden;
}
.fl-wrap {
  float: left;
  width: 100%;
  position: relative;
}
.no-bg {
  background: none;
}
.full-height {
  height: 100%;
}
.full-width-wrap {
  width: 100%;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background-image: url(../../../public/img/aboutus-bg.jpg); */
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  background-repeat: repeat;
  background-origin: content-box;
}
.wave-bg,
.city-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background:url(../images/wave.png) repeat; */
}
.respimg {
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.2;
  z-index: 2;
}
.op1 {
  opacity: 0.1;
}

.op3 {
  opacity: 0.3;
}
.op7 {
  /* opacity:0.5 !important ; */
  opacity: 0 !important ;
}
.box-item {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.box-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  background: rgba(51, 51, 51, 0.41);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.box-item a.gal-link {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  color: #fff;
  font-size: 8px;
  z-index: 10;
  top: 40%;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.list-single-gallery .box-item {
  border-radius: 6px;
}
.box-item:hover a.gal-link {
  top: 50%;
  opacity: 1;
}
.box-item:hover:before {
  opacity: 1;
}
.fw-separator {
  float: left;
  width: 100%;
  margin: 25px 0;
  height: 1px;
  background: #eee;
}
.no-padding {
  padding: 0 !important;
}
/*-------------Button---------------------------------------*/
.btn3 {
  padding: 0 80px 0 30px;
  height: 44px;
  line-height: 44px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  position: relative;
  overflow: hidden;
  display: inline-block;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.1);
}
.btn3.no-shdow-btn {
  box-shadow: none;
}
.btn3.whitebtn i {
  border-color: #eee;
}
.btn3:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 0;
  right: 0;
  top: 0;
  transition: all 200ms linear;
}

.btn3:hover:after {
  width: 120%;
  background: rgba(255, 255, 255, 0.1);
}
.btn3 i {
  position: absolute;
  right: 0;
  width: 50px;
  height: 44px;
  line-height: 44px;
  border-left: 1px solid rgba(255, 255, 255, 0.21);
  text-align: center;
  top: 0;
}
.btn3.float-btn {
  float: left;
  margin: 10px 0 0;
}
.btn3.flat-btn {
  color: #fff;
}
.down-btn {
  float: left;
  padding: 15px 20px;
  margin-right: 15px;
  margin-top: 15px;
  color: #fff;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
  font-size: 12px;
  border-radius: 4px;
}
.down-btn i {
  padding-right: 10px;
  font-size: 16px;
}
.list-author-widget-text .btn3 {
  display: inline-table;
  margin-top: 16px;
}
.list-single-btn {
  display: inline-block;
  padding: 15px 35px;
  background: #fff;
  margin-top: 90px;
  border-radius: 30px;
  position: relative;
  border: 1px solid #eee;
  font-size: 14px;
}
.list-single-btn i {
  padding-left: 10px;
}
.load-more-button {
  display: inline-block;
  color: #fff;
  padding: 15px 70px;
  margin-top: 20px;
  border-radius: 6px;
  font-weight: 700;
  background: #18458b;
  box-shadow: 0px 0px 0px 7px rgba(204, 204, 204, 0.4);
}
.load-more-button i {
  float: right;
  margin-left: 18px;
  top: 2px;
  position: relative;
  animation-name: spin;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
/*-------------accordion---------------------------------------*/
.accordion {
  float: left;
  width: 100%;
  position: relative;
}
.accordion a.toggle {
  border: 1px solid #eee;
  padding: 18px 50px 18px 30px;
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  color: #666;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  margin-bottom: 15px;
}
.accordion a.toggle span {
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: 60px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.accordion a.toggle span:before,
.accordion a.toggle span:after {
  content: "";
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.accordion a.toggle span:before {
  width: 10px;
  height: 2px;
  margin-top: -1px;
  margin-left: -5px;
}
.accordion a.toggle span:after {
  width: 2px;
  height: 10px;
  margin-top: -5px;
  margin-left: -1px;
}
.accordion a.toggle.act-accordion {
  color: #fff;
  border-color: transparent;
}
.accordion a.toggle.act-accordion span:after {
  height: 0;
}
.accordion-inner {
  display: none;
  float: left;
  width: 100%;
  position: relative;
  padding: 20px 30px 15px;
  background: #fff;
  margin: 0 0 15px;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #eee;
}
.accordion-inner.visible {
  display: block;
}
/* ------Header------------------------------------------------------------ */
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid rgba(0, 0, 0, 0.21);
  -webkit-transform: translate3d(0, 0, 0);
  height: 110px;
  background: #18458b;
}
.fs-header {
  padding: 0px 50px;
}
.header-top {
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.21);
  padding: 0 0 6px 0;
  z-index: 20;
}
.header-inner {
  height: 50px;
  padding: 0 0 6px 0;
  z-index: 19;
}
.logo-holder {
  float: left;
  position: relative;
  top: 12px;
  height: 35px;
}
.logo-holder img {
  height: 100%;
  width: auto;
}
.add-hotel {
  float: right;
  padding: 0 32px;
  position: relative;
  color: #fff;
  height: 59px;
  line-height: 59px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  background: #3aaced;
}
.add-hotel span {
  padding-left: 4px;
  font-weight: 700;
}
.show-reg-form {
  float: right;
  color: #fff;
  cursor: pointer;
  margin-right: 40px;
  position: relative;
  top: 0;
  height: 60px;
  line-height: 60px;
  font-size: 13px;
  font-weight: 400;
}
.show-reg-form:before {
  content: "";
  position: absolute;
  left: -20px;
  width: 1px;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.21);
}
.show-reg-form i {
  padding-right: 10px;
}
.show-search-button {
  color: #fff;
  float: right;
  position: relative;
  width: 172px;
  top: 0;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
  border-right: 1px solid rgba(0, 0, 0, 0.21);
  box-sizing: border-box;
}
.wishlist-link {
  color: #fff;
  float: right;
  position: relative;
  width: 60px;
  top: 0;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.21);
  box-sizing: border-box;
}
.wishlist-link:hover i {
  font-weight: 800;
}
.wishlist-link span.wl_counter {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 100%;
  z-index: 2;
  font-size: 10px;
  color: #fff;
}
.wishlist-wrap {
  position: absolute;
  top: 100%;
  right: -20px;
  opacity: 0;
  visibility: visible;
  display: none;
  width: 400px;
  height: 320px;
  overflow: auto;
  padding: 25px 30px;
  background: #fff;
  z-index: -1;
  border-radius: 0 0 6px 6px;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  box-shadow: 0px 1px 15px 1px rgba(113, 106, 202, 0.1);
}
.vis-wishlist {
  opacity: 1;
  visibility: visible;
  right: 0;
}
.wishlist-wrap .box-widget-content {
  padding: 0;
}
.show-search-button i {
  padding-left: 19px;
  position: relative;
  top: 1px;
}
.show-search-button i.vis-head-search-close:before,
.wishlist-link.scwllink i:before {
  font-family: Font Awesome\5 Pro;
  content: "\f00d";
}
.show-search-button:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background: rgba(0, 0, 0, 0.21);
}
.lang-wrap,
.currency-wrap {
  float: right;
  margin-right: 40px;
  position: relative;
  top: 10px;
}
.currency-wrap {
  margin-right: 0px;
  top: 12px;
}
.lang-tooltip,
.currency-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  z-index: 105;
  background: #fff;
  padding: 40px 0 12px;
  border-radius: 4px;
  border: 1px solid #eee;
  border-top: none;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.1);
}
.show-lang,
.show-currency-tooltip {
  color: #fff;
  cursor: pointer;
  width: 100px;
  float: right;
  padding: 10px;
  position: relative;
  z-index: 106;
  font-size: 12px;
  border: 1px solid transparent;
}
.show-currency-tooltip span {
  position: relative;
  float: left;
}
.show-currency-tooltip i {
  padding-right: 8px;
  float: left;
  position: relative;
  top: 1px;
}
.show-lang i,
.show-currency-tooltip span i {
  position: absolute;
  right: 10px;
  top: 50%;
  line-height: 10px;
  height: 10px;
  margin-top: -5px;
}
.show-currency-tooltip span i {
  right: -30px;
}
.show-lang img,
.lang-tooltip li img {
  position: relative;
  border-radius: 0px;
}
.show-lang img {
  margin-right: 4px;
}
.show-lang span {
  padding: 0 10px 0 6px;
  position: relative;
  z-index: 2;
}
.show-lang .fa-angle-down {
  position: relative;
  top: 2px;
}
.lang-wrap:hover .lang-tooltip,
.currency-wrap:hover .currency-tooltip {
  visibility: visible;
  opacity: 1;
}
.lang-wrap:hover .show-lang span,
.currency-wrap:hover .show-currency-tooltip span {
  color: #666;
}
.lang-tooltip li,
.currency-tooltip li {
  float: left;
  width: 100%;
  position: relative;
  text-align: left;
  color: #fff;
  border-radius: 4px;
  padding: 6px 14px;
}
.lang-tooltip li:hover,
.currency-tooltip li:hover {
  background: #18458b;
}
.currency-tooltip li {
  margin-bottom: 4px;
  padding-left: 10px;
}
.currency-tooltip li:last-child {
  margin-bottom: 0;
}
.currency-tooltip li i {
  margin-right: 8px;
}
.lang-tooltip li img {
  margin-right: 10px;
}
.lang-tooltip li:hover a,
.currency-tooltip li:hover a {
  color: #fff;
}
.lang-tooltip li a,
.currency-tooltip li a {
  color: #566985;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
}
.header-search {
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  padding: 20px 30px 30px;
  background: #fff;
  z-index: 18;
  border-bottom: 1px solid #eee;
  opacity: 0;
  margin-top: 30px;
  visibility: hidden;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.vis-head-search {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.header-search-input-item label,
.range-slider-title,
.col-list-search-input-item label,
.leave-rating-holder .leave-rating-title {
  float: left;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  color: #878c9f;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.header-search-input-item input[type="text"],
.col-list-search-input-item input[type="text"] {
  float: left;
  width: 100%;
  border: 1px solid #eee;
  padding: 12px 32px 12px 33px;
  border-radius: 6px;
  background: #f7f9fb;
  height: 42px;
  line-height: 42px;
}
.header-search-input-item input[type="text"]:focus,
.col-list-search-input-item input[type="text"]:focus {
  background: #fff;
}
.header-search-input-item.in-loc-dec:before,
.col-list-search-input-item.in-loc-dec:before,
.header-search-input-item.date-parent:before {
  font-family: Font Awesome\5 Pro;
  content: "\f107";
  position: absolute;
  color: #fff;
  bottom: 10px;
  font-size: 15px;
  right: 14px;
  font-weight: 500;
  z-index: 120;
  transition: all 0.2s ease-in-out;
}
.not-vis-arrow {
  z-index: 520;
}
.not-vis-arrow:before {
  display: none;
}
.header-search-input-item input[type="number"],
.col-list-search-input-item input[type="number"] {
  border-radius: 6px;
  background: #fff;
}
.header-search-input-item .quantity-item,
.col-list-search-input-item .quantity-item {
  float: left;
  width: 33.3%;
}
.header-search-input-item-icon {
  position: absolute;
  bottom: 14px;
  left: 13px;
  z-index: 100;
  font-weight: 600;
  font-size: 14px;
}
.header-search-button {
  float: left;
  width: 100%;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  color: #fff;
  border: none;
  border-radius: 4px;
  position: relative;
  top: 20px;
  left: 0;
  outline: none;
  cursor: pointer;
}
.header-search-button i {
  padding-left: 10px;
}
.header-search-input-item.location a,
.col-list-search-input-item.location a {
  position: absolute;
  bottom: 12px;
  right: 16px;
  z-index: 500;
}
.close-header-search {
  position: absolute;
  width: 100px;
  height: 26px;
  right: 50%;
  margin-right: -50px;
  line-height: 26px;
  bottom: -27px;
  color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
.search-opt-wrap-container {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
}
.search-input-item {
  float: left;
  width: 50%;
  padding: 0 16px;
}
.search-input-item.midd-input {
  width: 30%;
  padding: 0 15px;
}
.search-input-item.small-input {
  width: 20%;
  padding: 0 18px 0 15px;
}
.home-btn {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  line-height: 50px;
  color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.21);
  border-left: 1px solid rgba(0, 0, 0, 0.21);
  box-sizing: border-box;
}
.home-btn a {
  color: #fff;
}
/* ------Navigation------------------------------------------------------------ */
.nav-holder {
  float: left;
  position: relative;
  opacity: 1;
  left: 0;
  visibility: visible;
  z-index: 20;
}
.nav-holder nav {
  position: relative;
  float: right;
}
.nav-holder nav li {
  float: left;
  position: relative;
  margin-right: 6px;
  height: 50px;
}
.nav-holder nav li ul {
  margin: 30px 0 0 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-width: 150px;
  top: 50px;
  left: 0;
  z-index: 1;
  padding: 10px 0;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;
}
.nav-holder nav li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin: 0;
}
.nav-holder nav li ul li ul {
  top: -10px;
  left: 100%;
  margin-left: 25px;
  margin-top: 0;
  max-width: 150px;
}
.nav-holder nav li ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-right: 0px;
}
.nav-holder nav li ul li {
  width: 100%;
  float: left;
  height: auto;
  position: relative;
}
.nav-holder nav li a {
  float: left;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  text-transform: none;
  line-height: 25px;
  letter-spacing: 0px;
  color: #fff;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
}
.dark-header .nav-holder nav li a {
  color: #fff;
}
.nav-holder nav li a i {
  padding-left: 6px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.nav-holder nav li a:hover i {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.nav-holder nav li ul a {
  color: #566985;
  float: left;
  width: 100%;
  font-weight: 700;
  text-align: left;
  padding: 5px 15px;
  font-size: 12px;
}
.dark-header .nav-holder nav li ul a {
  color: #000;
}
nav li ul a:before,
nav li ul a:after {
  display: none;
}
.nav-button-wrap {
  float: left;
  height: 34px;
  width: 34px;
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  top: 8px;
  display: none;
}
.nav-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 20px;
  margin-left: -11px;
  margin-top: -6px;
}
.nav-button span {
  float: left;
  width: 100%;
  height: 2px;
  background: #fff;
  margin-bottom: 4px;
  border-radius: 4px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.header-user-menu {
  float: right;
  position: relative;
  top: 8px;
  margin-right: 50px;
}
.header-user-name {
  position: relative;
  float: left;
  cursor: pointer;
  color: #fff;
  transition: 0.2s;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 10px;
  font-weight: 700;
  font-size: 12px;
}
.header-user-name:before,
.qty-dropdown-header:before,
.main-search-input-item_small:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0d7";
  position: absolute;
  color: #fff;
  bottom: 1px;
  font-size: 13px;
  right: -26px;
  font-weight: 800;
  transition: all 0.2s ease-in-out;
  z-index: 20;
}
.main-search-input-item_small:before,
.qty-dropdown-header:before {
  bottom: 16px;
  right: 20px;
}
.qty-dropdown-header:before {
  bottom: -2px;
}
.header-user-name span {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  overflow: hidden;
  top: 0;
  left: -50px;
}
.header-user-name span img {
  width: 100%;
  height: 100%;
}
.header-user-menu ul {
  margin: 10px 0 0 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-width: 150px;
  top: 60px;
  left: -50px;
  z-index: 1;
  padding: 10px 0;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;
}
.header-user-name.hu-menu-visdec:before {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.header-user-menu ul.hu-menu-vis {
  opacity: 1;
  visibility: visible;
  top: 40px;
}
.header-user-menu ul li {
  float: left;
  width: 100%;
  padding: 4px 0;
}
.header-user-menu ul li a {
  color: #50596e;
  float: left;
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  padding: 6px 15px;
}
/*------footer -------------------------------------------------*/
.main-footer,
.footer-inner,
.sub-footer,
.top-footer {
  float: left;
  width: 100%;
  position: relative;
}
.main-footer {
  background: #18458b;
  z-index: 3;
  overflow: hidden;
}
.footer-inner {
  padding: 70px 0;
  z-index: 2;
  border-top: 2px solid rgba(0, 0, 0, 0.21);
}
.footer-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background-image:url(../images/bg/4.jpg); */
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  background-repeat: repeat;
  opacity: 0.04;
  background-origin: content-box;
}
.sub-footer {
  background: #061b41;
  padding: 34px 0 20px;
  z-index: 3;
}
.copyright {
  float: left;
  color: #fff;
  font-size: 12px;
}
.subfooter-nav {
  float: right;
  margin-right: 50px;
}
.subfooter-nav li {
  float: left;
  margin-right: 20px;
}
.subfooter-nav li a {
  color: rgba(255, 255, 255, 0.61);
  font-weight: 400;
  font-size: 12px;
}
.subfooter-lang {
  float: right;
  padding-left: 70px;
  position: relative;
  height: 25px;
}
.subfooter-show-lang {
  float: left;
  color: #fff;
  position: relative;
  cursor: pointer;
}
.subfooter-show-lang i {
  margin-left: 10px;
}
.subfooter-lang:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* background:url(../images/map.png) no-repeat center; */
  width: 52px;
  height: 25px;
  opacity: 0.8;
}
.subfooter-lang-tooltip {
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  padding: 15px 0;
  background: #18458b;
  right: 0;
  width: 80%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  visibility: hidden;
  opacity: 0;
}
.subfooter-lang:hover .subfooter-lang-tooltip {
  visibility: visible;
  opacity: 1;
}
.subfooter-lang-tooltip li {
  float: left;
  width: 100%;
  margin-bottom: 6px;
}
.subfooter-lang-tooltip li a {
  text-align: right;
  float: left;
  width: 100%;
  padding: 0 10px;
  font-weight: 500;
}
.footer-carousel-title {
  float: left;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  top: 40px;
  font-size: 14px;
}
.footer-fw-widget {
  padding-bottom: 30px;
  margin-bottom: 70px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
}
.footer-carousel-item img {
  width: 100%;
  height: auto;
}
.footer-carousel-item a {
  opacity: 0.6;
  float: left;
}
.footer-widget h3 {
  font-size: 14px;
  float: left;
  text-transform: uppercase;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  color: #fff;
  font-weight: 600;
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
  padding-bottom: 25px;
}
.footer-social {
  float: left;
  margin-top: 20px;
}
.footer-social span {
  float: left;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  padding-right: 30px;
  position: relative;
  top: 8px;
  font-size: 14px;
}
.footer-social ul {
  float: left;
}
.footer-social li {
  float: left;
  margin-right: 14px;
}
.footer-social li a {
  float: left;
  border-left: none;
  font-size: 13px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.11);
  border-radius: 4px;
}
.footer-menu {
  margin-top: 20px;
}
.footer-menu li {
  float: left;
  text-align: left;
  padding-right: 16px;
  position: relative;
}
.footer-menu li:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  width: 4px;
  height: 4px;
  right: 6px;
  border-radius: 100%;
}
.footer-menu li:last-child:before {
  display: none;
}
.footer-menu li a {
  color: #eee;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica", "Arial", sans-serif;
}
.footer-menu li a:hover {
  color: rgba(255, 255, 255, 0.41);
}

.no-border {
  border: none !important;
}
.footer-list li {
  display: block;
  text-align: left;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}
.footer-list li a {
  color: #fff;
}
.footer-list li:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0da";
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 11px;
  transition: all 200ms linear;
  color: #4db7fe;
}
.footer-list li:hover:before {
  left: 4px;
}
.contact-btn {
  float: left;
  color: #fff;
  padding: 15px 35px;
  font-size: 12px;
  margin-top: 50px;
  font-weight: 600;
  border-radius: 4px;
  position: relative;
  top: 28px;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.1);
}
.contact-btn i {
  padding-left: 15px;
  color: rgba(255, 255, 255, 0.81);
  font-size: 16px;
  position: relative;
  top: 1px;
}
.customer-support-widget {
  margin-top: 50px;
  padding: 25px 30px 15px 45px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.04);
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.customer-support-widget:before {
  font-family: Font Awesome\5 Pro;
  content: "\f4fe";
  position: absolute;
  z-index: -1;
  color: #fff;
  font-size: 84px;
  opacity: 0.07;
  left: 14px;
  bottom: 8px;
}
.customer-support-widget h4 {
  float: left;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  width: 100%;
  margin-bottom: 6px;
}
.cs-mumber {
  float: left;
  text-align: left;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  position: relative;
  top: 6px;
  color: #fff;
}
.cs-mumber:hover {
  color: rgba(255, 255, 255, 0.6);
}
.cs-mumber-button {
  float: right;
  padding: 15px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  top: -14px;
  position: relative;
}
.cs-mumber-button i {
  padding-left: 10px;
}
.contact-btn:hover {
  background: #18458b;
}
.subscribe-wrap {
  padding: 90px 0;
  overflow: hidden;
}
.subscribe-header h3 {
  float: left;
  width: 100%;
  padding-bottom: 15px;
  color: #fff;
  text-align: left;
  font-weight: 600;
  font-size: 22px;
}
.subscribe-header p {
  color: rgba(255, 255, 255, 0.71);
  text-align: left;
}
.footer-widget #subscribe .enteremail {
  background: #fff;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  padding: 0 120px 0 15px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.21);
  z-index: 1;
}
.sp-bg {
  position: absolute;
  bottom: -240px;
  left: -70px;
  width: 350px;
  height: 350px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.11);
  box-shadow: 0px 0px 30px 0px rgba(50, 50, 50, 0.05);
}
.sp-bg:before {
  content: "";
  position: absolute;
  top: -50px;
  left: -50px;
  bottom: -50px;
  right: -50px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.051);
}
#subscribe {
  float: left;
  width: 100%;
  position: relative;
  top: 30px;
}
#subscribe:before {
  content: "";
  position: absolute;
  top: -9px;
  left: -9px;
  right: -9px;
  bottom: -9px;
  background: rgba(255, 255, 255, 0.18);
  z-index: -1;
  border-radius: 4px;
}
.footer-widget #subscribe-button {
  position: absolute;
  right: 0;
  width: 120px;
  height: 50px;
  line-height: 50px;
  top: 0;
  cursor: pointer;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  background: #18458b;
  color: #fff;
}
.footer-widget #subscribe-button:hover i {
  color: rgba(255, 255, 255, 1);
}
.subscribe-message {
  color: #fff;
  float: left;
  margin-top: 10px;
  text-align: left;
  font-weight: 500;
  line-height: 19px;
}
.subscribe-message i {
  padding-right: 6px;
}
.subscribe-message.valid i {
  color: #5ecfb1;
}
.subscribe-message.error i {
  color: #f00;
}
.footer-widget #subscribe-button i {
  padding-right: 6px;
}
.sub-footer .about-widget img {
  float: left;
  width: 120px;
  height: auto;
}
.footer-widget .footer-contacts-widget p {
  color: #fff;
  text-align: left;
}
.footer-widget .about-widget p {
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
}
.footer-widget .about-widget .btn3 {
  float: left;
  margin-top: 20px;
  padding: 13px 20px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
}
.footer-contacts {
  margin: 20px 0;
  padding: 15px 0;
  border-top: 1px dashed rgba(255, 255, 255, 0.21);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.21);
}
.footer-contacts li {
  float: left;
  width: 100%;
  text-align: left;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 500;
}
.footer-contacts li a,
.footer-contacts li span {
  color: rgba(255, 255, 255, 0.51);
}
.footer-contacts li a {
  color: rgba(255, 255, 255, 0.81);
  padding-left: 4px;
}
.footer-contacts li i {
  padding-right: 4px;
}
.footer-widget .widget-posts li,
.box-widget-content .widget-posts li {
  padding: 13px 0;
  margin-bottom: 10px;
  width: 100%;
  float: left;
}
.box-widget-content .widget-posts li {
  border-bottom: 1px solid #eee;
  margin-bottom: 13px;
  padding: 0 0 13px;
}
.box-widget-content .widget-posts li:last-child {
  margin-bottom: 0;
}
.footer-widget .widget-posts-img,
.box-widget-content .widget-posts-img,
.cart-details_header .widget-posts-img {
  float: left;
  width: 30%;
}
.footer-widget .widget-posts-img img,
.box-widget-content .widget-posts-img img,
.cart-details_header .widget-posts-img img {
  border-radius: 4px;
}
.footer-widget .widget-comments-img img {
  border-radius: 100%;
}
.footer-widget .widget-posts-descr,
.box-widget-content .widget-posts-descr,
.cart-details_header .widget-posts-descr {
  float: left;
  width: 70%;
  padding-left: 20px;
  text-align: left;
  position: relative;
}
.box-widget-content .widget-posts-descr a,
.cart-details_header .widget-posts-descr a {
  color: #666;
}
.box-widget-content .widget-posts-descr .geodir-category-location,
.cart-details_header .widget-posts-descr .geodir-category-location {
  margin-top: 12px;
}
.box-widget-content .widget-posts-descr .geodir-category-location a,
.cart-details_header .widget-posts-descr .geodir-category-location a {
  font-size: 10px;
  color: #878c9f;
}
.box-widget-content .widget-posts-descr .rooms-price {
  font-size: 12px;
  text-transform: uppercase;
  top: 0px;
}
.box-widget-content .widget-posts-descr .rooms-price strong {
  font-size: 9px;
}
.box-widget-content .widget-posts-descr .listing-rating,
.cart-details_header .widget-posts-descr .listing-rating {
  margin-top: 6px;
}
.box-widget-content .widget-posts-descr .listing-rating i,
.cart-details_header .widget-posts-descr .listing-rating i {
  font-size: 10px;
  margin-right: 2px;
}
.box-widget-content .widget-posts-link {
  float: left;
  position: relative;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  margin-top: 20px;
  color: #878c9f;
}
.widget-posts-link:hover {
  color: #666;
}
.box-widget-content .widget-posts-link:before {
  display: none;
}
.box-widget-content .widget-posts-link i {
  padding-left: 10px;
  font-size: 14px;
  position: relative;
  top: 2px;
}
.footer-widget .widget-posts-descr:before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 20px;
  right: 0;
  height: 1px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.21);
}
.footer-widget .widget-posts-descr a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.71);
  font-weight: 600;
  padding-bottom: 20px;
}
.footer-widget .widget-posts-descr a:hover {
  color: #fff;
}
.footer-widget .widget-posts-date {
  float: left;
  width: 100%;
  margin-top: 10px;
  color: #999;
  font-weight: 600;
  font-size: 12px;
}
.footer-widget .widget-posts-date i {
  color: rgba(255, 255, 255, 0.41) !important;
  padding-right: 10px;
}
.about-wrap {
  margin-bottom: 30px;
}
.about-wrap .list-single-main-item-title h3 {
  font-size: 24px;
}
.about-wrap p,
.about-wrap h4 {
  text-align: left;
}
.about-wrap h4 {
  font-size: 16px;
  padding: 10px 0 0;
  font-weight: 600;
  color: #adc7dd;
}
.about-wrap .section-separator {
  margin-bottom: 5px;
}
.fc-cont {
  position: absolute;
  top: 50%;
  z-index: 100;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.fc-cont.fc-cont-prev {
  left: 0;
}
.fc-cont.fc-cont-next {
  right: 0;
}
.fc-cont.lc-prev {
  left: -20px;
  font-size: 20px;
}
.fc-cont.lc-next {
  right: -20px;
  font-size: 20px;
}
/*------ section-------------------------------------------------*/
section {
  float: left;
  width: 100%;
  position: relative;
  /* padding:80px 0; */
  background: #fff;
  z-index: 1;
}
section.middle-padding {
  padding: 50px 0;
}
.small-padding {
  padding: 30px 0;
}
section.no-top-pading {
  padding-top: 0;
}
section.no-pading {
  padding-bottom: 0;
}
.section-title {
  margin-bottom: 40px;
}
.section-title h2 {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
  /* margin-left: 160px; */
  font-size: 24px;
  font-weight: 600;
  color: #566985;
  font-family: "Montserrat", sans-serif;
}
.section-title h4 {
  float: left;
  width: 100%;
  color: #566985;
  font-size: 12px;
  font-weight: 600;
}
.section-title-separator {
  position: relative;
  /* margin-left: 250px; */
  text-align: center;

  max-width: 450px;
  margin: 0 auto 10px;
}
.section-title-separator span {
  position: relative;
  display: inline-table;
  z-index: 2;
}
.section-title-separator span i {
  padding: 0 2px;
  color: #d0dbdb;
}
.section-title-separator span i:nth-child(2) {
  font-size: 16px;
}
section.parallax-section {
  padding: 110px 0;
  overflow: hidden;
}
section.video-section {
  padding: 150px 0;
  overflow: hidden;
}
section.single-par {
  padding: 140px 0;
  overflow: hidden;
}
section.big-padding {
  padding: 350px 0;
  overflow: hidden;
}
section.parallax-section.small-par {
  padding: 90px 0;
}
section.parallax-section .section-title-separator span,
.ajax-modal-title .section-title-separator span {
  padding: 0;
  background: none;
}
.section-title-separator span i,
.ajax-modal-title .section-title-separator span i {
  padding: 0 2px;
  color: #d0dbdb;
}
.section-title-separator span i:nth-child(2) {
  font-size: 16px;
}
.ajax-modal-title .section-title-separator span i {
  font-size: 10px;
}
.ajax-modal-title .section-title-separator span i:nth-child(2) {
  font-size: 13px;
}
section.parallax-section .section-title-separator:before,
.ajax-modal-title .section-title-separator:before {
  display: none;
}
/* section.parallax-section .overlay {
	opacity:0.4;
} */
.hero-section-wrap {
  margin-top: 30px;
  z-index: 30;
}
section.parallax-section .bg,
.list-single-hero .bg,
.hero-parallax .bg {
  height: 120%;
  top: 0;
}
.section-separator {
  float: left;
  width: 100%;
  position: relative;
  margin: 10px 0 15px;
}
.section-separator:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  height: 4px;
  margin-left: -25px;
  width: 50px;
  border-radius: 3px;
}
.breadcrumbs.inline-breadcrumbs {
  margin-bottom: 20px;
  padding: 19px 20px;
  background: #fff;
  border-radius: 4px;
}
.breadcrumbs.inline-breadcrumbs a,
.breadcrumbs.inline-breadcrumbs span,
.dasboard-breadcrumbs a,
.dasboard-breadcrumbs span {
  float: left;
  padding: 0 16px;
  position: relative;
  color: #888da0;
  font-size: 12px;
}
.breadcrumbs.inline-breadcrumbs span {
  color: #666;
}
.breadcrumbs.inline-breadcrumbs a:first-child,
.dasboard-breadcrumbs a:first-child {
  padding-left: 0;
}
.dasboard-breadcrumbs a,
.dasboard-breadcrumbs span {
  padding: 0 0 0 32px;
  color: #fff;
}
.dasboard-breadcrumbs span,
section.color-bg .section-title p {
  color: rgba(255, 255, 255, 0.71);
}
.section-title .breadcrumbs,
.flat-title-wrap .breadcrumbs {
  margin-top: 20px;
}
.section-title .breadcrumbs a,
.section-title .breadcrumbs span,
.breadcrumbs-hero-buttom .breadcrumbs a,
.breadcrumbs-hero-buttom .breadcrumbs span,
.flat-title-wrap .breadcrumbs span,
.flat-title-wrap .breadcrumbs a {
  color: rgba(255, 255, 255, 0.71);
  display: inline-block;
  padding: 0 16px;
  position: relative;
  font-weight: 500;
}
.section-title .breadcrumbs span,
.section-title .breadcrumbs a:hover,
section.parallax-section .section-title h2,
.breadcrumbs-hero-buttom .breadcrumbs a,
.flat-title-wrap .breadcrumbs a {
  color: #fff;
}
section.parallax-section .section-title.big-title h2 {
  font-size: 38px;
}
.section-title .breadcrumbs a:before,
.breadcrumbs-hero-buttom .breadcrumbs a:before,
.breadcrumbs.inline-breadcrumbs a:before,
.dasboard-breadcrumbs a:before,
.flat-title-wrap .breadcrumbs a:before,
.breadcrumbs-fs .breadcrumbs a:before {
  font-family: Font Awesome\5 Pro;
  content: "\f105";
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: -4px;
  margin-top: -7px;
  width: 10px;
  height: 2px;
}
.dasboard-breadcrumbs a:before {
  right: -20px;
  content: "\f0da";
}
.breadcrumbs-fs {
  background: #fff;
  border-bottom: 1px solid #eee;
  padding: 25px 0;
  overflow: hidden;
}
.breadcrumbs-fs .breadcrumbs a,
.breadcrumbs-fs .breadcrumbs span {
  float: left;
  margin-right: 15px;
  padding-right: 15px;
  color: #878c9f;
  font-size: 12px;
}
.breadcrumbs-fs .breadcrumbs:before {
  font-family: Font Awesome\5 Pro;
  content: "\f14c";
  position: absolute;
  right: 0;
  top: -4px;
  font-size: 22px;
  font-weight: 100;
  color: #adc7dd;
}
.breadcrumbs-hero-buttom .breadcrumbs a:before {
  right: 10px;
}
.breadcrumbs-hero-buttom {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.21);
}
.list-single-hero-price {
  float: right;
  font-size: 11px;
}
section.color-bg .section-title h2,
.list-single-hero-price,
.header-sec-link a,
.flat-title-wrap h2 {
  color: #fff;
}
.list-single-hero-price span {
  color: #5ecfb1;
  font-size: 24px;
  font-weight: 600;
  padding-left: 20px;
  position: relative;
  top: 2px;
}
.breadcrumbs-hero-buttom .breadcrumbs {
  float: left;
  position: relative;
  top: 10px;
}
.breadcrumbs-hero-buttom .breadcrumbs a,
.breadcrumbs-hero-buttom .breadcrumbs span {
  float: left;
  padding: 0 32px 0 0;
}
section.color-bg .section-title .breadcrumbs {
  margin-top: 0;
  margin-bottom: 10px;
}
section.color-bg .section-title .breadcrumbs a:before {
  background: #fff !important;
}
.header-sec-link {
  position: absolute;
  /* bottom:0; */

  z-index: 10 !important;
  bottom: -185px !important;
  left: 40%;
  width: 100%;
}
.header-sec-link a {
  display: inline-block;
  font-size: 19px;
  padding: 8px 60px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.flat-title-wrap {
  padding: 20px 0;
}
.flat-title-wrap h2 {
  font-size: 30px;
  font-weight: 600;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

section.parallax-section .section-title h4,
.flat-title-wrap h4 {
  color: rgba(255, 255, 255, 0.71);
  font-size: 12px;
  font-weight: 400;
}
.no-mar-bottom {
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 1064px) {
  section.hero-section {
    padding: 100px 0;
  }
  .hero-section-wrap {
    margin-top: 300px;
  }

  @media only screen and (max-width: 540px) {
    section.hero-section {
      padding: 50px 0 100px;
    }
  }
}
.hero-parallax {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow:hidden; */
}
section.hero-section {
  padding: 100px 0;
  z-index: 100;
}
.slider-container .slider-item {
  padding: 150px 80px;
}
.home-intro h2,
.home-intro-card h3 {
  font-size: 44px;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.7) !important;
  text-align: center;
  color: white;
  /* color:#fff; */
  font-family: "Montserrat", sans-serif;
}
.home-intro-card {
  margin-top: 50px;
}
.home-intro-card .btn3 {
  margin-top: 20px;
}
.home-intro-card h3 {
  text-align: left;
  font-size: 34px;
}
.home-intro-card h5 {
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  padding-top: 10px;
}
.home-intro h3 {
  color: #fff;
  padding-top: 12px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7) !important;

  color: white;
}
.home-intro-card-counter {
  float: left;
  margin-bottom: 10px;
  font-size: 11px;
  border-radius: 4px;
  background: #18458b;
  color: #fff;
  padding: 9px 12px;
  position: relative;
  top: -4px;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}
.home-intro-card-counter.home-intro-card-counter_price {
  top: 0;
  margin-top: 10px;
}
.home-intro-card .listing-rating i {
  margin-right: 4px;
}
.home-intro-subtitle {
  padding-top: 60px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
}
.main-search-input {
  padding: 0 150px 0 0;
  border-radius: 4px;
  margin-top: 50px;
  box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0.3);
}
.main-search-input-item {
  float: left;
  width: 33.3%;
  box-sizing: border-box;
  border-left: 1px solid #eee;
  height: 50px;
  position: relative;
  background: #fff;
}
.main-search-input-item.location {
  border: none;
}
.main-search-input-item.location input,
.main-search-input-item.location {
  border-radius: 6px 0 0 6px;
}
.inpt_dec {
  position: absolute;
  left: 15px;
  top: 18px;
  z-index: 20;
}
.main-search-input-item input {
  float: left;
  border: none;
  width: 100%;
  height: 50px;
  padding: 0 10px 0 40px !important;
}
.main-search-button {
  position: absolute;
  right: 0px;
  height: 50px;
  width: 151px;
  color: #fff;
  top: 0;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}
.main-search-button i {
  padding-left: 10px;
}
.main-search-input-wrap {
  max-width: 900px;
  margin: 20px auto;
  position: relative;
}
.main-search-input:before {
  content: "";
  position: absolute;
  bottom: -40px;
  width: 50px;
  height: 1px;
  background: rgba(255, 255, 255, 0.41);
  left: 50%;
  margin-left: -25px;
}
.main-search-input-item.location a {
  position: absolute;
  top: 18px;
  right: 20px;
  z-index: 312;
}
.main-search-input-item.location a:hover {
  border-radius: 100%;
  animation: pulse 1.1s infinite ease-in-out;
}
.qty-dropdown-header {
  font-size: 12px;
  color: #666;
  text-align: left;
  font-weight: 300;
  padding-left: 15px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  position: relative;
}
.qty-dropdown-header i {
  padding-right: 10px;
}
.qty-dropdown-content {
  border: 1px solid #eee;
  background: #fff;
  padding: 15px 20px;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  display: none;
  box-sizing: border-box;
}
.qty-dropdown-content .quantity-item {
  width: 100%;
  float: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.qty-dropdown-content .quantity-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}
.qty-dropdown-content .quantity-item label {
  float: left;
  color: #666;
  font-size: 12px;
  font-weight: 300;
  position: relative;
  top: 12px;
}
.qty-dropdown-content .quantity-item label i {
  padding-right: 10px;
  font-size: 16px;
  width: 20px;
}
.qty-dropdown-content .quantity-item .quantity {
  float: right;
}
/*------ process------------------------------------------------*/
.colomn-text-title h3 {
  text-align: left;
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  padding-bottom: 10px;
}
.colomn-text-title p,
.colomn-text-list li p {
  text-align: left;
  color: rgba(255, 255, 255, 0.71);
}
.colomn-text {
  float: left;
  width: 100%;
  position: relative;
  padding: 0 40px 0 0;
}
.pad-top-column-text {
  padding: 80px;
  margin-bottom: 20px;
}
.pad-top-column-text_small {
  padding-top: 40px;
}
.colomn-text-list .btn3,
.intro-item .btn3,
.mar-top-btn {
  margin-top: 30px;
}
.hidden-section {
  overflow: hidden;
}
.intro-item h2 {
  font-size: 34px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 30px;
  position: relative;
}
.intro-item.big-text h2 {
  font-size: 44px;
  font-family: "Montserrat", sans-serif;
}
.intro-item h2:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 50px;
  height: 4px;
  border-radius: 2px;
  margin-left: -25px;
  bottom: 15px;
}
.intro-item h3 {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
.intro-item.big-text h3 {
  font-size: 18px;
}
.light-carousel .slick-slide-item {
  padding: 10px 20px;
  -webkit-transform: translate3d(0, 0, 0);
}
.hotel-card .geodir-category-img {
  border-radius: 6px;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.1);
}
.hotel-card .geodir-category-location {
  float: left;
  position: relative;
  top: 18px;
}
.hotel-card .geodir-category-opt h4 {
  position: relative;
  top: 10px;
}
.hotel-card .geodir-category-location a {
  float: left;
  color: #fff;
  font-size: 10px;
}
.hotel-card .geodir-category-opt .listing-rating {
  top: 4px;
}
/*-------------Team---------------------------------------*/
.team-box {
  float: left;
  width: 33.3%;
  position: relative;
  padding: 10px 4px;
}
.section-team .team-box {
  padding: 10px 8px;
}
.team-photo {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 6px;
}
.section-team .team-photo {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.team-info {
  float: left;
  width: 100%;
  text-align: left;
}
.section-team .team-info {
  border: 1px solid #eee;
  padding: 25px 30px 70px 30px;
  border-top: none;
  position: relative;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
}
.team-info h3,
.team-info h4 {
  float: left;
  width: 100%;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}
.team-info h4 {
  font-size: 11px;
  color: #666;
}
.team-social {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 30px;
}
.team-social ul {
  float: left;
}
.team-social li {
  float: left;
  height: 50px;
  line-height: 50px;
  margin-right: 15px;
}
.team-social li a:hover {
  color: #999;
}
.team-contact_link {
  float: right;
  width: 60px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.21);
  box-sizing: border-box;
  text-align: center;
}
.team-dec {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 26px;
  z-index: -1;
  color: #adc7dd;
}
/*------ register form------------------------------------------------*/
.main-register-wrap,
.reg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  display: none;
  -webkit-transform: translate3d(0, 0, 0);
  background: rgba(0, 0, 0, 0.81);
}
.reg-overlay {
  z-index: 1;
  background: none;
}
.hid-body {
  overflow: hidden;
}
.main-register-holder {
  max-width: 420px;
  margin: 100px auto 50px;
  position: relative;
  z-index: 5;
}
.main-register h3 {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 0;
  margin-bottom: 30px;
  font-weight: 600;
  color: #666;
  border-bottom: 1px solid #eee;
}
.main-register h3 span {
  font-weight: 800;
  font-size: 18px;
  color: #566985;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
.main-register {
  float: left;
  width: 100%;
  position: relative;
  padding: 0 0 10px;
  margin-bottom: 50px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.3);
}
.close-reg {
  position: absolute;
  top: 12px;
  right: 24px;
  border-radius: 100%;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 100;
  border: 1px solid #fff;
}
.soc-log {
  padding: 15px 0;
}
.soc-log p {
  margin-bottom: 12px;
  font-size: 12px;
  color: #454545;
}
.soc-log a {
  float: left;
  width: 100%;
  padding: 0 0 0 100px;
  color: #fff;
  height: 60px;
  line-height: 60px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-align: left;
}
.soc-log a i {
  font-size: 16px;
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
  left: 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.21);
}
.soc-log a.facebook-log {
  background: #3b5998;
}
.soc-log a.twitter-log {
  background: #55acee;
}
.soc-log a.paypal-log {
  background: #205da5;
}
.log-separator {
  margin-top: 10px;
}
.log-separator span {
  position: relative;
  width: 90px;
  display: inline-block;
  background: #fff;
  z-index: 2;
}
.log-separator:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #eee;
  z-index: 1;
}
.custom-form .log-submit-btn {
  float: left;
  padding: 13px 35px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 12px;
}
.main-register .filter-tags {
  float: left;
  margin-top: 30px;
}
.main-register .filter-tags label {
  color: #50596e !important;
}
.lost_password {
  margin-top: 34px;
  float: right;
}
.lost_password a,
.main-register .filter-tags {
  float: left;
  color: #50596e;
  font-size: 12px;
  font-weight: 600;
}
#tabs-container {
  float: left;
  width: 100%;
  margin-top: 10px;
  padding: 0 30px;
}
.tab {
  float: left;
  width: 100%;
}
.tabs-menu {
  float: left;
  width: 100%;
}
.tabs-menu li {
  float: left;
  width: 50%;
}
.tabs-menu li a {
  float: left;
  width: 100%;
  padding: 20px 30px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  background: #eee;
  text-align: left;
  text-transform: uppercase;
}
.tabs-menu li a i {
  padding-right: 20px;
}
.tabs-menu li.current a {
  background: #fff;
}
.tabs-menu li.current a,
.tabs-menu li a:hover {
  color: #fff;
}
/* .tab-content {
    width: 100%;
    padding: 20px 0;
    display: none;
} */
#tab-1 {
  display: block;
}
/*-------------Forms---------------------------------------*/
.custom-form {
  float: left;
  width: 100%;
  position: relative;
}
.custom-form .nice-select {
  margin-bottom: 20px;
}
.custom-form textarea,
.custom-form input[type="text"],
.custom-form input[type="email"],
.custom-form input[type="password"],
.custom-form input[type="button"] {
  float: left;
  border: 1px solid #eee;
  background: #f7f9fb;
  width: 100%;
  padding: 14px 20px 14px 45px;
  border-radius: 6px;
  color: #666;
  font-size: 13px;
  -webkit-appearance: none;
}
.custom-form textarea:focus,
.custom-form input[type="text"]:focus,
.custom-form input[type="email"]:focus,
.custom-form input[type="password"]:focus {
  background: #fff;
}
.custom-form .nice-select input {
  padding-left: 20px;
}
.custom-form input::-webkit-input-placeholder,
.custom-form textarea::-webkit-input-placeholder {
  color: #666;
  font-weight: 500;
  font-size: 13px;
}
.custom-form input:-moz-placeholder,
.custom-form textarea:-moz-placeholder {
  color: #888da0;
  font-weight: 600;
  font-size: 13px;
}
.custom-form textarea {
  height: 200px;
  resize: none;
  padding: 25px 20px;
  -webkit-appearance: none;
}
.custom-form input {
  margin-bottom: 20px;
}
.filter-tags input,
.custom-form .filter-tags input {
  float: left;
  position: relative;
  border: 2px solid #ccc;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative;
  color: #fff;
  background: #fff !important;
  -webkit-appearance: none;
}
.filter-tags input:checked:after,
.custom-form .filter-tags input:checked:after {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  font-size: 12px;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 20;
}
.filter-tags label,
.custom-form .filter-tags label {
  float: left;
  padding: 0 10px;
  position: relative;
  top: 4px;
  color: #888da0;
  font-weight: 600;
  width: auto;
}
.custom-form label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: #666;
  color: #878c9f;
  font-size: 13px;
  font-weight: 500;
}
.main-register .custom-form label {
  padding-bottom: 12px;
}
.custom-form label i {
  padding-right: 12px;
  font-size: 14px;
  position: absolute;
  top: 16px;
  left: 16px;
}
.custom-form button {
  outline: none;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.quantity {
  float: left;
  width: 65px;
  height: 42px;
  position: relative;
}
.quantity input {
  width: 100%;
  height: 42px;
  line-height: 1.65;
  float: left;
  padding: 0;
  margin: 0;
  padding-left: 10px;
  border: 1px solid #eee;
  border-radius: 4px 0 0 4px;
  margin-bottom: 0;
}
.quantity input:focus {
  outline: 0;
}
.quantity-nav {
  position: absolute;
  height: 42px;
  right: 0;
  width: 20px;
  border-right: 1px solid #eee;
}
.quantity-button {
  background: #f7f9fb;
  position: absolute;
  right: 0;
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  text-align: center;
  color: #333;
  font-size: 13px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 1.7;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
}
.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}
.quantity-button.quantity-down {
  position: absolute;
  bottom: -1px;
  height: 50%;
  border-bottom: 1px solid #eee;
}
.custom-form.no-icons input,
.custom-form.no-icons textarea {
  padding-left: 10px;
}
.custom-form.no-icons label {
  margin-bottom: 20px;
}
.pass-input-wrap span {
  position: absolute;
  right: 20px;
  cursor: pointer;
  bottom: 36px;
  z-index: 10;
  font-size: 12px;
}
.radio {
  color: #999;
  font-size: 13px;
  position: relative;
  top: -2px;
}
.radio span {
  position: relative;
  padding-left: 30px;
  top: 1px;
}
.radio span:after {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  position: absolute;
  left: 0;
  top: -3px;
  box-sizing: border-box;
}
.radio input[type="radio"] {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
.radio input[type="radio"]:checked + span {
  color: #666;
}
.radio input[type="radio"]:checked + span:before {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 4px;
  z-index: 20;
}
.fuzone {
  position: relative;
  border: 1px solid #eee;
  border-radius: 3px;
  background: #f7f9fb;
  transition: all 0.3s linear;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  min-height: 160px;
  margin-top: 0px;
  float: left;
  cursor: pointer;
}
.fuzone input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
}
.fuzone .fu-text {
  text-align: center;
  margin: 50px 0;
  font-size: 12px;
  color: #999;
  font-weight: 400;
  position: relative;
}
.fuzone .fu-text i {
  font-size: 44px;
  width: 100%;
  padding-bottom: 10px;
  transition: all 0.3s linear;
}
.fuzone:hover .fu-text i {
  transform: translateY(-10px);
}
.photoUpload-files span {
  color: #666;
  margin: 0 3px;
  padding: 3px 6px;
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 7%);
}
.book-form .quantity-item label {
  width: auto;
  position: relative;
  top: 16px;
  padding-right: 10px;
  color: #878c9f;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.book-form .quantity-item label i {
  position: relative;
  float: left;
  top: 0;
  left: 0;
  padding-right: 8px;
}
.book-form .quantity {
  float: right;
}
.book-form .quantity-item {
  margin-bottom: 20px;
  float: left;
  width: 50%;
}

.book-form .quantity-item.fcit {
  padding-left: 20px;
}
.book-form .btnaplly {
  color: #fff;
  width: 100%;
  background: #f8bb11;
  padding: 15px 0;
  border-radius: 4px;
}
.book-form .btnaplly i {
  padding-left: 10px;
}
.total-coast {
  padding: 15px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.total-coast strong {
  color: #878c9f;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 21px;
}
.total-coast span {
  float: right;
  text-align: right;
  font-size: 19px;
  font-weight: 400;
  position: relative;
  top: 3px;
}
.custom-form .total-coast input {
  width: inherit !important;
  float: left;
  border: none;
  background: none;
  height: auto;
  padding: 0;
  color: #888da0;
  font-size: 24px;
  text-align: right;
  margin-bottom: 0;
  top: -3px;
  position: relative;
  right: 10px;
}
.hid-input {
  display: none;
}
.book-form .bookdate-container label i {
  top: 42px;
}
.book-form .bookdate-container input {
  padding-left: 42px;
}
.book-form .listsearch-input-item label,
.book-form .bookdate-container label {
  margin-bottom: 12px;
}
.bookdate-container-dayscounter {
  position: absolute;
  right: 10px;
  bottom: 32px;
  z-index: 20;
  cursor: help;
}
.bookdate-container-dayscounter span {
  color: #fff;
  position: absolute;
  right: -10px;
  top: -40px;
  border-radius: 4px;
  background: #18458b;
  height: 30px;
  line-height: 30px;
  min-width: 100px;
  font-size: 11px;
  opacity: 0;
  visibility: hidden;
}
.bookdate-container-dayscounter:hover span {
  opacity: 1;
  visibility: visible;
}
.onoffswitch {
  position: relative;
  width: 83px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 20px;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "ON";
  padding-left: 21px;
  background-color: #4db7fe;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 21px;
  background-color: #4db7fe;
  color: #ffffff;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  width: 21px;
  margin: 4.5px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 49px;
  border: 2px solid #ffffff;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
.main-register .custom-form input[type="text"],
.main-register .custom-form input[type="email"],
.main-register .custom-form input[type="password"] {
  padding-left: 20px;
}
/*-------------Map---------------------------------------*/
.map-container {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fw-map {
  height: 500px;
}
.fw-map.big_map {
  height: 550px;
}
.fw-map2 #singleMap,
.fw-map2 {
  height: 500px;
}
.hidden-map-container {
  display: none;
  background: #fff;
  overflow: hidden;
}
#singleMap,
.fw-map-small-map {
  height: 420px;
  margin-bottom: 30px;
}
.hidden-map-container #singleMap {
  height: 500px;
  margin-bottom: 15px;
}
.map-container.column-map.right-pos-map {
  right: 0;
}
.map-container.column-map.left-pos-map {
  left: 0;
}
.map-container #map-main {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 990;
  overflow: hidden;
}
.map-container.column-map {
  width: calc(100% - 900px);
  position: absolute;
  overflow: hidden;
}
.map-close {
  position: absolute;
  left: 365px;
  top: 10px;
  z-index: 1100;
  cursor: pointer;
  width: 43px;
  height: 43px;
  line-height: 43px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  display: none;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.controls-mapwn {
  position: absolute;
  /* background: #fff url(../images/search-icon.png) right no-repeat; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: 350px;
  z-index: 1000;
  border: none;
  padding: 20px;
  border-radius: 4px;
}
.controls {
  margin: 10px 0 0 10px;
}
#singleMap {
  width: 100%;
  /* position:absolute ; */
  height: 300px;
  float: left;
  margin-bottom: 30px;
}
.box-widget #singleMap {
  margin-bottom: 10px;
}
.mapzoom-in,
.mapzoom-out {
  position: absolute;
  z-index: 100;
  top: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  right: 12px;
  margin-top: -84px;
  -webkit-transform: translate3d(0, 0, 0);
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.mapzoom-out {
  margin-top: -42px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.mapzoom-in {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
#singleMap .mapzoom-in,
#singleMap .mapzoom-out {
  margin-top: 0;
}
#singleMap .mapzoom-in {
  margin-top: -42px;
}
.mapzoom-in:before,
.mapzoom-out:before {
  font-family: Font Awesome\5 Pro;
  content: "\f068";
  font-size: 10px;
  font-weight: 600;
}
.mapzoom-in:before {
  content: "\f067";
}
.mapnavigation {
  position: absolute;
  bottom: 50px;
  right: 20px;
  z-index: 1030;
  width: 140px;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.mapnavigation:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 1px;
  height: 100%;
  z-index: 10;
  background: #000;
  opacity: 0.2;
}
.mapnavigation a {
  width: 70px;
  padding: 10px 0;
  color: #fff;
  float: left;
  font-size: 11px;
  background: #18458b;
}
.mapnavigation a i {
  padding: 0 5px;
  font-size: 13px;
  position: relative;
  top: 1px;
}
.mapnavigation a:hover i {
  color: #fff;
}
.cluster img {
  display: none;
}
.cluster {
  width: 40px !important;
  height: 40px !important;
}
.cluster div {
  text-align: center;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 100%;
  width: 40px !important;
  height: 40px !important;
  line-height: 38px !important;
  box-shadow: 0px 0px 0px 4px rgba(6, 27, 65, 0.3);
  border: 2px solid #fff;
  z-index: 100;
  -webkit-transition: all 300ms linear;
  transition: all 100ms 3inear;
  animation: cluster-animation 1.5s infinite;
}
.cluster div:hover {
  background: #5d6275;
}
@keyframes cluster-animation {
  0%,
  100% {
    box-shadow: 0px 0px 0px 4px rgba(6, 27, 65, 0.3);
  }
  50% {
    box-shadow: 0px 0px 0px 9px rgba(6, 27, 65, 0.1);
  }
}
.map-view-wrap {
  position: absolute;
  z-index: 211;
  left: 0;
  width: 100%;
  height: 1px;
  top: 0;
}
.map-view-wrap_item {
  position: relative;
  float: left;
  width: 350px;
  border-radius: 6px;
  background: #fff;
  padding: 25px 30px;
  top: 80px;
}
.map-view-wrap .containers {
  height: 1px;
}
.map-view-wrap .containers1 {
  height: 1px;
}
.mar-top {
  margin-top: 30px !important;
}
/*------listing------------------------------------------------*/
.grey-blue-bg {
  background: #ecf6f8;
}
.col-list-wrap {
  position: relative;
  width: 900px;
  float: left;
  background: #ecf6f8;
  min-height: 100vh;
  z-index: 50;
  left: 0;
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.17);
  transition: all 0.2s ease-in-out;
  padding-bottom: 40px;
}
.fw-col-list-wrap {
  width: 100% !important;
  box-shadow: 0px 0px 0 0px rgba(0, 0, 0, 0.27);
  background: none;
  padding-bottom: 0;
}
.fw-col-list-wrap .list-main-wrap-opt {
  padding: 0 8px;
  margin-top: 0;
}
.fh-col-list-wrap,
.center-col-list-wrap {
  width: 100%;
}
.filter-sidebar {
  padding: 25px 30px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #eee;
  z-index: 120;
}
.filter-sidebar .col-list-search-input-item {
  margin-bottom: 25px;
}
.filter-sidebar .col-list-search-input-item:last-child {
  margin-bottom: 0;
}
.filter-sidebar .col-list-search-input-item label {
  margin-bottom: 15px;
}
.filter-sidebar .col-list-search-input-item .filter-tags label {
  margin-bottom: 0;
}
.filter-sidebar .col-list-search-input-item .header-search-button {
  top: 0;
  left: 0;
}
.list-wrap-search {
  padding: 25px 0;
  background: #fff;
  z-index: 60;
  margin-bottom: 10px;
}
.list-wrap-search.lisfw {
  margin-bottom: 50px;
  border-radius: 10px;
}
.search-opt-wrap {
  margin-top: 20px;
}
.range-slider-wrap {
  margin-top: 6px;
}
.search-opt-container .filter-tags li {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  font-size: 11px;
}
.filter-tags .listing-rating i {
  padding-right: 3px;
  font-size: 10px;
}
.four-star-rating .listing-rating i:nth-child(5),
.three-star-rating .listing-rating i:nth-child(4),
.three-star-rating .listing-rating i:nth-child(5) {
  color: #ccc;
}
.filter-tags .listing-rating span {
  color: #999;
  padding-left: 6px;
}
.leave-rating-title {
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #666;
  position: relative;
  top: -3px;
}
.leave-rating input[type="radio"] {
  display: none;
}
.leave-rating label {
  font-size: 12px;
  float: right;
  letter-spacing: 4px;
  color: #facc39;
  cursor: pointer;
  transition: 0.3s;
}
.leave-rating-holder .leave-rating {
  float: left;
  margin-top: 6px;
}
.leave-rating label:hover:before,
.leave-rating label.selected:before,
.leave-rating label.min-star:before {
  font-weight: bold;
}
.leave-rating:hover input[type="radio"]:checked ~ label:before,
.leave-rating input[type="radio"]:checked ~ label:before,
.leave-rating label:hover ~ label:before {
  font-weight: bold;
}
.hidden-listing-filter {
  padding: 25px 20px 0;
  margin-top: 25px;
  border-top: 1px solid #eee;
  display: none;
}
.hidden-listing-filter-vis {
  opacity: 1;
}
.hidden-listing-filter h4 {
  float: left;
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  color: #878c9f;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.show-more-filters {
  position: absolute;
  right: 0;
  bottom: -53px;
  text-align: left;
  cursor: pointer;
  padding: 8px 17px;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  z-index: 20;
  transition: all 0.2s ease-in-out;
}
.show-more-filters i {
  padding-right: 10px;
  font-size: 9px;
  position: relative;
  top: 0;
  font-weight: 600;
}
.show-more-filters.active-hidden-opt-btn i {
  padding-right: 10px;
  top: 3px;
  color: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.list-main-wrap-title {
  padding: 20px 0;
  margin-top: 10px;
  border-bottom: 1px solid #e3e3e3;
}
.list-main-wrap-title.single-main-wrap-title {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 40px;
  border-bottom: 1px solid #eee;
}
.fw-col-list-wrap .list-main-wrap-opt .list-main-wrap-title {
  padding: 0 0 10px 0;
  margin-top: 0;
  margin-bottom: 10px;
}
.list-main-wrap-title h2 {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  float: left;
  font-family: "Montserrat", sans-serif;
  color: #888da0;
}
.list-main-wrap-opt {
  margin-top: 20px;
}
.fw-col-list-wrap .list-main-wrap-opt .list-main-wrap-title.col-title {
  margin-bottom: 20px;
}
.list-main-wrap-opt-sb {
  margin-top: 0;
}
.half-tags {
  width: 50%;
}
.price-opt,
.price-opt ul,
.price-opt-title,
.price-opt ul li {
  float: left;
}
.price-opt {
  position: relative;
  top: 10px;
}
.price-opt-title {
  color: #666;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  float: left;
}
.price-opt .listsearch-input-item {
  float: left;
  margin-left: 20px;
  position: relative;
  top: -15px;
  width: 200px;
}
.price-opt .listsearch-input-item .nice-select {
  background: #fff;
  width: 100%;
}
.grid-opt {
  float: right;
}
.grid-opt li {
  float: left;
  margin-left: 10px;
}
.grid-opt li span {
  float: left;
  font-size: 14px;
  font-weight: 900;
  color: #ccc;
  background: #fff;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid #eee;
  line-height: 36px;
  cursor: pointer;
}
.listing-item-container {
  margin-top: 20px;
  overflow: hidden;
}
.listing-item {
  float: left;
  width: 50%;
  padding: 0 8px 0 0;
  margin-bottom: 12px;
  position: relative;
}
.listing-item:nth-child(even) {
  padding: 0 0 0 8px;
}
.three-columns-grid .listing-item {
  width: 33.3%;
  padding: 0 8px !important;
}
.listing-item.has_one_column,
.slick-slide-item .listing-item {
  width: 100%;
}
.listing-item.has_one_column {
  padding: 0 !important;
}
.listing-item.has_one_column .geodir-category-listing {
  padding-left: 13px !important;
}
.listing-item.has_one_column .geodir-category-img {
  width: 40%;
  border-radius: 10px;
  top: 12px;
}
.listing-item.has_one_column .hotel-card .geodir-category-img {
  width: 100%;
  border-radius: 10px;
  top: 0;
}
.three-columns-grid .listing-item.has_one_column .geodir-category-listing {
  padding-left: 0 !important;
}
.three-columns-grid .listing-item.has_one_column .geodir-category-img {
  top: 0px;
}
.listing-item.has_one_column .geodir-category-content {
  width: 60%;
}
.slick-slide-item .listing-item {
  padding: 0 10px;
  margin-bottom: 0;
}
.geodir-category-listing {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #eee;
}
.geodir-category-content {
  padding: 20px;
  z-index: 2;
}
.geodir-category-img {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px 10px 0 0;
}
.geodir-category-img .listing-counter {
  top: 20px;
  left: 20px;
}
.geodir-category-img .listing-counter strong {
  padding-left: 4px;
}
.geodir-category-img img,
.gallery-item img {
  width: 100%;
  height: auto;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.geodir-category-img:hover img,
.gallery-item:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.listing-avatar {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 4;
}
.listing-avatar img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}
.avatar-tooltip {
  position: absolute;
  top: 2px;
  margin-top: 20px;
  opacity: 0;
  left: 100%;
  margin-left: 20px;
  min-width: 150px;
  visibility: hidden;
  padding: 9px 0;
  border-radius: 4px;
  background: #18458b;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}
.listing-avatar:hover .avatar-tooltip {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}
.geodir-category-content p {
  text-align: left;
  font-size: 12px;
  color: #999;
}
.geodir-category-content h3,
.cart-details_text .geodir-category-content-title-item h3 {
  float: left;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}
.geodir-category-content h3 a {
  color: #46a5dc;
}
.geodir-category-content-tags {
  margin-top: 10px;
}
.category-name {
  float: left;
  margin-right: 10px;
  position: relative;
  color: #999;
  top: -2px;
  border-radius: 2px;
  background: #eee;
  padding: 4px 14px;
}
.geodir-category-opt {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  padding: 10px 20px;
  cursor: pointer;
}
.geodir-category-opt h4 {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1px;
}
.geodir-category-opt h4 a {
  color: #fff;
}
.geodir-category-opt .listing-rating {
  float: left;
  position: relative;
  top: 10px;
}
.geodir-category-opt .listing-rating i {
  font-size: 10px;
  margin-right: 4px;
}
.geodir-category-opt .rate-class-name {
  float: right;
  position: relative;
  top: -6px;
}
.geodir-category-opt .rate-class-name .score {
  float: left;
  color: #fff;
  margin-right: 10px;
  font-size: 11px;
}
.geodir-category-opt .rate-class-name .score strong {
  display: block;
  text-align: right;
  margin: 5px 0 2px;
  font-style: normal;
  color: #fff;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.geodir-category-opt .rate-class-name span {
  background: #18458b;
  color: #fff;
  float: left;
  font-weight: 600;
  border-radius: 5px 5px 5px 0;
  padding: 12px;
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
}
.geodir-category-content-title {
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px dotted #ccc;
}
/* .geodir-category-content-title:before {
	font-family: 'Font Awesome 5 Pro';
	content: "\f59f";
	position:absolute;
	color:#ccc;
	top:0;
	right:0;
	font-size:25px;
	font-weight:500;
	z-index:-1;
	opacity:0.5;
} */
.geodir-category-content-title-item {
  float: left;
  width: 100%;
  position: relative;
  z-index: 1;
}
.facilities-list {
  margin: 12px 0 12px;
  list-style: none;
}
.facilities-list li {
  float: left;
  margin-right: 14px;
  cursor: pointer;
  position: relative;
}
.facilities-list li i {
  color: #adc7dd;
  font-size: 16px;
}
.facilities-list li span {
  position: absolute;
  opacity: 0;
  left: -20px;
  top: -34px;
  height: 26px;
  line-height: 26px;
  min-width: 90px;
  color: #fff;
  font-size: 11px;
  visibility: hidden;
  border-radius: 4px;
  background: #18458b;
}
.facilities-list li:hover span {
  visibility: visible;
  opacity: 1;
}
.geodir-category-footer {
  margin: 4px 0 0 0;
  padding: 18px 130px 0 0;
  border-top: 1px dotted #ccc;
}
.geodir-category-price {
  float: left;
  font-size: 11px;
  color: #999;
  font-weight: 600;
  background: #f7f9fb;
  padding: 9px 12px;
  width: 210px;
  text-align: center;
  border-radius: 4px;
  border: 1px dotted #e4e4e4;
  text-transform: uppercase;
}
.geodir-category-price span {
  font-weight: 600;
  margin-top: 6px;
  font-size: 13px;
  color: #5ecfb1;
}
.geodir-category-location a {
  float: left;
  text-align: left;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
}
.geodir-category-location a i {
  padding-right: 6px;
}
.geodir-opt-list {
  position: absolute;
  right: 0;
  width: 130px;
  top: 16px;
}
.geodir-opt-list a {
  float: right;
  width: 36px;
  height: 36px;
  margin-left: 5px;
  background: #ecf6f8;
  line-height: 40px;
  position: relative;
  color: #999;
  border-radius: 4px;
  font-size: 15px;
}
.geodir-opt-list a:hover i,
.show-search-button:hover i {
  transition: all 0.2s ease-in-out;
  animation: bounce 1s;
  color: #fff;
}
@keyframes bounce {
  0%,
  20%,
  60%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
.geodir-opt-link {
  padding-right: 6px;
}
.geodir-opt-link a {
  float: left;
  height: 36px;
  line-height: 36px;
  color: #fff;
  background: #ecf6f8;
  width: 100%;
  font-weight: 500;
  border-radius: 4px;
  text-align: left;
  padding-left: 20px;
}
.geodir-opt-link a:hover {
  background: #18458b;
}
.geodir-opt-link a i {
  position: absolute;
  right: 0;
  width: 40px;
  height: 36px;
  line-height: 36px;
  top: 0;
  font-size: 8px;
  border-left: 1px solid rgba(255, 255, 255, 0.21);
  text-align: center;
}
.geodir-opt-link a:hover {
  color: #fff;
}
.geodir-opt-tooltip {
  position: absolute;
  opacity: 0;
  right: 0;
  top: -40px;
  height: 30px;
  line-height: 30px;
  min-width: 100px;
  margin-left: -75px;
  color: #fff;
  font-size: 11px;
  visibility: hidden;
  border-radius: 4px;
}
.geodir-opt-tooltip strong {
  color: #fff;
  padding-left: 6px;
}
.geodir-opt-list a:hover .geodir-opt-tooltip {
  opacity: 1;
  visibility: visible;
}
.sale-window {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
  background: #5ecfb1;
  padding: 8px 18px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 11px;
  color: #fff;
  -webkit-transform: translate3d(0, 0, 0);
}
.sale-window:before,
.sale-window:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
}
.sale-window:after {
  border-bottom: 14px solid #5ecfb1;
  border-left: 16px solid transparent;
  margin-left: -16px;
  margin-top: 6px;
}
.sale-window:before {
  border-top: 14px solid #5ecfb1;
  border-left: 16px solid transparent;
  margin-left: -16px;
  margin-top: -8px;
}
.sale-window.big-sale:after {
  border-bottom: 14px solid #3aaced;
}
.sale-window.big-sale:before {
  border-top: 14px solid #3aaced;
}
.sale-window.big-sale {
  background: #3aaced;
}
.map-popup-wrap {
  display: block;
  width: 300px !important;
  position: relative;
}
.map-popup {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 9px 16px rgba(58, 87, 135, 0.15);
}
.map-popup-wrap:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  margin-top: -1px;
  z-index: 20;
}
.map-popup-wrap:before {
  border-color: transparent;
  border-top-color: #fff;
  border-width: 15px;
  margin-left: -15px;
}
.map-popup img {
  width: 100%;
  height: auto;
}
.listing-content {
  background: #fff;
  padding: 20px 20px;
  z-index: 20;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.listing-content:before {
  font-family: Font Awesome\5 Pro;
  content: "\f35d";
  position: absolute;
  color: #ccc;
  top: 20px;
  right: 20px;
  font-size: 25px;
  font-weight: 500;
  z-index: -1;
  opacity: 0.5;
}
.listing-title h4 {
  font-size: 16px;
  text-align: left;
  font-weight: 500;
}
.map-popup-location-price {
  position: absolute;
  left: 20px;
  top: 24px;
  padding: 6px 7px;
  font-size: 12px;
  z-index: 20;
  font-weight: 700;
  border-radius: 4px;
  overflow: hidden;
}
.map-popup-location-price strong {
  color: #fff;
  float: left;
  width: 100%;
  margin-bottom: 4px;
  font-size: 11px;
  font-weight: 400;
}
.map-popup-location-info {
  float: left;
  width: 100%;
  margin-top: 10px;
  color: #878c9f;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
}
.map-popup-location-info i {
  padding-right: 6px;
}
.map-card-rainting {
  position: absolute;
  left: 0;
  width: 100%;
  top: -40px;
  padding: 15px 20px;
  z-index: 2;
}
.map-popup-location-price:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #061b41;
  opacity: 0.5;
}
.map-card-rainting i {
  float: left;
  margin-right: 4px;
}
.infoBox-close {
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  top: 30px;
  right: 20px;
  color: #fff;
  border-radius: 6px;
  z-index: 20;
  cursor: pointer;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
.infoBox-close:hover,
.header-search-button:hover i,
.contact-btn:hover i,
.header-sec-link a:hover i {
  animation: bounce 1s;
}
.pagination {
  margin-top: 30px;
  text-align: center;
  float: left;
  width: 100%;
}
.pagination a {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 6px;
  border: 1px solid #eee;
  box-sizing: border-box;
  position: relative;
  font-size: 13px;
  color: #888da0;
  background: #fff;
  letter-spacing: 1px;
}
.pagination a.current-page,
.pagination a:hover {
  color: #fff;
}
.pagination a i {
  font-size: 12px;
}
.back-to-filters {
  position: absolute;
  right: -40px;
  width: 40px;
  height: 40px;
  top: 15px;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  z-index: -1;
  background: #18458b;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.back-to-filters span {
  visibility: visible;
  position: absolute;
  height: 40px;
  min-width: 150px;
  line-height: 40px;
  visibility: hidden;
  opacity: 0;
  top: 0;
  font-size: 12px;
  left: -150px;
  margin-top: 20px;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  font-weight: 500;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #18458b;
}
.back-to-filters:hover span {
  margin-top: 0;
  visibility: visible;
  opacity: 1;
}
.back-to-filters:hover i,
.wishlist-link:hover i {
  animation: bounce 1s;
}
.slideshow-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: 1;
}
.slideshow-item,
.slideshow-container .slick-slide {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.slick-dots {
  position: absolute;
  bottom: 120px;
  width: 100%;
  left: 0;
  z-index: 100;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 6px;
  height: 6px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: rgba(255, 255, 255, 0.41);
  border-radius: 100%;
}
.map-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  display: none;
  overflow: auto;
  -webkit-transform: translate3d(0, 0, 0);
}
.map-modal-wrap-overlay {
  position: fixed;
  top: 0;
  left: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.41);
  -webkit-transform: translate3d(0, 0, 0);
}
.map-modal-item {
  position: relative;
  max-width: 650px;
  margin: 0 auto;
  top: 25%;
  z-index: 2;
}
.map-modal-container {
  padding: 0;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}
.map-modal-container h3 {
  float: left;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  margin: 25px 0;
  color: #666;

  padding-left: 30px;
}
.map-modal-container h3 i {
  padding-right: 10px;
}
.map-modal #singleMap {
  margin-bottom: 0;
}

.map-modal-close {
  position: absolute;
  right: 30px;
  bottom: 16px;
  width: 36px;
  height: 36px;
  line-height: 38px;
  border-radius: 4px;
  color: #fff;
  z-index: 10;
  cursor: pointer;
}
.list-single-hero {
  padding: 250px 0 0;
  overflow: hidden;
}
.list-single-hero-title {
  z-index: 2;
  padding: 40px 0 30px;
}
.list-single-hero-title,
.map-card-rainting,
.geodir-category-opt,
.listing-item-cat,
.ajax-modal-title {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(6, 27, 65, 0)),
    color-stop(100%, rgba(6, 27, 65, 0.95))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(6, 27, 65, 0) 0%,
    rgba(6, 27, 65, 0.95) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(6, 27, 65, 0) 0%,
    rgba(6, 27, 65, 0.95) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(6, 27, 65, 0) 0%,
    rgba(6, 27, 65, 0.95) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(6, 27, 65, 0) 0%,
    rgba(6, 27, 65, 0.95) 100%
  );
}
.list-single-hero-title h2 {
  text-align: left;
  float: left;
  width: 100%;
  z-index: 2;
  color: #fff;
  font-size: 44px;
  font-weight: 600;
  position: relative;
  font-family: "Montserrat", sans-serif;
}
.list-single-hero-title h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 40px;
  height: 4px;
  border-radius: 4px;
}
.listing-rating-wrap {
  float: left;
  margin-bottom: 10px;
}
.list-single-hero-title .listing-rating i {
  font-size: 15px;
  margin-right: 4px;
}
.listing-rating-wrap .listing-rating,
.listing-rating-wrap span {
  float: left;
}
.listing-rating-wrap span {
  color: #fff;
  padding-right: 20px;
  font-size: 13px;
  font-weight: 600;
}
.list-single-header-contacts {
  margin-top: 40px;
}
.list-single-header-contacts li {
  float: left;
  margin-right: 18px;
}
.list-single-header-contacts li a {
  color: #fff;
  font-size: 12px;
}
.list-single-header-contacts li i {
  padding-right: 10px;
}
.list-single-hero-details {
  margin-top: 6px;
}
.list-single-hero-rating {
  float: right;
  position: relative;
  z-index: 1;
}
.list-single-hero-rating .rate-class-name {
  float: right;
  cursor: pointer;
}
.list-single-hero-rating .rate-class-name span {
  background: #18458b;
  color: #fff;
  float: left;
  font-weight: 600;
  border-radius: 7px 7px 7px 0;
  padding: 22px;
  font-size: 24px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.21);
}
.list-single-hero-rating .score {
  float: left;
  color: #fff;
  text-align: right;
  padding-right: 15px;
  margin-top: 20px;
}
.list-single-hero-rating .score strong {
  width: 100%;
  float: left;
  padding-bottom: 4px;
}
.list-single-hero-rating-list {
  position: absolute;
  bottom: 100%;
  margin-bottom: 20px;
  right: 0;
  z-index: 10;
  background: rgba(6, 27, 65, 0.45);
  padding: 15px;
  overflow: hidden;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.list-single-hero-rating:hover .list-single-hero-rating-list {
  visibility: visible;
  opacity: 1;
  margin-bottom: 10px;
}
.list-single-hero-rating-list .rate-item {
  margin-bottom: 10px;
}

.flat-hero-container {
  background: #fff;
  padding: 25px 30px 30px;
  border-radius: 4px;
}
.flat-hero-container .listing-rating-wrap {
  margin: 0;
  top: 11px;
  position: relative;
}
.flat-hero-container .box-widget-item-header {
  margin-bottom: 10px;
  padding-bottom: 30px;
}
.flat-hero-container .box-widget-item-header h3 {
  font-size: 18px;
}
.flat-hero-container .list-single-hero-price,
.list-single-hero-header .list-single-hero-price {
  color: #666;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding: 5px 0 20px;
  text-align: left;
}
.list-single-hero-header .list-single-hero-price {
  padding: 25px 30px;
  margin-bottom: 0;
  border: none;
  background: #ecf6f8;
  color: #999;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 11px;
}
.list-single-hero-header .list-single-hero-price span {
  padding-left: 50px;
}
.flat-hero-container .list-single-hero-price:before,
.list-single-hero-header .list-single-hero-price:before {
  font-family: Font Awesome\5 Pro;
  content: "\f4c0";
  position: absolute;
  color: #ccc;
  top: 1px;
  font-size: 35px;
  right: 0px;
  opacity: 0.4;
  font-weight: 500;
}
.list-single-hero-header .list-single-hero-price:before {
  top: 1px;
  font-size: 55px;
}
.list-single-hero-header .geodir-category-location {
  margin-top: 15px;
}
.flat-hero-container .review-score-detail {
  width: 100%;
  padding: 0;
}
.flat-hero-container .rate-class-name-wrap {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding: 0px 0 20px;
}
.flat-hero-container .rate-class-name-wrap a {
  float: right;
  width: 40%;
  padding: 14px 0;
  border-radius: 4px;
  font-size: 11px;
  position: relative;
  top: 18px;
  color: #fff;
  font-weight: 400;
}
.flat-hero-container .rate-class-name-wrap a i {
  padding-left: 10px;
}
.flat-hero-container .rate-class-name {
  float: left;
  width: 60%;
}
.flat-hero-container .rate-class-name span {
  background: #18458b;
  color: #fff;
  float: left;
  font-weight: 600;
  border-radius: 7px 7px 7px 0;
  padding: 22px;
  font-size: 24px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.21);
  margin-right: 10px;
}
.flat-hero-container .rate-class-name .score {
  text-align: left;
  padding-left: 18px;
  color: #666;
  font-size: 12px;
}
.flat-hero-container .rate-class-name .score strong {
  display: block;
  padding: 18px 0 6px 0;
}
.flat-hero-container .reviews-score-wrap {
  padding: 0 0 15px;
  margin-bottom: 10px;
}
.review-score-detail-list .rate-item {
  margin-bottom: 11px;
}
.rate-item-title {
  margin-bottom: 6px;
  text-align: left;
  color: #fff;
  font-size: 12px;
}
.review-score-detail-list .rate-item-title,
.review-score-detail-list .rate-item-percent {
  color: #666;
}
.rate-item-bg {
  height: 6px;
  background: #fff;
  border-radius: 8px;
  float: left;
  width: calc(100% - 30px);
  overflow: hidden;
}
.review-score-detail-list .rate-item-bg {
  background: #eee;
  height: 10px;
}
.rate-item-line {
  float: left;
  height: 6px;
  border-radius: 4px;
}
.review-score-detail-list .rate-item-line {
  height: 10px;
}
.rate-item-percent {
  position: absolute;
  top: 14px;
  right: 0;
  color: #fff;
}
.list-single-hero-links {
  float: right;
  position: relative;
  top: 20px;
}
.lisd-link {
  float: left;
  margin-left: 10px;
  padding: 10px 20px;
  border: 1px dashed rgba(255, 255, 255, 0.21);
  background: rgba(255, 255, 255, 0.11);
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}
.lisd-link i {
  padding-right: 10px;
}
.lisd-link:hover {
  border-color: transparent;
}
.list-single-main-container {
  padding-left: 60px;
}
.list-single-main-item {
  padding: 30px 30px;
  background: #fff;
  margin-bottom: 20px;
  width: 90%;
  border-radius: 4px;
  margin-top: 40px;
  border: 1px solid #f2f2f2;
}
article .list-single-main-item,
article.post-article .list-single-main-item:last-child {
  margin-bottom: 0;
}
article.post-article .list-single-main-item {
  margin-bottom: 20px;
}
.list-single-main-item-title h3 {
  color: #183c7d;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}
.list-single-main-item-title h3 a {
  color: #334e6f;
}
.list-single-main-item-title {
  margin: 0 0 25px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
}
.list-single-hero-header .list-single-main-item-title {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.list-single-main-item-title .listing-rating {
  float: left;
  margin-top: 10px;
}
.list-single-hero-header .list-single-main-item-title h3 {
  font-size: 24px;
}

.list-single-hero-header .list-single-main-item-title:before {
  display: none;
}
.list-single-main-item-title.no-dec-title {
  border: none;
  margin-bottom: 0;
  padding-bottom: 15px;
}
.list-single-main-item-title.no-dec-title:before {
  display: none;
}
.list-single-main-item p {
  text-align: left;
  color: #878c9f;
}
.listing-features li {
  float: left;
  width: 33.3%;
  text-align: left;
  margin-bottom: 15px;
  font-size: 13px;
  color: #888da0;
  font-weight: 600;
  position: relative;
  padding-left: 30px;
}
.listing-features li i {
  position: absolute;
  left: 0;
  top: 0;
}
.tags-stylwrap a {
  float: left;
  padding: 10px 25px;
  border-radius: 2px;
  color: #888da0;
  font-size: 12px;
  background: #ecf6f8;
  font-weight: 500;
  margin-right: 6px;
}
.tags-stylwrap.sb-tags a {
  color: #fff;
  margin-bottom: 6px;
}
.box-widget-item {
  margin-bottom: 30px;
}
.box-widget-item-header {
  padding: 0 0 20px;
  margin: 0 0 25px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #eee;
  position: relative;
}
.box-widget-item-header:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0d7";
  position: absolute;
  right: 0;
  top: 2px;
  color: #adc7dd;
}
.box-widget-item-header h3 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #183c7d;
}
.box-widget-item-header h3 i {
  padding-right: 12px;
  font-size: 16px;
  color: #999;
}
.box-widget {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
  float: left;
  width: 100%;
}
.no-bor-rad {
  border-radius: 0;
}
.box-widget-content {
  float: left;
  width: 100%;
  position: relative;
  padding: 25px 30px 30px;
}
.box-widget-content.no-pad-bottom {
  padding-bottom: 0;
}
#weather-widget {
  float: left;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.box-widget-list {
  display: block;
}
.box-widget-list i {
  padding-right: 10px;
}
.box-widget-list li {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 15px;
  color: #666;
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-size: 12px;
}
.box-widget-list li span {
  min-width: 80px;
  float: left;
}
.box-widget-list li a {
  margin-left: 20px;
  color: #878c9f;
}
.list-widget-social {
  float: left;
  margin-top: 10px;
}
.list-widget-social li {
  float: left;
  margin-right: 10px;
}
.list-widget-social li a {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}
.banner-wdget {
  overflow: hidden;
  border-radius: 6px;
}
.banner-wdget .overlay {
  opacity: 0.3;
}
.banner-wdget-content {
  padding: 90px 30px 70px;
  z-index: 10;
}
.banner-wdget-content h4 {
  font-weight: 500;
  color: #fff;
  font-size: 18px;
}
.banner-wdget-content h4 span {
  color: #5ecfb1;
  font-weight: 400;
}
.banner-wdget-content a {
  font-weight: 600;
  color: #fff;
  padding: 12px 30px;
  display: inline-table;
  margin-top: 15px;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}
.banner-wdget-content a:hover {
  background: #24324f;
}
.banner-wdget-content .countdown-item {
  width: 25%;
  float: left;
  color: #fff;
}
.banner-wdget-content .countdown-item p {
  font-size: 10px;
  color: #fff;
}
.banner-wdget-content .countdown-item span {
  font-size: 24px;
  font-weight: 700;
  font-style: italic;
}
.banner-wdget-content .countdown {
  margin-top: 15px;
}
.list-single-main-media {
  overflow: hidden;
  margin-bottom: 20px;
}
.box-widget-author-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding: 0 0 40px 80px;
}
.box-widget-author-content {
  padding: 10px 0 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.box-widget-author-content p {
  text-align: left;
}
.box-widget-author:before {
  font-family: Font Awesome\5 Pro;
  content: "\f4fc";
  position: absolute;
  color: #ccc;
  top: 10px;
  font-size: 35px;
  right: 0px;
  opacity: 0.4;
  font-weight: 500;
}
.box-widget-author-title a {
  float: left;
  text-align: left;
  color: #666;
  position: relative;
  font-size: 14px;
  margin-top: 10px;
  padding-bottom: 6px;
}
.box-widget-author-title span {
  float: left;
  width: 100%;
  font-size: 10px;
  text-align: left;
  color: #999;
  text-transform: uppercase;
}
.box-widget-author-title-img {
  position: absolute;
  left: 0;
  width: 60px;
  top: 0;
  height: 100%;
}
.box-widget-author-title-img img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.box-widget.widget-posts li {
  padding: 8px 0;
  margin-bottom: 19px;
  border-bottom: 1px solid #eee;
}
.box-widget.widget-posts li:last-child {
  margin-bottom: 0;
}
.box-widget.widget-posts .widget-posts-img {
  float: left;
  width: 28%;
}
.box-widget.widget-posts .widget-posts-img img {
  border-radius: 4px;
  width: 100%;
  height: auto;
}
.box-widget.widget-posts .widget-posts-descr {
  float: left;
  width: 66%;
  margin-left: 6%;
  text-align: left;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 4px;
}
.box-widget.widget-posts .widget-posts-descr a {
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 20px;
  color: #878c9f;
}
.box-widget.widget-posts .widget-posts-date {
  float: left;
  width: 100%;
  margin-top: 14px;
  font-weight: 600;
  color: #999;
  font-size: 11px;
}
.box-widget.widget-posts .widget-posts-date i {
  padding-right: 10px;
}
.box-image-widget {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.box-image-widget:last-child {
  margin-bottom: 0;
}
.box-image-widget-media {
  float: left;
  width: 35%;
}
.box-image-widget-media a {
  float: left;
  width: 100%;
  margin-top: 8px;
  padding: 8px 0;
  color: #fff;
  border-radius: 2px;
  font-size: 11px;
}
.box-image-widget-details {
  float: left;
  width: 65%;
  padding-left: 18px;
  text-align: left;
}
.box-image-widget-details h4 {
  padding-bottom: 6px;
  color: #666;
}
.box-image-widget-details h4 span {
  color: #5ecfb1;
  font-size: 11px;
  padding-left: 20px;
}
.box-image-widget-details p {
  font-size: 12px;
}
.box-image-widget-media img {
  width: 100%;
  height: auto;
}
.pricerange span {
  float: left;
  margin-right: 20px;
  color: #878c9f;
  font-weight: 700;
}
.pricerange {
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  color: #5ecfb1;
}
.claim-widget-link {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #eee;
}
.claim-widget-link span,
.claim-widget-link a {
  float: left;
  text-align: left;
  font-weight: 500;
}
.claim-widget-link a:hover {
  color: #24324f;
}
.claim-widget-link span {
  color: #878c9f;
  margin-right: 20px;
}
.rooms-item {
  margin-bottom: 25px;
  border-bottom: 1px solid #eee;
}
.rooms-item:last-child {
  margin-bottom: 0;
}
.rooms-media {
  float: left;
  width: 40%;
  position: relative;
  overflow: hidden;
  border-radius: 6px 6px 6px 0;
}
.rooms-media img {
  width: 100%;
  height: auto;
}
.rooms-details {
  float: left;
  width: 60%;
  padding: 5px 0 10px 20px;
}
.rooms-details-header {
  text-align: left;
  padding-bottom: 20px;
}
.rooms-price {
  position: absolute;
  right: 0;
  top: 0;
  color: #5ecfb1;
  font-size: 19px;
  font-weight: 600;
}
.rooms-price strong {
  padding-left: 3px;
  font-size: 10px;
  color: #999;
}
.rooms-details-header h3 {
  color: #334e6f;
  padding-bottom: 6px;
  font-size: 20px;
  font-weight: 700;
}
.rooms-details-header h5 {
  font-size: 14px;
  font-weight: 700;
}
.rooms-container {
  margin-top: 20px;
}
.rooms-details .facilities-list {
  margin-top: 20px;
  margin-bottom: 0;
}
.facilities-list .btn3 {
  float: right;
  margin: 0;
  color: white !important;
  top: -18px;
  height: 40px;
  line-height: 40px;
}
.facilities-list .btn3 i {
  height: 40px;
  color: white;
  line-height: 40px;
}
.list-single-facts {
  background: #fff;
}
.single-facts {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}
.single-facts .inline-facts-wrap {
  width: 25%;
  float: left;
  padding: 15px;
}
.single-facts .inline-facts {
  position: relative;
  padding: 20px 30px;
  background: #ecf6f8;
  border-radius: 6px;
}
.single-facts .inline-facts i {
  font-size: 54px;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.4;
  z-index: 1;
}
.single-facts .inline-facts .num {
  font-size: 34px;
  color: #999;
  font-weight: 700;
  text-align: left;
}
.single-facts .inline-facts h6 {
  text-align: left;
  color: #888da0;
  font-weight: 300;
  padding-top: 10px;
}
.home-facts .inline-facts .num,
.single-facts .inline-facts h6 {
  position: relative;
  z-index: 2;
}
.home-facts {
  float: left;
  width: 25%;
  padding: 0 25px;
}
.home-facts .num {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 44px;
}
.home-facts h6 {
  color: rgba(255, 255, 255, 0.71);
  font-size: 13px;
  font-weight: 500;
}
.home-facts h6 {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  color: rgba(255, 255, 255, 0.91);
}
.home-facts h6:before {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  height: 1px;
  background: #fff;
  margin-left: -25px;
  opacity: 0.4;
  width: 50px;
}
.list-single-facts .inline-facts-wrap {
  width: 25%;
  float: left;
  padding: 20px 0;
  border-left: 1px solid #e2e2e2;
  box-sizing: border-box;
  background: #f9f9f9;
}
.list-single-facts .inline-facts-wrap:first-child {
  border: none;
}
.list-single-facts .inline-facts-wrap i {
  font-size: 34px;
  width: 100%;
  padding-bottom: 10px;
}
.list-single-facts .inline-facts-wrap .milestone-counter,
.list-single-facts .inline-facts-wrap h6 {
  display: inline-block;
  color: #666;
}
.list-single-facts .inline-facts-wrap h6 {
  padding-left: 4px;
  color: #999;
  font-size: 12px;
}
.cart-detai ls {
  background: #f9f9f9;
}
.cart-details_text {
  float: left;
  width: 100%;
  border: 1px solid #eee;
  border-top: none;
}
.cart-details_header {
  border: 1px solid #eee;
  display: inline-table;
  padding: 15px 20px;
}
.cart-details_text .geodir-category-content-title-item h3 a {
  color: #666;
}
.cart_list li {
  text-align: left;
  padding: 15px 20px;
  color: #666;
  font-size: 12px;
  border-bottom: 1px solid #eee;
}
.cart_list li:last-child {
  border-bottom: 0;
}
.cart_list li span,
.cart-total strong {
  float: right;
}
.cart_list li span strong {
  padding-left: 6px;
  color: #5ecfb1;
}
.cart-total {
  padding: 15px 20px;
  background: #f7f9fb;
  float: left;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 6px;
}
.cart-details {
  margin-bottom: 15px;
}
.cart-total span {
  float: left;
  color: #878c9f;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.cart-total strong {
  font-size: 20px;
  font-weight: 400;
}
#progressbar {
  margin-bottom: 30px;
  counter-reset: step;
  width: 100%;
  float: left;
  box-sizing: border-box;
}
#progressbar li {
  list-style-type: none;
  color: #666;
  font-size: 12px;
  width: 25%;
  float: left;
  text-align: left;
  position: relative;
  padding: 0 0 25px;
}
#progressbar li span {
  padding-right: 10px;
}
#progressbar li:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 4px;
  border-radius: 4px;
  background: #eee;
  z-index: 1;
}
#progressbar li:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  left: 0;
  height: 4px;
  z-index: 2;
  border-radius: 4px;
  transition: all 200ms linear;
}
#progressbar li.active:before {
  width: 99%;
}
.bookiing-form-wrap .custom-form form fieldset:not(:first-of-type) {
  display: none;
}
.next-form {
  float: right;
}
.previous-form {
  float: left;
  padding: 0 30px 0 80px;
  height: 44px;
  line-height: 44px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  position: relative;
  overflow: hidden;
}
.previous-form i {
  position: absolute;
  left: 0;
  width: 50px;
  height: 44px;
  line-height: 44px;
  border-right: 1px solid rgba(255, 255, 255, 0.21);
  text-align: center;
  top: 0;
}
.previous-form:hover:after {
  width: 100%;
}
.previous-form:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 0;
  left: 0;
  top: 0;
  transition: all 200ms linear;
}
.success-table-header {
  text-align: left;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0 0 70px;
}
.success-table-header i.decsth {
  color: #5ecfb1;
  position: absolute;
  font-size: 54px;
  left: 0;
  top: 0;
}
.success-table-header h4,
.success-table-header p {
  float: left;
}
.success-table-header a {
  font-size: 12px;
  color: #fff;
  padding: 12px 25px;
  float: right;
  border-radius: 30px;
  position: relative;
  top: -14px;
}
.success-table-header a i {
  padding-right: 8px;
  font-size: 15px;
}
.log-massage {
  padding: 10px 0;
  text-align: left;
  font-size: 12px;
  color: #666;
}
/*------ Reviews------------------------------------------------*/
.reviews-score-wrap {
  padding: 0 0 25px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.list-single-hero-header .reviews-score-wrap {
  padding: 0;
  border-bottom: none;
  margin-bottom: 0;
}
.review-score-total,
.review-score-detail {
  float: left;
  width: 25%;
}
.review-score-total span {
  background: #18458b;
  color: #fff;
  float: left;
  font-weight: 600;
  border-radius: 7px 7px 0 7px;
  padding: 22px 0;
  width: 100%;
  font-size: 34px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.21);
}
.review-score-total a {
  float: left;
  width: 100%;
  padding: 10px 0;
  color: #fff;
  margin-top: 10px;
  border-radius: 4px;
}
.review-score-total span strong {
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding-top: 6px;
}
.review-score-detail {
  width: 75%;
  padding-left: 20px;
}
.reviews-comments-wrap {
  display: inline-table;
  margin-top: 30px;
}
.reviews-comments-item-text .review-score-user {
  float: right;
  position: relative;
  top: -12px;
}
.reviews-comments-item-text .review-score-user span {
  background: #18458b;
  color: #fff;
  float: right;
  font-weight: 600;
  border-radius: 3px 3px 0 3px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.21);
}
.reviews-comments-item-text .review-score-user strong {
  margin-right: 12px;
  font-size: 11px;
  color: #666;
  position: relative;
  top: 10px;
}
.reviews-comments-item {
  padding: 0 0 30px 110px;
  position: relative;
  float: left;
  margin-bottom: 40px;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.reviews-comments-item.reply-comment-item {
  width: 90%;
  margin-left: 10%;
  padding: 30px 20px 30px 110px;
  border-radius: 6px;
}
.reviews-comments-item.reply-comment-item .review-comments-avatar {
  top: 30px;
}
.reviews-comments-item.reply-comment-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: -10%;
  margin-left: 30px;
  width: 40px;
  height: 40px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.reviews-comments-item:last-child {
  border: none;
  margin-bottom: 0;
}
.review-comments-avatar {
  position: absolute;
  top: 0;
  left: 10px;
  width: 80px;
  height: 80px;
}
.review-comments-avatar:before {
  content: "";
  position: absolute;
  top: 100%;
  margin-top: 20px;
  left: 0;
  margin-left: 38px;
  width: 40px;
  height: 40px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.review-comments-avatar img {
  width: 80px;
  height: 80px;
  float: left;
  border-radius: 100%;
}
.reviews-comments-item-text {
  float: left;
  width: 100%;
  position: relative;
  padding: 30px 20px;
  background: #f9f9f9;
  border-radius: 6px;
}
.reviews-comments-item-text .new-dashboard-item {
  top: 0;
}
.reviews-comments-item-text h4 {
  text-align: left;
  padding-bottom: 25px;
  float: left;
  font-size: 16px;
  font-weight: 600;
  color: #666;
}
.reviews-comments-item-text h4 a {
  color: #666;
}
.reviews-comments-item-text p {
  text-align: left;
}
.reviews-comments-item-date {
  float: left;
  margin-top: 18px;
  font-weight: 500;
  color: #999;
  width: 100%;
  text-align: left;
  padding-top: 18px;
  font-size: 12px;
  border-top: 1px solid #eee;
}
.reviews-comments-item-date span {
  float: left;
}
.reviews-comments-item-date a {
  float: right;
  font-weight: 600;
  color: #666;
}
.reviews-comments-item-date span i,
.reviews-comments-item-date a i {
  margin-right: 10px;
}
.leave-rating,
.leave-rating-title {
  float: left;
}
.leave-rating-wrap {
  float: left;
  margin: 30px 0 30px 0;
  width: 100%;
}
.leave-rating-title {
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #666;
  position: relative;
  top: -3px;
}
.review-score-form {
  margin-top: 10px;
}
.review-total,
.review-range-container {
  float: left;
}
.review-total {
  width: 30%;
  background: #f7f9fb;
  padding: 35px 0;
  border: 1px solid #eee;
  box-sizing: border-box;
  border-radius: 4px;
}
.review-total strong {
  float: left;
  width: 100%;
  margin-top: 10px;
  color: #666;
}
.custom-form .review-total span input {
  font-size: 34px;
  border: none;
  padding: 0;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1px;
}
.review-range-container {
  width: 70%;
  padding-right: 20px;
}
.review-range-item {
  display: block;
  margin-bottom: 10px;
}
.review-range-item .range-slider-title {
  width: 30%;
  margin-bottom: 0;
}
.review-range-item .range-slider-wrap {
  width: 70%;
  margin-top: 0;
  position: relative;
  top: -24px;
}
.review-range-item .range-slider-title,
.review-range-item .range-slider-wrap {
  float: left;
}
/*------ Grid Gallery------------------------------------------------*/
.listing-item-cat {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px 35px 15px;
  z-index: 10;
  text-align: left;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
}
.listing-counter {
  position: absolute;
  left: 35px;
  top: 40px;
  color: #fff;
  z-index: 10;
  font-size: 11px;
  border-radius: 4px;
  background: #18458b;
  color: #fff;
  padding: 9px 12px;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}
.listing-item-cat h3 {
  font-size: 18px;
  float: left;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 10px;
}
.listing-item-cat p {
  color: rgba(255, 255, 255, 0.71);
}
.listing-item-cat h3 a {
  color: #fff;
}
.weather-grid {
  float: left;
  position: relative;
  top: 2px;
  margin-left: 20px;
}
.gallery-items.mr-bot {
  margin-bottom: 40px;
}
.grid-item-holder,
.gallery-items {
  float: left;
  width: 100%;

  height: auto;
  position: relative;
  overflow: hidden;
}
.grid-item-holder {
  padding: 10px;
}
.grid-small-pad .grid-item-holder {
  padding: 0 4px 4px 0;
}
.gallery-item {
  width: 33.3%;
  position: relative;
  float: left;
}
.gallery-items.home-grid .gallery-item .listing-item-grid {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.gallery-item-second,
.grid-sizer-second {
  width: 66.6%;
}
.four-column .gallery-item {
  width: 25%;
}
.four-column .gallery-item.gallery-item-second {
  width: 50%;
}
.gallery-item img {
  position: relative;
  z-index: 1;
}
.list-single-main-item .gallery-items .gallery-item {
  overflow: hidden;
}
.gallery-item .box-item {
  border-radius: 0;
}
.more-photos-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 60px 0 20px;
  text-align: left;
  color: #fff;
  font-size: 10px;
  z-index: 10;
  text-transform: uppercase;
  cursor: pointer;
}
.more-photos-button span {
  padding: 0 2px;
  font-style: italic;
  font-size: 9px;
  color: rgba(255, 255, 255, 0.61);
}
.more-photos-button i {
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  line-height: 40px;
  width: 50px;
  border-left: 1px solid rgba(0, 0, 0, 0.21);
  text-align: center;
}
/*-------------Ajax modal---------------------------------------*/
.ajax-modal-container {
  position: fixed;
  top: 0;
  right: -550px;
  width: 550px;
  height: 100%;
  z-index: 119;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  background: #fff;
}
#ajax-modal {
  overflow: hidden;
  background: #fff;
}
.ajax-modal-wrap {
  opacity: 0;
}
.ajax-modal-media {
  border-radius: 20px 0 0 0;
}
.ajax-modal-media img {
  width: 100%;
  height: auto;
}
.ajax-modal-details {
  padding: 30px 45px;
  margin-top: 20px;
}
.ajax-modal-details p {
  text-align: left;
}
.ajax-modal-details h3 {
  text-align: left;
  color: #334e6f;
  padding-bottom: 26px;
  font-size: 16px;
  font-weight: 700;
}
.ajax-modal-details-box {
  width: 100%;
  float: left;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.ajax-modal-title {
  color: #fff;
  padding: 20px 0 25px;
  cursor: pointer;
  font-size: 18px;
  margin-left: 30px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.ajax-modal-photos-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 5;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  background: #18458b;
  font-size: 11px;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}
.ajax-modal-photos-btn span {
  padding: 0 4px;
}
.ajax-modal-list {
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
}
.ajax-modal-list li {
  float: left;
  width: 25%;
  padding: 12px 0;
  border-left: 1px solid #e2e2e2;
  box-sizing: border-box;
}
.ajax-modal-list li:first-child {
  border: none;
}
.ajax-modal-list li h5 {
  font-size: 11px;
  margin-left: 30px;
  color: #999;
}
.ajax-modal-list li h5 span {
  color: #666;
  padding-left: 4px;
}
.ajax-modal-list li i {
  font-size: 28px;
  margin-left: 40px;
  padding-bottom: 10px;
}
.ajax-modal-close {
  position: absolute;
  left: 40px;
  width: 40px;
  height: 40px;
  top: 25px;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  z-index: 20;
  background: #18458b;
  border-radius: 6px;
  cursor: pointer;
}
.ajax-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  display: none;
  z-index: 118;
  -webkit-transform: translate3d(0, 0, 0);
  background: rgba(0, 0, 0, 0.41);
}
.ajax-loader {
  z-index: 100;
}
/*------ Video ------------------------------------------------*/
.video-box {
  overflow: hidden;
  border-radius: 10px;
  -webkit-transform: translate3d(0, 0, 0);
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}
.video-box img {
  width: 100%;
  height: auto;
}
.video-box-btn {
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: -30px 0 0 -30px;
  background: #fff;
  color: #fff;
  z-index: 20;
  left: 80px;
  bottom: 30px;
  box-shadow: 0px 0px 0px 12px rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.video-box-btn:hover {
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
}
.video-box-title {
  position: absolute;
  bottom: 43px;
  left: 140px;
  color: #fff;
  font-size: 12px;
  z-index: 21;
  background: rgba(6, 27, 65, 0.45);
  padding: 10px 20px;
  border-radius: 4px;
}
.media-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.video-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 0;
  z-index: 1;
}
.video-container video {
  width: 100%;
}
.video-iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden !important;
  top: 0;
  left: 0;
  padding: 0 !important;
}
.video-iframe iframe {
  position: absolute;
  top: -75px;
  left: 50%;
  width: 100%;
  height: 100%;
  display: block;
}
.media-container.video-parallax {
  height: 120%;
  top: -10%;
}
.resp-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.resp-video iframe,
.resp-video object,
.resp-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.video-holder iframe {
  position: absolute;
  top: -75px;
  left: 50%;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}
.mob-bg {
  display: none;
}
/*-------------Sliders---------------------------------------*/
.slideshow-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: 1;
}
.slideshow-item,
.slideshow-container .slick-slide {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.sw-btn {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  z-index: 120;
  cursor: pointer;
  margin-top: -18px;
  box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
}
.sw-btn.swiper-button-prev {
  left: 50px;
}
.sw-btn.swiper-button-next {
  right: 50px;
}
.sw-btn:hover {
  background: #2c3b5a;
}
.single-carousel .slick-dots,
.listing-carousel .slick-dots {
  bottom: -40px;
}
.single-carousel .slick-dots li button,
.listing-carousel .slick-dots li button {
  background: #bdcbdb;
}
.list-carousel {
  padding: 0 30px;
}
.list-single-carousel-wrap {
  height: 500px;
  background: #24324f;
}
.fw-carousel .slick-slide-item {
  width: auto;
  height: 500px !important;
  float: left;
  cursor: w-resize;
}
.fw-carousel .slick-slide-item img {
  width: auto;
  height: 100% !important;
}
.list-single-carousel-wrap .slick-slide-item .box-item {
  height: 100%;
  z-index: 20;
}
.list-single-carousel-wrap .slick-slide-item .box-item:before {
  display: none;
}
.list-single-carousel-wrap .swiper-slide:first-child {
  padding-left: 0px;
}
.list-single-carousel-wrap .slick-slide-item .box-item a.gal-link {
  top: -100px;
  left: inherit;
  right: 50px;
}
.list-single-carousel-wrap .slick-slide-item .box-item:hover a.gal-link {
  top: 50px;
}
.list-single-carousel-wrap .sw-btn,
.single-slider-wrapper .sw-btn {
  top: 50%;
}
.list-carousel .sw-btn.swiper-button-prev {
  left: 8px;
}
.list-carousel .sw-btn.swiper-button-next {
  right: 8px;
}
.single-slider-wrapper img,
.inline-carousel img {
  width: 100%;
  height: auto;
}
.single-slider-wrapper .slider-nav .slick-slide-item {
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.single-slider-wrapper .slider-nav .slick-current .slick-slide-item,
.single-slider-wrapper .slider-nav .slick-slide-item:hover {
  opacity: 1;
}
.slick-dots {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
}
.inline-carousel .slick-dots {
  bottom: 20px;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 6px;
  height: 6px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: rgba(255, 255, 255, 0.81);
  border-radius: 100%;
}
/*-------------scroll-nav-wrapper---------------------------------------*/
.scroll-nav-container {
  padding-top: 110px;
}
.scroll-nav-wrapper {
  background: #fff;
  border-bottom: 1px solid #eee;
  padding: 14px 0;
  -webkit-transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.scroll-nav-wrapper .scroll-nav li {
  float: left;
  position: relative;
  overflow: hidden;
}
.scroll-nav-wrapper .scroll-nav li a {
  padding: 0 30px;
  float: left;
  height: 100%;
  height: 40px;
  line-height: 40px;
  position: relative;
  font-weight: 700;
  color: #888da0;
  font-size: 12px;
  background: #ecf6f8;
}
.scroll-nav-wrapper .scroll-nav li:last-child {
  border-radius: 0 4px 4px 0;
}
.scroll-nav-wrapper .scroll-nav li:first-child {
  border-radius: 4px 0 0 4px;
}
.scroll-nav-wrapper .scroll-nav li a.act-scrlink {
  color: #fff;
}
.scroll-nav-wrapper .show-hidden-map {
  float: right;
  position: relative;
  color: #fff;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  background: #18458b;
}
.scroll-nav-wrapper .show-hidden-map:hover {
  background: #18458b;
}
.scroll-nav-wrapper .show-hidden-map:hover i,
.scroll-nav-wrapper .show-hidden-map:hover {
  color: #fff;
}
.scroll-nav-wrapper .show-hidden-map i {
  padding-left: 10px;
}
.collage-image {
  position: relative;
  width: 352px;
  margin: 0 auto;
  height: 300px;
}
.collage-image .main-collage-image {
  position: absolute;
  right: -20px;
  bottom: -400px;
}
.images-collage-title {
  position: absolute;
  left: -70px;
  width: 180px;
  padding: 12px 0;
  z-index: 10;
  top: 70px;
  border-radius: 6px;
  font-weight: 800;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
  animation: bounce 4s ease-in-out infinite;
}
.images-collage-title span {
  color: #fff;
}
.collage-image:before {
  content: "";
  position: absolute;
  width: 250px;
  height: 250px;
  top: 50%;
  left: 50%;
  margin-top: -125px;
  margin-left: -325px;
  border: 2px dotted #fff;
  z-index: -1;
  opacity: 0.6;
  border-radius: 100%;
}
.collage-image-min {
  position: absolute;
  z-index: 10;
}
.collage-image-min:before {
  top: 63px;
  left: 90%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #fff;
  border-width: 14px;
  margin-left: 18px;
  z-index: 20;
}
.collage-image-min.cim_1 {
  bottom: 70px;
  left: 80px;
}
.collage-image-min.cim_2 {
  bottom: 190px;
  right: 80px;
}
.collage-image-min.cim_2 img {
  animation: cluster-animation 2.5s infinite;
}
.collage-image-min.cim_1 img {
  animation: cluster-animation 3.5s infinite;
}
.collage-image-min.cim_3 {
  bottom: 110px;
  right: 130px;
}
.collage-image-min img {
  position: absolute;
  width: 67px;
  height: 67px;
  border: 8px solid #fff;
  border-radius: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
}
.collage-image-input {
  position: absolute;
  bottom: -40px;
  right: -90px;
  width: 190px;
  padding: 0 40px 0 16px;
  background: #fff;
  border-radius: 6px;
  z-index: 10;
  text-align: left;
  font-size: 12px;
  color: #999;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
  box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.1);
}
.collage-image-btn {
  color: #fff;
  position: absolute;
  top: 40px;
  right: -20px;
  padding: 12px 25px;
  border-radius: 4px;
  font-size: 10px;
  box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.1);
}
.collage-image-input i {
  position: absolute;
  width: 30px;
  height: 40px;
  line-height: 40px;
  right: 0;
  top: 0;
}
/*------sahre------------------------------------------------*/
.fixed-scroll-column {
  width: 50px;
  position: absolute;
  left: 15px;
  top: 0;
  z-index: 20;
}
.share-holder.hid-share {
  float: left;
  position: relative;
  z-index: 20;
}
/* .fc-button {
  width: 50px;
  height: 50px;
  float: left;
  position: relative;
  line-height: 50px;
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 4px;
} */

/* .fc-button span {
  position: absolute;
  left: 100%;
  margin-left: 12px;
  background: #18458b;
  opacity: 0;
  visibility: hidden;
  top: 8px;
  color: #fff;
  width: 120px;
  height: auto !important;
  line-height: 1;
  font-size: 12px;
  padding: 12px 0;
  border-radius: 22px;
} */
/* .fc-button:hover span {
  opacity: 1;
  visibility: visible;
} */
.fixed-scroll-column-share-container {
  display: block;
}
.fixed-scroll-column-share-container {
  display: none;
  float: left;
  width: 100%;
  background: #f9f9f9;
  padding: 10px 0;
  margin-bottom: 4px;
}
.visshare {
  visibility: visible;
  opacity: 1;
}
.share-container .share-icon {
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  margin: 4px 0;
  position: relative;
  font-size: 13px;
  color: #888da0;
  font-weight: 400;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.fixed-scroll-column-share-container
  .share-container
  .share-container
  .share-icon {
  float: left;
  width: 50px;
  height: 50px;
  color: #ccc;
}
.share-holder.hid-share .share-container.visshare,
.bl-opt .share-holder.hid-share .share-container.visshare {
  top: -35px;
  opacity: 1;
  visibility: visible;
}
.share-icon:before {
  font-family: Font Awesome\5 Brands;
}
.share-icon-digg:before {
  content: "\f1a6";
}
.share-icon-email:before {
  content: "\f003";
}
.share-icon-flickr:before {
  content: "\f16e";
}
.share-icon-foursquare:before {
  content: "\f180";
}
.share-icon-rss:before {
  content: "\f09e";
}
.share-icon-facebook:before {
  content: "\f39e";
}
.share-icon-twitter:before {
  content: "\f099";
}
.share-icon-tumblr:before {
  content: "\f173";
}
.share-icon-linkedin:before {
  content: "\f0e1";
}
.share-icon-vimeo:before {
  content: "\f27d";
}
.share-icon-instagram:before {
  content: "\f16d";
}
.share-icon-pinterest:before {
  content: "\f231";
}
.share-icon-googleplus:before {
  content: "\f0d5";
}
.share-icon-in1:before {
  content: "\f08c";
}
.share-icon-stumbleupon:before {
  content: "\f1a4";
}
/* --------- clients - terstimonials--------------------------------------*/
.text-carousel .text-carousel-item {
  padding: 30px 65px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 0 10px;
  float: left;
  width: 100%;
  position: relative;
  margin-top: 70px;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.text-carousel .text-carousel-item:before,
.text-carousel .text-carousel-item:after {
  font-family: Font Awesome\5 Pro;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  color: #ccc;
  opacity: 0.4;
  font-size: 34px;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.text-carousel-item:before,
.pr-list-det:before {
  content: "\f10e";
  top: 20px;
  left: 15px;
}
.text-carousel-item:after,
.pr-list-det:after {
  content: "\f10e";
  bottom: 20px;
  right: 15px;
}
.text-carousel .slick-item {
  padding: 0 20px;
}
.text-carousel .slick-current .text-carousel-item {
  background: #ecf6f8;
  border-color: transparent;
}
.text-carousel .slick-dots {
  margin-top: 40px;
  bottom: -50px;
}
.text-carousel .slick-dots li {
  display: inline-block;
  margin: 0 3px;
}
.text-carousel .slick-dots li button:before {
  background: #ccc;
}
.text-carousel .slick-dots li.slick-active button {
  border-color: #ccc;
}
.text-carousel-wrap {
  margin: 0 0 50px 0;
}
.text-carousel-item p {
  font-size: 13px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  text-align: left;
}
.text-carousel-item a.testim-link {
  float: left;
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  position: relative;
  color: #666;
}
.text-carousel-item a.testim-link:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px dotted #ccc;
}
.text-carousel-item .listing-rating {
  float: right;
  width: 100%;
  margin-bottom: 12px;
}
.text-carousel-item .listing-rating i {
  float: right;
  margin-left: 10px;
  font-size: 11px;
}
.popup-avatar {
  position: absolute;
  left: 70px;
  z-index: 10;
  width: 80px;
  height: 80px;
  z-index: 20;
  border-radius: 100%;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  opacity: 0.5;
  top: -40px;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}
.text-carousel .slick-current .popup-avatar {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
.popup-avatar:before {
  content: "";
  position: absolute;
  border-radius: 100%;
  left: -8px;
  bottom: -8px;
  right: -8px;
  top: -8px;
  border: 1px solid #eee;
  z-index: 1;
  background: #fff;
}
.popup-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  position: relative;
  z-index: 2;
}
.review-owner {
  margin: 0 0 15px 0;
  float: left;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 11px;
  text-align: left;
  color: #666;
  font-family: "Poppins", sans-serif;
}
.review-owner span {
  color: #999;
}
.text-carousel-wrap .sw-btn {
  top: 100%;
  margin-top: 26px;
}
.text-carousel-wrap .sw-btn.swiper-button-prev {
  left: 50%;
  margin-left: -105px;
}
.text-carousel-wrap .sw-btn.swiper-button-next {
  right: 50%;
  margin-right: -100px;
}
/*------Dasboard------------------------------------------------*/
.dasboard-wrap {
  padding-left: 290px;
}
.dasboard-sidebar {
  position: absolute;
  left: 0;
  width: 250px;
  z-index: 20;
  top: -50px;
}
.dasboard-sidebar-content {
  padding: 40px 0 44px 0;
  border-radius: 6px;
  z-index: 20;
  overflow: hidden;
}
.log-out-btn {
  position: absolute;
  width: 100%;
  left: 0;
  height: 44px;
  bottom: 0;
  line-height: 44px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
}
.log-out-btn i {
  padding-left: 10px;
}
.dasboard-avatar {
  overflow: hidden;
  padding: 0 55px;
  position: relative;
}
.dasboard-avatar img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.dasboard-sidebar-item {
  margin: 30px 0 20px;
}
.dasboard-sidebar-item:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 30px;
  bottom: -10px;
  height: 2px;
  margin-left: -15px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.21);
}
.dasboard-sidebar-item h3 {
  color: #fff;
  font-size: 18px;
}
.dasboard-sidebar-item h3 span {
  float: left;
  width: 100%;
  padding-bottom: 10px;
  font-size: 11px;
}
.ed-btn {
  display: inline-block;
  padding: 8px 17px;
  margin: 10px 0;
  color: #fff;
  font-size: 11px;
  border-radius: 30px;
  z-index: 2;
  border: 2px solid;
  background: rgba(255, 255, 255, 0.11);
}
.dasboard-menu,
.dasboard-menu li {
  float: left;
  position: relative;
}
.dasboard-menu {
  top: 2px;
}
.flat-header {
  padding-top: 140px;
  padding-bottom: 0;
}
.adm-header {
  z-index: 100;
}
.dasboard-menu-btn {
  float: left;
  padding: 14px 20px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  border-radius: 6px 6px 0 0;
  display: none;
}
.dasboard-menu-btn i {
  padding-left: 10px;
}
.dasboard-menu li ul {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  padding: 10px 20px;
  width: 150px;
  z-index: 2;
  border: 1px solid #eee;
  border-top: none;
  opacity: 0;
  visibility: hidden;
}
.dasboard-menu li:hover ul {
  opacity: 1;
  visibility: visible;
}
.dasboard-menu li ul li {
  float: left;
  width: 100%;
  padding: 8px 0;
  position: relative;
}
.dasboard-menu li ul li span {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  line-height: 16px;
  border-radius: 100%;
  font-size: 9px;
  color: #fff;
}
.dasboard-menu li a {
  padding: 13px 20px;
  float: left;
  font-size: 12px;
  border-radius: 4px 4px 0 0;
  color: #fff;
  position: relative;
}
.dasboard-menu li ul li a {
  padding: 0;
  color: #666 !important;
  border-radius: 0;
}
.dasboard-menu li a i {
  padding-right: 6px;
}
.dasboard-menu li a span {
  display: inline-block;
  width: 16px;
  height: 16px;
  top: -1px;
  line-height: 16px;
  background: #98aab8;
  color: #fff;
  position: relative;
  margin-left: 4px;
  border-radius: 100%;
  text-align: center;
  font-weight: 300px;
  font-size: 9px;
}
.dasboard-menu li a.user-profile-act,
.dasboard-menu li a:hover {
  background: #fff !important;
  color: #666 !important;
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.07);
}
.dasboard-menu li ul li a:hover {
  box-shadow: none;
}
.user-stats {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.21);
}
.user-stats:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  border-radius: 6px;
  opacity: 0.14;
}
.user-stats li {
  float: left;
  width: 33.3%;
  padding: 15px 0;
  color: #fff;
  font-size: 11px;
  box-sizing: border-box;
  border-left: 1px solid rgba(0, 0, 0, 0.21);
}
.user-stats li:first-child {
  border: none;
}
.user-stats li span {
  float: left;
  width: 100%;
  padding-top: 10px;
  font-size: 14px;
}
.tfp-btn {
  position: absolute;
  right: 0;
  top: -110px;
  background: #fff;
  padding: 12px 40px 12px 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
  z-index: 10;
  color: #666;
  font-size: 12px;
  cursor: pointer;
}
.tfp-btn strong {
  padding-left: 5px;
}
.tfp-btn:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0d7";
  position: absolute;
  color: #fff;
  top: 50%;
  font-size: 13px;
  right: 11px;
  margin-top: -7px;
  font-weight: 800;
  transition: all 0.2s ease-in-out;
}
.tfp-btn.rot_tfp-btn:before {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.tfp-det {
  position: absolute;
  top: -70px;
  right: 0;
  z-index: 11;
  padding: 15px 30px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;
}
.tfp-det-btn {
  float: right;
  padding: 6px 12px;
  color: #fff;
  font-size: 11px;
  border-radius: 4px;
  margin-top: 3px;
}
.tfp-det.vis_tfp-det {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}
.dasboard-breadcrumbs {
  position: absolute;
  top: -100px;
  left: 0;
  z-index: 1;
}
#myChartLegend {
  float: right;
}
.chart-header {
  margin-bottom: 20px;
}
.chart-header .listsearch-input-item {
  width: 150px;
}
[class="0-legend"] {
  cursor: pointer;
  list-style: none;
  padding-left: 0;
  float: right;
}
[class="0-legend"] li {
  float: left;
  padding: 14px 25px;
  border-radius: 2px;
  color: #888da0;
  font-size: 12px;
  background: #ecf6f8;
  font-weight: 500;
  margin-left: 10px;
}
[class="0-legend"] li.hidden-lable {
  text-decoration: line-through;
}
[class="0-legend"] li span {
  border-radius: 5px;
  display: inline-block !important;
  height: 10px;
  margin-right: 10px;
  width: 10px;
}
.dashboard-list-box {
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
}
.mat-top {
  margin-top: 50px;
}
.dashboard-header {
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
}
.dashboard-header h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #fff;
}
.dashboard-list {
  float: left;
  padding: 25px 30px;
  position: relative;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.dashboard-list:nth-child(2n + 1) {
  background: #f9f9f9;
}
.dashboard-list:last-child {
  border: none;
}
.dashboard-message-avatar {
  float: left;
  margin: 10px;
  width: 70px;
  height: 70px;
}
.dashboard-message-text .btn {
  margin-top: 10px;
  margin-left: 10px;
  float: left;
  background: #5ecfb1 !important;
  padding: 12px 20px;
  font-weight: 500;
  font-size: 12px;
}
.dashboard-message-text .btn:hover {
  color: rgba(255, 255, 255, 0.71);
}
.color-bg {
  background: #3aaced;
}
.dashboard-message-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.dashboard-message-text {
  margin-left: 30px;
  text-align: left;
  padding-left: 50px;
  top: 6px;
  position: relative;
}
.dashboard-message-text h4,
.dashboard-listing-table-text h4 {
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 20px;
  color: #50596e;
}
.dashboard-message-text h4 span {
  font-weight: 500;
  font-size: 12px;
}
.reply-mail {
  text-align: left;
  font-weight: 500;
}
.reply-mail a {
  font-weight: 400;
}
.reply-mail a:hover {
  color: #999;
}
.new-dashboard-item {
  position: absolute;
  right: 30px;
  top: 28px;
  padding: 6px 15px;
  border-radius: 4px;
  color: #fff;
  background: #5ecfb1;
  z-index: 10;
}
.dashboard-listing-table-image {
  float: left;
  width: 30%;
  padding: 0 30px 0 0;
}
.dashboard-listing-table-image img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}
.dashboard-listing-table-text {
  float: left;
  text-align: left;
  width: 70%;
}
.dashboard-listing-table-text h4 a {
  color: #50596e;
}
.dashboard-listing-table-address {
  float: left;
  padding-bottom: 10px;
}
.dashboard-listing-table-address a {
  font-weight: 600;
  color: #999;
  font-size: 10px;
  text-transform: uppercase;
}
.dashboard-listing-table-address i {
  padding-right: 10px;
}
.dashboard-listing-table-opt li {
  float: left;
  margin-right: 10px;
}
.dashboard-listing-table-opt {
  margin-top: 15px;
}
.dashboard-listing-table-opt li a {
  color: #fff;
  padding: 9px 22px;
  border-radius: 4px;
  background: #5ecfb1;
  float: left;
  font-size: 12px;
}
.dashboard-listing-table-opt li a i {
  padding-left: 10px;
}
.dashboard-listing-table-opt li a.del-btn:hover {
  background: #f91942;
}
.profile-edit-header h4 span {
  color: #5ecfb1;
}
.notification {
  padding: 18px 20px;
  border-radius: 4px;
  margin-bottom: 40px;
}
.notification p {
  color: #fff;
  text-align: left;
  padding-bottom: 0;
}
.notification p a {
  color: rgba(255, 255, 255, 0.71);
  font-weight: 600;
  padding: 0 5px;
}
.notification.success {
  background: #5ecfb1;
}
.notification.waitforreview {
  background: #fbc54f;
}
.notification.reject {
  background: #ea4d37;
}
.notification-close {
  position: absolute;
  top: 50%;
  right: 20px;
  height: 20px;
  margin-top: -10px;
  color: rgba(255, 255, 255, 0.71);
}
.notification-close:hover {
  color: #fff;
}
.dashboard-list-box.activities .dashboard-list {
  padding-left: 0;
}
.dashboard-list-box.activities .dashboard-message-text {
  padding-left: 0;
}
.dashboard-list-box.activities .dashboard-message-text i {
  padding-right: 20px;
}
.dashboard-list-box.activities .new-dashboard-item {
  cursor: pointer;
}
.profile-edit-container {
  margin: 10px 0;
  float: left;
  width: 100%;
}
.profile-edit-container .custom-form label {
  float: left;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 500;
}
.profile-edit-container .custom-form label i {
  top: 42px;
}
.custom-form.no-icons input,
.custom-form.no-icons textarea {
  padding-left: 10px;
}
.pass-input-wrap span {
  position: absolute;
  right: 20px;
  cursor: pointer;
  bottom: 36px;
  z-index: 10;
}
.profile-edit-container.add-list-container {
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  float: left;
  width: 100%;
}
.profile-edit-container.add-list-container:first-child {
  margin-top: 0;
}
.add-list-media-header {
  float: left;
  width: 100%;
  padding: 15px 20px 3px;
  background: #f7f9fb;
  border: 1px solid #eee;
}
.add-list-media-header span {
  text-align: left;
  float: left;
}
.booking-details {
  padding: 6px 0;
  font-size: 12px;
}
.booking-details:last-child {
  padding-bottom: 12px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.booking-title {
  color: #999;
  padding-right: 10px;
}
.booking-text,
.booking-text a {
  color: #666;
}
.done-paid {
  color: #5ecfb1;
  text-decoration: underline;
}
.chat-box {
  padding: 50px 30px 60px;
  min-height: 300px;
}
.chat-message {
  padding-left: 70px;
  margin-bottom: 30px;
}
.chat-message:last-child {
  margin-bottom: 0;
}
.chat-message p {
  float: left;
  padding: 15px 20px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  text-align: left;
  position: relative;
}
.chat-message p:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0d9";
  position: absolute;
  top: 12px;
  left: -9px;
  font-size: 31px;
  color: #fff;
  font-weight: bold;
}
.chat-message.chat-message_user p:before {
  content: "\f0da";
  left: inherit;
  right: -9px;
}
.massage-date {
  float: left;
  font-size: 11px;
  color: #999;
  margin-bottom: 11px;
}
.massage-date span {
  position: relative;
  padding-left: 10px;
}
.massage-date span:before {
  content: "";
  position: absolute;
  left: 2px;
  width: 4px;
  height: 4px;
  top: 4px;
  z-index: 5;
  border-radius: 100%;
}
.chat-message.chat-message_user .massage-date {
  float: right;
}
.chat-message .dashboard-message-avatar {
  position: absolute;
  left: 0;
  top: 20px;
}
.chat-message.chat-message_user .dashboard-message-avatar {
  left: inherit;
  right: 0;
}
.chat-message .dashboard-message-avatar span {
  font-size: 11px;
  color: #666;
  display: inline-block;
  margin-top: 6px;
}
.chat-message.chat-message_user {
  padding-left: 0;
  padding-right: 70px;
}
.message-input {
  float: left;
  width: 100%;
  position: relative;
  border-right: 1px solid #eee;
}
.message-input textarea {
  position: relative;
  width: 100%;
  border: none;
  background: #fff;
  z-index: 1;
  resize: vertical;
  min-height: 60px;
  padding: 25px 140px 0 30px;
}
.message-input button {
  position: absolute;
  right: 20px;
  border-radius: 36px;
  width: 100px;
  height: 36px;
  line-height: 36px;
  background: none;
  border: none;
  font-size: 14px;
  top: 12px;
  z-index: 2;
  color: #fff;
  cursor: pointer;
}
.chat-contacts-item {
  float: left;
  width: 100%;
  position: relative;
  padding: 20px 20px 20px 70px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}
.chat-contacts-item .dashboard-message-avatar {
  position: absolute;
  top: 15px;
  left: 0;
}
.chat-contacts-item .chat-contacts-item-text p {
  height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  color: #888;
}
.chat-contacts-item .chat-contacts-item-text h4 {
  text-align: left;
  font-size: 12px;
  color: #666;
  padding-bottom: 4px;
}
.chat-contacts-item .chat-contacts-item-text span {
  position: absolute;
  right: 20px;
  top: 21px;
  font-size: 9px;
  color: #999;
}
.message-counter {
  position: absolute;
  left: 0;
  bottom: -2px;
  z-index: 10;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  color: #fff;
  border-radius: 100%;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.chat-contacts-item:after {
  content: "";
  position: absolute;
  right: 0;
  width: 6px;
  height: 0;
  z-index: -1;
  top: 0;
  transition: all 0.2s ease-in-out;
}
.chat-contacts-item_active:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: -30px;
  background: #ecf6f8;
  z-index: -2;
}
.chat-contacts-item.chat-contacts-item_active:after,
.chat-contacts-item:hover:after {
  height: 100%;
}
.dashboard-list-box .reviews-comments-wrap {
  padding: 0 30px;
}
.add-button {
  float: left;
  padding: 10px 15px;
  border-radius: 4px;
  color: #fff;
  font-size: 11px;
  cursor: pointer;
}
.hotel-facts input {
  width: 30% !important;
  padding-left: 10px !important;
  margin-right: 1%;
}
.act-widget-header {
  float: left;
  width: 100%;
  position: relative;
  border-radius: 6px;
  background: #f7f9fb;
  padding: 10px 20px;
  border: 1px solid #eee;
  margin-bottom: 15px;
}
.act-widget h4 {
  float: left;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  top: 16px;
  color: #999;
}
.act-widget-header .onoffswitch {
  float: right;
  top: 6px;
}
.error_message,
#success_page h3 {
  text-align: left;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
}
#success_page p {
  margin-top: 10px;
}

.booking-modal-wrap .success-table-header a {
  float: left;
  top: 0;
}
.booking-modal-wrap .success-table-header p {
  width: 100%;
}
.booking-modal-wrap,
.bmw-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  overflow-y: auto;
  -webkit-transform: translate3d(0, 0, 0);
  display: none;
}
.bmw-overlay {
  z-index: 999;
  background: rgba(0, 0, 0, 0.81);
}
.booking-modal-container {
  max-width: 1000px;
  margin: 120px auto 70px;
  position: relative;
}
.booking-modal-content {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0.3);
  margin-bottom: 50px;
  padding-right: 300px;
}
.booking-modal-info {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 300px;
  background: #eee;
  padding: 100px 30px 20px;
}
.booking-modal-info .overlay {
  opacity: 0.3;
}
.booking-modal-info_content {
  z-index: 20;
}
.booking-modal-info_content h4 {
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 800;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}
.booking-modal-info_content h4:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50px;
  height: 4px;
  margin-left: -25px;
  border-radius: 4px;
  background: #4db7fe;
}
.booking-modal-info_content ul li {
  float: left;
  width: 100%;
  padding: 6px 0;
  color: #fff;
  text-align: center;
  font-weight: 600;
}
.booking-modal-info_content ul li span {
  opacity: 0.7;
}
.booking-modal-close {
  position: absolute;
  top: 14px;
  right: 20px;
  border-radius: 100%;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 20;
}
.booking-modal-wrap .list-single-main-item {
  padding: 10px 20px;
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 0;
}
.booking-modal-wrap .soc-log {
  padding: 15px 0;
}
.bookiing-form-wrap {
  padding: 20px 30px;
}
/*------ to-top------------------------------------------------*/
.to-top {
  position: fixed;
  bottom: 120px;
  right: 20px;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  font-size: 17px;
  z-index: 116;
  cursor: pointer;
  display: none;
  border-radius: 3px;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(0, 0, 0);
}
.to-top:hover {
  background: #18458b;
  color: #fff;
}
/*------FAQ------------------------------------------------*/
.faq-nav {
  margin-bottom: 30px;
}
.faq-nav li {
  text-align: left;
  width: 100%;
  float: left;
  border-bottom: 1px solid #eee;
}
.faq-nav li a {
  color: #666;
  float: left;
  width: 100%;
  padding: 14px 12px;
  position: relative;
  font-size: 12px;
}
.faq-nav li a:hover {
  color: #999;
}
.faq-nav li a:before {
  font-family: Font Awesome\5 Pro;
  content: "\f105";
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 12px;
  color: #ccc;
}
.process-item {
  padding: 20px 30px;
  background: #fff;
  border-radius: 4px;
  position: relative;
  border: 1px solid #eee;
}
.process-item:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -100px;
  height: 1px;
  width: 100px;
  z-index: -1;
  background: #eee;
}
.process-item.nodecpre:before {
  display: none;
}
.big-pad-pr-item {
  padding: 30px 30px;
}
.process-item h4 {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
}
.process-item h4,
.process-item h4 a {
  color: #183c7d;
}
.time-line-icon {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.time-line-icon:before {
  content: "";
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  left: -20px;
  top: -50px;
  background: #ecf6f8;
  opacity: 0.7;
  z-index: 1;
}
.time-line-icon i {
  font-size: 54px;
  position: relative;
  z-index: 2;
  padding-bottom: 10px;
}
/*------404------------------------------------------------*/
.error-wrap h2 {
  font-size: 134px;
  color: #fff;
  font-weight: 900;
  letter-spacing: 10px;
}
.error-wrap p {
  color: rgba(255, 255, 255, 0.91);
  font-size: 14px;
}
.error-wrap form {
  margin: 30px auto 20px;
  max-width: 550px;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}
.error-wrap form input {
  float: left;
  width: 100%;
  border: none;
  background: #fff;
  height: 50px;
  padding: 0 100px 0 20px;
  z-index: 1;
}
.error-wrap form .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 50px;
  line-height: 50px;
  z-index: 2;
  color: #fff;
  border: none;
  cursor: pointer;
}
.error-wrap a.btn {
  display: inline-table;
  margin-top: 10px;
}
.city-bg {
  height: 325px;
  top: inherit;
  bottom: 0;
  opacity: 0.8;
  /* background:url(../images/city.png) repeat-x; */
}
.wave-bg.wave-bg2 {
  /* background:url(../images/pattern.png) repeat; */
  opacity: 0.1;
}
.cloud-anim {
  z-index: 1;
  font-size: 54px;
  opacity: 0.8;
  color: #fff;
  position: absolute;
}
.cloud-anim-bottom {
  bottom: 350px;
  right: 150px;
  -webkit-animation: animateCloud2 20s linear infinite;
  animation: animateCloud2 20s linear infinite;
}
.cloud-anim-top {
  top: 120px;
  left: 150px;
  -webkit-animation: animateCloud 35s linear infinite;
  animation: animateCloud 35s linear infinite;
}
@keyframes animateCloud {
  0% {
    left: -110px;
  }
  100% {
    left: 100%;
  }
}
@keyframes animateCloud2 {
  0% {
    right: -110px;
  }
  100% {
    right: 100%;
  }
}
@keyframes animateCloud3 {
  0% {
    left: 20px;
  }
  50% {
    left: 90%;
  }
  100% {
    left: 20px;
  }
}
@keyframes animateCloud4 {
  0% {
    right: 50px;
  }
  50% {
    right: 80%;
  }
  100% {
    right: 50px;
  }
}
@keyframes animateStars {
  0% {
    bottom: -10px;
    opacity: 0.5;
  }
  50% {
    bottom: 80%;
    opacity: 0.2;
  }
  100% {
    bottom: -10px;
    opacity: 0.5;
  }
}
@keyframes animateStars2 {
  0% {
    top: -10px;
    opacity: 0.5;
  }
  50% {
    top: 80%;
    opacity: 0.2;
  }
  100% {
    top: -10px;
    opacity: 0.5;
  }
}
/*------ price tables------------------------------------------------*/
.price-item {
  float: left;
  width: 33.3%;
  position: relative;
  z-index: 1;
}
.price-item:hover,
.best-price {
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.07);
  z-index: 2;
}
.best-price {
  top: -30px;
  transform: scale(1.05);
}
.price-item:hover {
  z-index: 3;
}
.price-head {
  float: left;
  width: 100%;
  padding: 22px 20px;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.price-head:before,
.price-head:after {
  content: "";
  position: absolute;
  bottom: -150px;
  right: -60px;
  width: 200px;
  height: 200px;
  background: #fff;
  border-radius: 100%;
  opacity: 0.2;
}
.price-head:after {
  bottom: 20px;
  right: inherit;
  left: -200px;
  width: 300px;
  height: 300px;
}
.price-head-decor {
  position: relative;
  width: 100%;
  display: inline-table;
  margin-bottom: 20px;
  padding: 20px 0;
}
.price-head-decor i {
  font-size: 74px;
  color: #fff;
  -webkit-box-reflect: below 4px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.2)));
}
.price-item:hover .price-head-decor i {
  animation: bounce 2s ease-in-out infinite;
}
.price-item:hover .price-head-decor:before {
  -webkit-animation: animateCloud3 12s ease-in-out infinite;
  animation: animateCloud3 12s ease-in-out infinite;
}
.price-item:hover .price-head-decor:after {
  -webkit-animation: animateCloud4 15s ease-in-out infinite;
  animation: animateCloud4 15s ease-in-out infinite;
}
.price-head-decor:before,
.price-head-decor:after {
  font-family: Font Awesome\5 Pro;
  content: "\f0c2";
  position: absolute;
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  z-index: 120;
  opacity: 0.5;
}
.price-head-decor.stars-dec:before,
.price-head-decor.stars-dec:after {
  content: "\f005";
}
.price-head-decor.cloud-2:before,
.price-head-decor.cloud-2:after {
  font-weight: 800;
}
.price-item:hover .price-head-decor.stars-dec:before {
  -webkit-animation: animateStars 3s ease-in-out infinite;
  animation: animateStars 3s ease-in-out infinite;
}
.price-item:hover .price-head-decor.stars-dec:after {
  -webkit-animation: animateStars2 5s ease-in-out infinite;
  animation: animateStars2 5s ease-in-out infinite;
}
.price-head-decor:before {
  left: 20px;
  bottom: -10px;
}
.price-head-decor:after {
  top: -10px;
  right: 50px;
}
.best-price .price-head,
.price-num.col-dec-2:before {
  background: #5ecfb1;
}
.price-head h3 {
  color: #fff;
  position: relative;
  z-index: 2;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  display: inline-table;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.81);
}
.price-num {
  padding: 20px 15px 0;
  overflow: hidden;
}
.price-num:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -35px;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 100%;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
}

.price-num span.curen {
  position: relative;
  top: -32px;
  padding-right: 7px;
  font-weight: 400;
  font-size: 17px;
}
.price-num-desc {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  color: #999;
  display: inline-table;
  font-size: 12px;
  margin-top: 6px;
  font-weight: 300;
  position: relative;
  overflow: hidden;
}
.mouth-cont {
  z-index: 1;
  opacity: 1;
  position: relative;
}
.year-cont {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  z-index: 2;
}
.year-cont,
.year-sale,
.price-item {
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}
.year-sale {
  position: absolute;
  top: 60px;
  right: 30px;
  font-size: 36px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.41);
  opacity: 0;
}
.year-mont .year-sale {
  opacity: 1;
  top: 80px;
}
.year-mont .mouth-cont {
  opacity: 0;
}
.year-mont .year-cont {
  opacity: 1;
}
.year-mont .price-num-item .year-cont {
  left: -30px;
}
.price-num-item {
  font-size: 55px;
  position: relative;
  font-weight: 600;
  color: #666;
  display: inline-table;
}
.price-desc {
  padding: 20px 0 30px;
}
.price-desc ul li {
  float: left;
  width: 100%;
  padding: 18px 0;
  color: #666;
  font-weight: 500;
  font-size: 12px;
  border-bottom: 1px solid #eee;
}
.price-desc ul li:nth-child(2n + 1) {
  background: #f9f9f9;
}
.price-desc ul li:first-child {
  border-top: 1px solid #eee;
}
.price-link {
  display: inline-table;
  padding: 15px 20px;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 30px;
  font-weight: 400;
}
.price-content {
  background: #fff;
  border: 1px solid #eee;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.recomm-price {
  margin-top: 40px;
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
.recomm-price i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 100%;
  margin-bottom: 10px;
}
.pricing-wrap {
  margin: 60px 0 30px;
}
.pricing-switcher {
  text-align: center;
  position: relative;
}
.pricing-switcher:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 1px;
  height: 80px;
  z-index: -1;
  bottom: -53px;
  background: #eee;
}
.pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 2px;
  border-radius: 30px;
  margin-bottom: 30px;
}
.pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
  left: -1000px;
}
.pricing-switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  float: left;
  width: 110px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  font-weight: 600;
  font-size: 11px;
  color: #fff;
  -webkit-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
}
.pricing-switcher .switch {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 30px;
  width: 110px;
  background: #fff;
  border-radius: 30px;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}
.pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(104px);
  transform: translateX(104px);
}
.pricing-switcher input[type="radio"]:checked + label {
  color: #999;
}
/*------Blog------------------------------------------------*/
.article-masonry {
  width: 50%;
  float: left;
  padding: 0 10px;
  margin-bottom: 20px;
}
.card-post {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
}
.card-post .btn {
  margin-bottom: 20px;
}
.post-article {
  margin-bottom: 30px;
  float: left;
  width: 100%;
  position: relative;
}
.post-article .list-single-main-media,
.card-post .list-single-main-media {
  margin: 0;
}
.post-article-title {
  padding: 30px;
}
.card-post-img img {
  float: left;
  width: 100%;
  height: auto;
}
.card-post-content {
  padding: 15px 20px 0;
  border: 1px solid #eee;
}
.card-post-content h3 {
  float: left;
  width: 100%;
  text-align: left;
  color: #666;
  font-size: 16px;
  font-weight: 700;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}
.card-post-content h3:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0d7";
  position: absolute;
  right: 20px;
  top: 30px;
}
.card-post-content h3 a {
  color: #666;
}
.card-post-content p {
  text-align: left;
}
.home-posts {
  margin-bottom: 40px;
}
.card-post-content .post-opt {
  border-top: 1px solid #eee;
  width: 100%;
  padding: 15px 0;
  margin: 20px 0 5px;
}
.post-nav {
  margin-top: 20px;
  margin-bottom: 10px;
}
.post-nav:before {
  content: "";
  position: absolute;
  left: 50%;
  height: 50px;
  top: 50%;
  margin-top: -25px;
  width: 1px;
  background: #eee;
}
.post-link {
  position: relative;
  font-size: 15px;
  font-weight: 400;
  color: #999;
}
.post-link span {
  color: #999;
  font-size: 12px;
  font-weight: 600;
}
.post-link i {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  line-height: 40px;
  font-size: 22px;
}
.post-link:hover i {
  -webkit-transform: rotateX(360deg);
  transform: rotateX(360deg);
}
.post-link.prev-post-link {
  float: left;
  padding-left: 40px;
  text-align: left;
}
.post-link.next-post-link {
  float: right;
  padding-right: 40px;
  text-align: right;
}
.post-link.prev-post-link i {
  left: 0;
  text-align: left;
}
.post-link.next-post-link i {
  right: 0;
  text-align: right;
}
.post-author {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
}
.post-author img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  float: left;
  margin-right: 20px;
}
.post-author span {
  font-weight: 600;
  position: relative;
  top: 14px;
  color: #666;
  font-size: 12px;
}
.post-opt {
  padding-top: 24px;
}
.post-opt,
.post-opt li {
  float: left;
}
.post-opt li {
  margin-right: 20px;
}
.post-opt li span,
.post-opt li a {
  color: #999;
  font-weight: 500;
  font-size: 12px;
}
.post-opt li i {
  padding-right: 6px;
}
.search-widget input {
  float: left;
  width: 100%;
  border: none;
  background: #ecf6f8;
  height: 50px;
  padding: 0 80px 0 20px;
  z-index: 1;
  border-radius: 4px 0 0 4px;
}
.search-widget .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 50px;
  line-height: 50px;
  z-index: 2;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}
.jr-insta-thumb {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.jr-insta-thumb ul {
  list-style: none;
}
.jr-insta-thumb li {
  float: left;
  width: 33.3%;
  padding: 5px;
  overflow: hidden;
}
.jr-insta-thumb li img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}
.jr-insta-thumb li a,
.post-link i {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.jr-insta-thumb li a:hover {
  opacity: 0.6;
}
.cat-item li {
  float: left;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 15px;
  text-align: left;
  position: relative;
}
.cat-item li:last-child {
  margin-bottom: 0;
}
.cat-item li:before {
  content: "";
  position: absolute;
  bottom: 6px;
  width: 100px;
  height: 1px;
  background: #eee;
  left: 50%;
  margin-left: -25px;
}
.cat-item li a {
  float: left;
  font-size: 12px;
  color: #666;
  font-weight: 400;
}
.cat-item li span {
  float: right;
  color: #999;
  font-size: 12px;
}
.fixed-bg .overlay {
  opacity: 0.6;
}
.mob-bg,
.mobile-list-controls {
  display: none;
}
.vishidelem {
  display: block !important;
}

/*------responsive------------------------------------------------*/
@media only screen and (max-width: 1224px) {
  .text-carousel .slick-item {
    padding: 0 5px;
  }
  .col-list-wrap {
    width: 800px;
  }
  .map-container.column-map {
    width: calc(100% - 800px);
  }
  .three-columns-grid .listing-item.has_one_column .geodir-category-listing {
    padding-left: 13px !important;
  }
  .three-columns-grid .listing-item.has_one_column .geodir-category-img {
    top: 12px;
  }
}
@media only screen and (max-width: 1064px) {
  .mobile-list-controls {
    display: block;
  }
  .col-list-wrap .mobile-list-controls {
    margin-top: 20px;
  }
  .mlc {
    padding: 15px 0;
    width: 50%;
    color: #fff;
    float: left;
    border-radius: 0 4px 4px 0;
    font-size: 12px;
    cursor: pointer;
  }
  .mlc:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.21);
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
  }
  .mlc.fl-wrap {
    width: 100%;
    border-radius: 4px;
  }
  .mlc i {
    padding-right: 10px;
  }
  .col-list-search-input-item .quantity-item {
    margin-right: 15px;
    width: auto;
  }
  .col-list-search-input-item {
    margin-bottom: 15px;
  }
  .map-container.column-map.right-pos-map,
  .map-container.fw-map.big_map.hid-mob-map {
    width: 100%;
    right: -100%;
    position: absolute;

    z-index: 100 !important;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .fixed-mobile {
    position: fixed !important;
    top: 110px !important;
  }
  .map-close {
    display: block;
  }
  .nav-button-wrap,
  .mob-bg {
    display: block;
  }
  .logo-holder {
    margin-left: 10px;
  }
  .fixed-footer {
    position: relative !important;
    float: left;
  }
  .main-menu nav ul,
  video.bgvid,
  .process-item:before,
  .back-to-filters,
  .lws_mobile {
    display: none;
  }
  .process-item {
    margin-bottom: 20px;
  }
  .main-menu {
    position: absolute;
    top: 50px;
    left: -20px;
    width: 300px;
    z-index: 50;
    padding: 15px 10px;
    background: #fff;
    border-bottom-right-radius: 6px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .vismobmenu {
    opacity: 1;
    left: 0;
    visibility: visible;
  }
  .menusb {
    display: block;
    float: left;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .main-menu .menusb ul {
    display: block;
    float: left;
    position: relative;
    width: 100%;
  }
  .menusb li {
    margin: 0;
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 20px;
  }
  .menusb li i {
    position: absolute;
    right: 18px;
    top: 10px;
  }
  .menusb a {
    float: left;
    width: 100%;
    padding: 8px 5px;
    position: relative;
    font-weight: 600;
    font-size: 12px;
    color: #50596e;
    line-height: 14px;
    text-align: left;
  }
  .menusb a i {
    transform: rotate(-90deg);
  }
  .menusb a.back {
    padding-left: 24px;
  }
  .menusb a.back:before {
    font-family: Font Awesome\5 Pro;
    content: "\f177";
    font-size: 14px;
  }
  .main-header .containers {
    width: 100%;
  }
  .main-header .containers1 {
    width: 100%;
  }
  .home-grid .gallery-item {
    width: 50%;
  }
  .home-grid .gallery-item .listing-counter {
    top: 20px;
    left: 15px;
  }
  .home-grid .gallery-item.gallery-item-second .listing-counter {
    left: inherit;
    right: 15px;
    z-index: 100;
    top: 10px;
  }
  .home-grid .gallery-item .listing-item-cat {
    padding: 30px 15px 10px;
  }
  section.hero-section {
    padding: 100px 0;
  }
  .light-carousel-wrap,
  .fw-col-list-wrap .list-main-wrap-opt .list-main-wrap-title.col-title {
    margin-top: 30px;
  }
  .single-facts .inline-facts-wrap {
    width: 100%;
    padding: 5px 0;
  }
  .collage-image {
    margin-top: 40px;
    float: left;
  }
  .images-collage-title {
    left: 10px;
  }
  .pad-top-column-text {
    padding: 0;
  }
  .footer-carousel-title {
    top: 0;
  }
  .footer-social {
    margin-bottom: 30px;
  }
  .contact-btn,
  .mar-bot-cont {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .subfooter-lang {
    float: left;
    width: 100%;
    margin: 15px 0;
  }
  .subfooter-lang-tooltip {
    right: inherit;
    left: 0;
    width: 200px;
  }
  .subfooter-nav {
    float: left;
    margin-right: 0;
  }
  .card-post,
  .video-box,
  .team-box,
  .tags-stylwrap a,
  .price-item {
    margin-bottom: 15px;
  }
  .best-price {
    top: 0;
    transform: scale(1);
  }
  .pricing-wrap {
    margin: 0;
  }
  .team-box,
  .three-columns-grid .listing-item {
    width: 50%;
  }
  .success-table-header a {
    top: 0;
    margin-top: 15px;
    float: left;
  }
  .success-table-header p,
  #footer-twiit p.tweet,
  .contact-btn {
    width: 100%;
  }
  .dasboard-wrap {
    padding-left: 0;
  }
  .dasboard-sidebar {
    position: relative;
    float: left;
    width: 100%;
    z-index: 10;
    top: 0;
    margin-bottom: 20px;
  }
  .dasboard-avatar {
    width: 30%;
    float: left;
  }
  .dasboard-sidebar-item {
    width: 70%;
  }
  .dasboard-sidebar-item {
    padding: 0 10px 0 0;
    text-align: left;
  }
  .dasboard-sidebar-item:before {
    left: 0;
    margin-left: 0;
  }
  .log-out-btn {
    background: #f9b90f !important;
  }
  .ed-btn {
    float: left;
  }
  .flat-header {
    padding-top: 140px;
  }
  .dasboard-sidebar-content {
    padding: 20px 0 44px 0;
  }
  .dasboard-sidebar-content:before {
    position: absolute;
    font-family: Font Awesome\5 Pro;
    content: "\f085";
    color: #fff;
    right: 70px;
    top: 70px;
    opacity: 0.4;
    font-size: 54px;
  }
  .header-search-button {
    margin-top: 10px;
  }
  .col-list-wrap,
  .search-input-item,
  .search-input-item.midd-input,
  .search-input-item.small-input,
  #footer-twiit .timePosted a,
  .price-item,
  .three-columns-grid .listing-item.has_one_column {
    width: 100%;
  }
  .list-single-main-container {
    padding-left: 0;
  }
  .fixed-scroll-column {
    position: relative !important;
    float: left;
    left: 0;
    width: 100%;
    top: -20px;
  }
  .list-single-hero-rating {
    float: left;
    margin-top: 20px;
  }
  .list-single-hero-links {
    float: left;
  }
  .lisd-link {
    margin-left: 0;
    margin-right: 10px;
  }
  .list-single-hero-rating .score {
    float: right;
    text-align: left;
    padding-right: 0;
    padding-left: 15px;
  }
  .list-single-hero {
    padding: 100px 0 0;
  }
  /* .fc-button {
    margin-right: 5px;
  }
  .fc-button span {
    left: 0;
    top: -60px;
  } */
  .fixed-scroll-column-share-container {
    position: absolute;
    top: 70px;
    left: 0;
    background: #18458b;
    padding-left: 20px;
    width: 180px;
    border-radius: 6px;
  }
  .share-container .share-icon {
    float: left;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-right: 6px;
  }
  .list-single-carousel-wrap,
  .fw-carousel .slick-slide-item {
    height: 400px !important;
  }
  .booking-modal-content {
    padding-right: 0;
  }
  .booking-modal-info {
    position: relative;
    height: auto;
    width: 100%;
    float: left;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .home-grid .gallery-item,
  .article-masonry,
  .main-search-input-item,
  .main-search-button,
  .listing-item,
  .rooms-media,
  .rooms-details {
    width: 100%;
  }
  .show-lang,
  .show-currency-tooltip,
  .review-comments-avatar:before,
  .grid-opt,
  .scroll-nav {
    display: none;
  }
  .show-search-button {
    width: 120px;
  }
  .backgroundImg {
    margin-left: -80px;
  }
  .image-sliderdesc {
    display: none;
  }
  section.parallax-section .bg,
.list-single-hero .bg,
.hero-parallax .bg {
  height: 100%;
 
}
  .add-hotel {
    padding: 0 20px;
  }
  .show-reg-form {
    margin-right: 20px;
  }
  .header-search-input-item {
    margin-bottom: 15px;
  }
  .header-search-input-item .quantity-item,
  .col-list-search-input-item .quantity-item {
    width: auto;
  }
  .header-search-button {
    top: 5px;
  }
  .header-search {
    padding: 20px 30px 10px;
  }
  .main-search-input-item {
    margin-bottom: 10px;
  }
  .main-search-input-item input,
  .main-search-input-item,
  .main-search-button {
    border-radius: 6px;
  }
  .main-search-input {
    padding: 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .main-search-button,
  .scroll-nav-wrapper .show-hidden-map {
    position: relative;
    float: left;
  }
  .qty-dropdown {
    z-index: 100;
  }
  .home-intro h2 {
    font-size: 34px;
  }
  .list-carousel .sw-btn {
    top: 26%;
  }
  .light-carousel .slick-slide-item {
    padding: 10px 0;
  }
  .light-carousel .slick-slide-item .hotel-card {
    padding: 0 10px;
  }
  .text-carousel .slick-item {
    padding: 0 25px 0 5px;
  }
  .slider-container .slider-item {
    padding: 80px 0 100px;
  }
  .slider-container-wrap .sw-btn {
    top: 100%;
  }
  .reviews-comments-item {
    padding: 0 0 30px 0;
  }
  .review-comments-avatar {
    top: -25px;
    left: 25px;
    z-index: 30;
    width: 50px;
    height: 50px;
  }
  .review-comments-avatar img {
    width: 50px;
    height: 50px;
  }
  .post-nav a {
    margin-bottom: 20px;
  }
  #progressbar li {
    width: 48%;
    margin-bottom: 10px;
  }
  .ed-btn {
    margin-left: 30%;
  }
  .listing-item,
  .listing-item:nth-child(even) {
    padding: 0;
  }
  .listing-features li,
  .list-single-facts .inline-facts-wrap {
    width: 50%;
  }
  .rooms-details {
    padding-left: 0;
  }
  .list-single-hero-header .list-single-hero-price,
  .rooms-details {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 540px) {
  .home-btn,
  .show-search-button span {
    display: none;
  }
  .nav-button-wrap {
    margin-left: 10px;
  }
  .show-search-button {
    width: 60px;
  }
  .show-search-button i {
    padding-left: 0;
  }
  .home-intro h2,
  .home-intro-card h3,
  section.parallax-section .section-title.big-title h2,
  .list-single-hero-title h2 {
    font-size: 24px;
  }
  section.hero-section {
    padding: 50px 0 100px;
  }
  .list-carousel {
    padding: 0;
  }
  .collage-image .main-collage-image,
  .collage-image-input {
    right: 10px;
  }
  .controls-mapwn {
    width: 250px;
    padding: 0 20px;
    height: 43px;
    line-height: 43px;
  }
  .map-modal-item,
  .main-register-holder {
    padding: 0 10px;
  }
  .daterangepicker {
    width: 303px !important;
  }
  .team-box,
  #progressbar li,
  .previous-form,
  .next-form,
  .list-single-header-contacts li {
    width: 100%;
  }
  .list-single-header-contacts li {
    text-align: left;
    padding: 5px 0;
  }
  .next-form,
  .list-single-hero-price {
    float: left;
    margin-top: 15px;
  }
  .success-table-header {
    padding: 10px 0 0 0;
  }
  .success-table-header i.decsth {
    position: relative;
    float: left;
    margin-bottom: 10px;
  }
  .map-view-wrap {
    position: relative;
    height: auto;
    float: left;
  }
  .map-view-wrap_item {
    top: 0;
  }
  .map-view-wrap_item,
  .dasboard-avatar,
  .dasboard-sidebar-item,
  .dashboard-message-avatar,
  .price-opt,
  .half-tags,
  .three-columns-grid .listing-item,
  .list-single-main-media .gallery-item,
  .listing-features li,
  .list-single-facts .inline-facts-wrap,
  .review-total,
  .review-range-container,
  .facilities-list .btn,
  .review-score-detail,
  .review-score-total {
    width: 100%;
  }
  .facilities-list .btn3 {
    margin-top: 40px;
  }
  .review-score-total {
    margin-bottom: 10px;
  }
  .dasboard-avatar,
  .review-range-container,
  .review-score-detail {
    padding: 0;
  }
  .dasboard-avatar img {
    width: 80px;
    height: 80px;
  }
  .list-single-facts .inline-facts-wrap {
    border-top: 1px solid #e2e2e2;
    border-left: none;
  }
  .dasboard-sidebar-item {
    padding: 0 10px 0 0;
    text-align: center;
  }
  .dasboard-sidebar-item:before {
    left: 50%;
    margin-left: -25px;
  }
  .dasboard-sidebar-content {
    padding: 40px 0 44px 0;
  }
  .ed-btn {
    float: none;
  }
  .tfp-btn {
    top: -70px;
    right: inherit;
    left: 0;
  }
  .tfp-det {
    top: -30px;
  }
  .tfp-det-btn {
    float: none;
    display: inline-block;
  }
  .dasboard-sidebar-content:before {
    top: 20px;
    right: 20px;
  }
  .ed-btn {
    margin-left: 0;
  }
  .dasboard-menu,
  .dasboard-menu li {
    width: 100%;
  }
  .dasboard-menu-btn {
    display: block;
  }
  .dasboard-menu ul.dasboard-menu-wrap {
    display: none;
    background: #fff;
    float: left;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  .dasboard-menu li ul {
    position: relative;
    top: 0;
    background: none;
    padding: 0;
    width: auto;
    z-index: 2;
    border: none;
    opacity: 1;
    display: block;
    visibility: visible;
    float: left;
    width: 100%;
  }
  .dasboard-menu li ul li {
    float: left;
    width: 100%;
    padding: 0;
    position: relative;
  }
  .dasboard-menu li ul li span {
    position: absolute;
    top: 50%;
    right: 0;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    line-height: 16px;
    border-radius: 100%;
    font-size: 9px;
    color: #fff;
  }
  .dasboard-menu li a i {
    display: none;
  }
  .dasboard-menu li a {
    padding: 10px 0 !important;
    background: none !important;
    color: #666 !important;
  }
  .dasboard-menu li a.user-profile-act,
  .dasboard-menu li a:hover {
    color: #3aaced !important;
    box-shadow: none;
  }
  #myChartLegend {
    float: left;
    margin-top: 10px;
  }
  #myChartLegend li {
    margin-left: 0;
    margin-right: 10px;
  }
  .dashboard-message-text {
    margin-left: 15px;
  }
  .new-dashboard-item {
    padding: 6px 10px;
    right: 15px;
  }
  .dasboard-menu {
    top: 0;
  }
  .dashboard-message-text {
    margin-left: 0;
    text-align: left;
    padding-left: 0;
  }
  .dashboard-message-avatar img,
  .review-total {
    float: left;
    margin-bottom: 20px;
  }
  .chat-message,
  .chat-message.chat-message_user .massage-date {
    padding-left: 0;
    padding-right: 0 !important;
  }
  .chat-message .dashboard-message-avatar {
    position: relative;
    top: 0;
  }
  .chat-message .dashboard-message-avatar span {
    float: left;
    margin: 10px 0 0 12px;
  }
  .chat-message.chat-message_user .massage-date {
    float: left;
  }
  .massage-date {
    margin: 10px 0;
  }
  .chat-message p:before {
    display: none;
  }
  .map-close {
    width: 43px;
    height: 43px;
    left: 260px;
    line-height: 43px;
    font-size: 12px;
  }
  .list-single-carousel-wrap,
  .fw-carousel .slick-slide-item {
    height: 300px !important;
  }
  .to-top {
    right: 10px;
  }
  .ajax-modal-container {
    width: 500px;
  }
  .ajax-modal-details {
    padding: 20px 25px;
  }
  .ajax-modal-list li {
    width: 50%;
  }
  .error-wrap h2 {
    font-size: 74px;
  }
}
@media only screen and (max-width: 420px) {
  .add-hotel {
    display: none;
  }
  .show-reg-form {
    margin-right: 30px;
  }
  .wishlist-wrap {
    width: 300px;
    padding: 25px 10px 0;
  }
  .header-user-name {
    font-size: 11px;
  }
  .header-user-name:before {
    bottom: -1px;
    right: -20px;
  }
  .header-user-menu {
    margin-right: 30px;
  }
  .ajax-modal-container {
    width: 400px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .bg {
    background-attachment: scroll !important;
    -webkit-background-size: cover;
    background-position: center;
  }
}

.show-lang i,
.nav-holder nav li a i,
.filter-tags input:checked:after,
.custom-form .filter-tags input:checked:after,
.main-register h3 span,
.lost_password a:hover,
.header-search-input-item.location a,
.pac-icon:before,
.footer-social li a,
.cs-mumber,
.show-search-button i,
.show-сurrency-tooltip span i,
.show-reg-form i,
.header-user-name:before,
.tabs-menu li.current a,
.currency-tooltip li i,
.currency-wrap .show-currency-tooltip i,
.subfooter-lang-tooltip li a,
.header-search-input-item.in-loc-dec:before,
.col-list-search-input-item.location a,
.col-list-search-input-item.in-loc-dec:before,
.list-main-wrap-title h2 span,
.grid-opt li span.act-grid-opt,
.grid-opt li span.act-grid-opt:hover,
.price-opt ul li a:hover,
.geodir-category-content h3 a:hover,
.geodir-category-opt .js-favorite i,
.avatar-tooltip strong,
.listing-title a,
.map-popup-location-price,
.nice-select .nice-select-search-box:before,
.map-modal-container h3 a:hover,
.category-fix-bg-title h4,
.geodir-opt-tooltip strong,
.facilities-list li i:hover,
.to-top:hover,
.section-title .breadcrumbs a:before,
.list-single-header-contacts li i,
.share-container .share-icon:hover:before,
.breadcrumbs-hero-buttom .breadcrumbs a:before,
.listing-features li i,
.rooms-details-header h5 span,
.inline-facts i,
.ajax-modal-photos-btn span,
.ajax-modal-list li i,
.total-coast span,
.custom-form .total-coast input,
.bookdate-container-dayscounter i,
.box-widget-list li i,
.list-single-main-item-title:before,
.box-widget-item-header:before,
/* .fc-button i, */
.share-container .share-icon,
.reviews-comments-item-date i,
.custom-form .review-total span input,
.flatWeatherPlugin ul.wiForecasts li,
.box-widget-list li a:hover,
.list-single-main-item-title h3 span,
.claim-widget-link a,
.mapnavigation a i,
.list-single-hero-header .list-single-hero-price span,
.breadcrumbs.inline-breadcrumbs a:before,
.box-widget-content .widget-posts-descr .geodir-category-location a i,
.box-widget-content .widget-posts-link i,
.tfp-btn strong,
.dashboard-message-text p a,
.dasboard-menu li a.user-profile-act i,
.dasboard-menu li a:hover i,
.pass-input-wrap span,
.radio input[type="radio"]:checked + span:before,
.custom-form .radio span,
.reply-mail a,
.tfp-det p a,
.chat-contacts-item .chat-contacts-item-text span,
.reviews-comments-item-text h4 a.reviews-comments-item-link,
.video-box-btn,
.text-carousel .slick-center .text-carousel-item:after,
.text-carousel .slick-center .text-carousel-item:before,
.single-facts .inline-facts i,
.team-social li a,
.team-dec,
.filter-tags label a,
.breadcrumbs-fs .breadcrumbs span,
.home-btn a,
.cart_list li span,
#progressbar li:before,
.log-massage a,
.cat-item li a,
.post-link i,
.card-post-content h3:before,
.card-post-content h3 a:hover,
.post-opt li a:hover,
.wishlist-link,
.box-widget-content .widget-posts-descr a:hover,
.faq-nav li a.act-faq-link,
.time-line-icon i,
s .listing-counter span,
.images-collage-title,
.down-btn i,
.qty-dropdown-content .quantity-item label i,
.qty-dropdown-header:before,
.main-search-input-item_small:before,
.main-search-input-item.location a,
.listing-counter strong,
.weather-grid .flatWeatherPlugin .wiToday .wiIconGroup div.wi,
.geodir-category-price span,
.show-search-button:hover i,
.hotel-card .geodir-category-location a i,
.header-search-input-item.date-parent:before,
.menusb a i,
.menusb a.back:before,
.footer-contacts li a:hover,
#footer-twiit a:hover,
.dasboard-menu-btn i,
.header-user-menu li a:hover,
.dashboard-listing-table-text h4 a,
.mlc i,
.show-more-filters i {
  color: #3aaced;
}

.color-bg,
.close-header-search:hover,
#footer-twiit p.tweet:before,
.cs-mumber-button:hover,
.tabs-menu li a,
.review-range-container .irs-bar,
.review-range-container .irs-bar-edge,
.search-input-item .irs-line,
.col-list-search-input-item .irs-line,
.pagination a.current-page,
.pagination a.current-page:hover,
.mapnavigation a:hover,
.back-to-filters span,
.infoBox-close,
.slick-dots li.slick-active button,
.map-modal-close,
.geodir-opt-link a,
.cluster div:hover,
.to-top,
.daterangepicker td.active,
.list-single-hero-title h2:before,
.user-stats:before,
.ed-btn:hover,
.dashboard-header,
.massage-date span:before,
.chat-contacts-item:after,
.lisd-link:hover,
.scroll-nav-wrapper .scroll-nav li a.act-scrlink,
.accordion a.toggle span,
.list-widget-social li a,
.box-item a.gal-link,
.message-input button,
.message-counter,
.line-separator:before,
.intro-item h2:before,
.section-separator:before,
#progressbar li:before,
.price-num.col-dec-1:before,
.tags-stylwrap.sb-tags a,
.geodir-opt-list a:hover,
.main-search-button:hover,
.twitter-link,
.recomm-price i,
.lg-actions .lg-next,
.lg-actions .lg-prev {
  background: #3aaced;
}

.pin,
.nice-select:after,
.ajax-loader,
.ed-btn,
blockquote {
  border-color: #3aaced;
}

/*--
	orange
--*/
.nav-holder nav li a.act-link,
.nav-holder nav li a:hover,
.main-register h3 span strong,
.header-search-input-item-icon,
.subfooter-show-lang i,
#footer-twiit .timePosted a:before,
.footer-contacts li i,
.fc-cont:hover,
.custom-form label span,
.section-title-separator span i:nth-child(2),
.five-star-rating .listing-rating i,
.four-star-rating .listing-rating i,
.three-star-rating .listing-rating i,
.grid-opt li span:hover,
.price-opt ul li a i,
.geodir-category-opt .listing-rating i,
.geodir-category-opt .js-favorite:hover i,
.geodir-category-location a i,
.map-card-rainting i,
.map-popup-location-info i,
.daterangepicker .calendar-table th,
.listing-rating i,
.rooms-details-header h5,
.custom-form label i,
.nice-select:before,
.amenities-list li span.status.status-no,
.flatWeatherPlugin ul.wiForecasts li.wi,
.widget-posts-date i,
.claim-widget-link a:hover,
.mapzoom-in,
.dashboard-list-box.activities .dashboard-message-text i,
.fuzone .fu-text i,
.dashboard-message-text h4 span,
.tfp-btn:before,
.process-wrap li i,
.singleMap_visible-title-item h4 i,
.flat-title-wrap .breadcrumbs a:before,
.breadcrumbs-fs .breadcrumbs a:before,
.log-massage a:hover,
.cart-total strong,
.post-opt li i,
.cat-item li span,
.post-link,
.faq-nav li a.act-faq-link:before,
.faq-nav li a:hover:before,
.process-item h4 a:hover,
.intro-item h6,
.collage-image-input i,
.inpt_dec,
.qty-dropdown-header i,
.geodir-category-opt h4 a:hover,
.map-modal-container h3 i,
.header-user-name:hover,
.show-reg-form:hover,
.home-btn:hover a,
.menusb a.act-link,
.footer-social li a:hover,
.dashboard-listing-table-text h4 a:hover,
.dashboard-listing-table-address i,
.map-close,
.text-carousel-item a.testim-link:hover {
  color: #f9b90f;
}

.color2-bg,
.header-search-button,
.pac-item:hover,
.close-header-search,
.footer-widget #subscribe-button:hover,
.cs-mumber-button,
.irs-slider,
.pagination a:hover,
.cluster div,
.infoBox-close:hover,
.show-more-filters.active-hidden-opt-btn,
.load-more-button:hover,
.daterangepicker .drp-buttons .btn.btn-default,
.header-sec-link a:hover,
.scroll-nav-wrapper .show-hidden-map,
.ajax-loader-cirle:before,
.dashboard-list-box.activities .new-dashboard-item:hover,
.dasboard-menu li a span,
.dasboard-menu li ul li span,
.text-carousel-wrap .sw-btn,
.price-link:hover,
.price-num.col-dec-3:before,
section.color-bg .section-separator:before,
.add-hotel:hover,
.success-table-header a:hover,
.dashboard-listing-table-opt li a.del-btn {
  background: #f9b90f;
}

/*--
	dark-blue
--*/
.accordion a.toggle.act-accordion,
.banner-wdget-content a,
.box-image-widget-media a:hover,
.rooms-media-btn-item,
.sw-btn,
.bt n:hover,
.more-photos-button,
.box-item a.gal-link:hover,
.dasboard-sidebar-content,
.tfp-det-btn:hover,
.color3-bg,
.team-social,
.text-carousel-wrap .sw-btn:hover,
.home-intro-card-counter,
.mlc,
.geodir-opt-tooltip,
.custom-form .log-submit-btn,
.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover,
.error-wrap form .search-submit:hover,
.close-reg:hover,
.custom-form .log-submit-btn:hover,
.soc-log a:hover {
  background: #18458b;
}

.mapzoom-out,
.single-facts .inline-facts .num,
.section-title h2,
.singleMap_visible-title-item h4,
.card-post-content h3 a,
.map-modal-container h3 a,
.error_message h3,
#success_page h3,
.text-carousel-item a.testim-link {
  color: #18458b;
}

/*--
	gradient
--*/
.gradient-bg {
  background-color: #3aaced;

  background: -webkit-linear-gradient(top, #3aaced, #49ceff);
  background: -moz-linear-gradient(top, #3aaced, #49ceff);
  background: -ms-linear-gradient(top, #3aaced, #49ceff);
  background: -o-linear-gradient(top, #3aaced, #49ceff);
}

/*--
	reset
--*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: none;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
  margin: 0;
  padding: 0;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
:focus {
  outline: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
a {
  text-decoration: none;
  position: relative;
  color: #000;
}
audio,
video,
canvas {
  max-width: 100%;
}
.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px; /* Adjust the gap between images */
}

.gallery-item {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

/* Reviews */
.mt-ico-rating {
  width: 100px;
  height: 18px;
  position: relative;
}

.mt-ico-rating > div {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-size: 100px 35px;
  width: 100%;
}

.mt-ico-rating-empty {
  /* background-image: url(../images/stars-empty.svg); */
  background-repeat: no-repeat;
  background-position: left;
}

.mt-ico-rating-fill {
  /* background-image: url(../images/stars-fill.svg); */
  background-repeat: no-repeat;
  background-position: left;
}

.iconfilter-listing .mt-ico-rating {
  margin-top: 5px;
}

.single-icondrops-content .social-links li.facebook a {
  background: #3b5998;
}

.single-icondrops-content .social-links li.facebook:hover a {
  background: #314b80;
}

.single-icondrops-content .social-links li.linkedin a {
  background: #225982;
}

.single-icondrops-content .social-links li.linkedin:hover a {
  background: #1b4769;
}

.single-icondrops-content .social-links li.telegram a {
  background: #20a0e1;
}

.single-icondrops-content .social-links li.telegram:hover a {
  background: #1a8cc5;
}

.single-icondrops-content .social-links li.bitcointalk a {
  background: #f7931a;
}

.single-icondrops-content .social-links li.bitcointalk:hover a {
  background: #e27f07;
}

.single-icondrops-content .social-links li.twitter a {
  background: #44ccf6;
}

.single-icondrops-content .social-links li.twitter:hover a {
  background: #1fc1f4;
}

.single-icondrops-content .social-links li.github a {
  background: #323131;
}

.single-icondrops-content .social-links li.github:hover a {
  background: #212020;
}

.single-icondrops-content .social-links li.medium a {
  background: #23c66d;
}

.single-icondrops-content .social-links li.medium:hover a {
  background: #1bab6e;
}

.single-icondrops-content .social-links li.youtube a {
  background: #ce332d;
}

.single-icondrops-content .social-links li.youtube:hover a {
  background: #ab2a25;
}

.single-icondrops-content .social-links li a {
  display: block;
  text-align: center;
  color: #fff;
  padding: 8px;
  font-size: 17px;
}

.social-sharer > li {
  display: block;
  float: left;
  margin: 0 5px 0 0;
  margin-bottom: 0;
  padding: 8px 12px;
  text-align: center;
  width: 40px;
  transition: all 300ms;
  -o-transition: all 300ms;
  -ms-transition: all 300ms;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
}

.single-icondrops-content.single-icondrops-content-socialmedia {
  padding: 20px;
  padding-bottom: 15px;
}

.single-icondrops-content .social-links li {
  border-radius: 0;
  list-style: none;
  width: 23.6%;
  margin-bottom: 5px;
  float: left;
  margin-right: 2.5px;
  margin-left: 2.5px;
}

.single-icondrops-content .social-links {
  padding: 0;
}

.ico-cat-parent-subtitle {
  padding: 0 !important;
}

.ico-cat-parent-subtitle a {
  display: block;
  color: #fff !important;
  padding: 18px !important;
  border: 2px solid #6cd086;
  transition: all 0.3s ease-in-out;
}

.ico-listing-category-style-v2 .ico-cat-parent-title > h2 {
  color: #252525;
  margin: 0;
  font-weight: 400;
}

.ico-listing-category-style-v2 .ico-cat-parent-title > a {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
}

.ico-listing-category-style-v2 .ico-cat-parent-title > p {
  color: rgba(37, 37, 37, 0.8);
  margin-bottom: 0;
  font-weight: 300;
}

.ico-listing-category-style-v2 .ico-cat-parent-title {
  background: #fff;
  color: #252525;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 38px rgba(59, 77, 133, 0.08);
}

.tax-mt-listing-category {
  background: #f9f9f9;
}

.row-1items .walkerton-img-left {
  width: 9.5%;
}

.row-1items .walkerton-img-left,
.ico-listing-category-style-v2 .walkerton-img-left,
.blog-posts .walkerton-img-left {
  width: initial !important;
  margin-right: 30px;
}

.row-1items .walkerton-details,
.blog-posts .walkerton-details {
  width: 90.5%;
}

.iconfilter-shortcode .iconfilter-listing .row-1items .post-name-listings,
.iconfilter-shortcode .iconfilter-listing .row-1items .mt-ico-rating {
  display: inline-block;
}

.iconfilter-shortcode
  .iconfilter-listing
  .row-1items
  .blog_custom_listings
  .post-name-listings
  span {
  margin-right: 25px;
}

.walkerton-metas > div {
  padding: 4px 10px;
  margin-right: 3px;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  color: #252525;
  border: 1px solid #e5e3eb;
}

.walkerton-metas > div:last-child {
  margin-right: 0 !important;
}

.ico-type i {
  font-size: 15px;
}

.ico-type a:hover,
.ico-type a:focus {
  background: #eaeaea;
}

.ico-category img.attachment-thumbnail {
  width: 15px;
  vertical-align: text-top;
  margin-right: 3px;
  border-radius: 0;
}

.ico-category a {
  color: #483ca8;
}

.ico-category a:hover,
.ico-category a:focus {
  background: #eaeaea;
}

.ico-interest a {
  color: #e74c3c !important;
}

.ico-interest a:hover,
.ico-interest a:focus {
  background: #eaeaea;
}

.walkerton-img-left > a,
.blog-posts .walkerton-img-left .ico-icon {
  display: block;
}

.search-list-item .walkerton-img-left .ico-icon {
  text-align: center;
}

.walkerton-filter {
  box-shadow: 0 0 38px rgba(59, 77, 133, 0.08);
  background: #fefefe;
  padding: 15px;
}

.walkerton-filters {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.walkerton-filters .filter {
  box-shadow: 0 0 38px rgba(59, 77, 133, 0.09);
  text-align: right;
  background: #fff;
  transition: all 0.2s ease-in-out;
  float: left;
  margin-right: 10px;
  width: 32%;
  text-align: left;
}

.walkerton-filters .filter:last-child {
  margin-right: 0;
}

.walkerton-filters .filter a {
  color: #483ca8;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  padding: 15px 20px;
  width: 100%;
}

.walkerton-filters .filter:hover {
  background: #6cd086;
  color: #fff;
}

.walkerton-filters .filter:hover a {
  color: #fff;
}

.fa-input {
  font-family: FontAwesome, â€˜Helvetica Neueâ€™, Helvetica, Arial, sans-serif;
}

.walkerton-filter-content input[type="text"] {
  padding: 14px 30px;
  border: solid 2px #483ca8;
  color: #483ca8;
  margin-right: 2%;
  width: 82%;
  float: left;
}

.walkerton-filter-content input[type="text"]::-webkit-input-placeholder {
  color: #483ca8;
}

.walkerton-filter-content input[type="text"]::-moz-placeholder {
  color: #483ca8;
}

.walkerton-filter-content input[type="text"]:-ms-input-placeholder {
  color: #483ca8;
}

.walkerton-filter-content input[type="text"]:-moz-placeholder {
  color: #483ca8;
}

.walkerton-filter-content button[type="submit"] {
  padding: 13px 18px;
  background: #6cd086;
  border: solid 2px #6cd086;
  color: #fff;
  line-height: normal;
  width: 16%;
  transition: all 0.2s ease-in-out;
  float: left;
}

.walkerton-filter-content button[type="submit"] i {
  font-size: 18.5px;
  transition: all 0.2s ease-in-out;
}

.walkerton-filter-content button[type="submit"]:focus {
  outline: none;
}

.walkerton-filter-content button[type="submit"]:hover {
  background: #fff;
}

.walkerton-filter-content button[type="submit"]:hover i.fa.fa-search {
  color: #6cd086;
}

.walkerton-main-content .ico-cat-parent hr {
  margin: 0;
}

.post-name-listings .fa.fa-calendar-times-o {
  font-size: 13px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.iconfilter-shortcode .blog_custom_listings .walkerton-img-left > a > i,
.blog-posts .blog_custom_listings .walkerton-img-left .ico-icon i {
  font-size: 70px;
  transition: all 0.2s ease-in-out;
  margin-right: 0;
}

.row-2items .blog_custom_listings .walkerton-img-left > a > i,
.row-3items .blog_custom_listings .walkerton-img-left > a > i,
.row-4items .blog_custom_listings .walkerton-img-left > a > i {
  line-height: 70px;
  margin-bottom: 10px;
}

.mt_listings_page.mt_listing_map_location {
  margin-top: 0;
}

.mt_listings--main-pic .play_tour_href {
  overflow: initial !important;
}

.mt-listings-popular-searches {
  display: inline-table;
  list-style: none;
  margin-top: 30px;
  padding-left: 15px;
}

.mt-listings-popular-searches-parent {
  color: #fff;
}

.mt-listings-popular-searches li:hover a {
  color: rgba(255, 255, 255, 0.9) !important;
}

.mt-listings-popular-searches li a {
  transition: all 0.2s ease-in-out;
  color: rgba(255, 255, 255, 0.8) !important;
}

.mt-listings-popular-searches li {
  display: inline-table;
  margin-right: 12px;
  padding: 1px 7px;
  transition: all 0.2s ease-in-out;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.mtsearchform-style-v2 .mt-listings-popular-searches li {
  margin-right: 5px;
  padding: 2px 9px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.mt-listings-popular-searches li:hover {
  border: 2px solid rgba(255, 255, 255, 0.9);
}

.mtsearchform-style-v2 .mt-listings-popular-searches li:hover {
  border: 1px solid rgba(255, 255, 255, 0.9);
}

.mt-car-search .slider-state-submit button {
  font-weight: 700;
  height: 60px !important;
  padding: 0 18px;
  font-size: 14px;
  transition: all 0.25s ease-in-out;
  box-shadow: none;
}

.mt-car-search .select2.select2-container .select2-selection {
  padding-left: 20px;
}

.mt-car-search .slider-state-search .search-field.form-control {
  box-shadow: none;
}

.mt-car-search
  .slider-state-search
  .search-field.form-control::-webkit-input-placeholder {
  color: #483ca8;
}

.mt-car-search
  .slider-state-search
  .search-field.form-control::-moz-placeholder {
  color: #483ca8;
}

.mt-car-search
  .slider-state-search
  .search-field.form-control:-ms-input-placeholder {
  color: #483ca8;
}

.mt-car-search
  .slider-state-search
  .search-field.form-control:-moz-placeholder {
  color: #483ca8;
}

.mt-car-search
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #483ca8;
  font-size: 16px;
}

.mt-car-search .row .slider-state-select {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.mt-car-search .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: #fff;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-border-radius: 0;
  transition: all 300ms ease-out;
  -webkit-transition: all 300ms ease-out;
}

.mt-car-search .row .slider-state-select,
.mt-car-search .row .slider-state-search,
.mt-car-search .row .slider-state-submit {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.post-details .description_container {
  display: flex;
}

.post-details .description_container .mt_listings--features-description {
  align-self: center;
}

.mt_listings--features-description .house_location {
  margin-top: 0;
  display: inline-block;
}

.mt_listings--features-description .house_address {
  display: inline-block;
  font-size: 16px;
  font-weight: 20px;
  color: #c3c3c3;
  font-style: italic;
  font-weight: 400;
  margin-top: 0;
}

.mt_listings--main-pic .play_tour {
  position: absolute;
  bottom: 10px;
  z-index: 3;
  left: 10px;
}

.mt_listings--main-pic a {
  border-radius: 10px;
  display: block;
  overflow: hidden;
}

.mt_listing .blog_custom:hover {
  box-shadow: none;
}

.mt_listings--single-main-pic .post-title {
  margin-right: 20px;
  margin-top: 10px;
}

.mt_listings--single-main-pic img {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.mt_listing--booking-form div.wpcf7 {
  background-color: #fff !important;
  border-radius: 0 0 10px 10px !important;
  margin: 0 !important;
  padding: 25px 0 0 !important;
}

.mt_house--price-container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 0 0 !important;
  overflow: hidden;
}

.mt_listings--single-main-pic {
  position: relative;
}

.mt_listing--single-gallery.mt_listing--featured-single-gallery i {
  margin-right: 5px;
}

.mt_listing--single-gallery.mt_listing--featured-single-gallery {
  border: 2px solid #fff;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: normal;
  padding: 6px 10px;
  transition: all 250ms ease-in-out 0s;
  -o-transition: all 250ms ease-in-out 0s;
  -moz-transition: all 250ms ease-in-out 0s;
  -webkit-transition: all 250ms ease-in-out 0s;
  -ms-transition: all 250ms ease-in-out 0s;
}

.mt_listing--single-gallery.mt_listing--featured-single-gallery:hover {
  color: #000 !important;
  border-color: #000 !important;
}

.contact_insert_input .wpcf7-date {
  padding: 1em 2em !important;
  width: 100% !important;
}

.single .mt_listings--single-main-pic .post-name {
  align-self: end;
  color: #fff;
  margin-bottom: 40px;
  text-transform: none;
  width: 100%;
}

.mt_listing--tax-type {
  display: inline-table !important;
}

.gradient-holder {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000',endColorstr='#66000000',GradientType=0 );
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

article.mt_listing .mt_listings--main-pic a {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  display: block;
  overflow: hidden;
}

.gradient-holder-category {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 45%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 45%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 45%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000',endColorstr='#66000000',GradientType=0 );
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.single-mt_listing .related-posts {
  margin: 70px 0;
}

.mt_listing--booking-form {
  margin-top: 0 !important;
}

.single-mt_listing .related-posts .post-name {
  margin: 0;
}

.mt_listings--main-pic .post-name {
  bottom: 0;
  z-index: 2;
  width: 100%;
  color: #fff !important;
  padding: 30px;
  font-size: 35px;
  line-height: 40px;
  font-weight: 500;
  padding-bottom: 23px;
  padding-top: 23px;
}

.mt_listings--main-pic .post-name a {
  display: inline-block !important;
  font-size: 35px !important;
  font-weight: 500;
  line-height: 40px !important;
  color: #ffd600 !important;
  overflow: visible !important;
}

.mt_listing--single-price-inner {
  background: #009dde;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
}

.mt_listing--single-price-inner-day {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mt_listing--single-price .priceval {
  font-size: 32px;
  opacity: 1;
  color: #fff;
}

.mt_listings--features-description .mt_listing--price {
  background: transparent none repeat scroll 0 0;
  border-radius: 10px;
  display: inline-block;
  font-size: 15px;
  margin-bottom: 3px;
  margin-top: 10px;
  padding: 10px 10px 10px 0;
  text-align: left;
  width: 100%;
}

.mt_listings--main-pic:hover .blog_post_image {
  transform: scale(1.03);
}

.mt_listing--price-day.mt_listing--price .mt_listing_price {
  font-size: 50px;
  font-weight: 900;
  line-height: 60px;
  color: #009dde;
}

.mt_listing--price-day.mt_listing--price .mt_listing_currency {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  position: relative;
  bottom: 35px;
  color: #009dde;
}

.mt_listing--price-day.mt_listing--price .mt_listing_per {
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  position: relative;
  bottom: 13px;
  margin-left: 10px;
  color: #009dde;
}

.single .content-listing-heading:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 150px;
  height: 3px;
}

.single .content-listing-heading {
  font-size: 25px;
  font-weight: 700;
}

.mt_listings--features-description .house_title {
  margin-top: 0;
}

.mt_listings--features-description .house_description {
  margin: 0;
}

.mt_listings--main-pic {
  margin-top: 12px !important;
}

.mt_listings--main-pic .mt_listing--features-group {
  margin: 30px 0;
}

.mt_listings--features-description .single-car-feature {
  margin: 5px 0;
}

.mt_listing--important-features .mt_listing_number_passengers_value,
.mt_listing--important-features .mt_listing_luggage_value,
.mt_listing--important-features .mt_listing_gearbox_value {
  margin-left: 10px;
  position: relative;
  bottom: -2px;
}

.mt_listing--important-features .mt_listing_number_passengers,
.mt_listing--important-features .mt_listing_luggage,
.mt_listing--important-features .mt_listing_gearbox {
  margin-top: 15px;
  margin-bottom: 15px;
  border-right: 1px solid #dadada !important;
}

.mt_listing--important-features span:last-child {
  border: none !important;
}

.mt_listing--features-group .features_items p {
  margin: 0 !important;
}

.mt_listing--features-group .features_items a {
  font-size: 18px !important;
  margin: 0;
  line-height: 29px;
  font-weight: 200;
  color: rgba(69, 70, 70, 0.8);
  margin-left: 5px;
}

.mt_listings--features-description .mt_listing--important-features {
  border: 1px solid #dadada;
  border-radius: 10px;
  margin-top: 12px !important;
  padding: 0;
}

.mt_listing .mt_listing_separator {
  height: 1px;
  background-color: #ddd;
  margin-top: 30px;
  margin-bottom: 30px;
}

.mt_listings--extra-pics .mt_listings--extra-pics-holder img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin-bottom: 0 !important;
}

.mt_listings--extra-pics .mt_listings--extra-pics-holder {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.list-view.mt_listing .blog_custom {
  background: transparent none repeat scroll 0 0 !important;
}

.iconfilter-search {
  margin-bottom: 30px;
}

.single-mt_house .related-posts {
  margin: 70px 0;
  margin-top: 84px;
}

.single-mt_listing .single-icondrops {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  /* background-image: url(../inc/templates/images/single_top_bg.jpg)!important; */
  background-size: cover !important;
}

.single-mt_listing .list_title .mt_listing_category {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.55);
  display: inline-block;
  vertical-align: middle;
}

.single-mt_listing .list_title {
  color: #151515;
  font-weight: 500;
  margin: 10px 0;
}

.mt_listing_header_section {
  padding: 50px 0;
}

.single-mt_listing .mt_listing_category {
  margin: 4px 0;
}

.single-mt_listing .mt_listing_header_section .category_icon_holder {
  float: left;
  width: 60px;
  height: 60px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin-right: 15px;
  margin-left: -10px;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
}

.single-mt_listing .mt_listing_category a {
  color: #002395;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  clear: both;
}

.single-mt_listing .mt_listing_category a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.list_description {
  margin: 30px 0 5px;
}

.list_description p {
  margin-bottom: 0;
}

.list_share_this {
  font-size: 15px;
  color: black;
  display: inline-block;
  cursor: pointer;
}

.single_list_share_this {
  display: inline-block;
  padding-top: 10.5px;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  line-height: 1;
}

.single-ico-social-sharer-tooltip {
  background: #151515 none repeat scroll 0 0;
  bottom: 30px;
  box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
  cursor: default;
  font-size: 0.765em;
  left: -40px;
  line-height: 1.4;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  text-align: left;
  width: auto;
  z-index: 9999;
  -webkit-transform: translate3d(9px, -10px, 0);
  transform: translate3d(9px, -10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.single_list_share_this:hover .single-ico-social-sharer-tooltip {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(20px, 3px, 0px) rotate3d(0, 0, 0, 0);
  transform: translate3d(9px, 5px, 0px) rotate3d(0, 0, 0, 0);
}

.single-ico-social-sharer-tooltip ul.single-ico-social-sharer {
  color: #fff;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  margin-bottom: 0;
  min-width: 235px;
  padding: 0 10px;
}

.single-ico-social-sharer-tooltip ul.single-ico-social-sharer::after {
  content: "";
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #151515;
  border-width: 10px;
  margin-left: -10px;
}

.list_share_this i {
  padding-left: 10px;
}

.single-post .single_list_share_this {
  margin-right: 0;
}

.list_excerpt p {
  color: #fff;
  margin-bottom: 0;
  padding: 20px 0;
  font-weight: 300;
}

.single-mt_listing .single-icondrops-tabs .ico-info,
.single-mt_listing .posts-listings .ico-info {
  margin: 0;
  background: #fff;
  margin-bottom: 30px;
  box-shadow: 0 0 38px rgba(59, 77, 133, 0.09);
}

.single-icondrops-tabs .row div:last-child .ico-info {
  margin-bottom: 0 !important;
}

.single-mt_listing .info-ico {
  padding: 40px;
  border-bottom: 1px solid #e0e0e0;
}

.single-mt_listing .info-ico h5 {
  margin: 10px 0 0;
  line-height: 1.5;
}

.single-mt_listing .middle-desk {
  padding: 20px 10px 27px;
}

.single-mt_listing .mt_listing_end_date_details {
  font-size: 16px;
  padding: 10px 0;
}

.single-mt_listing .mt_listing_token_sale_date {
  margin: 0 0 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  display: inline-block;
}

.single-mt_listing .mt_listing_token_sale_date i {
  color: #002395;
  margin-right: 7px;
  width: 18px;
}

.single-mt_listing .token-sale h5 {
  margin: 20px 0;
}

.single-mt_listing .mt_listing_goal_money {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

.single-mt_listing .mt_listing_received {
  color: #002395;
  font-weight: 400;
  font-size: 25px;
}

.mt_listing_received-goal {
  font-weight: 400;
  margin-bottom: 25px;
}

.mt_listing_button {
  padding: 20px 0;
}

.single-mt_listing .mt_listing_website_button,
.single-mt_listing .mt_listing_whitepaper_button,
.single-mt_listing .mt_listing_purchase_button,
.single-mt_listing .mt_listing_video_button {
  padding: 15px 30px;
  margin: 0 10px 0 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  display: inline-block;
}

.single-mt_listing .mt_listing_website_button:last-child,
.single-mt_listing .mt_listing_whitepaper_button:last-child,
.single-mt_listing .mt_listing_purchase_button:last-child,
.single-mt_listing .mt_listing_video_button:last-child {
  margin-right: 0;
}

.single-mt_listing .mt_listing_website_button {
  background: #6cd086;
}

.single-mt_listing .mt_listing_whitepaper_button {
  background: #6cd086;
}

.single-mt_listing .mt_listing_purchase_button,
.single-mt_listing .mt_listing_video_button {
  background: #6cd086;
}

.mt_listing_start_end_date {
  padding: 10px 0;
}

.single-mt_listing .token-sale-column .social-links a i {
  font-size: 16px;
  color: rgba(37, 37, 37, 0.3);
  margin: 10px 5px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.single-mt_listing .token-sale-column .social-links a i:hover {
  color: #002395;
}

.mt_listing_start_date_pick {
  display: inline-block;
  padding: 15px 30px;
  margin: 20px 10px 20px 0;
  background-color: #5b50b1;
  font-size: 16px;
  color: #fff;
}

.mt_listing_end_date_pick {
  display: inline-block;
  padding: 15px 30px;
  margin: 20px 10px 20px 0;
  background-color: #5b50b1;
  font-size: 16px;
  color: #fff;
}

.mt_listing_start_date_pick .mt_listing_date,
.mt_listing_end_date_pick .mt_listing_date {
  display: inline-block;
  font-size: 16px !important;
  font-weight: 400;
  color: #fff;
  margin: 0;
}

.mt_video_tour {
  display: inline-block;
  margin: 20px 0;
}

.mt_video_tour .mt_listing_video_button {
  margin: 0 20px 0 0;
}

.single-icondrops .token-sale-column {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 50px 15px;
  background: #f7f7f7;
  margin: 40px 10px;
}

.mt_listing--single-gallery .ico-screenshot {
  position: relative;
  margin-top: 10px;
  height: 130px;
  border: 1px solid #f6f6f6;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2), 0 0 15px 0 rgba(0, 0, 0, 0.01);
}

.mt_listing--single-gallery img {
  width: 100%;
}

.mt_listing--single-gallery .flex-zone {
  transform: scale(0);
  background: transparent none repeat scroll 0 0;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  transition: all 250ms ease 0s;
  -webkit-transition: all 250ms ease 0s;
  opacity: 0;
  visibility: hidden;
}

.mt_listing--single-gallery .flex-zone-inside {
  align-self: center;
  background: #002395 none repeat scroll 0 0;
  border-radius: 0;
  color: #fff;
  font-weight: 400;
  margin: 0 auto;
  opacity: 1;
  padding: 10px;
  transition: all 250ms ease 0s;
  -webkit-transition: all 250ms ease 0s;
  width: 50px;
}

.mt_listing--single-gallery .ico-screenshot:hover .flex-zone {
  background: rgba(29, 39, 128, 0.8) none repeat scroll 0 0;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.panel-single-icondrops {
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
  -ms-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  margin-bottom: 10px !important;
  border-radius: 5px 5px 0 0 !important;
}

.panel-single-icondrops .panel-body {
  font-weight: 400;
  opacity: 1;
}

.single-icondrops-content {
  padding: 10px 5px;
  display: inline-block;
  width: 100%;
}

.single-icondrops-content h5 strong {
  color: #483ca8;
}

.single-icondrops-content .skill {
  color: #483ca8;
  float: right;
  font-weight: 400;
}

.single-icondrops-content p a,
.single-icondrops-content p {
  font-weight: 300;
}

.single-icondrops-content p,
.single-icondrops-content h5,
.single-icondrops-content h5 p {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.single-icondrops-content p a,
.single-icondrops-content a,
.single-icondrops-content h5 a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  color: #a7a7a7;
}

.single-icondrops-content a:hover,
.single-icondrops-content h5 a:hover {
  color: #3e3492;
}

.row-2items .blog_custom_listings {
  padding: 30px 30px 35px;
}

.blog_custom_listings {
  padding: 30px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  border: 1px solid #ddd;
}

.single .ico-info-button i {
  margin-right: 5px;
}

.ico-listing-category-style-v2 .blog_custom_listings:hover {
  background-color: #e0e0e0 !important;
}

.ico-listing-category-style-v2 .blog_custom_listings {
  border: 2px solid #e5e3eb;
  background: #f9f9f9 !important;
}

.ico-listing-category-style-v2 .is-sticky .blog_custom_listings,
.ico-listing-category-style-v2 .sponsored_ico .blog_custom_listings {
  border: 2px solid #252525;
  background: #f9f9f9;
}

.walkerton-single-list-item .blog_custom_listings > .row,
.search-post .main-content > .row {
  display: contents;
}

.blog_custom_listings.is-sticky,
.blog_custom_listings.sponsored_ico {
  border: 2px solid #252525 !important;
  background: #e2f6e7;
  position: relative;
}

.icondrops-shortcode .sponsored_ico .blog_custom_listings::after {
  top: 0;
  right: 0;
  border-top-right-radius: 0;
}

.blog_custom_listings .post-name-listings,
.blog-posts .list_title {
  line-height: 1;
  font-weight: 500;
  margin-top: 5px;
}

.iconfilter-shortcode .col-md-3 .blog_custom_listings .post-name-listings a {
  font-size: 20px;
  margin-top: 25px;
  display: block;
}

.iconfilter-shortcode
  .col-md-3
  .blog_custom_listings
  .walkerton-metas
  .ico-type
  div,
.iconfilter-shortcode
  .col-md-3
  .blog_custom_listings
  .walkerton-metas
  .ico-category
  div {
  font-size: 12px;
}

.iconfilter-shortcode .col-md-3 .blog_custom_listings {
  padding: 30px 20px;
}

.iconfilter-shortcode .col-md-3 .blog_custom_listings img {
  max-width: 90px;
}

.iconfilter-shortcode .col-md-3 .walkerton-details-content {
  font-weight: 300;
  margin: 15px 0 20px;
}

.blog_custom_listings .post-name-listings a,
#DataTable-icondrops-active .ico-row .list_title a {
  font-size: 24px;
  line-height: 1;
}

.blog_custom_listings .post-name-listings a:hover,
#DataTable-icondrops-active .ico-row .list_title a:hover,
.blog_custom .post-name a:hover,
.events-title-subtitle a:hover {
  opacity: 0.9;
  transition: all 0.2s ease-in-out;
}

.blog_custom_listings .post-name-listings span,
.blog-posts .ico-main-info .mt_listing_end_date {
  font-size: 14px;
  color: #4f4f4f;
  font-weight: 300;
  position: relative;
  top: -1px;
  margin-right: 30px;
}

.blog_custom_listings .walkerton-details-content {
  font-weight: 300;
  margin: 8px 0 11px;
}

.blog_custom_listings .mt_listing_category_recent,
.blog_custom_listings .mt_listing_token_name {
  font-size: 14px;
  margin: 5px 0;
}

.blog_custom_listings .mt_listing_category_recent img {
  width: 15px;
  vertical-align: text-top;
  margin-right: 3px;
}

.col-md-3.row-4items {
  margin-bottom: 15px;
}

.blog_custom_listings .recent-received-goals h6 {
  color: #002395;
  font-size: 16px;
}

.blog_custom_listings .percentange {
  float: right;
}

.blog_custom_listings .goal {
  color: #252525;
}

.blog_custom_listings .mt_listing_interest_end_date .ico-drops-date {
  float: right;
}

.blog_custom_listings .mt_listing_interest_end_date {
  color: #252525;
}

.ico-drops-interest {
  text-transform: capitalize;
}

.single-icondrops-related-listings .blog_custom_listings .featured-icon i {
  font-size: 70px !important;
}

.single-icondrops-related-listings .walkerton-metas {
  margin-top: 10px;
}

.single-icondrops-related-listings .blog_custom_listings {
  padding: 30px 30px 35px;
  border: 2px solid #e5e3eb;
}

.single-icondrops-related-listings
  .blog_custom_listings
  .post-name-listings
  span {
  margin-left: 15px;
}

.single-icondrops-related-listings .blog_custom_listings .post-name-listings {
  margin-top: 0;
}

.single-icondrops .col-md-2 .blog_post_image {
  border-radius: 5px;
}

.blog_custom_listings img {
  max-width: 60px;
}

.icondrops-shortcode .blog_custom_listings {
  background: #fff;
  background: #fff;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 15px;
  margin-bottom: 1px;
  border: 1px solid #e0e0e0;
}

.single-icondrops .progress-bar {
  text-align: center !important;
  transition-duration: 3s;
  background-color: #1d2780 !important;
}

.single-icondrops .progress.skill-bar {
  margin-top: 20px;
}

.single-icondrops .ico-info {
  padding: 80px 10px 20px;
  background: 0 0;
}

#DataTable-icondrops-active tr {
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
}

#DataTable-icondrops-active tr:hover {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
}

.single-mt_listing .featured-icon {
  text-align: center;
}

.single-mt_listing .ico-info .featured-icon i {
  font-size: 83px;
  text-align: center;
  line-height: 1 !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.icondrops-shortcode .blog_custom_listings i {
  font-size: 50px !important;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  line-height: 1 !important;
}

#DataTable-icondrops-active_wrapper .pagination > li > a.current,
#DataTable-icondrops-active_wrapper .pagination > li > a:hover {
  background-color: #1d2780 !important;
  color: #fff;
  border-color: #1d2780;
}

#DataTable-icondrops-active_wrapper .pagination > li.previous > a.current,
#DataTable-icondrops-active_wrapper .pagination > li.previous > a:hover {
  border-right: 1px solid #fff !important;
}

#DataTable-icondrops-active_wrapper .pagination > li.next > a.current,
#DataTable-icondrops-active_wrapper .pagination > li.next > a:hover {
  border-left: 1px solid #fff !important;
}

.pagination > li > a,
.pagination > li > span {
  margin-left: 0 !important;
}

.single-icondrops-related-listings {
  padding: 105px 0 60px;
  background: #fff;
}

.related-listings-title {
  margin-bottom: 50px;
}

.related-listings-title h1 {
  font-weight: 400;
  color: #483ca8;
  text-align: center;
  margin-top: 0;
}

.related-listings-title h4 {
  font-weight: 400;
  color: #483ca8;
  text-align: center;
}

.row-1items {
  margin: 10px 0;
}

.iconfilter-shortcode .ico-cat-parent-link {
  margin: 0 15px;
}

.iconfilter-shortcode .ico-cat-parent-link a {
  clear: both;
  display: inline-block;
  color: #fff;
  background: #ff2122;
  padding: 15px 50px;
  text-align: center;
  margin-top: 10px;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.iconfilter-shortcode .ico-cat-parent-link a:hover {
  background: #216fff;
}

.mtlistings-ico-style-v2 .row-1items {
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-bottom: solid 0 #ecebf6;
}

.row-2items {
  padding-bottom: 25px;
}

.row-2items .walkerton-details-title {
  width: 100%;
}

.row-3items {
  padding-bottom: 25px;
}

.row-3items .blog_custom_listings .post-name-listings span {
  display: block;
  margin-left: 0;
  margin-top: 7px;
}

.row-3items .walkerton-img-left a {
  text-align: left;
}

.row-3items .walkerton-details {
  width: 100%;
}

.row-3items .walkerton-metas a {
  padding: 4px 5px !important;
}

.row-3items .walkerton-metas a {
  margin-right: 2px;
}

.row-4items .walkerton-img-left a {
  text-align: left;
}

.row-4items .blog_custom_listings .post-name-listings span {
  display: block;
  margin-left: 0;
  margin-top: 7px;
}

.row-4items
  .walkerton-details
  .listings_details
  .walkerton-metas-single-meta.ico-type {
  margin-bottom: 10px;
}

.single-ico-share {
  float: right;
  margin-top: 40px;
}

.single-ico-social-sharer > li {
  display: block;
  float: left;
  text-align: center;
  padding: 10px;
}

.single-ico-social-sharer li a {
  color: #fff;
  display: block;
  line-height: normal;
  font-size: 20px;
  transition: all 300ms;
  -o-transition: all 300ms;
  -ms-transition: all 300ms;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
}

.single-ico-social-sharer li:last-child {
  padding-right: 0 !important;
}

.single-ico-social-sharer li a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.mt_listing_header_section .embed {
  display: inline-block;
  float: right;
  margin-top: 10px;
}

.mt_listing_header_section .embed button {
  font-size: 15px;
  font-weight: 300;
  background: #002395 !important;
  border: 0;
  padding: 4px 10px !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
  -moz-border-radius: 5px;
  outline: 0;
}

.single-ico-social-sharer .embed button i {
  font-size: 18px;
  font-weight: 400;
}

.mt_listing_header_section .embed button:hover {
  background: #216fff !important;
}

.modal-body-view {
  padding-bottom: 50px;
}

.embed-modal-content {
  border-radius: 5px !important;
}

.embed-modal-content .modal-footer button {
  background: #002395;
  color: #fff;
  font-weight: 500;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -ms-border-radius: 3px;
  -moz-border-radius: 3px;
}

.embed-modal-content .modal-footer button:hover {
  background: #4f4f4f;
  color: #fff;
}

.single-mt_listing .light .single-icondrops {
  background: #fff;
  /* background-image: url(../inc/templates/images/light_single_top_bg.jpg)!important; */
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}

.single-mt_listing .light .list_title,
.light .list_excerpt p,
.light .single-ico-social-sharer li a,
.light .thumbs-rating-container .thumbs-rating-up,
.light .thumbs-rating-container .thumbs-rating-down,
.light .modeltheme-countdown .days-name,
.light .modeltheme-countdown .hours-name,
.light .modeltheme-countdown .minutes-name,
.light .modeltheme-countdown .seconds-name,
.light .panel-single-icondrops {
  color: #252525;
}

.light .thumbs-rating-container .thumbs-rating-up i,
.light .thumbs-rating-container .thumbs-rating-down i {
  color: #fff;
}

.light .panel-single-icondrops {
  background: #fff;
}

.light.mt_listing .single-icondrops-content .progress {
  background: #4f4f4f;
}

.light .modeltheme-countdown .days-digit,
.light .modeltheme-countdown .hours-digit,
.light .modeltheme-countdown .minutes-digit,
.light .modeltheme-countdown .seconds-digit {
  box-shadow: 0 0 38px rgba(59, 77, 133, 0.09);
}

.mt-single-event .mt-event-cat a {
  padding: 4px 10px;
  margin-right: 5px;
  transition: all 0.2s ease-in-out;
}

.mt-single-event .mt-event-cat a:focus,
.mt-single-event .mt-event-cat a:hover {
  background: #eaeaea;
}

.mt-single-event-inner.sponsored-event:hover {
  background: #d4f1db;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.mt-single-event-inner:hover {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.single .single-icondrops-content h5 {
  font-size: 16px;
}

#listings_metaboxs .cmb_id_mt_select_font,
#listings_metaboxs .cmb_id_mt_font_simple_line_icons,
#listings_metaboxs .cmb_id_mt_font_awesome_icons,
#listings_metaboxs .cmb_id_mt_font_cryptocoins_icons {
  display: none;
}

#listings_metaboxs th label {
  text-align: right;
}

#listings_metaboxs td {
  text-align: left;
  position: relative;
}

#listings_metaboxs select,
#listings_metaboxs input[type="text"] {
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 0;
  padding: 15px;
  width: 100% !important;
  box-shadow: none;
  outline: 0;
}

#listings_metaboxs input[type="submit"] {
  padding: 15px 45px;
  border-radius: 0;
  border: 0;
  color: #fff;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  margin-top: 15px;
  margin-bottom: 40px;
  text-transform: uppercase;
  background: #002395 !important;
  font-weight: 400;
  outline: 0;
}

#listings_metaboxs input[type="submit"]:hover {
  background: #216fff !important;
}

#listings_metaboxs table.cmb_metabox th label {
  margin-top: -20px;
}

#listings_metaboxs table {
  border: 0;
}

#listings_metaboxs td {
  border: 0 !important;
}

#listings_metaboxs th {
  border: 0 !important;
}

#listings_metaboxs {
  border: 2px solid #fff;
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0 !important;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
}

#listings_metaboxs .cmb-type-title {
  border-radius: 5px 5px 0 0 !important;
  background: #483ca8;
  color: #fff;
}

#listings_metaboxs .cmb-type-title h1 {
  margin: 0 0 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
}

#listings_metaboxs .cmb-type-title td {
  padding: 20px 30px 25px !important;
}

#listings_metaboxs
  .cmb-type-title.cmb_id_mtlistings_general_group:first-child
  td {
  border-radius: 5px 5px 0 0 !important;
}

#listings_metaboxs .cmb_id_mt_listing_crypto_coin_name td,
#listings_metaboxs .cmb_id_mt_listing_crypto_coin_name th,
#listings_metaboxs .cmb_id_mt_listing_facebook td,
#listings_metaboxs .cmb_id_mt_listing_facebook th,
#listings_metaboxs .cmb_id_mt_listing_fundraising_goal td,
#listings_metaboxs .cmb_id_mt_listing_fundraising_goal th,
#listings_metaboxs .cmb_id_mt_video_tour td,
#listings_metaboxs .cmb_id_mt_video_tour th {
  padding-top: 40px !important;
}

#listings_metaboxs .cmb_id_mt_listing_purchase_button th,
#listings_metaboxs .cmb_id_mt_listing_purchase_button td,
#listings_metaboxs .cmb_id_mt_listing_youtube th,
#listings_metaboxs .cmb_id_mt_listing_youtube td,
#listings_metaboxs .cmb_id_mt_listing_other_tokens th,
#listings_metaboxs .cmb_id_mt_listing_other_tokens td {
  padding-bottom: 40px !important;
}

#listings_metaboxs table tr th {
  padding-left: 50px !important;
}

#listings_metaboxs table tr td {
  padding-right: 50px !important;
}

#listings_metaboxs span.cmb_metabox_description {
  display: block;
}

#listings_metaboxs label.error {
  right: 15px;
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}

#listings_metaboxs #mt_ico_icon {
  width: 76% !important;
}

#listings_metaboxs .cmb_upload_button.button {
  padding: 15px 30px;
  font-weight: 700;
  text-transform: uppercase;
  border: 0;
  color: #fff;
  background: #002395;
  margin-right: 0;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
  -moz-border-radius: 5px;
}

#listings_metaboxs .cmb_upload_button.button:hover {
  background: #216fff;
}

.ico-directory-listing.alert-dismissible {
  border-radius: 4px;
}

.ico-directory-listing.alert-dismissible .close {
  right: 0;
}

.ico-goal {
  float: right;
}

.ico-goal .received,
.ico-goal .goal {
  display: inline-block;
  color: #6cd086;
  font-weight: 400;
}

.ico-goal-title {
  line-height: 14px;
  font-size: 14px;
  color: #483ca8;
  font-weight: 400;
}

.blog-posts .blog_custom_listings .list_title,
.blog-posts .blog_custom_listings .review-recent,
.blog-posts .blog_custom_listings .end-date {
  display: inline-block;
}

.blog-post-list-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: solid 2px #ecebf6;
}

.search-post .blog-post-list-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: solid 0 #ecebf6;
}

.ico-listing-category-style-v2 .blog-post-list-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: solid 0 #ecebf6;
}

.blog-post-list-item:last-child {
  padding-bottom: 0 !important;
  border-bottom: solid 0 #ecebf6 !important;
}

.modeltheme-pagination-holder {
  margin-top: 30px;
}

.blog-posts .modeltheme-pagination-holder {
  margin-top: 0;
}

.tax-mt-listing-category .modeltheme-pagination-holder,
.search-post .modeltheme-pagination-holder {
  margin-top: 30px;
}

@media only screen and (max-width: 1280px) {
  #DataTable-icondrops-active_wrapper {
    overflow-x: scroll;
  }
}

.mt_ico_video_resources .embed-responsive.embed-responsive-16by9 {
  padding-bottom: initial !important;
}

.mt_ico_video_resources .embed-responsive {
  height: 100px !important;
}

.mt_ico_video_resources .ytp-large-play-button {
  display: none !important;
}

.mt_ico_video_resources .ico-icon {
  position: absolute;
  top: 65px;
  right: 0;
  background: #fff;
  text-align: center;
  max-width: 60px;
  border-radius: 5px;
}

.mt_ico_video_resources .ico-icon i.cc {
  margin: 0 !important;
  font-size: 3.5rem !important;
}

.mt_ico_video_resources .ico-icon .featured-icon {
  text-align: center;
  padding: 0 7px;
}

.mt_ico_video_resources_desc {
  padding: 25px 24px;
}

.mt_ico_video_resources .blog_custom {
  border: 1px solid #e8ecf1;
  border-radius: 2px;
}

.mt_ico_video_resources .post-name {
  margin: 10px 0;
}

.mt_ico_video_resources .review-recent {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mt_ico_video_resources .mt_ico_video_resources_category {
  padding: 4px 10px;
  color: #252525;
  border: 1px solid #e5e3eb;
  display: -webkit-inline-box;
}

.mt_ico_video_resources .mt_ico_video_resources_category img {
  width: 15px;
  vertical-align: text-top;
  margin-top: 2px;
  margin-right: 3px;
  border-radius: 0;
}

.mt_ico_video_resources .ytp-large-play-button,
.mt_ico_video_resources .ytp-button {
  display: none !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.rating-margin-top {
  margin-top: 16.5%;
}

.single-icondrops-adplace-top {
  text-align: center;
  padding-bottom: 40px;
}

.single-icondrops-adplace-top-content {
  padding-bottom: 40px;
}

.single-icondrops-adplace-bottom {
  text-align: center;
}

.single-icondrops-adplace-bottom-content {
  padding-bottom: 30px;
}

.adplace-blog-post {
  padding-bottom: 10px;
}

.adplace-search,
.adplace-category {
  padding-bottom: 10px;
  text-align: center;
}

.adplace-row-1items {
  padding: 10px 0;
  text-align: center;
}

.mtlistings-ico-style-v2 .adplace-row-1items {
  padding: 0 0 10px;
  text-align: center;
}

.mtlistings-ico-style-v2 .adplace-row-1items img,
.mtlistings-ico-style-v2 .adplace-row-2items img,
.mtlistings-ico-style-v2 .adplace-row-3items img,
.mtlistings-ico-style-v2 .adplace-row-4items img,
.search-results .search-adplace img,
.ico-listing-category-style-v2 .search-category img,
.search-post .adplace-search img,
.adplace-category img {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
  -moz-border-radius: 5px;
}

.adplace-row-3items {
  padding: 0 15px 15px !important;
}

.adplace-row-4items {
  padding: 0 15px 15px !important;
}

.thumbs-rating-container {
  padding: 0;
  display: block;
}

.thumbs-rating-container span {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.thumbs-rating-container span i {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

.thumbs-rating-container span:before {
  content: attr(data-text);
}

.thumbs-rating-container span.thumbs-rating-down:hover i {
  background: #de2d1b;
}

.thumbs-rating-container .thumbs-rating-up,
.thumbs-rating-container .thumbs-rating-down {
  padding: 0;
  color: #fff;
  font-size: 14px;
  display: inline;
}

.thumbs-rating-container span.number {
  display: block;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-top: 0;
  color: #fff !important;
}

.single-mt_listing .mt_listing_website_button i {
  margin-right: 5px;
}

.thumbs-rating-container .thumbs-rating-up i {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.thumbs-rating-container .thumbs-rating-down i {
  background: #e74c3c;
  padding: 19px;
  margin-right: 8px;
  height: 55px;
  width: 55px;
  text-align: center;
}

.thumbs-rating-container .thumbs-rating-already-voted {
  padding: 5px 10px;
  font-size: 14px;
  display: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #fff;
  color: #151515;
  cursor: text;
  position: absolute;
  right: 0;
  margin-top: 2px;
}

.thumbs-rating-container .thumbs-rating-already-voted:before {
  content: attr(data-text);
}

.thumbs-rating-up {
  margin-bottom: 0;
  display: block;
  width: 200px;
}

#listings_metaboxs .cmb_id_mt_listing_kyc .cmb_metabox_description,
#listings_metaboxs .cmb_id_mt_listing_interest .cmb_metabox_description,
#listings_metaboxs .cmb_id_mt_select_rating_star .cmb_metabox_description,
#listings_metaboxs span.cmb_metabox_description,
#listings_metaboxs span.cmb_metabox_description strong {
  font-weight: 300;
  font-style: normal;
  padding-top: 5px;
}

#listings_metaboxs .cmb_id_mt_video_tour p {
  font-weight: 400;
  font-style: normal !important;
  padding-top: 5px;
}

#DataTable-icondrops-active .mt_listing_category a,
#DataTable-icondrops-active .mt_listing_interest {
  background: #f4f4f4;
  padding: 4px 10px;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
}

#DataTable-icondrops-active .mt_listing_category a:hover,
#DataTable-icondrops-active .mt_listing_category a:focus,
#DataTable-icondrops-active .mt_listing_interest:hover,
#DataTable-icondrops-active .mt_listing_interest:focus {
  background: #eaeaea;
}

#DataTable-icondrops-active .mt_listing_interest {
  color: #e74c3c !important;
}

.mt_listing--youtube_video {
  padding: 0 5px;
  position: relative;
}

.popup-play-video {
  position: absolute;
  width: 15%;
  top: 50%;
  left: 50%;
  margin-left: -7.5%;
  margin-top: -7.5%;
}

.popup-play-video img:hover {
  transform: scale(1.1);
}

.popup-play-video img {
  transform: scale(1);
  transition: all 300ms ease-in-out 0s;
  -ms-transformtransition: all 400ms ease-in-out 0s;
  -webkit-transformtransition: all 400ms ease-in-out 0s;
}

.mtlistings-events-style-blue .events-title-subtitle a,
.mtlistings-events-style-blue .events-title-subtitle p,
.mtlistings-events-style-blue .event-meta > div {
  color: #5144ad;
}

.mtlistings-events-style-blue .mt-single-event .mt-event-cat a {
  color: #5144ad;
}

.mtlistings-events-style-red .events-title-subtitle a,
.mtlistings-events-style-red .events-title-subtitle p,
.mtlistings-events-style-red .event-meta > div {
  color: #483ca8;
}

.mtlistings-events-style-red .mt-single-event .mt-event-cat a {
  color: #483ca8;
}

.mtlistings-events-style-blue .mt-single-event-inner,
.mtlistings-events-style-blue .mt-single-event-inner {
}

.ico-drops-category {
  margin-bottom: 15px;
  padding: 30px;
  box-shadow: 0 0 38px rgba(59, 77, 133, 0.08);
}

.ico-drops-category h3 {
  margin: 0;
  font-weight: 400;
}

.icondrops-slider-shortcode {
  background: #f6f6f6;
  padding: 50px 0;
}

.icondrops-slider-shortcode .blog_custom_listings {
  background: #fff;
  padding: 0;
}

.icondrops-slider-shortcode .blog_custom_listings .post-name-listings {
  font-weight: 400;
  margin: 0;
  padding: 15px 0 35px;
  text-align: center;
}

.icondrops-slider-shortcode .blog_custom_listings .post-name-listings a {
  color: #fff;
}

.icondrops-slider-shortcode .sponsored_ico .blog_custom_listings::after {
  border-width: 2em !important;
}

.icondrops-slider-shortcode .sponsored_ico .blog_custom_listings::before {
  width: 25px;
}

.icondrops-slider-shortcode .sponsored_ico .blog_custom_listings::after {
  top: 0;
  right: 0;
  border-top-right-radius: 0;
}

.icodrops-slider-content-top {
  background-color: #252525;
  text-align: center;
}

.icodrops-slider-content-logo {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
}

.icodrops-slider-content-logo img {
  border-radius: 50px;
  border: 2px solid #fff;
  max-width: 50px;
  margin: 0 auto;
}

.icondrops-slider-shortcode .sponsored_ico .blog_custom_listings:hover {
  background-color: #e0e0e0 !important;
}

.ico-drops-slider-date {
  padding: 35px 0 15px;
  text-align: center;
}

.icodrops-slider-dot {
  color: #6cd086;
}

.testimonials-style-blue .testimonial02_text {
  border: 0 solid #e5e3eb;
  box-shadow: 0 0 38px rgba(59, 77, 133, 0.08);
}

.testimonials-style-blue .testimonial02_title,
.testimonials-style-blue .testimonial02_rating_titles p,
.testimonials-style-blue .testimonial02_text_content p {
  color: #5144ad;
}

.testimonials-style-blue.owl-theme .owl-controls .owl-page.active span {
  background-color: #5144ad !important;
}

.mtlistings-ico-style-v2 .walkerton-filters .filter a {
  transition: all 0.2s ease-in-out;
  padding: 15px 20px;
  color: #252525;
  width: 100%;
}

.mtlistings-ico-style-v2 .walkerton-filters .filter:hover a {
  color: #fff;
}

.mtlistings-ico-style-v2 .walkerton-filter-content input[type="text"],
.mtlistings-ico-style-v2 .walkerton-filter-content button[type="submit"] {
  width: 49%;
  padding: 13.5px 20px;
  border: solid 2px #252525;
  color: #252525;
}

.mtlistings-ico-style-v2
  .walkerton-filter-content
  input[type="text"]::-webkit-input-placeholder {
  color: #252525;
}

.mtlistings-ico-style-v2
  .walkerton-filter-content
  input[type="text"]::-moz-placeholder {
  color: #252525;
}

.mtlistings-ico-style-v2
  .walkerton-filter-content
  input[type="text"]:-ms-input-placeholder {
  color: #252525;
}

.mtlistings-ico-style-v2
  .walkerton-filter-content
  input[type="text"]:-moz-placeholder {
  color: #252525;
}

.mtlistings-ico-style-v2 .walkerton-filter-content button[type="submit"] {
  padding: 13px;
  background: #252525;
  border: solid 2px #252525;
  color: #fff !important;
}

.mtlistings-ico-style-v2 .walkerton-filter-content button[type="submit"]:hover {
  color: #fff !important;
  background: #6cd086;
  border: solid 2px #6cd086;
}

.mtlistings-ico-style-v2 .walkerton-main-content .ico-cat-parent,
.mtlistings-ico-style-v2 .blog-posts .ico-cat-parent {
  background: 0 0;
  padding: 0;
  box-shadow: none;
}

.mtlistings-ico-style-v2 .ico-cat-parent-title {
  margin-bottom: 30px;
  padding: 30px;
  box-shadow: 0 0 38px rgba(59, 77, 133, 0.08);
}

.mtlistings-ico-style-v2 .ico-cat-parent-title > h3 {
  font-size: 30px;
  margin: 0;
  font-weight: 400;
}

.mtlistings-ico-style-v2 .ico-cat-parent-title > p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.mtlistings-ico-style-v2 .row-2items.walkerton-single-list-item,
.mtlistings-ico-style-v2 .row-3items.walkerton-single-list-item,
.mtlistings-ico-style-v2 .row-4items.walkerton-single-list-item {
  padding: 15px !important;
}

.mtlistings-ico-style-v2
  .row-2items.walkerton-single-list-item:nth-child(-n + 2),
.mtlistings-ico-style-v2
  .row-3items.walkerton-single-list-item:nth-child(-n + 3),
.mtlistings-ico-style-v2
  .row-4items.walkerton-single-list-item:nth-child(-n + 4) {
  padding-top: 0 !important;
}

.mtlistings-ico-style-v2
  .row-2items.walkerton-single-list-item:nth-last-child(-n + 1),
.mtlistings-ico-style-v2
  .row-3items.walkerton-single-list-item:nth-last-child(-n + 1),
.mtlistings-ico-style-v2
  .row-4items.walkerton-single-list-item:nth-last-child(-n + 4) {
  padding-bottom: 30px !important;
}

.mtlistings-ico-style-v2.iconfilter-shortcode .blog_custom_listings {
  border: 2px solid #e5e3eb;
  background: #f9f9f9 !important;
}

.mtlistings-ico-style-v2 .is-sticky .blog_custom_listings,
.mtlistings-ico-style-v2 .sponsored_ico .blog_custom_listings {
  border: 2px solid #252525;
  background: #f9f9f9 !important;
}

.mtlistings-ico-style-v2.iconfilter-shortcode .blog_custom_listings:hover {
  background-color: #e0e0e0 !important;
}

.mtlistings-ico-style-v2 .is-sticky .blog_custom_listings::after,
.mtlistings-ico-style-v2 .sponsored_ico .blog_custom_listings::after {
  border-width: 2.5em !important;
  border-right-color: #6cd086 !important;
  border-top-color: #6cd086 !important;
  border-top-right-radius: 3px;
}

.mtlistings-ico-style-v2 .is-sticky .blog_custom_listings::before,
.mtlistings-ico-style-v2 .is-sticky .blog_custom_listings::after,
.mtlistings-ico-style-v2 .sponsored_ico .blog_custom_listings::before,
.mtlistings-ico-style-v2 .sponsored_ico .blog_custom_listings::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  border-color: transparent;
  border-style: solid;
}

.mtlistings-ico-style-v2 .is-sticky .blog_custom_listings::before,
.mtlistings-ico-style-v2 .sponsored_ico .blog_custom_listings::before {
  content: "\f005";
  position: absolute;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  width: 30px;
  right: 0;
  top: 10px;
  z-index: 3;
  font-family: fontawesome;
}

.mtlistings-ico-style-v1 .row-3items .walkerton-img-left,
.mtlistings-ico-style-v2 .row-3items .walkerton-img-left {
  width: initial;
  margin-right: 15px;
}

.mtlistings-ico-style-v2
  .row-2items
  .blog_custom_listings
  .walkerton-img-left
  > a
  > i,
.mtlistings-ico-style-v2
  .row-3items
  .blog_custom_listings
  .walkerton-img-left
  > a
  > i,
.mtlistings-ico-style-v2
  .row-4items
  .blog_custom_listings
  .walkerton-img-left
  > a
  > i {
  line-height: 50px;
  margin-bottom: 0;
}

.mtlistings-ico-style-v2.iconfilter-shortcode
  .blog_custom_listings
  .walkerton-img-left
  > a
  > i {
  font-size: 50px;
  margin-right: 20px;
}

.mtlistings-ico-style-v1 .walkerton-details-title,
.mtlistings-ico-style-v2 .walkerton-details-title {
  margin: 5px 0;
}

.mtlistings-ico-style-v2 .blog_custom_listings .post-name-listings a {
  color: #252525;
}

.mtlistings-ico-style-v2 .blog_custom_listings .post-name-listings span,
.ico-listing-category-style-v2 .ico-main-info .mt_listing_end_date {
  color: rgba(37, 37, 37, 0.6);
}

.mtlistings-ico-style-v2 .blog_custom_listings .walkerton-details-content,
.mtlistings-events-style-v2 .events-title-subtitle p,
.ico-listing-category-style-v2 .list_description {
  color: rgba(37, 37, 37, 0.8);
}

.mtlistings-ico-style-v2 .ico-cat-parent-subtitle {
  background: #331f6c !important;
  margin-bottom: 60px;
}

.row-2items .walkerton-img-left {
  width: initial;
  margin-right: 22px;
}

.row-2items .walkerton-details-title {
  width: initial;
}

.row-2items .blog_custom_listings .post-name-listings a,
.row-2items .blog_custom_listings .post-name-listings span {
  display: block;
}

.row-2items .blog_custom_listings .post-name-listings span {
  margin-top: 7px;
}

.ico-listing-category-style-v2 .walkerton-single-list-item {
  padding: 0 0 10px;
}

.mtlistings-ico-style-red
  .walkerton-filter-content
  input[type="text"]::-webkit-input-placeholder {
  color: #331f6c;
}

.mtlistings-ico-style-red
  .walkerton-filter-content
  input[type="text"]::-moz-placeholder {
  color: #331f6c;
}

.mtlistings-ico-style-red
  .walkerton-filter-content
  input[type="text"]:-ms-input-placeholder {
  color: #331f6c;
}

.mtlistings-ico-style-red
  .walkerton-filter-content
  input[type="text"]:-moz-placeholder {
  color: #331f6c;
}

.mtlistings-ico-style-red
  .walkerton-filter-content
  button[type="submit"]:hover {
  color: #fff !important;
  background: #f4374c;
  border: 2px solid #f4374c !important;
}

.mtlistings-ico-style-red .walkerton-filters .filter:hover {
  background: #f4374c;
}

.mtlistings-ico-style-red .is-sticky .blog_custom_listings,
.mtlistings-ico-style-red .sponsored_ico .blog_custom_listings,
.mtlistings-events-style-red.mt-events-block
  .row-1event.sponsored-event
  .mt-single-event-inner {
  border: 2px solid #331f6c;
}

.mtlistings-events-style-red.mt-events-block .mt-single-event-inner {
  background: #fff;
}

.mtlistings-ico-style-red .walkerton-filter-content input[type="text"],
.mtlistings-ico-style-red .walkerton-filter-content button[type="submit"] {
  width: 49%;
  padding: 13.5px 20px;
  border: solid 2px #331f6c;
  color: #331f6c;
}

.mtlistings-ico-style-red .walkerton-filter-content button[type="submit"] {
  padding: 13px 20px !important;
  background: #331f6c;
}

.mtlistings-ico-style-red .walkerton-filters .filter a {
  transition: all 0.2s ease-in-out;
  padding: 15px 20px;
  color: #331f6c;
  width: 100%;
}

.mtlistings-ico-style-dark .walkerton-filter {
  background: #252525;
}

.mtlistings-ico-style-dark .walkerton-filters .filter a {
  color: #fff;
  background: #0b0d19;
  transition: all 0.2s ease-in-out;
}

.mtlistings-ico-style-dark .walkerton-filters .filter:hover a {
  background: #12141f;
}

.mtlistings-ico-style-dark .walkerton-filter-content input[type="text"] {
  border: solid 2px #1e202c;
  color: #fff;
  background: #040714;
}

.mtlistings-ico-style-dark
  .walkerton-filter-content
  input[type="text"]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.mtlistings-ico-style-dark
  .walkerton-filter-content
  input[type="text"]::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.mtlistings-ico-style-dark
  .walkerton-filter-content
  input[type="text"]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.mtlistings-ico-style-dark
  .walkerton-filter-content
  input[type="text"]:-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.mtlistings-ico-style-dark .walkerton-filter-content button[type="submit"] {
  color: #fff !important;
  background: #f6255a;
}

.mtlistings-ico-style-dark
  .walkerton-filter-content
  button[type="submit"]:hover {
  background: #12141f;
  border: solid 2px #12141f;
}

.mtlistings-ico-style-dark.iconfilter-shortcode .blog_custom_listings {
  border: 2px solid #353535;
  background: #252525 !important;
}

.mtlistings-ico-style-dark .is-sticky .blog_custom_listings,
.mtlistings-ico-style-dark .sponsored_ico .blog_custom_listings {
  border: 2px solid #f6255a;
}

.mtlistings-ico-style-dark .is-sticky .blog_custom_listings::after,
.mtlistings-ico-style-dark .sponsored_ico .blog_custom_listings::after {
  border-width: 2.5em !important;
  border-right-color: #f6255a !important;
  border-top-color: #f6255a !important;
  border-top-right-radius: 3px;
}

.mtlistings-ico-style-dark.iconfilter-shortcode
  .blog_custom_listings
  .post-name-listings
  a,
.mtlistings-ico-style-dark .blog_custom_listings .post-name-listings span {
  color: #fff;
}

.mtlistings-ico-style-dark.iconfilter-shortcode
  .blog_custom_listings
  .post-name-listings
  a:hover {
  color: #f6255a !important;
}

.mtlistings-ico-style-dark .blog_custom_listings .walkerton-details-content {
  color: rgba(255, 255, 255, 0.3);
}

.mtlistings-ico-style-dark.iconfilter-shortcode .blog_custom_listings:hover {
  background-color: #12141f !important;
}

.mtlistings-ico-style-dark .ico-category img.attachment-thumbnail {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.mtlistings-ico-style-dark .walkerton-metas > div {
  color: #fff;
  opacity: 0.5;
}

.mtlistings-events-style-dark .row-1event .mt-single-event-inner {
  border: 2px solid #353535 !important;
}

.custom_footer_col_md_5 {
  width: 41.66666667% !important;
}

.custom_footer_col_md_2 {
  width: 16.66666667% !important;
}

.custom_footer_style_red .widget-title,
.custom_footer_style_dark .widget-title,
.custom_footer_style_blue .widget-title {
  margin-top: 0 !important;
  margin-bottom: 24px;
}

.custom_footer_style_red .menu .menu-item,
.custom_footer_style_dark .menu .menu-item,
.custom_footer_style_blue .menu .menu-item {
  width: 100%;
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 25px;
}

.custom_footer_style_dark .widget_social_icons li a i {
  font-size: 14px !important;
}

.custom_footer_style_dark .widget_social_icons li a {
  width: 37px !important;
  height: 37px !important;
  border-radius: 3px !important;
}

.custom_footer_style_dark .widget_nav_menu li a {
  color: rgba(255, 255, 255, 0.3) !important;
}

.custom_footer_style_dark .widget_nav_menu li a:hover {
  color: #fff !important;
}

.custom_footer_style_dark
  .widget_walkerton_recent_icos_with_thumbnail
  .post-details
  a,
.custom_footer_style_dark
  .widget_walkerton_recent_icos_with_thumbnail
  .post-details
  .post-date {
  color: rgba(255, 255, 255, 0.3) !important;
}

.custom_footer_style_dark
  .widget_walkerton_recent_icos_with_thumbnail
  .post-details
  a:hover {
  color: #fff !important;
}

.custom_footer_style_red .widget_nav_menu li a,
.custom_footer_style_blue .widget_nav_menu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}

.custom_footer_style_red .widget_nav_menu li a:hover,
.custom_footer_style_blue .widget_nav_menu li a:hover {
  color: #fff !important;
}

.background_position_bottom {
  background-position: bottom;
}

.mtlistings-events-style-v2 .events-title-subtitle a,
.mtlistings-events-style-v2 .event-meta > div,
.mtlistings-events-style-v2 .mt-single-event .mt-event-cat a {
  color: #252525;
}

.mtlistings-events-style-v2 .mt-single-event-inner {
  background: #f9f9f9;
  box-shadow: none;
}

.mtlistings-events-style-v2 .row-1event .mt-single-event-inner {
  border: 2px solid #e5e3eb;
}

.mtlistings-events-style-v2 .row-2events .mt-single-event-inner,
.mtlistings-events-style-v2 .row-3events .mt-single-event-inner,
.mtlistings-events-style-v2 .row-4events .mt-single-event-inner {
  background: #fff;
}

.mtlistings-events-style-v2 .row-1event.sponsored-event .mt-single-event-inner {
  border: 2px solid #252525;
}

.mtlistings-events-style-v2 .event-box-corner::before,
.mtlistings-events-style-v2 .event-box-corner::after {
  top: 2px;
  right: 2px;
  border-top-right-radius: 3px;
  z-index: 9;
}

.mtlistings-events-style-v2 .event-box-corner::before {
  top: 10px !important;
  z-index: 99;
}

.mtlistings-events-style-v2 .mt-single-event .mt-event-cat a {
  background: 0 0;
  padding: initial;
  font-weight: 400;
}

.mtlistings-events-style-v2 .mt-single-event {
  margin-bottom: 10px;
}

.mt-events-style-v2 .article-details img {
}

p.cmb_metabox_description {
  font-style: initial !important;
  font-weight: lighter;
}

.um .um-login .um-form input[type="text"],
.um .um-login .um-form input[type="tel"],
.um .um-login .um-form input[type="number"],
.um .um-login .um-form input[type="password"] {
  border: 2px solid #252525 !important;
  color: #252525 !important;
  padding: 15px 20px !important;
  height: initial !important;
}

.um-field-checkbox-option {
  font-weight: 400 !important;
}

.mt-listing-search-taxonomy select.select-car-category.form-control,
.mt-listing-search-taxonomy .state-search .search-field {
  border: 1px solid #e0e0e0;
  padding: 0 20px;
  height: 60px;
  color: black;
  border-radius: 5px;
  font-weight: 300;
  font-size: 16px;
  box-sizing: inherit;
  box-shadow: none;
  -webkit-box-shadow: none;
  width: 100%;
}

.mt-listing-search-taxonomy
  .state-search
  .search-field::-webkit-input-placeholder {
  color: gray;
}

.mt-listing-search-taxonomy .state-search .search-field::-moz-placeholder {
  color: gray;
}

.mt-listing-search-taxonomy .state-search .search-field:-ms-input-placeholder {
  color: gray;
}

.mt-listing-search-taxonomy .state-search .search-field:-moz-placeholder {
  color: gray;
}

.mt-listing-search-taxonomy .select2.select2-container .select2-selection {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  height: 60px;
  line-height: 60px;
  border: 1px solid #e0e0e0;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
  outline: 0;
}

.mt-listing-search-taxonomy
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 60px;
  text-align: center;
  text-align: left;
  padding-left: 0;
  color: black;
  font-weight: 300;
}

.mt-listing-search-taxonomy
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 60px;
  width: 35px;
}

.mt-listing-search-taxonomy
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.mt-listing-search-taxonomy
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  i {
  font-size: 18px;
  font-weight: 400;
  color: #002395;
}

.mt-listing-search-taxonomy .checkboxes-categories {
  margin-top: 10px;
  margin-bottom: 35px;
}

.mt-listing-search-taxonomy .state-submit {
  margin-bottom: 50px;
}

.mt-listing-search-taxonomy button.form-control.btn {
  font-weight: 700;
  height: 60px !important;
  padding: 0 18px;
  font-size: 14px;
  transition: all 0.25s ease-in-out;
  box-shadow: none;
  font-style: normal;
  background: #002395;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
}

.mt-listing-search-taxonomy .checkboxes-categories .checkbox-inline {
  padding-right: 12px;
  padding-top: 0.5px;
}

.single_list_thumbs_rating {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.single_list_thumbs_rating .thumbs_rating_getlink_v2 {
  position: static;
  display: inline-block;
  padding-right: 30px;
}

.single_list_thumbs_rating .thumbs-rating-container span.number {
  color: gray !important;
  display: inline-block !important;
  font-weight: 400;
  margin-left: 5px;
  line-height: 14px;
  position: relative;
  bottom: 4px;
}

.single_list_thumbs_rating .thumbs-rating-container span i {
  color: gray !important;
  display: inline-block !important;
  font-size: 20px;
  position: relative;
  bottom: 1px;
  padding-right: 5px;
}

.mt_listing_header_section .listing-star {
  display: inline-block;
}

.mt_listing_header_section .mt-item-reviews-average-rating {
  color: #fff;
  padding: 7px 10px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 700;
}

.dashboard-single-review .mt-item-reviews-average-rating {
  color: #fff;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 700;
  display: inline-block;
}

.dashboard-single-review .listing-actions {
  display: inline-block;
}

.dashboard-single-review .review-rating {
  display: inline-block;
  margin-bottom: 4px;
}

.listing_sponsored_bolt {
  display: inline-block;
  color: #fff;
  padding: 7px 13px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 700;
  background: #216fff;
  margin-left: 20px;
  display: none !important;
}

.mt_listing_content_parent {
  background: #fafafa;
  padding: 100px 0;
}

.mt_listing_content_parent .mt_listing_content_heading {
  font-size: 22px;
  margin-bottom: 25px;
  margin-top: 0;
}

.mt_listing_content_parent .listing_features_section,
.mt_listing_content_parent .listing_tags_section,
.mt_listing_content_parent .listing_video_section {
  margin-top: 60px;
}

.mt_listing_content_parent .amenities-section {
  margin-top: 30px;
}

.listing_video_section iframe {
  margin-top: 10px;
}

.mt_listing_content_parent .amenities-section span.amenities-item {
  display: block;
  color: black;
  font-size: 15px;
  margin-top: 15px;
  transition: all 300ms ease;
  font-weight: 400;
}

.mt_listing_content_parent .amenities-section a:hover span.amenities-item {
  color: #002395;
}

.mt_listing_content_parent .amenities-section span.amenities-item i {
  color: #002395;
  padding-right: 8px;
  position: relative;
  top: 1px;
}

.mt_listing_content_parent .listing_tags_section a {
  border: 1px solid #e0e0e0;
  color: black;
  padding: 9px 15px;
  font-size: 15px;
  font-weight: 500;
  margin-right: 10px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.mt_listing_content_parent .listing_tags_section a i {
  padding-right: 5px;
  opacity: 0.7;
}

.listing_tags_section a:hover {
  color: #002395;
  border-color: #002395;
}

.mt_listing_content_sidebar_part_contact_info {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 50px;
  -webkit-box-shadow: 0 0 20px transparent;
}

.mt_listing_content_sidebar_part_contact_info_details {
  padding: 40px 30px 30px;
}

.mt_listing_content_sidebar_part_contact_info_details
  h3.mt_listing_content_heading {
  margin-bottom: 25px;
}

.mt_listing_content_sidebar_part_contact_info_details_info {
  padding-top: 0;
}

.mt_listing_content_sidebar_part_contact_info_details_info p {
  margin-bottom: 5px;
  color: #151515 !important;
}

.mt_listing_content_description p {
  margin-bottom: 5px;
  font-weight: 400;
}

.mt_listing_content_sidebar_part_contact_info_details_info p:last-child {
  margin-bottom: 0;
}

.mt_listing_content_sidebar_part_contact_info_details_info p i {
  color: #002395;
  padding-right: 8px;
}

.mt_listing_content_sidebar_part_working_hours {
  padding: 40px 40px 30px;
  background: #fff;
  margin-bottom: 50px;
  -webkit-box-shadow: 0 0 20px transparent;
}

.mt_listing_content_sidebar_part_next_event {
  padding: 40px 40px 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.mt_listing_content_sidebar_part_author_listing {
  padding: 40px 40px 30px;
  background: #fff;
  margin-bottom: 50px;
  -webkit-box-shadow: 0 0 20px transparent;
}

.mt_listing_content_sidebar_part_author_listing
  .mt_listing_content_sidebar_part_author_listing_inner {
  padding: 0 70px 18px 0;
  margin: -4px 0 25px;
  display: block;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}

.mt_listing_content_sidebar_part_author_listing h4.mt_listing_content_heading {
  font-size: 18px;
  transition: 0.2s;
  line-height: 22px;
  font-weight: 500;
  margin: 4px 0 7px;
}

.mt_listing_content_sidebar_part_author_listing
  h4.mt_listing_content_heading
  span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #888;
  margin-bottom: 2px;
}

.mt_listing_content_sidebar_part_author_listing
  h4.mt_listing_content_heading
  a {
  color: #333;
}

.listing-details-author-info {
  padding: 0;
  list-style: none;
  font-size: 15px;
  margin-bottom: -5px;
  position: relative;
  display: block;
}

.listing-details-author-info li {
  display: block;
  padding-left: 23px;
  position: relative;
  margin-bottom: 5px;
  line-height: 24px;
}

.listing-details-author-info li a,
.listing-details-author-social-profiles li a,
.listing-details-author-info li,
.listing-details-author-social-profiles li {
  color: #151515;
  transition: 0.3s;
  font-weight: 400;
}

.listing-details-author-info li i {
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 15px;
  color: #002395;
  width: 20px;
}

.listing-details-author-social-profiles {
  padding: 0;
  list-style: none;
  font-size: 15px;
  margin-bottom: -5px;
  position: relative;
  display: block;
  margin-top: 20px;
}

.listing-details-author-social-profiles li {
  line-height: 22px;
  padding-left: 23px;
}

.listing-details-author-social-profiles li {
  display: block;
  padding-left: 23px;
  position: relative;
  margin-bottom: 5px;
  line-height: 24px;
}

.listing-details-author-social-profiles li i {
  top: 3px;
}

.listing-details-author-social-profiles li i {
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 15px;
  color: #002395;
}

.mt_listing_content_sidebar_part_author_listing .author_listing_avatar {
  max-width: 56px;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -8px;
  image-rendering: -webkit-optimize-contrast;
  border: 4px solid #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 50px;
  width: 50px;
}

.mt_listing_content_sidebar_part_author_listing .author_listing_avatar .avatar {
  max-width: 100%;
}

.mt_listing_content_sidebar_part_add_place {
  padding: 40px 40px 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.mt_listing_content_sidebar_part_next_event .next-event-countdown {
  order: 2;
}

.mt_listing_content_sidebar_part_next_event .modeltheme-countdown {
  padding: 0;
}

.mt_listing_content_sidebar_part_next_event .modeltheme-countdown .days-name,
.mt_listing_content_sidebar_part_next_event .modeltheme-countdown .hours-name,
.mt_listing_content_sidebar_part_next_event .modeltheme-countdown .minutes-name,
.mt_listing_content_sidebar_part_next_event
  .modeltheme-countdown
  .seconds-name {
  color: #151515;
  margin: 0 !important;
}

.mt_listing_content_sidebar_part_next_event
  .modeltheme-countdown
  div:first-child {
  padding: 0;
  text-align: left;
}

.mt_listing_content_sidebar_part_next_event
  .modeltheme-countdown
  div:last-child {
  text-align: left !important;
  font-size: 12px !important;
  color: #686868;
}

.mt_listing_content_sidebar_part_working_hours
  .mt_listing_content_description
  p
  span {
  float: right;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #002395;
}

.high-padding-top {
  padding: 65px 0 0;
}

.mt-listing-search-taxonomy .checkboxes-categories .checkbox-inline,
.styling-checkboxes .checkbox-inline {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: black;
  margin-top: 20px;
  margin-left: 0 !important;
  font-size: 14px;
}

.mt-listing-search-taxonomy .checkboxes-categories .checkbox-inline input,
.styling-checkboxes input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.mt-listing-search-taxonomy .checkboxes-categories .checkbox-inline .checkmark,
.styling-checkboxes .checkbox-inline .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #e8e8e8;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.mt-listing-search-taxonomy
  .checkboxes-categories
  .checkbox-inline:hover
  input
  ~ .checkmark,
.styling-checkboxes .checkbox-inline:hover input ~ .checkmark {
  background-color: #ccc;
}

.mt-listing-search-taxonomy
  .checkboxes-categories
  .checkbox-inline
  input:checked
  ~ .checkmark,
.styling-checkboxes .checkbox-inline input:checked ~ .checkmark {
  background-color: #002395;
}

.mt-listing-search-taxonomy
  .checkboxes-categories
  .checkbox-inline
  .checkmark:after,
.styling-checkboxes .checkbox-inline .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.mt-listing-search-taxonomy
  .checkboxes-categories
  .checkbox-inline
  input:checked
  ~ .checkmark:after,
.styling-checkboxes .checkbox-inline input:checked ~ .checkmark:after {
  display: block;
}

.mt-listing-search-taxonomy
  .checkboxes-categories
  .checkbox-inline
  .checkmark:after,
.styling-checkboxes .checkbox-inline .checkmark:after {
  left: 5px;
  top: 3px;
  width: 5px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

div#datafetch .search-result {
  position: absolute;
  z-index: 1;
  background: #fff;
  left: 0;
  margin-top: 20px;
  padding: 15px 0;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  list-style-type: none;
}

div#datafetch .search-result li {
  margin: 0;
  line-height: 1;
  font-size: 15px;
  padding: 10px 25px;
  -moz-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  transition: all 250ms ease;
  text-align: left;
}

div#datafetch .search-result li:hover {
  background: #f5f5f5;
}

div#datafetch .search-result li a {
  font-size: 15px;
  color: #252525;
}

div#datafetch .search-result::before,
div#datafetch .search-result::after {
  left: 40px;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  top: -23px;
}

div#datafetch .search-result::before {
  border-color: transparent;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

div#datafetch .search-result::after {
  border-color: transparent transparent #fff;
  border-width: 12px;
}

div#datafetch .search-result li::before {
  display: inline-block;
  font-family: FontAwesome;
  font-weight: 400;
  font-size: 15px;
  width: 20px;
  background: 0 0 !important;
  content: "\f0f6";
}

.mt-car-search.wow.fadeInUp.mtsearchform-style-v2.animated {
  position: relative;
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
}

.upload-images {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 30px;
  width: 33%;
}

#add-new-listing input[type="text"] {
  padding: 0 20px !important;
  height: 50px;
  width: 100%;
  border-radius: 5px !important;
  background: #fff;
  -webkit-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  -moz-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  -ms-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  margin-bottom: 30px !important;
  font-size: 15px;
  box-shadow: none;
  border: 1px solid #e0e0e0 !important;
  color: black;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  font-style: normal;
  margin-top: 5px;
  font-weight: 300;
}

#add-new-listing input[type="text"]:focus {
  border-color: #002395;
}

#add-new-listing textarea:focus {
  border-color: #002395;
}

#add-new-listing label.error {
  color: #002395;
  margin-top: 0;
  margin-bottom: 0;
}

#add-new-listing textarea {
  padding: 20px !important;
  width: 100%;
  border-radius: 5px !important;
  background: #fff;
  -webkit-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  -moz-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  -ms-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  margin-bottom: 30px !important;
  font-size: 15px !important;
  box-shadow: none;
  border: 1px solid #e0e0e0 !important;
  color: black;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  font-style: normal;
  margin-top: 5px;
  font-weight: 300;
}

#add-new-listing .upload-images {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}

#add-new-listing .spacer-upload {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 100%;
  position: relative;
  margin-top: 5px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

#add-new-listing .spacer-upload:hover {
  border-color: #ccc;
}

#add-new-listing small.field-description {
  font-size: 14px;
  color: #686868;
  margin-top: 10px;
  display: block;
}

#add-new-listing .spacer-upload::before {
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  /* background: url(../inc/frontend-media/img/addphoto-listing.png) center no-repeat; */
  background-size: 60px;
  height: 100%;
  left: 4px;
  top: -15px;
  width: 100%;
}

#add-new-listing .spacer-upload:after {
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  border: 15px solid #fff;
  border-bottom-color: #e0e0e0;
  border-bottom-left-radius: 4px;
  border-left-color: #e0e0e0;
  right: -1px;
  top: -1px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

#add-new-listing .spacer-upload:hover:after {
  border-bottom-color: #ccc;
  border-left-color: #ccc;
}

#add-new-listing .text {
  color: black;
  content: "";
  font-size: 15px;
  left: 50%;
  margin-top: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3;
}

.mt_listings_page.mt_listing_map_location .sbvcgmap-map img {
  max-width: 200px;
  display: block;
  margin: 5px 0 10px;
}

.mt_listings_page.mt_listing_map_location .sbvcgmap-map p {
  font-size: 12px;
  margin-bottom: 0;
  line-height: 1.3;
  margin-top: 3px;
}

.mt_listings_page.mt_listing_map_location .sbvcgmap-map a {
  font-size: 15px;
  font-family: montserrat;
  font-weight: 500;
}

#add-new-listing input[type="text"]::-webkit-input-placeholder {
  color: gray;
}

#add-new-listing input[type="text"]::-moz-placeholder {
  color: gray;
}

#add-new-listing input[type="text"]:-ms-input-placeholder {
  color: gray;
}

#add-new-listing input[type="text"]:-moz-placeholder {
  color: gray;
}

#add-new-listing textarea::-webkit-input-placeholder {
  color: gray;
}

#add-new-listing textarea::-moz-placeholder {
  color: gray;
}

#add-new-listing textarea:-ms-input-placeholder {
  color: gray;
}

#add-new-listing textarea:-moz-placeholder {
  color: gray;
}

#add-new-listing label {
  font-size: 15px;
}

#add-new-listing .styling-checkboxes label.checkbox-inline {
  margin-top: 10px;
  margin-left: 0;
  font-size: 14px;
  margin-right: 30px;
  color: black;
  font-weight: 300;
  width: 20%;
}

.form-group.styling-checkboxes {
  margin-bottom: 30px;
}

#add-new-listing .select2-container {
  width: 100% !important;
  margin-top: 5px;
}

#add-new-listing label {
  font-size: 15px;
  margin-bottom: 5px;
  color: rgba(21, 21, 21, 0.7);
  font-weight: 500;
}

#add-new-listing .form-group {
  margin-bottom: 30px;
}

#add-new-listing .select2.select2-container .select2-selection {
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  height: 50px;
  line-height: 50px;
  outline: 0;
}

#add-new-listing
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 48px;
  text-align: center;
  text-align: left;
  padding-left: 0;
  font-size: 15px;
  color: gray !important;
  font-weight: 300;
}

#add-new-listing .select2.select2-container .select2-selection {
  padding: 0 0 0 20px !important;
}

#add-new-listing
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
  width: 35px;
}

.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  display: none !important;
}

.inputfile {
  display: none !important;
}

#add-new-listing label.mt_listing_image {
  min-width: 100%;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
}

.inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.no-js .inputfile + label {
  display: none !important;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile-6 + label {
  color: #d3394c;
}

.inputfile-6 + label {
  border: 1px solid #e0e0e0;
  background-color: #fff;
  padding: 0;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
  border-color: #e0e0e0;
}

.inputfile-6 + label span,
.inputfile-6 + label strong {
  padding: 0 20px;
  line-height: 60px;
  font-size: 16px;
}

.inputfile-6 + label span {
  width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: black;
  vertical-align: top;
  font-weight: 400;
}

.inputfile-6 + label strong {
  height: 100%;
  color: #fff;
  background-color: #002395;
  display: inline-block;
  transition: all 250ms ease;
  float: right;
  text-transform: uppercase;
  padding: 0 40px;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.inputfile-6 + label strong i {
  padding-right: 10px;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
  background: #216fff;
}

@media screen and (max-width: 50em) {
  .inputfile-6 + label strong {
    display: block;
  }
}

form#add-new-listing {
  max-width: 800px;
  margin: 0 auto;
}

form#add-new-listing button.button-listing {
  background-color: #002395;
  text-align: center;
  padding: 15px 40px;
  color: #fff;
  font-size: 16px;
  display: block;
  transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  text-transform: uppercase;
  font-weight: 700;
  outline: 0;
  border: 0;
  border-radius: 5px;
}

form#add-new-listing button.button-listing:hover {
  background: #216fff;
  color: #fff !important;
}

button#frontend-button {
  background-color: #002395;
  text-align: center;
  padding: 15px 30px;
  color: #fff;
  font-size: 16px;
  display: block;
  transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  text-transform: uppercase;
  font-weight: 700;
  outline: 0;
  border: 0;
  border-radius: 5px;
}

form#add-new-listing button.button-listing:hover {
  background: #216fff;
  color: #fff !important;
}

.file-upload {
  position: relative;
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 150px;
}

.file-upload input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}

.file-preview img {
  width: 100%;
  height: auto;
}

.file-preview .ibenic_file_preview {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ibenic_file_delete {
  position: absolute;
  width: 100%;
  top: 98%;
  left: 0;
  padding: 0.5em;
  text-align: center;
  color: #fff;
  background-color: red;
}

.group_pictures_holder.row {
  margin-top: 20px;
}

.group_pictures_holder.row img {
  max-height: 150px;
  width: 100%;
}

.group_pictures_holder.row .delete {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #216fff;
  color: #fff;
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
}

.group_pictures_holder.row .col-md-4:hover .delete {
  opacity: 1;
  visibility: visible;
}

.group_pictures_holder.row .col-md-4 {
  position: relative;
  margin-bottom: 30px;
}

.group_pictures_holder .col-md-4:first-child img {
  border: 5px solid #216fff;
}

.group_pictures_holder .col-md-4:first-child:after {
  content: "Featured";
  position: absolute;
  top: 0;
  left: 15px;
  background: #216fff;
  color: #fff;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.mt_listing_single_slider_section button#prev-arrow,
.mt_listing_single_slider_section button#next-arrow {
  position: absolute;
  top: 44%;
  left: 25px;
  width: 45px !important;
  height: 45px !important;
  padding: 0 !important;
  text-align: center;
  font-size: 16px !important;
  line-height: 45px;
  color: #fff;
  cursor: pointer;
  opacity: 1 !important;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 5px !important;
  transition: all 250ms ease;
  box-shadow: none;
  border: 0;
  outline: 0;
}

.mt_listing_single_slider_section button#next-arrow {
  left: inherit;
  right: 25px;
}

.mt_listing_single_slider_section {
  position: relative;
}

.mt-rating-stars ul {
  list-style-type: none;
  padding: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
}

#add-new-review .mt-rating-stars ul > li.star {
  cursor: pointer;
}

.mt-review-content .review-author-name h4 {
  margin-bottom: 10px;
}

.mt-review-text .review-content-paragraph {
  display: inline-block;
}

.mt-review-text .review-date {
  line-height: 1;
}

.mt-review-text .review-date span {
  color: #686868;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
}

.mt-rating-stars ul > li.star {
  display: inline-block;
  line-height: 1;
}

.mt-rating-stars ul > li.star > i.fa {
  font-size: 16px;
  color: #ccc;
}

.mt-rating-stars ul > li.star.selected > i.fa {
  color: #ff912c;
}

.mt-review-content {
  margin-bottom: 40px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 40px;
}

.mt-review-content .mt-review-image {
  position: relative;
  top: 3px;
  left: 0;
  width: 82px;
  height: 82px;
  display: block;
  float: left;
}

.mt-review-content .mt-review-image .avatar {
  max-width: 82px;
}

.reviews-section h3#review-title {
  margin-bottom: 25px;
  margin-top: 50px;
}

.mt-review-text {
  padding: 0 0 0 105px;
  min-height: 82px;
}

div#mt-rating-stars-parent .mt-rating-stars {
  width: 50%;
  float: left;
  margin-bottom: 5px;
}

div#mt-rating-stars-parent .mt-rating-stars label,
div#mt-rating-stars-parent .mt-rating-stars ul {
  display: table-cell;
  margin: 0;
}

div#mt-rating-stars-parent .mt-rating-stars label {
  font-size: 15px;
  margin-bottom: 5px;
  color: rgba(21, 21, 21, 0.7);
  font-weight: 500;
}

form#add-new-review h3#review-title {
  margin-bottom: 25px;
}

#add-new-review div#mt-rating-stars-parent {
  overflow: hidden;
  margin-bottom: 20px;
}

form#add-new-review .form-group label {
  font-size: 15px;
  margin-bottom: 5px;
  color: rgba(21, 21, 21, 0.7);
  font-weight: 500;
}

form#add-new-review .form-group input {
  padding: 0 20px !important;
  height: 50px;
  width: 100%;
  border-radius: 5px !important;
  background: #fff;
  -webkit-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  -moz-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  -ms-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  margin-bottom: 30px !important;
  font-size: 15px;
  box-shadow: none;
  border: 1px solid #e0e0e0 !important;
  color: black;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  font-style: normal;
  margin-top: 5px;
  font-weight: 300;
}

form#add-new-review .form-group input:focus {
  border-color: #002395 !important;
}

form#add-new-review .form-group textarea:focus {
  border-color: #002395 !important;
}

form#add-new-review .form-group textarea {
  padding: 20px !important;
  width: 100%;
  border-radius: 5px !important;
  background: #fff;
  -webkit-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  -moz-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  -ms-box-shadow: 0 0 20px rgba(21, 21, 21, 0.05);
  margin-bottom: 30px !important;
  font-size: 15px !important;
  box-shadow: none;
  border: 1px solid #e0e0e0 !important;
  color: black;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  font-style: normal;
  margin-top: 5px;
  font-weight: 300;
}

form#add-new-review button.button-listing {
  background-color: #002395;
  text-align: center;
  /* padding: 13px 30px; */
  color: #fff;
  font-size: 14px;
  display: block;
  transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  text-transform: uppercase;
  font-weight: 600;
  outline: 0;
  border: 0;
  border-radius: 5px;
}

form#add-new-review button.button-listing:hover {
  background-color: #030b54 !important;
}

@media only screen and (min-width: 681px) {
  .reviews-criteria {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: center;
    -webkit-justify-content: center;
  }
}

.reviews-criteria .mt-item-reviews-average-wrapper {
  width: 132px;
  padding: 60px 25px 20px 0;
  box-sizing: border-box;
  margin-left: -10px;
}

.reviews-criteria .mt-item-reviews-average-rating {
  font-size: 60px;
  line-height: 1em;
  font-weight: 700;
  color: #002395;
  text-align: center;
}

.reviews-criteria .mt-item-reviews-average-rating.star1,
.reviews-criteria .mt-item-reviews-average-rating.star2,
.reviews-criteria span.mt-item-reviews-rating-description.rating-Poor,
.reviews-criteria span.mt-item-reviews-rating-description.rating-Fair {
  color: #002395;
}

.reviews-criteria .mt-item-reviews-average-rating.star3,
.reviews-criteria .mt-item-reviews-average-rating.star4,
.reviews-criteria span.mt-item-reviews-rating-description.rating-Good,
.reviews-criteria span.mt-item-reviews-rating-description.rating-Superb {
  color: #ff7e00;
}

.reviews-criteria .mt-item-reviews-average-rating.star5,
.reviews-criteria span.mt-item-reviews-rating-description.rating-Excellent {
  color: #f2be50;
}

.reviews-criteria .mt-item-reviews-verbal-description {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 2px 0 0;
}

.reviews-criteria span.mt-item-reviews-rating-description {
  display: block;
  font-size: 17px;
  text-align: center;
  color: #002395;
}

.reviews-criteria .mt-reviews-list-info.edgtf-reviews-count {
  margin-left: 10px;
  margin-top: 15px;
}

.reviews-criteria .mt-reviews-list-info.edgtf-reviews-count > * {
  margin: 0;
}

.reviews-criteria p.mt-reviews-summary {
  display: inline-block;
  padding: 3px 15px;
  text-align: center;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-sizing: border-box;
}

.mt-rating-percentage-wrapper {
  width: 850px;
  padding-top: 60px;
}

.mt-rating-percentage-wrapper .label_text_percentange:first-child {
  margin-top: 0;
}

.mt-rating-percentage-wrapper .label_text_percentange {
  margin-bottom: 7px;
  margin-top: 15px;
}

.mt-rating-percentage-wrapper .progress {
  height: 5px !important;
  margin-bottom: 0 !important;
  border-radius: 2px !important;
}

.listings-dashboard
  .dashboard-single-review
  .author-name-review
  .review-listing {
  display: inline;
  font-size: 15px;
  color: black;
  font-weight: 400;
  padding-left: 20px;
}

@media (min-width: 992px) {
  .mt_listings_page.mt_listing_map_location.col-md-5 .sbvcgmap-map {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 41.6% !important;
    z-index: 1;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    transition: width 0.3s ease;
    height: 100% !important;
  }
}

.tax-mt-listing-category2 footer {
  z-index: 1;
  position: relative;
}

.tax-mt-listing-category2 header.header1 {
  z-index: 2;
  position: relative;
}

.taxonomy-listing-page.term-style2.row .col-md-4.adplace-category {
  margin-bottom: 70px;
}

.taxonomy-listing-page.term-style2.row .col-md-4.adplace-category img {
  width: 100%;
}

.taxonomy-listing-page.term-style2.row {
  margin: 0;
}

button.gm-control-active.gm-fullscreen-control {
  display: none;
}

.iconfilter-shortcode .ico-category_icon_holder {
  float: left;
  width: 60px;
  height: 60px;
  text-align: center;
  background: #ff1e27;
  padding: 10px;
  border-radius: 5px;
  margin-right: 15px;
  margin-left: 15px;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  cursor: pointer;
}

.iconfilter-shortcode .ico-category_icon_holder img {
  filter: brightness(0) invert(1);
}

.iconfilter-shortcode h1.ico-cat-parent-heading {
  color: #151515;
  font-weight: 700;
  padding: 10px 0;
  margin-bottom: 25px;
  margin-top: 1px;
}

.iconfilter-shortcode .listing-grid-1item .blog_custom_listings {
  background: #fff;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.iconfilter-shortcode .sponsored_listing {
  overflow: hidden;
}

.iconfilter-shortcode .listing-grid-1item .thumbnail-name {
  display: flex;
  align-items: center;
}

.iconfilter-shortcode .listing-grid-1item img.blog_post_image {
  max-width: 80px;
  margin-right: 20px;
  border-radius: 5px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.iconfilter-shortcode .listing-grid-1item .listing-name {
  font-size: 16px;
}

.iconfilter-shortcode .listing-grid-1item .categories-name {
  color: black;
  font-size: 16px;
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.iconfilter-shortcode .listing-grid-1item .row {
  display: flex;
  align-items: center;
}

.iconfilter-shortcode .row .listing-star .mt-item-reviews-average-rating {
  color: #fff;
  font-size: 14px;
  padding: 7px 10px;
  font-weight: 700;
  margin-top: 8px;
  display: inline-block;
}

.iconfilter-shortcode
  .row
  .listing-grid-star-sponsored
  .listing_sponsored_bolt {
  margin-top: 8px;
  margin-left: 0;
  padding: 7px 13px;
}

.iconfilter-shortcode
  .row
  .listing-grid-star-sponsored
  .listing_sponsored_bolt
  span {
  color: #fff;
  margin: 0;
}

.iconfilter-shortcode .row .listing-grid-star-sponsored .listing-star {
  float: left;
  margin-right: 5px;
}

.iconfilter-shortcode .listing-grid-1item .row .listing-actions a.btn-view {
  font-size: 16px;
  color: #757575;
  background: #eaeaea;
  padding: 7px 13px;
  border-radius: 5px;
  line-height: 1;
  text-transform: lowercase;
  margin: 0 5px;
}

.iconfilter-shortcode
  .listing-grid-1item
  .row
  .listing-actions
  a.btn-view
  span {
  font-size: 12px;
}

.iconfilter-shortcode .listing-grid-1item .row .single_list_share_this {
  position: relative;
  bottom: 4px;
}

.iconfilter-shortcode .listing-grid-1item .row .list_share_this {
  font-size: 16px;
}

.iconfilter-shortcode .row .blog_custom_listings img {
  max-width: 80px;
  border-radius: 5px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.iconfilter-shortcode .row .blog_custom_listings img:hover {
  opacity: 0.8;
}

.iconfilter-shortcode .row .blog_custom_listings .thumbnail-name {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.iconfilter-shortcode .row .blog_custom_listings .post-name-listings {
  padding-left: 10px;
}

.walkerton-metas-single-meta {
  border: 1px solid #ddd;
  padding: 1px;
}

.iconfilter-shortcode .row .blog_custom_listings h4 {
  margin: 0;
}

.iconfilter-shortcode .row .blog_custom_listings h4 a {
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

.iconfilter-shortcode .row .blog_custom_listings h4 a:hover {
  opacity: 0.8;
}

.iconfilter-shortcode .row-2items p.walkerton-details-content {
  margin: 15px 0;
}

.row-3items .walkerton-img-left {
  width: initial;
  margin-right: 15px;
}

.row-4items .walkerton-img-left {
  width: initial;
  margin-right: 17px;
}

.iconfilter-shortcode .row-3items p.walkerton-details-content {
  margin: 15px 0;
}

.iconfilter-shortcode .row-2items .blog_custom_listings h4 a,
.iconfilter-shortcode .row-3items .blog_custom_listings h4 a {
  font-size: 18px;
}

.iconfilter-shortcode .row-4items .blog_custom_listings h4 a {
  font-size: 16px;
}

.iconfilter-shortcode .row-4items .blog_custom_listings h4 a {
  font-size: 16px !important;
  margin: 0 !important;
}

.iconfilter-shortcode .row-4items .blog_custom_listings img {
  max-width: 60px;
}

.iconfilter-shortcode .row-4items p.walkerton-details-content {
  margin: 15px 0;
}

body.author .col-md-8.author-listings .listings-dashboard {
  margin-top: 0;
}

body.author {
  background: #fafafa;
}

body.author .sidebar-author-inner {
  padding: 15px 40px 30px;
  background: #fff;
  margin-bottom: 50px;
  -webkit-box-shadow: 0 0 20px transparent;
}

body.author .mt-item-reviews-average-rating {
  padding: 2px 10px;
  color: #eaeaea;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 700;
  display: inline-block;
}

body.author .user-profile-name {
  position: relative;
  left: 127px;
}

.profile-rating {
  display: flex;
  align-items: center;
}

body.author .profile-rating .listing-star {
  display: inline-block;
  float: left;
  padding-right: 10px;
}

body.author .rating-counter {
  color: black;
  font-size: 16px;
  float: left;
  display: block;
}

body.author .user-profile-avatar {
  width: 96px;
  height: auto;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: -12px;
  image-rendering: -webkit-optimize-contrast;
  border: 5px solid #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

body.author .user-profile-avatar .avatar.photo {
  max-width: 100%;
  height: auto;
}

body.author .user-profile-titlebar {
  position: relative;
  margin-top: 0;
  min-height: 73px;
  display: inline-block;
  margin-bottom: 50px;
}

body.author .user-profile-name h3 {
  margin: 5px 0 10px;
}

body.author .listing-details-author-info {
  margin-top: 20px;
}

body.author .col-md-8.author-listings h3 {
  margin-top: 6px;
}

body.author .modeltheme-pagination-holder.col-md-12 {
  margin-top: 40px;
}

body.author .col-md-8.author-listings h3.reviews-title {
  margin-top: 60px;
}

body.author .dashboard-single-review .review-content {
  margin: 15px 0;
}

body.author .col-md-8.author-listings h3 {
  margin-top: 5px;
}
.progress-bar-success {
  background-color: #5cb85c;
}
img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}
.payment_methods_readability.ppd_payment_methods_readability {
  cursor: default;
}
.payment_methods_readability {
  border: 0;
  cursor: help;
}
.maestro {
  width: 46px;
  background-position: -105px 0;
}
.creditcard {
  height: 28px;
  position: relative;
  float: left;
  margin: 0 9px 7px 0;
}

.bui-badge__text {
  display: inline-block;
  vertical-align: top;
}
.euromastercard,
.mastercard {
  width: 46px;
  background-position: -55px 0;
}
.visa {
  width: 46px;
  background-position: -5px 0;
}
.americanexpress {
  width: 46px;
  background-position: -309px 0;
}

element.style {
}
.bui-badge__text {
  display: inline-block;
  vertical-align: top;
}
[class^="bui-"],
[class^="bui-"]:after,
[class^="bui-"]:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bui-badge--constructive {
  background-color: #008009;
  border-color: #008009;
  color: white;
}
.hp_bp_payment_method__cash-badge {
  margin-top: 3px;
}
.bui-badge {
  font-size: var(--bui_font_small_1_font-size);
  font-weight: var(--bui_font_small_1_font-weight);
  line-height: var(--bui_font_small_1_line-height);
  font-family: var(--bui_font_small_1_font-family);

  border-radius: var(--bui_border_radius_100);
  color: var(--bui_color_on_background);

  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: -webkit-calc(var(--bui_spacing_half) - var(--bui_border_width_100))
    var(--bui_spacing_1x);
  padding: calc(var(--bui_spacing_half) - var(--bui_border_width_100))
    var(--bui_spacing_1x);
  vertical-align: middle;
}
.backgroundImg {
  background-size: cover;
  background-position: center;
  padding: 20px;
}

.headerTextweather {
  font-size: 36px;
  margin-top: 10px;
  color: white !important;
}

.extraInfo {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  padding: 10px;
}

.infoweather {
  width: 45%; /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}
.image-sliderdesc {
  position: relative;
  height: 400px;
  width: 45%;
  margin-top: 10px;
  display: flex;
  margin-left: 40px;
  /* /* justify-content: center; */
  align-items: center;
}
.imagedesc {
  width: 410px;
  height: 350px;
  border-radius: 12px;
}
.leftdesc {
  position: absolute;
  left: 0rem;
  top: 50%;
  font-size: 1rem;
  color: white;
  z-index: 5;
  cursor: pointer;
  user-select: none;
}
.rightdesc {
  position: absolute;
  left: 23rem;
  top: 48%;
  font-size: 2rem;
  color: white;
  z-index: 5;
  cursor: pointer;
  user-select: none;
}
.currentSlide {
  transition-duration: 0.5s ease;
  opacity: 0;
}
.currentSlide.active {
  opacity: 1;
  transition-duration: 0.5s;
  transform: scale(1.2);
}
