
/* .header12 {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(103, 71, 54, 0.4)),
      url("https://reserva-web-app.netlify.app/static/media/heroimage-1.c485d6a6233f174ef9b7.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 100vh;
    margin-bottom: 5rem;
    position: relative;
  } */
 .header-slide {
  /* height: 100vh !important; */
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  position: relative !important;
} 
  .header12 .headerContainer12 .headerCont12 {
    height: 75vh;
    display: flex;
    align-items: center;
  }
  
  .header12 .headerContainer12 .headerCont12 .button-cont12 .headerBtn12 {
    width: 130px;
    border-radius: 5px;
  }
  
  .header12 .headerContainer12 .headerCont12 .headerTitle12 {
    color: white;
    font-size: 60px;
  }
  
  .header12 .headerContainer12 .headerCont12 .headerDesc12 {
    margin: 20px 0px;
    color: white !important;
  }
  
  .header12 .headerContainer12 .headerCont12 .headerSearch12 {
    box-shadow: 0px -1px -1px -1px rgba(185, 185, 185, 1) !important;
    margin-top: 2rem;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1rem 1rem;
    border-radius: 3px;
    /* position: absolute; */
  }
  
  .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchItem12 {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fbfbfb;
    border-radius: 5px;
    padding: 0.7rem;
    position: relative;
  }
  
  .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchItem12:nth-child(2) {
    min-width: 230px !important;
    position: relative;
  }
  
  .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchItem12:nth-child(2) .date {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 300 !important;
  }
  
  .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchItem12 .headerSearchInput12 {
    border: none;
    outline: none;
    background-color: transparent;
  }
  
  .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchItem12 .headerIcon12 {
    color: lightgray;
    font-size: 20px;
  }
  
  .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchButton12 .headerBtn2 {
    background-color: rgba(103, 71, 54);
    color: white;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  /* nbvnbvbnvnv */

  .awssld__content{
    display: block !important;
  }
  .awssld__container{
    display: block;
    width: 100%;
    height: 450px !important;
    padding: 0px !important;
  }
  
.headerSearch12 {
    box-shadow: 0px -1px -1px -1px rgba(185, 185, 185, 1) !important;
    /* margin-top: 6.5rem !important; */
    background-color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 1rem 1rem;
    border-radius: 3px;
    position: absolute  !important;
    z-index: 999  !important;
    top: 6.5%  !important;
    /* top: 6%  !important; */
    
    width: 97%  !important;
  }
  
  .headerSearch12 .headerSearchItem12 {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fbfbfb;
    border-radius: 5px;
    width:500px;
    padding: 0.7rem;
    position: relative;
    height: 50px;
  }
  
 .headerSearch12 .headerSearchItem12:nth-child(2) {
    min-width: 230px !important;
    position: relative;
  }
  .headerSearch12 .headerSearchItem12:nth-child(2) .date {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 300 !important;
  }
 .headerSearch12 .headerSearchItem12 .headerSearchInput12 {
    border: none;
    outline: none;
    background-color: transparent;
  }
  
 .headerSearch12 .headerSearchItem12 .headerIcon12 {
    color: lightgray;
    font-size: 20px;
  }
 .headerSearch12 .headerSearchButton12 .headerBtn2 {
    background-color: rgba(103, 71, 54);
    color: white;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  /* nbvnbvbnvnv */
  .headerBtn2.active {
    background-color: rgba(103, 71, 54, 0.7) !important;
  }
  
  .header12.listMode {
    margin-bottom: 3rem;
    height: 45vh;
    background-position: top;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(103, 71, 54)),
      url("https://images.pexels.com/photos/1001965/pexels-photo-1001965.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
  }
  
  .header12.listMode .headerContainer12 .headerCont12 {
    height: 55vh;
    justify-content: center;
  }
  
  .header12.listMode .headerContainer12 .headerCont12 .headerTitle12 {
    color: white;
    font-size: 42px;
  }
  
  .headerList {
    display: flex;
   
    gap: 40px;
   
    /* margin-top: 120px; */
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0
}

.dropdown-item:focus,.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa
}

.dropdown-item.active,.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff
}

.dropdown-item.disabled,.dropdown-item:disabled {
    color: #868e96;
    background-color: transparent
}

  .headerListItem {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .headerListItem.active {
    border: 1px solid white;
    padding: 10px;
    border-radius: 20px;
  }
  
  .headerBtn12 {
    background-color: white;
    color:black;
    font-weight: 500;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  .row12 {
    --mdb-gutter-x: 1.5rem;
    --mdb-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--mdb-gutter-y)*-1);
    margin-right: calc(var(--mdb-gutter-x)*-0.5);
    margin-left: calc(var(--mdb-gutter-x)*-0.5);
}
  .headerBtn12:hover {
    background-color: rgb(0, 0, 0, 0.5);
    color: white;
  }
  
  .headerSearchText {
    color: lightgray;
    cursor: pointer;
    min-width: 50px;
  }
  
  .options {
    z-index: 2;
    position: absolute;
    top: 3rem;
    background-color: white;
    color: black;
    border-radius: 5px;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  }
  
  .optionItem {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }
  
  .optionCounter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: black;
  }
  
  .optionCounterButton {
    width: 30px;
    height: 30px;
    border: 1px solid rgba(103, 71, 54);
    color: rgba(103, 71, 54);
    background-color: white;
    cursor: pointer;
  }
  
  .optionCounterButton:disabled {
    cursor: not-allowed;
  }
  
  @media (max-width: 1199.98px) {
    .header12.listMode .headerContainer12 .headerCont12 .headerTitle12 {
      font-size: 36px;
    }
    .headerTitle12 {
      font-size: 36px;
    }
  }
  
  @media (min-width: 575.98px) and (max-width: 991.98px) {
    .header12.listMode .headerContainer12 .headerCont12 .headerTitle12 {
      font-size: 35px;
    }
   .headerTitle12 {
      font-size: 35px;
    }
    .header12 {
      margin-bottom: 8rem;
    }
   
    .headerSearch12 {
      display: none !important;
      border-radius: 10px;
      padding: 1rem 1.5rem;
      z-index: 200 !important;
      top: 33rem;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 65%;
      flex-direction: column;
    }
  
    .headerSearch12 .headerSearchItem12 {
      width: 100%;
      margin: 2px;
    }
  
    .headerSearch12 .headerSearchItem12:nth-child(2) .date {
      left: 50%;
      transform: translate(-10%, 0%);
    }
  
    .headerSearch12 .headerSearchButton12 {
      margin-top: 0.5rem;
      width: 100%;
    }
  
    .headerSearch12 .headerSearchButton12 .headerBtn2 {
      width: 100%;
      border-radius: 8px;
    }
  }
  
  @media (min-width: 991.98px) {
    .headerSearch12 {
      margin-top: 5rem;
    }
   
  }
  
  @media (max-width: 576px) {
    .header12.listMode {
      .headerContainer12 {
        .headerCont12 {
          .headerTitle12 {
            font-size: 25px !important;
            width: 200px;
            
          }
        }
      }
    }
    .headerSearch12 {
      display: none !important;
    }
    .header12 {
      margin-bottom: 14rem;
      height: 50vh;
    }
  
    .header12 .headerContainer12 .headerCont12 {
      height: 50vh;
    }
  
    .header12 .headerContainer12 .headerCont12 .headerTitle12 {
      font-size: 35px !important;
      margin-bottom: 1rem;
    }
  
    .header12 .headerContainer12 .headerCont12 .headerWrapper12 {
      justify-content: center;
    }
  
    .header12 .headerContainer12 .headerCont12 .button-cont12 {
      text-align: center;
    }
  
    .header12 .headerContainer12 .headerCont12 .headerDesc12 {
      display: none;
    }
  
    .header12 .headerContainer12 .headerCont12 .headerTitle12 {
      text-align: center;
      font-size: 45px;
    }
  
    .header12 .headerContainer12 .headerCont12 .headerSearch12 {
      border-radius: 10px;
      padding: 1rem 1.5rem;
      z-index: 200 !important;
      top: 25rem;
      left: 50%;
      transform: translate(-50%, -60%);
      width: 85%;
      flex-direction: column;
      box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.2);
    }
   .headerSearch12 {
      border-radius: 10px;
      padding: 1rem 1.5rem;
      z-index: 200 !important;
      top: 25rem;
      left: 50%;
      transform: translate(-50%, -60%);
      width: 85%;
      flex-direction: column;
      box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.2);
    }
    .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchItem12 {
      width: 100%;
      margin: 3px;
    }
   .headerSearch12 .headerSearchItem12 {
      width: 100%;
      margin: 3px;
    }
    .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchItem12:nth-child(2) .date {
      left: 50%;
      transform: translate(-50%, 0%);
    }
  .headerSearch12 .headerSearchItem12:nth-child(2) .date {
      left: 50%;
      transform: translate(-50%, 0%);
    }
    .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchButton12 {
      margin-top: 0.5rem;
      width: 100%;
    }
   .headerSearch12 .headerSearchButton12 {
      margin-top: 0.5rem;
      width: 100%;
    }
    .header12 .headerContainer12 .headerCont12 .headerSearch12 .headerSearchButton12 .headerBtn12 {
      width: 100%;
      border-radius: 8px;
    }
    .headerSearch12 .headerSearchButton12 .headerBtn12 {
      width: 100%;
      border-radius: 8px;
    }
  }
  
  @media (max-width: 370px) {
    .header12 {
      height: 55vh !important;
      margin-bottom: 5rem !important;
    }
  
    .headerSearch12 {
      display: none !important;
    }
  
    .header12.listMode {
      height: 45vh !important;
    }
  
    .header12.listMode .headerContainer12 .headerCont12 {
      height: 45vh !important;
    }
  }
  
  @media (max-width: 450px) {
    .headerSearch12 {
      top: 28rem;
    }
  }
  .ageInput {
    width: 50px;
    padding: 5px;
    margin: 5px;
    border: 1px solid #70bbe9;
    color: #70bbe9;
    cursor: pointer;
    background-color: white;
  }
