.navbar12 {
  background-color: rgba(0, 0, 0, 0.1) !important;
  overflow: hidden;
  z-index: 500 !important;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,.07), 0 2px 4px rgba(0,0,0,.05);
  padding-top: 0.5625rem;
}

.navbar12 .navbar-brand12 {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6rem;
}

.navbar12 .navbar-brand12:hover {
  color: white !important;
}

.navbar12 ul li .btn {
  color: black !important;
  background: white !important;
  cursor: pointer;
  border-radius: 3px !important;
}

.navbar12 ul li .btn:hover {
  /* background-color: rgba(10, 10, 10, 0.3) !important; */
  background-color: #18458b !important;


  color: white !important;
}

.navbar12 ul li .btn i {
  font-size: 17px;
  color: red;
}
.navbar12>.container12, .navbar12>.container-fluid, .navbar12>.container-lg, .navbar12>.container-md, .navbar12>.container-sm, .navbar12>.container-xl, .navbar12>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar12.active {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.login-btn12 {
  display: flex;
  align-items: center;
  background-color: white !important;
  color: black !important;
}

.login-btn12:hover {
  /* background-color: rgba(10, 10, 10, 0.3) !important; */
  background-color: #18458b !important;
  color: white !important;
}

.login-btn12 i {
  margin-left: 0.3rem;
  font-size: 14px;
}
@media (max-width: 768px) {
  .navbar12 {
    padding-top: 0.5625rem;
    display: none;
  }

  .navbar12>.container12,
  .navbar12>.container-fluid,
  .navbar12>.container-lg,
  .navbar12>.container-md,
  .navbar12>.container-sm,
  .navbar12>.container-xl,
  .navbar12>.container-xxl {
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    width: 50%;
  }

  .navbar12 ul {
    flex-direction: column;
  }

  .navbar12 ul li {
    width: 100%;
    text-align: center;
  }

  .navbar12 ul li .btn {
    width: 100%;
    margin-bottom: 0.5rem; /* Adjust spacing between buttons */
  }

  .login-btn12 {
    width: 42%;
    margin-top: 0.5rem; /* Adjust spacing between login button and other elements */
  }
}