:root {
  --spacing: 50px;
  --heading-font: Marcellus, serif;
  --body-font: Jost, sans-serif;
  --secondary-font: Jost, sans-serif;
  --btn-font: Marcellus, serif;
  --sidebar: 360px;
  --site-width: 1300px;
  --sidebar-gap: 80px;
  --primary-color: #b99d75;
  --primary-color-semi: rgba(185, 157, 117, 0.3);
  --secondary-color: #53624e;
  --content-color: #333632;
  --theme-primary-color: var(--primary-color);
  --theme-secondary-color: var(--secondary-color);
  --btn-bg: var(--primary-color);
  --btn-color: #fff;
  --btn-bg-hover: #ab916c;
  --btn-color-hover: #fff;
  --btn-height: 52px;
  --btn-font-size: 16px;
  --btn-font-weight: 400;
  --btn-letter-spacing: 0;
  --btn-text-transform: none;
  --hf-weight: 400;
  --hf-letter-spacing: 0;
  --hf-text-transform: none;
  --hf-style: normal;
  --subheading-font: Jost, sans-serif;
  --shf-font-size: 12px;
  --shf-weight: 500;
  --shf-letter-spacing: 0.1em;
  --shf-text-transform: uppercase;
  --shf-style: normal;
  --shf-color: var(--secondary-color);
  --sf-letter-spacing: 0.05em;
  --sf-text-transform: uppercase;
  --sf-style: normal;
  --blog-title-weight: 400;
  --bt-letter-spacing: 0;
  --bt-text-transform: none;
  --bt-style: normal;
  --post-text-size: 18px;
  --post-line-height: 1.66;
  --widget-title-font: Jost, sans-serif;
  --widget-title-size: 14px;
  --widget-title-weight: 500;
  --widget-title-spacing: 0.05em;
  --widget-title-trans: uppercase;
  --widget-title-style: normal;
  --nav-font: Jost, sans-serif;
  --nav-font-size: 13px;
  --nav-font-weight: 500;
  --nav-font-letter-spacing: 0.05em;
  --nav-font-transform: uppercase;
  --fbnav-font-size: 14px;
  --fbnav-font-weight: 400;
  --fbnav-font-letter-spacing: 0.05em;
  --fbnav-font-transform: uppercase;
  --grid-unit: 25;
  --grid-val: calc(100 / var(--grid-unit));
  --side-padding: calc(var(--grid-val) * 2vw);
  --form-bd-width: 1px;
  --popup-width: 600px;
  --light-bg-color: #fff;
  --light-text-color: #1a1b1a;
  --light-content-color: #333632;
  --light-link-color: var(--primary-color);
  --light-link-color-hover: var(--primary-color);
  --dark-bg-color: #0e0d0a;
  --dark-text-color: #fff;
  --dark-content-color: #eee;
  --dark-link-color: var(--primary-color);
  --dark-link-color-hover: var(--primary-color);
}

:root .light-color {
  --bg-color: var(--light-bg-color);
  --text-color: var(--light-text-color);
  --content-color: var(--light-content-color);
  --page-title-bg: #f9f9f9;
  --page-title-color: #000;
  --link-color: var(--light-link-color);
  --link-color-hover: var(--light-link-color-hover);
  --text-lighter: #666;
  --form-bd: rgba(0, 0, 0, 0.12);
  --form-bd-focus: var(--primary-color);
  --border-color: rgba(0, 0, 0, 0.1);
  --light-grey: #f9f9f9;
}

:root .dark-color {
  --bg-color: var(--dark-bg-color);
  --text-color: var(--dark-text-color);
  --content-color: var(--dark-content-color);
  --page-title-bg: #151515;
  --page-title-color: #fff;
  --link-color: var(--dark-link-color);
  --link-color-hover: var(--dark-link-color-hover);
  --text-lighter: #aaa;
  --form-bd: rgba(255, 255, 255, 0.35);
  --form-bd-focus: var(--primary-color);
  --border-color: rgba(255, 255, 255, 0.15);
  --light-grey: #151515;
}

@media (min-width: 1025px) {
  :root {
    --grid-unit: 32;
    --spacing: 80px;
  }
}

@-webkit-keyframes stickyHeaderShow {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes stickyHeaderShow {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes fade {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@-webkit-keyframes slideFade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideFade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes showTab {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes showTab {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

*:focus {
  outline: 0;
}

html {
  width: 100%;
  min-height: 100%;
}

html.touch div {
  background-attachment: scroll !important;
}

body {
  width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0 auto;
  background-color: white;
  color: #000;
}

body#tinymce {
  padding: 20px !important;
  background: #fff;
}

html,
body,
blockquote,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dd,
dl,
dt,
pre,
fieldset,
figure,
hr,
iframe,
legend {
  margin: 0;
  padding: 0;
  border: none;
}

#page {
  position: relative;
  z-index: 2;
  overflow-x: hidden;
  width: 100%;
  height: auto;
}

ul,
ol {
  margin: 0 0 30px 30px;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

dl {
  margin: 0 0 30px;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 0 30px 30px;
}

figure {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin: 0;
}

figure img {
  display: block;
}

img {
  height: auto;
  max-width: 100%;
  border: 0;
}

hr {
  display: block;
  height: 1px;
  padding: 0;
  margin: 50px auto;
  border: 0;
  border-top: 1px solid #ccc;
}

.dark-color hr {
  background-color: rgba(255, 255, 255, 0.1);
}

hr.lo-divider {
  background: none !important;
}

.hide {
  display: none !important;
}

.containercart:after,
#page ul:after,
.main:after,
.home-widget .section-content:after,
.posts-wrapper:after,
.entry-content:after,
.single #primary > .post:after,
.post-navigation .nav-links:after,
.image-gallery:after,
.widget_mc4wp_form_widget .fields-containercart:after,
.widget-area-row:after,
.entry-content .gallery:after,
.comments-area .navigation .nav-links:after,
.widget.widget_archive ul li:after,
.widget.widget_categories ul li:after,
.lo-row:after,
.gallery[class*="gallery-columns-"]:after,
.fields-containercart:after,
.authors-list:after,
.row:after {
  position: relative;
  display: block;
  content: "";
  clear: both;
}

.says,
.screen-reader-text {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  word-wrap: normal !important;
}

.site .skip-link {
  top: -9999em;
  left: -9999em;
  display: block;
  padding: 15px 23px 14px;
  background-color: #f1f1f1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  color: #21759b;
  font-family: Montserrat, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  text-decoration: none;
  text-transform: none;
}

.logged-in .site .skip-link {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  font-family: "Open Sans", sans-serif;
}

.site .skip-link:focus {
  z-index: 100000;
  top: 7px;
  left: 6px;
  clip: auto;
  width: auto;
  height: auto;
}

.alignleft {
  float: left;
  display: inline;
}

.alignleft[class*="wp-block-"] {
  margin-right: 30px;
}

.alignright {
  float: right;
  display: inline;
}

.alignright[class*="wp-block-"] {
  margin-left: 30px;
}

.aligncenter {
  display: block;
  margin-right: auto !important;
  margin-left: auto !important;
}

figure.alignleft,
img.alignleft {
  margin: 0 30px 30px 0;
}

figure.alignright,
img.alignright {
  margin: 0 0 30px 30px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.button {
  position: relative;
  z-index: 9;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  height: var(--btn-height);
  background: var(--btn-bg);
  border: none;
  border-radius: var(--btn-radius);
  color: var(--btn-color);
  font-family: var(--btn-font);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  letter-spacing: var(--btn-letter-spacing);
  text-transform: var(--btn-text-transform);
  line-height: 1;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
}

.no-touch .button:not(.cs-btn-underline):hover {
  background: #ab916c;
  color: #fff;
  border-color: #ab916c;
}

.button.cs-btn-outline {
  --btn-color: #1a1b1a;
  background: none;
  border: 1px solid var(--btn-bg);
}

.button.cs-btn-underline {
  padding: 7px 0;
  height: auto;
  background: none;
  color: #1a1b1a;
  border-radius: 0;
}

.button.cs-btn-underline:before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: var(--btn-bg);
  content: "";
  transition: transform 0.2s ease;
  transform-origin: 0 0;
}

.no-touch .button.cs-btn-underline:hover:before {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.button.cs-btn-plain {
  background: none;
  color: inherit;
}

.button.cs-btn-small {
  --btn-height: 44px;
  padding: 0 24px;
  font-size: 14px;
  font-size: 0.875rem;
}

.button.cs-btn-small.cs-btn-underline {
  padding: 5px 0;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
}

.button.cs-btn-large {
  --btn-height: 62px;
  padding: 0 60px;
  font-size: 20px;
  font-size: 1.25rem;
}

.button.cs-btn-extra-large {
  --btn-height: 72px;
  padding: 0 60px;
  font-size: 24px;
  font-size: 1.5rem;
}

.button.cs-btn-color-primary {
  --btn-bg: var(--primary-color);
}

.button.cs-btn-color-secondary {
  --btn-bg: var(--secondary-color);
}

.button.cs-btn-color-black {
  --btn-bg: #000;
  --btn-color: #fff;
}

.button.cs-btn-color-black.cs-btn-outline {
  --btn-color: #000;
  --btn-bg-hover: #000;
  --btn-color-hover: #fff;
}

.button.cs-btn-color-white {
  --btn-bg: #fff;
  --btn-color: #000;
}

.button.cs-btn-color-white.cs-btn-outline {
  --btn-color: #fff;
  --btn-bg-hover: #fff;
  --btn-color-hover: #000;
}

.button .cs-btn-icon {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  align-self: center;
}

.button .cs-btn-icon.icon-line {
  width: 40px;
  height: 1px;
  border-top: 1px solid;
}

.button .cs-btn-icon.icon-arrow:after,
.button .cs-btn-icon.icon-plus:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: inherit;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.button .cs-btn-icon.icon-arrow:after {
  content: "\35";
}

.button .cs-btn-icon.icon-arrow.arrow-2:after {
  content: "\24";
  font-size: larger;
  right: -5px;
}

.button .cs-btn-icon.icon-arrow.arrow-3:after {
  content: "\39";
}

.button .cs-btn-icon.icon-plus:after {
  content: "\4c";
}

.button.cs-btn-underline .cs-btn-icon {
  position: absolute;
  right: -15px;
}

.button.cs-btn-underline .cs-btn-icon.icon-line {
  right: -40px;
  width: 30px;
}

.button.cs-btn-circle {
  --btn-size: 150px;
  width: var(--btn-size);
  height: var(--btn-size);
  padding: 0;
  border-radius: 999px;
  line-height: 1.3;
}

.button.cs-btn-circle .cs-btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 20px;
}

.button.cs-btn-circle.with-inner-border:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  display: block;
  content: "";
  border-radius: 999px;
  border: 1px solid;
  pointer-events: none;
  opacity: 0.8;
}

.cs-btn-square {
  --btn-radius: 0px;
}

.cs-btn-rounded {
  --btn-radius: 5px;
}

.cs-btn-pill {
  --btn-radius: 999px;
}

.tagcloud a {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  color: inherit;
}

.tagcloud a:not(:last-child):after {
  margin: 0 4px;
  content: "/";
}

.no-touch .tagcloud a:hover,
.tagcloud a:focus {
  color: #1a1b1a;
}

.close-button {
  z-index: 9;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
  cursor: pointer;
  transition: 0.4s;
}

.close-button:before,
.close-button:after {
  position: absolute;
  top: 19px;
  left: 8px;
  display: block;
  width: 25px;
  height: 2px;
  background: currentColor;
  content: "";
  transition: transform 0.25s;
}

.close-button:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-button:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.slick-dots button,
.slick-arrow {
  background: none;
  border: 0;
  box-shadow: none;
  color: inherit;
  font-size: 0;
  pointer-events: auto;
  cursor: pointer;
}

.slick-arrow {
  --arrow-size: 60px;
  position: absolute;
  top: 50%;
  z-index: 99;
  padding: 0;
  width: var(--arrow-size);
  height: var(--arrow-size);
  background: #fff;
  border-radius: 99px;
  box-shadow: 0 2px 40px -10px rgba(83, 54, 36, 0.1);
  text-align: center;
  color: #111;
  line-height: var(--arrow-size);
  cursor: pointer;
  font-feature-settings: normal;
  transition: 0.2s;
}

.cs-gallery:not(.slider-dots-overlap) .slick-dotted .slick-arrow,
.cs-rooms-carousel:not(.slider-arrow-top):not(.slider-dots-overlap)
  .slick-dotted
  + .slider-arrows
  .slick-arrow {
  margin-top: -30px;
}

.no-touch .slick-arrow:hover {
  box-shadow: 0 2px 40px -10px rgba(83, 54, 36, 0.3);
}

.slick-arrow:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: inherit;
}

.slick-arrow.slick-prev {
  left: 30px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slick-arrow.slick-prev:after {
  content: "\34";
}

.slick-arrow.slick-next {
  right: 30px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slick-arrow.slick-next:after {
  content: "\35";
}

.slick-dots {
  margin: 50px auto 0;
  text-align: center;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  list-style: none;
  height: 12px;
}

.slider-dots-left .slick-dots {
  text-align: left;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.slider-dots-left .slick-dots li:first-child {
  margin-left: -6px;
}

.slider-dots-right .slick-dots {
  text-align: right;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.slider-dots-right .slick-dots li:last-child {
  margin-right: -6px;
}

.slick-dots li {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  line-height: 0;
}

.slick-dots li:only-child {
  display: none;
}

.slick-dots li button {
  padding: 6px;
}

.slick-dots li button:before {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: currentColor;
  content: "";
  transition: 0.2s;
  transform-origin: 50% 50%;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}

.slick-dots li:not(.slick-active) button:before {
  opacity: 0.35;
}

.no-touch .slick-dots li:not(.slick-active) button:hover:before {
  opacity: 0.7;
}

.containercartcart {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: calc(var(--site-width) + var(--side-padding) * 2);
  padding: 0 var(--side-padding);
  margin-right: auto;
  margin-left: auto;
}

.custom-site-width .containercart {
  --site-width: var(--custom-site-width);
}

.cs-template-wide .containercart {
  max-width: 1600px;
}

.site-content {
  position: relative;
  z-index: 9;
}

.site-content > .containercart {
  margin-top: 50px;
}

.primary {
  padding-bottom: 80px;
}

.primary > *:last-child {
  margin-bottom: 0;
}

.sidebar {
  padding-bottom: 80px;
}

.light-color,
.dark-color {
  color: #1a1b1a;
}

/* .row > * {
    margin-bottom: 30px;
} */

.cs-notice {
  padding: 30px;
  background: lightskyblue;
  color: #111;
}

.cs-notice:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  content: "\f05a";
  margin-right: 10px;
}

@media screen and (min-width: 480px) {
  .author-social ul.social-nav li a {
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .row {
    margin: 0 -15px;
  }

  .row > * {
    padding: 0 15px;
    float: left;
  }

  .row .col-1-2 {
    width: 50%;
  }

  .row .col-1-3 {
    width: 33.333333%;
  }
}

@media screen and (min-width: 1200px) {
  .site-content[class*="with-sidebar-"] .primary {
    float: left;
    width: calc(100% - var(--sidebar) - var(--sidebar-gap));
  }

  .site-content[class*="with-sidebar-"] .sidebar {
    float: left;
    width: var(--sidebar);
  }

  .site-content[class*="with-sidebar-"] .sidebar-containercart {
    width: var(--sidebar);
  }

  .site-content[class*="with-sidebar-"].with-sidebar-left .primary {
    float: right;
  }

  .site-content[class*="with-sidebar-"].with-sidebar-right .sidebar {
    float: right;
  }
}

@media screen and (max-width: 1024px) {
  .text-left-tablet {
    text-align: left;
  }

  .text-center-tablet {
    text-align: center;
  }

  .text-right-tablet {
    text-align: right;
  }
}

@media screen and (max-width: 767px) {
  .text-left-mobile {
    text-align: left;
  }

  .text-center-mobile {
    text-align: center;
  }

  .text-right-mobile {
    text-align: right;
  }

  .slick-arrow {
    --arrow-size: 36px;
  }

  .slick-arrow.slick-prev {
    left: 15px;
  }

  .slick-arrow.slick-next {
    right: 15px;
  }
}

@media screen and (max-width: 599px) {
  .button:not(.cs-btn-underline) {
    padding: 0 24px;
  }

  .button:not(.cs-btn-underline).cs-btn-small {
    padding: 0 20px;
  }

  .button:not(.cs-btn-underline).cs-btn-large {
    padding: 0 30px;
  }

  .button:not(.cs-btn-underline).cs-btn-extra-large {
    padding: 0 30px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.site-branding .site-title,
.entry-title,
.post-title,
blockquote,
.widget.widget_rss ul li a.rsswidget,
.widget.widget_recent_entries a,
.widget_recent_comments ul li > a {
  font-family: var(--heading-font);
}

nav li {
  font-family: var(--nav-font);
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.4s;
}

.entry-content a:not([class*="button"]),
.comment-content a:not([class*="button"]),
.site-footer a:not([class*="button"]) {
  color: var(--link-color);
}

.no-touch .entry-content a:not([class*="button"]):hover,
.no-touch .comment-content a:not([class*="button"]):hover,
.no-touch .site-footer a:not([class*="button"]):hover {
  color: var(--link-color-hover);
}

.entry-content .posts.layout-overlay a,
.entry-content .cs-menu a {
  color: inherit;
}

.no-touch .entry-content .cs-menu .primary-menu a:hover,
.no-touch .entry-content .cs-menu .mobile-menu a:hover {
  color: inherit;
}

.no-touch .entry-content a.page-numbers:hover {
  color: inherit;
}

.entry-content {
  color: var(--content-color);
  font-size: var(--post-text-size);
  line-height: var(--post-line-height);
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6,
.entry-content form {
  color: #1a1b1a;
}

.entry-content > ul li:not(:last-child),
.entry-content > ol li:not(:last-child) {
  margin-bottom: 8px;
}

.entry-content > ul ul,
.entry-content > ul ol,
.entry-content > ol ul,
.entry-content > ol ol {
  margin-top: 8px;
}

.elementor-widget-text-editor ul li:not(:last-child),
.elementor-widget-text-editor ol li:not(:last-child) {
  margin-bottom: 8px;
}

.elementor-widget-text-editor ul ul,
.elementor-widget-text-editor ul ol,
.elementor-widget-text-editor ol ul,
.elementor-widget-text-editor ol ol {
  margin-top: 8px;
}

.elementor-section {
  color: var(--content-color);
  font-size: var(--post-text-size);
  line-height: var(--post-line-height);
}

.elementor-section h1,
.elementor-section h2,
.elementor-section h3,
.elementor-section h4,
.elementor-section h5,
.elementor-section h6,
.elementor-section form {
  color: #1a1b1a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--hf-weight);
  letter-spacing: var(--hf-letter-spacing);
  text-transform: var(--hf-text-transform);
  font-style: var(--hf-style);
  line-height: 1.35;
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin-bottom: 30px;
}

.entry-content * + h1,
.entry-content * + h2,
.entry-content * + h3,
.entry-content * + h4,
.entry-content * + h5,
.entry-content * + h6 {
  margin-top: 50px;
}

.entry-content .elementor-element h1,
.entry-content .elementor-element h2,
.entry-content .elementor-element h3,
.entry-content .elementor-element h4,
.entry-content .elementor-element h5,
.entry-content .elementor-element h6 {
  margin-top: 0;
}

.comment-content * + h1,
.comment-content * + h2,
.comment-content * + h3,
.comment-content * + h4,
.comment-content * + h5,
.comment-content * + h6 {
  margin-top: 30px;
}

.comment-content h1,
.widget-area .widget h1,
.comment-content h2,
.widget-area .widget h2,
.comment-content h3,
.widget-area .widget h3,
.comment-content h4,
.widget-area .widget h4,
.comment-content h5,
.widget-area .widget h5,
.comment-content h6,
.widget-area .widget h6 {
  margin-bottom: 15px;
}

.entry-content h1,
.elementor h1 {
  font-size: 36px;
  font-size: 2.25rem;
}

.entry-content h2,
.elementor h2 {
  font-size: 32px;
  font-size: 2rem;
}

.entry-content h3,
.elementor h3 {
  font-size: 16px;
}

.entry-content h4,
.elementor h4 {
  font-size: 24px;
  font-size: 1.5rem;
}

.entry-content h5,
.elementor h5 {
  font-size: 21px;
  font-size: 1.3125rem;
}

.entry-content h6,
.elementor h6 {
  font-size: 18px;
  font-size: 1.125rem;
}

.comment-content h1,
.widget-area .widget h1 {
  font-size: 28px;
  font-size: 1.75rem;
}

.comment-content h2,
.widget-area .widget h2 {
  font-size: 24px;
  font-size: 1.5rem;
}

.comment-content h3,
.widget-area .widget h3 {
  font-size: 21px;
  font-size: 1.3125rem;
}

.comment-content h4,
.widget-area .widget h4 {
  font-size: 18px;
  font-size: 1.125rem;
}

.comment-content h5,
.widget-area .widget h5 {
  font-size: 16px;
  font-size: 1rem;
}

.comment-content h6,
.widget-area .widget h6 {
  font-size: 14px;
  font-size: 0.875rem;
}

.entry-title a {
  color: inherit;
}

.entry-header h1.entry-title {
  margin-bottom: 15px;
  font-size: 36px;
  font-size: 2.25rem;
}

.entry-header h1.entry-title:last-child {
  margin-bottom: 0;
}

.posts .post-title,
.page-title-section.post-header-section .entry-title {
  font-weight: var(--blog-title-weight);
  letter-spacing: var(--bt-letter-spacing);
  text-transform: var(--bt-text-transform);
  font-style: var(--bt-style);
}

.widget h5.widget-title,
.widget.widget_block .wp-block-group__inner-containercart > h2 {
  font-family: var(--widget-title-font);
  font-size: var(--widget-title-size);
  font-weight: var(--widget-title-weight);
  letter-spacing: var(--widget-title-spacing);
  text-transform: var(--widget-title-trans);
  font-style: var(--widget-title-style);
}

.related-posts .related-posts-title,
.comments-area h2.comments-title,
.comment-respond h3.comment-reply-title {
  margin-bottom: 30px;
  font-size: 24px;
  font-size: 1.5rem;
  font-family: var(--body-font);
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.breadcrumbs,
.meta,
.cat-links,
.pagination,
.comment-metadata,
.nav-links .post-info .text,
.comments-area .navigation .nav-links a {
  font-family: var(--secondary-font);
  letter-spacing: var(--sf-letter-spacing);
  text-transform: var(--sf-text-transform);
  font-style: var(--sf-style);
  font-size: 12px;
  font-size: 0.75rem;
}

.cs-subtitle {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  font-family: var(--subheading-font);
  letter-spacing: var(--shf-letter-spacing);
  text-transform: var(--shf-text-transform);
  font-style: var(--shf-style);
  font-size: var(--shf-font-size);
  font-weight: var(--shf-weight);
  color: var(--shf-color);
}

.cs-subtitle.style-underline {
  border-bottom: 2px solid var(--primary-color);
}

.cs-subtitle.style-bordered {
  max-width: calc(100% - 100px);
}

.cs-subtitle.style-bordered:before,
.cs-subtitle.style-bordered:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  width: 70px;
  height: 1px;
  background: currentColor;
  content: "";
}

.cs-subtitle.style-bordered:before {
  left: -95px;
}

.cs-subtitle.style-bordered:after {
  right: -95px;
}

.tagcloud a {
  text-transform: none;
  letter-spacing: 0;
}

.meta,
.cat-links {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.6;
}

.breadcrumbs {
  opacity: 0.6;
}

p {
  margin: 0 auto 30px;
  font-size: inherit;
}

blockquote {
  position: relative;
  margin: 0;
}

blockquote p:last-of-type {
  margin-bottom: 0;
}

.entry-content blockquote {
  padding: 0 50px;
  margin: 50px auto;
}

.entry-content blockquote:before {
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1;
  display: block;
  width: 30px;
  height: 30px;
  color: var(--primary-color);
  font-size: 70px;
  font-size: 4.375rem;
  line-height: 30px;
  content: "\201C";
}

.entry-content blockquote:first-child {
  margin-top: 0;
}

.entry-content blockquote p {
  color: #1a1b1a;
}

.entry-content blockquote cite {
  color: inherit;
  font-style: normal;
  font-family: var(--body-font);
}

blockquote p {
  position: relative;
  z-index: 9;
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5;
}

.elementor-widget blockquote p {
  font-size: inherit;
}

blockquote cite,
blockquote small {
  display: block;
  margin-top: 20px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
}

table {
  width: 100%;
  max-width: 100%;
  margin: 0 0 30px;
  border-collapse: separate;
  border-spacing: 0;
  /* border-width: 1px;
    border-style: solid;
    border-color: var(--border-color); */
  background: none;
  border : 1px solid black;
  color: inherit;
  font-size: 16px;
  font-size: 1rem;
}

table th,
table td {
  padding: 16px;
  vertical-align: top;
  line-height: 1.5;
}

table th {
  font-weight: 600;
  text-align: left;
}

table thead tr th,
table thead tr td {
  border-bottom: 1px solid var(--border-color);
}

table tbody tr:not(:last-child) th,
table tbody tr:not(:last-child) td {
  /* border-bottom: 1px solid var(--border-color); */
}

table tfoot tr td {
  border-top: 1px solid var(--border-color);
}

table caption {
  margin: 15px auto;
  font-size: 12px;
  font-size: 0.75rem;
}

pre,
code,
kbd {
  background-color: #f7f7f7;
  color: #1a1b1a;
}

pre {
  display: block;
  padding: 30px;
  margin: 30px 0;
  border: 1px solid #eee;
  tab-size: 4;
  overflow: auto;
  font-size: 16px;
}

code,
kbd {
  padding: 5px;
  font-size: 14px;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

address {
  margin: 0 0 30px;
  font-style: italic;
}

code,
kbd,
tt,
var,
samp,
pre {
  font-family: Menlo, Consolas, monaco, monospace;
}

abbr,
acronym {
  border-bottom: 1px dotted;
  cursor: help;
}

mark {
  padding: 0;
  background: none;
  color: var(--primary-color);
  text-decoration: none;
}

ins {
  font-style: italic;
}

big {
  font-size: 125%;
}

.smaller {
  font-size: 60%;
}

@media screen and (min-width: 768px) {
  .entry-content blockquote p {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 599px) {
  .entry-content blockquote {
    padding-right: 0;
  }
}

@media screen and (max-width: 480px) {
  table th,
  table td {
    padding-right: 8px;
    padding-left: 8px;
  }
}

nav ul {
  margin: 0;
  list-style: none;
}

.site-branding {
  padding: 0;
  font-size: 0;
}

.site-branding p {
  margin-bottom: 0;
}

.site-branding .site-title {
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.2;
}

.site-branding .site-title:last-child {
  margin-bottom: 20px;
}

.site-branding .site-description {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
}

.site-branding.hide-title-tagline .site-title,
.site-branding.hide-title-tagline .site-description {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  word-wrap: normal !important;
}

.site-branding a {
  display: inline-block;
  color: inherit;
}

.site-branding img {
  display: block;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .site-branding img {
    width: 100% !important;
  }
}

.menu-toggle {
  position: relative;
  z-index: 999;
  padding: 0;
  width: fit-content;
  min-width: var(--line-width);
  height: var(--height);
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  font-size: 0 !important;
  line-height: var(--height);
  transition: 0.4s;
  cursor: pointer;
  --line-width: 35px;
  --line-height: 1px;
  --height: 50px;
}

.menu-toggle .menu-toggle-icon:before,
.menu-toggle .menu-toggle-icon:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  display: block;
  width: var(--line-width);
  border: 1px solid currentColor;
  border-width: var(--line-height);
  background: none;
  content: "";
}

.menu-toggle .menu-toggle-icon:before {
  height: 14px;
  border-right: none;
  border-left: none;
  transition: height 0.3s;
}

.menu-toggle .menu-toggle-icon:after {
  height: 1px;
  border-top: none;
  border-right: none;
  border-left: none;
}

.menu-toggle .menu-toggle-text {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-left: calc(var(--line-width) + 15px);
  text-transform: uppercase;
  line-height: var(--height);
}

.no-touch .menu-toggle:hover,
.menu-toggle:focus {
  color: var(--primary-color);
  outline: 0;
}

.menu-toggle.close-button .menu-toggle-icon {
  display: none;
}

.menu-toggle.close-button:before,
.menu-toggle.close-button:after {
  top: 24px;
  left: 5px;
}

.site-header {
  position: relative;
  z-index: 999;
  width: 100%;
  height: auto;
}

.site-header > * {
  width: 100%;
}

.site-header .containercart {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-header .site-header-main {
  position: relative;
  z-index: 3;
  background: #fff;
  color: #000;
  border-bottom: 1px solid #eee;
}

.site-header .site-header-main.with-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.site-header .header-section {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.site-header .header-section.branding {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  overflow: hidden;
  max-width: calc(100% - 80px);
}

.site-header .header-section.header-left {
  margin-left: 0;
  margin-right: auto;
}

.site-header .header-section.header-right {
  margin-right: 0;
  margin-left: auto;
}

.site-header.site-header-layout-default .header-section.menu,
.touch .site-header.site-header-layout-default .header-section.menu {
  display: none;
}

.site-header.site-header-layout-default.overlap-header:not(.is-sticky):not(
    .sticky
  )
  > * {
  background: transparent;
}

.site-header.site-header-layout-default .site-header-main {
  padding: 20px 0;
}

.touch .site-header.site-header-layout-default .menu-toggle {
  display: block;
}

.site-header.site-header-layout-default .header-section.menu {
  margin: 0 0 0 auto;
}

.site-header.site-header-layout-default .header-section.header-right {
  margin-left: 0;
}

.site-header.site-header-layout-default
  .header-section.header-right
  > *:first-child {
  margin-left: 20px;
}

.site-header.overlap-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.site-header.sticky .site-header-main,
.site-header.is-sticky .site-header-main {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999;
}

@media screen and (min-width: 601px) and (max-width: 782px) {
  .logged-in.admin-bar .site-header.sticky .site-header-main,
  .logged-in.admin-bar .site-header.is-sticky .site-header-main {
    top: 46px;
  }
}

@media screen and (min-width: 783px) {
  .logged-in.admin-bar .site-header.sticky .site-header-main,
  .logged-in.admin-bar .site-header.is-sticky .site-header-main {
    top: 32px;
  }
}

.site-header.sticky .site-header-main {
  -webkit-animation: stickyHeaderShow 0.4s ease-out;
  animation: stickyHeaderShow 0.4s ease-out;
}

.site-header.is-sticky .site-header-main {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.site-header.is-sticky.show-header .site-header-main {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition: transform 0.4s ease-out;
}

.site-header.is-sticky.hide-header .site-header-main {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: transform 0.4s ease-out;
}

.site-header.is-sticky.hide-header ul.sub-menu {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.site-header.custom-sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.site-header.custom-sticky-header.is-sticky {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.site-header.custom-sticky-header.is-sticky.show-header {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition: transform 0.4s ease-out;
}

.site-header.custom-sticky-header.is-sticky.hide-header {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: transform 0.4s ease-out;
}

.site-header.custom-sticky-header.is-sticky.hide-header ul.sub-menu {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

@media screen and (min-width: 601px) and (max-width: 782px) {
  .logged-in.admin-bar .site-header.custom-sticky-header.is-sticky {
    top: 46px;
  }
}

@media screen and (min-width: 783px) {
  .logged-in.admin-bar .site-header.custom-sticky-header.is-sticky {
    top: 32px;
  }
}

@media screen and (min-width: 601px) and (max-width: 782px) {
  .logged-in.admin-bar .site-header.custom-sticky-header.sticky {
    top: 46px;
  }
}

@media screen and (min-width: 783px) {
  .logged-in.admin-bar .site-header.custom-sticky-header.sticky {
    top: 32px;
  }
}

.dropdown-dark,
.sub-menu.dark-color {
  --sub-menu-bg: #090909;
  --sub-menu-color: #fff;
}

.dropdown-light,
.sub-menu.light-color {
  --sub-menu-bg: #fff;
  --sub-menu-color: #000;
}

.primary-menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.primary-menu li {
  position: relative;
}

.primary-menu li.menu-item-has-children > a:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
}

.primary-menu > li {
  white-space: nowrap;
}

.primary-menu > li > a {
  position: relative;
  display: block;
  padding: 0 15px;
  font-size: var(--nav-font-size);
  font-weight: var(--nav-font-weight);
  letter-spacing: var(--nav-font-letter-spacing);
  text-transform: var(--nav-font-transform);
  line-height: 60px;
}

.primary-menu > li > a:before {
  display: block;
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: currentColor;
  content: "";
  transition: transform 0.2s ease;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  transform-origin: 0 0;
}

.no-touch .primary-menu > li > a:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.primary-menu > li.menu-item-has-children > a:before {
  width: calc(100% - 50px);
}

.site-header-layout-default .primary-menu > li:last-child > a {
  margin-right: -15px;
}

.primary-menu > li > .sub-menu {
  bottom: 0;
  left: -15px;
  -webkit-transform: translateY(105%);
  -ms-transform: translateY(105%);
  transform: translateY(105%);
}

.primary-menu > li > .sub-menu .sub-menu {
  top: -20px;
  left: 100%;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
}

.primary-menu > li.current-menu-item > a:before,
.primary-menu > li.current-menu-ancestor > a:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.primary-menu > li.menu-item-has-children > a:after {
  margin-left: 5px;
  -webkit-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
  content: "\33";
}

.primary-menu .sub-menu {
  position: absolute;
  z-index: -1;
  display: block;
  min-width: 200px;
  padding: 20px 0;
  margin: 0;
  text-align: left;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  background: var(--sub-menu-bg);
  color: var(--sub-menu-color);
}

.primary-menu .sub-menu li.menu-item-has-children > a:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  content: "\35";
}

.primary-menu .sub-menu .sub-menu {
  padding-top: 20px;
}

.primary-menu .sub-menu:not(.cozystay-dropdown-menu) li {
  white-space: nowrap;
}

.primary-menu .sub-menu:not(.cozystay-dropdown-menu) a {
  position: relative;
  display: block;
  padding: 0 30px;
  font-size: 15px;
  font-size: 0.9375rem;
  letter-spacing: 0;
  text-transform: capitalize;
  line-height: 36px;
}

.primary-menu .sub-menu:not(.cozystay-dropdown-menu) a span {
  position: relative;
}

.no-touch .primary-menu .sub-menu:not(.cozystay-dropdown-menu) a:hover {
  opacity: 1;
  color: var(--primary-color);
}

.primary-menu .sub-menu li.current-menu-item > a {
  color: var(--primary-color);
}

.no-touch .primary-menu li:hover > .sub-menu,
.primary-menu li.focus > .sub-menu {
  z-index: 99;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.primary-menu > li:hover > .sub-menu,
.primary-menu > li.focus > .sub-menu {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.primary-menu .sub-menu li:hover .sub-menu,
.primary-menu .sub-menu li.focus .sub-menu {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.cozystay-dropdown-menu {
  white-space: normal;
  padding: 0;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.primary-menu .cozystay-dropdown-menu.sub-menu {
  padding: 0;
}

.cozystay-dropdown-menu > .containercart {
  display: block;
  padding: 0;
  max-width: none;
}

.cozystay-dropdown-menu a.button span:before {
  display: none;
}

.cozystay-dropdown-menu.fullwidth {
  width: 100vw;
}

.no-touch .cozystay-dropdown-menu .elementor-widget-icon-list a:hover {
  color: var(--primary-color);
}

.site-header .main-navigation .dropdown-toggle:before,
.main-navigation.not-mobile-menu .dropdown-toggle:before {
  display: none;
}

.no-touch .site-header .main-navigation .dropdown-toggle,
.no-touch .main-navigation.not-mobile-menu .dropdown-toggle {
  display: none;
}

.touch .site-header .main-navigation .dropdown-toggle,
.touch .main-navigation.not-mobile-menu .dropdown-toggle {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 35px;
  height: 36px;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  transition: 0.4s;
}

.touch .site-header .main-navigation .dropdown-toggle.toggled-on + .sub-menu,
.touch
  .main-navigation.not-mobile-menu
  .dropdown-toggle.toggled-on
  + .sub-menu {
  z-index: 99;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.touch .site-header .main-navigation .primary-menu > li:hover > .sub-menu,
.touch .main-navigation.not-mobile-menu .primary-menu > li:hover > .sub-menu {
  -webkit-transform: translateY(105%);
  -ms-transform: translateY(105%);
  transform: translateY(105%);
}

.site-header
  .main-navigation
  .primary-menu
  > li
  > .dropdown-toggle.toggled-on
  + .sub-menu,
.main-navigation.not-mobile-menu
  .primary-menu
  > li
  > .dropdown-toggle.toggled-on
  + .sub-menu {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.site-header-search,
.cs-search-toggle {
  display: block;
}

.site-header-search .toggle-button,
.cs-search-toggle .toggle-button {
  z-index: 999;
  display: block;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  font-size: 0;
  line-height: 40px;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
}

.site-header-search .toggle-button:after,
.cs-search-toggle .toggle-button:after {
  position: relative;
  z-index: 3;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  font-weight: 900;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 40px;
  content: "\f002";
}

ul.social-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  line-height: 1;
  list-style: none;
}

ul.social-nav li {
  display: block;
}

ul.social-nav li:not(:last-child) a {
  margin-right: 25px;
}

ul.social-nav li a {
  display: block;
  padding: 0;
  text-align: center;
  font-size: 0 !important;
}

.no-touch .main ul.social-nav li a:hover {
  color: var(--primary-color);
}

ul.social-nav li a:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  font-size: 16px;
  font-size: 1rem;
  content: "\f1e0";
}

.site-header ul.social-nav li a:before {
  font-size: 14px;
  font-size: 0.875rem;
}

ul.social-nav li a[title="website"]:before,
ul.social-nav li a[title="Website"]:before {
  content: "\f0c1";
}

ul.social-nav li a[href*="mailto"]:before {
  content: "\f0e0";
}

ul.social-nav li a[href*="bloglovin"]:before {
  content: "\f004";
}

ul.social-nav li a[href*="twitter"]:before,
ul.social-nav li a[href*="facebook"]:before,
ul.social-nav li a[href*="google"]:before,
ul.social-nav li a[href*="pinterest"]:before,
ul.social-nav li a[href*="instagram"]:before,
ul.social-nav li a[href*="behance"]:before,
ul.social-nav li a[href*="vimeo"]:before,
ul.social-nav li a[href*="youtube"]:before,
ul.social-nav li a[href*="snapchat"]:before,
ul.social-nav li a[href*="medium"]:before,
ul.social-nav li a[href*="linkedin"]:before,
ul.social-nav li a[href*="flickr"]:before,
ul.social-nav li a[href*="etsy"]:before,
ul.social-nav li a[href*="dribbble"]:before,
ul.social-nav li a[href*="tumblr"]:before,
ul.social-nav li a[href*="vine"]:before,
ul.social-nav li a[href*="vk"]:before,
ul.social-nav li a[href*="del.icio"]:before,
ul.social-nav li a[href*="reddit"]:before,
ul.social-nav li a[href*="yelp"]:before,
ul.social-nav li a[href*="soundcloud"]:before,
ul.social-nav li a[href*="500px"]:before,
ul.social-nav li a[href*="digg"]:before,
ul.social-nav li a[href*="foursquare"]:before,
ul.social-nav li a[href*="houzz"]:before,
ul.social-nav li a[href*="tripadvisor"]:before,
ul.social-nav li a[href*="play.google"]:before,
ul.social-nav li a[href*="apple"]:before,
ul.social-nav li a[href*="slack"]:before,
ul.social-nav li a[href*="amazon"]:before,
ul.social-nav li a[href*="codepen"]:before,
ul.social-nav li a[href*="deviantart"]:before,
ul.social-nav li a[href*="github"]:before,
ul.social-nav li a[href*="wordpress"]:before,
ul.social-nav li a[href*="tiktok"]:before,
ul.social-nav li a[href*="unsplash"]:before,
ul.social-nav li a[href*="spotify"]:before,
ul.social-nav li a[href*="wa.me"]:before {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

ul.social-nav li a[href*="twitter"]:before {
  content: "\f099";
}

ul.social-nav li a[href*="facebook"]:before {
  content: "\f39e";
}

ul.social-nav li a[href*="google"]:before {
  content: "\f0d5";
}

ul.social-nav li a[href*="pinterest"]:before {
  content: "\f0d2";
}

ul.social-nav li a[href*="instagram"]:before {
  content: "\f16d";
}

ul.social-nav li a[href*="behance"]:before {
  content: "\f1b4";
}

ul.social-nav li a[href*="vimeo"]:before {
  content: "\f27d";
}

ul.social-nav li a[href*="youtube"]:before {
  content: "\f167";
}

ul.social-nav li a[href*="snapchat"]:before {
  content: "\f2ac";
}

ul.social-nav li a[href*="medium"]:before {
  content: "\f3c7";
}

ul.social-nav li a[href*="linkedin"]:before {
  content: "\f0e1";
}

ul.social-nav li a[href*="flickr"]:before {
  content: "\f16e";
}

ul.social-nav li a[href*="etsy"]:before {
  content: "\f2d7";
}

ul.social-nav li a[href*="dribbble"]:before {
  content: "\f17d";
}

ul.social-nav li a[href*="tumblr"]:before {
  content: "\f173";
}

ul.social-nav li a[href*="vine"]:before {
  content: "\f1ca";
}

ul.social-nav li a[href*="/feed/"]:before,
ul.social-nav li a[href*="?feed="]:before {
  content: "\f09e";
}

ul.social-nav li a[href*="vk"]:before {
  content: "\f189";
}

ul.social-nav li a[href*="del.icio"]:before {
  content: "\f1a5";
}

ul.social-nav li a[href*="reddit"]:before {
  content: "\f281";
}

ul.social-nav li a[href*="yelp"]:before {
  content: "\f1e9";
}

ul.social-nav li a[href*="soundcloud"]:before {
  content: "\f1be";
}

ul.social-nav li a[href*="500px"]:before {
  content: "\f26e";
}

ul.social-nav li a[href*="digg"]:before {
  content: "\f1a6";
}

ul.social-nav li a[href*="foursquare"]:before {
  content: "\f180";
}

ul.social-nav li a[href*="houzz"]:before {
  content: "\f27c";
}

ul.social-nav li a[href*="tripadvisor"]:before {
  content: "\f262";
}

ul.social-nav li a[href*="play.google"]:before {
  content: "\f3ab";
}

ul.social-nav li a[href*="apple"]:before {
  content: "\f179";
}

ul.social-nav li a[href*="slack"]:before {
  content: "\f198";
}

ul.social-nav li a[href*="amazon"]:before {
  content: "\f270";
}

ul.social-nav li a[href*="codepen"]:before {
  content: "\f1cb";
}

ul.social-nav li a[href*="deviantart"]:before {
  content: "\f1bd";
}

ul.social-nav li a[href*="github"]:before {
  content: "\f09b";
}

ul.social-nav li a[href*="wordpress"]:before {
  content: "\f411";
}

ul.social-nav li a[href*="tiktok"]:before {
  content: "\e07b";
}

ul.social-nav li a[href*="unsplash"]:before {
  content: "\e07c";
}

ul.social-nav li a[href*="spotify"]:before {
  content: "\f1bc";
}

ul.social-nav li a[href*="wa.me"]:before {
  content: "\f232";
}

.sidemenu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;
  display: block;
  width: 100%;
  max-width: 375px;
  height: 100%;
  transition: 0.4s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.sidemenu:before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  content: "";
}

.no-touch .sidemenu a:hover {
  opacity: 0.6;
}

.no-touch .sidemenu a.button:hover {
  opacity: 1;
}

.sidemenu .containercart {
  position: relative;
  z-index: 9;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0 30px 60px;
  margin: 0;
  background-color: var(--secondary-color);
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  overflow: auto;
  transition: transform 0.4s;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sidemenu .containercart::-webkit-scrollbar {
  display: none;
}

.sidemenu .containercart > * {
  margin-bottom: 50px;
}

.sidemenu .containercart > *:last-child {
  margin-bottom: 0;
}

.sidemenu.slide-from-left {
  right: auto;
  left: 0;
}

.sidemenu.slide-from-left .containercart {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.sidemenu.fade-in .containercart {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.sidemenu.show {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.sidemenu.show .containercart {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.sidemenu .sidemenu-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 0;
}

.sidemenu.sidemenu-custom .sidemenu-header {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 30px;
  width: auto;
  border: none;
}

.sidemenu.sidemenu-custom .containercart {
  padding: 0;
}

.sidemenu .close-button {
  position: relative;
  order: 2;
  margin-left: auto;
}

.sidemenu.fullwidth {
  max-width: 100vw;
}

.cs-menu-mobile .mobile-menu {
  display: block;
  --nav-font: var(--heading-font);
}

.cs-menu-mobile .mobile-menu li {
  position: relative;
  display: block;
}

.cs-menu-mobile .mobile-menu a {
  position: relative;
  text-transform: capitalize;
}

.cs-menu-mobile .mobile-menu a:before,
.cs-menu-mobile .mobile-menu a:after {
  display: none !important;
}

.cs-menu-mobile .mobile-menu > li {
  padding: 0;
}

.cs-menu-mobile .mobile-menu > li > a {
  display: block;
  padding: 5px 0;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.35;
}

.cs-menu-mobile .mobile-menu li.current-menu-item > a,
.cs-menu-mobile .mobile-menu li.current-menu-ancestor > a {
  color: var(--primary-color);
}

.cs-menu-mobile .mobile-menu ul.sub-menu {
  display: none;
  margin: 0 0 0 30px;
}

.cs-menu-mobile .mobile-menu ul.sub-menu li a {
  padding: 4px 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
}

.cs-menu-mobile .mobile-menu ul.sub-menu .dropdown-toggle {
  top: 0;
}

.dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  transition: 0.4s;
}

.dropdown-toggle:before {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: block;
  font-size: 21px;
  font-size: 1.3125rem;
  content: "\35";
}

.dropdown-toggle.toggled-on:before {
  content: "\33";
}

.dropdown-toggle.toggled-on + ul.sub-menu {
  display: block;
}

@media screen and (min-width: 1200px) {
  .site-header.site-header-layout-default .menu-toggle {
    display: none;
  }

  .site-header.site-header-layout-default .header-section.menu {
    display: block;
    max-width: calc(100% - 300px);
  }

  .cs-menu-mobile .mobile-menu > li > a {
    font-size: 28px;
    font-size: 1.75rem;
  }

  .cs-menu-mobile .mobile-menu .dropdown-toggle {
    top: 4px;
  }
}

.meta-wrap {
  color: var(--secondary-color);
}

.meta-wrap .meta:last-child .meta-item:last-child {
  margin-right: 0;
}

.meta-wrap .meta:last-child .meta-item:last-child:after {
  display: none;
}

.meta-wrap > *:not(:last-child):after {
  position: relative;
  display: inline-block;
  margin: 0 6px;
  content: "/";
}

.no-touch .meta-wrap a:hover {
  color: inherit;
}

.meta {
  display: inline-flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.meta .meta-item {
  margin-right: 9px;
}

.meta .meta-item:last-child {
  margin-right: 0;
}

.meta .meta-item:after {
  position: relative;
  display: inline-block;
  content: "/";
  margin-left: 6px;
}

.cat-links {
  display: inline-flex;
  flex-wrap: wrap;
}

.post-header-section .cat-links {
  justify-content: center;
}

.cat-links a,
.cat-links span {
  margin-right: 3px;
}

.cat-links a:after,
.cat-links span:after {
  position: relative;
  display: inline-block;
  content: ".";
  margin-left: 3px;
}

.cat-links a:last-child:after,
.cat-links span:last-child:after {
  display: none;
}

.read-more-btn {
  border: none !important;
  font-size: 14px;
  font-size: 0.875rem;
}

.read-more-btn span {
  position: relative;
}

.read-more-btn span:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\35";
  font-size: 16px;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  right: -8px;
  -webkit-transform: translate(70%, -50%);
  -ms-transform: translate(70%, -50%);
  transform: translate(70%, -50%);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.no-touch .read-more-btn:hover span:after {
  -webkit-transform: translate(100%, -50%);
  -ms-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
}

.post-excerpt {
  color: var(--content-color);
  font-size: 16px;
  font-size: 1rem;
  z-index: 9;
  line-height: 1.6;
}

.posts {
  --post-gap: 35px;
  --img-ratio: 66.6666%;
  --ratio: 0.6666;
}

.posts .posts-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.posts.layout-masonry .masonry-column,
.posts .post,
.posts .featured-img,
.posts .featured-img a,
.posts .featured-img img,
.posts .post-content,
.posts .post-header {
  position: relative;
  width: 100%;
}

.no-touch .posts .post:hover .featured-img a {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

.posts.img-ratio-4-3 {
  --img-ratio: 75%;
  --ratio: 0.75;
}

.posts.img-ratio-1-1 {
  --img-ratio: 100%;
  --ratio: 1;
}

.posts.img-ratio-2-3 {
  --img-ratio: 150%;
  --ratio: 1.5;
}

.posts.img-ratio-4-5 {
  --img-ratio: 125%;
  --ratio: 1.25;
}

.posts .meta-wrap {
  margin-bottom: 14px;
  line-height: 1;
}

.posts .post-header,
.posts .post-title,
.posts .post-excerpt {
  margin-bottom: 15px;
}

.posts .post-title {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2;
}

.no-touch .posts .post-title a:hover {
  color: inherit;
  opacity: 0.6;
}

.posts .post-excerpt:last-child,
.posts .post-excerpt p {
  margin-bottom: 0;
}

.posts .post-header:last-child,
.posts .post-header > *:last-child {
  margin-bottom: 0;
}

.posts .post {
  margin-top: var(--post-gap);
  overflow: hidden;
  padding-bottom: 15px;
}

.posts .post:first-child {
  margin-top: 0;
}

.posts .post .featured-img {
  margin: 0 0 22px;
  overflow: hidden;
}

.posts .post .featured-img a,
.posts .post .featured-img img {
  display: block;
}

.posts .post .featured-img a {
  -webkit-transform: scale(1.01);
  -ms-transform: scale(1.01);
  transform: scale(1.01);
  transition: 0.35s ease-in-out;
}

.posts .post.sticky .post-title a {
  position: relative;
  padding-left: 20px;
}

.posts .post.sticky .post-title a:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  content: "\f02e";
  color: var(--primary-color);
  font-size: 16px;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.posts .overlay-label {
  position: absolute;
  z-index: 99;
  pointer-events: none;
  text-align: center;
}

.posts .overlay-label.time-label {
  top: 15px;
  left: 15px;
  padding: 8px 12px;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.85);
  color: #000;
}

.posts .overlay-label.time-label.featured-style {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  width: 65px;
  height: 72px;
  padding: 0;
}

.posts .overlay-label.time-label.featured-style .meta-item.time {
  font-family: var(--heading-font);
}

.posts .overlay-label.time-label.featured-style .month,
.posts .overlay-label.time-label.featured-style .day {
  display: block;
  line-height: 1;
}

.posts .overlay-label.time-label.featured-style .month {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.posts .overlay-label.time-label.featured-style .day {
  font-size: 32px;
  font-size: 2rem;
}

.posts .format-label {
  --label-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
  width: var(--label-size);
  height: var(--label-size);
  padding: 0;
  border-radius: 999px;
  border: 1px solid;
  color: #fff;
  line-height: var(--label-size);
}

.posts .format-label svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 3px 0 0 2px;
  max-width: 21px;
}

.posts.layout-grid .featured-img a,
.posts.layout-list .featured-img a {
  display: block;
  height: 0;
  padding-top: var(--img-ratio);
}

.posts.layout-grid .featured-img a img,
.posts.layout-list .featured-img a img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 100%;
  object-fit: cover;
}

.posts.layout-list.with-border {
  --bd-color: #b5bbb3;
}

.posts.layout-list.with-border .featured-img {
  margin-bottom: 0;
}

.posts.layout-list.with-border .post-content {
  padding: 20px 20px 30px;
  border: 1px solid var(--bd-color);
}

.posts.layout-list.with-border .featured-img + .post-content {
  border-top-width: 0;
}

.posts.layout-overlay .post {
  background: #000;
  color: #fff;
  padding-bottom: 0;
}

.posts.layout-overlay .post .featured-img {
  margin-bottom: 0;
}

.posts.layout-overlay .post .featured-img:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  pointer-events: none;
  content: "";
  background: var(--secondary-color);
  opacity: 0;
  transition: opacity 0.4s;
}

.posts.layout-overlay .post .format-label.format-video {
  display: none;
}

.posts.layout-overlay .post .post-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  pointer-events: none;
  padding: 150px 24px 30px;
}

.posts.layout-overlay .post .post-content:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  content: "";
  background: linear-gradient(to top, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
  transition: opacity 0.4s;
}

.posts.layout-overlay .post .post-content a {
  pointer-events: auto;
}

.posts.layout-overlay .post .post-content > * {
  position: relative;
}

.posts.layout-overlay .post .post-content,
.posts.layout-overlay .post .post-excerpt,
.posts.layout-overlay .post .meta-wrap {
  color: inherit;
}

.posts.layout-overlay .post:not(.has-post-thumbnail):before {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: var(--img-ratio);
  padding-bottom: 0;
  content: "";
}

.posts.layout-overlay .featured-img {
  z-index: 1;
}

.posts.layout-overlay .post-header {
  z-index: 9;
  margin-bottom: 10px;
}

.posts.layout-overlay .post-header:last-child {
  margin-bottom: 0;
}

.posts.layout-overlay .post-header > * {
  position: relative;
  z-index: 3;
}

.posts.layout-overlay .post-title {
  color: inherit;
}

.no-touch .posts.layout-overlay .post-title a:hover {
  opacity: 0.9;
}

.posts.layout-overlay .post-title:not(:last-child) {
  margin-bottom: 10px;
}

.posts.layout-overlay .featured-img {
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.no-touch .posts.layout-overlay .post:hover .featured-img:after {
  opacity: 0.9;
}

.no-touch .posts.layout-overlay .post:hover .post-content:before {
  opacity: 0;
}

.posts.layout-overlay.btn-slide-up .post .post-content {
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  transition: transform 0.4s;
}

.no-touch .posts.layout-overlay.btn-slide-up .post:hover .post-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.posts.layout-overlay .button.cs-btn-underline {
  color: inherit;
}

.posts .post.format-gallery .thumbnail-gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  list-style: none;
  padding: 0;
}

.posts .post.format-gallery .thumbnail-gallery li {
  position: relative;
}

.posts .post.format-gallery .thumbnail-gallery .featured-img-caption {
  display: none;
}

.posts .post.format-gallery .slick-list,
.posts .post.format-gallery .slick-track,
.posts .post.format-gallery .slick-slide {
  height: 100%;
}

.posts .post.format-gallery .slider-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  color: #fff;
}

.no-touch .posts .post.format-gallery .slider-arrows {
  opacity: 0;
  transition: opacity 0.4s;
}

.no-touch .posts .post.format-gallery:hover .slider-arrows {
  opacity: 1;
}

.posts .post.format-gallery .slick-arrow {
  --arrow-size: 36px;
}

.posts .post.format-gallery .slick-arrow.slick-prev {
  left: 15px;
}

.posts .post.format-gallery .slick-arrow.slick-next {
  right: 15px;
}

.posts.layout-standard .post.format-gallery .thumbnail-gallery,
.posts.layout-masonry .post.format-gallery .thumbnail-gallery {
  position: relative;
}

.pagination {
  --pagi-size: 32px;
  position: relative;
  width: 100%;
  margin: var(--spacing) 0 0;
  text-align: center;
  font-family: var(--heading-font);
  font-size: 18px;
  font-size: 1.125rem;
  line-height: var(--pagi-size);
  clear: both;
}

.pagination .pagination-containercart {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
}

.pagination .page-numbers {
  padding: 0;
  height: var(--pagi-size);
  text-align: center;
}

.pagination .page-numbers.current {
  border-bottom: 1px solid var(--primary-color);
}

.pagination span.page-numbers.prev,
.pagination span.page-numbers.next {
  opacity: 0.35;
}

.pagination .prev:after,
.pagination .next:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  line-height: var(--pagi-size);
  position: relative;
  transition: transform 0.3s ease-out;
}

.pagination .prev:after {
  content: "\34";
}

.pagination .next:after {
  content: "\35";
}

.pagination .prev-next {
  font-size: 14px;
  font-size: 0.875rem;
}

.pagination .prev-next .prev,
.pagination .prev-next .next {
  position: relative;
  padding: 0 18px;
  margin: 0 10px;
  width: auto;
}

.pagination .prev-next .prev:after,
.pagination .prev-next .next:after {
  position: absolute;
  top: 0;
}

.pagination .prev-next .prev {
  padding-left: 44px;
}

.pagination .prev-next .prev:after {
  left: 18px;
}

.pagination .prev-next .next {
  padding-right: 44px;
}

.pagination .prev-next .next:after {
  right: 18px;
}

.pagination .with-page-numbers .page-numbers {
  padding: 0 6px;
  margin: 0 6px;
}

.pagination .with-page-numbers .page-numbers.prev,
.pagination .with-page-numbers .page-numbers.next {
  font-size: 0;
}

.pagination .with-page-numbers .page-numbers.prev:after,
.pagination .with-page-numbers .page-numbers.next:after {
  font-size: 20px;
  font-size: 1.25rem;
}

.no-touch .pagination .with-page-numbers a:hover {
  opacity: 0.5;
}

.pagination a.load-more-btn {
  position: relative;
  z-index: 9;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  height: var(--btn-height);
  background: var(--btn-bg);
  border: none;
  border-radius: var(--btn-radius);
  color: var(--btn-color);
  font-family: var(--btn-font);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  letter-spacing: var(--btn-letter-spacing);
  text-transform: var(--btn-text-transform);
  line-height: 1;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  color: var(--btn-color) !important;
  overflow-anchor: none;
}

.no-touch .pagination a.load-more-btn:not(.cs-btn-underline):hover {
  background: #ab916c;
  color: #fff;
  border-color: #ab916c;
}

.pagination a.load-more-btn span {
  position: relative;
  z-index: 3;
}

.pagination a.load-more-btn.disabled {
  pointer-events: none;
}

.pagination .load-more .loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.pagination .load-more.loading {
  pointer-events: none;
}

.pagination .load-more.loading .btn-text {
  opacity: 0;
}

.pagination .load-more.loading .loading-text {
  opacity: 1;
  -webkit-animation: fade 3s linear infinite;
  animation: fade 3s linear infinite;
}

.pagination .load-more.infinite .loading-text {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
}

.pagination .no-more-posts-message {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.4;
}

.pagination .no-more-posts-message:before,
.pagination .no-more-posts-message:after {
  content: " - ";
}

@media screen and (min-width: 768px) {
  .posts.layout-masonry .posts-wrapper {
    display: block;
    margin-left: calc(0px - var(--post-gap));
  }

  .posts.layout-masonry .masonry-column {
    float: left;
    width: 50%;
    padding-left: var(--post-gap);
  }

  .posts.layout-grid .posts-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: calc(0px - var(--post-gap));
  }

  .posts.layout-grid .post {
    margin-right: 0;
    margin-left: var(--post-gap);
    width: calc(50% - var(--post-gap));
  }

  .posts.layout-grid .post:nth-child(-n + 2) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1024px) {
  .posts .post-title {
    font-size: 24px;
    font-size: 1.5rem;
  }

  .posts.layout-standard .post-title {
    font-size: 32px;
    font-size: 2rem;
  }

  .posts.layout-masonry.column-3 .masonry-column {
    width: 33.333333%;
  }

  .posts.layout-list {
    --col-gap: 35px;
  }

  .posts.layout-list .post {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .posts.layout-list .featured-img {
    margin-right: var(--col-gap);
    margin-bottom: 0;
    width: 50%;
  }

  .posts.layout-list .post-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
  }

  .posts.layout-list .post-content > * {
    width: 100%;
  }

  .posts.layout-list .featured-img + .post-content {
    padding: 0;
    width: calc(50% - var(--col-gap));
  }

  .posts.layout-list.layout-zigzag .post:nth-child(2n) {
    flex-direction: row-reverse;
  }

  .posts.layout-list.layout-zigzag .post:nth-child(2n) .featured-img {
    margin-right: 0;
    margin-left: var(--col-gap);
  }

  .posts.layout-list.with-border .post-content {
    padding: var(--col-gap);
  }

  .posts.layout-list.with-border .featured-img {
    margin: 0;
  }

  .posts.layout-list.with-border .featured-img + .post-content {
    width: 50%;
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 1px;
    border-left-width: 0;
  }

  .posts.layout-list.with-border.layout-zigzag
    .post:nth-child(2n)
    .featured-img {
    margin: 0;
  }

  .posts.layout-list.with-border.layout-zigzag
    .post:nth-child(2n)
    .featured-img
    + .post-content {
    border-right-width: 0;
    border-left-width: 1px;
  }

  .posts.layout-grid.column-3 .post {
    width: calc(33.333333% - var(--post-gap));
  }

  .posts.layout-grid.column-3 .post:nth-child(-n + 3) {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .posts .post-title {
    font-size: 30px;
    font-size: 1.875rem;
  }

  .related-posts .posts .post-title {
    font-size: 24px;
    font-size: 1.5rem;
  }

  .site-content[class*="with-sidebar-"]
    .primary
    .posts.layout-masonry.column-3
    .post-title,
  .site-content[class*="with-sidebar-"]
    .primary
    .posts.layout-grid.column-3
    .post-title {
    font-size: 24px;
    font-size: 1.5rem;
  }

  .posts.layout-standard .post-title,
  .site-content:not([class*="with-sidebar-"]) .posts.layout-list .post-title {
    font-size: 36px;
    font-size: 2.25rem;
  }

  .posts.layout-standard .post-excerpt,
  .site-content:not([class*="with-sidebar-"]) .posts.layout-list .post-excerpt {
    font-size: 18px;
    font-size: 1.125rem;
  }

  .posts.layout-standard .read-more-btn,
  .site-content:not([class*="with-sidebar-"])
    .posts.layout-list
    .read-more-btn {
    font-size: 16px;
    font-size: 1rem;
  }

  .posts.layout-standard .post.format-gallery .slider-dots,
  .posts.layout-list .post.format-gallery .slider-dots,
  .posts.column-2 .post.format-gallery .slider-dots {
    bottom: 35px;
  }

  .site-content:not([class*="with-sidebar-"]) .posts.layout-list {
    --col-gap: 60px;
  }

  .site-content:not([class*="with-sidebar-"])
    .elementor-column:not(:only-child)
    .posts.layout-list {
    --col-gap: 35px;
  }
}

@media screen and (min-width: 1366px) {
  .posts {
    --post-gap: 50px;
  }
}

@media screen and (min-width: 1440px) {
  .site-content[class*="with-sidebar-"] .primary .posts.layout-masonry .post,
  .site-content[class*="with-sidebar-"] .primary .posts.layout-grid .post {
    padding-bottom: 20px;
  }

  .site-content[class*="with-sidebar-"]
    .primary
    .posts.layout-grid.layout-overlay
    .post {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .posts.layout-overlay:not(.cs-rooms) .post-excerpt {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .posts.img-ratio-3-2-tablet {
    --img-ratio: 66.66%;
    --ratio: 0.6666;
  }

  .posts.img-ratio-4-3-tablet {
    --img-ratio: 75%;
    --ratio: 0.75;
  }

  .posts.img-ratio-1-1-tablet {
    --img-ratio: 100%;
    --ratio: 1;
  }

  .posts.img-ratio-2-3-tablet {
    --img-ratio: 150%;
    --ratio: 1.5;
  }

  .posts.img-ratio-4-5-tablet {
    --img-ratio: 125%;
    --ratio: 1.25;
  }
}

@media screen and (max-width: 767px) {
  .posts.img-ratio-3-2-mobile {
    --img-ratio: 66.66%;
    --ratio: 0.6666;
  }

  .posts.img-ratio-4-3-mobile {
    --img-ratio: 75%;
    --ratio: 0.75;
  }

  .posts.img-ratio-1-1-mobile {
    --img-ratio: 100%;
    --ratio: 1;
  }

  .posts.img-ratio-2-3-mobile {
    --img-ratio: 150%;
    --ratio: 1.5;
  }

  .posts.img-ratio-4-5-mobile {
    --img-ratio: 125%;
    --ratio: 1.25;
  }
}

@media screen and (max-width: 599px) {
  .posts .overlay-label.time-label.featured-style {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

.page-title-section {
  position: relative;
  overflow: hidden;
  padding: 60px 0;
  margin-bottom: var(--spacing);
  background: var(--page-title-bg);
  color: var(--page-title-color);
  text-align: center;
  --page-title-weight: var(--hf-weight);
}

.page-title-section.page-title-default {
  padding: 80px 0;
}

.page-title-section.page-title-large {
  padding: 100px 0;
}

.page-title-section.page-title-fullheight {
  height: 100vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
}

.page-title-section.page-title-fullheight .containercart:after {
  display: inline-block;
  margin-top: 30px;
  width: 1px;
  height: 65px;
  background: currentColor;
  opacity: 0.8;
}

.page-title-section.page-title-fullheight .page-title-extra {
  position: absolute;
  bottom: 60px;
  left: 0;
  z-index: 9;
  width: 100%;
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.page-title-section.page-title-fullheight .entry-title:not(:first-child) {
  margin-bottom: 30px;
}

.page-title-section .entry-title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1;
  font-weight: var(--page-title-weight);
}

.page-title-section .entry-title:not(:first-child) {
  margin-top: 20px;
}

.page-title-section .entry-title:not(:last-child) {
  margin-bottom: 20px;
}

.page-title-section p,
.page-title-section .description {
  margin: 0 auto;
  max-width: 800px;
}

.page-title-section .containercart {
  z-index: 9;
}

.page-title-section .page-title-bg + .containercart .breadcrumbs {
  opacity: 1;
}

.no-touch
  .page-title-section
  .page-title-bg
  + .containercart
  .breadcrumbs
  a:hover {
  opacity: 0.7;
}

.page-title-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.post-header-section {
  --post-header-meta-color: var(--secondary-color);
}

.post-header-section .page-title-bg:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.45;
  content: "";
}

.post-header-section .meta-wrap {
  color: var(--post-header-meta-color);
}

.post-header-section .page-title-bg + .containercart {
  color: #fff;
  --post-header-meta-color: #fff;
}

.no-touch .post-header-section .page-title-bg + .containercart a:hover {
  opacity: 0.7;
}

.post-header-section .page-title-bg ~ .page-title-extra {
  color: #fff;
}

.overlap-header ~ .site-content .page-title-section .containercart {
  margin-top: 100px;
}

.single .primary,
.page .primary {
  margin-right: auto;
  margin-left: auto;
}

.single .primary > .post .entry-content > *:last-child,
.page .primary > .post .entry-content > *:last-child {
  margin-bottom: 0;
}

.single-custom_blocks .primary,
.single-custom_site_headers .primary {
  max-width: 100%;
}

.article-footer {
  margin-top: 50px;
  color: var(--text-lighter);
  font-family: var(--heading-font);
  font-size: 15px;
  font-size: 0.9375rem;
}

.article-footer:before {
  position: relative;
  display: block;
  margin-bottom: 50px;
  width: 50px;
  height: 1px;
  background: currentColor;
  content: "";
}

.article-footer .article-footer-title {
  margin-right: 5px;
}

.article-footer .post-tag-cloud {
  position: relative;
  display: flex;
}

.article-footer .post-tag-cloud .tagcloud {
  display: flex;
  flex-wrap: wrap;
}

.article-share {
  display: flex;
}

.article-share:not(:first-child) {
  margin-top: 15px;
}

.article-share .article-share-containercart {
  display: flex;
  flex-wrap: wrap;
}

.article-share .article-share-containercart a:not(:last-child):after {
  margin: 0 4px;
  content: "-";
}

.article-share .article-share-containercart a i {
  display: none;
}

.no-touch .article-share .article-share-containercart a:hover,
.article-share .article-share-containercart a:focus {
  color: #1a1b1a;
}

.comments-area {
  --cauthor-img: 48px;
  padding-top: var(--spacing);
}

.comments-area ol.comment-list {
  margin: 0 auto;
  list-style: none;
}

.comments-area ol.comment-list + .comment-respond {
  margin-top: 40px;
}

.comments-area ol.comment-list ol.children {
  margin: 30px 0 0 30px;
}

.comments-area ol.comment-list li.comment,
.comments-area ol.comment-list li.pingback,
.comments-area ol.comment-list li.trackback {
  position: relative;
  padding: 30px 0;
  list-style: none;
  border-top: 1px dotted var(--border-color);
}

.comments-area ol.comment-list li:not(.depth-1):last-of-type {
  padding-bottom: 0;
}

.comments-area ol.comment-list li.pingback,
.comments-area ol.comment-list li.trackback {
  font-weight: 700;
}

.comments-area ol.comment-list li.pingback a,
.comments-area ol.comment-list li.trackback a {
  font-weight: 400;
}

.comments-area ol.comment-list li:last-child {
  margin-bottom: 0 !important;
}

.comments-area ol.comment-list li.comment .comment-body {
  position: relative;
}

.comments-area ol.comment-list li .comment-meta {
  padding-left: 64px;
  margin-bottom: 10px;
}

.comments-area ol.comment-list li .comment-meta .comment-author.vcard {
  font-family: var(--heading-font);
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
}

.comments-area ol.comment-list li .comment-meta .comment-author.vcard img {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--cauthor-img);
  height: var(--cauthor-img);
}

.comments-area ol.comment-list li .comment-meta .comment-author.vcard b.fn {
  font-weight: 400;
}

.no-touch
  .comments-area
  ol.comment-list
  li
  .comment-meta
  .comment-author.vcard
  b.fn
  a:hover {
  color: var(--primary-color);
}

.comments-area
  ol.comment-list
  li
  .comment-meta
  .comment-author.vcard
  .author-label {
  display: none;
}

.comments-area ol.comment-list li .comment-meta .comment-metadata {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
}

.comments-area ol.comment-list li .comment-meta .comment-metadata a {
  color: var(--text-lighter);
}

.comments-area ol.comment-list li a.comment-edit-link {
  margin-left: 4px;
}

.comments-area ol.comment-list li a.comment-edit-link:before {
  content: "-";
  margin-right: 8px;
}

.no-touch .comments-area ol.comment-list li a.comment-edit-link:hover {
  color: var(--primary-color);
}

.comments-area ol.comment-list li .comment-content > *:last-child {
  margin-bottom: 0;
}

.comments-area ol.comment-list li .comment-content img.wprm-comment-rating {
  margin: 10px 0;
}

.comments-area ol.comment-list li .comment-content p {
  color: var(--content-color);
  font-size: inherit;
}

.comments-area ol.comment-list li .comment-content p,
.comments-area ol.comment-list li .comment-content dd,
.comments-area ol.comment-list li .comment-content address {
  margin-bottom: 10px;
}

.comments-area ol.comment-list li .comment-content hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

.comments-area ol.comment-list li .comment-content li {
  margin-bottom: 8px;
}

.comments-area ol.comment-list li .comment-content li ul,
.comments-area ol.comment-list li .comment-content li ol {
  margin-top: 8px;
}

.comments-area ol.comment-list li .comment-content blockquote {
  padding: 30px;
  margin: 30px 0;
  background: rgba(0, 0, 0, 0.025);
  border-left: 4px solid var(--primary-color);
}

.comments-area ol.comment-list li .comment-content blockquote p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.8;
}

.comments-area ol.comment-list li .comment-content blockquote p:last-child {
  margin-bottom: 0;
}

.comments-area ol.comment-list li .comment-content blockquote:last-child {
  margin-bottom: 0;
}

.comments-area ol.comment-list li .reply {
  margin-top: 14px;
  font-family: var(--heading-font);
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 500;
}

.comments-area ol.comment-list li .reply a {
  position: relative;
  display: inline-block;
  line-height: 22px;
}

.comments-area ol.comment-list li .reply a:before {
  position: absolute;
  bottom: -1px;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: var(--primary-color);
  content: "";
  transition: transform 0.2s ease;
  transform-origin: 0 0;
}

.no-touch .comments-area ol.comment-list li .reply a:hover:before {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.comments-area ol.comment-list li.bypostauthor > .comment-body b.fn:after {
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 4px;
  border-radius: 50%;
  color: var(--primary-color);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  content: "\f005";
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 17px;
  text-align: center;
}

.comments-area ol.comment-list li .comment-respond {
  margin-top: 50px;
}

.comments-area ol.comment-list li .comment-respond #cancel-comment-reply-link {
  padding: 0 15px;
  background: none;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  font-family: var(--body-font);
  opacity: 0.6;
}

.no-touch
  .comments-area
  ol.comment-list
  li
  .comment-respond
  #cancel-comment-reply-link:hover {
  opacity: 1;
}

.comments-area ol.comment-list li .comment-respond .comment-reply-title-wrap {
  display: block;
  text-align: left;
}

.comments-area
  ol.comment-list
  li
  .comment-respond
  .comment-reply-title-wrap
  .title-decor-line {
  display: none;
}

.comments-area
  ol.comment-list
  li
  .comment-respond
  .comment-reply-title-wrap
  .comment-reply-title {
  padding: 0;
  white-space: normal;
}

.comments-area .comment-form {
  color: var(--content-color);
}

.comments-area .navigation {
  margin-top: 20px;
  margin-bottom: 50px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}

.comments-area .navigation .nav-links {
  padding: 15px 0;
  display: flex;
}

.comments-area .navigation .nav-links a {
  display: block;
  color: var(--content-color);
  opacity: 0.7;
  font-size: 12px;
  font-size: 0.75rem;
}

.no-touch .comments-area .navigation .nav-links a:hover {
  opacity: 1;
}

.comments-area .navigation .nav-links .nav-previous {
  margin: 0;
}

.comments-area .navigation .nav-links .nav-next {
  margin: 0 0 0 auto;
}

.comments-area .navigation .nav-links .nav-previous a:before,
.comments-area .navigation .nav-links .nav-next a:after {
  display: inline-block;
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-size: 0.75rem;
}

.comments-area .navigation .nav-links .nav-previous a:before {
  content: "\34";
  margin-right: 8px;
}

.comments-area .navigation .nav-links .nav-next a:after {
  content: "\35";
  margin-left: 8px;
}

.comments-area .comments-closed {
  padding: 16px 0;
  margin-bottom: 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  font-weight: 400;
  opacity: 0.5;
}

.comments-area .logged-in-as a {
  color: var(--link-color);
}

.no-touch .comments-area .logged-in-as a:hover {
  color: var(--link-color-hover);
}

.author-info-box {
  position: relative;
  padding: 30px;
  margin: 50px 0 0;
  border: 1px solid var(--primary-color);
  color: #1a1b1a;
  text-align: center;
}

.author-info-box .author-photo {
  margin: 0 auto 25px;
  width: 150px;
}

.author-info-box .author-photo img {
  display: block;
}

.author-info-box .author-info > *:not(:last-child) {
  margin-bottom: 20px;
}

.author-info-box .author-info .author-name {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
}

.no-touch .author-info-box .author-info .author-name a:hover {
  color: var(--primary-color);
}

.author-info-box .author-info p {
  margin-bottom: 15px;
  font-size: 16px;
  font-size: 1rem;
}

.author-info-box .author-info p:last-child {
  margin-bottom: 0;
}

.author-info-box .author-info-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.author-info-box .author-info-footer .author-profile-link {
  font-family: var(--heading-font);
  font-size: 13px;
  font-size: 0.8125rem;
  text-transform: uppercase;
}

.author-info-box .author-info-footer .author-profile-link:not(:last-child) {
  margin-right: 45px;
}

.author-info-box .author-info-footer .author-profile-link a {
  position: relative;
  display: block;
}

.author-info-box .author-info-footer .author-profile-link a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: var(--primary-color);
  content: "";
  transition: transform 0.2s ease;
  transform-origin: 0 0;
}

.no-touch
  .author-info-box
  .author-info-footer
  .author-profile-link
  a:hover:before {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.author-info-box .author-info-footer .author-social a {
  opacity: 0.7;
}

.no-touch .author-info-box .author-info-footer .author-social a:hover {
  opacity: 1;
}

.page-title-section .author-social {
  margin-top: 20px;
}

.page-title-section .author-social ul.social-nav {
  justify-content: center;
}

.single .post-navigation {
  padding: 60px 0 0;
}

.single .post-navigation .nav-links {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
}

.single .post-navigation .nav-links a {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  height: 100%;
  transition: 0s;
  overflow: hidden;
}

.single .post-navigation .nav-links a .post-title {
  font-weight: 400;
  font-size: 20px;
  font-size: 1.25rem;
  transition: all 0.4s;
  line-height: 1.2;
}

.single .post-navigation .nav-links a > * {
  margin-left: 0;
}

.no-touch .single .post-navigation .nav-links a:hover .post-title {
  opacity: 0.6;
}

.single .post-navigation .nav-previous,
.single .post-navigation .nav-next {
  padding: 10px 0;
}

.single .post-navigation .nav-previous:not(:only-child),
.single .post-navigation .nav-next:not(:only-child) {
  width: 50%;
}

.single .post-navigation .nav-previous:not(:only-child) {
  padding-right: 20px;
  border-right: 1px solid var(--primary-color);
}

.single .post-navigation .nav-next {
  margin-left: auto;
  text-align: right;
}

.single .post-navigation .nav-next:not(:only-child) {
  padding-left: 20px;
}

.single .post-navigation .nav-next a {
  justify-content: flex-end;
}

.single .post-navigation .post-info span {
  display: block;
  word-break: break-word;
}

.single .post-navigation .post-info span.text {
  margin-bottom: 8px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0.5;
}

.related-posts {
  margin-top: 60px;
}

.related-posts .related-posts-title {
  margin-bottom: 50px;
  text-align: center;
}

.search-results .posts .featured-img {
  margin-bottom: 20px;
}

.search-results .posts .featured-img img {
  display: block;
}

.error404 .primary {
  padding-bottom: 0;
}

.page.page-404 .page-404-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  padding: var(--spacing) 0;
  min-height: calc(100vh - 240px);
  text-align: center;
  font-size: var(--post-text-size);
}

.page.page-404 .page-404-content > *:last-child {
  margin-bottom: 0;
}

.page.page-404 .page-404-content .entry-title {
  margin-bottom: 15px;
  line-height: 1.2;
}

.search-no-results .no-results.not-found {
  text-align: center;
  font-size: var(--post-text-size);
}

.search-no-results .no-results.not-found .entry-title {
  margin-bottom: 30px;
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.2;
}

.search-no-results .no-results.not-found .search-form {
  margin-right: auto;
  margin-left: auto;
  max-width: 420px;
}

.page-links {
  display: block;
  clear: both;
  --pagi-size: 32px;
  line-height: var(--pagi-size);
  font-size: 15px;
  font-size: 0.9375rem;
}

.page-links .page-links-containercart {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.page-links .page-links-title {
  margin-right: 10px;
}

.page-links .post-page-numbers {
  margin: 0 6px;
  padding: 0 6px;
  width: auto;
  height: var(--pagi-size);
  text-align: center;
  line-height: var(--pagi-size);
}

.page-links .post-page-numbers.current {
  border-bottom: 1px solid var(--primary-color);
}

.tribe-events-event-meta.primary {
  margin: 0;
}

.single .tribe-events-single .tribe-events-sub-nav .tribe-events-nav-next {
  margin-left: auto;
}

.single-tribe_events
  .page-title-section
  .page-title-bg:not(.page-title-default-background-image):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.25;
  content: "";
}

.single-tribe_events
  .page-title-section
  .page-title-bg:not(.page-title-default-background-image)
  + .containercart {
  color: #fff;
}

.tribe-events-content a.button {
  border: none !important;
  transition: all 0.4s;
}

@media screen and (min-width: 600px) {
  .comments-area {
    --cauthor-img: 64px;
    --side-space: calc(var(--cauthor-img) + 20px);
  }

  .comments-area ol.comment-list li.comment .comment-body {
    padding-left: var(--side-space);
  }

  .comments-area ol.comment-list li .comment-meta {
    padding: 0;
  }

  .comments-area ol.comment-list li .comment-meta .comment-author.vcard {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {
  .page-title-section.page-title-large .entry-title,
  .page-title-section.page-title-fullheight .entry-title {
    font-size: 60px;
    font-size: 3.75rem;
  }

  .page-title-section.page-title-large p,
  .page-title-section.page-title-large .description,
  .page-title-section.page-title-fullheight p,
  .page-title-section.page-title-fullheight .description {
    font-size: 18px;
    font-size: 1.125rem;
  }

  .comments-area ol.comment-list ol.children {
    margin-left: var(--side-space);
  }

  .comments-area .comment-respond form.comment-form {
    margin: 0 -10px;
  }

  .comments-area .comment-respond form.comment-form > * {
    padding: 0 10px;
  }

  .comments-area .comment-respond form.comment-form .comment-form-author,
  .comments-area .comment-respond form.comment-form .comment-form-email,
  .comments-area .comment-respond form.comment-form .comment-form-url {
    float: left;
    width: 33.333333%;
  }

  .author-info-box {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    text-align: left;
  }

  .author-info-box .author-photo {
    align-self: flex-start;
    margin: 0 30px 0 0;
  }

  .author-info-box .author-photo + .author-info {
    width: calc(100% - 180px);
  }

  .author-info-box .author-info {
    width: 100%;
  }

  .author-info-box .author-info-footer {
    justify-content: flex-start;
  }

  .page.page-404 .page-404-content .entry-title {
    font-size: 48px;
    font-size: 3rem;
  }
}

@media screen and (min-width: 1024px) {
  .comments-area ol.comment-list li .reply {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
  }

  .search-no-results .no-results.not-found .entry-title {
    font-size: 48px;
    font-size: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .page .site-content[class*="with-sidebar-"] .primary,
  .single .site-content[class*="with-sidebar-"] .primary {
    max-width: none;
  }

  .page-title-section .entry-title {
    font-size: 60px;
    font-size: 3.75rem;
  }

  .page-title-section.page-title-default {
    padding: 100px 0;
  }

  .page-title-section.page-title-large {
    padding: 180px 0;
  }

  .page-title-section.page-title-large .entry-title {
    font-size: 72px;
    font-size: 4.5rem;
  }

  .page-title-section.page-title-small .entry-title {
    font-size: 42px;
    font-size: 2.625rem;
  }

  .page-title-section.page-title-fullheight .entry-title {
    font-size: 72px;
    font-size: 4.5rem;
  }

  .comments-area {
    --cauthor-img: 110px;
  }

  .page.page-404 .page-404-content .entry-title {
    font-size: 80px;
    font-size: 5rem;
  }
}

@media screen and (max-width: 480px) {
  .author-info-box .author-info-footer {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .author-info-box .author-info-footer .author-profile-link:not(:last-child) {
    margin: 0 0 25px;
  }
}

.widget {
  position: relative;
  margin: 0 auto;
}

.widget select {
  display: block;
  width: 100%;
}

.widget.widget_calendar table#wp-calendar {
  margin-bottom: 0;
  border: none;
  border-spacing: 2px;
}

.widget.widget_calendar table#wp-calendar caption {
  margin-top: 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
}

.widget.widget_calendar table#wp-calendar th,
.widget.widget_calendar table#wp-calendar td {
  padding: 0 5px;
  text-align: center;
  border: none !important;
}

.widget.widget_calendar table#wp-calendar th {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 400;
}

.widget.widget_calendar table#wp-calendar td {
  font-size: 13px;
  font-size: 0.8125rem;
}

.widget.widget_calendar table#wp-calendar tbody td {
  position: relative;
  background: #f7f7f7;
  line-height: 40px;
}

.dark-color .widget.widget_calendar table#wp-calendar tbody td {
  background: #363636;
}

.widget.widget_calendar table#wp-calendar tbody td[colspan="2"],
.widget.widget_calendar table#wp-calendar tbody td[colspan="3"],
.widget.widget_calendar table#wp-calendar tbody td[colspan="4"],
.widget.widget_calendar table#wp-calendar tbody td[colspan="5"],
.widget.widget_calendar table#wp-calendar tbody td[colspan="6"] {
  background: none;
}

.widget.widget_calendar table#wp-calendar tbody td a {
  font-weight: 800;
}

.widget.widget_calendar table#wp-calendar tbody td a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  opacity: 0.1;
  content: "";
}

.widget.widget_calendar table#wp-calendar tfoot td {
  line-height: 36px;
  font-size: 11px;
  font-size: 0.6875rem;
  text-transform: uppercase;
}

.widget.widget_calendar table#wp-calendar tfoot td a {
  padding: 2px 10px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  color: inherit;
}

.dark-color .widget.widget_calendar table#wp-calendar tfoot td a {
  border-color: #393939;
}

.no-touch .widget.widget_calendar table#wp-calendar tfoot td a:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.widget.widget_calendar .wp-calendar-nav {
  font-size: 0;
}

.widget.widget_calendar .wp-calendar-nav .wp-calendar-nav-prev,
.widget.widget_calendar .wp-calendar-nav .wp-calendar-nav-next {
  display: inline-block;
  width: 45%;
  text-align: center;
  line-height: 36px;
  font-size: 11px;
  font-size: 0.6875rem;
  text-transform: uppercase;
}

.widget.widget_calendar .wp-calendar-nav .wp-calendar-nav-prev a,
.widget.widget_calendar .wp-calendar-nav .wp-calendar-nav-next a {
  padding: 2px 10px;
  border: 1px solid #e6e6e6;
  color: inherit;
}

.dark-color .widget.widget_calendar .wp-calendar-nav .wp-calendar-nav-prev a,
.dark-color .widget.widget_calendar .wp-calendar-nav .wp-calendar-nav-next a {
  border-color: #393939;
}

.no-touch
  .widget.widget_calendar
  .wp-calendar-nav
  .wp-calendar-nav-prev
  a:hover,
.no-touch
  .widget.widget_calendar
  .wp-calendar-nav
  .wp-calendar-nav-next
  a:hover {
  background: #1a1b1a;
  border-color: #1a1b1a;
  color: white;
}

.widget.widget_calendar .wp-calendar-nav .pad {
  display: inline-block;
  width: 10%;
}

.widget.widget_categories ul,
.widget.widget_archive ul,
.widget.widget_nav_menu ul,
.widget.widget_meta ul,
.widget.widget_pages ul,
.widget.widget_rss ul,
.widget.widget_recent_entries ul {
  margin: 0;
  list-style: none;
}

.widget.widget_categories ul li,
.widget.widget_archive ul li,
.widget.widget_nav_menu ul li,
.widget.widget_meta ul li,
.widget.widget_pages ul li,
.widget.widget_rss ul li,
.widget.widget_recent_entries ul li {
  position: relative;
}

.widget.widget_categories ul li:not(:last-child),
.widget.widget_archive ul li:not(:last-child),
.widget.widget_nav_menu ul li:not(:last-child),
.widget.widget_meta ul li:not(:last-child),
.widget.widget_pages ul li:not(:last-child),
.widget.widget_rss ul li:not(:last-child),
.widget.widget_recent_entries ul li:not(:last-child) {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.dark-color .widget.widget_categories ul li:not(:last-child),
.dark-color .widget.widget_archive ul li:not(:last-child),
.dark-color .widget.widget_nav_menu ul li:not(:last-child),
.dark-color .widget.widget_meta ul li:not(:last-child),
.dark-color .widget.widget_pages ul li:not(:last-child),
.dark-color .widget.widget_rss ul li:not(:last-child),
.dark-color .widget.widget_recent_entries ul li:not(:last-child) {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.widget.widget_categories ul li a,
.widget.widget_archive ul li a,
.widget.widget_nav_menu ul li a,
.widget.widget_meta ul li a,
.widget.widget_pages ul li a,
.widget.widget_rss ul li a,
.widget.widget_recent_entries ul li a {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 30px;
}

.no-touch .widget.widget_categories ul li a:hover,
.no-touch .widget.widget_archive ul li a:hover,
.no-touch .widget.widget_nav_menu ul li a:hover,
.no-touch .widget.widget_meta ul li a:hover,
.no-touch .widget.widget_pages ul li a:hover,
.no-touch .widget.widget_rss ul li a:hover,
.no-touch .widget.widget_recent_entries ul li a:hover {
  color: var(--link-color-hover);
}

.widget.widget_categories ul li ul,
.widget.widget_archive ul li ul,
.widget.widget_nav_menu ul li ul,
.widget.widget_meta ul li ul,
.widget.widget_pages ul li ul,
.widget.widget_rss ul li ul,
.widget.widget_recent_entries ul li ul {
  margin-top: 7.5px;
  margin-left: 20px;
  clear: both;
}

.widget.widget_categories ul li ul li,
.widget.widget_archive ul li ul li,
.widget.widget_nav_menu ul li ul li,
.widget.widget_meta ul li ul li,
.widget.widget_pages ul li ul li,
.widget.widget_rss ul li ul li,
.widget.widget_recent_entries ul li ul li {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.widget.widget_archive ul li,
.widget.widget_categories ul li {
  font-size: 14px;
  font-size: 0.875rem;
  text-align: right;
  line-height: 30px;
}

.widget.widget_archive ul li a,
.widget.widget_categories ul li a {
  float: left;
  width: 80%;
  text-align: left;
}

.widget.widget_categories ul ul li:before,
.widget.widget_nav_menu ul ul li:before,
.widget.widget_pages ul ul li:before {
  position: absolute;
  top: 1px;
  left: -20px;
  width: 20px;
  color: inherit;
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\35";
  line-height: inherit;
  text-align: left;
  color: var(--primary-color);
}

.widget.widget_categories ul li a:not(:last-child) {
  margin-bottom: 6px;
}

.widget.widget_recent_entries ul li a {
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
}

.widget.widget_recent_entries .post-date {
  display: block;
  margin-top: 2px;
  font-size: 13px;
  font-size: 0.8125rem;
  opacity: 0.5;
}

.widget.widget_recent_comments ul {
  margin: 0;
  list-style: none;
}

.widget.widget_recent_comments ul li {
  font-size: 14px;
  font-size: 0.875rem;
}

.widget.widget_recent_comments ul li:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.widget.widget_recent_comments ul li > a {
  display: block;
  margin-top: 5px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
}

.no-touch .widget.widget_recent_comments ul li > a:hover {
  color: var(--primary-color);
}

.widget.widget_rss .widget-title .rsswidget:first-child .rss-widget-icon {
  display: none;
}

.widget.widget_rss .widget-title .rsswidget:first-child:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  content: "\f09e";
  color: #f7943e;
}

.widget.widget_rss ul li:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.widget.widget_rss ul li a.rsswidget {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
}

.widget.widget_rss ul li .rss-date {
  display: block;
  margin-top: 5px;
  font-size: 13px;
  font-size: 0.8125rem;
  opacity: 0.5;
}

.widget.widget_rss ul li .rssSummary {
  margin-top: 7.5px;
  font-size: 14px;
  font-size: 0.875rem;
}

.widget.widget_rss ul li .rssSummary + cite {
  display: block;
  margin-top: 7.5px;
}

.widget.widget_rss ul li cite {
  font-size: 13px;
  font-size: 0.8125rem;
}

.widget.widget_tag_cloud,
.elementor-widget-wp-widget-tag_cloud {
  font-size: 0;
}

.widget.widget_tag_cloud .tagcloud,
.elementor-widget-wp-widget-tag_cloud .tagcloud {
  display: flex;
  flex-wrap: wrap;
  margin: -4px -2px;
}

.widget.widget_tag_cloud .tagcloud a,
.elementor-widget-wp-widget-tag_cloud .tagcloud a {
  margin: 4px 2px;
  line-height: 28px;
  font-size: 14px !important;
  font-size: 0.875rem !important;
}

.widget.widget_tag_cloud .tagcloud a:not(:last-child):after,
.elementor-widget-wp-widget-tag_cloud .tagcloud a:not(:last-child):after {
  margin: 0 4px 0 8px;
}

.no-touch .widget.widget_tag_cloud .tagcloud a:hover,
.no-touch .elementor-widget-wp-widget-tag_cloud .tagcloud a:hover {
  color: var(--link-color-hover);
}

.no-touch .widget.widget_tag_cloud .tagcloud a:hover:after,
.no-touch .elementor-widget-wp-widget-tag_cloud .tagcloud a:hover:after {
  color: #1a1b1a;
}

.widget.cs-widget_cat .cat,
.elementor-widget-wp-widget-loftocean-widget-category .cat {
  width: 100%;
  margin: 0 0 15px;
  overflow: hidden;
}

.widget.cs-widget_cat .cat a,
.elementor-widget-wp-widget-loftocean-widget-category .cat a {
  position: relative;
  display: block;
  width: 100%;
  color: #fff !important;
  background: rgba(0, 0, 0, 0.1);
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: 0s;
}

.widget.cs-widget_cat .cat a .cat-bg,
.elementor-widget-wp-widget-loftocean-widget-category .cat a .cat-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: 0.3s ease;
}

.widget.cs-widget_cat .cat a .cat-meta,
.elementor-widget-wp-widget-loftocean-widget-category .cat a .cat-meta {
  position: relative;
  z-index: 3;
  background: rgba(0, 0, 0, 0.3);
  padding: 40px;
  text-align: center;
}

.widget.cs-widget_cat .cat a .category-name,
.elementor-widget-wp-widget-loftocean-widget-category .cat a .category-name {
  position: relative;
  z-index: 3;
  line-height: 1.5;
}

.no-touch .widget.cs-widget_cat .cat a:hover .cat-bg,
.no-touch
  .elementor-widget-wp-widget-loftocean-widget-category
  .cat
  a:hover
  .cat-bg {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.widget.cs-widget_about p:last-child,
.elementor-widget-wp-widget-loftocean-widget-profile p:last-child {
  margin-bottom: 0;
}

.widget.cs-widget_about .profile-img,
.elementor-widget-wp-widget-loftocean-widget-profile .profile-img {
  position: relative;
  z-index: 3;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
}

.widget.cs-widget_about .subheading,
.elementor-widget-wp-widget-loftocean-widget-profile .subheading {
  display: block;
  margin: 0 0 15px;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-area .widget.cs-widget_about p,
.widget-area .elementor-widget-wp-widget-loftocean-widget-profile p {
  font-size: 16px;
  font-size: 1rem;
}

.widget.cs-widget_about .socialwidget,
.widget.cs-widget_about a.button,
.elementor-widget-wp-widget-loftocean-widget-profile .socialwidget,
.elementor-widget-wp-widget-loftocean-widget-profile a.button {
  margin-top: 20px;
}

.widget.cs-widget_instagram ul,
.elementor-widget-wp-widget-loftocean-widget-instagram ul {
  margin: -5px;
  list-style: none;
}

.widget.cs-widget_instagram ul li,
.elementor-widget-wp-widget-loftocean-widget-instagram ul li {
  float: left;
  width: 33.333333%;
  padding: 5px;
  margin: 0;
  border: none;
}

.widget.cs-widget_instagram ul li a,
.elementor-widget-wp-widget-loftocean-widget-instagram ul li a {
  position: relative;
  display: block;
  overflow: hidden;
  background: #000;
}

.no-touch .widget.cs-widget_instagram ul li a:hover .feed-bg,
.no-touch
  .elementor-widget-wp-widget-loftocean-widget-instagram
  ul
  li
  a:hover
  .feed-bg {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
  opacity: 0.8;
}

.widget.cs-widget_instagram ul li .feed-bg,
.elementor-widget-wp-widget-loftocean-widget-instagram ul li .feed-bg {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.widget.cs-widget_instagram ul li .feed-video,
.elementor-widget-wp-widget-loftocean-widget-instagram ul li .feed-video {
  margin-bottom: 0;
}

.widget.cs-widget_instagram .ins-follow-btn,
.elementor-widget-wp-widget-loftocean-widget-instagram .ins-follow-btn {
  margin: 15px auto 0;
  text-align: center;
}

.widget.cs-widget_instagram .ins-follow-btn a.button,
.elementor-widget-wp-widget-loftocean-widget-instagram
  .ins-follow-btn
  a.button {
  padding: 0 20px;
  line-height: 36px;
}

.widget.cs-widget_instagram.column-4 ul li,
.elementor-widget-wp-widget-loftocean-widget-instagram.column-4 ul li {
  width: 25%;
}

.widget.cs-widget_instagram.column-5 ul li,
.elementor-widget-wp-widget-loftocean-widget-instagram.column-5 ul li {
  width: 20%;
}

.widget.cs-widget_instagram.column-6 ul li,
.elementor-widget-wp-widget-loftocean-widget-instagram.column-6 ul li {
  width: 16.6666%;
}

.widget.cs-widget_instagram.column-7 ul li,
.elementor-widget-wp-widget-loftocean-widget-instagram.column-7 ul li {
  width: 14.285%;
}

.widget.cs-widget_instagram.column-8 ul li,
.elementor-widget-wp-widget-loftocean-widget-instagram.column-8 ul li {
  width: 12.5%;
}

.widget.loftocean-widget_facebook {
  overflow: hidden;
}

.widget.loftocean-widget_facebook > div {
  text-align: center;
}

.widget.cs-widget_posts ul,
.elementor-widget-wp-widget-loftocean-widget-posts ul {
  margin: 0;
  list-style: none;
}

.widget.cs-widget_posts ul li,
.elementor-widget-wp-widget-loftocean-widget-posts ul li {
  position: relative;
  padding: 0;
}

.widget.cs-widget_posts ul li:not(:first-child),
.elementor-widget-wp-widget-loftocean-widget-posts ul li:not(:first-child) {
  margin-top: 28px;
}

.widget.cs-widget_posts .thumbnail,
.widget.cs-widget_posts .post-content,
.elementor-widget-wp-widget-loftocean-widget-posts .thumbnail,
.elementor-widget-wp-widget-loftocean-widget-posts .post-content {
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.widget.cs-widget_posts .thumbnail,
.elementor-widget-wp-widget-loftocean-widget-posts .thumbnail {
  position: relative;
  width: 140px;
  height: 140px;
  margin-right: 15px;
  overflow: hidden;
}

.widget.cs-widget_posts .thumbnail img,
.elementor-widget-wp-widget-loftocean-widget-posts .thumbnail img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.widget.cs-widget_posts .post-content,
.elementor-widget-wp-widget-loftocean-widget-posts .post-content {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

.widget.cs-widget_posts .post-link,
.elementor-widget-wp-widget-loftocean-widget-posts .post-link {
  position: relative;
  z-index: 1;
  display: table;
  width: 100%;
  clear: both;
}

.widget.cs-widget_posts .post-title,
.elementor-widget-wp-widget-loftocean-widget-posts .post-title {
  margin-bottom: 0;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: var(--blog-title-weight);
  letter-spacing: var(--bt-letter-spacing);
  text-transform: var(--bt-text-transform);
  font-style: var(--bt-style);
  transition: color 0.4s;
}

.no-touch .widget.cs-widget_posts .post-link:hover .post-title,
.no-touch
  .elementor-widget-wp-widget-loftocean-widget-posts
  .post-link:hover
  .post-title {
  color: var(--primary-color);
}

.widget.cs-widget_posts .meta-wrap,
.elementor-widget-wp-widget-loftocean-widget-posts .meta-wrap {
  margin-top: 5px;
}

.widget.cs-widget_posts .meta-item,
.elementor-widget-wp-widget-loftocean-widget-posts .meta-item {
  display: inline-block;
  margin-right: 5px;
}

.widget.cs-widget_posts .meta-item:last-child,
.elementor-widget-wp-widget-loftocean-widget-posts .meta-item:last-child {
  margin-right: 0;
}

.widget.cs-widget_posts .meta-item:last-child:after,
.elementor-widget-wp-widget-loftocean-widget-posts .meta-item:last-child:after {
  display: none;
}

.widget.cs-widget_posts.with-post-number ul li,
.elementor-widget-wp-widget-loftocean-widget-posts.with-post-number ul li {
  counter-increment: step-counter;
}

.widget.cs-widget_posts.with-post-number ul li .post-content,
.elementor-widget-wp-widget-loftocean-widget-posts.with-post-number
  ul
  li
  .post-content {
  padding-left: 0;
}

.widget.cs-widget_posts.with-post-number ul li .post-content:before,
.elementor-widget-wp-widget-loftocean-widget-posts.with-post-number
  ul
  li
  .post-content:before {
  display: none;
}

.widget.cs-widget_posts.with-post-number .thumbnail:before,
.widget.cs-widget_posts.with-post-number .thumbnail:after,
.elementor-widget-wp-widget-loftocean-widget-posts.with-post-number
  .thumbnail:before,
.elementor-widget-wp-widget-loftocean-widget-posts.with-post-number
  .thumbnail:after {
  transition: opacity 0.4s;
}

.widget.cs-widget_posts.with-post-number .thumbnail:before,
.elementor-widget-wp-widget-loftocean-widget-posts.with-post-number
  .thumbnail:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background: #000;
  content: "";
  opacity: 0.2;
}

.widget.cs-widget_posts.with-post-number .thumbnail:after,
.elementor-widget-wp-widget-loftocean-widget-posts.with-post-number
  .thumbnail:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
  display: block;
  width: 28px;
  height: 22px;
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 22px;
  content: counter(step-counter);
  pointer-events: none;
}

.no-touch
  .widget.cs-widget_posts.with-post-number
  .post-link:hover
  .thumbnail:before,
.no-touch
  .widget.cs-widget_posts.with-post-number
  .post-link:hover
  .thumbnail:after,
.no-touch
  .elementor-widget-wp-widget-loftocean-widget-posts.with-post-number
  .post-link:hover
  .thumbnail:before,
.no-touch
  .elementor-widget-wp-widget-loftocean-widget-posts.with-post-number
  .post-link:hover
  .thumbnail:after {
  opacity: 0;
}

.widget .social-nav {
  margin: 0;
}

.sidebar .wp-block-latest-posts.wp-block-latest-posts__list {
  margin: 0;
}

.sidebar .wp-block-latest-posts.wp-block-latest-posts__list li {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar .wp-block-latest-posts.wp-block-latest-posts__list li a {
  font-family: var(--heading-font);
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 30px;
}

.sidebar .wp-block-latest-comments li {
  font-size: 14px;
  font-size: 0.875rem;
}

.sidebar .wp-block-latest-comments li:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar .wp-block-latest-comments li .wp-block-latest-comments__comment-link {
  display: block;
  margin-top: 5px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: var(--heading-font);
}

.no-touch
  .sidebar
  .wp-block-latest-comments
  li
  .wp-block-latest-comments__comment-link:hover {
  color: var(--primary-color);
}

.sidebar .widget_block.widget_search .wp-block-search__inside-wrapper {
  position: relative;
}

.sidebar .widget_block.widget_search .wp-block-search__button {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 42px;
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  font-size: 0;
}

.sidebar .widget_block.widget_search .wp-block-search__button:before {
  position: relative;
  z-index: 3;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  font-size: 16px;
  font-size: 1rem;
  line-height: 42px;
  content: "\f002";
}

.sidebar .widget_block.widget_search .wp-block-search__label {
  font-family: var(--widget-title-font);
  font-size: var(--widget-title-size);
  font-weight: var(--widget-title-weight);
  letter-spacing: var(--widget-title-spacing);
  text-transform: var(--widget-title-trans);
  font-style: var(--widget-title-style);
}

.sidebar .wp-block-archives,
.sidebar .wp-block-categories {
  margin: 0;
  list-style: none;
}

.sidebar .wp-block-archives li,
.sidebar .wp-block-categories li {
  position: relative;
}

.sidebar .wp-block-archives li:not(:last-child),
.sidebar .wp-block-categories li:not(:last-child) {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.dark-color .sidebar .wp-block-archives li:not(:last-child),
.dark-color .sidebar .wp-block-categories li:not(:last-child) {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.sidebar .wp-block-archives li a,
.sidebar .wp-block-categories li a {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 30px;
}

.no-touch .sidebar .wp-block-archives li a:hover,
.no-touch .sidebar .wp-block-categories li a:hover {
  color: var(--link-color);
}

.sidebar .wp-block-archives li ul,
.sidebar .wp-block-categories li ul {
  margin-top: 7.5px;
  margin-left: 20px;
  clear: both;
}

.sidebar .wp-block-archives li ul li,
.sidebar .wp-block-categories li ul li {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.widget-area .widget p,
.widget-area .widget .textwidget ul,
.widget-area .widget .textwidget ol {
  margin-bottom: 15px;
  font-size: 14px;
  font-size: 0.875rem;
}

.widget-area .widget.widget_search form.search-form {
  max-width: none;
}

.mc4wp-form {
  max-width: 100%;
}

.mc4wp-form .mc4wp-form-fields > * {
  margin-bottom: 15px;
}

.mc4wp-form .mc4wp-form-fields > *:last-child {
  margin-bottom: 0;
}

.mc4wp-form .text-small {
  font-size: 14px;
  font-size: 0.875rem;
}

.mc4wp-form .agree-to-terms {
  position: relative;
  padding-left: 23px;
}

.mc4wp-form .agree-to-terms input[type="checkbox"] {
  position: absolute;
  top: 3px;
  left: 0;
}

.mc4wp-form a {
  text-decoration: underline;
}

.mc4wp-form .fields-containercart {
  position: relative;
  width: 100%;
}

.mc4wp-form .fields-containercart input[type="email"] {
  border-color: currentColor;
}

.mc4wp-form .fields-containercart button[type="submit"] {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 15px;
  width: auto;
  padding: 0;
  background: none;
  border: none;
  color: inherit;
  font-family: var(--btn-font);
  font-size: 16px;
  font-size: 1rem;
  line-height: 52px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
}

.cs-form-underline .mc4wp-form .fields-containercart button[type="submit"] {
  right: 0;
}

.mc4wp-form .fields-containercart button[type="submit"]:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  content: "\f1d8";
  font-weight: 400;
  margin-left: 12px;
}

.mc4wp-form.cs-form-underline .fields-containercart button[type="submit"] {
  right: 0;
}

.sidebar .widget:not(:first-child),
.elementor-widget-sidebar .widget:not(:first-child) {
  margin-top: 60px;
}

.sidebar .widget:last-child,
.elementor-widget-sidebar .widget:last-child {
  margin-bottom: 0;
}

.site-footer {
  clear: both;
}

.site-footer .widget-area {
  padding: 0 0 50px;
}

.site-footer .widget-area .widget-area-row {
  width: 100%;
  overflow: hidden;
}

.site-footer .widget-area .widget {
  margin-top: 50px;
}

.site-footer .site-footer-bottom {
  padding: 30px 0;
  background: #111;
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  text-align: center;
}

.site-footer .site-footer-bottom a {
  color: inherit;
}

.site-footer .site-footer-bottom .widget.widget_text {
  padding: 10px 0;
}

.site-footer .site-footer-bottom .widget.widget_nav_menu > div {
  font-size: 0;
}

.site-footer .site-footer-bottom .widget.widget_nav_menu ul {
  display: inline-block;
  margin: 10px auto;
}

.site-footer .site-footer-bottom .widget.widget_nav_menu ul li {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
}

.site-footer .site-footer-bottom .widget.widget_nav_menu ul li a {
  display: block;
  padding: 5px 15px;
  font-family: var(--nav-font);
  font-size: var(--fbnav-font-size);
  font-weight: var(--fbnav-font-weight);
  letter-spacing: var(--fbnav-font-letter-spacing);
  text-transform: var(--fbnav-font-transform);
}

.site-footer-instagram .widget.cs-widget_instagram {
  position: relative;
  margin: 0;
  text-align: center;
  overflow: hidden;
}

.site-footer-instagram .widget.cs-widget_instagram .widget-title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 120px;
  height: 120px;
  padding: 0 10px;
  margin-bottom: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  font-family: inherit;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  overflow: hidden;
}

.site-footer-instagram .widget.cs-widget_instagram .widget-title:before {
  display: block;
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  content: "\f16d";
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 5px;
}

.site-footer-instagram .widget.cs-widget_instagram ul li {
  padding: 0;
}

.site-footer-instagram .widget.cs-widget_instagram.column-4 ul li {
  width: 25%;
}

.site-footer-instagram .widget.cs-widget_instagram.column-5 ul li {
  width: 20%;
}

.site-footer-instagram .widget.cs-widget_instagram.column-6 ul li {
  width: 16.666%;
}

.site-footer-instagram .widget.cs-widget_instagram.column-7 ul li {
  width: 14.28%;
}

.site-footer-instagram .widget.cs-widget_instagram.column-8 ul li {
  width: 12.5%;
}

.to-top {
  --to-top-size: 46px;
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 1000;
  display: block;
  width: var(--to-top-size);
  height: var(--to-top-size);
  border-radius: 0;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  text-align: center;
  line-height: var(--to-top-size);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 0.4s, transform 0.4s, background 0.4s, color 0.4s;
  box-sizing: content-box;
}

.to-top:before {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\32";
  font-size: 24px;
  font-size: 1.5rem;
  line-height: var(--to-top-size);
}

.to-top.show {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.no-touch .to-top.show:hover {
  background: var(--primary-color);
  color: #fff;
}

.cs-popup-box {
  position: fixed;
  z-index: 100002;
  top: 0;
  left: 0;
  display: flex;
  padding: 0;
  max-width: 100%;
  background-color: white;
  overflow: hidden;
  transition: 0.4s;
  visibility: hidden;
  transition: visibility 0s 0.35s, opacity 0.3s 0s;
  opacity: 0;
}

.cs-popup-box.cs-popup-fullsize {
  width: 100%;
  height: 100%;
}

.cs-popup-box:not(.cs-popup-fullsize) {
  width: var(--popup-width);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cs-popup-box.show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.cs-popup-box .screen-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.cs-popup-box .close-button {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}

.cs-popup-box.cs-popup-fullsize .close-button {
  top: 20px;
  right: 20px;
}

.cs-popup-box .containercart {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  padding: 0;
  max-width: 100%;
}

.cs-popup-box .containercart > * {
  width: 100%;
}

.cs-popup-box .containercart > .elementor {
  max-height: 100vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.cs-popup-box .containercart > .elementor::-webkit-scrollbar {
  display: none;
}

.cs-cookies-popup {
  position: fixed;
  z-index: 1003;
  left: 50%;
  bottom: 40px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  align-items: center;
  width: auto;
  height: auto;
  background: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 32px 0px;
  color: #1a1b1a;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2;
  overflow: hidden;
  transition: 0.4s;
  visibility: hidden;
  transition: visibility 0s 0.35s, opacity 0.3s 0s;
  opacity: 0;
}

.cs-cookies-popup.show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.cs-cookies-popup .containercart {
  padding: 10px 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.cs-cookies-popup .cookies-msg a {
  font-weight: 600;
  color: var(--link-color);
}

.cs-cookies-popup .cookies-buttons {
  margin-left: 10px;
}

.search-screen {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  display: block;
  width: 100vw;
  height: 100%;
  padding: 0;
  background: inherit;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

@media screen and (min-width: 783px) {
  .logged-in.admin-bar .search-screen {
    top: 32px;
    height: calc(100% - 32px);
  }
}

.search-screen.show {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.search-screen .containercart {
  width: 100vw;
  height: 100%;
  padding: 0;
}

.search-screen .close-button {
  position: absolute;
  top: 80px;
  right: 30px;
  background: none;
  color: inherit;
}

.search-screen .close-button:before,
.search-screen .close-button:after {
  width: 38px;
  height: 2px;
}

.search-screen .search-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  max-width: 660px;
}

.search-screen .search-form:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
  left: 15px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  font-size: 16px;
  font-size: 1rem;
  line-height: 40px;
  content: "\f002";
}

.search-screen .search-form {
  max-width: 100%;
}

.search-screen .search-form input[type="search"] {
  padding: 0 0 0 50px;
  height: 50px;
  border: none;
  border-bottom: 2px solid;
  border-radius: 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 50px;
}

.search-screen button.search-submit {
  display: none;
}

@media screen and (min-width: 1024px) {
  .search-screen .close-button {
    right: 80px;
  }

  .search-screen .search-form:before {
    left: 25px;
    font-size: 20px;
    font-size: 1.25rem;
  }

  .search-screen .search-form input[type="search"] {
    padding: 0 0 0 80px;
    height: 70px;
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 70px;
  }
}

@media screen and (min-width: 1200px) {
  .sidebar {
    padding-bottom: 100px;
  }

  .site-footer .site-footer-bottom:not(.column-single) .containercart {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .site-footer
    .site-footer-bottom:not(.column-single)
    .containercart
    > .widget {
    flex: 1;
    flex-grow: 1;
  }

  .site-footer
    .site-footer-bottom:not(.column-single)
    .containercart
    > .widget:first-child:not(:only-child) {
    text-align: left;
  }

  .site-footer
    .site-footer-bottom:not(.column-single)
    .containercart
    > .widget:first-child:not(:only-child)
    ul.menu {
    margin-left: -15px;
  }

  .site-footer
    .site-footer-bottom:not(.column-single)
    .containercart
    > .widget:last-child:not(:only-child) {
    text-align: right;
  }

  .site-footer
    .site-footer-bottom:not(.column-single)
    .containercart
    > .widget:last-child:not(:only-child)
    ul.menu {
    margin-right: -15px;
  }
}

@media screen and (max-width: 600px) {
  .site-footer-instagram .widget.cs-widget_instagram.column-4 ul li {
    width: 50%;
  }

  .site-footer-instagram .widget.cs-widget_instagram.column-5 ul li {
    width: 50%;
  }

  .site-footer-instagram .widget.cs-widget_instagram.column-5 ul li:last-child {
    display: none;
  }

  .site-footer-instagram .widget.cs-widget_instagram.column-6 ul li {
    width: 33.3333%;
  }

  .site-footer-instagram .widget.cs-widget_instagram.column-7 ul li {
    width: 33.3333%;
  }

  .site-footer-instagram .widget.cs-widget_instagram.column-7 ul li:last-child {
    display: none;
  }

  .site-footer-instagram .widget.cs-widget_instagram.column-8 ul li {
    width: 25%;
  }

  .mc4wp-form .fields-containercart input[type="email"] {
    padding-right: 120px;
  }

  .cs-cookies-popup {
    width: calc(100% - 20px);
    bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .site-footer .site-footer-bottom .widget.widget_nav_menu ul li a {
    padding-right: 8px;
    padding-left: 8px;
  }
}

::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.5;
}

.placeholder-normal ::-webkit-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  color: currentColor;
  opacity: 0.5;
}

.placeholder-normal :-moz-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.5;
}

.placeholder-normal :-ms-input-placeholder {
  opacity: 1;
}

form * {
  outline: 0;
}

form ul {
  position: relative;
  margin: 0 auto;
  list-style: none;
}

form ul > li > label {
  display: block;
  margin-bottom: 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 2;
}

form li fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

form button {
  cursor: pointer;
}

/* input[type="text"], input[type="email"], input[type="url"], input[type="file"], input[type="password"], input[type="number"], input[type="date"], input[type="search"], input[type="tel"], textarea, select {
    height: 52px;
    padding: 5px var(--form-side-padding);
    background: none;
    border-color: var(--form-bd);
    border-style: solid;
    border-width: var(--form-bd-width);
    border-radius: var(--form-radius);
    color: #1a1b1a;
    font-size: 16px;
    font-size: 1rem;
    line-height: 2;
    font-weight: 400;
    transition: 0.3s;
    -webkit-appearance: none;
} */

/* input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="file"]:focus, input[type="password"]:focus, input[type="number"]:focus, input[type="date"]:focus, input[type="search"]:focus, input[type="tel"]:focus, textarea:focus, select:focus {
    outline: 0;
    border-color: var(--form-bd-focus);
} */

/* .cs-form-underline input[type="text"], .cs-form-underline input[type="email"], .cs-form-underline input[type="url"], .cs-form-underline input[type="file"], .cs-form-underline input[type="password"], .cs-form-underline input[type="number"], .cs-form-underline input[type="date"], .cs-form-underline input[type="search"], .cs-form-underline input[type="tel"], .cs-form-underline textarea, .cs-form-underline select {
    border-top: none;
    border-right: none;
    border-left: none;
} */

/* .cs-form-underline .cs-form-square input[type="text"], .cs-form-underline .cs-form-rounded input[type="text"], .cs-form-underline .cs-form-pill input[type="text"], .cs-form-underline .cs-form-square input[type="email"], .cs-form-underline .cs-form-rounded input[type="email"], .cs-form-underline .cs-form-pill input[type="email"], .cs-form-underline .cs-form-square input[type="url"], .cs-form-underline .cs-form-rounded input[type="url"], .cs-form-underline .cs-form-pill input[type="url"], .cs-form-underline .cs-form-square input[type="file"], .cs-form-underline .cs-form-rounded input[type="file"], .cs-form-underline .cs-form-pill input[type="file"], .cs-form-underline .cs-form-square input[type="password"], .cs-form-underline .cs-form-rounded input[type="password"], .cs-form-underline .cs-form-pill input[type="password"], .cs-form-underline .cs-form-square input[type="number"], .cs-form-underline .cs-form-rounded input[type="number"], .cs-form-underline .cs-form-pill input[type="number"], .cs-form-underline .cs-form-square input[type="date"], .cs-form-underline .cs-form-rounded input[type="date"], .cs-form-underline .cs-form-pill input[type="date"], .cs-form-underline .cs-form-square input[type="search"], .cs-form-underline .cs-form-rounded input[type="search"], .cs-form-underline .cs-form-pill input[type="search"], .cs-form-underline .cs-form-square input[type="tel"], .cs-form-underline .cs-form-rounded input[type="tel"], .cs-form-underline .cs-form-pill input[type="tel"], .cs-form-underline .cs-form-square textarea, .cs-form-underline .cs-form-rounded textarea, .cs-form-underline .cs-form-pill textarea, .cs-form-underline .cs-form-square select, .cs-form-underline .cs-form-rounded select, .cs-form-underline .cs-form-pill select {
    border-color: var(--form-bd);
    border-style: solid;
    border-width: var(--form-bd-width);
} */

/* input[type="text"], input[type="email"], input[type="url"], input[type="file"], input[type="password"], input[type="number"], input[type="date"], input[type="search"], input[type="tel"], textarea {
    width: 100%;
} */

.cs-form-underline {
  --form-radius: 0px;
  --form-side-padding: 0px;
}

.cs-form-square {
  --form-radius: 0px;
  --form-side-padding: 15px;
}

.cs-form-rounded {
  --form-radius: 5px;
  --form-side-padding: 15px;
}

.cs-form-pill {
  --form-radius: 50px;
  --form-side-padding: 15px;
}

textarea {
  display: block;
  min-height: 120px;
  resize: vertical;
}

/* input[type="submit"] {
    position: relative;
    z-index: 9;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 48px;
    height: var(--btn-height);
    background: var(--btn-bg);
    border: none;
    border-radius: var(--btn-radius);
    color: var(--btn-color);
    font-family: var(--btn-font);
    font-size: var(--btn-font-size);
    font-weight: var(--btn-font-weight);
    letter-spacing: var(--btn-letter-spacing);
    text-transform: var(--btn-text-transform);
    line-height: 1;
    text-align: center;
    transition: 0.4s;
    cursor: pointer;
} */

.no-touch input[type="submit"]:not(.cs-btn-underline):hover {
  background: #ab916c;
  color: #fff;
  border-color: #ab916c;
}

select {
  -webkit-appearance: auto;
}

form.post-password-form label {
  position: relative;
  z-index: 9;
  display: inline-block;
  width: 280px;
  height: 52px;
  padding-left: 10px;
  color: #1a1b1a;
  font-family: var(--heading-font);
  font-size: 16px;
  font-size: 1rem;
  line-height: 52px;
  text-align: left;
  vertical-align: bottom;
}

form.post-password-form label input[type="password"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 0 0 0 80px;
}

form.post-password-form input[type="submit"] {
  width: 130px;
  margin: 8px 0 0 0;
  padding: 0;
}

.search-form {
  position: relative;
  max-width: 600px;
}

.search-form input[type="search"] {
  padding-right: 60px;
  height: 50px;
  font-size: 16px;
  font-size: 1rem;
}

.search-form .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 50px;
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
}

.search-form .search-submit:before {
  position: relative;
  z-index: 3;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  font-size: 16px;
  font-size: 1rem;
  line-height: 50px;
  content: "\f002";
}

.cs-form-label {
  font-family: var(--heading-font);
  font-size: 16px;
  font-size: 1rem;
}

.light-color select option {
  background: #fff;
  color: #000;
}

.dark-color select option {
  background: #000;
  color: #fff;
}

/* .bbp-search-form input[type="text"] {
    display: inline-block;
    width: auto;
} */

#bbpress-forums
  div.bbp-the-content-wrapper
  .quicktags-toolbar
  input[type="button"] {
  letter-spacing: 0;
}

.wpcf7-form select {
  width: 100%;
}

.wpcf7-form .wpcf7-spinner {
  position: absolute;
}

.dark-color .wpcf7-form ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.wpcf7-form p {
  margin-bottom: 20px;
}

.wpcf7-form input[type="submit"] {
  width: 100%;
}

.select-wrap {
  position: relative;
}

.select-wrap:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\33";
  font-size: 16px;
  position: absolute;
  right: 5px;
  top: 0;
  line-height: 42px;
}

@media screen and (min-width: 600px) {
  form.post-password-form input[type="submit"] {
    margin: 0 0 0 8px;
  }

  .form-submit input[type="submit"] {
    padding: 0 45px;
  }
}

@media screen and (min-width: 768px) {
  .select-wrap.col-1-2:after,
  .select-wrap.col-1-3:after {
    right: 20px;
  }
}

.site .avatar {
  border-radius: 50%;
}

.entry-content .wp-smiley,
.comment-content .wp-smiley,
.textwidget .wp-smiley {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  border: none;
}

.comment-content a img,
.textwidget a img {
  display: block;
}

embed,
iframe,
object,
video {
  /* margin-bottom: 30px; */
  max-width: 100%;
  border: none;
  vertical-align: middle;
}

p > embed,
p > iframe,
p > object,
p > video {
  margin-bottom: 0;
}

.fluid-width-video-wrapper {
  margin-bottom: 30px;
}

.fluid-width-video-wrapper iframe {
  margin-bottom: 0;
}

.wp-block-embed-twitter iframe {
  margin-bottom: 0;
}

.wp-video-shortcode {
  margin-bottom: 30px;
}

#page .wp-playlist {
  margin: 0 0 30px;
  padding-bottom: 0;
}

#page .wp-playlist .wp-playlist-tracks {
  margin-top: 0;
}

#page .wp-playlist a {
  border-bottom: none;
  box-shadow: none !important;
}

.wp-playlist-item .wp-playlist-caption {
  padding: 15px 0;
  margin-left: 0;
  color: inherit !important;
}

.wp-playlist-item .wp-playlist-item-length {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mejs-containercart a {
  padding-bottom: 0;
  border-bottom: none;
  box-shadow: none !important;
}

.wp-caption {
  margin: 30px 0;
  max-width: 100%;
  clear: both;
}

.wp-caption.aligncenter {
  text-align: center;
}

.wp-caption-text,
figcaption {
  display: block;
  padding: 14px 0;
  text-align: center;
  line-height: 1.5;
  font-weight: 400;
  font-size: smaller;
  color: var(--text-lighter);
}

.entry-content p > img.alignnone {
  margin-top: 30px;
  margin-bottom: 30px;
}

.entry-content p > img.alignnone:first-child {
  margin-top: 0;
}

.entry-content p > img.alignnone:last-child {
  margin-bottom: 0;
}

.blocks-gallery-grid + .blocks-gallery-caption {
  padding-top: 0;
}

.blocks-gallery-grid .blocks-gallery-item figcaption {
  opacity: 1;
  font-family: var(--body-font);
  font-size: 14px;
  font-size: 0.875rem;
  font-style: normal;
}

img[class*="wp-image-"] {
  display: block;
  margin-left: 0;
}

img[class*="wp-image-"].aligncenter {
  margin-right: auto;
  margin-left: auto;
}

img[class*="wp-image-"].alignright {
  margin-right: 0;
  margin-left: 30px;
}

.gallery {
  margin: 0 -2px 30px;
}

.gallery .gallery-item {
  float: left;
  display: inline-block;
  width: 100%;
  max-width: 33.33%;
  text-align: center;
  vertical-align: top;
}

.gallery .gallery-item .gallery-icon {
  font-size: 0;
  padding: 0 2px 4px;
}

.gallery .gallery-item .gallery-icon a {
  transition: 0s;
}

.gallery .gallery-item .gallery-icon img {
  margin: 0 auto;
}

.gallery .gallery-item .gallery-caption {
  padding: 6px 10px 20px;
}

.gallery.gallery-columns-1 .gallery-item {
  max-width: 100%;
}

.gallery.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery.gallery-columns-1 .gallery-item,
.gallery.gallery-columns-2 .gallery-item:nth-child(2n + 1),
.gallery.gallery-columns-3 .gallery-item:nth-child(3n + 1),
.gallery.gallery-columns-4 .gallery-item:nth-child(4n + 1),
.gallery.gallery-columns-5 .gallery-item:nth-child(5n + 1),
.gallery.gallery-columns-6 .gallery-item:nth-child(6n + 1),
.gallery.gallery-columns-7 .gallery-item:nth-child(7n + 1),
.gallery.gallery-columns-8 .gallery-item:nth-child(8n + 1),
.gallery.gallery-columns-9 .gallery-item:nth-child(9n + 1) {
  clear: left;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

.entry-content .twitter-tweet.twitter-tweet-rendered {
  margin-left: 0;
}

@media screen and (max-width: 599px) {
  .gallery:not(.gallery-columns-1):not(.gallery-columns-2)
    .gallery-item
    .gallery-caption {
    display: none;
  }
}

.entry-content *[class*="wp-block-"] {
  margin-bottom: 30px;
}

.entry-content *[class*="wp-block-"] *[class*="wp-block-"] {
  margin-bottom: 0;
}

.entry-content *[class*="wp-block-"] .wp-block-heading:not(:last-child) {
  margin-bottom: 30px;
}

.entry-content .wp-block-group__inner-containercart > *[class*="wp-block-"],
.entry-content .wp-block-column > *[class*="wp-block-"] {
  margin-bottom: 30px;
}

.entry-content .small-margin h1,
.entry-content .small-margin h2,
.entry-content .small-margin h3,
.entry-content .small-margin h4,
.entry-content .small-margin h5,
.entry-content .small-margin h6 {
  margin-bottom: 7.5px;
}

.entry-content .small-margin h1:last-child,
.entry-content .small-margin h2:last-child,
.entry-content .small-margin h3:last-child,
.entry-content .small-margin h4:last-child,
.entry-content .small-margin h5:last-child,
.entry-content .small-margin h6:last-child {
  margin-bottom: 0;
}

.entry-content .small-margin p {
  margin-bottom: 15px;
}

.entry-content .small-margin p:last-child {
  margin-bottom: 0;
}

.entry-content h1.small-margin,
.entry-content h2.small-margin,
.entry-content h3.small-margin,
.entry-content h4.small-margin,
.entry-content h5.small-margin,
.entry-content h6.small-margin {
  margin-bottom: 7.5px;
}

.entry-content p.small-margin {
  margin-bottom: 15px;
}

.light-color .light-grey[class*="wp-block-"] {
  background-color: #fafafa;
}

.dark-color .light-grey[class*="wp-block-"] {
  background-color: #191919;
}

.dark-color .light-grey[class*="wp-block-"] .wp-block-social-link {
  color: #fff !important;
}

.alignleft[class*="wp-block-"] {
  margin-right: 30px;
}

.alignright[class*="wp-block-"] {
  margin-left: 30px;
}

.aligncenter[class*="wp-block-"] {
  clear: both;
}

.entry-content .wp-block-spacer {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.entry-content .wp-block-spacer + * {
  margin-top: 0 !important;
}

.wp-block-media-text .wp-block-media-text__content {
  width: 100%;
}

.wp-block-media-text .wp-block-media-text__content > * {
  margin-bottom: 15px;
}

.wp-block-media-text .wp-block-media-text__content > *:first-child {
  margin-top: 15px;
}

.wp-block-media-text.has-background .wp-block-media-text__content {
  padding-top: 8%;
  padding-bottom: 8%;
}

.wp-embed-responsive
  .wp-block-embed[class*="wp-embed-aspect-"]
  .wp-block-embed__wrapper:before {
  display: none;
}

.no-touch .wp-block-gallery a:hover,
.no-touch .wp-block-button a:hover {
  text-decoration: none;
}

.wp-block-image .alignleft {
  margin-right: 30px;
  margin-bottom: 30px;
}

.wp-block-image .alignright {
  margin-left: 30px;
  margin-bottom: 30px;
}

.wp-block-image.alignnone {
  display: inline-block;
  margin-top: 0 !important;
  width: auto;
}

.wp-block-image img {
  margin: 0;
}

.wp-block-image img[class*="wp-image-"] {
  display: block;
}

.wp-block-image figcaption {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.entry-content .has-small-font-size {
  font-size: 0.8125em;
}

.entry-content .has-normal-font-size,
.entry-content .has-regular-font-size {
  font-size: 1em;
}

.entry-content .has-medium-font-size {
  font-size: 1.25em;
}

.entry-content .has-large-font-size {
  font-size: 2.25em;
}

.entry-content .has-huge-font-size,
.entry-content .has-larger-font-size {
  font-size: 2.625em;
}

.light-color .wp-block-button .wp-block-button__link:not(.has-background) {
  background: #111;
}

.light-color .wp-block-button .wp-block-button__link:not(.has-text-color) {
  color: #fff;
}

.dark-color .wp-block-button .wp-block-button__link:not(.has-background) {
  background: #fff;
}

.dark-color .wp-block-button .wp-block-button__link:not(.has-text-color) {
  color: #111;
}

.wp-block-button.is-style-outline {
  background: none;
  color: inherit;
}

.wp-block-button.is-style-outline .wp-block-button__link:not(.has-background) {
  background: none;
}

.wp-block-button.is-style-outline .wp-block-button__link:not(.has-text-color) {
  color: inherit;
}

.no-touch .wp-block-button .wp-block-button__link:hover {
  opacity: 0.7;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.wp-block-button.alignleft,
.wp-block-button.alignright {
  margin-top: 8px;
}

.wp-block-buttons > *:not(:last-child) {
  margin-bottom: 0.5em !important;
}

.wp-block-button__link {
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
}

.wp-block-file * + .wp-block-file__button {
  margin-right: 10px;
}

.wp-block-file .wp-block-file__button {
  padding: 10px 30px;
  background: var(--btn-bg);
  border-radius: var(--btn-radius);
  color: var(--btn-color);
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
}

.no-touch .wp-block-file .wp-block-file__button:hover {
  box-shadow: none;
  opacity: 0.7;
}

.wp-block-cover,
.wp-block-cover-image {
  color: #fff;
  padding: 50px;
}

.wp-block-cover .wp-block-cover-image-text a,
.wp-block-cover .wp-block-cover-text a,
.wp-block-cover .wp-block-cover__inner-containercart a,
.wp-block-cover-image .wp-block-cover-image-text a,
.wp-block-cover-image .wp-block-cover-text a,
.wp-block-cover-image .wp-block-cover__inner-containercart a {
  color: inherit;
}

.no-touch .wp-block-cover .wp-block-cover-image-text a:hover,
.no-touch .wp-block-cover .wp-block-cover-text a:hover,
.no-touch .wp-block-cover .wp-block-cover__inner-containercart a:hover,
.no-touch .wp-block-cover-image .wp-block-cover-image-text a:hover,
.no-touch .wp-block-cover-image .wp-block-cover-text a:hover,
.no-touch .wp-block-cover-image .wp-block-cover__inner-containercart a:hover {
  color: #fff;
  text-decoration-color: #fff;
}

.wp-block-cover .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text,
.wp-block-cover .wp-block-cover__inner-containercart,
.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover-image .wp-block-cover-text,
.wp-block-cover-image .wp-block-cover__inner-containercart {
  width: 100%;
  max-width: 800px;
  line-height: 1.25;
}

.wp-block-cover .wp-block-cover__inner-containercart > *:last-child,
.wp-block-cover-image .wp-block-cover__inner-containercart > *:last-child {
  margin-bottom: 0;
}

.wp-block-cover h1,
.wp-block-cover-image h1 {
  margin-top: 0;
}

.wp-block-cover * + h1,
.wp-block-cover * + h2,
.wp-block-cover * + h3,
.wp-block-cover * + h4,
.wp-block-cover * + h5,
.wp-block-cover * + h6,
.wp-block-cover-image * + h1,
.wp-block-cover-image * + h2,
.wp-block-cover-image * + h3,
.wp-block-cover-image * + h4,
.wp-block-cover-image * + h5,
.wp-block-cover-image * + h6 {
  margin-top: 0;
}

.wp-block-cover form,
.wp-block-cover-image form {
  color: inherit;
}

.wp-block-column .wp-block-cover {
  height: auto;
}

.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  margin-top: 8px;
}

.wp-block-group.has-background {
  padding: 50px 20px;
}

.wp-block-group.has-background.alignfull + .wp-block-cover.alignfull,
.wp-block-group.has-background.alignfull
  + .wp-block-group.has-background.alignfull,
.wp-block-cover.alignfull + .wp-block-group.has-background.alignfull {
  margin-top: -34px;
}

.wp-block-group.alignfull {
  padding: 50px 20px;
}

.entry-content .wp-block-group.light-color a {
  color: #000;
}

.entry-content .wp-block-group.dark-color a {
  color: #fff;
}

.entry-content .wp-block-group.has-text-color a {
  color: inherit;
}

.entry-content .wp-block-group__inner-containercart > *:last-child {
  margin-bottom: 0;
}

.wp-block-verse {
  padding: 30px;
  background: #f7f7f7;
  border: 1px solid #eee;
  color: #000;
}

.wp-block-quote:not(:first-child) {
  margin-top: 50px;
}

.wp-block-quote:not(:last-child) {
  margin-bottom: 50px;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
  padding: 0 50px;
  margin-right: auto;
  margin-left: auto;
}

.wp-block-quote.is-large:not(.is-style-plain),
.wp-block-quote.is-style-large:not(.is-style-plain) {
  padding: 0 50px;
}

.wp-block-quote.is-large:not(.is-style-plain):not(:last-child),
.wp-block-quote.is-style-large:not(.is-style-plain):not(:last-child) {
  margin-bottom: 50px;
}

.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
  font-size: 24px;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.25;
}

.wp-block-quote.is-large cite,
.wp-block-quote.is-large footer,
.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer {
  text-align: left;
  font-size: 16px;
  font-size: 1rem;
}

.wp-block-quote.is-large.has-text-align-right cite,
.wp-block-quote.is-large.has-text-align-right footer,
.wp-block-quote.is-style-large.has-text-align-right cite,
.wp-block-quote.is-style-large.has-text-align-right footer {
  text-align: right;
}

.wp-block-quote.is-large.has-text-align-center cite,
.wp-block-quote.is-large.has-text-align-center footer,
.wp-block-quote.is-style-large.has-text-align-center cite,
.wp-block-quote.is-style-large.has-text-align-center footer {
  text-align: center;
}

.wp-block-quote.is-large:not(.has-text-align-center):before,
.wp-block-quote.is-style-large:not(.has-text-align-center):before {
  top: 5px;
}

.wp-block-quote.has-text-align-center {
  padding: 0;
}

.entry-content .wp-block-quote.has-text-align-center:before {
  position: relative;
  top: 0;
  width: 100%;
}

.wp-block-pullquote {
  padding: 35px;
  border-top: 4px solid;
  border-bottom: 4px solid;
}

.wp-block-pullquote blockquote {
  margin-bottom: 0;
  padding: 0;
  max-width: 100%;
  color: inherit;
}

.wp-block-pullquote blockquote:before {
  display: none;
}

.wp-block-pullquote.is-style-solid-color blockquote {
  max-width: 100%;
  margin-left: 0;
}

.wp-block-pullquote.is-style-solid-color blockquote p {
  font-size: 28px;
  font-size: 1.75rem;
}

.wp-block-pullquote p {
  font-size: 24px;
  font-size: 1.5rem;
}

.wp-block-pullquote.alignleft,
.wp-block-pullquote.alignright {
  margin-top: 8px;
}

.wp-block-pullquote.alignleft p,
.wp-block-pullquote.alignright p {
  font-size: 20px;
  font-size: 1.25rem;
}

.wp-block-pullquote.alignleft cite,
.wp-block-pullquote.alignright cite {
  font-size: 16px;
  font-size: 1rem;
}

.wp-block-separator {
  margin: 50px auto !important;
  clear: both;
  height: 0;
}

.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  max-width: 100px;
}

.wp-block-video video {
  margin-bottom: 0;
}

.wp-block-embed .fluid-width-video-wrapper {
  margin-bottom: 0;
}

.wp-block-video figcaption,
.wp-block-embed figcaption,
.wp-block-audio figcaption {
  margin: 0;
  padding-bottom: 0;
}

.wp-block-table table {
  margin-bottom: 0;
}

.wp-block-table tbody th,
.wp-block-table tbody td {
  vertical-align: middle;
}

.wp-block-table.is-style-stripes {
  border: none;
  border-bottom: 1px solid #eee;
}

.wp-block-table.is-style-stripes table {
  border: none;
}

.entry-content .wp-block-latest-posts,
.entry-content .wp-block-categories,
.entry-content .wp-block-archives,
.entry-content .wp-block-latest-comments {
  margin-right: 0;
  margin-left: 0;
  list-style: none;
}

.entry-content .wp-block-latest-posts a,
.entry-content .wp-block-categories a,
.entry-content .wp-block-archives a,
.entry-content .wp-block-latest-comments a {
  padding-bottom: 0;
  border-bottom: none;
  box-shadow: none;
  text-decoration: none;
  font-weight: 600;
}

.entry-content .wp-block-latest-posts a:not(:hover),
.entry-content .wp-block-categories a:not(:hover),
.entry-content .wp-block-archives a:not(:hover),
.entry-content .wp-block-latest-comments a:not(:hover) {
  color: inherit;
}

.entry-content .wp-block-latest-posts li,
.entry-content .wp-block-categories li,
.entry-content .wp-block-archives li,
.entry-content .wp-block-latest-comments li {
  padding-right: 0;
  padding-left: 0;
}

.entry-content .wp-block-latest-posts li:before,
.entry-content .wp-block-categories li:before,
.entry-content .wp-block-archives li:before,
.entry-content .wp-block-latest-comments li:before {
  display: none;
}

.wp-block-latest-posts {
  line-height: 1.3;
}

.wp-block-latest-posts li {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}

.wp-block-latest-posts li > a {
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
}

.wp-block-latest-posts .wp-block-latest-posts__post-author,
.wp-block-latest-posts .wp-block-latest-posts__post-date {
  display: inline-block;
  margin-top: 8px;
}

.wp-block-latest-posts
  .wp-block-latest-posts__post-author
  + .wp-block-latest-posts__post-date {
  margin-left: 6px;
}

.entry-content .wp-block-latest-posts li:not(:last-child) {
  margin-bottom: 30px;
}

.entry-content .wp-block-latest-posts.is-grid {
  margin-bottom: 0;
}

.entry-content .wp-block-latest-posts.is-grid li {
  margin-bottom: 30px;
}

.wp-block-latest-posts__post-author,
.wp-block-latest-posts__post-date {
  color: inherit;
  opacity: 0.5;
}

.wp-block-latest-posts__post-excerpt {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.6;
}

.wp-block-latest-posts__post-excerpt a {
  font-size: 14px;
  font-size: 0.875rem;
}

.entry-content .wp-block-latest-comments li {
  font-size: inherit;
}

.entry-content .wp-block-latest-comments li:not(:last-child) {
  margin-bottom: 15px;
}

.entry-content .wp-block-latest-comments__comment {
  line-height: 1.35;
}

.entry-content .wp-block-latest-comments__comment-author {
  font-weight: 600;
}

.entry-content .wp-block-latest-comments__comment-date {
  margin-top: 5px;
  opacity: 0.5;
}

.wp-block-tag-cloud {
  display: flex;
  flex-wrap: wrap;
  margin: -4px -2px;
}

.wp-block-tag-cloud a {
  margin: 4px 2px;
  line-height: 28px;
  font-size: 14px !important;
  font-size: 0.875rem !important;
}

.wp-block-tag-cloud a:not(:last-child):after {
  margin: 0 4px 0 8px;
  content: "/";
}

.no-touch .wp-block-tag-cloud a:hover {
  color: var(--link-color-hover);
}

.no-touch .wp-block-tag-cloud a:hover:after {
  color: #1a1b1a;
}

.entry-content .wp-block-tag-cloud a {
  color: #1a1b1a;
}

.wp-block-calendar table {
  margin-bottom: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 2px;
}

.wp-block-calendar table a {
  box-shadow: none !important;
  text-decoration: none !important;
}

.wp-block-calendar table caption,
.wp-block-calendar table tbody {
  color: inherit;
}

.wp-block-calendar table caption {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
}

.wp-block-calendar table th,
.wp-block-calendar table td {
  padding: 0 5px;
  text-align: center;
  border: none !important;
}

.wp-block-calendar table th {
  background: none;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
}

.wp-block-calendar table td {
  font-size: 16px;
  font-size: 1rem;
}

.wp-block-calendar table tbody td {
  position: relative;
  background: #f7f7f7;
  line-height: 40px;
}

.dark-color .wp-block-calendar table tbody td {
  background: #363636;
}

.wp-block-calendar table tbody td[colspan="2"],
.wp-block-calendar table tbody td[colspan="3"],
.wp-block-calendar table tbody td[colspan="4"],
.wp-block-calendar table tbody td[colspan="5"],
.wp-block-calendar table tbody td[colspan="6"] {
  background: none;
}

.wp-block-calendar table tbody td a {
  font-weight: 800;
}

.wp-block-calendar table tbody td a:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  opacity: 0.1;
  content: "";
}

.wp-block-calendar table tfoot td {
  line-height: 36px;
  font-size: 11px;
  font-size: 0.6875rem;
  text-transform: uppercase;
}

.wp-block-calendar table tfoot td a {
  padding: 2px 10px;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  color: inherit !important;
}

.dark-color .wp-block-calendar table tfoot td a {
  border-color: #393939;
}

.no-touch .wp-block-calendar table tfoot td a:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff !important;
}

.wp-block-calendar .wp-calendar-nav {
  font-size: 0;
}

.wp-block-calendar .wp-calendar-nav .wp-calendar-nav-prev,
.wp-block-calendar .wp-calendar-nav .wp-calendar-nav-next {
  line-height: 36px;
  font-size: 11px;
  font-size: 0.6875rem;
  text-transform: uppercase;
}

.wp-block-calendar .wp-calendar-nav .wp-calendar-nav-prev a,
.wp-block-calendar .wp-calendar-nav .wp-calendar-nav-next a {
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  color: inherit !important;
  box-shadow: none !important;
  text-decoration: none !important;
  line-height: 1.5;
}

.dark-color .wp-block-calendar .wp-calendar-nav .wp-calendar-nav-prev a,
.dark-color .wp-block-calendar .wp-calendar-nav .wp-calendar-nav-next a {
  border-color: #393939;
}

.no-touch .wp-block-calendar .wp-calendar-nav .wp-calendar-nav-prev a:hover,
.no-touch .wp-block-calendar .wp-calendar-nav .wp-calendar-nav-next a:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff !important;
}

.wp-block-calendar .wp-calendar-nav .pad {
  display: inline-block;
  width: 10%;
}

.wp-block-search {
  position: relative;
  margin-bottom: 30px;
}

.wp-block-search .wp-block-search__label {
  font-weight: 700;
}

.wp-block-search input[type="search"] {
  width: auto;
}

.wp-block-search .wp-block-search__button {
  padding: 0 10px;
  background: #000;
  border: none;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  cursor: pointer;
}

.wp-block-search .wp-block-search__button svg path {
  fill: #fff;
}

.wp-block-search.wp-block-search__button-inside
  .wp-block-search__inside-wrapper {
  padding: 4px;
  border: 1px solid #949494;
}

.wp-block-search.wp-block-search__button-inside
  .wp-block-search__inside-wrapper
  .wp-block-search__input {
  border: none;
}

.wp-block-rss {
  margin: 0 0 30px;
  list-style: none;
}

#page .wp-block-rss a:not(:hover) {
  color: inherit;
}

.wp-block-rss .wp-block-rss__item {
  padding-bottom: 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.dark-color .wp-block-rss .wp-block-rss__item {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.wp-block-rss .wp-block-rss__item:not(:last-child) {
  margin-bottom: 20px;
}

.wp-block-rss .wp-block-rss__item-title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 600;
}

.wp-block-rss .wp-block-rss__item-publish-date,
.wp-block-rss .wp-block-rss__item-author {
  display: inline-block;
  margin-top: 8px;
  color: inherit;
  opacity: 0.5;
}

.wp-block-rss
  .wp-block-rss__item-publish-date
  + .wp-block-rss__item-author:before {
  margin-right: 5px;
  content: ".";
}

.wp-block-rss .wp-block-rss__item-excerpt {
  margin-top: 8px;
  font-size: 16px;
  font-size: 1rem;
}

.wp-block-social-links.is-style-logos-only
  .wp-social-link.wp-social-link.wp-social-link {
  margin: 0;
}

.wp-block-social-links:not(.aligncenter):not(.items-justified-center) {
  margin-left: -10px;
}

.wp-social-link a,
.wp-social-link a:active,
.wp-social-link a:hover,
.wp-social-link a:visited,
.wp-social-link svg {
  color: currentColor !important;
}

.wp-block-gallery:not(.alignleft):not(.alignright) {
  clear: both;
}

.entry-content .wp-block-gallery.alignright {
  margin-left: 30px;
}

.entry-content .wp-block-gallery.alignleft {
  margin-right: 30px;
}

.entry-content .wp-block-gallery > li,
.entry-content .wp-block-gallery ul > li {
  padding: 0;
}

.entry-content .wp-block-gallery > li:before,
.entry-content .wp-block-gallery ul > li:before {
  display: none;
}

.entry-content > .wp-block-gallery li:not(:last-child) {
  margin-bottom: 16px;
}

.blocks-gallery-grid .blocks-gallery-image a + figcaption,
.blocks-gallery-grid .blocks-gallery-item a + figcaption,
.wp-block-gallery .blocks-gallery-image a + figcaption,
.wp-block-gallery .blocks-gallery-item a + figcaption {
  pointer-events: none;
}

.entry-content .blocks-gallery-item figcaption a {
  color: inherit;
}

.entry-content .alignfull {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100vw;
  max-width: 100vw;
  margin: 0 0 30px;
  clear: both;
}

.entry-content .alignfull.gallery-slider,
.entry-content .alignfull.gallery-justified {
  width: 100vw;
  max-width: 100vw;
}

.entry-content .alignfull.wp-block-cover.has-parallax {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: -50vw;
}

.entry-content *[class*="wp-block-"] *.alignfull {
  left: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  width: 100%;
  max-width: 100%;
}

.entry-content .wp-block-query .wp-block-post-featured-image {
  margin-bottom: 15px;
}

.entry-content .wp-block-query .wp-block-post-title {
  margin-top: 0;
}

.entry-content .wp-block-query .wp-block-post-date {
  margin-top: 15px;
}

.entry-content .wp-block-query ul {
  margin: 0;
}

p.has-drop-cap:not(:focus):first-letter {
  margin: 11px 10px 0 0;
  font-size: 3.6em;
  font-weight: 400;
}

.has-drop-cap:not(:focus):after {
  display: none;
}

.entry-content .wp-block-column,
.entry-content .wp-block-column:last-child > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 600px) {
  .wp-block-group.alignfull,
  .wp-block-group.has-background {
    padding-right: 30px;
    padding-left: 30px;
  }

  .wp-block-columns.alignfull {
    padding-right: 30px;
    padding-left: 30px;
  }

  .wp-block-column:nth-last-child(2) > *:last-child {
    margin-bottom: 0;
  }

  .entry-content *.alignwide {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: calc(100vw - 60px);
    max-width: 1400px;
  }

  .entry-content *.alignwide.wp-block-cover.has-parallax {
    left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin-left: calc(0px - var(--grid-val) * 1vw);
  }

  .entry-content *[class*="wp-block-"] *.alignwide {
    left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    width: 100%;
    max-width: 100%;
  }

  .entry-content .wp-block-cover.alignfull *.alignwide,
  .entry-content .wp-block-cover.alignwide *.alignwide {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: calc(100vw - 60px);
    max-width: calc(var(--site-width) - 60px);
  }

  .entry-content .wp-block-cover.alignwide *.alignwide {
    max-width: 1008px;
  }
}

@media screen and (min-width: 782px) {
  .wp-block-column > *:last-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 860px) {
  .entry-content .wp-block-cover.has-parallax.alignwide {
    margin-left: calc(0px - (100vw - 860px) / 2);
  }
}

@media screen and (min-width: 1024px) {
  .wp-block-quote.is-large p,
  .wp-block-quote.is-style-large p {
    font-size: 32px;
    font-size: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .site-content[class*="with-sidebar"] .entry-content *.alignfull,
  .site-content[class*="with-sidebar"] .entry-content *.alignwide {
    left: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    width: auto;
    max-width: none;
  }

  .site-content[class*="with-sidebar"]
    .entry-content
    *.alignfull.wp-block-cover.has-parallax,
  .site-content[class*="with-sidebar"]
    .entry-content
    *.alignwide.wp-block-cover.has-parallax {
    margin-left: 0;
  }

  .wp-block-group.has-background {
    padding: 50px;
  }

  .wp-block-group.alignfull {
    padding: 50px 0;
  }

  .wp-block-group.alignfull .wp-block-group__inner-containercart {
    max-width: var(--site-width);
    padding: 0 30px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1260px) {
  .entry-content .wp-block-cover.has-parallax.alignwide {
    margin-left: -170px;
  }
}

@media screen and (max-width: 1024px) {
  .entry-content > .wp-block-group.alignfull:not(.has-background):first-child {
    padding-top: 0;
  }
}

@media screen and (max-width: 599px) {
  .wp-block-pullquote.alignleft,
  .wp-block-pullquote.alignright {
    width: calc(50% - 30px);
  }

  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large {
    padding-right: 0;
  }

  .wp-block-cover,
  .wp-block-cover-image {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 480px) {
  blockquote.tiktok-embed {
    padding: 0;
  }

  blockquote.tiktok-embed:before {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .wp-block-pullquote.alignleft,
  .wp-block-pullquote.alignright {
    width: 100%;
    max-width: 100%;
    margin: 0 0 30px;
  }
}

.cs-room-basic-info {
  margin: var(--item-v-space) 0;
}

.cs-room-basic-info ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.cs-room-basic-info ul li {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding-right: 30px;
  line-height: 28px;
}

.cs-room-basic-info ul li:last-child {
  padding-right: 0;
}

.cs-room-basic-info .csrbi-icon {
  margin-right: 10px;
}

.cs-room-basic-info .csrbi-icon i {
  display: flex;
  font-size: var(--icon-size);
}

.cs-room-basic-info .csrbi-text {
  font-size: 14px;
  font-size: 0.875rem;
  white-space: nowrap;
}

.cs-rooms {
  --post-max-width: 800px;
  --item-v-space: 10px;
  --icon-size: 25px;
  --label-bg: #fff;
  --label-color: #000;
  --label-border: #fff;
}

.cs-rooms .overlay-label {
  top: 20px;
  left: 20px;
  background: var(--label-bg);
  border: 1px solid var(--label-border);
  color: var(--label-color);
  padding: 8px 12px;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.elementor-editor-active .cs-rooms .overlay-label {
  pointer-events: auto;
}

.cs-rooms.layout-standard {
  --icon-size: 28px;
}

.cs-rooms.text-center .cs-room-basic-info ul {
  justify-content: center;
}

.cs-rooms .item-header {
  margin-bottom: var(--item-v-space);
}

.cs-rooms .cs-room-content .item-title {
  margin-bottom: 0;
}

.cs-rooms .cs-room-content .item-subtitle {
  margin-top: var(--item-v-space);
  font-size: 15px;
  font-size: 0.9375rem;
  color: var(--secondary-color);
}

.cs-rooms .cs-room-content .item-subtitle:first-child {
  margin: 0 0 var(--item-v-space);
}

.cs-rooms.layout-overlay .cs-room-content .item-title {
  margin-bottom: 0;
}

.cs-rooms.layout-overlay .cs-room-content .item-subtitle {
  color: inherit;
}

.cs-rooms.layout-overlay .item-header {
  margin: 0;
  transition: opacity 0.4s, transform 0.4s;
}

.cs-rooms.layout-overlay .post .cs-room-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.cs-rooms.layout-overlay .hover-show-content {
  position: absolute !important;
  z-index: 9;
  bottom: 0;
  left: 0;
  padding: 150px 24px 30px;
  opacity: 0;
  transition: opacity 0.4s, transform 0.4s;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

.no-touch .cs-rooms.layout-overlay.with-hover-effect .post:hover .item-header {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
}

.no-touch
  .cs-rooms.layout-overlay.with-hover-effect
  .post:hover
  .hover-show-content {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.cs-rooms.layout-overlay.text-v-middle .hover-show-content {
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
  padding-top: 30px;
}

.no-touch
  .cs-rooms.layout-overlay.text-v-middle.with-hover-effect
  .post:hover
  .hover-show-content {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition-delay: 0.1s;
}

.cs-rooms.layout-overlay.slider-dots-overlap .slick-dots {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  color: #fff;
}

.cs-rooms.layout-overlay.text-v-middle .featured-img:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  content: "";
  background: #000;
  opacity: 0.3;
  pointer-events: none;
  transition: opacity 0.4s;
}

.no-touch
  .cs-rooms.layout-overlay.text-v-middle
  .post:hover
  .featured-img:before {
  opacity: 0;
}

.cs-rooms.layout-overlay.text-v-middle .cs-room-content {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 30px;
}

.cs-rooms.layout-overlay.text-v-middle .cs-room-content:before {
  background: none;
}

.cs-rooms.layout-overlay .cs-room-basic-info ul li {
  margin: 0;
}

.cs-rooms.cs-rooms-carousel.column-1.carousel-center-mode .post {
  max-width: var(--post-max-width);
}

.elementor-column:only-child .cs-rooms.cs-rooms-carousel:hover .slick-slide {
  will-change: transform;
}

.cs-rooms.cs-rooms-carousel .post {
  margin-top: 0;
}

.cs-rooms.cs-rooms-carousel .posts-wrapper.slick-initialized {
  display: block;
}

.cs-rooms.cs-rooms-carousel.column-1.layout-overlay.carousel-center-mode
  .cs-room-content {
  opacity: 1;
  transition: opacity 0.4s 0.5s;
}

.cs-rooms.cs-rooms-carousel.column-1.layout-overlay.carousel-center-mode
  .slick-initialized
  .post:not(.slick-center)
  .cs-room-content {
  opacity: 0;
}

.cs-rooms.cs-rooms-carousel.layout-overlay.slider-dots-overlap.column-1:not(
    .text-v-middle
  )
  .post
  .post-content {
  padding-bottom: 55px;
}

.cs-rooms.cs-rooms-carousel.layout-overlay.slider-dots-overlap.column-1:not(
    .text-v-middle
  )
  .hover-show-content {
  padding-bottom: 60px;
}

.cs-rooms.cs-rooms-carousel.layout-overlay.text-v-middle.slider-arrows-overlap
  .cs-room-content,
.cs-rooms.cs-rooms-carousel.layout-overlay.text-v-middle.slider-arrows-overlap
  .hover-show-content {
  padding-right: 80px;
  padding-left: 80px;
}

.cs-rooms.slider-arrow-top .slider-arrows {
  position: absolute;
  top: -60px;
  right: 0;
}

.cs-rooms.slider-arrow-top .slider-arrows .slick-arrow {
  --arrow-size: 40px;
  position: relative;
  right: auto !important;
  left: auto !important;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin: 0;
  border: 1px solid var(--border-color);
  box-sizing: content-box;
  box-shadow: none;
}

.cs-rooms.slider-arrow-top .slider-arrows .slick-arrow.slick-prev {
  margin-right: 5px;
}

.cs-rooms.hide-slider-dots .slider-dots {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.single-room {
  --sidebar: 440px;
  --sidebar-gap: 70px;
}

.room-top-section {
  position: relative;
  margin-bottom: 50px;
}

.room-top-section .featured-img {
  position: relative;
  width: 100%;
  height: 360px;
}

.room-top-section .featured-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.room-top-section .cs-gallery-view-all {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 9;
}

.room-top-section .cs-gallery-view-all .button {
  --btn-bg: rgba(0, 0, 0, 0.6);
  --btn-color: #fff;
  --btn-height: 38px;
  font-family: var(--body-font);
  padding: 0 15px 0 32px;
  text-transform: uppercase;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
  align-items: center;
}

.room-top-section .cs-gallery-view-all .button:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 12px;
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\e08c";
  font-size: 13px;
  line-height: 1;
}

.room-template-default.single-room
  .site-header.overlap-header
  ~ .site-content
  .room-top-section
  .featured-img:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  content: "";
}

.room-template-default.single-room
  .site-header.overlap-header
  ~ .site-content
  .room-top-section
  .gallery-carousel
  .cs-gallery-item:after {
  position: absolute;
  top: 0;
  left: var(--gallery-gap);
  width: calc(100% - var(--gallery-gap) * 2);
  height: 100%;
  z-index: 3;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  content: "";
}

.room .post-header {
  margin-bottom: 40px;
}

.room .post-header .entry-title {
  font-size: 30px;
  font-size: 1.875rem;
}

.room .post-header .item-subtitle {
  margin-top: 20px;
  font-size: 15px;
  font-size: 0.9375rem;
  color: var(--secondary-color);
}

.room .post-header .cs-room-basic-info {
  --icon-size: 28px;
  margin: 25px 0 0;
}

.room-availability {
  padding-top: 50px;
}

.room-availability .containercart {
  padding: 0;
}

.room-availability .room-availability-title {
  padding-top: 50px;
  margin-bottom: 30px;
  border-top: 1px solid var(--border-color);
  font-size: 24px;
  font-size: 1.5rem;
}

.room-availability .hidden-calendar {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.room-availability .daterangepicker {
  position: relative;
  left: auto !important;
  top: auto !important;
  right: auto !important;
}

.theme-cozystay .room-availability .daterangepicker {
  --dropdown-bg: transparent;
  --dropdown-color: #1a1b1a;
  --dropdown-border: transparent;
  --available-bg: transparent;
  --active-bg: var(--primary-color);
  --active-color: #fff;
  --inrange-bg: #ede6dc;
  --inrange-color: #000;
  --disabled-color: #d8d9db;
  --padding: 0px;
  --td-border: transparent;
  --tooltip-bg: #1a1b1a;
  --tooltip-color: white;
  --no-check-inout: rgba(0, 0, 0, 0.7);
  --hover-highlight: var(--primary-color);
  width: 100%;
}

.theme-cozystay .room-availability .daterangepicker .calendar-table th,
.theme-cozystay .room-availability .daterangepicker .calendar-table td {
  width: 54px;
  max-width: 100%;
  height: 42px;
}

.theme-cozystay .room-availability .daterangepicker td.off {
  background: none;
}

.theme-cozystay .room-availability .daterangepicker td {
  font-size: 13px;
}

.theme-cozystay .room-availability .daterangepicker .drp-calendar {
  width: 50%;
}

.theme-cozystay .room-availability .daterangepicker .drp-calendar.left {
  padding-right: 8px;
  padding-left: 0;
}

.theme-cozystay .room-availability .daterangepicker .drp-calendar.right {
  float: right;
  padding-right: 0;
}

.theme-cozystay .room-availability .daterangepicker.show-calendar .drp-buttons {
  padding: 20px var(--padding);
}

.theme-cozystay .room-availability .daterangepicker .drp-buttons {
  border-top-color: var(--border-color);
}

.theme-cozystay .room-availability .daterangepicker .drp-buttons .btn {
  padding: 8px 14px;
  min-width: 80px;
  background: transparent;
  border: 1px solid #1a1b1a;
  border-radius: 2px;
  color: #1a1b1a;
  font-family: var(--body-font);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.2s;
}

.theme-cozystay .room-availability .daterangepicker .drp-buttons .btn.applyBtn {
  background: #1a1b1a;
  color: white;
}

.theme-cozystay .room-availability .daterangepicker .drp-buttons .btn:hover {
  opacity: 0.8;
}

.theme-cozystay.dark-color .room-availability .daterangepicker {
  --disabled-color: #555;
  --no-check-inout: rgba(255, 255, 255, 0.7);
}

.similar-rooms {
  margin-bottom: 80px;
}

.similar-rooms .similar-rooms-title {
  padding-top: 70px;
  margin-bottom: 50px;
  border-top: 1px solid var(--border-color);
  font-size: 30px;
  font-size: 1.875rem;
}

.cs-room-booking {
  --csrb-bg: transparent;
  --csrb-border-radius: 8px;
  --csrb-box-shadow: 0 5px 30px rgba(80, 57, 24, 0.15);
  --total-divider: rgba(0, 0, 0, 0.1);
  --inner-bd: var(--primary-color);
  position: relative;
}

.dark-color .cs-room-booking {
  --csrb-bg: rgba(255, 255, 255, 0.02);
  --csrb-box-shadow: none;
  --total-divider: rgba(255, 255, 255, 0.1);
}

.cs-room-booking .cs-reservation-form {
  --form-bd: var(--primary-color);
  --field-space: 12px;
}

.cs-room-booking.with-inner-border:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  content: "";
  border: 1px solid var(--inner-bd);
  pointer-events: none;
}

.cs-room-booking .room-booking-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
}

.cs-room-booking .room-booking-title h4 {
  font-size: 30px;
  font-size: 1.875rem;
  text-transform: uppercase;
}

.cs-room-booking .room-booking-title h4 + span {
  text-align: right;
}

.cs-room-booking .room-booking-title .base-price {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
}

.cs-room-booking .room-booking-title .base-price del {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  opacity: 0.5;
}

.cs-room-booking .cs-room-booking-wrap {
  padding: 50px 45px 55px;
  background: var(--csrb-bg);
  border-radius: var(--csrb-border-radius);
  box-shadow: var(--csrb-box-shadow);
  transition: opacity 0.3s;
}

.cs-room-booking .cs-form-group {
  margin-top: 60px;
}

.cs-room-booking .csf-title {
  font-size: 24px;
  font-size: 1.5rem;
}

.cs-room-booking .csf-title + .cs-extra-service {
  margin-top: 10px;
}

.cs-room-booking .cs-form-field.cs-rooms {
  position: relative;
}

.cs-room-booking .cs-form-notice {
  --line-space: 15px;
  position: absolute;
  top: 50%;
  right: -4px;
  -webkit-transform: translate(100%, -50%);
  -ms-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
  margin-top: calc(var(--field-space) / 2);
  padding: 3px 8px;
  width: fit-content;
  z-index: 99;
  background: #1a1b1a;
  color: white;
  border-radius: 2px;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.cs-room-booking .cs-form-notice p {
  margin: 0;
}

.cs-room-booking .cs-form-notice.show {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.cs-room-booking .cs-form-notice:after {
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  left: -2px;
  width: 6px;
  height: 6px;
  background: #1a1b1a;
  content: "";
}

.cs-room-booking .cs-extra-service .field-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cs-room-booking .cs-extra-service .label-checkbox.obligatory {
  cursor: not-allowed;
}

.cs-room-booking .cs-extra-service .label-checkbox.obligatory * {
  pointer-events: none;
}

.cs-room-booking .hidden-check {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.cs-room-booking .hidden-check:checked + .cs-styled-checkbox:after {
  opacity: 1;
}

.cs-room-booking .cs-styled-checkbox {
  position: relative;
  width: 17px;
  height: 17px;
  border: 1px solid var(--form-bd);
  margin-right: 12px;
  pointer-events: none;
}

.cs-room-booking .cs-styled-checkbox:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 8px;
  height: 8px;
  background: var(--form-bd);
  content: "";
  opacity: 0;
}

.cs-room-booking .label-checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

.cs-room-booking .label-checkbox .checkbox-label label {
  cursor: pointer;
}

.cs-room-booking .price-quantity {
  position: relative;
  display: flex;
  align-items: center;
}

.cs-room-booking .price-quantity .cs-form-price {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 35px;
}

.cs-room-booking .price-quantity .cs-form-price:not(:last-child) {
  margin-right: 15px;
}

.cs-room-booking
  .price-quantity
  .cs-form-price.cs-form-price-long
  .cs-form-price-brief:after {
  display: inline-block;
  margin-left: 4px;
  content: "...";
}

.cs-room-booking
  .price-quantity
  .cs-form-price.cs-form-price-long
  .cs-form-price-all {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  padding: 10px;
  width: fit-content;
  border-radius: 4px;
  background: #1a1b1a;
  color: white;
  font-size: 12.5px;
  font-size: 0.78125rem;
  line-height: 1.5;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.3s;
}

.cs-room-booking
  .price-quantity
  .cs-form-price.cs-form-price-long
  .cs-form-price-all:after {
  position: absolute;
  right: 30px;
  bottom: -4px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background: #1a1b1a;
  content: "";
}

.cs-room-booking
  .price-quantity
  .cs-form-price.cs-form-price-long:hover
  .cs-form-price-all {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.cs-room-booking .price-quantity .field-input-wrap {
  width: 65px;
}

.cs-room-booking .price-quantity .field-input-wrap input {
  --field-height: 35px;
  text-align: left;
}

.cs-room-booking .price-quantity .csf-dropdown {
  bottom: -5px;
  padding: 10px 5px;
  min-width: 130px !important;
}

.cs-room-booking .price-quantity .csf-dropdown .cs-form-label {
  display: none;
}

.cs-room-booking .price-quantity .csf-dropdown .csf-dropdown-item {
  justify-content: center;
}

.cs-room-booking .price-quantity .csf-dropdown .cs-quantity .input-text55 {
  font-size: 14px;
  font-size: 0.875rem;
}

.cs-room-booking .cs-form-total-price {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  padding-top: 40px;
  cursor: pointer;
}

.cs-room-booking .cs-form-total-price:before {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  display: block;
  width: calc(100% + 70px);
  height: 1px;
  background: var(--total-divider);
  content: "";
}

.cs-room-booking .cs-form-total-price .price-details:before {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\33";
  font-size: 18px;
  font-size: 1.125rem;
}

.cs-room-booking .cs-form-total-price.toggled-on .price-details:before {
  content: "\32";
}

.cs-room-booking .cs-form-total-price .total-price {
  font-size: 24px;
  font-size: 1.5rem;
}

.cs-room-booking .cs-form-price-details {
  position: relative;
  margin-top: 20px;
  font-size: 15px;
  font-size: 0.9375rem;
}

.cs-room-booking .cs-form-price-details ul {
  display: block;
  margin: 0;
}

.cs-room-booking .cs-form-price-details li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding-top: 8px;
  border-top: 1px dashed var(--total-divider);
}

.cs-room-booking .cs-form-price-details li.cs-form-price-details-total {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
}

.cs-room-booking .cs-form-price-details li.with-tax-info {
  align-items: flex-start;
}

.cs-room-booking .cs-form-price-details li.with-tax-info .csf-pd-value {
  text-align: right;
}

.cs-room-booking .cs-form-price-details li.with-tax-info .includes_tax,
.cs-room-booking .cs-form-price-details li.with-tax-info .excludes_tax {
  display: block;
  font-weight: 400;
  opacity: 0.6;
  font-size: 14px;
  font-size: 0.875rem;
}

.cs-room-booking .cs-form-price-details li.csf-pd-total-base > .csf-pd-label {
  cursor: pointer;
}

.cs-room-booking .cs-form-price-details li .info-indicator {
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid;
  border-radius: 50%;
  font-size: 12px;
  font-size: 0.75rem;
  font-style: italic;
  line-height: 12px;
  text-align: center;
  font-family: serif;
  font-weight: 600;
  opacity: 0.6;
}

.cs-room-booking .cs-form-price-details li .csf-base-price-breakdown {
  --line-space: 15px;
  position: absolute;
  top: -5px;
  left: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  padding: 20px 24px;
  width: 100%;
  z-index: 99;
  background: white;
  color: #1a1b1a;
  border-radius: 8px;
  box-shadow: 0 5px 30px rgba(80, 57, 24, 0.3);
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.cs-room-booking .cs-form-price-details li .csf-base-price-breakdown.show {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.cs-room-booking .cs-form-price-details li .csf-base-price-breakdown:after {
  position: absolute;
  display: none;
  width: 10px;
  height: 10px;
  background: white;
  content: "";
}

.cs-room-booking .cs-form-price-details li .breakdown-title,
.cs-room-booking .cs-form-price-details li .breakdown-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
}

.cs-room-booking .cs-form-price-details li .breakdown-title {
  padding-bottom: var(--line-space);
  margin-bottom: var(--line-space);
  border-bottom: 1px solid var(--border-color);
}

.cs-room-booking .cs-form-price-details li .breakdown-main {
  max-height: 300px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.cs-room-booking .cs-form-price-details li .breakdown-main::-webkit-scrollbar {
  display: none;
}

.cs-room-booking .cs-form-price-details li .breakdown-main li {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 14px;
  font-size: 0.875rem;
}

.cs-room-booking
  .cs-form-price-details
  li
  .breakdown-main
  li:not(:first-child) {
  margin-top: 8px;
}

.cs-room-booking .cs-form-price-details li .breakdown-footer {
  padding-top: var(--line-space);
  margin-top: var(--line-space);
  border-top: 1px solid var(--border-color);
}

.cs-room-booking .cs-form-price-details li del {
  opacity: 0.6;
}

.cs-room-booking .cs-form-price-details .discounted {
  color: red;
}

.cs-room-booking .cs-reservation-form.style-block .cs-form-field.cs-submit {
  padding-top: 35px;
}

.cs-room-booking button[type="submit"] {
  font-size: 18px;
  font-size: 1.125rem;
}

.cs-room-booking .cs-form-error-message,
.cs-room-booking .cs-form-success-message {
  margin-top: 12px;
}

.cs-room-booking .cs-form-error-message p,
.cs-room-booking .cs-form-success-message p {
  margin-bottom: 0;
}

.cs-room-booking .cs-form-error-message {
  color: red;
}

.cs-room-booking .cs-form-success-message {
  color: green;
}

.cs-room-booking .cs-submit .btn-text {
  position: relative;
  display: block;
}

.cs-room-booking .cs-submit .btn-text:after {
  position: absolute;
  top: 1px;
  right: -30px;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid currentColor;
  border-top: 2px solid transparent;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  transition: opacity 0.3s;
  transform-origin: 50% 50%;
  opacity: 0;
  content: "";
}

.cs-room-booking.loading .cs-room-booking-wrap {
  pointer-events: none;
  opacity: 0.5;
}

.cs-room-booking.loading .cs-submit .btn-text:after {
  opacity: 1;
}

.sl-overlay {
  background: #000 !important;
}

.sl-wrapper .sl-close,
.sl-wrapper .sl-navigation button,
.sl-wrapper .sl-counter {
  color: #fff !important;
}

@media screen and (min-width: 768px) {
  .cs-rooms.layout-grid.column-1 .posts-wrapper {
    margin-left: 0;
  }

  .cs-rooms.layout-grid.column-1 .post {
    width: 100%;
    max-width: 100%;
  }

  .cs-rooms.layout-grid.column-1 .post-title {
    font-size: 30px;
    font-size: 1.875rem;
  }

  .cs-rooms.layout-grid.column-1:not(.carousel-center-mode) {
    --post-gap: 0;
  }

  .room-top-section .featured-img {
    height: 480px;
  }

  .cs-rooms.cs-rooms-carousel .slick-arrow.slick-prev {
    left: -30px;
  }

  .elementor-section-full_width
    .cs-rooms.cs-rooms-carousel
    .slick-arrow.slick-prev {
    left: 60px;
  }

  .cs-rooms.cs-rooms-carousel .slick-arrow.slick-next {
    right: -30px;
  }

  .elementor-section-full_width
    .cs-rooms.cs-rooms-carousel
    .slick-arrow.slick-next {
    right: 60px;
  }

  .cs-rooms.cs-rooms-carousel.column-1 .posts-wrapper:not(.slick-initialized) {
    justify-content: center;
    margin-left: calc(0px - var(--post-gap));
  }
}

@media screen and (min-width: 1200px) {
  .cs-rooms.layout-grid.column-1 .post-title {
    font-size: 36px;
    font-size: 2.25rem;
  }

  .cs-rooms.layout-standard .cs-room-basic-info {
    margin: 20px 0;
  }

  .cs-rooms.layout-standard,
  .cs-rooms.layout-list {
    --item-v-space: 20px;
  }

  .cs-rooms.layout-standard .item-excerpt,
  .cs-rooms.layout-list .item-excerpt {
    margin-bottom: var(--item-v-space);
  }

  .elementor-column:only-child
    .cs-rooms.cs-rooms-carousel.column-1.text-v-middle {
    --item-v-space: 20px;
  }

  .room-top-section {
    margin-bottom: 80px;
  }

  .room .post-header {
    margin-bottom: 60px;
  }

  .room .post-header .entry-title {
    font-size: 36px;
    font-size: 2.25rem;
  }

  .room-top-section .featured-img {
    height: 520px;
  }

  .site-header.overlap-header ~ .site-content .room-top-section .featured-img {
    height: 600px;
  }

  .cs-room-booking .cs-form-price-details li .csf-base-price-breakdown {
    top: 20px;
  }

  .cs-room-booking .cs-form-price-details li .csf-base-price-breakdown:after {
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
  }

  .site-content.with-sidebar-right
    .cs-room-booking
    .cs-form-price-details
    li
    .csf-base-price-breakdown {
    left: 0;
    -webkit-transform: translate(-105%, -50%);
    -ms-transform: translate(-105%, -50%);
    transform: translate(-105%, -50%);
  }

  .site-content.with-sidebar-right
    .cs-room-booking
    .cs-form-price-details
    li
    .csf-base-price-breakdown:after {
    right: -4px;
  }

  .site-content.with-sidebar-left
    .cs-room-booking
    .cs-form-price-details
    li
    .csf-base-price-breakdown {
    right: 0;
    left: auto;
    -webkit-transform: translate(105%, -50%);
    -ms-transform: translate(105%, -50%);
    transform: translate(105%, -50%);
  }

  .site-content.with-sidebar-left
    .cs-room-booking
    .cs-form-price-details
    li
    .csf-base-price-breakdown:after {
    right: auto;
    left: -4px;
  }
}

@media screen and (max-width: 1024px) {
  .cs-room-basic-info ul li:not(:last-child) {
    padding-right: 20px;
  }

  .cs-rooms.text-center .cs-room-basic-info ul li {
    padding: 0 10px;
  }

  .cs-rooms {
    --icon-size: 20px;
  }

  .cs-rooms .post-title {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .room-top-section .cs-gallery.gallery-mosaic .cs-gallery-wrap {
    display: block;
  }

  .room-top-section
    .cs-gallery.gallery-mosaic
    .cs-gallery-wrap
    .cs-gallery-item:not(:first-child) {
    display: none;
  }

  .cs-rooms.cs-rooms-carousel.layout-overlay.slider-dots-overlap:not(
      .text-v-middle
    )
    .post
    .post-content {
    padding-bottom: 55px;
  }

  .cs-rooms.cs-rooms-carousel.layout-overlay.slider-dots-overlap:not(
      .text-v-middle
    )
    .hover-show-content {
    padding-bottom: 60px;
  }

  .cs-rooms.cs-rooms-carousel.layout-overlay.column-1.carousel-center-mode
    .slick-initialized
    .post:not(.slick-center)
    .cs-room-content {
    opacity: 1;
  }

  .theme-cozystay .room-availability .daterangepicker .drp-calendar {
    float: none !important;
    width: 100%;
  }

  .theme-cozystay .room-availability .daterangepicker .drp-calendar.left,
  .theme-cozystay .room-availability .daterangepicker .drp-calendar.right {
    padding-right: 8px;
    padding-left: 8px;
  }
}

@media screen and (max-width: 599px) {
  .cs-room-booking .cs-room-booking-wrap {
    padding: 30px;
  }

  .cs-room-booking .room-booking-title {
    font-size: 14px;
    font-size: 0.875rem;
  }

  .cs-room-booking .room-booking-title h4 {
    font-size: 24px;
    font-size: 1.5rem;
  }

  .cs-room-booking .room-booking-title .base-price {
    font-size: 16px;
    font-size: 1rem;
  }

  .cs-room-booking .cs-form-total-price:before {
    width: calc(100% + 60px);
  }

  .cs-room-booking .cs-form-label {
    font-size: 14px;
    font-size: 0.875rem;
  }

  .cs-room-booking .csf-title,
  .cs-room-booking .cs-form-total-price .total-price {
    font-size: 20px;
    font-size: 1.25rem;
  }

  .cs-room-booking .cs-extra-service .field-wrap .cs-styled-checkbox,
  .cs-room-booking
    .cs-extra-service
    .field-wrap
    .cs-form-price:not(:last-child) {
    margin-right: 8px;
  }

  .cs-room-booking .price-quantity .csf-dropdown {
    right: 0;
    left: auto;
  }

  .cs-room-booking .cs-form-notice {
    width: 50px;
  }
}

@media screen and (max-width: 360px) {
  .cs-room-booking .cs-extra-service .field-wrap {
    flex-wrap: wrap;
  }

  .cs-room-booking .cs-extra-service .field-wrap .price-quantity {
    width: 100%;
    padding-left: 29px;
  }

  .cs-room-booking .cs-reservation-form.style-block .form-field-col-1-2 {
    width: 100%;
  }

  .cs-room-booking
    .cs-reservation-form.style-block
    .form-field-col-1-2.cs-adults {
    padding-right: 0;
  }

  .cs-room-booking
    .cs-reservation-form.style-block
    .form-field-col-1-2.cs-children {
    padding-left: 0;
  }
}

.elementor-section.elementor-section-boxed.cs-section-content-fullwidth
  > .elementor-containercart,
.elementor-section.elementor-section-full_width.cs-section-content-fullwidth
  > .elementor-containercart {
  max-width: calc(var(--site-width) + var(--side-padding) * 2);
  padding: 0 var(--side-padding);
}

.elementor-editor-active
  .elementor-edit-area-active
  .elementor-widget-containercart {
  --primary-color: var(--theme-primary-color);
  --secondary-color: var(--theme-secondary-color);
}

.cs-template-wide .site-content .elementor-section-full_width,
.custom_blocks-template-default .elementor-section-full_width,
.site-footer-main .elementor-section-full_width,
.cs-section-content-fullwidth {
  position: relative;
  width: 100vw;
  left: calc(50% - 50vw);
}

.cs-template-wide
  .site-content
  .elementor-section
  .elementor-section-full_width,
.elementor-section .elementor-section-full_width {
  width: 100%;
  left: auto;
}

.cs-template-wide.elementor-page .primary {
  padding-bottom: 0;
}

.single-custom_site_headers .containercart {
  padding: 0;
  max-width: 100vw;
}

.single-custom_site_headers .entry-content a:not(.button) {
  color: inherit;
}

.elementor-align-center {
  margin-right: auto;
  margin-left: auto;
}

.elementor-text-editor > *:last-child,
.elementor-widget-text-editor .elementor-widget-containercart > *:last-child,
.elementor-text-editor p:last-child {
  margin-bottom: 0;
}

.color-primary,
.entry-content .color-primary,
.elementor-section .color-primary {
  color: var(--primary-color);
}

.color-secondary,
.entry-content .color-secondary,
.elementor-section .color-secondary {
  color: var(--secondary-color);
}

.color-black,
.entry-content .color-black,
.elementor-section .color-black {
  color: #000;
}

.color-white,
.entry-content .color-white,
.elementor-section .color-white {
  color: #fff;
}

.cs-elementor-simulator-scheme-switcher {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99;
}

.cs-elementor-simulator-scheme-dark,
.cs-elementor-simulator-scheme-light {
  background: #999;
  color: #000;
  padding: 2px 6px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
}

.cs-elementor-simulator-scheme-dark.active,
.cs-elementor-simulator-scheme-light.active {
  background: #fff;
}

.cs-elementor-simulator-scheme-dark {
  margin-bottom: 2px;
}

.loftocean-library-modal-btn {
  margin-left: 5px;
  background: #000;
  vertical-align: top;
  font-size: 0 !important;
}

.elementor-widget-heading .elementor-heading-title {
  margin-bottom: 0;
}

.elementor-element a:not([class*="button"]):not(:hover) {
  color: inherit;
}

.elementor-editor-active .site-header.overlap-header {
  pointer-events: none;
}

.elementor-editor-active
  .site-header:not(.overlap-header):not(.sticky):not(.is-sticky) {
  z-index: 1;
}

body > div.elementor {
  overflow: hidden;
}

.elementor-widget-cs_logo {
  font-size: 0;
}

.elementor-widget-cs_logo .cs-notice {
  font-size: 16px;
  font-size: 1rem;
  color: #161616;
}

.elementor-widget-cs_logo a {
  display: inline-block;
}

.elementor-widget-cs_logo img {
  display: block;
}

.elementor-widget-cs_button.elementor-align-justify .button {
  width: 100%;
  padding: 0;
}

.elementor-widget-cs_button .cs-btn-circle {
  display: flex;
}

.elementor-widget-cs_button.elementor-align-left .cs-btn-circle {
  margin-right: auto;
  margin-left: 0;
}

.elementor-widget-cs_button.elementor-align-center .cs-btn-circle {
  margin-right: auto;
  margin-left: auto;
}

.elementor-widget-cs_button.elementor-align-right .cs-btn-circle {
  margin-right: 0;
  margin-left: auto;
}

ul.social-nav.text-left,
.text-left ul.social-nav {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

ul.social-nav.text-center,
.text-center ul.social-nav {
  -webkit-justify-content: center;
  justify-content: center;
}

ul.social-nav.text-right,
.text-right ul.social-nav {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.cs-menu {
  --item-padding: 15px;
}

.cs-menu > ul > li > a {
  font-family: var(--nav-font);
}

.cs-menu.text-left .menu {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.cs-menu.text-center .menu {
  -webkit-justify-content: center;
  justify-content: center;
}

.cs-menu.text-right .menu {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.cs-menu .primary-menu > li > a {
  padding: 0 var(--item-padding);
}

.cs-menu .primary-menu > li > a:before {
  left: var(--item-padding);
  width: calc(100% - var(--item-padding) * 2);
}

.cs-menu .primary-menu > li.menu-item-has-children > a:before {
  width: calc(100% - var(--item-padding) * 2 - 20px);
}

.cs-menu .primary-menu > li > .sub-menu {
  left: calc(var(--item-padding) - 30px);
}

.cs-menu.footer-menu .menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cs-menu.footer-menu li a {
  display: block;
  padding: 5px var(--item-padding);
  font-family: var(--nav-font);
  font-size: var(--fbnav-font-size);
  font-weight: var(--fbnav-font-weight);
  letter-spacing: var(--fbnav-font-letter-spacing);
  text-transform: var(--fbnav-font-transform);
}

.cs-menu.cs-menu-inline .menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cs-menu.cs-menu-inline .menu li {
  padding: 5px var(--item-padding);
}

.cs-menu.cs-menu-inline .menu a {
  white-space: nowrap;
}

.cs-menu.cs-menu-inline.text-left .menu li:first-child {
  padding-left: 0;
}

.cs-menu.cs-menu-inline.text-right .menu li:last-child {
  padding-right: 0;
}

.cs-menu.cs-menu-inline .sub-menu {
  position: absolute;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-size: 1rem;
  line-height: 30px;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.cs-menu.cs-menu-inline .sub-menu:before {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  content: "\35";
  padding: 5px 0;
  line-height: 30px;
}

.no-touch .cs-menu.cs-menu-inline li:hover > ul.sub-menu {
  z-index: 99;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.elementor-widget-cs_title .cs-subtitle-wrap {
  position: relative;
  overflow: hidden;
}

.elementor-widget-cs_title .cs-subtitle-wrap + .cs-title {
  margin-top: 20px;
}

.elementor-widget-cs_title .cs-title-separator {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.elementor-widget-cs_title .cs-title-separator span {
  display: block;
  width: 1px;
  height: 45px;
  background: currentColor;
}

.elementor-widget-cs_title .cs-title-text {
  margin-top: 20px;
}

.elementor-widget-cs_title .cs-title-text:first-child {
  margin-top: 0;
}

.elementor-widget-cs_title .cs-title-text p {
  margin-bottom: 20px;
}

.elementor-widget-cs_title .cs-title-text > *:last-child {
  margin-bottom: 0;
}

.elementor-widget-cs_title .cs-title-text + .cs-title-btn {
  margin-top: 30px;
}

.elementor-widget-cs_title .cs-title-btn {
  margin-top: 20px;
}

.elementor-widget-cs_title .cs-title-btn .button.cs-btn-underline {
  margin-bottom: 2px;
}

.cs-title,
.entry-content .cs-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.35;
}

.cs-title small,
.entry-content .cs-title small {
  font-weight: 300;
  font-size: 100%;
}

.cs-subtitle-wrap {
  line-height: 1.2;
}

.cs-title-wrap.text-left {
  margin-right: auto;
}

.cs-title-wrap.text-center {
  margin-right: auto;
  margin-left: auto;
}

.cs-title-wrap.text-right {
  margin-left: auto;
}

h1.cs-title {
  font-size: 48px;
  font-size: 3rem;
}

h2.cs-title {
  font-size: 38px;
  font-size: 2.375rem;
}

h3.cs-title {
  font-size: 32px;
  font-size: 2rem;
}

h4.cs-title {
  font-size: 28px;
  font-size: 1.75rem;
}

h5.cs-title {
  font-size: 24px;
  font-size: 1.5rem;
}

h6.cs-title {
  font-size: 21px;
  font-size: 1.3125rem;
}

.cs-slider .slick-dots {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
}

.cs-slider .cs-slider-wrap > .cs-slider-item {
  opacity: 0;
}

.cs-slider .slick-initialized .cs-slider-item .elementor-section:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  content: "";
  transition: 0s;
}

.cs-slider
  .slick-initialized
  .cs-slider-item.current-item
  .elementor-section:before {
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
  transition: transform 10s linear 0s;
}

.cs-slider
  .slick-initialized
  .cs-slider-item.slick-current
  .elementor-widget
  > *
  > * {
  -webkit-animation: slideFade 0.7s ease-out;
  animation: slideFade 0.7s ease-out;
}

.cs-divider {
  position: relative;
  color: #dfdbdc;
}

.cs-divider .cs-d-line {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 6px;
}

.cs-divider .cs-d-line:before,
.cs-divider .cs-d-line:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  background: currentColor;
  content: "";
}

.cs-divider .cs-d-line:before {
  width: 100%;
  height: 1px;
}

.cs-divider .cs-d-line:after {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.cs-divider .cs-d-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
  width: 33px;
  color: #c8c4c5;
}

.cs-divider .cs-d-icon.icon-start {
  left: 0;
}

.cs-divider .cs-d-icon.icon-end {
  right: 0;
}

.cs-divider .cs-d-icon svg {
  display: block;
}

.cs-divider .cs-d-icon svg > * {
  fill: currentColor;
}

.cs-divider.divider-star .cs-d-icon {
  width: 22px;
}

.cs-divider.divider-star .cs-d-line {
  width: calc(100% - 50px);
}

.cs-divider.divider-grass .cs-d-line {
  width: calc(100% - 20px);
}

.cs-divider.divider-grass .cs-d-icon.icon-start {
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}

.cs-divider.divider-grass .cs-d-icon.icon-end {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

.cs-vertical-divider {
  color: #dfdbdc;
  height: 200px;
}

.cs-vertical-divider > * {
  margin: 0 auto;
}

.cs-vertical-divider .cs-vd-line {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 6px;
  height: 100%;
}

.cs-vertical-divider .cs-vd-line:before,
.cs-vertical-divider .cs-vd-line:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  background: currentColor;
  content: "";
}

.cs-vertical-divider .cs-vd-line:before {
  width: 1px;
  height: 100%;
}

.cs-vertical-divider .cs-vd-line:after {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.cs-vertical-divider .cs-vd-icon {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 3;
  width: 33px;
  color: #c8c4c5;
}

.cs-vertical-divider .cs-vd-icon.icon-start {
  top: 0;
}

.cs-vertical-divider .cs-vd-icon.icon-end {
  bottom: 0;
}

.cs-vertical-divider .cs-vd-icon svg {
  display: block;
}

.cs-vertical-divider .cs-vd-icon svg > * {
  fill: currentColor;
}

.cs-vertical-divider.divider-star .cs-vd-icon {
  width: 22px;
}

.cs-vertical-divider.divider-star .cs-vd-line {
  height: calc(100% - 50px);
}

.cs-vertical-divider.divider-star .cs-vd-icon.icon-end {
  -webkit-transform: translateX(-50%) rotate(180deg);
  -ms-transform: translateX(-50%) rotate(180deg);
  transform: translateX(-50%) rotate(180deg);
}

.cs-vertical-divider.divider-grass .cs-vd-line {
  height: calc(100% - 20px);
}

.cs-vertical-divider.divider-grass .cs-vd-icon.icon-end {
  -webkit-transform: translateX(-50%) rotate(180deg);
  -ms-transform: translateX(-50%) rotate(180deg);
  transform: translateX(-50%) rotate(180deg);
}

.elementor-widget-cs_vertical_divider.full-height,
.elementor-widget-cs_vertical_divider.full-height
  .elementor-widget-containercart,
.elementor-widget-cs_vertical_divider.full-height .cs-vertical-divider {
  height: 100%;
}

.cs-tabs .elementor-tabs-wrapper {
  text-align: center;
}

.cs-tabs .elementor-tab-desktop-title {
  display: inline-flex;
  font-family: var(--secondary-font);
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: var(--spacing);
}

.cs-tabs .elementor-tab-desktop-title a {
  position: relative;
  margin: 0 15px;
  color: #1a1b1a;
}

.elementor-element .cs-tabs .elementor-tab-desktop-title a {
  color: #1a1b1a;
}

.cs-tabs .elementor-tab-desktop-title a:before {
  position: absolute;
  bottom: -2px;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: var(--primary-color);
  content: "";
  transition: transform 0.2s ease;
  transform-origin: 100% 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.cs-tabs .elementor-tab-desktop-title.elementor-active a:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.cs-tabs .elementor-tab-content {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}

.cs-tabs .elementor-tab-content.elementor-active {
  -webkit-animation: showTab 0.7s ease-out 0.2s;
  animation: showTab 0.7s ease-out 0.2s;
  animation-fill-mode: forwards;
}

.cs-food-menu {
  --food-img-width: 85px;
}

.cs-food-menu .cs-food-menu {
  position: relative;
}

.cs-food-menu .cs-food-menu-item {
  display: table;
  width: 100%;
}

.cs-food-menu .cs-food-menu-img {
  position: relative;
  width: var(--food-img-width);
  max-width: var(--food-img-width);
  margin-right: 20px;
  overflow: hidden;
}

.cs-food-menu .cs-food-menu-main {
  display: table-cell;
  width: inherit;
  min-width: 100%;
  vertical-align: middle;
}

.cs-food-menu .cs-food-menu-header > * {
  display: table-cell;
}

.cs-food-menu .cs-food-menu-title {
  padding-right: 15px;
  margin-bottom: 0;
  width: 1%;
  white-space: nowrap;
  font-size: 21px;
  font-size: 1.3125rem;
}

.cs-food-menu .cs-food-menu-title a {
  color: inherit;
  white-space: inherit;
}

.cs-food-menu .cs-food-menu-title .title-wrap {
  white-space: inherit;
}

.cs-food-menu
  .cs-food-menu-title
  .title-wrap.pen:not([data-elementor-inline-editing-toolbar="advanced"]) {
  white-space: nowrap !important;
}

.cs-food-menu .cs-food-menu-title .menu-label {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
  display: inline-block;
  padding: 4px 5px;
  border-radius: 3px;
  background: var(--primary-color);
  color: #fff;
  font-family: var(--secondary-font);
  font-size: 10px;
  font-size: 0.625rem;
  text-transform: uppercase;
}

.cs-food-menu .cs-food-menu-lines {
  position: relative;
  width: 98%;
}

.cs-food-menu .cs-food-menu-group {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-size: 0.75rem;
  transform: translateY(-150%);
}

.cs-food-menu .cs-food-menu-group span {
  margin-right: 20px;
  border: 2px solid;
  padding: 0 3px;
}

.cs-food-menu .cs-food-menu-group span:last-child {
  margin: 0;
}

.cs-food-menu .cs-food-menu-price {
  padding-left: 15px;
  width: 1%;
  font-size: 20px;
  font-size: 1.25rem;
  white-space: nowrap;
}

.cs-food-menu .cs-food-menu-price del {
  font-size: 18px;
  font-size: 1.125rem;
  opacity: 0.35;
}

.cs-food-menu .cs-food-menu-price.multi-price .cs-price {
  margin-right: 20px;
}

.cs-food-menu .cs-food-menu-price.multi-price .cs-price:last-child {
  margin: 0;
}

.cs-food-menu .cs-food-menu-details {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.6;
  opacity: 0.6;
  color: var(--content-color);
}

.cs-food-menu.food-menu-style-1 .cs-food-menu-item {
  padding: 0 0 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dark-color .cs-food-menu.food-menu-style-1 .cs-food-menu-item {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.cs-food-menu.food-menu-style-2 .cs-food-menu-item {
  margin: 0 0 30px;
}

.cs-food-menu.food-menu-style-2 .cs-food-menu-lines {
  top: 21px;
  background-position: top;
  background-image: linear-gradient(
    to right,
    #000 25%,
    rgba(255, 255, 255, 0) 0%
  );
  background-size: 8px 2px;
  background-repeat: repeat-x;
}

.dark-color .cs-food-menu.food-menu-style-2 .cs-food-menu-lines {
  background-image: linear-gradient(
    to right,
    #fff 25%,
    rgba(255, 255, 255, 0) 0%
  );
}

.cs-food-menu.food-menu-style-3 .cs-food-menu-item {
  margin: 0 0 30px;
}

.cs-food-menu.food-menu-style-3 .cs-food-menu-lines {
  top: 17px;
  border-top: 1px solid;
  color: var(--border-color);
}

.cs-food-menu.food-menu-style-4 .cs-food-menu-item {
  margin: 0 0 30px;
}

.cs-food-menu.food-menu-style-4 .cs-food-menu-lines {
  position: relative;
  top: 14px;
  border-top: 1px solid;
  color: var(--border-color);
}

.cs-food-menu.food-menu-style-4 .cs-food-menu-lines:before {
  position: absolute;
  top: 4px;
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid;
  content: "";
}

.cs-food-menu.food-menu-style-5 {
  margin: calc(0px - var(--gap));
}

.cs-food-menu.food-menu-style-5.gap-10 {
  --gap: 10px;
}

.cs-food-menu.food-menu-style-5.gap-20 {
  --gap: 20px;
}

.cs-food-menu.food-menu-style-5.gap-32 {
  --gap: 32px;
}

.cs-food-menu.food-menu-style-5 .cs-food-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.cs-food-menu.food-menu-style-5 .cs-food-menu-header > * {
  display: block;
  white-space: normal;
  width: auto;
}

.cs-food-menu.food-menu-style-5 .cs-food-menu-header .cs-food-menu-lines {
  display: none;
}

.cs-food-menu.food-menu-style-5 .cs-food-menu-item {
  display: block;
  padding: var(--gap);
}

.cs-food-menu.food-menu-style-5 .pt-food-menu-main {
  display: block;
}

.cs-food-menu.food-menu-style-5 .cs-food-menu-title {
  line-height: 1.35;
}

.cs-food-menu.food-menu-style-5 .cs-food-menu-details {
  margin-top: 10px;
  line-height: 1.35;
}

.cs-food-menu.food-menu-style-5 .cs-food-menu-img {
  width: 100%;
  max-width: 100%;
  margin: 0 0 20px;
}

.cs-food-menu.food-menu-style-5 .cs-food-menu-img img {
  display: block;
}

.cs-food-menu.food-menu-style-6 .cs-food-menu-item {
  margin: 0 0 30px;
}

.cs-food-menu.food-menu-style-6 .cs-food-menu-lines {
  opacity: 0;
}

.cs-food-menu .cs-food-menu-item:last-child {
  margin-bottom: 0;
}

.cs-food-card {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.cs-food-card .cs-fc-img,
.cs-food-card .cs-fc-img + .cs-fc-info {
  width: 50%;
}

.cs-food-card:not(.column-reverse) .cs-fc-img {
  display: flex;
  justify-content: flex-end;
}

.cs-food-card .cs-fc-img + .cs-fc-info {
  padding: 0 60px;
}

.cs-food-card .cs-fc-info-title {
  margin: 0;
  line-height: 1.2;
  font-size: 40px;
}

.cs-food-card .cs-fc-info-title + .cs-fc-info-text {
  margin-top: 20px;
}

.cs-food-card .cs-fc-info-text {
  line-height: 1.25;
  font-size: 21px;
}

.cs-food-card .cs-fc-info-text > *:last-child {
  margin-bottom: 0;
}

.cs-food-card .cs-fc-info-text:not(:last-child) {
  margin-bottom: 30px;
}

.cs-food-card .cs-fc-info-extra {
  display: flex;
  margin-top: 20px;
}

.cs-food-card .cs-fc-info-extra .info-label {
  display: inline-block;
  margin-right: 45px;
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
  border: 2px solid;
  padding: 3px 15px;
  line-height: 24px;
  text-align: center;
  min-width: 130px;
}

.cs-food-card .cs-fc-info-extra .info-content {
  display: inline-block;
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1;
}

.cs-food-card.column-reverse {
  flex-direction: row-reverse;
}

.cs-food-card.column-reverse .cs-fc-info {
  display: flex;
  justify-content: flex-end;
}

.cs-testimonials .cs-testimonial-content {
  color: #1a1b1a;
  font-family: var(--heading-font);
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.5;
}

.cs-testimonials .cs-testimonial-content + .cs-testimonial-rating {
  margin-top: 10px;
}

.cs-testimonials .cs-testimonial-meta {
  margin-top: 25px;
}

.cs-testimonials .cs-testimonial-rating + .cs-testimonial-meta {
  margin-top: 0;
}

.cs-testimonials .elementor-testimonial-image {
  font-size: 0;
}

.cs-testimonials .elementor-testimonial-image img {
  display: inline;
  width: 70px;
  border-radius: 50%;
}

.cs-testimonials .cs-testimonial-rating:first-child {
  margin-bottom: 20px;
}

.cs-testimonials .cs-testimonial-stars {
  display: block;
}

.cs-testimonials .cs-testimonial-stars:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  content: "\f005\f005\f005\f005\f005";
  color: #ffc107;
  font-size: 11px;
  letter-spacing: 2px;
}

.cs-testimonials:not(.elementor-testimonial-image-position-aside)
  .cs-testimonial-meta
  .elementor-testimonial-image {
  margin-bottom: 12px;
}

.cs-testimonials.elementor-testimonial-image-position-aside
  .cs-testimonial-meta
  .elementor-testimonial-meta-inner {
  width: fit-content;
  text-align: left;
}

.cs-testimonials.elementor-testimonial-image-position-aside
  .cs-testimonial-meta
  .elementor-testimonial-image,
.cs-testimonials.elementor-testimonial-image-position-aside
  .cs-testimonial-meta
  .elementor-testimonial-details {
  display: inline-block;
  vertical-align: middle;
}

.cs-testimonials.elementor-testimonial-image-position-aside
  .cs-testimonial-meta
  .elementor-testimonial-image {
  margin-right: 12px;
}

.cs-testimonials.elementor-testimonial-image-position-aside.text-center
  .elementor-testimonial-meta-inner {
  margin: 0 auto;
}

.cs-testimonials .elementor-testimonial-name {
  font-family: var(--heading-font);
  font-size: 16px;
  font-size: 1rem;
}

.cs-testimonials .elementor-testimonial-job {
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.cs-testimonials.testimonial-style-1 .cs-testimonial {
  margin: 0 auto;
}

.cs-testimonials.testimonial-style-1 .cs-testimonial,
.cs-testimonials.testimonial-style-1 .slick-dots {
  max-width: 820px;
}

.cs-testimonials.testimonials-grid {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -30px;
}

.cs-testimonials.testimonials-grid .cs-testimonial {
  padding: 30px;
}

.cs-testimonials.testimonials-grid .cs-testimonial-content {
  font-size: 21px;
  font-size: 1.3125rem;
}

.cs-testimonials.testimonials-slider > .cs-ts-wrap > .cs-ts-item {
  display: none;
}

.cs-testimonials.testimonials-slider > .cs-ts-wrap > .cs-ts-item:first-child {
  display: block;
}

.cs-testimonials.testimonials-slider .slick-arrow.slick-prev {
  left: -30px;
}

.cs-testimonials.testimonials-slider .slick-arrow.slick-next {
  right: -30px;
}

.cs-testimonials.testimonials-slider[data-column="1"] .cs-ts-item {
  padding: 0;
}

.cs-testimonials.testimonials-slider[data-column="2"] .cs-testimonial-content,
.cs-testimonials.testimonials-slider[data-column="3"] .cs-testimonial-content {
  font-size: 18px;
  font-size: 1.125rem;
}

.cs-testimonials.testimonials-slider .cs-ts-item {
  padding: 0 30px;
}

.cs-gallery img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  transition: transform 0.3s;
}

.cs-gallery.gap-0 {
  --gallery-gap: 0px;
}

.cs-gallery.gap-2 {
  --gallery-gap: 2px;
}

.cs-gallery.gap-5 {
  --gallery-gap: 5px;
}

.cs-gallery.gap-10 {
  --gallery-gap: 10px;
}

.cs-gallery.gap-16 {
  --gallery-gap: 16px;
}

.cs-gallery.gap-20 {
  --gallery-gap: 20px;
}

.cs-gallery.gap-32 {
  --gallery-gap: 32px;
}

.cs-gallery .cs-gallery-item {
  position: relative;
}

.cs-gallery.gallery-grid .cs-gallery-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: calc(0px - var(--gallery-gap));
}

.cs-gallery.gallery-grid .cs-gallery-item {
  padding: var(--gallery-gap);
}

.cs-gallery.gallery-grid.grid-col-2 .cs-gallery-item {
  width: 50%;
}

.cs-gallery.gallery-grid.grid-col-3 .cs-gallery-item {
  width: 33.33%;
}

.cs-gallery.gallery-grid.grid-col-4 .cs-gallery-item {
  width: 25%;
}

.cs-gallery.gallery-grid.grid-col-5 .cs-gallery-item {
  width: 20%;
}

.cs-gallery.gallery-grid.grid-col-6 .cs-gallery-item {
  width: 16.6666%;
}

.cs-gallery.gallery-grid.align-middle-v .cs-gallery-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.cs-gallery.gallery-grid.align-bottom-v .cs-gallery-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.cs-gallery.gallery-mosaic .cs-gallery-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
}

.cs-gallery.gallery-mosaic .cs-gallery-wrap .cs-gallery-item:first-child {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

.cs-gallery.gallery-mosaic .cs-gallery-wrap img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cs-gallery.gallery-carousel .slick-list {
  margin: 0 calc(0px - var(--gallery-gap));
}

.cs-gallery.gallery-carousel .slick-slide {
  will-change: transform;
}

.cs-gallery.gallery-carousel .cs-gallery-item {
  padding: 0 var(--gallery-gap);
}

.cs-gallery.gallery-carousel.align-middle-v .slick-track {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.cs-gallery.gallery-carousel.align-bottom-v .slick-track {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.cs-gallery.gallery-carousel.slider-height-full .cs-gallery-item {
  height: 100vh;
}

.cs-gallery.gallery-carousel.slider-height-full .cs-gallery-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cs-gallery.gallery-carousel > .cs-gallery-wrap > .cs-gallery-item {
  display: none;
  opacity: 0;
}

.cs-gallery.gallery-carousel > .cs-gallery-wrap > .cs-gallery-item:first-child {
  display: block;
}

.cs-gallery.slider-dots-overlap .slick-dots {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  color: #fff;
}

.cs-gallery.variable-width .cs-gallery-item {
  height: 600px;
}

.cs-gallery.variable-width .cs-gallery-item img {
  height: 100%;
  width: auto;
}

.cs-gallery.style-overflow .slick-list {
  overflow: visible;
}

.cs-gallery.style-overflow .slick-arrow.slick-prev {
  left: -30px;
}

.cs-gallery.style-overflow .slick-arrow.slick-next {
  right: -30px;
}

.cs-instagram {
  --ig-gap: 8px;
}

.cs-instagram .cs-instagram-title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 256px;
  height: 256px;
  padding: 0 30px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.3;
  font-weight: 500;
  text-align: center;
  overflow: hidden;
}

.cs-instagram ul {
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--ig-gap);
}

.cs-instagram li {
  position: relative;
  overflow: hidden;
}

.cs-instagram.column-4 ul {
  grid-template-columns: repeat(4, 1fr);
}

.cs-instagram.column-5 ul {
  grid-template-columns: repeat(5, 1fr);
}

.cs-instagram .feed-bg {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.cs-instagram .feed-video {
  margin-bottom: 0;
}

.cs-instagram li a {
  position: relative;
  display: block;
  overflow: hidden;
  background: #000;
}

.cs-instagram li a:after {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 99;
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  content: "\f16d";
  font-size: 24px;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.4s ease-out;
}

.no-touch .cs-instagram li a:hover .feed-bg {
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
  opacity: 0.9;
}

.no-touch .cs-instagram li a:hover:after {
  opacity: 1;
}

.cs-fancy-card {
  --content-bg: #000;
  --content-color: #fff;
  --content-bg-hover: var(--content-bg);
  --content-color-hover: var(--content-color);
}

.cs-fancy-card .cs-fc-wrap {
  position: relative;
  overflow: hidden;
}

.cs-fancy-card .cs-fc-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.cs-fancy-card .cs-fc-link ~ * {
  pointer-events: none;
}

.elementor-editor-active .cs-fancy-card .cs-fc-link ~ *,
.cs-fancy-card .cs-fc-link ~ * a {
  pointer-events: auto;
}

.cs-fancy-card .cs-fc-img {
  position: relative;
  overflow: hidden;
}

.cs-fancy-card .cs-fc-img img {
  display: block;
  width: 100%;
  transition: 0.35s ease-in-out;
}

.no-touch .cs-fancy-card.cs-hover-img-zoom:hover .cs-fc-img img {
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
}

.cs-fancy-card.cs-hover-img-zoom-out .cs-fc-img img {
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
}

.no-touch .cs-fancy-card.cs-hover-img-zoom-out:hover .cs-fc-img img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.cs-fancy-card .cs-fc-content {
  z-index: 9;
  width: 100%;
  padding: 30px;
}

.cs-fancy-card .cs-fc-title:not([class*="color-"]),
.cs-fancy-card .cs-subtitle:not([class*="color-"]) {
  color: inherit;
}

.cs-fancy-card .cs-fc-text p {
  margin-bottom: 0;
}

.cs-fancy-card.style-1 .cs-fc-wrap {
  display: flex;
  flex-direction: column;
  background: var(--content-bg);
  color: var(--content-color);
  transition: background 0.3s, color 0.3s;
}

.no-touch .cs-fancy-card.style-1:hover .cs-fc-wrap {
  background: var(--content-bg-hover);
  color: var(--content-color-hover);
}

.cs-fancy-card.style-1.order-reverse:not(.layout-row) .cs-fc-img {
  order: 9;
}

.cs-fancy-card.style-1 .cs-fc-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cs-fancy-card.style-2 .cs-fc-wrap {
  overflow: visible;
}

.cs-fancy-card.style-2 .cs-fc-content {
  position: absolute;
  bottom: 30px;
  left: -20px;
  padding: 15px 50px;
  background: var(--content-bg);
  color: var(--content-color);
  width: fit-content;
  max-width: calc(100% - 20px);
  transition: background 0.3s, color 0.3s;
}

.cs-fancy-card.style-2.content-position-right .cs-fc-content {
  right: -20px;
  left: auto;
}

.cs-fancy-card.style-2.content-position-center .cs-fc-content {
  right: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 70%;
  max-width: 100%;
}

.no-touch .cs-fancy-card.style-2:hover .cs-fc-content {
  background: var(--content-bg-hover);
  color: var(--content-color-hover);
}

.cs-cta {
  --cta-border-color: var(--primary-color);
}

.cs-cta .cs-cta-wrap {
  position: relative;
  overflow: hidden;
}

.cs-cta .cs-cta-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
}

.cs-cta .cs-cta-img {
  position: relative;
  overflow: hidden;
}

.cs-cta .cs-cta-img img {
  display: block;
  width: 100%;
  transition: 0.35s ease-in-out;
}

.cs-cta .cs-cta-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.35s;
}

.cs-cta .cs-cta-label {
  z-index: 9;
  position: absolute;
  background: #fff;
  color: #000;
  padding: 8px 12px;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  pointer-events: none;
}

.elementor-editor-active .cs-cta .cs-cta-label {
  pointer-events: auto;
}

.cs-cta .cs-cta-label.label-top-left {
  top: 20px;
  left: 20px;
}

.cs-cta .cs-cta-label.label-top-right {
  top: 20px;
  right: 20px;
}

.cs-cta .cs-cta-label.label-bottom-left {
  bottom: 20px;
  left: 20px;
}

.cs-cta .cs-cta-label.label-bottom-right {
  right: 20px;
  bottom: 20px;
}

.cs-cta .cs-cta-label.label-centered {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cs-cta .cs-cta-content {
  z-index: 9;
  width: 100%;
  pointer-events: none;
}

.elementor-editor-active .cs-cta .cs-cta-content {
  pointer-events: auto;
}

.cs-cta .cs-cta-content > * {
  margin-bottom: 10px;
}

.cs-cta .cs-cta-content .cs-subtitle-wrap:last-child {
  margin-bottom: 0;
}

.cs-cta .cs-cta-content a {
  pointer-events: auto;
}

.elementor-editor-active .cs-cta .cs-cta-content a {
  pointer-events: none;
}

.elementor-editor-active .cs-cta .cs-cta-content a .cs-btn-text {
  pointer-events: auto;
}

.cs-cta .cs-cta-text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
}

.cs-cta .cs-cta-text p {
  margin-bottom: 10px;
}

.cs-cta .cs-cta-text p:last-child {
  margin-bottom: 0;
}

.cs-cta .hover-slide-up {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: 0.35s ease-in-out;
}

.no-touch .cs-cta:hover .hover-slide-up {
  max-height: 200px;
  opacity: 1;
}

.cs-cta.cta-layout-text-normal .cs-cta-content {
  padding-top: 30px;
}

.cs-cta.cta-layout-text-normal .cs-cta-content > * {
  margin-bottom: 15px;
}

.cs-cta.cta-layout-text-overlap .cs-cta-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px;
}

.no-touch .cs-cta.cta-hover-img-zoom:hover .cs-cta-img img {
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
}

.cs-cta.cta-hover-img-zoom-out .cs-cta-img img {
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
}

.no-touch .cs-cta.cta-hover-img-zoom-out:hover .cs-cta-img img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.cs-cta.content-top .cs-cta-content {
  justify-content: flex-start;
}

.cs-cta.content-middle .cs-cta-content {
  justify-content: center;
}

.cs-cta.content-bottom .cs-cta-content {
  justify-content: flex-end;
}

.cs-cta.text-hover-to-show .cs-cta-content {
  opacity: 0;
  transition: opacity 0.4s;
}

.no-touch .cs-cta.text-hover-to-show:hover .cs-cta-content {
  opacity: 1;
}

.cs-cta.text-hover-to-hide .cs-cta-content {
  transition: opacity 0.4s;
}

.no-touch .cs-cta.text-hover-to-hide:hover .cs-cta-content {
  opacity: 0;
}

.cs-cta.with-inner-border {
  --border-position: 10px;
}

.cs-cta.with-inner-border .cs-cta-content:before,
.cs-cta.with-inner-border .cs-cta-content:after {
  position: absolute;
  top: var(--border-position);
  right: var(--border-position);
  bottom: var(--border-position);
  left: var(--border-position);
  color: var(--cta-border-color);
  content: "";
  transition: opacity 0.35s, transform 0.35s;
}

.cs-cta.with-inner-border .cs-cta-content:before {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.cs-cta.with-inner-border .cs-cta-content:after {
  border-right: 1px solid;
  border-left: 1px solid;
}

.cs-cta.with-inner-border.text-hover-to-show .cs-cta-content:before,
.cs-cta.with-inner-border.text-hover-to-show .cs-cta-content:after {
  opacity: 0;
}

.cs-cta.with-inner-border.text-hover-to-show .cs-cta-content:before {
  transform: scale(0, 1);
}

.cs-cta.with-inner-border.text-hover-to-show .cs-cta-content:after {
  transform: scale(1, 0);
}

.no-touch
  .cs-cta.with-inner-border.text-hover-to-show:hover
  .cs-cta-content:before,
.no-touch
  .cs-cta.with-inner-border.text-hover-to-show:hover
  .cs-cta-content:after {
  opacity: 1;
  transform: scale(1);
}

.no-touch
  .cs-cta.with-inner-border.text-hover-to-hide:hover
  .cs-cta-content:before,
.no-touch
  .cs-cta.with-inner-border.text-hover-to-hide:hover
  .cs-cta-content:after {
  opacity: 0;
}

.no-touch
  .cs-cta.with-inner-border.text-hover-to-hide:hover
  .cs-cta-content:before {
  transform: scale(0, 1);
}

.no-touch
  .cs-cta.with-inner-border.text-hover-to-hide:hover
  .cs-cta-content:after {
  transform: scale(1, 0);
}

.cs-cta.img-offset-border {
  --offset: -10px;
  --offset-revert: calc(0px - var(--offset));
}

.cs-cta.img-offset-border .cs-cta-wrap {
  overflow: visible;
}

.cs-cta.img-offset-border .cs-cta-img-wrap {
  position: relative;
}

.cs-cta.img-offset-border .cs-offset-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--cta-border-color);
  transform: translate(var(--offset), calc(var(--offset)));
  transition: all 0.3s;
  will-change: transform, width, height;
}

.no-touch .cs-cta.img-offset-border:hover .cs-offset-border {
  -webkit-transform: translate(var(--offset-revert), var(--offset-revert));
  -ms-transform: translate(var(--offset-revert), var(--offset-revert));
  transform: translate(var(--offset-revert), var(--offset-revert));
  width: calc(100% + var(--offset) * 2);
  height: calc(100% + var(--offset) * 2);
}

.cs-block-links {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.cs-block-links .cs-bl-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 400px;
  flex-grow: 2;
  transition: width 0.8s cubic-bezier(0.36, 0.33, 0, 1);
}

.no-touch .cs-block-links .cs-bl-item:hover .cs-bl-item-bg {
  opacity: 1;
}

.cs-block-links .cs-bl-item-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.4s ease-out;
}

.cs-block-links .cs-bl-item-bg .cs-bl-item-bg-containercart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cs-block-links .cs-bl-item-inner {
  position: relative;
  z-index: 3;
  padding: 80px 30px;
  text-align: center;
}

.cs-block-links .cs-text,
.cs-block-links .button {
  margin-top: 20px;
}

.cs-block-links .cs-text p {
  margin-bottom: 20px;
}

.cs-block-links .cs-text p:last-child {
  margin-bottom: 0;
}

.cs-block-links .cs-bl-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.no-touch .cs-block-links.hover-text-white .cs-bl-item:hover {
  color: #fff !important;
}

.no-touch .cs-block-links.hover-text-white .cs-bl-item:hover .cs-subtitle,
.no-touch .cs-block-links.hover-text-white .cs-bl-item:hover .cs-title,
.no-touch .cs-block-links.hover-text-white .cs-bl-item:hover .cs-text {
  color: inherit !important;
}

.no-touch
  .cs-block-links.hover-text-white
  .cs-bl-item:hover
  .button.cs-btn-outline {
  --btn-bg: #fff;
}

.elementor-editor-active .cs-block-links .cs-bl-link {
  pointer-events: none;
}

.cs-countdown .countdown-item {
  position: relative;
  display: inline-flex;
  flex: 0 1;
  flex-direction: column;
  margin: 0 2%;
  padding: 10px 0;
  min-width: 100px;
  text-align: center;
  font-size: 0;
}

.cs-countdown .countdown-amount {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.2;
}

.cs-countdown .countdown-period {
  font-size: 18px;
  font-size: 1.125rem;
}

.cs-countdown.amount-color-primary .countdown-amount,
.cs-countdown.period-color-primary .countdown-period {
  color: var(--primary-color);
}

.cs-countdown.amount-color-secondary .countdown-amount,
.cs-countdown.period-color-secondary .countdown-period {
  color: var(--secondary-color);
}

.cs-countdown.amount-color-black .countdown-amount,
.cs-countdown.period-color-black .countdown-period {
  color: #000;
}

.cs-countdown.amount-color-white .countdown-amount,
.cs-countdown.period-color-white .countdown-period {
  color: #fff;
}

.cs-video-btn {
  font-size: 0;
}

.cs-video-btn .video-play-btn {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid;
  cursor: pointer;
}

.cs-video-btn .video-play-btn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 1px;
  margin-left: 4px;
  max-width: 32px;
}

.elementor-widget-cs_social a,
.entry-content .elementor-widget-cs_social a {
  color: inherit;
}

.cs-info-box {
  position: relative;
  display: flex;
  --box-gap: 20px;
  --icon-space: 25px;
}

.cs-info-box .cs-info-box-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: calc(0px - var(--box-gap) / 2);
  width: calc(100% + var(--box-gap));
}

.cs-info-box .cs-info-box-item,
.cs-info-box .cs-item-containercart {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.cs-info-box .cs-info-box-item {
  padding: calc(var(--box-gap) / 2);
}

.cs-info-box.valign-middle .cs-item-containercart {
  align-items: center;
}

.cs-info-box.img-left .cs-info-box-item {
  flex-direction: row;
}

.cs-info-box.img-left .cs-info-box-img {
  margin-right: var(--icon-space);
}

.cs-info-box.img-right .cs-info-box-item .cs-item-containercart {
  flex-direction: row-reverse;
}

.cs-info-box.img-right .cs-info-box-img {
  margin-right: 0;
  margin-left: var(--icon-space);
}

.cs-info-box.img-top {
  --icon-space: 15px;
}

.cs-info-box.img-top .cs-info-box-item .cs-item-containercart {
  flex-direction: column;
}

.cs-info-box.img-top .cs-info-box-img {
  margin-bottom: var(--icon-space);
}

.cs-info-box .cs-info-box-img {
  flex: 0 0 auto;
  line-height: 0;
}

.cs-info-box .cs-info-box-img i {
  font-size: 42px;
}

.cs-info-box .cs-info-box-content {
  position: relative;
  flex: 1 1 auto;
}

.cs-info-box .cs-info-box-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
}

.cs-info-box img {
  display: block;
}

.cs-info-box.text-center img {
  margin: 0 auto;
}

.cs-info-box.text-right img {
  margin-left: auto;
}

.cs-info-box.column-1 .cs-info-box-item {
  width: 100%;
}

.cs-info-box .cs-info-box-title {
  font-size: 21px;
  font-size: 1.3125rem;
}

.cs-info-box .cs-info-box-title + .cs-info-box-text,
.cs-info-box .cs-info-box-btn {
  margin-top: 10px;
}

.cs-info-box .cs-info-box-text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.35;
}

.cs-info-box .cs-info-box-text p {
  margin-bottom: 15px;
}

.cs-info-box .cs-info-box-text p:last-child {
  margin-bottom: 0;
}

.cs-team {
  position: relative;
  overflow: hidden;
}

.cs-team .cs-team-photo {
  margin-bottom: 15px;
}

.cs-team .cs-team-photo img {
  display: block;
}

.cs-team.text-left .cs-team-photo img {
  margin-right: auto;
}

.cs-team.text-center .cs-team-photo img {
  margin: 0 auto;
}

.cs-team.text-right .cs-team-photo img {
  margin-left: auto;
}

.cs-team .cs-team-position {
  display: block;
  margin-bottom: 7.5px;
}

.cs-team .cs-title.cs-team-name {
  font-size: 30px;
  font-size: 1.875rem;
  margin-bottom: 15px;
}

.cs-team .cs-team-text {
  margin-bottom: 15px;
  font-size: 16px;
  font-size: 1rem;
}

.cs-team .cs-team-text p {
  margin-bottom: 15px;
}

.cs-team .cs-team-text p:last-child {
  margin-bottom: 0;
}

.cs-team .cs-team-social a {
  color: inherit;
}

.cs-team > *:last-child {
  margin-bottom: 0;
}

.cs-team.style-overlay .cs-team-photo {
  margin-bottom: 0;
}

.cs-team.style-overlay .cs-team-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 50px 30px 30px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  color: #fff;
}

.cs-team.style-overlay .cs-team-name {
  color: inherit;
}

.cs-list {
  --icon-v-offset: 0px;
  --list-space: 2px;
  list-style: none;
  margin: 0;
}

.cs-list li {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: var(--list-space) 0;
  line-height: 1.4;
}

.cs-list .list-icon {
  position: relative;
  top: var(--icon-v-offset);
  display: flex;
  margin-right: 20px;
}

.cs-list .list-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cs-list.with-border {
  --list-space: 12px;
  border-top: 1px solid var(--border-color);
}

.cs-list.with-border li {
  border-bottom: 1px solid var(--border-color);
}

.cs-list.cs-list-type-none .list-icon {
  display: none;
  margin: 0;
}

.cs-list.cs-list-type-ordered {
  counter-reset: item;
}

.cs-list.cs-list-type-ordered .list-icon:before {
  counter-increment: item;
}

.cs-list.type-decimal .list-icon:before {
  content: counter(item, decimal) ".";
}

.cs-list.type-decimal-leading-zero .list-icon:before {
  content: counter(item, decimal-leading-zero) ".";
}

.cs-list.type-upper-roman .list-icon:before {
  content: counter(item, upper-roman) ".";
}

.cs-list.type-lower-roman .list-icon:before {
  content: counter(item, lower-roman) ".";
}

.elementor-editor-active .cs-list a {
  pointer-events: none;
}

.elementor-align-center .cs-list li {
  justify-content: center;
}

.elementor-align-right .cs-list li {
  justify-content: flex-end;
  text-align: right;
}

.cs-open-table .field-wrap > * {
  width: 100%;
}

.cs-open-table.wide .cs-otf-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
}

.cs-open-table.wide .cs-otf-wrap .cs-otf-field {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 30px;
}

.cs-open-table.wide .cs-otf-wrap .cs-otf-field:last-child {
  margin-bottom: 0;
}

.cs-open-table.standard .cs-otf-wrap .cs-otf-field {
  width: 100%;
  margin-bottom: 30px;
}

.cs-open-table.standard .cs-otf-wrap .cs-otf-field:last-child {
  margin-bottom: 0;
}

.cs-open-table .field-wrap {
  position: relative;
}

.cs-open-table .cs-otf-field {
  position: relative;
}

.cs-open-table .cs-otf-field.otf-restaurant .field-wrap:after,
.cs-open-table .cs-otf-field.otf-size .field-wrap:after,
.cs-open-table .cs-otf-field.otf-date .field-wrap:after,
.cs-open-table .cs-otf-field.otf-time .field-wrap:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\33";
  font-size: 16px;
  position: absolute;
  right: 5px;
  top: 0;
  line-height: 42px;
}

.cs-open-table select {
  -webkit-appearance: none !important;
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker {
  --dropdown-bg: #f8f5f1;
  --dropdown-color: #000;
  --dropdown-border: var(--primary-color);
  background: var(--dropdown-bg);
  color: var(--dropdown-color);
  border: 1px solid var(--dropdown-border);
  border-radius: 0;
  padding: 16px;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-header {
  padding: 0;
  border: none;
  background: none;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-title {
  line-height: 32px;
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker .ui-datepicker-prev,
.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 0;
  width: 32px;
  height: 32px;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-prev
  span,
.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-next
  span {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  border: 0.0625rem solid #d8d9db;
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%205.24%208.07%22%3E%3Cg%3E%3Cpath%20style%3D%22fill%3A%2523333%22%20d%3D%22M5.09%203.68L4.39%203%201.56.15a.5.5%200%200%200-.71%200l-.7.7a.5.5%200%200%200%200%20.71L2.62%204%20.15%206.51a.5.5%200%200%200%200%20.71l.71.71a.5.5%200%200%200%20.71%200L4.39%205.1l.71-.71a.5.5%200%200%200-.01-.71z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 0.375rem 0.5rem;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-prev.ui-state-hover,
.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-next.ui-state-hover {
  background: none;
  border: none;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-prev.ui-state-hover
  span,
.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-next.ui-state-hover
  span {
  border: 2px solid var(--primary-color);
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker .ui-datepicker-prev {
  left: 0;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  .ui-datepicker-prev
  span {
  transform: scaleX(-1);
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker .ui-datepicker-next {
  right: 0;
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker table {
  border: none;
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker table th {
  padding: 8px 0;
  font-weight: 500;
  font-size: 12px;
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker table td {
  padding: 0;
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker table td span,
.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker table td a {
  text-align: center;
}

.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker table td {
  border: none;
  line-height: 2rem;
  border: 0.0625rem solid #d8d9db;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  table
  .ui-state-default,
.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  table
  .ui-widget-content
  .ui-state-default {
  background: #fff;
  color: #000;
  border: none;
  padding: 0;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  table
  td.ui-state-disabled {
  color: #d8d9db;
  opacity: 1;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  table
  td.ui-state-disabled
  .ui-state-default {
  color: inherit;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  table
  td
  a.ui-state-active,
.theme-cozystay .ui-widget.ui-widget-content.ui-datepicker table td a:hover {
  position: relative;
}

.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  table
  td
  a.ui-state-active:before,
.theme-cozystay
  .ui-widget.ui-widget-content.ui-datepicker
  table
  td
  a:hover:before {
  border: 2px solid var(--primary-color);
  box-sizing: border-box;
  content: "";
  height: calc(100% + 2px);
  left: 0;
  margin: -0.0625rem;
  position: absolute;
  width: calc(100% + 2px);
}

.theme-cozystay .daterangepicker {
  --dropdown-bg: #f8f5f1;
  --dropdown-color: #000;
  --dropdown-border: var(--primary-color);
  --available-bg: #fff;
  --active-bg: var(--primary-color);
  --active-color: #fff;
  --inrange-bg: #ede6dc;
  --inrange-color: var(--dropdown-color);
  --disabled-color: #d8d9db;
  --padding: 8px;
  --td-border: #d8d9db;
  --tooltip-bg: #000;
  --tooltip-color: #fff;
  --no-check-inout: rgba(0, 0, 0, 0.7);
  --hover-highlight: var(--primary-color);
  background: var(--dropdown-bg);
  color: var(--dropdown-color);
  border: 1px solid var(--dropdown-border);
  border-radius: 0;
  padding: var(--padding);
}

.theme-cozystay .daterangepicker:before,
.theme-cozystay .daterangepicker:after {
  display: none;
}

.theme-cozystay .daterangepicker .calendar-table {
  background: none;
  border: none;
}

.theme-cozystay .daterangepicker .calendar-table table {
  border: none;
}

.theme-cozystay .daterangepicker .calendar-table th,
.theme-cozystay .daterangepicker .calendar-table td {
  position: relative;
  padding: 0;
  max-width: 100%;
  height: 36px;
  border: none;
  border-radius: 0;
}

.theme-cozystay .daterangepicker .calendar-table thead th.month {
  font-size: 16px;
  font-size: 1rem;
}

.theme-cozystay .daterangepicker .calendar-table tbody td {
  border: 0.0625rem solid var(--td-border);
}

.theme-cozystay .daterangepicker td:hover {
  z-index: 99;
}

.theme-cozystay .daterangepicker td.disabled,
.theme-cozystay .daterangepicker option.disabled {
  color: var(--disabled-color) !important;
  background: none !important;
}

.theme-cozystay .daterangepicker td.disabled:before,
.theme-cozystay
  .daterangepicker
  td.disabled:not(.maximal-stay-unavailable)
  .day-tooltip,
.theme-cozystay .daterangepicker option.disabled:before,
.theme-cozystay
  .daterangepicker
  option.disabled:not(.maximal-stay-unavailable)
  .day-tooltip {
  display: none !important;
}

.theme-cozystay .daterangepicker td.available {
  background: var(--available-bg);
}

.theme-cozystay .daterangepicker td.available:hover {
  background: var(--available-bg);
}

.theme-cozystay
  .daterangepicker
  td.no-checkin:not(.off):not(.active):not(.in-range),
.theme-cozystay
  .daterangepicker
  td.no-checkout:not(.off):not(.active):not(.in-range) {
  color: var(--no-check-inout);
}

.theme-cozystay .daterangepicker td.in-range {
  background: var(--inrange-bg);
  color: var(--inrange-color);
}

.theme-cozystay .daterangepicker td.active {
  background: var(--active-bg);
  color: var(--active-color);
}

.theme-cozystay .daterangepicker td.active:hover {
  background: var(--available-bg);
  color: var(--dropdown-color);
}

.theme-cozystay .daterangepicker td.active,
.theme-cozystay .daterangepicker td.in-range,
.theme-cozystay .daterangepicker td.available:hover {
  position: relative;
}

.theme-cozystay .daterangepicker td.active:before,
.theme-cozystay .daterangepicker td.in-range:before,
.theme-cozystay .daterangepicker td.available:hover:before {
  position: absolute;
  top: 0;
  left: 0;
  margin: -0.0625rem;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 2px solid;
  box-sizing: border-box;
  content: "";
}

.theme-cozystay .daterangepicker td.in-range:before {
  border-color: var(--inrange-bg);
}

.theme-cozystay .daterangepicker td.in-range.off:before {
  border-color: transparent;
}

.theme-cozystay .daterangepicker td.active:before {
  border-color: var(--active-bg);
}

.theme-cozystay .daterangepicker td.available:hover:before {
  border-color: var(--hover-highlight);
}

.theme-cozystay .daterangepicker td.active.off:not(:hover):before {
  border-color: transparent;
}

.theme-cozystay .daterangepicker th.available:hover {
  background: none;
}

.theme-cozystay .daterangepicker th.prev,
.theme-cozystay .daterangepicker th.next {
  position: relative;
}

.theme-cozystay .daterangepicker th.prev span,
.theme-cozystay .daterangepicker th.next span {
  border-color: var(--dropdown-color);
}

.theme-cozystay .daterangepicker th.prev:before,
.theme-cozystay .daterangepicker th.prev:after,
.theme-cozystay .daterangepicker th.next:before,
.theme-cozystay .daterangepicker th.next:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 1px 0 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
}

.theme-cozystay .daterangepicker th.prev:before,
.theme-cozystay .daterangepicker th.next:before {
  border: 0.0625rem solid var(--td-border);
}

.theme-cozystay .daterangepicker th.prev:after,
.theme-cozystay .daterangepicker th.next:after {
  border: 2px solid var(--hover-highlight);
  opacity: 0;
}

.theme-cozystay .daterangepicker th.prev:hover:before,
.theme-cozystay .daterangepicker th.next:hover:before {
  opacity: 0;
}

.theme-cozystay .daterangepicker th.prev:hover:after,
.theme-cozystay .daterangepicker th.next:hover:after {
  opacity: 1;
}

.theme-cozystay .daterangepicker th.prev span,
.theme-cozystay .daterangepicker th.next span {
  padding: 2px;
}

.theme-cozystay .daterangepicker th.prev:before,
.theme-cozystay .daterangepicker th.prev:after {
  margin-left: -1px;
}

.theme-cozystay .daterangepicker .day-tooltip {
  position: absolute;
  top: 1px;
  left: 50%;
  z-index: 999;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  display: block;
  width: fit-content;
  height: auto;
  padding: 2px 6px;
  background: var(--tooltip-bg);
  color: var(--tooltip-color);
  font-size: 10px;
  font-size: 0.625rem;
  border-radius: 2px;
  pointer-events: none;
}

.theme-cozystay .daterangepicker .day-tooltip:after {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background: var(--tooltip-bg);
  content: "";
  bottom: -2px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.cs-reservation-form {
  --field-height: 48px;
  --field-space: 30px;
  --dropdown-bg: #f8f5f1;
  --dropdown-color: #000;
  --dropdown-border: var(--primary-color);
  --icon-color: var(--primary-color);
  --divider-color: var(--primary-color);
}

.cs-reservation-form .cs-form-field .field-wrap,
.cs-reservation-form .cs-form-field .field-input-wrap {
  position: relative;
}

.cs-reservation-form .cs-form-field:not(.cs-submit) .field-input-wrap:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\33";
  font-size: 20px;
  line-height: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  opacity: 0.35;
}

.cs-reservation-form .cs-form-label {
  display: block;
}

.cs-reservation-form .field-wrap > .cs-form-label {
  color: #1a1b1a;
}

/* .cs-reservation-form input[type="text"], .cs-reservation-form input[type="email"], .cs-reservation-form input[type="url"], .cs-reservation-form input[type="file"], .cs-reservation-form input[type="password"], .cs-reservation-form input[type="number"], .cs-reservation-form input[type="date"], .cs-reservation-form input[type="search"], .cs-reservation-form input[type="tel"], .cs-reservation-form textarea, .cs-reservation-form select {
    height: var(--field-height);
    font-size: 14px;
    font-size: 0.875rem;
} */

.cs-reservation-form select {
  -webkit-appearance: none !important;
  width: 100%;
}

.cs-reservation-form button[type="submit"] {
  padding: 0 15px;
  width: 100%;
  height: var(--field-height);
  line-height: 1.2;
  text-align: center;
}

.cs-reservation-form.style-block .cs-form-wrap {
  font-size: 0;
}

.cs-reservation-form.style-block .cs-form-field:not(:first-child) {
  padding-top: var(--field-space);
}

.cs-reservation-form.style-block .form-field-col-1-2 {
  display: inline-block;
  width: 50%;
}

.cs-reservation-form.style-block .form-field-col-1-2.cs-adults {
  padding-right: calc(var(--field-space) / 2);
}

.cs-reservation-form.style-block .form-field-col-1-2.cs-children {
  padding-left: calc(var(--field-space) / 2);
}

.cs-reservation-form.style-block .form-field-col-1-2 .csf-dropdown {
  padding: 15px;
}

.cs-reservation-form.style-block
  .form-field-col-1-2
  .csf-dropdown
  .cs-form-label {
  display: none;
}

.cs-reservation-form.style-block
  .form-field-col-1-2
  .csf-dropdown
  .csf-dropdown-item {
  justify-content: center;
}

.cs-reservation-form.style-block .csf-dropdown {
  min-width: 100px;
  width: 100%;
}

.cs-reservation-form.style-banner .cs-form-wrap {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 calc(0px - var(--field-space) / 2);
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.cs-reservation-form.style-banner .cs-form-wrap .cs-form-field {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 0 calc(var(--field-space) / 2);
}

.cs-reservation-form.style-banner button[type="submit"] {
  padding: 0 15px;
  text-align: center;
  width: 100%;
}

.cs-reservation-form.style-banner.style-banner-4 {
  --field-space: 15px;
}

.cs-reservation-form.style-banner.style-banner-4 .cs-form-wrap {
  flex-wrap: wrap;
}

.cs-reservation-form.style-banner.style-banner-4 .cs-form-field {
  display: block;
  width: 50%;
  margin-top: var(--field-space);
}

.cs-reservation-form.style-banner.style-banner-4
  .cs-form-field:nth-child(-n + 2) {
  margin-top: 0;
}

.cs-reservation-form.style-banner.style-banner-4
  .cs-form-field.cs-submit:nth-child(5) {
  width: 100%;
}

.cs-reservation-form.style-banner-2 {
  --field-space: 40px;
}

.cs-reservation-form.style-banner-2 .cs-form-wrap {
  margin: 0;
  align-items: center;
}

.cs-reservation-form.style-banner-2
  .cs-form-wrap
  .cs-form-field:not(.cs-submit) {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.cs-reservation-form.style-banner-2 .cs-form-wrap .field-wrap {
  flex: 1 1 auto;
}

.cs-reservation-form.style-banner-2 .cs-form-wrap .cs-form-field {
  padding-left: 0;
  padding-right: 0;
}

.cs-reservation-form.style-banner-2 .cs-form-wrap .cs-form-field.cs-check-out,
.cs-reservation-form.style-banner-2 .cs-form-wrap .cs-form-field.cs-guests {
  padding-left: var(--field-space);
}

.cs-reservation-form.style-banner-2 .cs-form-icon {
  width: 20px;
  height: 25px;
  margin-right: 25px;
}

.cs-reservation-form.style-banner-2 .cs-form-icon svg {
  width: 20px;
  height: auto;
}

.cs-reservation-form.style-banner-2 .cs-form-icon svg path {
  fill: var(--icon-color);
}

.cs-reservation-form.style-banner-2 .cs-form-field.cs-guests .cs-form-icon,
.cs-reservation-form.style-banner-2 .cs-form-field.cs-guests .cs-form-icon svg {
  width: 25px;
}

.cs-reservation-form.style-banner-2
  .cs-form-field:not(.cs-submit)
  .field-input-wrap:after {
  display: none;
}

.cs-reservation-form.style-banner-2 .cs-form-field:not(.cs-submit):after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 30px;
  display: block;
  width: 1px;
  height: 56px;
  background: var(--divider-color);
  content: "";
  right: 0;
}

.cs-reservation-form.style-banner-2 .cs-form-field.cs-guests:after {
  display: none;
}

.cs-reservation-form.style-banner-2 .field-wrap > .cs-form-label {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
}

/* .cs-reservation-form.style-banner-2 input[type="text"], .cs-reservation-form.style-banner-2 input[type="email"], .cs-reservation-form.style-banner-2 input[type="url"], .cs-reservation-form.style-banner-2 input[type="file"], .cs-reservation-form.style-banner-2 input[type="password"], .cs-reservation-form.style-banner-2 input[type="number"], .cs-reservation-form.style-banner-2 input[type="date"], .cs-reservation-form.style-banner-2 input[type="search"], .cs-reservation-form.style-banner-2 input[type="tel"], .cs-reservation-form.style-banner-2 textarea, .cs-reservation-form.style-banner-2 select {
    padding: 0;
    height: auto;
    border: none;
    line-height: 1.2;
} */

.cs-reservation-form.style-banner-2 button[type="submit"] {
  min-height: 56px;
  font-size: 18px;
  font-size: 1.125rem;
}

.cs-reservation-form.style-banner-2.inline-label {
  --field-space: 60px;
}

.cs-reservation-form.style-banner-2.inline-label .cs-form-icon {
  margin-right: 15px;
}

.cs-reservation-form.style-banner-2.inline-label .cs-form-label {
  left: 0;
}

.cs-reservation-form.style-banner-2.inline-label
  .cs-form-field:not(.cs-submit):after {
  right: 0;
}

.cs-reservation-form.style-banner-2.inline-label .cs-form-field.cs-check-in,
.cs-reservation-form.style-banner-2.inline-label .cs-form-field.cs-check-out {
  width: 23%;
}

.cs-reservation-form.style-banner-2.inline-label .cs-form-field.cs-guests {
  width: 30%;
}

.cs-reservation-form.style-banner-2.inline-label .cs-form-field.cs-submit {
  width: 24%;
}

.cs-reservation-form.style-banner-3 {
  --field-height: 60px;
  --field-space: 0px;
  --btn-radius: 0px;
}

.cs-reservation-form.style-banner-3 .cs-form-wrap {
  margin: 0 5px;
}

.cs-reservation-form.style-banner-3 .cs-form-wrap:before {
  position: absolute;
  top: 5px;
  left: -5px;
  display: block;
  width: calc(100% + 10px);
  height: calc(100% - 10px);
  border: 1px solid var(--form-bd);
  content: "";
  pointer-events: none;
}

.cs-reservation-form.style-banner-3 .cs-form-wrap .cs-form-field {
  padding: 0;
}

.cs-reservation-form.style-banner-3 .cs-form-wrap .button {
  background: none;
  color: inherit;
  border: 1px solid var(--form-bd);
}

.cs-reservation-form.inline-label .field-wrap > .cs-form-label {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
}

.cs-reservation-form.inline-label .field-input-wrap > * {
  text-align: right;
  padding-right: 35px;
}

.cs-reservation-form.inline-label.cs-form-underline
  .field-wrap
  > .cs-form-label {
  left: 0;
}

.cs-reservation-form.inline-label.cs-form-underline .field-input-wrap > * {
  padding-right: 25px;
}

.cs-reservation-form.inline-label.cs-form-underline
  .cs-form-field:not(.cs-submit)
  .field-input-wrap:after {
  right: 0;
}

.cs-reservation-form.hide-label .field-wrap > .cs-form-label {
  display: none;
}

.cs-reservation-form .csf-dropdown {
  position: absolute;
  bottom: -12px;
  left: 0;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  z-index: -1;
  display: block;
  min-width: 320px;
  background: var(--dropdown-bg);
  color: var(--dropdown-color);
  border: 1px solid var(--dropdown-border);
  padding: 30px;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.cs-reservation-form .csf-dropdown.is-open {
  z-index: 999;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.cs-reservation-form .csf-dropdown .csf-dropdown-item,
.cs-reservation-form .csf-dropdown .cs-quantity {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cs-reservation-form .csf-dropdown .csf-dropdown-item:not(:last-child) {
  margin-bottom: 15px;
}

.cs-reservation-form .csf-dropdown .cs-quantity .input-text55 {
  padding: 0;
  margin: 0;
  width: 40px;
  height: auto;
  background: none;
  border: none;
  text-align: center;
  color: inherit;
  font-size: 16px;
  line-height: 1.2;
}

.cs-reservation-form .csf-dropdown .cs-quantity .minus,
.cs-reservation-form .csf-dropdown .cs-quantity .plus {
  position: relative;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  width: 30px;
  height: 30px;
  padding: 0;
  color: inherit;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.cs-reservation-form .csf-dropdown .cs-quantity .minus:before,
.cs-reservation-form .csf-dropdown .cs-quantity .minus:after,
.cs-reservation-form .csf-dropdown .cs-quantity .plus:before,
.cs-reservation-form .csf-dropdown .cs-quantity .plus:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  content: "";
  background: currentColor;
}

.cs-reservation-form .csf-dropdown .cs-quantity .minus:before,
.cs-reservation-form .csf-dropdown .cs-quantity .plus:before {
  width: 12px;
  height: 1px;
}

.cs-reservation-form .csf-dropdown .cs-quantity .plus:after {
  width: 1px;
  height: 12px;
}

.cs-reservation-form .date-range-picker {
  position: absolute;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.elementor-widget-cs_signup.elementor-align-left form {
  margin-right: auto;
  margin-left: 0;
}

.elementor-widget-cs_signup.elementor-align-center form {
  margin-right: auto;
  margin-left: auto;
}

.elementor-widget-cs_signup.elementor-align-right form {
  margin-right: 0;
  margin-left: auto;
}
:root {
  --rating-color: var(--primary-color);
  --product-bg: #f9f6f3;
  --label-bg: var(--primary-color);
  --label-color: #fff;
}

.woocommerce-store-notice,
p.demo_store {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: 1em;
  padding: 1em 0;
  text-align: center;
  background-color: #000;
  color: #fff;
  z-index: 99998;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: none;
}

.woocommerce-store-notice a,
p.demo_store a {
  text-decoration: underline;
}

.admin-bar p.demo_store {
  top: 32px;
}

.woocommerce-page .primary {
  max-width: none;
}

.woocommerce-page strong {
  font-weight: 600;
}

.woocommerce .products .added_to_cart,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce-page .products a.button,
.woocommerce-page .products .added_to_cart,
.woocommerce #respond input#submit,
.woocommerce-page .woocommerce a.button,
.woocommerce a.button.alt,
.woocommerce button.button.alt.single_add_to_cart_button,
.woocommerce-page .woocommerce-notices-wrapper a.button,
.woocommerce-page .woocommerce-info a.button,
.woocommerce .cart .button,
.woocommerce .cart input.button,
#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
#page .checkout_coupon .button[name="apply_coupon"],
.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order,
.woocommerce-form button.button,
.woocommerce-page button.woocommerce-Button,
.woocommerce-page a.woocommerce-button,
.woocommerce-MyAccount-content button.button,
.woocommerce-MyAccount-content a.button {
  position: relative;
  z-index: 9;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  height: var(--btn-height);
  background: var(--btn-bg);
  border: none;
  border-radius: var(--btn-radius);
  color: var(--btn-color);
  font-family: var(--btn-font);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  letter-spacing: var(--btn-letter-spacing);
  text-transform: var(--btn-text-transform);
  line-height: 1;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
}

.no-touch .woocommerce .products .added_to_cart:not(.cs-btn-underline):hover,
.no-touch .woocommerce a.button:not(.cs-btn-underline):hover,
.no-touch .woocommerce button.button:not(.cs-btn-underline):hover,
.no-touch .woocommerce-page .products a.button:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce-page
  .products
  .added_to_cart:not(.cs-btn-underline):hover,
.no-touch .woocommerce #respond input#submit:not(.cs-btn-underline):hover,
.no-touch .woocommerce-page .woocommerce a.button:not(.cs-btn-underline):hover,
.no-touch .woocommerce a.button.alt:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce
  button.button.alt.single_add_to_cart_button:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce-page
  .woocommerce-notices-wrapper
  a.button:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce-page
  .woocommerce-info
  a.button:not(.cs-btn-underline):hover,
.no-touch .woocommerce .cart .button:not(.cs-btn-underline):hover,
.no-touch .woocommerce .cart input.button:not(.cs-btn-underline):hover,
.no-touch
  #add_payment_method
  .wc-proceed-to-checkout
  a.checkout-button:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce-cart
  .wc-proceed-to-checkout
  a.checkout-button:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce-checkout
  .wc-proceed-to-checkout
  a.checkout-button:not(.cs-btn-underline):hover,
.no-touch
  #page
  .checkout_coupon
  .button[name="apply_coupon"]:not(.cs-btn-underline):hover,
.no-touch .woocommerce #payment #place_order:not(.cs-btn-underline):hover,
.no-touch .woocommerce-page #payment #place_order:not(.cs-btn-underline):hover,
.no-touch .woocommerce-form button.button:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce-page
  button.woocommerce-Button:not(.cs-btn-underline):hover,
.no-touch .woocommerce-page a.woocommerce-button:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce-MyAccount-content
  button.button:not(.cs-btn-underline):hover,
.no-touch .woocommerce-MyAccount-content a.button:not(.cs-btn-underline):hover {
  background: #ab916c;
  color: #fff;
  border-color: #ab916c;
}

.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled] {
  position: relative;
  z-index: 9;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  height: var(--btn-height);
  background: var(--btn-bg);
  border: none;
  border-radius: var(--btn-radius);
  color: var(--btn-color);
  font-family: var(--btn-font);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  letter-spacing: var(--btn-letter-spacing);
  text-transform: var(--btn-text-transform);
  line-height: 1;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  opacity: 0.5;
  pointer-events: none;
}

.no-touch .woocommerce button.button:disabled:not(.cs-btn-underline):hover,
.no-touch
  .woocommerce
  button.button:disabled[disabled]:not(.cs-btn-underline):hover {
  background: #ab916c;
  color: #fff;
  border-color: #ab916c;
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button:not(:hover),
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:not(:hover),
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button:not(:hover),
.woocommerce #payment #place_order:not(:hover),
.woocommerce-page #payment #place_order:not(:hover) {
  background: #1a1b1a;
  color: white;
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order {
  width: 100%;
  padding: 10px;
}

.woocommerce-page .woocommerce-notices-wrapper a.button,
.woocommerce-page .woocommerce-info a.button,
.woocommerce-page .shop_table a.button {
  --btn-height: 32px;
  padding: 0 16px;
  font-size: 11px;
  font-size: 0.6875rem;
  font-family: var(--body-font);
  text-transform: uppercase;
  font-weight: 500;
}

.woocommerce #respond input#submit:hover {
  background: #ab916c;
  color: #fff;
}

.woocommerce .button.alt.disabled {
  pointer-events: none;
}

.woocommerce a.button.cs-btn-outline {
  --btn-bg: #1a1b1a;
  --btn-bg-hover: var(--btn-bg);
  --btn-color-hover: white;
  background: none;
  border: 2px solid;
  border-color: var(--btn-bg);
  color: var(--btn-bg);
  line-height: 38px;
}

.no-touch .woocommerce a.button.cs-btn-outline:hover {
  background: #ab916c;
  color: #fff;
  border-color: #ab916c;
}

.woocommerce a.button.cs-btn-underline {
  padding: 7px 0;
  background: none;
  color: inherit;
  border-radius: 0;
  line-height: 1;
}

.woocommerce a.button.cs-btn-underline:before {
  position: absolute;
  bottom: -2px;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: var(--btn-bg);
  content: "";
  transition: transform 0.2s ease;
  transform-origin: 100% 0;
}

.no-touch .woocommerce a.button.cs-btn-underline:hover {
  color: inherit;
}

.no-touch .woocommerce a.button.cs-btn-underline:hover:before {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.woocommerce a.button.cs-btn-small {
  padding: 0 16px;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 32px;
}

.woocommerce a.button.cs-btn-small.cs-btn-outline {
  line-height: 28px;
}

.woocommerce a.button.cs-btn-small.cs-btn-underline {
  padding: 7px 0;
  line-height: 1;
}

.woocommerce a.button.cs-btn-large {
  padding: 0 25px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 45px;
}

.woocommerce a.button.cs-btn-large.cs-btn-outline {
  line-height: 41px;
}

.woocommerce a.button.cs-btn-large.cs-btn-underline {
  padding: 7px 0;
  line-height: 1;
}

.woocommerce a.button.cs-btn-extra-large {
  padding: 0 35px;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 60px;
}

.woocommerce a.button.cs-btn-extra-large.cs-btn-outline {
  line-height: 56px;
}

.woocommerce a.button.cs-btn-extra-large.cs-btn-underline {
  padding: 7px 0;
  line-height: 1;
}

.woocommerce a.button.cs-btn-color-primary {
  --btn-bg: var(--primary-color);
}

.woocommerce a.button.cs-btn-color-secondary {
  --btn-bg: var(--secondary-color);
}

.woocommerce a.button.cs-btn-color-black {
  --btn-bg: #000;
}

.woocommerce a.button.cs-btn-color-white {
  --btn-bg: #fff;
  --btn-color: #000;
}

.woocommerce a.button.cs-btn-color-white.cs-btn-outline {
  --btn-color-hover: #000;
}

figure.woocommerce-product-gallery__wrapper {
  max-width: none;
}

.woocommerce-breadcrumb,
.woocommerce .woocommerce-breadcrumb {
  margin-bottom: 0;
  font-family: var(--secondary-font);
  letter-spacing: var(--sf-letter-spacing);
  text-transform: var(--sf-text-transform);
  font-style: var(--sf-style);
  font-size: 12px;
  font-size: 0.75rem;
  color: inherit;
  line-height: 1.6;
}

.woocommerce-breadcrumb a,
.woocommerce .woocommerce-breadcrumb a {
  color: inherit;
}

.no-touch .woocommerce-breadcrumb a:hover,
.no-touch .woocommerce .woocommerce-breadcrumb a:hover {
  opacity: 0.7;
}

.woocommerce ul.products li.product .price ins,
.woocommerce div.product p.price ins,
.woocommerce div.product span.price ins {
  font-style: normal;
  text-decoration: none;
}

.woocommerce div.product .stock {
  color: #77a464;
}

.woocommerce span.onsale,
.woocommerce .stock {
  --product-label-size: 32px;
  width: auto;
  height: var(--product-label-size);
  min-width: var(--product-label-size);
  min-height: var(--product-label-size);
  line-height: var(--product-label-size);
  background: var(--label-bg);
  z-index: 9;
  top: 10px;
  left: 10px;
  right: auto;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 15px;
  border-radius: 0;
  color: var(--label-color);
  font-weight: 500;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1;
}

.woocommerce.rtl span.onsale,
.woocommerce.rtl .stock {
  right: 10px;
  left: auto;
}

.woocommerce .stock.out-of-stock {
  background: #111;
  color: #fff;
}

.woocommerce div.product .out-of-stock {
  color: #fff;
}

.woocommerce .star-rating {
  color: var(--rating-color);
}

.woocommerce a.remove {
  display: block;
  margin: 0 auto;
  width: 22px;
  height: 22px;
  color: inherit !important;
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  border-radius: 50%;
}

.no-touch .woocommerce a.remove:hover {
  background: red;
  color: #fff !important;
}

.woocommerce .quantity .qty {
  width: 140px;
  padding: 5px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  -moz-appearance: textfield !important;
  margin: 0;
  text-align: center;
}

.woocommerce .quantity .qty::-webkit-outer-spin-button,
.woocommerce .quantity .qty::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.woocommerce div.product form.cart div.quantity {
  margin-right: 12px;
}

.rtl.woocommerce div.product form.cart div.quantity {
  margin-right: 0;
  margin-left: 12px;
}

.cs-quantity {
  --form-bd: var(--primary-color);
  position: relative;
  display: inline-block;
}

.cs-quantity .minus,
.cs-quantity .plus {
  position: absolute;
  width: 40px;
  height: 100%;
  top: 0;
  text-align: center;
  border: none;
  background: none;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
}

.cs-quantity .minus {
  left: 0;
}

.cs-quantity .plus {
  right: 0;
}

.cs-quantity input.qty {
  border: 1px solid var(--form-bd);
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  padding: 1em 2em 1em 3em;
  margin: 0 0 2em;
  position: relative;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
  border: none;
  line-height: 32px;
  background: #f9f9f9;
  color: #1a1b1a;
  font-size: 16px;
  font-size: 1rem;
}

.woocommerce-error:after,
.woocommerce-info:after,
.woocommerce-message:after {
  content: " ";
  display: table;
  clear: both;
}

.woocommerce-error .button,
.woocommerce-info .button,
.woocommerce-message .button {
  float: right;
}

.woocommerce div.product form.cart {
  margin-bottom: 2em;
}

.woocommerce div.product form.cart .variations td,
.woocommerce div.product form.cart .variations th {
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 10px;
}

.woocommerce div.product div.cart .variations th {
  padding-right: 0;
  padding-left: 0;
}

.woocommerce div .form-row55 {
  margin-bottom: 30px;
}

.woocommerce div .form-row55 input.input-text55,
.woocommerce div .form-row55 textarea {
  color: var(--text-lighter);
}

body .select2-container--default .select2-selection--single {
  width: 100%;
  height: 40px;
  padding: 5px 15px;
  background: white;
  border: 1px solid var(--form-bd);
  border-radius: 0;
  color: #1a1b1a;
  -webkit-appearance: none !important;
  font-size: 16px;
  line-height: 42px;
  font-weight: 400;
}

body
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 2px;
  right: 10px;
  height: 36px;
}

body
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-top-color: currentColor;
}

body
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: inherit;
  opacity: 0.5;
}

body
  .select2-container--default.select2-container--open
  .select2-selection--single {
  border-color: #f3f5f6;
  opacity: 1;
}

body
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-bottom-color: currentColor;
}

body
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: #f7f7f7;
  color: #111;
}

body
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background: #fff;
  color: #111;
}

body
  .select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  margin-right: 15px;
}

.woocommerce-page.cs-form-underline
  form
  .select2-container--default
  .select2-selection--single {
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 0;
}

.woocommerce-page.cs-form-underline
  form
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 0;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 10px;
}

body .select2-container--open .select2-dropdown--below,
body .select2-container--open .select2-dropdown--above {
  border: 2px solid #f3f5f6;
  border-radius: 0;
}

.select2-dropdown {
  color: #111 !important;
}

.woocommerce table.shop_attributes th {
  width: 150px;
  padding: 8px;
  border-color: 1px solid black;
  line-height: 1.5;
}

.woocommerce table.shop_attributes td {
  font-style: normal;
  border-color: 1px solid black;
  padding: 0;
  border-top: 0;
  border: none;
  margin: 0;
  line-height: 1.5;
}

.woocommerce table.shop_attributes td p {
  margin: 0;
  padding: 8px 0;
}

.woocommerce table.shop_attributes tr:nth-child(even) th,
.woocommerce table.shop_attributes tr:nth-child(even) td {
  background: none;
}

.woocommerce table.shop_table {
  border-radius: 0;
  border-color: black;
  border-right-width: 0;
  border-left-width: 0;
}

.woocommerce table.shop_table thead th {
  font-family: var(--secondary-font);
  letter-spacing: var(--sf-letter-spacing);
  text-transform: var(--sf-text-transform);
  font-style: var(--sf-style);
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
}

.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot th {
  font-weight: 500;
}

.woocommerce table.shop_table th,
.woocommerce table.shop_table td {
  border-top: none !important;
  padding: 18px 0;
}

.woocommerce table.shop_table tfoot th,
.woocommerce table.shop_table tfoot td {
  border-top: 1px solid black !important;
}

.woocommerce .woocommerce-result-count {
  line-height: 42px;
}

.woocommerce .woocommerce-ordering {
  position: relative;
  margin-bottom: 35px;
}

.woocommerce .woocommerce-ordering select {
  padding-right: calc(var(--form-side-padding) + 20px);
}

.rtl.woocommerce .woocommerce-ordering select {
  padding-right: var(--form-side-padding);
  padding-left: calc(var(--form-side-padding) + 20px);
}

.woocommerce-page .woocommerce-result-count,
.rtl.woocommerce-page .woocommerce-ordering {
  float: left;
}

.woocommerce-page .woocommerce-ordering,
.rtl.woocommerce-page .woocommerce-result-count {
  float: right;
}

.woocommerce ul.products {
  --product-gap: 35px;
  margin: 0 0 1em;
  padding: 0;
  list-style: none;
  clear: both;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.woocommerce ul.products li.product {
  position: relative;
  margin-top: var(--product-gap);
  margin-bottom: 0;
  width: 100%;
  text-align: center;
}

.woocommerce ul.products li.product a img {
  margin: 0 0 20px;
}

.woocommerce ul.products li.product:first-child {
  margin-top: 0;
}

.woocommerce ul.products li.product .stock,
.woocommerce ul.products li.product .onsale {
  position: absolute;
  top: 10px;
  left: 10px;
  right: auto;
  margin: 0;
}

.woocommerce ul.products li.product .stock {
  position: absolute;
}

.woocommerce ul.products li.product .stock + .onsale {
  top: 47px;
}

.woocommerce ul.products li.product .product-image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.woocommerce ul.products li.product .product-image img {
  display: block;
  width: 100%;
}

.woocommerce
  ul.products
  li.product
  .cs-product-content
  .woocommerce-LoopProduct-link {
  display: block;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title,
.woocommerce ul.products li.product .woocommerce-loop-product__title,
.woocommerce ul.products li.product h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: var(--hf-weight);
  padding: 0;
  margin: 0 0 8px;
}

.woocommerce ul.products li.product .price-rating {
  margin: 0 0 8px;
}

.woocommerce ul.products li.product .price {
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  color: #1a1b1a;
}

.woocommerce ul.products li.product .price ins {
  font-weight: 400;
}

.woocommerce ul.products li.product .star-rating {
  margin: 10px auto 0;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 0.2em;
  width: 6.2em;
}

.woocommerce
  ul.products
  li.product
  .woocommerce-product-details__short-description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--content-color);
  margin-bottom: 10px;
}

.woocommerce
  ul.products
  li.product
  .woocommerce-product-details__short-description
  p {
  margin-bottom: 0;
}

.woocommerce ul.products li.product .button,
.woocommerce ul.products li.product .added_to_cart {
  --btn-color: #1a1b1a;
  --btn-height: 44px;
  padding: 0 24px;
  margin-top: 8px;
  background: none;
  border: 1px solid var(--btn-bg);
  font-family: var(--body-font);
  font-weight: 500;
  font-size: 11px;
  font-size: 0.6875rem;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  white-space: nowrap;
}

.woocommerce ul.products li.product .button.loading:after,
.woocommerce
  ul.products
  li.product
  .button.add_to_cart_button.ajax_add_to_cart.added {
  display: none !important;
}

.woocommerce ul.products li.product .button.product_type_external {
  display: block !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: var(--btn-height);
}

.rtl.woocommerce ul.products li.product .stock,
.rtl.woocommerce ul.products li.product .onsale {
  right: 10px;
  left: auto;
}

.woocommerce nav.woocommerce-pagination {
  margin-top: 50px;
  text-align: center;
}

.woocommerce nav.woocommerce-pagination ul {
  --pagi-size: 32px;
  display: inline-block;
  margin: 1px;
  padding: 0;
  border: none;
  white-space: nowrap;
  clear: both;
}

.woocommerce nav.woocommerce-pagination ul li {
  display: inline-block;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border: none;
}

.woocommerce nav.woocommerce-pagination ul li .page-numbers {
  display: block;
  margin: 0 6px;
  padding: 0 6px;
  width: auto;
  height: var(--pagi-size);
  background: none;
  border-radius: 0;
  text-align: center;
  font-family: var(--heading-font);
  font-size: 18px;
  font-size: 1.125rem;
  line-height: var(--pagi-size);
}

.woocommerce nav.woocommerce-pagination ul li .page-numbers.current {
  background: none;
  border-bottom: 1px solid var(--primary-color);
  color: inherit;
}

.woocommerce nav.woocommerce-pagination ul li .page-numbers.prev,
.woocommerce nav.woocommerce-pagination ul li .page-numbers.next {
  position: relative;
  font-size: 0;
}

.woocommerce nav.woocommerce-pagination ul li .page-numbers.prev:after,
.woocommerce nav.woocommerce-pagination ul li .page-numbers.next:after {
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: var(--pagi-size);
}

.woocommerce nav.woocommerce-pagination ul li .page-numbers.prev:after {
  content: "\34";
}

.woocommerce nav.woocommerce-pagination ul li .page-numbers.next:after {
  content: "\35";
}

.no-touch .woocommerce nav.woocommerce-pagination ul li a:hover {
  background: transparent;
  color: inherit;
  opacity: 0.5;
}

.woocommerce.single-product .site-content:not([class*="with-sidebar"]) .primary,
.woocommerce-cart .primary,
.woocommerce-checkout .primary,
.woocommerce-account .primary {
  margin: 0 auto;
}

.woocommerce-page div.product div.images {
  position: relative;
  float: left;
  width: 48%;
  margin-bottom: 2em;
}

.woocommerce-page div.product div.images img {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: none;
}

.woocommerce-page div.product div.images .flex-control-thumbs {
  overflow: hidden;
  zoom: 1;
  margin: 0;
  padding: 0;
  list-style: none;
}

.woocommerce-page div.product div.images .flex-control-thumbs li img {
  cursor: pointer;
  margin: 0;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.woocommerce-page
  div.product
  div.images
  .flex-control-thumbs
  li
  img.flex-active,
.woocommerce-page div.product div.images .flex-control-thumbs li img:hover {
  opacity: 1;
}

.woocommerce-page div.product div.summary {
  float: right;
  width: 48%;
  clear: none;
}

.woocommerce.single.single-product div.product p.stock {
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.025em;
  display: block;
  text-align: revert;
  line-height: revert;
  width: revert;
  height: revert;
  min-width: revert;
  min-height: revert;
  border-radius: 0;
  background: none;
  margin-bottom: 30px;
  padding: 0;
}

.woocommerce.single.single-product div.product p.stock.out-of-stock {
  position: relative;
  top: auto;
  right: auto;
  left: auto;
  padding: 0;
  border: none;
  color: red;
}

.woocommerce.single.single-product
  .woocommerce-product-details__short-description {
  font-size: var(--post-text-size);
}

.woocommerce.single.single-product
  .woocommerce-product-details__short-description
  p:not(:last-child) {
  margin-bottom: 15px;
}

.woocommerce.single.single-product div.product form.cart:not(.variations_form),
.woocommerce.single.single-product .woocommerce-variation-add-to-cart {
  display: flex;
  flex-wrap: wrap;
}

.woocommerce.single.single-product
  div.product
  form.cart:not(.variations_form)
  .button.single_add_to_cart_button,
.woocommerce.single.single-product
  .woocommerce-variation-add-to-cart
  .button.single_add_to_cart_button {
  min-width: 180px;
}

.woocommerce.single.single-product div.product form.cart.grouped_form {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.woocommerce.single.single-product
  div.product
  form.cart.grouped_form
  table
  td
  > *:last-child {
  margin-bottom: 0;
}

.woocommerce.single.single-product
  div.product
  form.cart.grouped_form
  .group_table {
  padding: 30px;
  background: #f9f9f9;
  border: none;
}

.woocommerce.single.single-product
  div.product
  form.cart.grouped_form
  .group_table
  td {
  vertical-align: middle;
}

.woocommerce.single.single-product .primary > .product > .onsale {
  display: none;
}

.woocommerce.single.single-product .summary.entry-summary {
  margin-bottom: 0;
}

.woocommerce.single.single-product .summary.entry-summary > * {
  margin-bottom: 15px;
}

.woocommerce.single.single-product .summary.entry-summary > *:last-child {
  margin-bottom: 0;
}

.woocommerce.single.single-product
  .summary.entry-summary
  .woocommerce-product-rating
  a:hover,
.woocommerce.single.single-product
  .summary.entry-summary
  .product_meta
  a:hover {
  color: var(--link-color-hover);
}

.woocommerce.single.single-product .summary.entry-summary .entry-title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.2;
}

.woocommerce.single.single-product .summary.entry-summary .price {
  color: inherit;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 400;
}

.woocommerce.single.single-product .summary.entry-summary .price del {
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
}

.woocommerce.single.single-product .summary.entry-summary .price ins {
  margin-left: 4px;
  font-weight: 600;
}

.woocommerce.single.single-product
  .summary.entry-summary
  .woocommerce-product-details__short-description
  p {
  margin-bottom: 15px;
}

.woocommerce.single.single-product
  .summary.entry-summary
  .woocommerce-product-details__short-description
  p:last-child {
  margin-bottom: 0;
}

.woocommerce.single.single-product .summary.entry-summary form.cart {
  margin: 30px 0;
}

.woocommerce.single.single-product
  .summary.entry-summary
  form.cart
  .variations
  tr:first-child
  th,
.woocommerce.single.single-product
  .summary.entry-summary
  form.cart
  .variations
  tr:first-child
  td {
  padding-top: 0;
}

.woocommerce.single.single-product
  .summary.entry-summary
  form.cart
  .woocommerce-variation-price {
  margin-bottom: 20px;
}

.woocommerce.single.single-product .summary.entry-summary .product_meta > * {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}

.woocommerce.single.single-product
  .summary.entry-summary
  .product_meta
  > *
  span:first-child,
.woocommerce.single.single-product
  .summary.entry-summary
  .product_meta
  > *
  a:first-child {
  margin-left: 5px;
}

.woocommerce.single.single-product .woocommerce-product-rating {
  margin: 0;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 30px;
}

.woocommerce.single.single-product .woocommerce-product-rating .star-rating {
  margin: 5px 10px 0 0;
  height: 20px;
  line-height: 20px;
  letter-spacing: 0.2em;
  width: 6.2em;
}

.woocommerce.single.single-product
  .woocommerce-product-rating
  .woocommerce-review-link {
  display: inline-block;
  font-weight: 400;
  line-height: 30px;
  font-size: 16px;
  font-size: 1rem;
}

.woocommerce.single.single-product .woocommerce-tabs {
  padding-top: 40px;
  clear: both;
}

.woocommerce.single.single-product .woocommerce-tabs ul.tabs {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0;
  margin: 0 0 35px;
  overflow: hidden;
  text-align: center;
  list-style: none;
}

.woocommerce.single.single-product .woocommerce-tabs ul.tabs:before {
  position: absolute;
  content: " ";
  width: 100%;
  top: 0;
  bottom: auto;
  left: 0;
  border-bottom: 1px solid var(--border-color);
  z-index: 1;
}

.woocommerce.single.single-product .woocommerce-tabs ul.tabs li {
  position: relative;
  z-index: 9;
  display: inline-block;
  padding: 0 30px;
  margin: 0;
  background: none;
  border: none;
  border-radius: 0;
  color: inherit !important;
}

.woocommerce.single.single-product .woocommerce-tabs ul.tabs li a {
  position: relative;
  display: inline-block;
  padding: 25px 0;
  color: inherit;
  font-family: var(--heading-font);
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: capitalize;
}

.woocommerce.single.single-product .woocommerce-tabs ul.tabs li a:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 5px;
  background: var(--primary-color);
  content: "";
  opacity: 0;
  transition: opacity 0.3s;
}

.woocommerce.single.single-product .woocommerce-tabs ul.tabs li.active {
  background: none;
}

.woocommerce.single.single-product .woocommerce-tabs ul.tabs li.active a:after {
  opacity: 1;
}

.woocommerce.single.single-product .woocommerce-tabs ul.tabs li:before,
.woocommerce.single.single-product .woocommerce-tabs ul.tabs li:after {
  display: none;
}

.woocommerce.single.single-product
  .woocommerce-tabs
  .entry-content
  > h2:first-child {
  display: none;
}

.woocommerce.single.single-product .woocommerce-tabs table.shop_attributes {
  border: none;
}

.woocommerce.single.single-product .up-sells.upsells.products,
.woocommerce.single.single-product .related.products {
  padding-top: var(--spacing);
  clear: both;
}

.woocommerce.single.single-product
  .woocommerce-tabs
  + .up-sells.upsells.products,
.woocommerce.single.single-product .woocommerce-tabs + .related.products {
  margin-top: var(--spacing);
  border-top: 1px solid var(--border-color);
}

.woocommerce div.product .woocommerce-tabs .panel,
.woocommerce div.product .woocommerce-tabs .panel > *:last-child {
  margin-bottom: 0;
}

.rtl.woocommerce.single.single-product
  .woocommerce-product-rating
  .star-rating {
  margin-right: 0;
  margin-left: 10px;
}

.up-sells.upsells.products > h2,
.related.products > h2 {
  display: block;
  margin-bottom: 50px;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: var(--hf-weight);
  letter-spacing: var(--hf-letter-spacing);
  text-transform: var(--hf-text-transform);
  font-style: var(--hf-style);
}

.woocommerce-Reviews-title {
  display: none;
}

.woocommerce #reviews #comments ol.commentlist {
  margin: 0;
  width: 100%;
  background: 0 0;
  list-style: none;
}

.woocommerce #reviews #comments ol.commentlist li {
  position: relative;
  margin-bottom: 30px;
}

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  padding: 0;
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  border-radius: 50%;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 70px;
  padding: 0;
  border: none;
  border-radius: 0;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating {
  order: 3;
  float: none;
  margin: 5px 0 0 0;
  font-size: 12px;
  letter-spacing: 0.2em;
  width: 6.2em;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
  align-items: center;
  margin: 0;
  color: inherit;
  font-size: 12px;
  font-size: 0.75rem;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta > * {
  margin-right: 8px;
}

.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  p.meta
  .woocommerce-review__author {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
}

.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  p.meta
  .woocommerce-review__dash,
.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  p.meta
  .woocommerce-review__published-date {
  color: var(--text-lighter);
}

.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  p.meta
  .woocommerce-review__verified {
  color: var(--text-lighter);
  letter-spacing: 0;
  text-transform: none;
  font-style: normal;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text .description {
  margin: 10px 0;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text .description p {
  margin-bottom: 10px;
}

.woocommerce
  #reviews
  #comments
  ol.commentlist
  li
  .comment-text
  .description
  p:last-child {
  margin-bottom: 0;
}

.woocommerce #reviews #comments ol.commentlist:after {
  display: block;
  content: "";
  clear: both;
}

.woocommerce #reviews #review_form_wrapper label {
  font-size: 16px;
  font-size: 1rem;
}

.woocommerce #reviews #review_form_wrapper p {
  margin-bottom: 30px;
}

.woocommerce #reviews #review_form_wrapper .comment-form-rating {
  margin-bottom: 30px;
  clear: both;
}

.woocommerce #reviews #review_form_wrapper .comment-form-rating label {
  float: left;
  margin-right: 15px;
}

.woocommerce #reviews #review_form_wrapper .comment-form-rating p.stars {
  padding-top: 5px;
}

.woocommerce #reviews #review_form_wrapper .comment-form-rating p.stars span {
  display: flex;
  align-items: flex-start;
}

.woocommerce #reviews #review_form_wrapper .comment-form-rating a {
  color: var(--rating-color);
}

.woocommerce #reviews #review_form_wrapper .comment-form-rating:after {
  position: relative;
  display: block;
  content: "";
  clear: both;
}

.woocommerce #reviews .comment-reply-title {
  display: block;
  margin-top: 40px;
  margin-bottom: 30px;
  font-family: var(--heading-font);
  font-weight: var(--hf-weight);
  letter-spacing: var(--hf-letter-spacing);
  text-transform: var(--hf-text-transform);
  font-style: var(--hf-style);
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.35;
}

.woocommerce #review_form #respond::after,
.woocommerce #review_form #respond::before {
  content: " ";
  display: table;
}

.rtl.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin: 0 70px 0 0;
}

.rtl.woocommerce #reviews #review_form_wrapper .comment-form-rating label {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}

.woocommerce table.shop_table.cart {
  margin-bottom: var(--spacing);
  border-bottom-width: 0;
}

.woocommerce table.shop_table.cart th {
  padding: 24px 0 24px 25px;
}

.woocommerce table.shop_table.cart td {
  padding: 18px 0 18px 25px;
  vertical-align: middle;
}

.woocommerce table.shop_table.cart th.product-remove,
.woocommerce table.shop_table.cart td.product-remove {
  padding-right: 20px;
  padding-left: 0;
}

.woocommerce table.shop_table.cart th.product-thumbnail,
.woocommerce table.shop_table.cart td.product-thumbnail {
  padding-right: 0;
  padding-left: 0;
  width: 100px;
}

.woocommerce table.shop_table.cart th.product-name,
.woocommerce table.shop_table.cart td.product-name {
  padding-right: 20px;
}

.woocommerce table.shop_table.cart td.product-name > a {
  font-weight: 600;
  text-transform: uppercase;
}

.woocommerce table.shop_table.cart td.actions {
  padding: 30px 0 0;
}

.rtl .woocommerce table.shop_table.cart td.actions {
  padding-right: 0;
}

.woocommerce table.shop_table.cart .variation {
  max-width: 400px;
}

.rtl .woocommerce table.shop_table.cart th,
.rtl .woocommerce table.shop_table.cart td {
  padding-right: 25px;
  padding-left: 0;
}

.rtl .woocommerce table.shop_table.cart th.product-remove,
.rtl .woocommerce table.shop_table.cart td.product-remove {
  padding-right: 0;
  padding-left: 20px;
}

.rtl .woocommerce table.shop_table.cart th.product-thumbnail,
.rtl .woocommerce table.shop_table.cart td.product-thumbnail {
  padding-right: 0;
  padding-left: 0;
}

.rtl .woocommerce table.shop_table.cart th.product-name,
.rtl .woocommerce table.shop_table.cart td.product-name {
  padding-left: 20px;
}

.woocommerce td.product-name dl.variation,
.woocommerce td.product-name .cs-room-order-wrapper {
  margin-top: 4px;
  font-size: 14px;
  font-size: 0.875rem;
}

.woocommerce-page .cart-collaterals:not(:last-child) {
  margin-bottom: 50px;
}

.woocommerce-page .cart-collaterals .cart_totals {
  float: none;
  width: 100%;
  padding: 40px 40px 20px;
  background: #f9f9f9;
}

.woocommerce-page .cart-collaterals + .cross-sells {
  float: none;
  width: 100%;
  clear: both;
}

.woocommerce-page .cart-collaterals .order-total .includes_tax {
  display: block;
  margin-top: 4px;
  line-height: 1.35;
}

.woocommerce .cart-collaterals .cart_totals td,
.woocommerce-page .cart-collaterals .cart_totals td {
  text-align: right;
}

.rtl .woocommerce .cart-collaterals .cart_totals td,
.rtl .woocommerce-page .cart-collaterals .cart_totals td {
  text-align: left;
}

.woocommerce-page.woocommerce-cart .cart-empty {
  padding: 30px 0 0;
  margin: 0 auto 30px;
  background: none;
  box-shadow: none;
  font-family: var(--heading-font);
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: var(--hf-weight);
  letter-spacing: var(--hf-letter-spacing);
  text-transform: var(--hf-text-transform);
  font-style: var(--hf-style);
  line-height: 1;
  text-align: center;
}

.woocommerce-page.woocommerce-cart .cart-empty:before {
  display: none;
}

.woocommerce-page.woocommerce-cart p.return-to-shop {
  text-align: center;
}

.woocommerce-page.woocommerce-cart .quantity .qty {
  font-size: 16px;
  font-size: 1rem;
}

.woocommerce-page.woocommerce-cart table.cart td.actions {
  text-align: right;
}

.woocommerce-page.woocommerce-cart table.cart td.actions .coupon {
  float: left;
}

.woocommerce-page.woocommerce-cart table.cart td.actions .coupon label {
  display: none;
}

.woocommerce-page.woocommerce-cart table.cart td.actions .coupon .input-text55 {
  float: left;
  box-sizing: border-box;
  border: 1px solid #d3ced2;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: 0;
}

.woocommerce-page.woocommerce-cart table.cart td.actions #coupon_code {
  border-color: var(--form-bd);
  width: 140px;
  margin-right: 8px;
}

.woocommerce-page.woocommerce-cart .cart-collaterals .cross-sells > h2,
.woocommerce-page.woocommerce-cart .cart-collaterals .cart_totals > h2 {
  display: block;
  margin-bottom: 30px;
  font-weight: var(--hf-weight);
  letter-spacing: var(--hf-letter-spacing);
  text-transform: var(--hf-text-transform);
  font-style: var(--hf-style);
  font-size: 28px;
  font-size: 1.75rem;
}

.woocommerce-page.woocommerce-cart .woocommerce-shipping-calculator {
  margin-top: 15px;
}

.woocommerce-page.woocommerce-cart
  .woocommerce-shipping-calculator
  > p:first-child {
  margin-bottom: 0;
}

.woocommerce-page.woocommerce-cart
  .woocommerce-shipping-calculator
  .shipping-calculator-form {
  margin-top: 15px;
}

.woocommerce-page.woocommerce-cart
  .woocommerce-shipping-calculator
  .shipping-calculator-form
  p:last-of-type {
  margin-bottom: 0;
}

.woocommerce-page.woocommerce-cart .wc-proceed-to-checkout {
  text-align: right;
}

.rtl.woocommerce-page.woocommerce-cart
  table.cart
  td.actions
  .coupon
  .input-text55 {
  float: right;
  margin: 0 0 0 4px;
}

.rtl.woocommerce-page.woocommerce-cart table.cart td.actions #coupon_code {
  margin-right: 0;
  margin-left: 8px;
}

.woocommerce-cart table.cart td.product-name a:not(:hover) {
  color: inherit;
}

#add_payment_method table.cart td,
.woocommerce-cart table.cart td,
.woocommerce-checkout table.cart td {
  font-size: 16px;
  font-size: 1rem;
}

#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
  display: block;
  margin: 0;
  width: 100px;
}

#add_payment_method table.cart a,
.woocommerce-cart table.cart a,
.woocommerce-checkout table.cart a {
  text-decoration: none;
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment {
  padding: 40px;
  background: #f9f9f9;
  border: none;
}

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods {
  padding: 0 0 30px;
  border-color: var(--border-color);
}

#add_payment_method #payment ul.payment_methods li:not(:last-child),
.woocommerce-cart #payment ul.payment_methods li:not(:last-child),
.woocommerce-checkout #payment ul.payment_methods li:not(:last-child) {
  margin-bottom: 15px;
}

#add_payment_method #payment ul.payment_methods label,
.woocommerce-cart #payment ul.payment_methods label,
.woocommerce-checkout #payment ul.payment_methods label {
  display: inline;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
}

#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
  margin-left: 35px;
  padding: 0;
  width: auto;
  background: none;
}

#add_payment_method #payment div.payment_box p,
.woocommerce-cart #payment div.payment_box p,
.woocommerce-checkout #payment div.payment_box p {
  font-size: 14px;
  font-size: 0.875rem;
}

#add_payment_method #payment div.payment_box:before,
.woocommerce-cart #payment div.payment_box:before,
.woocommerce-checkout #payment div.payment_box:before {
  display: none;
}

#add_payment_method #payment .payment_method_paypal .about_paypal,
.woocommerce-cart #payment .payment_method_paypal .about_paypal,
.woocommerce-checkout #payment .payment_method_paypal .about_paypal {
  margin-top: 18px;
  line-height: 1.2;
}

#add_payment_method #payment .form-row.place-order,
.woocommerce-cart #payment .form-row.place-order,
.woocommerce-checkout #payment .form-row.place-order {
  padding: 30px 0 0;
  margin-bottom: 0;
}

#add_payment_method
  #payment
  .form-row.place-order
  .woocommerce-privacy-policy-text
  p,
.woocommerce-cart
  #payment
  .form-row.place-order
  .woocommerce-privacy-policy-text
  p,
.woocommerce-checkout
  #payment
  .form-row.place-order
  .woocommerce-privacy-policy-text
  p {
  margin-bottom: 30px;
  font-size: 14px;
  font-size: 0.875rem;
  color: var(--text-lighter);
}

.woocommerce-checkout .checkout.woocommerce-checkout .col2-set .col-55,
.woocommerce-checkout .checkout.woocommerce-checkout .col2-set .col-2 {
  float: none;
  width: 100%;
}

.woocommerce-checkout .checkout.woocommerce-checkout:after {
  position: relative;
  display: block;
  clear: both;
  content: "";
}

.woocommerce-checkout .checkout.woocommerce-checkout ul > li > label {
  font-size: 14px;
  font-size: 0.875rem;
}

.woocommerce-checkout
  .checkout.woocommerce-checkout
  h3#ship-to-different-address {
  margin-top: 20px;
  padding: 40px 0;
  width: 100%;
}

.woocommerce-checkout .checkout.woocommerce-checkout #customer_details {
  margin-bottom: 50px;
}

.woocommerce-checkout
  .checkout.woocommerce-checkout
  .woocommerce-shipping-fields
  .shipping_address {
  margin-bottom: 30px;
}

.woocommerce-checkout .woocommerce * + .checkout.woocommerce-checkout {
  padding-top: 60px;
}

.woocommerce-checkout .woocommerce-additional-fields h3 {
  margin-top: var(--spacing);
}

.woocommerce-checkout #order_review_heading,
.woocommerce-checkout .woocommerce-checkout-review-order table {
  padding: 40px;
  background: #f9f9f9;
}

.woocommerce-checkout #order_review_heading {
  margin-bottom: 0;
  padding-bottom: 0;
}

.woocommerce-checkout .woocommerce-checkout-review-order table {
  border: none;
  padding-top: 30px;
}

.woocommerce-checkout .woocommerce-checkout-review-order table th,
.woocommerce-checkout .woocommerce-checkout-review-order table td {
  padding-right: 18px;
  padding-left: 10px;
  font-size: 15px;
}

.rtl.woocommerce-checkout .woocommerce-checkout-review-order table th,
.rtl.woocommerce-checkout .woocommerce-checkout-review-order table td {
  padding-right: 0;
  padding-left: 18px;
}

.dark-color #add_payment_method #payment div.payment_box,
.woocommerce-cart.dark-color #payment div.payment_box,
.woocommerce-checkout.dark-color #payment div.payment_box {
  color: #999;
}

.woocommerce-form-login-toggle .woocommerce-info,
.woocommerce-form-coupon-toggle .woocommerce-info {
  display: block;
  padding: 15px 30px;
  font-size: smaller;
}

.woocommerce-form-login-toggle .woocommerce-info:before,
.woocommerce-form-coupon-toggle .woocommerce-info:before {
  display: none;
}

.woocommerce-form-login-toggle + form,
.woocommerce-form-coupon-toggle + form {
  padding: 0 30px !important;
  margin: 0 !important;
}

.woocommerce div.checkout_coupon,
.woocommerce-page form.checkout_coupon {
  border: none;
  padding: 0;
  font-size: 16px;
  font-size: 1rem;
}

.woocommerce div.checkout_coupon p,
.woocommerce-page form.checkout_coupon p {
  margin-bottom: 10px;
}

.woocommerce-order-received .woocommerce-order section {
  margin-top: var(--spacing);
}

.woocommerce-order-details table.shop_table th,
.woocommerce-order-details table.shop_table td {
  padding-right: 18px;
}

.rtl .woocommerce-order-details table.shop_table th,
.rtl .woocommerce-order-details table.shop_table td {
  padding-right: 0;
  padding-left: 18px;
}

.woocommerce .woocommerce-customer-details address {
  padding: 30px;
  border: 1px solid var(--border-color);
  font-size: smaller;
  border-radius: 0;
}

.woocommerce-account
  .woocommerce-MyAccount-content
  .woocommerce-address-fields__field-wrapper
  + p {
  padding: 15px 0 0 0;
  clear: both;
}

.woocommerce-account .woocommerce-MyAccount-content mark {
  background: none;
  color: inherit;
  font-weight: 600;
}

.woocommerce-account .woocommerce-MyAccount-content h3 {
  margin-bottom: 30px;
}

.woocommerce-account .woocommerce-MyAccount-content p + .woocommerce-Addresses {
  padding-top: 30px;
  border-top: 1px solid var(--border-color);
}

.woocommerce-account .primary section {
  margin-top: var(--spacing);
}

.woocommerce-account .addresses .title .edit,
.woocommerce-account ul.digital-downloads li .count {
  display: inline-block;
  padding: 3px 8px;
  background: #f9f9f9;
  color: #1a1b1a;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1.6;
  text-transform: uppercase;
  text-decoration: none;
}

.no-touch .woocommerce-account .addresses .title .edit:hover,
.no-touch .woocommerce-account ul.digital-downloads li .count:hover {
  background: var(--primary-color);
  color: #fff;
}

.woocommerce-account ul.digital-downloads li .count {
  margin: 10px 0;
  border-bottom: none;
}

.woocommerce-account .woocommerce-EditAccountForm.edit-account fieldset {
  margin-top: 30px;
}

.woocommerce-account .woocommerce-EditAccountForm.edit-account fieldset legend {
  margin: 0;
  font-weight: bold;
}

.woocommerce-account .woocommerce-EditAccountForm.edit-account fieldset ~ p {
  padding: 30px 3px 0 3px;
  clear: both;
}

.woocommerce-account.woocommerce-view-order ul.wc-item-downloads {
  margin-bottom: 0;
}

.woocommerce-account .entry-content .woocommerce-notices-wrapper + h2 {
  margin-top: 0;
}

.woocommerce-account #account_display_name + span {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-size: 0.875rem;
}

.woocommerce-MyAccount-navigation ul {
  padding: 0;
  margin: 0 0 50px 0;
  color: var(--content-color);
  list-style: none;
}

.woocommerce-MyAccount-navigation ul li {
  margin: 0;
  border-bottom: 1px solid #f9f9f9;
}

.woocommerce-MyAccount-navigation ul li:last-child {
  margin-bottom: 0;
}

.woocommerce-MyAccount-navigation ul li a {
  position: relative;
  display: block;
  width: auto;
  padding: 4px 0;
  color: inherit !important;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none !important;
  opacity: 0.5;
}

.woocommerce-MyAccount-navigation ul li a:hover,
.woocommerce-MyAccount-navigation ul li.is-active a {
  opacity: 1;
}

.woocommerce-form.woocommerce-form-login.login
  .woocommerce-form__label.woocommerce-form__label-for-checkbox.inline {
  margin-left: 20px;
  line-height: 36px;
}

.woocommerce-orders .woocommerce-orders-table__cell-order-actions a.button {
  margin-right: 2px;
}

.woocommerce-account .addresses .title .edit {
  float: none;
  margin: 10px;
}

.pswp__caption .pswp__caption__center {
  text-align: center;
}

.woocommerce div.login,
.woocommerce div.register {
  padding: 0;
  border: none;
  font-size: 16px;
  font-size: 1rem;
}

.woocommerce .woocommerce-form-login .woocommerce-form-login__rememberme {
  margin-top: 5px;
  font-size: 14px;
  font-size: 0.875rem;
}

.woocommerce ul.cart_list,
.woocommerce ul.product_list_widget {
  list-style: none outside;
  padding: 0;
  margin: 0;
}

.woocommerce ul.cart_list li dl,
.woocommerce ul.product_list_widget li dl {
  display: none;
}

.woocommerce ul.cart_list .remove,
.woocommerce ul.product_list_widget .remove {
  position: absolute;
  top: 0;
}

.woocommerce ul.cart_list li:after,
.woocommerce ul.product_list_widget li:after {
  clear: both;
  content: " ";
  display: table;
}

.woocommerce.widget ul.cart_list li,
.woocommerce.widget ul.product_list_widget li {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 24px;
  font-size: 14px;
  font-size: 0.875rem;
}

.woocommerce.widget ul.cart_list li a,
.woocommerce.widget ul.product_list_widget li a {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  opacity: 1;
}

.no-touch .woocommerce.widget ul.cart_list li a:hover,
.no-touch .woocommerce.widget ul.product_list_widget li a:hover {
  color: var(--link-color-hover);
}

.woocommerce.widget ul.cart_list li .product-title,
.woocommerce.widget ul.product_list_widget li .product-title {
  display: block;
}

.woocommerce.widget ul.cart_list li img,
.woocommerce.widget ul.product_list_widget li img {
  float: left;
  margin: 0 18px 0 0;
  width: 84px;
}

.rtl .woocommerce.widget ul.cart_list li img,
.rtl .woocommerce.widget ul.product_list_widget li img {
  float: right;
  margin: 0 0 0 18px;
}

.woocommerce.widget ul.cart_list li .amount,
.woocommerce.widget ul.cart_list li .quantity,
.woocommerce.widget ul.product_list_widget li .amount,
.woocommerce.widget ul.product_list_widget li .quantity {
  color: inherit;
  font-size: 14px;
  font-size: 0.875rem;
}

.woocommerce.widget ul.cart_list li .star-rating,
.woocommerce.widget ul.product_list_widget li .star-rating {
  margin: 8px 0 5px;
  font-size: 12px;
  letter-spacing: 0.2em;
  width: 6.2em;
}

.woocommerce.widget ul.cart_list li del,
.woocommerce.widget ul.product_list_widget li del {
  color: inherit;
  font-weight: 400;
  opacity: 0.5;
}

.woocommerce.widget ul.cart_list li del span.woocommerce-Price-amount.amount,
.woocommerce.widget
  ul.product_list_widget
  li
  del
  span.woocommerce-Price-amount.amount {
  color: inherit;
}

.woocommerce.widget ul.cart_list li ins,
.woocommerce.widget ul.product_list_widget li ins {
  background: none;
  color: inherit;
  font-style: normal;
  text-decoration: none;
}

.woocommerce.widget.widget_shopping_cart .cart_list li {
  padding-left: 0;
  padding-right: 2em;
}

.woocommerce.widget.widget_shopping_cart .cart_list li a.remove {
  right: 0;
  left: auto;
}

.woocommerce.widget.widget_shopping_cart .total {
  padding: 20px 0;
  margin-bottom: 0;
  border-top: 1px solid var(--border-color);
}

.woocommerce.widget.widget_shopping_cart .buttons a {
  position: relative;
  z-index: 9;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  height: var(--btn-height);
  background: var(--btn-bg);
  border: none;
  border-radius: var(--btn-radius);
  color: var(--btn-color);
  font-family: var(--btn-font);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  letter-spacing: var(--btn-letter-spacing);
  text-transform: var(--btn-text-transform);
  line-height: 1;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  width: calc(50% - 5px);
  display: inline-block;
}

.no-touch
  .woocommerce.widget.widget_shopping_cart
  .buttons
  a:not(.cs-btn-underline):hover {
  background: #ab916c;
  color: #fff;
  border-color: #ab916c;
}

.woocommerce.widget.widget_shopping_cart .buttons a:not(:hover) {
  background: #1a1b1a;
  color: white;
}

.woocommerce.widget.widget_shopping_cart .buttons a.checkout {
  margin-right: 0;
  background: none;
  border: 2px solid;
  color: #1a1b1a;
  line-height: 38px;
}

.woocommerce.widget.widget_shopping_cart .buttons #woo_pp_ec_button {
  margin: 15px 0 0 0;
  width: 100%;
  text-align: center;
}

.woocommerce.widget.widget_product_search form {
  position: relative;
}

.woocommerce.widget.widget_product_search form:after {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 42px;
  height: 42px;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 42px;
  text-align: center;
  pointer-events: none;
}

.rtl .woocommerce.widget.widget_product_search form:after {
  right: auto;
  left: 0;
}

.woocommerce.widget.widget_product_search form input[type="submit"],
.woocommerce.widget.widget_product_search form button[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  box-shadow: none;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  font-size: 0;
  color: inherit;
}

.woocommerce.widget.widget_product_search form input[type="submit"]:before,
.woocommerce.widget.widget_product_search form button[type="submit"]:before {
  font-size: 14px;
  font-size: 0.875rem;
  content: "\f002";
  line-height: 40px;
}

.rtl .woocommerce.widget.widget_product_search form input[type="submit"],
.rtl .woocommerce.widget.widget_product_search form button[type="submit"] {
  right: auto;
  left: 0;
}

.woocommerce.widget.widget_product_categories ul,
.woocommerce.widget.widget_layered_nav ul {
  margin: 0;
  list-style: none;
}

.woocommerce.widget.widget_product_categories ul li,
.woocommerce.widget.widget_layered_nav ul li {
  position: relative;
  list-style: none;
  font-size: 14px;
  text-align: right;
  line-height: 30px;
}

.rtl .woocommerce.widget.widget_product_categories ul li,
.rtl .woocommerce.widget.widget_layered_nav ul li {
  text-align: left;
}

.woocommerce.widget.widget_product_categories ul li:not(:last-child),
.woocommerce.widget.widget_layered_nav ul li:not(:last-child) {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
}

.woocommerce.widget.widget_product_categories ul li a,
.woocommerce.widget.widget_layered_nav ul li a {
  float: left;
  width: 80%;
  font-size: 15px;
  line-height: 30px;
  text-align: left;
}

.rtl .woocommerce.widget.widget_product_categories ul li a,
.rtl .woocommerce.widget.widget_layered_nav ul li a {
  float: right;
  text-align: right;
}

.no-touch .woocommerce.widget.widget_product_categories ul li a:hover,
.no-touch .woocommerce.widget.widget_layered_nav ul li a:hover {
  color: var(--link-color-hover);
}

.woocommerce.widget.widget_product_categories ul li:after,
.woocommerce.widget.widget_layered_nav ul li:after {
  position: relative;
  display: block;
  content: "";
  clear: both;
}

.woocommerce.widget.widget_product_categories ul li ul,
.woocommerce.widget.widget_layered_nav ul li ul {
  margin-top: 7.5px;
  margin-left: 20px;
  clear: both;
}

.rtl .woocommerce.widget.widget_product_categories ul li ul,
.rtl .woocommerce.widget.widget_layered_nav ul li ul {
  margin-right: 20px;
  margin-left: 0;
}

.woocommerce.widget.widget_product_categories ul li ul li,
.woocommerce.widget.widget_layered_nav ul li ul li {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.woocommerce.widget.widget_product_categories ul li ul li:before,
.woocommerce.widget.widget_layered_nav ul li ul li:before {
  position: absolute;
  top: 1px;
  left: -20px;
  width: 20px;
  color: inherit;
  font-family: "ElegantIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\35";
  line-height: inherit;
  text-align: left;
  color: var(--primary-color);
}

.rtl .woocommerce.widget.widget_product_categories ul li ul li:before,
.rtl .woocommerce.widget.widget_layered_nav ul li ul li:before {
  right: -20px;
  left: auto;
  text-align: right;
  content: "\34";
}

.woocommerce.widget
  .woocommerce-widget-layered-nav-list
  .woocommerce-widget-layered-nav-list__item
  a,
.woocommerce.widget
  .woocommerce-widget-layered-nav-list
  .woocommerce-widget-layered-nav-list__item
  span {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.woocommerce.widget.widget_layered_nav_filters ul li {
  font-size: 14px;
  font-size: 0.875rem;
}

.woocommerce.widget.widget_layered_nav_filters ul li a:before {
  color: currentColor;
}

.woocommerce.widget.widget_product_tag_cloud {
  font-size: 0;
}

.woocommerce.widget.widget_product_tag_cloud .tagcloud {
  display: flex;
  flex-wrap: wrap;
  margin: -4px -3px;
}

.woocommerce.widget.widget_product_tag_cloud .tagcloud a {
  margin: 4px 3px;
  line-height: 28px;
  font-size: 14px !important;
  font-size: 0.875rem !important;
}

.woocommerce.widget.widget_product_tag_cloud .tagcloud {
  font-size: 0;
}

.woocommerce.widget.widget_price_filter
  .price_slider_wrapper
  .ui-widget-content {
  height: 10px;
  background: none;
  border: 0;
}

.woocommerce.widget.widget_price_filter
  .price_slider_wrapper
  .ui-widget-content:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 3px;
  background: #1a1b1a;
  content: "";
  opacity: 0.4;
}

.woocommerce.widget.widget_price_filter .price_slider_amount .button {
  float: left;
  --btn-height: 42px;
}

.woocommerce.widget.widget_price_filter .ui-slider {
  margin: 25px 5px 10px;
}

.woocommerce.widget.widget_price_filter .ui-slider .ui-slider-range {
  height: 3px;
}

.woocommerce.widget.widget_price_filter .ui-slider .ui-slider-range,
.woocommerce.widget.widget_price_filter .ui-slider .ui-slider-handle {
  background: #1a1b1a;
}

.woocommerce.widget.widget_price_filter .ui-slider .ui-slider-handle {
  position: absolute;
  top: -4px;
  z-index: 2;
  width: 10px;
  height: 10px;
  border-radius: 1em;
  cursor: ew-resize;
  outline: 0;
  margin-left: -0.5em;
  border: 0;
}

.woocommerce.widget.widget_price_filter .price_label {
  line-height: 42px;
}

.woocommerce.widget.widget_price_filter .price_slider_amount {
  text-align: right;
  line-height: 2.4;
  font-size: 0.8751em;
}

.woocommerce.widget.widget_rating_filter ul {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none outside;
}

.woocommerce.widget.widget_rating_filter ul li {
  padding-bottom: 0;
  text-align: right;
  line-height: 30px;
}

.woocommerce.widget.widget_rating_filter ul li a {
  display: block;
  padding: 0;
  width: 100%;
}

.woocommerce.widget.widget_rating_filter ul li .star-rating {
  position: relative;
  float: left;
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
  transform: translateY(6px);
  text-align: left;
}

.rtl .woocommerce.widget.widget_rating_filter ul li {
  text-align: left;
}

.rtl .woocommerce.widget.widget_rating_filter ul li .star-rating {
  float: right;
  text-align: right;
}

.site-header-cart,
.cs-mini-cart {
  position: relative;
}

.site-header-cart .cart-contents,
.cs-mini-cart .cart-contents {
  position: relative;
  display: block;
  width: 48px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  opacity: 1;
}

.site-header-cart .cart-icon:before,
.cs-mini-cart .cart-icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  font-weight: 900;
  font-size: 16px;
  content: "\f290";
  line-height: 40px;
}

.site-header-cart .cart-notification.has_item,
.cs-mini-cart .cart-notification.has_item {
  position: absolute;
  top: -5px;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--primary-color);
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.site-header-cart .widget_shopping_cart,
.cs-mini-cart .widget_shopping_cart {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: translateY(105%);
  -ms-transform: translateY(105%);
  transform: translateY(105%);
  z-index: -99;
  padding-top: 15px;
  margin: 0;
  background: none;
  font-size: 13px;
  font-size: 0.8125rem;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  will-change: opacity, transform;
}

.rtl .site-header-cart .widget_shopping_cart,
.rtl .cs-mini-cart .widget_shopping_cart {
  right: auto;
  left: 0;
}

.site-header-cart .widget_shopping_cart .widget_shopping_cart_content,
.cs-mini-cart .widget_shopping_cart .widget_shopping_cart_content {
  min-width: 280px;
  padding: 25px;
  margin: 0;
  background: var(--sub-menu-bg);
  color: var(--sub-menu-color);
  list-style: none;
}

.site-header-cart .widget_shopping_cart .widget_shopping_cart_content p,
.cs-mini-cart .widget_shopping_cart .widget_shopping_cart_content p {
  margin-bottom: 0;
}

.site-header-cart .widget_shopping_cart .woocommerce-mini-cart__empty-message,
.cs-mini-cart .widget_shopping_cart .woocommerce-mini-cart__empty-message {
  font-size: 14px;
  font-size: 0.875rem;
}

.site-header-cart
  .widget_shopping_cart
  .woocommerce-mini-cart__buttons.buttons
  a.button,
.cs-mini-cart
  .widget_shopping_cart
  .woocommerce-mini-cart__buttons.buttons
  a.button {
  padding: 0;
}

.site-header-cart
  .widget_shopping_cart
  .woocommerce-mini-cart__buttons.buttons
  #woo_pp_ec_button,
.cs-mini-cart
  .widget_shopping_cart
  .woocommerce-mini-cart__buttons.buttons
  #woo_pp_ec_button {
  margin: 15px 0 0 !important;
  width: 100%;
}

.site-header-cart .widget_shopping_cart .woocommerce-mini-cart__empty-message,
.cs-mini-cart .widget_shopping_cart .woocommerce-mini-cart__empty-message {
  display: block;
  text-align: center;
}

.site-header-cart .widget_shopping_cart .cart_list.product_list_widget,
.cs-mini-cart .widget_shopping_cart .cart_list.product_list_widget {
  width: 280px;
  max-height: 260px;
  margin-bottom: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.site-header-cart
  .widget_shopping_cart
  .cart_list.product_list_widget::-webkit-scrollbar,
.cs-mini-cart
  .widget_shopping_cart
  .cart_list.product_list_widget::-webkit-scrollbar {
  display: none;
}

.site-header-cart:hover .widget_shopping_cart,
.cs-mini-cart:hover .widget_shopping_cart {
  z-index: 99;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.dropdown-dark {
  --border-color: rgba(255, 255, 255, 0.15);
  --bg-color: var(--dark-bg-color);
  --text-color: var(--dark-text-color);
}

.dropdown-light {
  --border-color: rgba(0, 0, 0, 0.1);
  --bg-color: var(--light-bg-color);
  --text-color: var(--light-text-color);
}

.site-header-layout-default .site-header-cart {
  display: none;
}

.site-header-cart .cart-contents,
.elementor-widget-pt_mini_cart:last-child .cart-contents {
  margin-right: -15px;
}

.rtl .site-header-cart .cart-contents,
.rtl .elementor-widget-pt_mini_cart:last-child .cart-contents {
  margin-right: 0;
  margin-left: -15px;
}

.entry-content .cs-mini-cart a {
  color: inherit;
}

.no-touch .entry-content .cs-mini-cart a.cart-contents:hover {
  color: inherit;
}

.wapf-wrapper .wapf-field-label {
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  text-transform: uppercase;
}

.wapf-wrapper .wapf-field-input > * {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-size: 0.875rem;
}

.wapf-wrapper .wapf-field-input > * > * {
  width: 33.3333%;
  padding-right: 20px;
  margin-bottom: 8px;
}

.wapf-wrapper .wapf-field-input > * > *:last-child {
  padding-right: 0;
}

.woocommerce.single.single-product .summary.entry-summary .wapf-product-totals {
  margin-bottom: 2em;
}

.woocommerce.single.single-product
  .summary.entry-summary
  .wapf-product-totals
  .price {
  margin-bottom: 0;
  font-size: 16px;
  font-size: 1rem;
}

.woocommerce.single.single-product
  .summary.entry-summary
  .wapf-product-totals
  .wapf--inner {
  padding: 0;
}

.woocommerce.single.single-product
  .summary.entry-summary
  .wapf-product-totals
  .wapf--inner
  > div {
  padding: 16px 0;
  border-bottom: 1px solid var(--border-color);
}

.woocommerce.single.single-product
  .summary.entry-summary
  .wapf-product-totals
  .wapf--inner
  > div:last-child {
  border-bottom: none;
}

.woocommerce.single.single-product
  .summary.entry-summary
  .wapf-product-totals
  div
  span:first-child {
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  text-transform: uppercase;
}

.woocommerce ul.products.cs-food-menu li.product {
  text-align: left;
}

.rtl .woocommerce ul.products.cs-food-menu li.product {
  text-align: right;
}

.woocommerce ul.products.cs-food-menu .product-image.cs-food-menu-img {
  margin-bottom: 0;
}

.woocommerce ul.products.cs-food-menu .price.cs-food-menu-price {
  display: table-cell;
  margin-bottom: 0;
}

.woocommerce ul.products.cs-food-menu .cs-food-menu-details p {
  margin-bottom: 0;
}

.woocommerce
  ul.products.cs-food-menu
  .woocommerce-loop-product__title.cs-food-menu-title {
  padding: 0 15px 0 0;
  margin: 0;
}

.rtl
  .woocommerce
  ul.products.cs-food-menu
  .woocommerce-loop-product__title.cs-food-menu-title {
  padding: 0 0 0 15px;
}

.woocommerce
  ul.products.cs-food-menu
  .cs-food-menu-footer
  .cs-food-menu-details {
  float: left;
  max-width: calc(100% - 150px);
}

.rtl
  .woocommerce
  ul.products.cs-food-menu
  .cs-food-menu-footer
  .cs-food-menu-details {
  float: right;
}

.woocommerce ul.products.cs-food-menu .cs-food-menu-footer .button,
.woocommerce ul.products.cs-food-menu .cs-food-menu-footer a.added_to_cart {
  float: right;
  margin: 1em 0 0;
  padding: 0 12px;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 26px;
}

.no-touch .woocommerce ul.products.cs-food-menu .cs-food-menu-footer .button,
.no-touch
  .woocommerce
  ul.products.cs-food-menu
  .cs-food-menu-footer
  a.added_to_cart {
  opacity: 0;
}

.rtl .woocommerce ul.products.cs-food-menu .cs-food-menu-footer .button,
.rtl
  .woocommerce
  ul.products.cs-food-menu
  .cs-food-menu-footer
  a.added_to_cart {
  float: left;
}

.woocommerce ul.products.cs-food-menu .cs-food-menu-footer a.added_to_cart {
  color: var(--btn-color);
}

.no-touch
  .woocommerce
  ul.products.cs-food-menu
  .cs-food-menu-footer
  a.added_to_cart:hover {
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
  background: #ab916c;
  color: #fff;
}

.woocommerce ul.products.cs-food-menu .cs-food-menu-footer:after {
  position: relative;
  display: block;
  clear: both;
  content: "";
}

.no-touch
  .woocommerce
  ul.products.cs-food-menu
  li:hover
  .cs-food-menu-footer
  .button,
.no-touch
  .woocommerce
  ul.products.cs-food-menu
  li:hover
  .cs-food-menu-footer
  a.added_to_cart {
  opacity: 1;
}

.woocommerce ul.products.cs-food-menu li.product .star-rating {
  margin: 8px 0 3px;
}

.woocommerce
  ul.products.cs-food-menu
  .cs-food-menu-title
  .menu-label.out-of-stock {
  background: #1a1b1a;
  color: white;
}

.woocommerce ul.products.cs-food-menu .cs-food-menu-price del {
  font-size: inherit;
}

.woocommerce ul.products.cs-food-menu.food-menu-style-5 .cs-food-menu-main {
  display: block;
}

.woocommerce ul.products.cs-food-menu.food-menu-style-5 .cs-food-menu-img {
  margin: 0;
}

.woocommerce
  ul.products.cs-food-menu.food-menu-style-5
  .price.cs-food-menu-price {
  text-align: right;
}

.rtl
  .woocommerce
  ul.products.cs-food-menu.food-menu-style-5
  .price.cs-food-menu-price {
  text-align: left;
}

.woocommerce
  ul.products.cs-food-menu.food-menu-style-5
  li.product
  .star-rating {
  margin-top: 12px;
}

@media screen and (min-width: 600px) {
  .woocommerce ul.products {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  .woocommerce ul.products[class*="columns-"] {
    margin-left: calc(0px - var(--product-gap));
  }

  .woocommerce ul.products[class*="columns-"] li.product {
    margin-right: 0;
    margin-left: var(--product-gap);
    width: calc(50% - var(--product-gap));
  }

  .woocommerce ul.products[class*="columns-"] li.product:nth-child(-n + 2) {
    margin-top: 0 !important;
  }

  .woocommerce ul.products.columns-1 li.product {
    width: 100%;
  }

  .woocommerce ul.products.layout-list li.product {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .woocommerce.single.single-product .summary.entry-summary .entry-title {
    font-size: 36px;
    font-size: 2.25rem;
  }

  .woocommerce #review_form #respond .comment-form {
    margin: 0 -15px;
  }

  .woocommerce #review_form #respond .comment-form > * {
    padding: 0 15px;
  }

  .woocommerce #review_form #respond .comment-form .comment-form-author,
  .woocommerce #review_form #respond .comment-form .comment-form-email,
  .woocommerce #review_form #respond .comment-form .comment-form-url {
    float: left;
    width: 33.333333%;
    margin-bottom: 30px;
  }

  .woocommerce
    #review_form
    #respond
    .comment-form
    .comment-form-cookies-consent {
    clear: both;
    margin-bottom: 30px;
  }

  .woocommerce.rtl #review_form #respond .comment-form .comment-form-author,
  .woocommerce.rtl #review_form #respond .comment-form .comment-form-email,
  .woocommerce.rtl #review_form #respond .comment-form .comment-form-url {
    float: right;
  }

  .woocommerce div.product div.woocommerce-product-gallery .flex-viewport {
    margin-left: 150px;
  }

  .woocommerce div.product div.woocommerce-product-gallery .flex-control-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 125px;
    max-height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }

  .woocommerce
    div.product
    div.woocommerce-product-gallery
    .flex-control-nav::-webkit-scrollbar {
    display: none;
  }

  .woocommerce
    div.product
    div.woocommerce-product-gallery
    .flex-control-nav
    li {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }

  .woocommerce
    div.product
    div.woocommerce-product-gallery
    .flex-control-nav
    li:last-child {
    margin-bottom: 0;
  }

  .rtl.woocommerce div.product div.woocommerce-product-gallery .flex-viewport {
    margin-right: 150px;
    margin-left: 0;
  }

  .rtl.woocommerce
    div.product
    div.woocommerce-product-gallery
    .flex-control-nav {
    right: 0;
    left: auto;
  }
}

@media screen and (min-width: 769px) {
  .woocommerce ul.products.columns-1 li.product:nth-child(2) {
    margin-top: var(--product-gap) !important;
  }

  .woocommerce ul.products.columns-3 li.product,
  .woocommerce ul.products.columns-6 li.product {
    width: calc(33.333333% - var(--product-gap));
  }

  .woocommerce ul.products.columns-3 li.product:nth-child(-n + 3),
  .woocommerce ul.products.columns-6 li.product:nth-child(-n + 3) {
    margin-top: 0 !important;
  }

  .woocommerce ul.products.columns-4 li.product {
    width: calc(25% - var(--product-gap));
  }

  .woocommerce ul.products.columns-4 li.product:nth-child(-n + 4) {
    margin-top: 0 !important;
  }

  .woocommerce ul.products.columns-5 li.product {
    width: calc(20% - var(--product-gap));
  }

  .woocommerce ul.products.columns-5 li.product:nth-child(-n + 5) {
    margin-top: 0 !important;
  }

  .woocommerce ul.products.layout-list {
    margin-right: 0;
    margin-left: 0;
  }

  .woocommerce ul.products.layout-list li.product {
    float: none;
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: 0;
  }

  .woocommerce ul.products.layout-list li.product .button,
  .woocommerce ul.products.layout-list li.product .added_to_cart {
    width: fit-content;
    padding: 0 48px;
  }

  .woocommerce ul.products.layout-list li.product .cs-product-content {
    padding: 40px;
  }

  .woocommerce ul.products.layout-list li.product .price-rating {
    flex-direction: column;
    align-items: flex-start;
  }

  .woocommerce ul.products.layout-list li.product .price-rating .star-rating {
    margin-top: 12px;
  }

  .woocommerce ul.products.layout-list .product-image,
  .woocommerce ul.products.layout-list .cs-product-content {
    width: 50%;
  }

  .woocommerce
    table.shop_table.cart
    td.product-quantity
    .quantity.hidden
    ~ .quantity {
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .up-sells.upsells.products > h2,
  .related.products > h2 {
    font-size: 30px;
    font-size: 1.875rem;
  }

  .woocommerce #reviews .comment-reply-title {
    font-size: 28px;
    font-size: 1.75rem;
  }

  .woocommerce div.checkout_coupon,
  .woocommerce-page form.checkout_coupon {
    max-width: 50%;
  }

  .woocommerce-MyAccount-navigation ul {
    padding: 0 40px 0 0;
  }

  .woocommerce ul.products.columns-6 li.product {
    width: calc(16.6666% - var(--product-gap));
  }

  .woocommerce ul.products.columns-6 li.product:nth-child(-n + 6) {
    margin-top: 0 !important;
  }

  .woocommerce ul.products.cs-food-menu.food-menu-style-1 .cs-food-menu-item {
    padding-bottom: 30px;
  }

  .woocommerce ul.products.cs-food-menu.food-menu-style-1 li {
    margin-top: 30px;
  }

  .woocommerce
    ul.products.cs-food-menu.food-menu-style-1.columns-1
    li.product:nth-child(2) {
    margin-top: 30px !important;
  }
}

@media screen and (min-width: 1200px) {
  .no-touch
    .site-header.site-header-layout-default
    .site-header-cart
    + .menu-toggle {
    margin-left: 0;
  }

  .site-header-layout-default .site-header-cart {
    display: block;
  }

  .woocommerce ul.products {
    --product-gap: 50px;
  }

  .woocommerce.single.single-product
    .woocommerce-tabs
    + .up-sells.upsells.products,
  .woocommerce.single.single-product .woocommerce-tabs + .related.products {
    padding-top: 60px;
  }

  .woocommerce-checkout #customer_details {
    width: 45%;
    float: left;
    margin-left: 100px;
  }

  .woocommerce-checkout #order_review_heading,
  .woocommerce-checkout .woocommerce-checkout-review-order {
    width: 45%;
    float: right;
  }

  .woocommerce-checkout #order_review_heading {
    margin-top: 0;
  }

  .woocommerce-MyAccount-navigation ul {
    padding: 0 80px 0 0;
  }

  .woocommerce
    .site-content[class*="with-sidebar"]
    div.product
    div.woocommerce-product-gallery
    .flex-viewport {
    margin-left: 105px;
  }

  .woocommerce
    .site-content[class*="with-sidebar"]
    div.product
    div.woocommerce-product-gallery
    .flex-control-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
  }

  .woocommerce-cart .woocommerce-cart-form {
    float: left;
    width: calc(100% - 470px);
  }

  .woocommerce-cart .cart-collaterals {
    float: right;
    width: 400px;
  }
}

@media screen and (max-width: 1366px) {
  .woocommerce table.shop_table.cart th.product-thumbnail,
  .woocommerce table.shop_table.cart td.product-thumbnail {
    width: 80px;
  }
}

@media screen and (max-width: 1023px) {
  .woocommerce ul.order_details li {
    margin-bottom: 30px;
  }

  .woocommerce ul.products.cs-food-menu:not(.food-menu-style-5) li {
    width: 100%;
  }

  .woocommerce #content div.product div.images,
  .woocommerce #content div.product div.summary,
  .woocommerce div.product div.images,
  .woocommerce div.product div.summary,
  .woocommerce-page #content div.product div.images,
  .woocommerce-page #content div.product div.summary,
  .woocommerce-page div.product div.images,
  .woocommerce-page div.product div.summary {
    float: none;
    width: 100%;
  }
}

@media screen and (max-width: 1199px) {
  .woocommerce-page .cart-collaterals + .cross-sells h2 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1199px) and (min-width: 1024px) {
  .woocommerce div.product div.woocommerce-product-gallery .flex-viewport {
    margin-left: 105px;
  }

  .woocommerce div.product div.woocommerce-product-gallery .flex-control-nav {
    width: 80px;
  }

  .rtl.woocommerce div.product div.woocommerce-product-gallery .flex-viewport {
    margin-right: 105px;
    margin-left: 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .woocommerce
    ul.products.cs-food-menu:not(.food-menu-style-5)
    li.product:nth-child(2) {
    margin-top: var(--product-gap) !important;
  }

  .woocommerce ul.products.cs-food-menu.food-menu-style-1 .cs-food-menu-item {
    padding-bottom: 35px;
  }

  .woocommerce
    ul.products.columns-1.cs-food-menu.food-menu-style-1
    li.product:nth-child(2) {
    margin-top: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .woocommerce-page div.product div.images,
  .woocommerce-page div.product div.summary {
    float: none;
    width: 100%;
  }

  .woocommerce table.shop_table_responsive thead,
  .woocommerce-page table.shop_table_responsive thead {
    display: none;
  }

  .woocommerce table.shop_table_responsive tr,
  .woocommerce-page table.shop_table_responsive tr {
    display: block;
  }

  .woocommerce table.shop_table_responsive tr td,
  .woocommerce-page table.shop_table_responsive tr td {
    display: block;
    text-align: right !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl.woocommerce table.shop_table_responsive tr td,
  .rtl.woocommerce-page table.shop_table_responsive tr td {
    text-align: left !important;
  }

  .woocommerce #content table.cart .product-thumbnail,
  .woocommerce table.cart .product-thumbnail,
  .woocommerce-page #content table.cart .product-thumbnail,
  .woocommerce-page table.cart .product-thumbnail {
    display: none;
  }

  .woocommerce table.cart .cart_item td:not(:last-child) {
    border-bottom: none;
  }

  .woocommerce table.shop_table_responsive tr:nth-child(2n) td,
  .woocommerce-page table.shop_table_responsive tr:nth-child(2n) td {
    background: none;
  }

  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    content: attr(data-title) " ";
    font-weight: 600;
    float: left;
  }

  .rtl.woocommerce table.shop_table_responsive tr td::before,
  .rtl.woocommerce-page table.shop_table_responsive tr td::before {
    float: right;
  }

  .woocommerce-page.woocommerce-cart table.cart td.actions #coupon_code,
  .woocommerce-page.woocommerce-cart table.cart td.actions .coupon .button {
    width: 48%;
  }

  .woocommerce-page.woocommerce-cart
    table.cart
    td.actions
    .input-text55
    + .button {
    float: right;
  }

  .woocommerce-page.woocommerce-cart table.cart td.actions .button {
    display: block;
    width: 100%;
  }

  .woocommerce-page.woocommerce-cart table.cart td.actions .coupon {
    float: none;
    padding-bottom: 0.5em;
  }

  .woocommerce-page.woocommerce-cart table.cart td.actions .coupon:after {
    display: table;
    content: " ";
    clear: both;
  }

  .woocommerce table.shop_table_responsive tbody th,
  .woocommerce-page table.shop_table_responsive tbody th {
    display: none;
  }

  .woocommerce .cart-collaterals .cart_totals,
  .woocommerce .cart-collaterals .cross-sells,
  .woocommerce .cart-collaterals .shipping_calculator,
  .woocommerce-page .cart-collaterals .cart_totals,
  .woocommerce-page .cart-collaterals .cross-sells,
  .woocommerce-page .cart-collaterals .shipping_calculator {
    width: 100%;
    float: none;
    text-align: left;
  }

  .rtl.woocommerce .cart-collaterals .cart_totals,
  .rtl.woocommerce .cart-collaterals .cross-sells,
  .rtl.woocommerce .cart-collaterals .shipping_calculator,
  .rtl.woocommerce-page .cart-collaterals .cart_totals,
  .rtl.woocommerce-page .cart-collaterals .cross-sells,
  .rtl.woocommerce-page .cart-collaterals .shipping_calculator {
    text-align: right;
  }

  .woocommerce .cart-collaterals .cross-sells,
  .woocommerce-page .cart-collaterals .cross-sells {
    margin-bottom: var(--spacing);
  }

  .woocommerce .col2-set .col-2,
  .woocommerce-page .col2-set .col-2 {
    margin-top: var(--spacing);
  }
}

@media screen and (max-width: 767px) {
  .woocommerce-page div.product div.images .flex-control-thumbs li {
    float: left;
    width: 25%;
  }

  .woocommerce.single.single-product .woocommerce-tabs ul.tabs li a {
    font-size: 14px;
    font-size: 0.875rem;
  }

  .woocommerce-error,
  .woocommerce-info,
  .woocommerce-message {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 2em;
    line-height: 1.3;
  }

  .woocommerce-error:before,
  .woocommerce-info:before,
  .woocommerce-message:before {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 auto 16px;
  }

  .woocommerce-error a.button,
  .woocommerce-info a.button,
  .woocommerce-message a.button {
    order: 2;
    margin-top: 16px;
  }

  .woocommerce-form-login-toggle .woocommerce-info,
  .woocommerce-form-coupon-toggle .woocommerce-info {
    text-align: revert;
  }

  #page .checkout_coupon .button[name="apply_coupon"] {
    padding: 0 15px;
  }

  #customer_login .u-column1.col-55 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 599px) {
  .woocommerce .woocommerce-ordering,
  .woocommerce .woocommerce-result-count,
  .woocommerce-page .woocommerce-ordering,
  .woocommerce-page .woocommerce-result-count,
  .woocommerce div.checkout_coupon .form-row-first,
  .woocommerce-page form.checkout_coupon .form-row-first,
  .woocommerce div.checkout_coupon .form-row-last,
  .woocommerce-page form.checkout_coupon .form-row-last {
    float: none !important;
    width: 100% !important;
  }

  .woocommerce .woocommerce-result-count,
  .woocommerce-page .woocommerce-result-count {
    margin-bottom: 0;
  }

  .woocommerce .woocommerce-ordering select {
    width: 100%;
  }

  .woocommerce ul.products[class*="columns-"] li.product,
  .woocommerce-page ul.products[class*="columns-"] li.product {
    width: 100%;
  }

  .woocommerce.single.single-product .woocommerce-tabs ul.tabs li {
    padding: 0 15px;
  }

  .woocommerce.single.single-product
    div.product
    form.cart:not(.variations_form)
    .button.single_add_to_cart_button,
  .woocommerce.single.single-product
    .woocommerce-variation-add-to-cart
    .button.single_add_to_cart_button {
    flex: 1;
    max-width: 100%;
    padding: 0;
  }

  .woocommerce .quantity .qty {
    width: 126px;
  }

  .woocommerce .cart .button[name="apply_coupon"] {
    padding: 0;
  }

  .woocommerce-checkout #order_review_heading,
  .woocommerce-checkout .woocommerce-checkout-review-order table,
  #add_payment_method #payment,
  .woocommerce-cart #payment,
  .woocommerce-checkout #payment {
    padding-right: 20px;
    padding-left: 20px;
  }

  .woocommerce ul.order_details li {
    float: none;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-right: none;
    border-bottom: 1px dashed #d3ced2;
  }

  .woocommerce.single.single-product
    div.product
    form.cart.grouped_form
    .group_table {
    padding: 20px;
  }

  .woocommerce
    ul.products.cs-food-menu
    .cs-food-menu-footer
    .cs-food-menu-details,
  .woocommerce ul.products.cs-food-menu .cs-food-menu-footer .button,
  .woocommerce ul.products.cs-food-menu .cs-food-menu-footer a.added_to_cart {
    float: none;
    max-width: none;
  }

  .woocommerce ul.products.cs-food-menu .cs-food-menu-item,
  .woocommerce ul.products.cs-food-menu .cs-food-menu-main {
    display: block;
  }

  .woocommerce ul.products.cs-food-menu .product-image.cs-food-menu-img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .woocommerce ul.products.cs-food-menu.food-menu-style-1 .cs-food-menu-item {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 389px) {
  .woocommerce.single.single-product div.product form.cart div.quantity {
    width: 100%;
    margin: 0 0 12px;
  }

  .woocommerce.single.single-product div.product form.cart div.quantity .qty {
    width: 100%;
  }
}
.woocommerce div .form-row55 {
  margin-bottom: 30px;
}
.woocommerce div .form-row-first,
.woocommerce-page form .form-row-first {
  float: left;
}
.woocommerce div .form-row-first,
.woocommerce div .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
  width: 47%;
  overflow: visible;
}
.woocommerce div .form-row55 {
  padding: 3px;
  margin: 0 0 6px;
}
form * {
  outline: 0;
}
p {
  margin: 0 auto 30px;
  font-size: inherit;
}
.woocommerce div .form-row55 input.input-text55,
.woocommerce div .form-row55 textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  outline: 0;
  line-height: normal;
}
.woocommerce div .form-row55 .input-text55,
.woocommerce-page form .form-row55 .input-text55 {
  box-sizing: border-box;
  width: 100%;
}
