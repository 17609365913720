/* .register {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rName {
  font-size: 20px;
  margin-bottom: 10px;
}

.rContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rInput {
  height: 30px;
  padding: 10px;
}

.rButton {
  border: none;
  padding: 10px 20px;
  background-color: #70bbe9;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.rButton:disabled {
  background-color: #70bbe98c;
  cursor: not-allowed;
} */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

::selection{
  background: #70bbe9;
}
.container1 {
  width: 340px;
 
  align-self: center;
  /* margin-top: 50px; */
}
.input-container {
  position: relative;
}

.input-container input {
  padding-right: 30px; /* Adjust the padding based on the icon size */
}

.input-container i {
  position: absolute;
  top: 50%;
  right: 2px; 
  transform: translateY(-50%);
  cursor: pointer;
}
.wrapper{
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.1);
}
.wrapper .title{
  height: 90px;
  background: #70bbe9;
  border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper form{
  padding: 30px 25px 25px 25px;
}
.wrapper form .row{
  height: 45px;
  margin-bottom: 15px;
  position: relative;
}
.wrapper form .row input{
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 60px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  font-size: 16px;
  transition: all 0.3s ease;
}
form .row input:focus{
  border-color: #70bbe9;
  box-shadow: inset 0px 0px 2px 2px rgba(26,188,156,0.25);
}
form .row input::placeholder{
  color: #999;
}
.wrapper form .row i{
  position: absolute;
  width: 47px;
  height: 100%;
  color: #fff;
  font-size: 18px;
  background: #70bbe9;
  border: 1px solid #70bbe9;
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper form .pass{
  margin: -8px 0 20px 0;
}
.wrapper form .pass a{
  color: #70bbe9;
  font-size: 17px;
  text-decoration: none;
}
.wrapper form .pass a:hover{
  text-decoration: underline;
}
.wrapper form .button input{
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding-left: 0px;
  background: #70bbe9;
  border: 1px solid #70bbe9;
  cursor: pointer;
}
form .button input:hover{
  background: #12876f;
}
.wrapper form .signup-link{
  text-align: center;
  margin-top: 20px;
  font-size: 17px;
}
.wrapper form .signup-link a{
  color: #70bbe9;
  text-decoration: none;
}
form .signup-link a:hover{
  text-decoration: underline;
}