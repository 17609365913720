.beachSliderContainer{
    width: 100%;
    margin:20px auto;
}
.propertySlider{
    margin-top: 5px;
}
.propertySliderText{
    font-size: 24px;
    font-weight: 700;
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
}
.exploreIndiaSliderLightText{
    margin-top: 5px;
    font-size: 18px;
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    font-weight: 400;
    color: gray;
}
.SliderCard{
    /* height: 15rem; */
    padding: 20px !important;

    
}
.SliderCard img{
    
    width: 88%;
    border-radius: 10px;
    cursor: pointer;
    object-fit: cover;
}
.sliderCardDestination{
    font-size: 16px;
    font-weight: 700;
    margin-top: 7px;
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
}
.sliderCardProperty{
    margin-top: 5px;
    font-size: 14px;
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    font-weight: 400;
    color: rgb(85, 84, 84);
}

@media only screen and (max-width: 1100px) {
    .propertySliderContainer{
        width: 100%;
    }
}

/* @media only screen and (max-width: 700px) {
    .propertySliderContainer{
        width: 100%;
    }
} */