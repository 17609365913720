.homeContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 30px;
}

.homeTitle {
    width: 1024px;
    font-size: 20px;
}
.slide-container {
  width:100%;display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center;
  margin-top:20px
}
  
  .sticky-button {
    position: fixed;
    bottom: 14%;
    right: 0px;
    transform: translateY(50%);
    padding: 15px;
    background-color: #FCCB43;
    border-top-right-radius: 50%;
    border-bottom-left-radius: 50%;
    color: black;
    cursor: pointer;
  }
  .adv {
    margin-left: 20px;
  }
@media only screen and (max-width: 768px) {

    .homeContainer {
        align-items: flex-start;
    }
    .slide-container {
     
      justify-content:center;
     
      
    }
    .section-title {
      margin-top: 10px;
    }
    .sectionnone{
      display: none;
    }
    .offer-card-group{
      display: none;

    }
    .adv {
      margin-left: 0px;
    }
}