.quickAndEasyTripPlanContainer{
    width: 95%;
    /* margin: 20px auto; */
}
.quicAndEasyBoldText{
    font-size: 24px;
    font-weight: 700;
    color:black;
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
}
.quicAndEasyLightText{
    margin-top: 5px;
    font-size: 18px;
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    font-weight: 400;
    color: black;
}
.quickAndEasyButtons{
    margin-top: 20px;
}

.quickAndEasyButtons button{
    padding:13px 17px;
    border-radius: 25px;
    border: none;
    background-color: white;
    margin-right: 10px;
    cursor: pointer;
}
.quickAndEasyButtons button:hover{
    background-color: rgb(241, 241, 241);
}
#active{
    border: 1px solid blue;
    background-color: #e7edf3;
}

@media only screen and (max-width: 1100px) {
    .quickAndEasyTripPlanContainer{
        width: 100%;
    }
}