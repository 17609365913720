.mpp-list-next-property.bui-container {
    color: #fff;
    left: 0;
    max-width: 470px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%)
}

.mpp-list-next-property__button-container {
    justify-content: center
}

.mpp-list-next-property__secondary-button {
    background-color: transparent;
    border-color: #fff
}

.mpp-list-next-property__secondary-button:active,.mpp-list-next-property__secondary-button:hover {
    background-color:#70bbe9;
    border-color: #fff
}

.automation-sync-status {
    background: linear-gradient(90deg,hsla(0,0%,100%,.3),transparent);
    bottom: 0;
    color: #fff;
    left: 0;
    max-width: 400px;
    padding: 16px;
    position: fixed;
    text-align: left;
    width: 400px
}

.automation-sync-status-json {
    white-space: pre
}

.synchronization-screen {
    background:#70bbe9;
    height: 100%;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1100
}

.synchronization-content {
    position: absolute;
    top: 50%;
    width: 100%
}

.synchronization-logo img {
    margin: 75px 0 0 -20px
}

.copies-loop {
    color: #fff;
    font-size: 16px;
    opacity: .5
}

.synchronization-message {
    color: #fff;
    font-size: 20px;
    margin-top: 15px
}

@keyframes carousel {
    0% {
        animation-timing-function: ease-in;
        margin-left: 163px;
        opacity: .01;
        transform: scale(.8)
    }

    30% {
        animation-timing-function: ease-in-out;
        margin-left: 15px;
        opacity: 1;
        transform: scale(1)
    }

    70% {
        animation-timing-function: ease-out;
        margin-left: -15xp;
        opacity: 1;
        transform: scale(1)
    }

    to {
        margin-left: -163px;
        opacity: .01;
        transform: scale(.8)
    }
}

.icons-carousel {
    animation: carousel .85s;
    display: inline-block
}

.page-loading {
    background: #fff;
    height: 100vh;
    left: 0;
    opacity: .3;
    padding-top: 40vh;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100vw;
    z-index: 100
}

.join_logo {
    padding-left: 25px
}

.join_logo img {
    margin: 22px 0 20px;
    width: 130px
}

.join-section.join-section--header.join-section--header--account {
    background-color:#70bbe9;
    height: 65px;
    position: relative
}

.my-properties_access {
    border: none;
    cursor: pointer;
    height: 100%;
    transition: background-color .3s ease
}

.is_right_to_left .my-properties_access {
    left: 60px;
    right: inherit
}

.join-header-help {
    height: 100%
}

.is_right_to_left .join-header-help {
    right: inherit
}

a.join-header-help_link {
    color: #fff;
    display: block;
    height: 100%;
    line-height: 23px;
    padding: 20px 0 0;
    position: relative;
    text-decoration: none;
    width: 23px
}

a.join-header-help_link span {
    display: none
}

@media (min-width: 1024px) {
    a.join-header-help_link {
        padding:20px 28px 0 0;
        width: inherit
    }

    a.join-header-help_link span {
        display: inline-block
    }
}

.join-header-help_link:active,.join-header-help_link:hover,.join-header-help_link:visited {
    color: #fff;
    text-decoration: none
}

.join-header-help_link:after {
    background: var(--bui_color_accent_background);
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #fff;
    color: #fff;
    content: "?";
    display: block;
    font-size: 16px;
    font-weight: 400;
    height: 23px;
    line-height: 23px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 20px;
    width: 23px
}

.join-header-help:hover,.my-properties_access:hover {
    border-bottom: 2px solid #70bbe9;
    transition: background-color .3s ease
}

.my-properties_access_icon {
    background-image: url(https://q.bstatic.com/static/img/join/icon_user_login_white.png);
    background-size: cover;
    display: inline-block;
    height: 23px;
    margin-top: 20px;
    width: 23px
}

.is_right_to_left .my-properties_access_icon {
    left: 50px;
    right: inherit
}

.my-properties_access_button {
    height: 100%
}

.my-properties_menu.my-properties_menu--hide {
    display: none
}

.my-properties_menu {
    background-color: #fff;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: 1px 1px 3px var(--bui_color_background);
    margin-top: 1px;
    position: absolute;
    right: 0;
    top: 100%;
    transition: background-color .3s ease;
    width: 250px;
    z-index: 10
}

.is_right_to_left .my-properties_menu {
    left: 50px;
    right: inherit
}

.my-properties_menu li {
    margin: 0
}

.my-properties_menu ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.my-properties_menu ul li a {
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    color: var(--bui_color_foreground);
    display: flex;
    padding: var(--bui_spacing_3x) var(--bui_spacing_4x);
    text-decoration: none;
    width: 100%
}

.my-properties_menu_icon {
    align-items: center;
    display: flex;
    margin-right: var(--bui_spacing_4x)
}

.my-properties_menu ul li a:hover {
    background-color: var(--bui_color_highlighted_alt);
    text-decoration: none
}

.my-properties_menu ul li a:last-child {
    border: none
}

.join-header__ctas {
    display: flex;
    height: 63px;
    position: absolute;
    right: 50px;
    top: 0
}

.join-header__ctas>* {
    margin-left: var(--bui_spacing_4x)
}

.joinapp-lang__container {
    box-sizing: border-box;
    height: 100%
}

.joinapp-lang-link {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    margin-top: 15px;
    padding-top: 6px;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 3.75em
}

.joinapp-lang-link:active,.joinapp-lang-link:hover {
    outline: 0
}

.joinapp-language-flag {
    background-position: 0 0;
    background-size: cover;
    display: inline-block;
    height: 20px;
    width: 20px
}

.joinapp__lang-selector-box {
    border: 10px solid transparent;
    cursor: pointer;
    display: block;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    height: 3.5em;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 3.75em
}

.joinapp-property-details {
    display: inline-block;
    vertical-align: middle
}

.joinapp-property-details-dropdown {
    display: none;
    margin-left: 0;
    margin-right: 0
}

@media (min-width: 1024px) {
    .joinapp-property-details-dropdown {
        display:block
    }
}

.joinapp-property-details__address,.joinapp-property-details__name {
    color: #fff;
    margin: 0;
    max-width: 400px ;
    overflow: hidden;
    padding: 0;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap
}

.joinapp-property-details__name {
    font-weight: 700;
    margin-top: 10px
}

.joinapp-property-details-dropdown__trigger {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none
}

.joinapp-language-flag_ar {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/ar.png)
}

.joinapp-language-flag_bg {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/bg.png)
}

.joinapp-language-flag_ca {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/ca.png)
}

.joinapp-language-flag_cs {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/cz.png)
}

.joinapp-language-flag_da {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/dk.png)
}

.joinapp-language-flag_de {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/de.png)
}

.joinapp-language-flag_el {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/gr.png)
}

.joinapp-language-flag_en,.joinapp-language-flag_gb {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/gb.png)
}

.joinapp-language-flag_en-us,.joinapp-language-flag_xu {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/us.png)
}

.joinapp-language-flag_es {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/es.png)
}

.joinapp-language-flag_et {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/ee.png)
}

.joinapp-language-flag_fi {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/fi.png)
}

.joinapp-language-flag_fr {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/fr.png)
}

.joinapp-language-flag_he {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/il.png)
}

.joinapp-language-flag_hi {
    background-image: url(https://static.booking.com/static/img/flags/24/in.png)
}

.joinapp-language-flag_hr {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/hr.png)
}

.joinapp-language-flag_hu {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/hu.png)
}

.joinapp-language-flag_id {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/id.png)
}

.joinapp-language-flag_is {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/is.png)
}

.joinapp-language-flag_it {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/it.png)
}

.joinapp-language-flag_ja {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/jp.png)
}

.joinapp-language-flag_ka {
    background-image: url(https://static.booking.com/static/img/flags/24/ge.png)
}

.joinapp-language-flag_ko {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/kr.png)
}

.joinapp-language-flag_lt {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/lt.png)
}

.joinapp-language-flag_lv {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/lv.png)
}

.joinapp-language-flag_ms {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/my.png)
}

.joinapp-language-flag_nl {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/nl.png)
}

.joinapp-language-flag_no {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/no.png)
}

.joinapp-language-flag_pl {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/pl.png)
}

.joinapp-language-flag_xb {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/br.png)
}

.joinapp-language-flag_pt {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/pt.png)
}

.joinapp-language-flag_ro {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/ro.png)
}

.joinapp-language-flag_ru {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/ru.png)
}

.joinapp-language-flag_sk {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/sk.png)
}

.joinapp-language-flag_sl {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/si.png)
}

.joinapp-language-flag_sr {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/rs.png)
}

.joinapp-language-flag_sv {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/se.png)
}

.joinapp-language-flag_th {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/th.png)
}

.joinapp-language-flag_tl {
    background-image: url(https://static.booking.com/static/img/flags/24/ph.png)
}

.joinapp-language-flag_tr {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/tr.png)
}

.joinapp-language-flag_uk {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/ua.png)
}

.joinapp-language-flag_vi {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/vn.png)
}

.joinapp-language-flag_xa {
    background-image: url(https://static.booking.com/static/img/flags/24/ar.png)
}

.joinapp-language-flag_xt {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/z4.png)
}

.joinapp-language-flag_zh {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/flags/cn.png)
}

.reward-block {
    background: #537bb4;
    color: #fff;
    display: table;
    padding: var(--bui_spacing_4x) 12px;
    table-layout: fixed
}

@media (min-width: 1024px) {
    .reward-block {
        padding-right:52px
    }

    .is_right_to_left .reward-block {
        padding-left: 52px;
        padding-right: auto
    }
}

.reward-block__cell {
    display: table-cell;
    vertical-align: middle;
    width: 1%
}

.reward-block__cell--text {
    padding: 0 12px;
    width: 98%
}

.reward-block__close-trigger {
    cursor: pointer;
    font-size: 22px;
    line-height: 22px
}

.form-cta {
    background: #fff;
    border-top: 1px solid var(--bui_color_border_alt);
    box-sizing: border-box;
    padding: 8px;
    position: static;
    width: 100%;
    z-index: 10
}

.form-cta--fixed {
    bottom: 0;
    left: 0;
    position: fixed
}

@media (min-width: 1024px) {
    .form-cta {
        background:none;
        margin-top: 20px;
        padding: 20px 0;
        position: static;
        z-index: 10
    }
}

.form-buttons-container {
    display: flex
}

.form-buttons-container--secondary {
    flex: 1;
    margin-right: 6px
}

.form-buttons-container--secondary button {
    background: #fff
}

.form-buttons-container--primary {
    flex: 6
}

.form-cta--inline {
    border: 0;
    max-width: 400px;
    padding: var(--bui_spacing_6x)
}

.form-cta--inline .form-buttons-container {
    flex-direction: column-reverse
}

.form-cta--inline .form-buttons-container--secondary {
    margin-right: 0;
    margin-top: var(--bui_spacing_4x)
}

@media (min-width: 1024px) {
    .form-cta--inline {
        padding:var(--bui_spacing_2x)
    }
}

.page-tooltip__wrap--collapsed {
    max-height: 150px
}

.page-tooltip__wrap--expand {
    max-height: inherit
}

.page-tooltip__insight .bui-banner {
    background-color: #70bbe9;
    border-color: var(--bui_color_action_background_alt)
}

.page-tooltip__read-more {
    background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,#fff 70%);
    border-bottom: 1px solid var(--bui_color_border_alt);
    bottom: 1px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff",endColorstr="#ffffff",GradientType=0);
    left: 1px;
    padding: 40px 0 0;
    position: absolute;
    right: 1px;
    z-index: 1
}

.page-tooltip__read-more-button {
    background: transparent;
    border: 0;
    color: var(--bui_color_black);
    cursor: pointer;
    display: block;
    line-height: 24px;
    padding: 0;
    text-align: center;
    width: 100%
}

.page-tooltip__read-more-button svg {
    font-size: 24px;
    vertical-align: middle
}

.page-tooltip__wrap {
    overflow: hidden;
    position: relative
}

@media (min-width: 1024px) {
    .page-tooltip__wrap {
        left:500px;
        margin: 0 16px 16px;
        position: absolute;
        width: 300px;
        z-index: 3
    }
}

.wide-container .page-tooltip__wrap {
    top: 0
}

body .screen-main-header,body .screen-main-sub-header {
    margin: 0;
    padding: var(--bui_spacing_4x) var(--bui_spacing_4x) 0 var(--bui_spacing_4x)
}

body .screen-main-sub-header {
    margin-bottom: var(--bui_spacing_4x)
}

@media (min-width: 1024px) {
    body .screen-main-header {
        margin-bottom:30px;
        padding: 0
    }

    body .screen-main-sub-header {
        margin-bottom: 6px;
        padding: 0
    }
}

body .screen-main-header .screen-main-header__title {
    font-weight: 400
}

.page-tooltip__container-main {
    padding: 0 var(--bui_spacing_4x)
}

.page-tooltip__container--last {
    order: 1
}

@media (min-width: 1024px) {
    .page-tooltip__container-main {
        padding:0
    }

    .page-tooltip__container {
        left: 500px;
        margin: 0 var(--bui_spacing_4x) var(--bui_spacing_4x) var(--bui_spacing_4x);
        position: absolute;
        width: 300px;
        z-index: 3
    }

    .page-tooltip__container--hidden {
        display: none
    }
}

.wide-container .page-tooltip__container {
    top: 0
}
.my-properties_access {
    float: right;
    margin-right: 24px;
    height: 65px;
}
.my-properties_access {
    position: absolute;
    top: 0px;
    right: 50px;
    height: 100%;
    border: none;
    transition: background-color 0.3s ease;
    cursor: pointer;
}
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
  }
  
  .ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
  }
  @media (min-width: 1024px){
.min-height {
    min-height: 100%;
}
  }
.custom-checkbox {
    appearance: none; /* Remove default styles */
    width: 20px;
    height: 20px;
    border: 2px solid #0074d9; /* Change the border color */
    border-radius: 3px;
    margin-right: 8px;
    outline: none;
    transition: border-color 0.3s;
    cursor: pointer;
  }
  .custom-checkbox:checked {
    border-color: #33d6a6; /* Change the border color when checked */
  }
.page-tooltip__container>:not(:last-child) {
    margin-bottom: 15px
}

.address-line2__warning-container {
    display: flex;
    margin: var(--bui_spacing_2x) 0 var(--bui_spacing_4x)
}

.address-line2__warning-icon {
    flex-shrink: 0;
    margin-right: var(--bui_spacing_2x)
}

.manual-address-form-no-postcode {
    display: flex;
    margin-top: calc(var(--bui_spacing_2x)*-1)
}

.manual-address-form-no-postcode__text {
    align-self: center;
    flex-grow: 1;
    flex-shrink: 1
}

.manual-address-form-no-postcode__icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: var(--bui_spacing_2x)
}

@media (min-width: 1024px) {
    .manual-address-form__postcode {
        width:50%
    }
}

.address-tooltip-list {
    padding-left: var(--bui_spacing_4x)
}

.autocomplete-suggestions__btn {
    justify-content: flex-start!important;
    text-align: left!important
}

.autocomplete-suggestions-dropdown {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 100%
}

.auto-suggestion-content-hide {
    display: none
}

.auto-suggestion-content {
    background: var(--bui_color_background_base);
    border-radius: 2px;
    box-shadow: 0 2px var(--bui_spacing_2x) 0 rgb(0 0 0/16%);
    box-sizing: border-box;
    display: block;
    left: 0;
    min-width: 100%;
    padding: 0;
    padding-bottom: var(--bui_spacing_4x);
    position: absolute;
    top: 100%;
    transform: translateY(var(--bui_spacing_2x));
    z-index: var(--bui_z_index_2)
}

.auto-suggestion-content.auto-suggestion_content-with-button {
    padding-bottom: 0
}

.autocomplete-suggestion__lists {
    list-style-type: none;
    margin: 0;
    padding: var(--bui_spacing_1x) 0
}

.autocomplete-suggestion__list {
    list-style-type: none;
    pointer-events: all
}

.autocomplete-suggestions__secondary-text {
    color: var(--bui_color_foreground_alt);
    font-size: var(--bui_spacing_3x);
    font-weight: 400;
    line-height: 18px
}

.autocomplete-suggestions__powered {
    margin-bottom: calc(var(--bui_spacing_4x)*-1);
    padding: var(--bui_spacing_2x);
    text-align: right
}

.autocomplete-suggestion__list .autocomplete-add_manually_button {
    padding: var(--bui_spacing_3x) var(--bui_spacing_4x);
    width: 100%
}

.autocomplete-suggestions__empty-state {
    padding: var(--bui_spacing_3x) var(--bui_spacing_4x)
}

.autocomplete-suggestion__divider {
    margin: 0 var(--bui_spacing_4x)
}

.autocomplete-suggestions__btn-text {
    padding: var(--bui_spacing_2x)
}

.autocomplete__input-helper {
    margin-block-start:var(--bui_spacing_1x)}

.map-title {
    color: var(--bui_color_foreground);
    display: none;
    margin-bottom: var(--bui_spacing_4x)
}

.map-mobile-title {
    padding: var(--bui_spacing_4x)
}

.form-container--map {
    min-height: calc(70vh - 140px)
}

#map {
    background: #f1f1f1;
    min-height: calc(100vh - 140px);
    width: 100%
}

.map-with-pin-container {
    position: relative
}

.map-with-pin-container .centerMarker {
    color: #70bbe9;
    height: 46px;
    left: 50%;
    margin-left: -23px;
    margin-top: -43px;
    position: absolute;
    top: 50%;
    width: 46px;
    z-index: 1
}

.map-error-summary {
    margin-bottom: var(--bui_spacing_2x);
    padding: 0 var(--bui_spacing_4x)
}

body .bui_font_display_one.screen-main-header.screen-main-header.screen-main-header-with-error-summary {
    margin-bottom: var(--bui_spacing_4x)
}

@media (orientation: landscape) {
    #map {
        margin-left:var(--bui_spacing_4x)
    }
}

@media (min-width: 1024px) {
    .map-title {
        display:block
    }

    .map-mobile-title {
        display: none
    }

    #map {
        margin-left: 0;
        min-height: calc(70vh - 140px)
    }

    .map-container {
        padding: var(--bui_spacing_6x)
    }

    .map-error-summary {
        padding: 0
    }
}

.bed-type--not-highlighted {
    font-weight: 400
}

.addRoom {
    padding-right: var(--bui_spacing_6x)
}

.ensuite-bathroom-text {
    display: block
}

.ensuite-bathroom-sub-text {
    color: var(--bui_color_foreground_alt);
    display: block;
    margin-bottom: var(--bui_spacing_4x)
}

@media (min-width: 1024px) {
    .addRoom {
        padding-right:0
    }
}

.child-age-select {
    width: 64px
}

.num-unit .bui-form__label,.smoking-allowed .bui-form__label {
    font-size: var(--bui_spacing_4x);
    font-weight: 700;
    line-height: var(--bui_spacing_6x);
    margin-bottom: var(--bui_spacing_4x)
}

.num-unit-container {
    width: 80px
}

.bedroom-label {
    display: block
}

.count-input {
    padding: 13px 0 0!important
}

.edit-room {
    background: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 8px 0 rgba(0,0,0,.12),0 2px 3px -1px rgba(0,0,0,.2);
    cursor: pointer;
    display: block;
    margin: 0;
    padding: 12px 16px;
    width: 100%
}

.link-add-bedroom_container {
    padding: 20px 0
}

.edit-room:focus,.edit-room:focus:active {
    box-shadow: none;
    outline: none
}

.edit-room small {
    color: #757575;
    display: block;
    text-align: left
}

.edit-room:hover {
    box-shadow: 0 3px 18px 0 rgba(0,0,0,.12),0 3px 5px -1px rgba(0,0,0,.2)
}

.add-room {
    border-bottom: none;
    padding-bottom: var( --bui_spacing_2x )!important
}

.bedroom-setup--list {
    text-align: left
}

.remove-room {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    padding-top: 10px;
    text-align: center
}

.remove-room:hover {
    opacity: .8
}

.remove-room:focus {
    outline: none
}

.roomDesc {
    color: #3b3a3a;
    line-height: 40px;
    padding-top: 5px
}

.roomDesc svg {
    fill: currentColor;
    color: var(--bui_color_foreground_disabled);
    float: left;
    height: 40px;
    margin-right: 15px;
    width: 40px
}

.bedroom-room-size {
    align-items: flex-end;
    display: flex
}

.bedroom-room-size__room-measurement .bui-input-text__field {
    width: 160px
}

.bedroom-room-size__measurement-type select {
    background-color: var(--bui_color_border_alt)
}

.mobile-home-warn {
    margin-left: var(--bui_spacing_2x)
}

.bedroom-error-msg {
    margin-top: var(--bui_spacing_4x)
}

.room-size-error {
    margin-top: var(--bui_spacing_2x)
}

.question-title {
    margin-top: 0
}

.b17c249e2e span span:last-child {
    margin-left: 0
}

.be98401a26 {
    display: block
}

.cefbfb6857 {
    margin-bottom: var(--bui_spacing_4x)
}

.aa15ecdee4 {
    margin-bottom: var(--bui_spacing_6x)
}

.adf8bda17a {
    align-items: center;
    display: flex;
    margin-bottom: var(--bui_spacing_4x)
}

.db40bf3148 {
    margin-right: var(--bui_spacing_2x)
}

.bf0bcb0270 {
    margin-bottom: var(--bui_spacing_4x);
    margin-left: 0;
    margin-right: 0;
    margin-top: var(--bui_spacing_6x)
}

.f15549b90c {
    color: var(--bui_color_foreground);
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height);
    text-decoration: line-through
}

.d1832c87be {
    width: 100px
}

.price-range-container {
    background: #70bbe9;
    box-sizing: border-box;
    display: flex;
    height: var(--bui_spacing_1x);
    margin-bottom: var(--bui_spacing_12x);
    margin-top: var(--bui_spacing_12x);
    position: relative;
    width: 100%
}

.price-high-container,.price-low-container,.price-median-container {
    position: relative
}

.price-high-container,.price-low-container {
    width: 25%
}

.price-median-container {
    background: #70bbe9;
    width: 50%
}

.price-median-container,.price-range-container {
    border-radius: 999px
}

.price-high-container span:after,.price-low-container span:after,.price-median-container span:after {
    border-style: solid;
    border-width: 7px;
    content: "";
    left: 50%;
    position: absolute;
    transform: translate(-50%)
}

.price-high-container span:after,.price-low-container span:after {
    border-bottom-color: #70bbe9;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    bottom: 100%
}

.price-median-container span:after {
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: #70bbe9;
    top: 100%
}

.price-high-container span,.price-low-container span,.price-median-container span {
    background: #70bbe9;
    border-radius: var(--bui_spacing_half);
    color: #fff;
    padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
    position: absolute
}

.price-high-container span,.price-low-container span {
    top: var(--bui_spacing_3x)
}

.price-median-container span {
    bottom: var(--bui_spacing_3x);
    left: 50%;
    white-space: nowrap
}

.non-refund-rate-discount.mobile .bui-input-text__content {
    flex-basis: 50%;
    max-width: 50%;
    width: 50%
}

.non-refund-rate-discount .bui-input-text__content {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    width: 33.3333333333%
}

.non-refund-rate-calculation__table {
    border: 0;
    border-collapse: collapse;
    border-spacing: 0;
    color: var(--bui_color_foreground);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    width: 100%
}

.non-refund-rate-calculation__table td {
    padding: var(--bui_spacing_2x)
}

.non-refund-rate-calculation__value-cell {
    color: var(--bui_color_foreground_alt);
    font-family: var(--bui_font_strong_2_font-family);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height);
    text-align: right;
    white-space: nowrap;
    width: 5%
}

.non-refund-rate-calculation__final-price-row {
    background-color: var(--bui_color_action_background_alt)
}

.price-discount-checkbox {
    margin-top: var(--bui_spacing_4x)
}

.price-discount-hint-text {
    color: var(--bui_color_foreground);
    font-size: 14px;
    line-height: 20px
}

.price-discount-checkbox .bui-checkbox__label {
    font-weight: 700
}

@media (min-width: 1024px) {
    .price-container-spacer {
        margin-top:var(--bui_spacing_8x)
    }
}

.usps {
    font-size: 16px;
    font-weight: 400;
    padding-left: 19px
}

.usps.incentives {
    padding-left: 0
}

.usps .prices {
    color: var(--bui_color_foreground_alt);
    display: inline-block;
    font-weight: 400;
    min-width: 40px;
    padding-right: var(--bui_spacing_2x)
}

.usps.incentives .prices {
    width: 110px
}

.usps .prices .commission-without-incentive {
    text-decoration: line-through
}

.usps .commission-with-incentive {
    color: var(--bui_color_constructive_foreground);
    display: inline-block;
    font-weight: 700;
    padding-left: var(--bui_spacing_2x)
}

.usp-list {
    color: var(--bui_color_foreground_alt);
    font-size: 14px;
    list-style: none;
    margin-left: 40px;
    padding: 0 0 10px
}

.usp-list li {
    line-height: 180%
}

.usp-list.incentives {
    margin-left: 120px
}

.incentives .payment-cost--description {
    line-height: 0
}

.usp-list__checkmark-icon {
    margin-right: 5px;
    vertical-align: middle
}

.usp-list__info-icon {
    cursor: pointer;
    margin-left: 5px;
    vertical-align: middle
}

.usp-list .usp-list__item--payment__link {
    font-size: var(--bui_spacing_3x);
    margin-left: 5px;
    padding-left: 0;
    padding-right: 0
}

.price-help-text {
    color: var(--bui_color_foreground_alt);
    margin-top: var(--bui_spacing_1x)
}

.price-advice--feedback__link {
    margin-left: var(--bui_spacing_2x);
    margin-right: var(--bui_spacing_2x)
}

.price-advice--learnmore__link {
    vertical-align: baseline
}

.price-advice-text-small {
    font-size: 11px
}

.price-range-list {
    margin-top: var(--bui_spacing_6x)
}

.currency-warning {
    margin-top: var(--bui_spacing_half)!important
}

.wide-container.price-cancellation-policy-block {
    border-top: 0;
    margin-bottom: var(--bui_spacing_6x);
    padding-bottom: 0!important
}

.price-non-refundable-input-percentage {
    width: 100px
}

.price-overview-heading {
    display: flex;
    justify-content: space-between
}

.price-overview-heading__text-container {
    align-self: center;
    vertical-align: middle
}

.price-overview-heading__text {
    line-height: 20px;
    margin-right: var(--bui_spacing_2x);
    vertical-align: middle
}

.is_right_to_left .price-overview-heading__text {
    margin-left: var(--bui_spacing_2x);
    margin-right: 0
}

.price-overview-info {
    vertical-align: middle
}

.price-overview-info-variant {
    color: var(--bui_color_callout_foreground)
}

.price-overview-link {
    text-decoration: none
}

.price-overview-checkbox-container {
    display: flex
}

.price-overview-checkbox-icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: var(--bui_spacing_4x)
}

.is_right_to_left .price-overview-checkbox-icon {
    margin-left: var(--bui_spacing_4x);
    margin-right: 0
}

.price-overview-per-person-item {
    display: flex
}

.price-overview-per-person__person {
    width: 150px
}

.price-overview_rate-row {
    padding-top: var(--bui_spacing_4x)
}

.price-overview__no-top-border {
    border-top-width: 0
}

.ota-import-input,.photos-import-cta {
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height)
}

.drag-and-drop {
    background: transparent;
    border: 2px dashed var(--bui_color_border);
    /* bottom: 0; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: var(--bui_spacing_8x);
    /* position: absolute; */
    height: auto;
    /* top: 0; */
    width: 100%
}

.drag-and-drop--dragging {
    background-color: var(--bui_color_action_highlighted_alt);
    border: 2px dashed var(--bui_color_cta_highlighted)
}

.take-photo--with-drag-and-drop-and-photos .drag-and-drop {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--bui_spacing_4x)
}

.take-photo--with-drag-and-drop-and-photos .take-photo__drag_explanation {
    flex: 2;
    text-align: left
}

.take-photo--with-drag-and-drop-and-photos .take-photo__drag_explanation_or {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding-right: 16px;
    text-align: right
}

.take-photo--with-drag-and-drop-and-photos .take-photo__upload_cta {
    flex: 2;
    text-align: right
}

.is_long_language .take-photo--with-drag-and-drop-and-photos .take-photo__upload_cta {
    flex-basis: 115px;
    flex-grow: 1
}

.drag-and-drop .bui-icon {
    margin-right: var(--bui_spacing_2x)
}

.drag-and-drop__highlight {
    position: relative
}

.drag-and-drop__scrim {
    display: none
}

.drag-and-drop__scrim,.drag-and-drop__scrim-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.drag-and-drop__scrim {
    border: 1px solid #70bbe9
}

.drag-and-drop__scrim-show {
    display: flex;
    pointer-events: none;
    z-index: 9
}

.drag-and-drop__scrim-background {
    background-color: #70bbe9;
    opacity: .1
}

.drag-and-drop__scrim-content {
    align-self: center;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1
}

.drag-and-drop__scrim-box {
    color: var(--bui_color_foreground)
}

.thumbnail-error {
    background-color: var(--bui_color_destructive_background_alt);
    bottom: 0;
    left: 0;
    padding: var(--bui_spacing_4x);
    position: absolute;
    right: 0;
    text-align: left
}

.thumbnail-error_icon {
    color: var(--bui_color_destructive_foreground);
    left: var(--bui_spacing_4x);
    position: absolute;
    top: 14px
}

.thumbnail-error_message {
    color: var(--bui_color_foreground);
    margin-left: var(--bui_spacing_8x)
}

.thumbnail-opaque {
    background: #fff;
    height: 100%;
    opacity: .5;
    width: 100%
}

.thumbnail-delete-btn {
    background-color: #fff;
    border-radius: 50%;
    color: var(--bui_color_foreground);
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px
}

.c852165033 {
    flex-wrap: nowrap;
    margin-bottom: var(--bui_spacing_4x)
}

.ef267c2ccf {
    flex: 1 1 25%;
    max-height: 100px;
    max-width: 100px;
    overflow: hidden
}

.abd4b1cbd9,.abd4b1cbd9:before {
    background-size: cover
}

.abd4b1cbd9:before {
    background-position: 50%;
    background-repeat: no-repeat;
    content: "";
    display: block;
    padding-top: 100%
}

.ef267c2ccf:last-child {
    position: relative
}

.c30b0d12ce:after {
    background-color: var(--bui_color_black);
    content: "";
    height: 100%;
    left: 0;
    opacity: .4;
    position: absolute;
    top: 0;
    width: 100%
}

.fd74926748 {
    color: var(--bui_color_foreground_inverted);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: var(--bui_z_index_2)
}

.photo-suggestion-upload dl {
    margin: auto;
    padding-left: .5em;
    position: relative
}

.photo-suggestion-upload dt:not(:first-child) {
    margin-top: var(--bui_spacing_1x)
}

.photo-suggestion-upload dt {
    font-weight: 700;
    left: 0;
    margin-bottom: var(--bui_spacing_2x);
    margin-top: calc(var(--bui_spacing_1x)*-1/2)
}

.photo-suggestion-upload dd {
    margin-bottom: var(--bui_spacing_4x);
    margin-left: 30px
}

.photo-page-tooltip__header {
    color: #70bbe9;
    padding: 0
}

.photo-page-tooltip__tips-list {
    padding: 0
}

.photo-page-tooltip__tips-list li {
    margin: var(--bui_spacing_2x) 0
}

.photo-page-tooltip__tip-with-popup {
    border-bottom: 1px dashed;
    cursor: pointer
}

.suggestion-icon {
    margin-right: 10px
}

.photos-error-summary {
    margin-bottom: var(--bui_spacing_6x)
}

@media (max-width: 575px) {
    .photos-error-summary {
        margin-top:var(--bui_spacing_6x);
        padding-left: var(--bui_spacing_8x);
        padding-right: var(--bui_spacing_8x)
    }
}

.ecf04749e8 {
    border: none;
    padding: 0
}

.ba431818b7 {
    margin-top: var(--bui_spacing_4x)
}

.uploaded-photos {
    margin: 0 -10px
}

.uploaded-photos:after {
    clear: both;
    content: "";
    display: block
}

.screen--desktop .uploaded-photos .photo {
    padding: calc(25% - 20px) 0 0;
    width: calc(25% - 20px)
}

.uploaded-photos .photo {
    float: left;
    margin: 10px;
    padding: calc(50% - 20px) 0 0;
    position: relative;
    text-align: center;
    width: calc(50% - 20px)
}

.uploaded-photos .photo-background,.uploaded-photos .photo-preview {
    background-size: cover;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.uploaded-photos .photo-placeholder .photo-background {
    background-color: #70bbe9;
    border-radius: 3px;
    opacity: .5
}

.screen--desktop .uploaded-photos .photo-placeholder {
    padding: calc(25% - 22px) 0 0;
    width: calc(25% - 20px)
}

.uploaded-photos .photo-placeholder {
    border: 1px dashed #70bbe9;
    border-radius: 3px;
    opacity: .3;
    padding: calc(50% - 22px) 0 0;
    width: calc(50% - 20px)
}

.uploaded-photos .photo-placeholder:hover {
    opacity: 1
}

.uploaded-photos .photo-placeholder .photo-placeholder__icon {
    color: var(--bui_color_action_foreground);
    height: 46px;
    left: calc(50% - 23px);
    position: absolute;
    top: calc(50% - 23px);
    width: 46px
}

.uploaded-photos .photo.loading {
    background: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader.gif) 50% no-repeat
}

.uploaded-photos .photo.loading .photo-preview {
    opacity: .5
}

.uploaded-photos .photo .error-message {
    color: var(--bui_color_destructive_highlighted);
    left: 0;
    margin-top: 25px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    vertical-align: top
}

.uploaded-photos .photo .icon-delete {
    background: var(--bui_color_destructive_highlighted);
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 21px;
    padding: 14px 16px;
    position: absolute;
    right: 6px;
    top: 6px
}

.uploaded-photos .photo .icon-delete:hover {
    background: #fd3a3b
}

.photos-grid--container {
    -ms-grid-columns: calc(100% + 2px) calc(100% + 2px);
    display: grid;
    grid-template-columns: calc(100% + 2px) calc(100% + 2px)
}

.photos-grid--holder {
    grid-area: 1/1
}

.photos-grid--container .photo-preview {
    border: 3px solid #fff;
    border-radius: 3px;
    height: calc(100% + 2px);
    width: calc(100% + 2px)
}

.photos-grid--container .photo-background {
    right: 7px;
    top: 7px
}

.uploaded-photos .draggable-photo {
    z-index: 1
}

.uploaded-photos .photo-preview--main_badge {
    position: absolute;
    top: -22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%
}

.uploaded-photos .photo-tags-badge {
    background-color: #fff;
    left: 0;
    max-width: 70%;
    position: absolute;
    top: 0;
    z-index: 10
}

.uploaded-photos .photo-preview--main_border {
    border: 1px dashed var(--bui_color_callout_border);
    border-radius: 4px;
    box-sizing: border-box;
    padding: calc(50% - 22px) 0 0
}

.uploaded-photos .photo-preview--secondary_border {
    border: 1px dashed #70bbe9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: calc(50% - 22px) 0 0
}

.uploaded-photos .photo-preview--main_border.loading,.uploaded-photos .photo-preview--secondary_border.loading {
    display: none
}

.ms-container--drag_instruction {
    color: var(--bui_color_foreground_alt);
    padding-bottom: 40px;
    padding-top: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.uploaded-photos .photo-preview--main_hightlight {
    box-shadow: 0 0 0 1px var(--bui_color_callout_background)
}

.uploaded-photos .photo-preview--draggable {
    background-color: #fff
}

.uploaded-photos .draggable-photo {
    box-shadow: 0 0 4px 2px rgba(0,0,0,.2);
    cursor: grab
}

.uploaded-photos .draggable-photo:hover {
    box-shadow: 0 14px 32px 0 rgba(0,0,0,.2)
}

.dragged-helper {
    box-shadow: 0 18px 32px 0 rgba(0,0,0,.2);
    cursor: grab!important;
    margin: 0;
    z-index: 4
}

.dragged-helper .photo-preview--main_hightlight {
    box-shadow: none
}

.dragged-helper .icon-delete {
    display: none
}

.dragged-helper .error-message {
    color: var(--bui_color_destructive_highlighted);
    left: 0;
    margin-top: 25px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: top
}

.take-photo {
    border: 1px solid #70bbe9;
    border-radius: 3px;
    margin-bottom: 0;
    padding: 15px;
    position: relative;
    text-align: center
}

.photos-import-cta,.take-photo--mobile {
    margin-top: var(--bui_spacing_4x)
}

.photos-import-cta {
    color: var(--bui_color_action_foreground);
    cursor: pointer;
    margin-bottom: var(--bui_spacing_4x)
}

.ota-container {
    display: flex;
    flex-direction: column;
    padding: 0 var(--bui_spacing_4x)
}

@media (min-width: 1024px) {
    .take-photo {
        margin-bottom:var(--bui_spacing_8x)
    }

    .photos-import-cta {
        margin-top: 0
    }

    .ota-container {
        padding: 0 25px
    }
}

.ota-completed-check,.ota-completed-check label {
    border: 0;
    color: var(--bui_color_foreground_alt);
    font-size: 14px
}

.ota-completed-check label {
    padding: 16px 0
}

.ota-completed-check-top,.ota-completed-check-top label {
    border: 0;
    color: var(--bui_color_foreground_alt)
}

.take-photo--with-drag-and-drop {
    border: none;
    display: flex;
    flex-basis: 160px;
    padding: 0
}

.take-photo--with-drag-and-drop-and-photos {
    flex-basis: 90px
}

.is_long_language .take-photo--with-drag-and-drop-and-photos {
    flex-basis: 125px
}

.take-photo__placeholder-icon {
    margin-bottom: var(--bui_spacing_4x)
}

.take-photo__drag-and-drop-btn {
    border: 1px solid #70bbe9;
    display: inline-block;
    padding: var(--bui_spacing_2x) var(--bui_spacing_6x)
}

.take-photo.take-photo--with-drag-and-drop label {
    font-size: 14px
}

.take-photo:hover {
    background-color: var(--bui_color_action_background_alt)
}

.take-photo.take-photo--with-drag-and-drop:hover {
    background-color: transparent
}

.take-photo label {
    color: var(--bui_color_action_foreground);
    font-size: 18px;
    margin-bottom: 0
}

.text-align--center {
    text-align: center
}

.ota-invisible {
    visibility: hidden
}

.ota-take-photo {
    -ms-flex-positive: inherit;
    -ms-flex-negative: inherit;
    -ms-flex-preferred-size: inherit;
    flex: 1;
    padding-top: 10px
}

.ota-take-photo label {
    font-size: 14px;
    font-weight: 400;
    line-height: calc(var(--bui_spacing_1x)*5)
}

.ota-icon {
    display: inline-block;
    padding: 0 5px;
    position: relative;
    top: 5px
}

.photos-import-cta span:hover {
    text-decoration: underline
}

.ota-uploaded-photos {
    -ms-flex-positive: inherit;
    -ms-flex-negative: inherit;
    -ms-flex-preferred-size: inherit;
    flex: 1
}

.ota-import {
    display: flex;
    flex-direction: column
}

.ota-import-icon {
    padding: 10px 0
}

.ota-main-title {
    padding-bottom: 15px
}

.ota-main-title h2 {
    padding-left: 0
}

.ota-import-input {
    padding-top: 25px
}

.ota-import-input input,.ota-import-input label {
    font-size: 14px;
    font-weight: 400;
    line-height: calc(var(--bui_spacing_1x)*5)
}

.ota-completed-check {
    padding-bottom: 100px
}

@media (min-width: 1024px) {
    .ota-completed-check {
        padding-bottom:0
    }
}

.ota-container--loading {
    -ms-flex-pack: justify;
    display: flex;
    justify-content: space-around;
    text-align: center
}

.ota-loading {
    padding-top: 50px
}

.ota-progress {
    background-color: #70bbe9;
    height: 100%;
    transition: width 1.5s ease-in-out
}

.ota-progressbar {
    background-color: var(--bui_color_border_alt);
    height: 8px;
    margin: 15px auto 10px;
    width: 50%
}

.ota-loading-import--icon {
    -ms-flex-positive: inherit;
    -ms-flex-negative: inherit;
    -ms-flex-preferred-size: inherit;
    flex: 1;
    height: 44px;
    margin: auto;
    width: 56px
}

.ota-transition {
    animation: slidein 1s infinite;
    transform-origin: 32px 50px
}

@keyframes slidein {
    0% {
        opacity: .5;
        transform: scale(1) translateY(-40px)
    }

    20% {
        transform: scale(1.5) translate(0)
    }

    70% {
        opacity: 1;
        transform: scale(1.5) translate(0)
    }

    to {
        opacity: 0;
        transform: scale(0) translateY(80px)
    }
}

.photo-placeholder input[type=file],.take-photo input[type=file] {
    /* bottom: 0; */
    cursor: pointer;
    display: block;
    /* left: 0; */
    opacity: 0;
    position: absolute;
    /* right: 0;
    top: 0; */
    width: 100%;
    z-index: 10
}

.add-photo-sub-info div {
    display: inline
}

.add-more-photos-warning {
    margin-bottom: var(--bui_spacing_4x);
    margin-top: var(--bui_spacing_6x)
}

@media (min-width: 1024px) {
    .add-more-photos-warning {
        margin-bottom:0
    }
}

.add-photos-to-proceed-message {
    margin-bottom: var(--bui_spacing_4x);
    margin-top: var(--bui_spacing_4x);
    padding-left: var(--bui_spacing_4x)
}

@media (min-width: 1024px) {
    .add-photos-to-proceed-message {
        margin-bottom:0;
        margin-top: var(--bui_spacing_8x);
        padding-left: 0
    }
}

.upload-photos_container {
    display: flex
}

.property-name-prompt {
    color: var(--bui_color_foreground_alt)
}

.property-name-tooltip-list {
    padding-left: var(--bui_spacing_4x);
    list-style-type: disc;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
.rating-group {
    display: inline-flex!important;
    padding-left: var(--bui_spacing_2x)
}

.a759f82ee7 {
    margin: 0
}

.a45966b1d9 {
    display: flex
}

.d81ca414c5 {
    padding-left: calc(var(--bui_spacing_2x) + var(--bui_spacing_1x)/2)!important
}

.d81ca414c5>textarea {
    cursor: text
}

.bbf1830cb5 {
    visibility: hidden
}

.a8d71f8a7d {
    white-space: pre-wrap
}

b-licensing.license-no-header h1:first-of-type {
    display: none
}

b-licensing.license-no-header h1 {
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height)
}

b-licensing.license-no-header .wc_root>label {
    color: var(--bui_color_foreground);
    font-size: 14px;
    font-weight: 400;
    line-height: calc(var(--bui_spacing_1x)*5)
}

b-licensing input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid var(--bui_color_border);
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 7px;
    text-align: left;
    width: 100%
}

.bui-checkbox {
    display: block;
    padding: 0 0 0 calc(var(--bui_spacing_1x)*5);
    position: relative
}

.bui-checkbox__input {
    border: 0;
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 0
}

.bui-checkbox__label {
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    min-height: calc(var(--bui_spacing_1x)*5);
    padding-left: var(--bui_spacing_2x)
}

.bui-checkbox__label:after,.bui-checkbox__label:before {
    box-sizing: border-box;
    content: "";
    height: calc(var(--bui_spacing_1x)*5);
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(var(--bui_spacing_1x)*5)
}

.bui-checkbox__label:before {
    background-color: var(--bui_color_background_elevation_one);
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    border-radius: var(--bui_border_radius_100)
}

.bui-checkbox__label:after {
    left: var(--bui_spacing_half);
    margin-top: var(--bui_spacing_half);
    top: 50%
}

.content_consent {
    margin-top: var(--bui_spacing_1x)
}

.bui-checkbox:hover .bui-checkbox__input:checked~.bui-checkbox__label:before,.bui-checkbox__input:checked:focus~.bui-checkbox__label:before,.bui-checkbox__input:checked~.bui-checkbox__label:before {
    background: #70bbe9;
    border-color: #70bbe9;
}

/* .bui-checkbox__input:checked~.bui-checkbox__label:after {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 128 128' fill='%23fff'%3E%3Cpath d='M56.33 102a6 6 0 0 1-4.24-1.75L19.27 67.54A6 6 0 1 1 27.74 59l27.94 27.88 44-58.49a6 6 0 1 1 9.58 7.22l-48.17 64a6 6 0 0 1-4.34 2.39Z' data-name='8x'/%3E%3C/svg%3E")
} */

b-licensing .bui-spacer--medium {
    margin-bottom: 8px!important
}

b-licensing .bui-form__group label,b-licensing .bui-form__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4285714286em;
    margin-bottom: 4px;
    padding-bottom: 0
}

.bui-group {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap
}

.bui-group>* {
    margin-top: var(--bui_spacing_2x)!important
}

.bui-group--inline {
    flex-direction: row;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px
}

.bui-form__group {
    border: none;
    margin: 0;
    padding: 0
}

.bui-group--inline>*,.bui-group--inline>:first-child {
    margin: var(--bui_spacing_2x) 0 0 var(--bui_spacing_2x)
}

[class^=bui-],[class^=bui-]:after,[class^=bui-]:before {
    box-sizing: border-box
}

.bui-radio {
    display: block;
    position: relative
}

.bui-radio__input {
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0
}

.bui-radio__label {
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0 0 0 28px;
    position: relative
}

.bui-radio__label:before {
    background-color: var(--bui_color_background_base);
    border: 1px solid var(--bui_color_border);
    border-radius: 50%;
    content: "";
    height: 20px;
    left: 0;
    position: absolute;
    width: 20px
}

.bui-radio__label:after {
    border-radius: 50%;
    content: "";
    height: 10px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 5px;
    width: 10px
}

.bui-radio__input:checked~.bui-radio__label:before {
    border-color: var(--bui_color_action_foreground);
    border-width: 2px
}

.bui-radio__input:checked~.bui-radio__label:after {
    background-color: var(--bui_color_action_foreground);
    opacity: 1
}

.bui-input-select {
    position: relative
}

.bui-input-select .bui-form__control {
    min-width: 64px;
    padding-left: 7px;
    padding-right: var(--bui_spacing_8x);
    transform: translateZ(0);
    z-index: 100
}

.bui-form__control,.bui-input-select .bui-form__control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.bui-form__control {
    background: var(--bui_color_background_base);
    border: 1px solid var(--bui_color_border);
    border-radius: 2px;
    color: var(--bui_color_foreground);
    cursor: pointer;
    font-weight: 400;
    padding: 7px;
    text-align: left;
    transition: border-color .15s cubic-bezier(.4,0,.2,1);
    width: 100%
}

.bui-form__control,.bui-form__label {
    display: block;
    font-size: 14px;
    line-height: 20px
}

.bui-form__label {
    font-weight: 500;
    padding-bottom: 4px
}

.bui-input-select__icon {
    fill: var(--bui_color_foreground);
    height: 16px;
    pointer-events: none;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
    width: 16px
}

.bui-form__error {
    color: var(--bui_color_destructive_foreground);
    display: none
}

.bui-has-error .bui-form__control {
    border-color: var(--bui_color_destructive_foreground)
}

.bui-has-error .bui-form__error {
    display: block
}

.bui-spacer--larger {
    margin-bottom: var(--bui_spacing_6x)
}

.bui-f-font-display_one {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px
}

.kyp-names-table-action {
    width: 28px
}

.kyp-names-table-footer {
    cursor: pointer;
    padding: 0 6px
}

.kyp-names-table-action-remove {
    background: transparent;
    border: none;
    color: var(--bui_color_foreground);
    cursor: pointer
}

.kyp-optional-label {
    color: var(--bui_color_foreground_disabled);
    margin-left: calc(var(--bui_spacing_1x)/2)
}

.kyp-names-table-footer-action {
    color: var(--bui_color_action_foreground);
    margin-left: 12px
}

.bui-form__group.kyp-names-table-input,.bui-form__group.kyp-names-table-input .bui-input-text__control {
    width: calc(50% - 26px)
}

.bui-form__group.kyp-names-table-input-date,.bui-form__group.kyp-names-table-input-date .bui-input-text__control {
    width: calc(100% - 8px)
}

.bui-form__group.kyp-names-table-input-date {
    margin-top: var(--bui_spacing_2x)
}

.bui-form__group.kyp-names-table-input,.bui-form__group.kyp-names-table-input-date {
    display: inline-block;
    margin-left: 8px
}

@media (min-width: 1024px) {
    .bui-form__group.kyp-names-table-input,.bui-form__group.kyp-names-table-input .bui-input-text__control {
        width:124px
    }

    .bui-form__group.kyp-names-table-input-date,.bui-form__group.kyp-names-table-input-date .bui-input-text__control {
        width: 158px
    }

    .bui-form__group.kyp-names-table-input,.bui-form__group.kyp-names-table-input-date {
        display: inline-block;
        margin-left: 8px
    }
}

.ab0db388cf:empty {
    display: none
}

.ed7f58d0b1 {
    margin-top: var(--bui_spacing_4x)
}

@media (min-width: 1024px) {
    .a8e45fbcef {
        padding:0
    }
}

.how-we-work__grid_wrap {
    display: flex
}

.how-we-work__icon {
    flex: 0;
    min-width: var(--bui_spacing_6x)
}

.how-we-work__q-and-a {
    flex: 1;
    padding: var(--bui_spacing_1x) 0 var(--bui_spacing_2x) var(--bui_spacing_6x)
}

.agreement-title {
    margin: 16px 0 25px;
    padding-left: 0
}

.agreement-sub-title {
    color: var(--bui_color_foreground_alt)
}

.agreement-next-list {
    border-bottom: 1px solid var(--bui_color_border);
    list-style: none;
    margin: 0 0 20px;
    padding: 20px 0
}

.agreement-next-list--with-av-message {
    border-top: 1px solid var(--bui_color_border);
    padding: 0
}

.agreement-primary-btn .bui-button__text {
    text-align: center
}

.agreement-title {
    margin-top: 0
}

.agreement-av-message {
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height);
    margin: 0;
    padding-top: var(--bui_spacing_2x)
}

.agreement-next-list li {
    margin: 5px 0
}

.agreement-next-item-icon {
    float: left;
    margin-right: 5px
}

.agreement-next-item-text {
    padding: 15px 25px
}

.agreement-next-list li:after {
    clear: both;
    content: "";
    display: block
}

.agreement-not-ready-form-subitems {
    margin-left: var(--bui_spacing_8x)
}

.is_right_to_left .agreement-not-ready-form-subitems {
    margin-left: 0;
    margin-right: var(--bui_spacing_8x)
}

.btn-not-ready {
    padding: 14px 20px;
    width: 100%
}

.stripe-disclaimer {
    color: var(--bui_color_foreground_alt);
    font-size: 12px;
    font-weight: 400;
    padding-left: 30px
}

.obp-discounts-table__container {
    margin-top: var(--bui_spacing_6x)
}

.obp-discounts-table__header {
    border-top: 1px solid var(--bui_color_border_alt);
    padding: var(--bui_spacing_4x) var(--bui_spacing_1x)
}

.obp-discounts-table__header-discount,.obp-discounts-table__normal-price-no-discount {
    text-align: center
}

.obp-discounts-table__normal-price-row {
    background-color: var(--bui_color_background_alt);
    border-top: 1px solid var(--bui_color_border_alt);
    padding: var(--bui_spacing_4x)
}

.obp-discounts-table__header-price,.obp-discounts-table__normal-price-cell {
    text-align: end
}

@media (min-width: 1024px) {
    .obp-discounts-table__header {
        padding:var(--bui_spacing_4x)
    }

    .obp-discounts-table {
        margin: var(--bui_spacing_6x) 0 0 0
    }

    .obp-discounts-table__header-discount,.obp-discounts-table__normal-price-no-discount {
        text-align: initial
    }
}

.obp-discounts-table__discount {
    border-top: 1px solid var(--bui_color_border_alt);
    padding: var(--bui_spacing_4x)
}

.obp-discounts-table__num-guests,.obp-discounts-table__price {
    align-items: center;
    display: flex
}

.obp-discounts-table__discount-value input {
    width: 100%
}

.obp-discounts-table__price {
    justify-content: flex-end
}

.overview__footer {
    margin-top: 20px
}

.overview__header {
    padding: 15px
}

.overview__header-name {
    font-size: 20px;
    font-weight: 700
}

.overview__header-name .bui-bubble {
    vertical-align: text-bottom
}

.overview__header-address {
    color: var(--bui_color_foreground_alt)
}

.overview__header-actions {
    margin-top: 15px;
    position: relative
}

.overview__header-actions .badge {
    margin-left: 10px;
    top: 0
}

.overview__header-actions .overview-header-ctas svg {
    vertical-align: top
}

.overview__header {
    background-color: #fff
}

.edit-pane-item {
    display: flex;
    justify-content: space-between
}

.edit-pane-item>div:first-child {
    flex: 1
}

.edit-pane-item>div:last-child {
    flex: 1.25
}

.edit-pane-item__cta {
    margin-top: 10px
}

@media (min-width: 1024px) {
    .edit-pane-item>div:last-child {
        flex:1.5
    }
}

.edit-settings-pane li,.edit-settings-pane ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.edit-settings-pane__list {
    border: 1px solid var(--bui_color_border_alt);
    border-radius: 2px;
    color: var(--bui_color_foreground_alt)
}

.edit-settings-pane__list li {
    border-bottom: 1px solid var(--bui_color_border_alt);
    padding: 12px
}

.edit-settings-pane__list li:last-child {
    border-bottom: none
}

.screen-overview.ms-container {
    margin-left: auto;
    margin-right: auto
}

.screen-overview .wide-container {
    border: none;
    padding: 0
}

.units {
    background-color: #fff
}

.units__actions {
    border: 1px dashed var(--bui_color_border_alt);
    padding: 20px
}

@media (min-width: 1024px) {
    .screen-overview.ms-container {
        width:800px
    }
}

.data-with-title__title {
    color: var(--bui_color_foreground_alt)
}

.unit {
    border-top: 1px solid var(--bui_color_border_alt);
    display: flex;
    position: relative
}

.unit__image {
    height: 120px;
    position: relative;
    width: 90px
}

.is_long_language .unit__image {
    width: 95px
}

.unit__image img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.unit__image .badge {
    border: none;
    bottom: 8px;
    padding: 2px 8px;
    position: absolute;
    right: 8px;
    top: auto
}

.unit__setup {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px
}

.unit__setup h3 {
    font-size: 16px;
    margin: 0
}

.unit__ctas {
    display: block;
    margin-left: -15px;
    margin-top: var(--bui_spacing_4x);
    padding-left: var(--bui_spacing_2x)
}

.is_long_language .unit__image .badge {
    font-size: 11px;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    right: 0
}

.is_long_language .unit__setup {
    padding: 12px 6px
}

.unit__image__taller {
    height: 194px
}

.unit-ctas-button--link.bui-button--secondary:before {
    border: none;
    color: var(--bui_color_action_foreground)
}

.unit-ctas-button--link:hover {
    background-color: #fff
}

.unit-ctas-button--link .bui-button__icon {
    height: 24px;
    width: 24px
}

.unit__data {
    display: block
}

.unit__data li,.unit__data ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.unit__data ul {
    display: flex;
    flex: 1;
    justify-content: flex-start
}

.unit__data li {
    border-left: 1px solid var(--bui_color_border_alt);
    flex: 1;
    margin-right: 40px;
    padding-left: 10px
}

.unit__data li:first-child {
    border-left: none;
    padding-left: 0
}

.unit__data li:last-child {
    margin-right: 0
}

.unit__data-price-no-wrap {
    white-space: nowrap
}

@media (min-width: 1024px) {
    .is_long_language .unit__image,.unit__image {
        width:165px
    }

    .unit__data {
        display: flex;
        justify-content: space-between
    }

    .unit__data li {
        flex: 0 1 auto
    }

    .unit__ctas {
        padding-left: 0
    }

    .is_long_language .unit__image .badge {
        font-size: 12px;
        left: auto;
        padding-left: var(--bui_spacing_2x);
        padding-right: var(--bui_spacing_2x);
        right: 8px
    }

    .is_long_language .unit__setup {
        padding: 15px
    }

    .unit__image__taller {
        height: 120px
    }
}

@media (max-width: 320px) {
    .unit__data {
        width:100%
    }

    .unit__image {
        display: none
    }

    .unit:after {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 4px
    }

    .unit--complete:after {
        background: var(--bui_color_constructive_background)
    }

    .unit--in-progress:after {
        background: var(--bui_color_callout_background)
    }
}

.actionsDetails {
    margin-top: var(--bui_spacing_1x)
}

.actionsButtons {
    margin-top: var( --bui_spacing_4x )!important
}

.actionsLinks {
    align-self: flex-start
}

.cardActionContainer {
    align-self: center;
    text-align: right
}

.mobileCard {
    margin: var(--bui_spacing_4x)
}

.action-btn-right-side-end {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center
}

.sectionDetails.sectionDetails-mobile {
    margin-bottom: var(--bui_spacing_4x)
}

.screen-overview--room .wide-container {
    background: inherit
}

.screen-overview--room.ms-container {
    margin-left: auto;
    margin-right: auto
}

.screen-overview--room .wide-container {
    border: none;
    padding: 0
}

@media (min-width: 1024px) {
    .screen-overview--room.ms-container {
        width:800px
    }
}

.mobileAlert {
    border-left: none;
    border-right: none
}

.sectionDetails>.bui-inline-container__end {
    align-self: center;
    justify-content: flex-end;
    width: 33%
}

.roomsCardUnit {
    display: flex
}

.roomsCardUnit>div {
    border-left: 1px solid var(--bui_color_border_alt);
    padding-left: var(--bui_spacing_1x);
    padding-right: var(--bui_spacing_4x)
}

.roomsCardUnit>div:first-child {
    border-left: 0;
    padding-left: 0
}

.roomsCardUnit>div:last-child {
    border-right: none;
    margin-right: inherit;
    padding-right: inherit
}

.roomsCards {
    border-left: inherit!important;
    border-right: inherit!important;
    border-top: inherit!important;
    padding: var(--bui_spacing_4x) 0 var(--bui_spacing_4x)!important
}

.roomsCardsMobile {
    border-bottom: inherit!important
}

.roomsCardsContainer {
    border-bottom: 1px solid var(--bui_color_border_alt)!important;
    padding-bottom: var( --bui_spacing_4x )!important
}

.roomsCards .bui-card__title,.roomsCardsContainer {
    margin-bottom: var( --bui_spacing_2x )!important
}

.form-container.screen-welcome {
    box-sizing: border-box;
    padding: 40px
}

@media (min-width: 1024px) {
    .form-container.screen-welcome {
        background:#fff;
        border: 1px solid #ececec;
        border-radius: 2px
    }
}

.form-container.screen-welcome h2 {
    font-size: 24px!important;
    line-height: 32px;
    padding: 0!important
}

.form-container.screen-welcome .main-title {
    padding-top: 0!important
}

.form-container.screen-welcome .wide-container {
    border: none;
    font-size: 16px!important;
    padding: 0!important
}

.multy-choice ul li {
    list-style: none
}

.credit-card-icon {
    background: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/cc_vert.png) no-repeat;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    margin-bottom: -11px;
    margin-top: -2px;
    position: relative;
    width: 40px
}

.americanexpress {
    background-position: 0 -216px
}

.visa {
    background-position: 0 0
}

.euromastercard {
    background-position: 0 -36px
}

.dinersclub {
    background-position: 0 -144px
}

.discover {
    background-position: 0 -108px
}

.maestro {
    background-position: 0 -72px
}

.switch {
    background-position: 0 -252px
}

.solo {
    background-position: 0 -468px
}

.jcb {
    background-position: 0 -180px
}

.cartebleue {
    background-position: 0 -396px
}

.hipercard {
    background-position: 0 -360px
}

.argencard {
    background-position: 0 -432px
}

.unionpaycreditcard,.unionpaydebitcard {
    background-position: 0 -288px
}

.eccard {
    background-position: 0 -324px
}

.cartasi {
    background-position: 0 -504px
}

.carteblanche {
    background-position: 0 -540px
}

.eftpos {
    background-position: 0 -576px
}

.uc {
    background-position: 0 -612px
}

.bancontact {
    background-position: 0 -648px
}

.nicos {
    background-position: 0 -684px
}

.redcompra {
    background-position: 0 -720px
}

.bccard {
    background-position: 0 -756px
}

.cabal {
    background-position: 0 -792px
}

.australianbankcard {
    background-position: 0 -828px
}

.elocreditcard {
    background-position: 0 -934px
}

.atmcard,.chipper,.dragon,.greatwall,.jinsui,.khszchnyipihenkrtya,.khszechenyipihenokartya,.mkbszchnyipihenkrtya,.mkbszechenyipihenokartya,.othercards,.otpszchnyipihenkrtya,.otpszechenyipihenokartya,.pacific,.peony,.pin,.red6000 {
    background-position: 0 -864px;
    height: 28px;
    line-height: 25px;
    min-width: 17px
}

.americanexpress {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/amex.svg)
}

.visa {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/visa.svg)
}

.euromastercard {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/mc.svg)
}

.dinersclub {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/diners.svg)
}

.discover {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/discover.svg)
}

.maestro {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/maestro.svg)
}

.jcb {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/jcb.svg)
}

.cartebleue {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/cartebancaire.svg)
}

.hipercard {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/hipercard.svg)
}

.unionpaycreditcard,.unionpaydebitcard {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/unionpay.svg)
}

.mir {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/mir.svg)
}

.carteblanche {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/carteblanche.svg)
}

.bankcard {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/bankcard.svg)
}

.atmcard,.chipper,.dragon,.greatwall,.jinsui,.khszchnyipihenkrtya,.khszechenyipihenokartya,.mkbszchnyipihenkrtya,.mkbszechenyipihenokartya,.othercards,.otpszchnyipihenkrtya,.otpszechenyipihenokartya,.pacific,.peony,.pin,.red6000 {
    background: url(https://q.bstatic.com/static/img/payments/payment_icons_redesign/generic-cc.svg)
}

.eccard {
    height: 28px;
    width: 46px
}

.bathroom-privacy-question .bui-form__label {
    font-size: var(--bui_spacing_4x);
    font-weight: 700;
    line-height: var(--bui_spacing_6x);
    margin-bottom: var(--bui_spacing_4x)
}

.grace-period__caption {
    padding-top: var(--bui_spacing_4x)
}

.policies__label {
    display: block;
    margin-top: 0
}

.policies__core-label {
    font-family: var(--bui_font_strong_2_font-family);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height)
}

.policies__spacing {
    padding-bottom: var(--bui_spacing_8x)
}

.policies__free-cancellation-recommended {
    left: 50%;
    position: absolute;
    top: calc(var(--bui_spacing_6x)*-1);
    transform: translate(-50%);
    white-space: nowrap
}

.policies__free-cancellation-segmented-control--desktop .bui-segmented-control__item {
    position: relative
}

.policies__free-cancellation-segmented-control--desktop {
    margin-top: var(--bui_spacing_8x);
    padding: 0 var(--bui_spacing_4x)
}

.policies-info {
    display: flex
}

.policies-info__icon {
    flex-grow: 0;
    flex-shrink: 0
}

.policies-info__text {
    margin-left: var(--bui_spacing_2x)
}

@media (-ms-high-contrast:active),(-ms-high-contrast:none) {
    .policies__free-cancellation-segmented-control--desktop>span {
        position: relative
    }

    .policies__free-cancellation-segmented-control--desktop label {
        position: static
    }

    .policies__free-cancellation-recommended {
        left: 0;
        transform: none
    }
}

.exact-layout-rooms-label {
    display: block;
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height)
}

.exact-layout-rooms--remove-label .bui-stepper__title-wrapper {
    display: none
}

.unit-name-reassurance__lists {
    padding-left: 12px
}

.payment-charge--info {
    padding-left: 5px
}

.guest-payment--header {
    margin-top: 0
}

.booking-payment-advice--loader {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.booking-payment-advice__container--loader {
    opacity: .2
}

.payment-by-booking-tooltip-list {
    list-style: none;
    padding-left: 0
}

.payment-by-booking-banner {
    margin-bottom: var(--bui_spacing_4x);
    margin-top: var(--bui_spacing_4x);
    padding-left: var(--bui_spacing_8x);
    padding-right: var(--bui_spacing_8x)
}

@media (min-width: 1024px) {
    .payment-by-booking-banner {
        margin-bottom:0;
        margin-top: var(--bui_spacing_4x);
        padding-left: var(--bui_spacing_4x);
        padding-right: var(--bui_spacing_4x)
    }
}

.channel-sync-ical-reassurance {
    margin: 0 20px var(--bui_spacing_6x)
}

.bui_font_heading.channel-sync-ical-reassurance__header-text {
    line-height: 29px;
    padding-left: 42px
}

.channel-sync-ical-reassurance__icon {
    float: left
}

.calendar-sync__popover-container {
    display: inline-block
}

.calendar-sync__popover-anchor {
    margin-left: var(--bui_spacing_1x)
}

.calendar-sync__popover-content {
    padding: var(--bui_spacing_2x)
}

.calendar-sync__popover-close--fix {
    float: right;
    height: var(--bui_spacing_8x);
    width: var(--bui_spacing_8x)
}

.calendar-sync__card {
    margin-top: var(--bui_spacing_2x)
}

@media (min-width: 1024px) {
    .channel-sync-ical-reassurance {
        margin:0 0 var(--bui_spacing_6x)
    }
}

.calendar-sync-ical-form {
    display: flex
}

.calendar-sync-ical-form__input {
    flex: 1 1
}

.calendar-sync-ical-form__input>.bui-form__label {
    white-space: nowrap
}

.calendar-sync-ical-form__button {
    align-self: flex-start;
    border-radius: 0;
    position: relative;
    top: var(--bui_spacing_6x)
}

.is_long_language .calendar-sync-ical-form__button {
    padding-left: var(--bui_spacing_1x);
    padding-right: var(--bui_spacing_1x)
}

@media (min-width: 1024px) {
    .calendar-sync__card {
        margin-left:var(--bui_spacing_6x)
    }

    .calendar-sync-ical-form__button {
        border-radius: 2px;
        margin-left: var(--bui_spacing_2x)
    }

    .is_long_language .calendar-sync-ical-form__button {
        padding-left: var(--bui_spacing_4x);
        padding-right: var(--bui_spacing_4x)
    }

    .ms-container.ms-container--calendar-sync {
        width: 734px
    }

    .ms-container.ms-container--calendar-sync .page-tooltip__container {
        left: 734px
    }
}

.find-ical-mobile-modal__intructions li {
    list-style: none
}

.container-class {
    margin-top: var(--bui_spacing_8x)
}

.select-component>div {
    max-width: 328px
}

.select-component-mobile>div {
    max-width: 100%
}

.read-more-link {
    display: block;
    margin-top: var(--bui_spacing_2x)
}

.f38d4ed64e {
    margin-bottom: var(--bui_spacing_2x);
    margin-top: var(--bui_spacing_4x)
}

.cfc67812fe {
    margin-top: var(--bui_spacing_8x)
}

.d58244fa5e {
    align-items: center;
    display: flex
}

.a31cf298fa {
    margin-left: var(--bui_spacing_2x)
}

.be705d4e58 {
    align-items: center;
    display: flex
}

.ca438d85c8 {
    margin-right: var(--bui_spacing_4x)
}

.input-field-nudge__gray {
    color: var(--bui_color_foreground_alt)
}

.fa42160220:first-of-type {
    flex: 2 1 0
}

.fa42160220:nth-of-type(2) {
    flex: 5 1 0
}

.fa42160220:nth-of-type(3) {
    flex: 3 1 0
}

.ebf9569d30 .fa42160220:first-of-type {
    flex-grow: 3
}

.ebf9569d30 .fa42160220:nth-of-type(3) {
    flex-grow: 2
}

.f77241d040 .fa42160220:first-of-type {
    flex-grow: 5
}

.f77241d040 .fa42160220:nth-of-type(2) {
    flex-grow: 2
}

.invoice-email-header {
    margin-top: 0
}

.languages__container--fixedHeight {
    min-height: 594px
}

.languages__additional--spacing {
    min-height: 214px
}

.languages__additional--spacingMobile {
    min-height: 206px
}

.languages__additionalMultiSelect--Mobile {
    padding-bottom: 22px;
    width: 80%
}

.languages__additionalMultiSelect--Hidden {
    visibility: hidden
}

.languages__additionalMultiSelect {
    padding-bottom: 10px
}

.languages__additionalMultiSelect .bui-input-multiselect--active,.languages__additionalMultiSelect .bui-input-multiselect__box {
    z-index: 9
}

.breakfast-offered-question .bui-form__label {
    font-size: var(--bui_spacing_4x);
    font-weight: 700;
    line-height: var(--bui_spacing_6x);
    margin-bottom: var(--bui_spacing_4x)
}

.bui-icon.bui-input-checkbutton__uncheck {
    display: none
}

.price_non_refundable_label {
    font-family: var(--bui_font_emphasized_2_font-family);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height)
}

.mup-calendar-sync-grid.bui-grid>.bui-grid__column-12 {
    padding-top: var(--bui_spacing_4x)
}

.parking_payment {
    display: flex
}

.cc0b8539fe {
    margin-top: var(--bui_spacing_6x)
}

.ff73c53bfe {
    margin-bottom: var(--bui_spacing_8x);
    margin-top: calc(var(--bui_spacing_8x)*-1)
}

.df624ccb89 {
    margin-left: calc(var(--bui_spacing_4x)*-1)
}

.d9833937a8 {
    margin-bottom: 0
}

@media (min-width: 1024px) {
    .cc0b8539fe {
        margin-top:0
    }

    .d9833937a8 {
        margin-bottom: var(--bui_spacing_8x)
    }
}

.category-card {
    margin-bottom: var(--bui_spacing_6x)
}

.category-card__content {
    display: inline-flex;
    overflow: hidden
}

.category-card__btn {
    display: none
}

.category-card__icon {
    padding-right: var(--bui_spacing_4x);
    width: 20%
}

.category-card__body {
    padding-right: var(--bui_spacing_6x);
    width: 70%
}

.category-card__body .category-card__header {
    margin-bottom: var(--bui_spacing_2x)
}

.category-card__arrow-container {
    align-items: center;
    display: flex;
    width: 10%
}

@media (min-width: 1024px) {
    .category-card {
        border:none;
        box-sizing: border-box;
        cursor: pointer;
        height: 220px;
        margin: var(--bui_spacing_6x) 0;
        padding: 0 var(--bui_spacing_4x);
        position: relative;
        width: 250px
    }

    .card-long-language.category-card {
        height: 255px;
        width: 280px
    }

    .category-card__content {
        display: block;
        height: 100%
    }

    .card {
        border: 0!important;
        height: 100%;
        padding: 0!important;
        position: relative
    }

    .category-card .category-card__btn {
        bottom: 0;
        display: block;
        position: absolute;
        width: 100%
    }

    .category-card__arrow-container {
        display: none
    }

    .category-card__description,.category-card__header,.category-card__icon {
        text-align: center
    }

    .category-card__body,.category-card__icon {
        padding: 0;
        width: 100%
    }
}

.custom-badge {
    background: var(--bui_color_background_base);
    border-radius: 3px;
    color: var(--bui_color_black);
    line-height: 1.8;
    padding: 0 var(--bui_spacing_3x);
    position: absolute;
    top: -12px;
    z-index: 1
}

.custom-badge--constructive {
    background: var(--bui_color_constructive_background);
    color: #fff;
    margin-left:60px
}

.custom-badge svg {
    vertical-align: bottom
}

.category-card-group--quick-start {
    position: relative
}

.category-card-group {
    display: block;
    margin-bottom: var(--bui_spacing_6x)
}

.category-card-group .category-card:last-child {
    margin-bottom: 0;
    width:200px
}

@media (min-width: 1024px) {
    .category-card-group {
        background-color:var(--bui_color_background_base);
        border: 1px solid var(--bui_color_border_alt);
        display: flex;
        justify-content: space-around;
        margin-bottom: 0;
        width: 1230px;
        /* height:300px; */
        margin-left: -106px;
    }

    .category-card-group .card,.category-card-group .category-card {
        border: none
    }

    .category-card-group .category-card {
        border-left: 1px solid var(--bui_color_border_alt)
    }

    .category-card-group--quick-start .category-card,.category-card-group .category-card:first-child {
        border-left: none
    }

    .category-card-group:nth-child(2) {
        margin-left: var(--bui_spacing_6x)
    }
}

.category-screen-container {
    display: flex;
    flex-direction: column
}

@media (min-width: 1024px) {
    .container--no-padding {
        padding:0
    }

    .ms-container.ms-container--full-width {
        width: 1020px
    }

    .category-screen-container {
        flex-direction: row;
        justify-content: space-between
    }

    .ms-container.ms-container--full-width .wide-container {
        background: none;
        border: none
    }
}

@keyframes load8 {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

.selectable-box,:after,:before {
    box-sizing: border-box
}

.selectable-box {
    color: var(--bui_color_foreground);
    cursor: pointer;
    display: block;
    min-height: 80px;
    position: relative;
    width: 100%
}

.selectable-box--wide {
    border: none;
    padding: 2px;
    width: 100%
}

.selectable-box--wide:first-child {
    margin-bottom: var(--bui_spacing_4x)
}

.selectable-box-card-text--center {
    align-items: center;
    display: flex;
    height: 100%
}

.selectable-box-card__text-container {
    padding-left: var(--bui_spacing_2x)
}

.selectable-box-card-text--center .selectable-box-card__text {
    display: inline-block;
    vertical-align: middle
}

.selectable-box--selected {
    border: 3px solid #70bbe9;
    padding: 14px
}

.selectable-box--wide.selectable-box--selected {
    padding: 0
}

.selectable-box-card__img:before {
    background-color: inherit
}

.selectable-box-card__img img {
    -o-object-fit: contain!important;
    object-fit: contain!important
}

.selectable-box--selected:before {
    background: #70bbe9;
    border-radius: 50%;
    color: #fff;
    content: "\2713";
    display: table-cell;
    height: 20px;
    padding-left: var(--bui_spacing_1x);
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px
}

@media (min-width: 1024px) {
    .selectable-box-card__text-container {
        padding-left:0
    }
}

.selectable-box-group .selectable-box {
    margin-right: 0
}

@media (min-width: 1024px) {
    .selectable-box-group .selectable-box:first-child {
        margin-bottom:var(--bui_spacing_3x)
    }

    .selectable-box-group .selectable-box--wide:first-child {
        margin-bottom: var(--bui_spacing_6x)
    }
}

.property-type-card :after,.property-type-card :before {
    box-sizing: border-box
}

.property-type-card {
    cursor: pointer;
    padding: 3px;
    position: relative
}

.property-type-card .card-property-type {
    height: 150px
}

.property-type-card--selected {
    border: 3px solid #70bbe9;
    padding: 0
}

.property-type-card--selected:before {
    background: #70bbe9;
    border-radius: 50%;
    color: #fff;
    content: "\2713";
    display: table-cell;
    height: 20px;
    padding-left: 4px;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px
}

@media (max-width: 768px) {
    .property-type-card .card-property-type {
        height:100%
    }
}

.property-type-card-group * {
    box-sizing: border-box
}

.property-type-card-group {
    background-color: var(--bui_color_background_base);
    box-sizing: border-box;
    padding: var(--bui_spacing_4x);
    position: relative;
    width: 100%
}

.property-type-card-group__button {
    background: var( --bui_color_background_base )!important;
    border: 1px solid var(--bui_color_border_alt)!important;
    box-sizing: content-box!important;
    color: var( --bui_color_action_foreground )!important;
    height: 145px!important;
    margin-top: 2px!important;
    min-height: 60px!important;
    padding: 3px!important;
    width: 97%!important
}

.bui-f-font-defaults .property-type-card-group__button {
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height)
}

@media (max-width: 1024px) {
    .property-type-card-group .property-type-card-grid {
        width:100%
    }
}

@media (max-width: 768px) {
    .property-type-card-group {
        padding-left:0
    }

    .is_right_to_left .property-type-card-group {
        padding-right: 0
    }

    .property-type-card-group__button {
        height: auto;
        min-height: 60px
    }
}

.missing-property-type-modal {
    margin-bottom: var(--bui_spacing_6x);
    margin-left: var(--bui_spacing_4x);
    margin-top: calc(var(--bui_spacing_4x)*-1)
}

.bui-f-font-defaults .missing-property-type-modal__open-button {
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height)
}

@media (min-width: 1024px) {
    .missing-property-type-modal {
        margin-bottom:0
    }
}

.property-number-label {
    display: block;
    margin-top: var(--bui_spacing_6x)
}

.property-number-input {
    width: 80px
}

.main {
    margin-top: 5%;
    text-align: center
}

.img-container {
    padding-top: var(--bui_spacing_6x)
}

.feedback-loop-img-container img,.img-container img {
    height: 75px;
    width: 75px
}

.feedback-loop-description {
    padding: var(--bui_spacing_8x)
}

.feedback-loop-question {
    margin-bottom: var(--bui_spacing_4x);
    padding-top: var(--bui_spacing_12x)
}

.feedbackloop-cta-mobile {
    display: block
}

.feedbackloop-cta-desktop {
    display: none
}

@media (max-width: 768px) {
    .feedback-loop-question {
        left:0;
        padding: 0;
        position: absolute;
        right: 0;
        text-align: center
    }

    .feedback-loop-description {
        padding: var(--bui_spacing_8x) 0
    }
}

@media (min-width: 1024px) {
    .feedbackloop-cta-mobile {
        display:none
    }

    .feedbackloop-cta-desktop {
        display: block
    }
}

.ota-placeholder-container {
    margin-bottom: var(--bui_spacing_4x)
}

.ota-title {
    margin-bottom: var(--bui_spacing_6x)
}

.ota-label {
    margin-bottom: var(--bui_spacing_1x)
}

.ota-help {
    font-size: var(--bui_spacing_4x);
    margin-bottom: var(--bui_spacing_8x)
}

.ota_import_listing_guidance {
    margin-bottom: var(--bui_spacing_6x)
}

.mobileModalSvg-container {
    padding-bottom: 0;
    padding-left: var(--bui_spacing_8x);
    padding-right: var(--bui_spacing_8x);
    padding-top: var(--bui_spacing_4x)
}

.airbnb-title {
    margin-left: var(--bui_spacing_2x);
    margin-right: var(--bui_spacing_2x);
    vertical-align: middle
}

.airbnb-body {
    color: var(--bui_color_background)
}

.airbnb-link-input {
    display: flex;
    margin-bottom: var(--bui_spacing_2x)
}

.airbnb-link-input__input {
    flex-grow: 1;
    width: 70%
}

.airbnb-link-input__button {
    flex-grow: 1;
    flex-shrink: 0;
    margin-left: var(--bui_spacing_2x)
}

.is_right_to_left .airbnb-link-input__button {
    margin-left: 0;
    margin-right: var(--bui_spacing_2x)
}

.airbnb-success-extra-spacing {
    height: var(--bui_spacing_2x)
}

.page__content .bui-form__group {
    padding-bottom: var(--bui_spacing_4x)
}

.ota-radio-group .bui-group>* {
    margin-top: var(--bui_spacing_2x)
}

.ota-radio-group .bui-group .bui-group>:first-child {
    margin-top: 0
}

.ota-import-link-divider,.ota-supported-header {
    margin-bottom: var(--bui_spacing_8x)
}

.otaq_none_input {
    margin-bottom: var(--bui_spacing_4x)
}

.conversion-checklist-setting-card {
    display: block!important;
    width: 100%!important
}

.card-wrapper {
    align-items: center!important;
    display: flex!important;
    justify-content: space-between!important
}

.card-container {
    border-radius: var(--bui_spacing_1x)!important
}

.ececc4da99 {
    margin-bottom: var(--bui_spacing_16x)
}

@media (min-width: 1024px) {
    .ececc4da99 {
        margin-bottom:inherit;
        padding: 0
    }
}

.cfa26f04f2 {
    background-color: var(--bui_color_action_background_alt)
}

.progress {
    background-color: #dcdcdc;
    border-radius: 0;
    height: 8px;
    margin-bottom: 0;
    overflow: hidden
}

.progress.progress--add-spacing {
    margin: var(--bui_spacing_4x)
}

.progress-bars {
    height: 8px;
    position: relative
}

.progress.completed,.progress .completed {
    background-color: #07c
}

.progress-bars .completed {
    left: 0;
    position: absolute;
    top: 0;
    transition: width .2s ease-in;
    width: 0
}

.progressbar-mark {
    border-left: 2px solid #fff;
    height: 8px;
    position: absolute;
    top: 0;
    width: 0
}

.completed {
    padding-bottom: 15%;
    padding-top: 20%;
    text-align: center
}

.e7eada0f12 {
    margin: 0 var(--bui_spacing_half);
    padding-bottom: var(--bui_spacing_6x)
}

.c1abf6012e {
    display: flex;
    padding: 0 var(--bui_spacing_6x)
}

.bcb19481d8 {
    align-items: center;
    display: flex
}

.a7cf972b52 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1px 2px 0;
    min-height: 60px;
    width: 100%
}

.a7cf972b52 .b3e064af69 {
    background-color: transparent;
    border: 0;
    color: var(--bui_color_foreground);
    flex-grow: 1;
    font-weight: 300
}

.a7cf972b52:hover {
    background-color: var(--bui_color_highlighted_alt)
}

.a7cf972b52 .b3e064af69:hover,.a7cf972b52:hover {
    color: var(--bui_color_action_foreground)
}

.a7cf972b52 .b3e064af69:focus {
    box-shadow: none;
    outline: none
}

.a764e67eb6 .b3e064af69 {
    pointer-events: none
}

.a764e67eb6:hover,.dce71f7971:hover {
    background-color: transparent
}

.dce71f7971 .b3e064af69,.dce71f7971 .b3e064af69:hover {
    color: var(--bui_color_highlighted_alt)
}

.dce71f7971 .b3e064af69:hover {
    background-color: transparent
}

.a66825fddd {
    display: flex;
    justify-content: space-between;
    visibility: hidden
}

.f9afd8f1a3 {
    visibility: visible
}

.c2ed34f808 {
    background-color: var(--bui_color_foreground_disabled);
    height: var(--bui_spacing_1x);
    margin: 0 1px;
    width: 100%
}

.b8d908b0ab {
    background-color: #97e59c
}

.be2293f8d4 {
    background-color: #70bbe9
}

.a7cf972b52 .b3e064af69:after,.a7cf972b52 .b3e064af69:before {
    border: 0
}

.e39a69cc78 {
    align-items: center;
    display: flex;
    height: 100%
}

.a6f5815c08 {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: var(--bui_spacing_2x)
}

.b541719d77 {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 270px;
    width: 100%
}

.e2c95bc49b {
    margin-top: var(--bui_spacing_2x)
}

.d7e0407102 {
    grid-row-gap: var(--bui_spacing_1x);
    display: grid;
    grid-template-columns: 1fr 1fr
}

.d35752a6df {
    background: var(--bui_color_background_base)
}

.d9f421c828 {
    margin-top: var(--bui_spacing_2x)
}

.b71e07191b {
    align-items: center;
    display: flex;
    height: 270px;
    justify-content: center;
    width: 100%
}

.dea28cccec {
    height: 64px;
    width: 64px
}

.e15cf47736 {
    align-items: center;
    background: var(--bui_color_foreground_inverted);
    border-bottom: 2px solid var(--bui_color_border_alt);
    display: flex;
    justify-content: center
}

.e4774336f6 {
    margin-right: var(--bui_spacing_2x)
}

.af4a5c1432 {
    min-height: 0
}

.survey-gizmo-container {
    bottom: var(--bui_spacing_8x);
    display: none;
    position: fixed;
    right: var(--bui_spacing_8x);
    width: 300px;
    z-index: 10
}

@media (min-width: 1024px) {
    .survey-gizmo-container {
        display:block
    }
}

.survey-gizmo-block {
    background: #70bbe9;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
    padding: var(--bui_spacing_4x);
    position: relative
}

.survey-gizmo-block-close {
    background: transparent;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    height: 36px;
    opacity: .75;
    padding: var(--bui_spacing_2x);
    position: absolute;
    right: 0;
    top: 0;
    width: 36px
}

.survey-gizmo-title {
    margin-top: 0;
    padding-right: var(--bui_spacing_8x)
}

.ms-container {
    justify-content: space-between
}

.application,.ms-container {
    display: flex;
    flex-direction: column;
    height: 100%
}

.application {
    flex-grow: 1
}

.application>* {
    flex-shrink: 0
}

.page-container {
    display: flex;
    flex-grow: 1
}

@media (min-width: 1024px) {
    .page-container {
        display:block
    }

    .ms-container.ms-container--full-width {
        width: 1020px
    }

    .ms-container.ms-container--full-width .wide-container {
        background: none;
        border: none
    }
}

.height-100 {
    height: 100%
}

@media (orientation: landscape) {
    .ms-container {
        max-width:500px
    }
}

.screens {
    width: 100%
}

label {
    color: var(--bui_color_foreground);
    font-size: var(--bui_spacing_4x);
    font-weight: 400;
    line-height: var(--bui_spacing_6x)
}

.form-group {
    margin: 0!important;
    padding-bottom: 20px!important
}

.form-container--mobile-flex {
    display: flex;
    flex-direction: column
}

.form-container {
    position: relative
}

.form-container--static--submit--spacing {
    padding-bottom: calc(var(--bui_spacing_8x)*2)
}

.smallText {
    color: var(--bui_color_foreground_alt);
    padding-bottom: 25px
}

.bui-popover--bottom {
    left: 10px!important;
    right: 10px!important
}

.bui-popover--bottom .payment-charge-popover {
    padding-right: 34px;
    padding-top: 4px
}

.bui-popover-with-close-btn-content-fix {
    padding-right: 40px
}

@media (min-width: 576px) {
    .ms-container {
        max-width:500px
    }
}

@media (min-width: 1024px) {
    .screens {
        margin:auto;
        padding-top: 30px;
        position: relative
    }

    .ms-container {
        margin-bottom: 0;
        margin-left: 10vw;
        max-width: none;
        width: 500px
    }

    .ms-container:after {
        clear: both;
        content: ".";
        display: block;
        height: 0;
        visibility: hidden
    }

    .form-overflow-container {
        margin: 0 calc(var(--bui_spacing_4x)*-1);
        width: 849px
    }

    .form-container--mobile-flex {
        display: block
    }

    .wide-container {
        background: #fff;
        border: 1px solid #ececec;
        border-radius: 2px;
        padding-bottom: var( --bui_spacing_6x )!important;
        padding-top: var( --bui_spacing_6x )!important
    }

    .wide-container--entitled {
        padding-top: 0
    }

    .second-container {
        margin-top: 20px
    }

    .min-height {
        min-height: calc(70vh - 140px)
    }
}

.fade-enter {
    opacity: .01
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity .3s ease
}

.fade-exit {
    opacity: 1
}

.fade-exit.fade-exit-active {
    opacity: .01;
    transition: opacity .3s ease
}

.fade-enter-active.page-loading,.fade-exit.page-loading {
    opacity: .3
}

.error-popup-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10
}

.error-popup,.error-popup-bui-alert {
    margin: 30px 5%
}

.error-popup {
    background-color: var(--bui_color_accent_background_alt);
    border: 1px solid var(--bui_color_accent_background);
    padding: 15px 30px 15px 15px;
    position: relative
}

.error-popup__warning-icon {
    color: var(--bui_color_accent_foreground);
    margin-right: 10px
}

.error-popup__close-button {
    background: none;
    border: none;
    color: var(--bui_color_accent_foreground);
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 10px
}

.error-popup__close-button:focus {
    outline: none
}

@media (min-width: 1024px) {
    .error-popup {
        margin:30px 25%
    }
}

.slideup-enter {
    margin-top: -70px
}

.slideup-enter.slideup-enter-active {
    margin-top: 30px;
    transition: margin-top .3s ease
}

.has-error .form-control {
    border-color: var(--bui_color_destructive_highlighted)
}

button.bui-link {
    padding: 0
}

button.bui-link:focus {
    outline: none
}

button.bui-button--large {
    font-size: var(--bui_spacing_4x);
    font-weight: 400;
    line-height: var(--bui_spacing_6x);
    min-height: calc(var(--bui_spacing_1x)*12 + 2px);
    min-width: calc(var(--bui_spacing_1x)*12 + 2px);
    padding: calc(var(--bui_spacing_6x)/2) var(--bui_spacing_6x)
}

.input-stepper .bui-stepper__display {
    color: var(--bui_color_foreground)
}

.toast-15min {
    bottom: 75px
}

@media (min-width: 1024px) {
    .toast-15min {
        bottom:var(--bui_spacing_6x)
    }
}

.ota-import-status-container {
    margin: var(--bui_spacing_4x)
}

@media (min-width: 1024px) {
    .ota-import-status-container {
        margin:0 0 var(--bui_spacing_4x) 0
    }
}

.iphone-bui-zoom-fix.bui-f-font-defaults .bui-input-select .bui-form__control,.iphone-bui-zoom-fix.bui-f-font-defaults .bui-input-text__control {
    font-size: 16px
}

.env-marker {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1
}

.screen-change-enter.screen-change-enter-active .form-cta,.screen-change-exit.screen-change-exit-active .form-cta {
    bottom: 32px
}

.screens-animation-direction-forward>.screen-change-enter {
    opacity: .01;
    position: fixed;
    transform: translateX(100vw)
}

.screens-animation-direction-forward>.screen-change-enter.screen-change-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1s ease,transform .4s ease
}

.screens-animation-direction-forward>.screen-change-exit {
    opacity: 1;
    position: fixed;
    transform: translateX(0)
}

.screens-animation-direction-forward>.screen-change-exit.screen-change-exit-active {
    opacity: .01;
    transform: translateX(-100vw);
    transition: opacity 1s ease,transform .4s ease
}

.screens-animation-direction-backward>.screen-change-enter {
    opacity: .01;
    position: fixed;
    transform: translateX(-100vw)
}

.screens-animation-direction-backward>.screen-change-enter.screen-change-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1s ease,transform .4s ease
}

.screens-animation-direction-backward>.screen-change-exit {
    opacity: 1;
    position: fixed;
    transform: translateX(0)
}

.screens-animation-direction-backward>.screen-change-exit.screen-change-exit-active {
    opacity: .01;
    transform: translateX(100vw);
    transition: opacity 1s ease,transform .4s ease
}

@media (min-width: 576px) and (orientation:landscape),(min-width:1024px) {
    .screens-animation-direction-forward>.screen-change-enter {
        transform:translateX(500px)
    }

    .screens-animation-direction-forward>.screen-change-enter.screen-change-enter-active {
        transform: translateX(0);
        transition: opacity .4s ease,transform .4s ease
    }

    .screens-animation-direction-forward>.screen-change-exit {
        transform: translateX(0)
    }

    .screens-animation-direction-forward>.screen-change-exit.screen-change-exit-active {
        transform: translateX(calc(-10vw - 500px));
        transition: opacity .4s ease,transform .4s ease
    }

    .screens-animation-direction-backward>.screen-change-enter {
        transform: translateX(calc(-10vw - 500px))
    }

    .screens-animation-direction-backward>.screen-change-enter.screen-change-enter-active {
        transform: translateX(0);
        transition: opacity .4s ease,transform .4s ease
    }

    .screens-animation-direction-backward>.screen-change-exit {
        transform: translateX(0)
    }

    .screens-animation-direction-backward>.screen-change-exit.screen-change-exit-active {
        transform: translateX(1000px);
        transition: opacity .4s ease,transform .4s ease
    }
}

body,html {
    height: 100%
}

body {
    background-color: #fff;
    margin: 0
}

#app-container,#app-container>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    height: 100%;
    height: -webkit-fill-available
}

[data-capla-component="b-registration-joinapp15min-fe/"],[data-capla-component="b-registration-joinapp15min-fe/"] .error-popup-container:first-of-type~div,[data-capla-component="b-registration-joinapp15min-fe/"]>div:first-child {
    height: 100%
}

@media (min-width: 1024px) {
    body {
        background-color:#f9f9fa!important
    }
}

.spacing--half_x {
    margin-bottom: calc(var(--bui_spacing_1x)/2)!important
}

.spacing--1x {
    margin-bottom: var( --bui_spacing_1x )!important
}

.spacing--2x {
    margin-bottom: var( --bui_spacing_2x )!important
}

.spacing--3x {
    margin-bottom: var( --bui_spacing_3x )!important
}

.spacing--4x {
    margin-bottom: var( --bui_spacing_4x )!important
}

.spacing--6x {
    margin-bottom: var( --bui_spacing_6x )!important
}

.spacing--8x {
    margin-bottom: var( --bui_spacing_8x )!important
}


.f419a93f12 {
    display: inline-block;
    vertical-align: middle
}

.d095a397d3 {
    display: block
}

.a0ac0ffd76 {
    opacity: 0;
    pointer-events: none;
    transition: var(--bui_animation_disappear);
    transition-property: opacity,transform,visibility;
    visibility: hidden;
    z-index: var(--bui_z_index_4)
}

.a0ac0ffd76 .f998a7c837 {
    display: inline-block;
    pointer-events: all;
    vertical-align: top
}

.a0ac0ffd76.bcb5482589,.a0ac0ffd76.d638038964,.a0ac0ffd76.f7d3384cf8 {
    transform: translateY(calc(var(--bui_spacing_4x)*-1))
}

.a0ac0ffd76.bcb5482589.eb4b382ac4,.a0ac0ffd76.d638038964.eb4b382ac4,.a0ac0ffd76.f7d3384cf8.eb4b382ac4 {
    transform: translateY(calc(var(--bui_spacing_2x)*-1))
}

.a0ac0ffd76.b00e0292fd,.a0ac0ffd76.e8c83e8bab,.a0ac0ffd76.eb89393781,.a0ac0ffd76.f38a56c611 {
    transform: translateY(var(--bui_spacing_4x))
}

.a0ac0ffd76.b00e0292fd.eb4b382ac4,.a0ac0ffd76.e8c83e8bab.eb4b382ac4,.a0ac0ffd76.eb89393781.eb4b382ac4,.a0ac0ffd76.f38a56c611.eb4b382ac4 {
    transform: translateY(var(--bui_spacing_2x))
}

.a0ac0ffd76.a132bc97d4,.a0ac0ffd76.b546b148f8,.a0ac0ffd76.ebf3c01873 {
    transform: translate(calc(var(--bui_spacing_4x)*-1))
}

.a0ac0ffd76.a132bc97d4.eb4b382ac4,.a0ac0ffd76.b546b148f8.eb4b382ac4,.a0ac0ffd76.ebf3c01873.eb4b382ac4 {
    transform: translate(calc(var(--bui_spacing_2x)*-1))
}

.a0ac0ffd76.aa07b5632a,.a0ac0ffd76.c4690e419a,.a0ac0ffd76.d0781bed8c {
    transform: translate(var(--bui_spacing_4x))
}

.a0ac0ffd76.aa07b5632a.eb4b382ac4,.a0ac0ffd76.c4690e419a.eb4b382ac4,.a0ac0ffd76.d0781bed8c.eb4b382ac4 {
    transform: translate(var(--bui_spacing_2x))
}

.a0ac0ffd76.eb4b382ac4 {
    opacity: 1;
    transition-duration: var(--bui_animation_appear_duration);
    transition-timing-function: var(--bui_animation_appear_timing_function);
    visibility: visible
}

.a0ac0ffd76.dd8d7ce4bd .f998a7c837 {
    transform: translate(0);
    transition-property: opacity
}

.a0ac0ffd76.b8f72989de {
    transition: none
}

.bdd5643ed9>span:first-child,.bdd5643ed9>span:last-child {
    height: var(--bui-flyout-arrow-size);
    position: absolute;
    width: var(--bui-flyout-arrow-size)
}

.bdd5643ed9>span:first-child {
    background: var(--bui-flyout-arrow-background)
}

.bdd5643ed9>span:last-child {
    box-shadow: var(--bui-flyout-arrow-shadow);
    z-index: -1
}

.d6a76c1377>span:first-child,.d6a76c1377>span:last-child {
    left: 50%;
    right: auto;
    top: 100%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform-origin: center
}

.db96a83d6d>span:first-child,.db96a83d6d>span:last-child,.e35f1f97ec>span:first-child,.e35f1f97ec>span:last-child {
    left: 50%;
    right: auto;
    top: 0;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform-origin: center
}

.b49c9e98e2>span:first-child,.b49c9e98e2>span:last-child {
    left: 100%;
    right: auto;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform-origin: center
}

.da65bc9d13>span:first-child,.da65bc9d13>span:last-child {
    left: 0;
    right: auto;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform-origin: center
}

.c0d49f51f4>span:first-child,.c0d49f51f4>span:last-child {
    left: var(--bui-flyout-arrow-offset);
    right: auto;
    top: 100%;
    transform: rotate(-45deg);
    transform-origin: top left
}

.b91de1e03e>span:first-child,.b91de1e03e>span:last-child {
    left: auto;
    right: var(--bui-flyout-arrow-offset);
    top: 100%;
    transform: rotate(45deg);
    transform-origin: top right
}

.d7de950f8d>span:first-child,.d7de950f8d>span:last-child {
    left: var(--bui-flyout-arrow-offset);
    right: auto;
    top: 0;
    transform: rotate(-45deg);
    transform-origin: top left
}

.c8e8039c3e>span:first-child,.c8e8039c3e>span:last-child {
    left: auto;
    right: var(--bui-flyout-arrow-offset);
    top: 0;
    transform: rotate(45deg);
    transform-origin: top right
}

.af34fbe506>span:first-child,.af34fbe506>span:last-child {
    left: 100%;
    right: auto;
    top: var(--bui-flyout-arrow-offset);
    transform: rotate(45deg);
    transform-origin: top left
}

.f18448241c>span:first-child,.f18448241c>span:last-child {
    bottom: var(--bui-flyout-arrow-offset);
    left: 100%;
    right: auto;
    transform: rotate(-45deg);
    transform-origin: bottom left
}

.b3447dd014>span:first-child,.b3447dd014>span:last-child {
    left: 0;
    right: auto;
    top: var(--bui-flyout-arrow-offset);
    transform: rotate(45deg);
    transform-origin: top left
}

.e0d499c9f2>span:first-child,.e0d499c9f2>span:last-child {
    bottom: var(--bui-flyout-arrow-offset);
    left: 0;
    right: auto;
    transform: rotate(-45deg);
    transform-origin: bottom left
}

.fcd9eec8fb {
    fill: currentcolor;
    display: inline-block
}

.fcd9eec8fb svg {
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: auto
}

[dir=rtl] .fcd9eec8fb svg[data-rtl-flip] {
    transform: scaleX(-1)
}

.fe255d8541 {
    display: block
}

.c25361c37f {
    height: var(--bui_spacing_3x)
}

.fb4ef8dd02 {
    height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half))
}

.bf9a32efa5 {
    height: var(--bui_spacing_4x)
}

.c2cc050fb8 {
    height: calc(var(--bui_spacing_1x)*5)
}

.d24fc26e73 {
    height: var(--bui_spacing_6x)
}

.dbed623b69 {
    height: calc(var(--bui_spacing_1x)*7)
}

.dba56f70c6 {
    height: calc(var(--bui_spacing_1x)*9)
}

@media (min-width: 576px) {
    .b138cad1f7 {
        height:var(--bui_spacing_3x)
    }

    .cde2354efd {
        height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half))
    }

    .ed0fdb6b63 {
        height: var(--bui_spacing_4x)
    }

    .f8f9489c14 {
        height: calc(var(--bui_spacing_1x)*5)
    }

    .eb3b554a94 {
        height: var(--bui_spacing_6x)
    }

    .cdd57dffd7 {
        height: calc(var(--bui_spacing_1x)*7)
    }

    .d76926d4a5 {
        height: calc(var(--bui_spacing_1x)*9)
    }
}

@media (min-width: 1024px) {
    .ff01d61571 {
        height:var(--bui_spacing_3x)
    }

    .e0a91ef7d6 {
        height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half))
    }

    .dd664c49eb {
        height: var(--bui_spacing_4x)
    }

    .d024bdaaaf {
        height: calc(var(--bui_spacing_1x)*5)
    }

    .d9aea8bf99 {
        height: var(--bui_spacing_6x)
    }

    .bcadaf381c {
        height: calc(var(--bui_spacing_1x)*7)
    }

    .b20aafd564 {
        height: calc(var(--bui_spacing_1x)*9)
    }
}

@media (min-width: 1280px) {
    .e691389692 {
        height:var(--bui_spacing_3x)
    }

    .b318b1b948 {
        height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half))
    }

    .fba4e00617 {
        height: var(--bui_spacing_4x)
    }

    .b425c29c37 {
        height: calc(var(--bui_spacing_1x)*5)
    }

    .df2b7cc4ba {
        height: var(--bui_spacing_6x)
    }

    .cb7876945d {
        height: calc(var(--bui_spacing_1x)*7)
    }

    .e252a89ec6 {
        height: calc(var(--bui_spacing_1x)*9)
    }
}

.aa02bb5dd2 {
    fill: #fff;
    color: #fff
}

.b3ef400590 {
    fill: var(--bui_color_action_foreground);
    color: var(--bui_color_action_foreground)
}

.edbaf47a05 {
    fill: var(--bui_color_destructive_foreground);
    color: var(--bui_color_destructive_foreground)
}

.b2d71ce18d {
    fill: var(--bui_color_callout_foreground);
    color: var(--bui_color_callout_foreground)
}

.e410954d4b {
    fill: var(--bui_color_accent_foreground);
    color: var(--bui_color_accent_foreground)
}

.a229b4a525 {
    fill: var(--bui_color_constructive_foreground);
    color: var(--bui_color_constructive_foreground)
}

.f64eb5d122 {
    fill: var(--bui_color_foreground);
    color: var(--bui_color_foreground)
}

.c696a7d242 {
    fill: var(--bui_color_foreground_alt);
    color: var(--bui_color_foreground_alt)
}

.c3619ffde7 {
    fill: var(--bui_color_foreground_disabled);
    color: var(--bui_color_foreground_disabled)
}

.fc17714355 {
    fill: var(--bui_color_brand_genius_secondary_foreground);
    color: var(--bui_color_brand_genius_secondary_foreground)
}

.f23b02a2a0 {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    outline: none;
    padding: 0;
    text-align: start;
    width: 100%
}

.f770ecbb66 {
    flex-grow: 1
}

.a4351ef0fd {
    flex-shrink: 0;
    margin-inline-start:var(--bui_spacing_4x)}

[data-bui-keyboard] .f23b02a2a0 focus .a4351ef0fd {
    box-shadow:0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.ea77c38cdd {
    display: none;
    margin-block-start:var(--bui_spacing_4x)}

.bacd419987>.ea77c38cdd {
    display: block
}

.bacd419987>.f23b02a2a0>.a4351ef0fd {
    transform: rotate(180deg)
}

.c2a5f22ca7 .bacd419987>.f23b02a2a0>.a4351ef0fd,[dir=rtl] .bacd419987>.f23b02a2a0>.a4351ef0fd {
    transform: rotate(-180deg)
}

.e804921cdd>.f23b02a2a0 {
    cursor: default
}

.e804921cdd>.f23b02a2a0>.a4351ef0fd {
    visibility: hidden
}

.a83ed08757 {
    background: none;
    border: none;
    box-sizing: border-box;
    color: inherit;
    display: inline-flex;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    position: relative;
    text-align: start;
    text-decoration: none;
    vertical-align: top
}

.a83ed08757[role=button],a.a83ed08757,button.a83ed08757 {
    cursor: pointer
}

.a83ed08757:focus {
    outline: none;
    z-index: 10
}

button.a83ed08757[disabled] {
    cursor: not-allowed
}

button.a83ed08757[disabled]:active {
    transform: none
}

[data-bui-keyboard] .a83ed08757:focus {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

[data-bui-keyboard] .a83ed08757.ec66406250:focus {
    box-shadow: inset 0 0 0 2px var(--bui_color_background_base),inset 0 0 0 4px #70bbe9;
    outline: none
}

@keyframes b9d689e899 {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(2turn)
    }
}

.a9932a3e99 {
    animation: b9d689e899 1s cubic-bezier(.445,.05,.55,.95) infinite;
    direction: ltr;
    height: var(--bui_spinner_size);
    position: relative;
    width: var(--bui_spinner_size)
}

.a9932a3e99:after,.a9932a3e99:before {
    border-radius: 50%;
    content: "";
    height: var(--bui_spinner_before_size);
    position: absolute;
    transform: translateY(-50%);
    width: var(--bui_spinner_before_size);
    z-index: 2
}

.a9932a3e99:after {
    right: 0;
    top: 50%
}

.a9932a3e99:before {
    left: 0;
    top: 50%
}

.dec0078e7c:after,.dec0078e7c:before {
    border: solid transparent;
    border-radius: 50%;
    border-width: var(--bui_spinner_inner_after_border_width);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.dec0078e7c:after {
    transform: rotate(-45deg)
}

.e29c1f9382:after,.e29c1f9382:before {
    background: #fff
}

.e29c1f9382 .dec0078e7c:before {
    border-color: #fff;
    opacity: .5
}

.e29c1f9382 .dec0078e7c:after {
    border-right-color: #fff;
    border-top-color: #fff
}

.bc91340c18:after,.bc91340c18:before {
    background: #70bbe9
}

.bc91340c18 .dec0078e7c:before {
    border-color: var(--bui_color_border_alt)
}

.bc91340c18 .dec0078e7c:after {
    border-right-color: #70bbe9;
    border-top-color: #70bbe9
}

.b0f9686a2f:after,.b0f9686a2f:before {
    background: var(--bui_color_destructive_border)
}

.b0f9686a2f .dec0078e7c:before {
    border-color: var(--bui_color_border_alt)
}

.b0f9686a2f .dec0078e7c:after {
    border-right-color: var(--bui_color_destructive_border);
    border-top-color: var(--bui_color_destructive_border)
}

.b222bd4b4b:after,.b222bd4b4b:before {
    background: currentcolor
}

.b222bd4b4b .dec0078e7c:before {
    border-color: currentcolor;
    opacity: .5
}

.b222bd4b4b .dec0078e7c:after {
    border-right-color: currentcolor;
    border-top-color: currentcolor
}

.a14823b05c {
    --bui_spinner_size: var(--bui_spacing_4x);
    --bui_spinner_before_size: var(--bui_spacing_half);
    --bui_spinner_inner_after_border_width: var(--bui_spacing_half)
}

.ba4e4ce302 {
    --bui_spinner_size: var(--bui_spacing_6x);
    --bui_spinner_before_size: calc(var(--bui_spacing_half)*1.5);
    --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half)*1.5)
}

.f31322b19f {
    --bui_spinner_size: var(--bui_spacing_8x);
    --bui_spinner_before_size: var(--bui_spacing_1x);
    --bui_spinner_inner_after_border_width: var(--bui_spacing_1x)
}

.dc262da21b {
    --bui_spinner_size: calc(var(--bui_spacing_6x)*2);
    --bui_spinner_before_size: calc(var(--bui_spacing_half)*3);
    --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half)*3)
}

@media (min-width: 576px) {
    .fe5f79d882 {
        --bui_spinner_size:var(--bui_spacing_4x);
        --bui_spinner_before_size: var(--bui_spacing_half);
        --bui_spinner_inner_after_border_width: var(--bui_spacing_half)
    }

    .e005705fb0 {
        --bui_spinner_size: var(--bui_spacing_6x);
        --bui_spinner_before_size: calc(var(--bui_spacing_half)*1.5);
        --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half)*1.5)
    }

    .c31997970d {
        --bui_spinner_size: var(--bui_spacing_8x);
        --bui_spinner_before_size: var(--bui_spacing_1x);
        --bui_spinner_inner_after_border_width: var(--bui_spacing_1x)
    }

    .bba96d4f36 {
        --bui_spinner_size: calc(var(--bui_spacing_6x)*2);
        --bui_spinner_before_size: calc(var(--bui_spacing_half)*3);
        --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half)*3)
    }
}

@media (min-width: 1024px) {
    .d23cee4464 {
        --bui_spinner_size:var(--bui_spacing_4x);
        --bui_spinner_before_size: var(--bui_spacing_half);
        --bui_spinner_inner_after_border_width: var(--bui_spacing_half)
    }

    .ddf4cfca6a {
        --bui_spinner_size: var(--bui_spacing_6x);
        --bui_spinner_before_size: calc(var(--bui_spacing_half)*1.5);
        --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half)*1.5)
    }

    .feab296737 {
        --bui_spinner_size: var(--bui_spacing_8x);
        --bui_spinner_before_size: var(--bui_spacing_1x);
        --bui_spinner_inner_after_border_width: var(--bui_spacing_1x)
    }

    .b1d154808a {
        --bui_spinner_size: calc(var(--bui_spacing_6x)*2);
        --bui_spinner_before_size: calc(var(--bui_spacing_half)*3);
        --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half)*3)
    }
}

@media (min-width: 1280px) {
    .cfd44f7263 {
        --bui_spinner_size:var(--bui_spacing_4x);
        --bui_spinner_before_size: var(--bui_spacing_half);
        --bui_spinner_inner_after_border_width: var(--bui_spacing_half)
    }

    .d691b3f7dd {
        --bui_spinner_size: var(--bui_spacing_6x);
        --bui_spinner_before_size: calc(var(--bui_spacing_half)*1.5);
        --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half)*1.5)
    }

    .a3fc44a2cb {
        --bui_spinner_size: var(--bui_spacing_8x);
        --bui_spinner_before_size: var(--bui_spacing_1x);
        --bui_spinner_inner_after_border_width: var(--bui_spacing_1x)
    }

    .cd595665a5 {
        --bui_spinner_size: calc(var(--bui_spacing_6x)*2);
        --bui_spinner_before_size: calc(var(--bui_spacing_half)*3);
        --bui_spinner_inner_after_border_width: calc(var(--bui_spacing_half)*3)
    }
}

.c21c56c305 {
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background: transparent;
    border: 0;
    border-radius: var(--bui_border_radius_100);
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    justify-content: center;
    position: relative;
    text-decoration: none;
    transition: var(--bui_animation_press);
    transition-property: transform;
    vertical-align: middle
}

.c21c56c305:before {
    border: var(--bui_border_width_100) solid transparent;
    border-radius: var(--bui_border_radius_100);
    bottom: 0;
    box-sizing: border-box;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: var(--bui_animation_hover);
    transition-property: background-color
}

.c21c56c305:active {
    transform: scale(.96)
}

.c21c56c305.c9b36b6cc7,.c21c56c305:active,.c21c56c305:focus,.c21c56c305:hover,.c21c56c305:link,.c21c56c305:visited {
    text-decoration: none
}

.eedba9e88a {
    fill: currentcolor;
    display: inline-flex;
    flex-shrink: 0;
    margin: 0 calc(var(--bui_spacing_3x)*-1);
    overflow: hidden;
    position: relative
}

.a29f44e7c4 {
    margin-inline-end:var(--bui_spacing_2x);margin-inline-start: 0
}

.d7eef963fa {
    margin-inline-end:0;margin-inline-start:var(--bui_spacing_2x)}

.e4adce92df {
    position: relative
}

.d46616f24d {
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%)
}

.dfbde2758d .d46616f24d,[dir=rtl] .d46616f24d {
    left: auto;
    right: 50%;
    transform: translate(50%,-50%)
}

.b84382a17d {
    cursor: default
}

.b84382a17d>.d46616f24d {
    display: block
}

.b84382a17d>.e4adce92df,.b84382a17d>.eedba9e88a {
    visibility: hidden
}

.a4c1805887[disabled],.a4c1805887[disabled]:active,.a4c1805887[disabled]:hover {
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed;
    text-decoration: none
}

.a4c1805887[disabled]:active:before,.a4c1805887[disabled]:before,.a4c1805887[disabled]:hover:before {
    background-color: var(--bui_color_background_disabled);
    border-color: var(--bui_color_background_disabled)
}

.c082d89982 {
    color: var(--bui_color_on_cta_background)
}

.c082d89982:before {
    background-color: #70bbe9;
    border-color: #70bbe9
}

.c082d89982.c9b36b6cc7,.c082d89982:active,.c082d89982:focus,.c082d89982:hover,.c082d89982:link,.c082d89982:visited {
    color: var(--bui_color_on_cta_background)
}

.c082d89982.c9b36b6cc7:before,.c082d89982:not(.b84382a17d):active:before {
    background-color: var(--bui_color_cta_highlighted);
    border-color: var(--bui_color_cta_highlighted)
}

@media (hover: hover) {
    .c082d89982:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_cta_highlighted);
        border-color: var(--bui_color_cta_highlighted)
    }
}

.e251114555 {
    color: var(--bui_color_on_destructive_background)
}

.e251114555:before {
    background-color: var(--bui_color_destructive_background);
    border-color: var(--bui_color_destructive_background)
}

.e251114555.c9b36b6cc7,.e251114555:active,.e251114555:focus,.e251114555:hover,.e251114555:link,.e251114555:visited {
    color: var(--bui_color_on_destructive_background)
}

.e251114555.c9b36b6cc7:before,.e251114555:not(.b84382a17d):active:before {
    background-color: var(--bui_color_destructive_highlighted);
    border-color: var(--bui_color_destructive_highlighted)
}

@media (hover: hover) {
    .e251114555:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_destructive_highlighted);
        border-color: var(--bui_color_destructive_highlighted)
    }
}

.bf0537ecb5[disabled],.bf0537ecb5[disabled]:active,.bf0537ecb5[disabled]:hover {
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed;
    text-decoration: none
}

.bf0537ecb5[disabled]:active:before,.bf0537ecb5[disabled]:before,.bf0537ecb5[disabled]:hover:before {
    background-color: var(--bui_color_background_disabled_alt);
    border-color: var(--bui_color_background_disabled)
}

.af7297d90d {
    background-color: var(--bui_color_background_elevation_one);
    color: var(--bui_color_action_foreground)
}

.af7297d90d:before {
    background-color: transparent;
    border-color: #70bbe9
}

.af7297d90d.c9b36b6cc7,.af7297d90d:active,.af7297d90d:focus,.af7297d90d:hover,.af7297d90d:link,.af7297d90d:visited {
    color: var(--bui_color_action_foreground)
}

.af7297d90d.c9b36b6cc7:before,.af7297d90d:not(.b84382a17d):active:before {
    background-color: var(--bui_color_action_highlighted_alt);
    border-color: #70bbe9
}

@media (hover: hover) {
    .af7297d90d:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_action_highlighted_alt);
        border-color: #70bbe9
    }
}

.f378252053 {
    background-color: var(--bui_color_background_elevation_one);
    color: var(--bui_color_destructive_foreground)
}

.f378252053:before {
    background-color: transparent;
    border-color: var(--bui_color_destructive_border)
}

.f378252053.c9b36b6cc7,.f378252053:active,.f378252053:focus,.f378252053:hover,.f378252053:link,.f378252053:visited {
    color: var(--bui_color_destructive_foreground)
}

.f378252053.c9b36b6cc7:before,.f378252053:not(.b84382a17d):active:before {
    background-color: var(--bui_color_destructive_highlighted_alt);
    border-color: var(--bui_color_destructive_border)
}

@media (hover: hover) {
    .f378252053:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_destructive_highlighted_alt);
        border-color: var(--bui_color_destructive_border)
    }
}

.d8974fd49d {
    background-color: transparent;
    color: var(--bui_color_foreground)
}

.d8974fd49d:before {
    background-color: transparent;
    border-color: var(--bui_color_border)
}

.d8974fd49d.c9b36b6cc7,.d8974fd49d:active,.d8974fd49d:focus,.d8974fd49d:hover,.d8974fd49d:link,.d8974fd49d:visited {
    color: var(--bui_color_foreground)
}

.d8974fd49d.c9b36b6cc7:before,.d8974fd49d:not(.b84382a17d):active:before {
    background-color: var(--bui_color_highlighted_alt);
    border-color: var(--bui_color_border)
}

@media (hover: hover) {
    .d8974fd49d:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_highlighted_alt);
        border-color: var(--bui_color_border)
    }
}

.f38b6daa18 {
    --bui_button_inline_padding_extra: calc(var(--bui_spacing_1x)*-1)
}

.f38b6daa18:before {
    background-color: transparent;
    border-color: transparent
}

.f38b6daa18[disabled],.f38b6daa18[disabled]:active,.f38b6daa18[disabled]:hover {
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed;
    text-decoration: none
}

.bb803d8689,.bb803d8689.c9b36b6cc7,.bb803d8689:active,.bb803d8689:focus,.bb803d8689:hover,.bb803d8689:link,.bb803d8689:visited {
    color: var(--bui_color_action_foreground)
}

.bb803d8689.c9b36b6cc7:before,.bb803d8689:not(.b84382a17d):active:before {
    background-color: var(--bui_color_action_highlighted_alt);
    border-color: transparent
}

@media (hover: hover) {
    .bb803d8689:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_action_highlighted_alt);
        border-color: transparent
    }
}

.c790a7450d,.c790a7450d.c9b36b6cc7,.c790a7450d:active,.c790a7450d:focus,.c790a7450d:hover,.c790a7450d:link,.c790a7450d:visited {
    color: var(--bui_color_destructive_foreground)
}

.c790a7450d.c9b36b6cc7:before,.c790a7450d:not(.b84382a17d):active:before {
    background-color: var(--bui_color_destructive_highlighted_alt);
    border-color: transparent
}

@media (hover: hover) {
    .c790a7450d:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_destructive_highlighted_alt);
        border-color: transparent
    }
}

.f4552b6561,.f4552b6561.c9b36b6cc7,.f4552b6561:active,.f4552b6561:focus,.f4552b6561:hover,.f4552b6561:link,.f4552b6561:visited {
    color: var(--bui_color_foreground)
}

.f4552b6561.c9b36b6cc7:before,.f4552b6561:not(.b84382a17d):active:before {
    background-color: var(--bui_color_highlighted_alt);
    border-color: transparent
}

@media (hover: hover) {
    .f4552b6561:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_highlighted_alt);
        border-color: transparent
    }
}

.fd3248769f {
    color: currentcolor
}

.fd3248769f:before {
    opacity: 0
}

.fd3248769f.c9b36b6cc7,.fd3248769f:active,.fd3248769f:focus,.fd3248769f:hover,.fd3248769f:link,.fd3248769f:visited {
    color: currentcolor
}

.fd3248769f.c9b36b6cc7:before,.fd3248769f:not(.b84382a17d):active:before {
    background-color: currentcolor;
    border-color: transparent;
    opacity: .1
}

@media (hover: hover) {
    .fd3248769f:not(.b84382a17d):hover:before {
        background-color:currentcolor;
        border-color: transparent;
        opacity: .1
    }
}

.eae8696fa4[disabled]:active:before,.eae8696fa4[disabled]:before,.eae8696fa4[disabled]:hover:before {
    background-color: #fff
}

.eae8696fa4[disabled],.eae8696fa4[disabled]:active,.eae8696fa4[disabled]:hover {
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed;
    text-decoration: none
}

.b2ed257b07 {
    background-color: #fff;
    color: var(--bui_color_black)
}

.b2ed257b07:before {
    border-color: transparent
}

.b2ed257b07.c9b36b6cc7,.b2ed257b07:active,.b2ed257b07:focus,.b2ed257b07:hover,.b2ed257b07:link,.b2ed257b07:visited {
    color: var(--bui_color_black)
}

.b2ed257b07.c9b36b6cc7:before,.b2ed257b07:not(.b84382a17d):active:before {
    background-color: rgba(0,0,0,.12)
}

@media (hover: hover) {
    .b2ed257b07:not(.b84382a17d):hover:before {
        background-color:rgba(0,0,0,.12)
    }
}

.bd3a1c7108[disabled],.bd3a1c7108[disabled]:active,.bd3a1c7108[disabled]:hover {
    box-shadow: var(--bui_shadow_100);
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed;
    text-decoration: none
}

.bd3a1c7108[disabled]:active:before,.bd3a1c7108[disabled]:before,.bd3a1c7108[disabled]:hover:before {
    background-color: var(--bui_color_background_elevation_two);
    border-color: transparent
}

.d691166b09.bd3a1c7108,.d691166b09.bd3a1c7108:before {
    border-radius: 50%
}

.fe94449254 {
    background-color: var(--bui_color_background_elevation_two);
    box-shadow: var(--bui_shadow_100);
    color: var(--bui_color_foreground)
}

.fe94449254:before {
    border-color: transparent
}

.fe94449254.c9b36b6cc7,.fe94449254:active,.fe94449254:focus,.fe94449254:hover,.fe94449254:link,.fe94449254:visited {
    color: var(--bui_color_foreground)
}

.fe94449254.c9b36b6cc7:before,.fe94449254:not(.b84382a17d):active:before {
    background-color: var(--bui_color_highlighted_alt)
}

@media (hover: hover) {
    .fe94449254:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_highlighted_alt)
    }
}

.a26779254e {
    background-color: var(--bui_color_background_elevation_two);
    box-shadow: var(--bui_shadow_100)
}

.a26779254e,.a26779254e.c9b36b6cc7,.a26779254e:active,.a26779254e:focus,.a26779254e:hover,.a26779254e:link,.a26779254e:visited {
    color: var(--bui_color_destructive_foreground)
}

.a26779254e.c9b36b6cc7:before,.a26779254e:not(.b84382a17d):active:before {
    background-color: var(--bui_color_destructive_highlighted_alt);
    border-color: transparent
}

@media (hover: hover) {
    .a26779254e:not(.b84382a17d):hover:before {
        background-color:var(--bui_color_destructive_highlighted_alt);
        border-color: transparent
    }
}

.f1a8ad0070,.f1a8ad0070:before {
    border-radius: 999px
}

.ab98298258 {
    font-family: var(--bui_font_emphasized_2_font-family);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height);
    margin-block-end:var(--bui_button_medium_margin_block_end,initial);margin-block-start: var(--bui_button_medium_margin_block_start,initial);
    margin-inline-end:var(--bui_button_medium_margin_inline_end,initial);margin-inline-start: var(--bui_button_medium_margin_inline_start,initial);
    min-height: calc(var(--bui_spacing_1x)*9);
    min-width: calc(var(--bui_spacing_1x)*9);
    padding: var(--bui_spacing_1x) calc(var(--bui_spacing_3x) + var(--bui_button_inline_padding_extra, 0px))
}

.f671049264 {
    font-family: var(--bui_font_emphasized_1_font-family);
    font-size: var(--bui_font_emphasized_1_font-size);
    font-weight: var(--bui_font_emphasized_1_font-weight);
    line-height: var(--bui_font_emphasized_1_line-height);
    margin-block-end:var(--bui_button_large_margin_block_end,initial);margin-block-start: var(--bui_button_large_margin_block_start,initial);
    margin-inline-end:var(--bui_button_large_margin_inline_end,initial);margin-inline-start: var(--bui_button_large_margin_inline_start,initial);
    min-height: calc(var(--bui_spacing_1x)*12);
    min-width: calc(var(--bui_spacing_1x)*12);
    padding: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) + var(--bui_button_inline_padding_extra, 0px))
}

@media (min-width: 576px) {
    .ea352b1b5f {
        font-family:var(--bui_font_emphasized_2_font-family);
        font-size: var(--bui_font_emphasized_2_font-size);
        font-weight: var(--bui_font_emphasized_2_font-weight);
        line-height: var(--bui_font_emphasized_2_line-height);
        margin-block-end:var(--bui_button_medium_margin_block_end,initial);margin-block-start: var(--bui_button_medium_margin_block_start,initial);
        margin-inline-end:var(--bui_button_medium_margin_inline_end,initial);margin-inline-start: var(--bui_button_medium_margin_inline_start,initial);
        min-height: calc(var(--bui_spacing_1x)*9);
        min-width: calc(var(--bui_spacing_1x)*9);
        padding: var(--bui_spacing_1x) calc(var(--bui_spacing_3x) + var(--bui_button_inline_padding_extra, 0px))
    }

    .ba2d268fe4 {
        font-family: var(--bui_font_emphasized_1_font-family);
        font-size: var(--bui_font_emphasized_1_font-size);
        font-weight: var(--bui_font_emphasized_1_font-weight);
        line-height: var(--bui_font_emphasized_1_line-height);
        margin-block-end:var(--bui_button_large_margin_block_end,initial);margin-block-start: var(--bui_button_large_margin_block_start,initial);
        margin-inline-end:var(--bui_button_large_margin_inline_end,initial);margin-inline-start: var(--bui_button_large_margin_inline_start,initial);
        min-height: calc(var(--bui_spacing_1x)*12);
        min-width: calc(var(--bui_spacing_1x)*12);
        padding: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) + var(--bui_button_inline_padding_extra, 0px))
    }
}

@media (min-width: 1024px) {
    .fc1ae8fdf2 {
        font-family:var(--bui_font_emphasized_2_font-family);
        font-size: var(--bui_font_emphasized_2_font-size);
        font-weight: var(--bui_font_emphasized_2_font-weight);
        line-height: var(--bui_font_emphasized_2_line-height);
        margin-block-end:var(--bui_button_medium_margin_block_end,initial);margin-block-start: var(--bui_button_medium_margin_block_start,initial);
        margin-inline-end:var(--bui_button_medium_margin_inline_end,initial);margin-inline-start: var(--bui_button_medium_margin_inline_start,initial);
        min-height: calc(var(--bui_spacing_1x)*9);
        min-width: calc(var(--bui_spacing_1x)*9);
        padding: var(--bui_spacing_1x) calc(var(--bui_spacing_3x) + var(--bui_button_inline_padding_extra, 0px))
    }

    .fd9575f0d5 {
        font-family: var(--bui_font_emphasized_1_font-family);
        font-size: var(--bui_font_emphasized_1_font-size);
        font-weight: var(--bui_font_emphasized_1_font-weight);
        line-height: var(--bui_font_emphasized_1_line-height);
        margin-block-end:var(--bui_button_large_margin_block_end,initial);margin-block-start: var(--bui_button_large_margin_block_start,initial);
        margin-inline-end:var(--bui_button_large_margin_inline_end,initial);margin-inline-start: var(--bui_button_large_margin_inline_start,initial);
        min-height: calc(var(--bui_spacing_1x)*12);
        min-width: calc(var(--bui_spacing_1x)*12);
        padding: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) + var(--bui_button_inline_padding_extra, 0px))
    }
}

@media (min-width: 1280px) {
    .a3e5ff6d54 {
        font-family:var(--bui_font_emphasized_2_font-family);
        font-size: var(--bui_font_emphasized_2_font-size);
        font-weight: var(--bui_font_emphasized_2_font-weight);
        line-height: var(--bui_font_emphasized_2_line-height);
        margin-block-end:var(--bui_button_medium_margin_block_end,initial);margin-block-start: var(--bui_button_medium_margin_block_start,initial);
        margin-inline-end:var(--bui_button_medium_margin_inline_end,initial);margin-inline-start: var(--bui_button_medium_margin_inline_start,initial);
        min-height: calc(var(--bui_spacing_1x)*9);
        min-width: calc(var(--bui_spacing_1x)*9);
        padding: var(--bui_spacing_1x) calc(var(--bui_spacing_3x) + var(--bui_button_inline_padding_extra, 0px))
    }

    .f35d3cd323 {
        font-family: var(--bui_font_emphasized_1_font-family);
        font-size: var(--bui_font_emphasized_1_font-size);
        font-weight: var(--bui_font_emphasized_1_font-weight);
        line-height: var(--bui_font_emphasized_1_line-height);
        margin-block-end:var(--bui_button_large_margin_block_end,initial);margin-block-start: var(--bui_button_large_margin_block_start,initial);
        margin-inline-end:var(--bui_button_large_margin_inline_end,initial);margin-inline-start: var(--bui_button_large_margin_inline_start,initial);
        min-height: calc(var(--bui_spacing_1x)*12);
        min-width: calc(var(--bui_spacing_1x)*12);
        padding: var(--bui_spacing_2x) calc(var(--bui_spacing_4x) + var(--bui_button_inline_padding_extra, 0px))
    }
}

.d2529514af {
    text-align: center;
    width: 100%
}

.deab83296e {
    text-align: start;
    width: auto
}

@media (min-width: 576px) {
    .b3d586cbc4 {
        text-align:center;
        width: 100%
    }

    .b3d7b42f0a {
        text-align: start;
        width: auto
    }
}

@media (min-width: 1024px) {
    .d4669f656e {
        text-align:center;
        width: 100%
    }

    .fc333a26b6 {
        text-align: start;
        width: auto
    }
}

@media (min-width: 1280px) {
    .ccfda9a7f4 {
        text-align:center;
        width: 100%
    }

    .af6bd39ba5 {
        text-align: start;
        width: auto
    }
}

.abcc616ec7 {
    display: inline-block;
    vertical-align: middle
}

.cc1b961f14 .f38b6daa18,.cc1b961f14 .f4552b6561 {
    --bui_button_medium_margin_block_start: calc(var(--bui_spacing_2x)*-1);
    --bui_button_large_margin_block_start: calc(var(--bui_spacing_3x)*-1)
}

.fc38ba3b1a .f38b6daa18,.fc38ba3b1a .f4552b6561 {
    --bui_button_medium_margin_block_end: calc(var(--bui_spacing_2x)*-1);
    --bui_button_large_margin_block_end: calc(var(--bui_spacing_3x)*-1)
}

.e530c37cf8 .f38b6daa18,.e530c37cf8 .f4552b6561 {
    --bui_button_medium_margin_inline_start: calc(var(--bui_spacing_2x)*-1);
    --bui_button_large_margin_inline_start: calc(var(--bui_spacing_3x)*-1)
}

.c180176d40 .f38b6daa18,.c180176d40 .f4552b6561 {
    --bui_button_medium_margin_inline_end: calc(var(--bui_spacing_2x)*-1);
    --bui_button_large_margin_inline_end: calc(var(--bui_spacing_3x)*-1)
}

.aca0ade214 {
    --bui_stack_column_item_split: initial;
    --bui_stack_height: initial;
    --bui_stack_gap_last_child: initial;
    display: flex;
    height: var(--bui_stack_height,initial);
    list-style-type: none;
    padding: 0;
    pointer-events: none
}

.aca0ade214>* {
    flex-shrink: 0;
    pointer-events: all
}

.cd2e7d62b0 {
    flex-direction: column
}

.cc828c00a1,.cd2e7d62b0 {
    align-items: stretch;
    flex-wrap: nowrap
}

.cc828c00a1 {
    flex-direction: column-reverse
}

.c2931f4182 {
    flex-direction: row
}

.c2931f4182,.e1ffac4e41 {
    align-items: flex-start;
    flex-wrap: wrap
}

.e1ffac4e41 {
    flex-direction: row-reverse
}

@media (min-width: 576px) {
    .a6e307ba46 {
        flex-direction:column
    }

    .a6e307ba46,.b8d73ea726 {
        align-items: stretch;
        flex-wrap: nowrap
    }

    .b8d73ea726 {
        flex-direction: column-reverse
    }

    .d8bec7798b {
        flex-direction: row
    }

    .d8bec7798b,.f3759b332b {
        align-items: flex-start;
        flex-wrap: wrap
    }

    .f3759b332b {
        flex-direction: row-reverse
    }
}

@media (min-width: 1024px) {
    .a1560f391a {
        flex-direction:column
    }

    .a1560f391a,.b8840498b3 {
        align-items: stretch;
        flex-wrap: nowrap
    }

    .b8840498b3 {
        flex-direction: column-reverse
    }

    .c7aa57535e {
        flex-direction: row
    }

    .c7aa57535e,.f2ce81e2ee {
        align-items: flex-start;
        flex-wrap: wrap
    }

    .f2ce81e2ee {
        flex-direction: row-reverse
    }
}

@media (min-width: 1280px) {
    .eac5af1ad5 {
        flex-direction:column
    }

    .b463ad3a69,.eac5af1ad5 {
        align-items: stretch;
        flex-wrap: nowrap
    }

    .b463ad3a69 {
        flex-direction: column-reverse
    }

    .d35cd58b2c {
        flex-direction: row
    }

    .d35cd58b2c,.e36f3a2499 {
        align-items: flex-start;
        flex-wrap: wrap
    }

    .e36f3a2499 {
        flex-direction: row-reverse
    }
}

.cd0091cad1 {
    align-items: flex-start
}

.e6b4e053c8 {
    align-items: flex-end
}

.e7d9f93f4d {
    align-items: center
}

.eba0ee7e37 {
    align-items: stretch
}

.f5d5738b85 {
    align-items: baseline
}

@media (min-width: 576px) {
    .a3a453ac28 {
        align-items:flex-start
    }

    .c80450bdb2 {
        align-items: flex-end
    }

    .a68f319c5b {
        align-items: center
    }

    .e4a9550bcb {
        align-items: stretch
    }

    .e37a26b2cb {
        align-items: baseline
    }
}

@media (min-width: 1024px) {
    .b45dec18b8 {
        align-items:flex-start
    }

    .f42cad206e {
        align-items: flex-end
    }

    .e6816ee0df {
        align-items: center
    }

    .c7c6d5d7ce {
        align-items: stretch
    }

    .b7a00d867f {
        align-items: baseline
    }
}

@media (min-width: 1280px) {
    .cbab8809bf {
        align-items:flex-start
    }

    .d505bd7bb8 {
        align-items: flex-end
    }

    .ef3509268e {
        align-items: center
    }

    .e4ed123537 {
        align-items: stretch
    }

    .cc5a576697 {
        align-items: baseline
    }
}

.c27e5d305d {
    justify-content: flex-start
}

.b7b48ea990 {
    justify-content: center
}

.faf8b5d9a5 {
    justify-content: flex-end
}

.c18bfa9a44 {
    justify-content: space-between
}

@media (min-width: 576px) {
    .c8935d16ff {
        justify-content:flex-start
    }

    .b48d526809 {
        justify-content: center
    }

    .ee7e39109c {
        justify-content: flex-end
    }

    .a1bf284b19 {
        justify-content: space-between
    }
}

@media (min-width: 1024px) {
    .c5bc0f6f2c {
        justify-content:flex-start
    }

    .af305cb9bc {
        justify-content: center
    }

    .aeb9b838bd {
        justify-content: flex-end
    }

    .f18eddb649 {
        justify-content: space-between
    }
}

@media (min-width: 1280px) {
    .cfdf80f294 {
        justify-content:flex-start
    }

    .ba6039813e {
        justify-content: center
    }

    .b29c5b07bc {
        justify-content: flex-end
    }

    .e4881801d4 {
        justify-content: space-between
    }
}

.b23c3f480a>* {
    --bui_stack_gap: 0px
}

.a76fac450a>* {
    --bui_stack_gap: var(--bui_spacing_half)
}

.a5f1aae5b2>* {
    --bui_stack_gap: var(--bui_spacing_1x)
}

.aaf30230d9>* {
    --bui_stack_gap: 8px
}

.cc8c279dac>* {
    --bui_stack_gap: var(--bui_spacing_3x)
}

.ebac6e22e9>* {
    --bui_stack_gap: var(--bui_spacing_4x)
}

.c9835feea9>* {
    --bui_stack_gap: var(--bui_spacing_6x)
}

.a6214541ae>* {
    --bui_stack_gap: var(--bui_spacing_8x)
}

@media (min-width: 576px) {
    .b0db8269f9>* {
        --bui_stack_gap:0px
    }

    .dcc8386208>* {
        --bui_stack_gap: var(--bui_spacing_half)
    }

    .f410f52ece>* {
        --bui_stack_gap: var(--bui_spacing_1x)
    }

    .c528ec8f17>* {
        --bui_stack_gap: var(--bui_spacing_2x)
    }

    .d21f94aa3c>* {
        --bui_stack_gap: var(--bui_spacing_3x)
    }

    .d78dff19a0>* {
        --bui_stack_gap: var(--bui_spacing_4x)
    }

    .f66f916626>* {
        --bui_stack_gap: var(--bui_spacing_6x)
    }

    .ec40dc9bbf>* {
        --bui_stack_gap: var(--bui_spacing_8x)
    }
}

@media (min-width: 1024px) {
    .c35b217b07>* {
        --bui_stack_gap:0px
    }

    .e0f989adf8>* {
        --bui_stack_gap: var(--bui_spacing_half)
    }

    .ac449bdf5f>* {
        --bui_stack_gap: var(--bui_spacing_1x)
    }

    .db95d73370>* {
        --bui_stack_gap: var(--bui_spacing_2x)
    }

    .aa3a9b8a75>* {
        --bui_stack_gap: var(--bui_spacing_3x)
    }

    .fccb75cd7a>* {
        --bui_stack_gap: var(--bui_spacing_4x)
    }

    .e23d91a7f2>* {
        --bui_stack_gap: var(--bui_spacing_6x)
    }

    .a6244624fc>* {
        --bui_stack_gap: var(--bui_spacing_8x)
    }
}

@media (min-width: 1280px) {
    .a2bb0390c0>* {
        --bui_stack_gap:0px
    }

    .baafed8639>* {
        --bui_stack_gap: var(--bui_spacing_half)
    }

    .a12effd4f9>* {
        --bui_stack_gap: var(--bui_spacing_1x)
    }

    .c7f5c4081c>* {
        --bui_stack_gap: var(--bui_spacing_2x)
    }

    .e6f36945a5>* {
        --bui_stack_gap: var(--bui_spacing_3x)
    }

    .ad26f01921>* {
        --bui_stack_gap: var(--bui_spacing_4x)
    }

    .ef9ac3ecf9>* {
        --bui_stack_gap: var(--bui_spacing_6x)
    }

    .e8d6093793>* {
        --bui_stack_gap: var(--bui_spacing_8x)
    }
}

.b23c3f480a {
    --bui_stack_compensation: 0px
}

.a76fac450a {
    --bui_stack_compensation: var(--bui_spacing_half)
}

.a5f1aae5b2 {
    --bui_stack_compensation: var(--bui_spacing_1x)
}

.aaf30230d9 {
    --bui_stack_compensation: var(--bui_spacing_2x)
}

.cc8c279dac {
    --bui_stack_compensation: var(--bui_spacing_3x)
}

.ebac6e22e9 {
    --bui_stack_compensation: var(--bui_spacing_4x)
}

.c9835feea9 {
    --bui_stack_compensation: var(--bui_spacing_6x)
}

.a6214541ae {
    --bui_stack_compensation: var(--bui_spacing_8x)
}

@media (min-width: 576px) {
    .b0db8269f9 {
        --bui_stack_compensation:0px
    }

    .dcc8386208 {
        --bui_stack_compensation: var(--bui_spacing_half)
    }

    .f410f52ece {
        --bui_stack_compensation: var(--bui_spacing_1x)
    }

    .c528ec8f17 {
        --bui_stack_compensation: var(--bui_spacing_2x)
    }

    .d21f94aa3c {
        --bui_stack_compensation: var(--bui_spacing_3x)
    }

    .d78dff19a0 {
        --bui_stack_compensation: var(--bui_spacing_4x)
    }

    .f66f916626 {
        --bui_stack_compensation: var(--bui_spacing_6x)
    }

    .ec40dc9bbf {
        --bui_stack_compensation: var(--bui_spacing_8x)
    }
}

@media (min-width: 1024px) {
    .c35b217b07 {
        --bui_stack_compensation:0px
    }

    .e0f989adf8 {
        --bui_stack_compensation: var(--bui_spacing_half)
    }

    .ac449bdf5f {
        --bui_stack_compensation: var(--bui_spacing_1x)
    }

    .db95d73370 {
        --bui_stack_compensation: var(--bui_spacing_2x)
    }

    .aa3a9b8a75 {
        --bui_stack_compensation: var(--bui_spacing_3x)
    }

    .fccb75cd7a {
        --bui_stack_compensation: var(--bui_spacing_4x)
    }

    .e23d91a7f2 {
        --bui_stack_compensation: var(--bui_spacing_6x)
    }

    .a6244624fc {
        --bui_stack_compensation: var(--bui_spacing_8x)
    }
}

@media (min-width: 1280px) {
    .a2bb0390c0 {
        --bui_stack_compensation:0px
    }

    .baafed8639 {
        --bui_stack_compensation: var(--bui_spacing_half)
    }

    .a12effd4f9 {
        --bui_stack_compensation: var(--bui_spacing_1x)
    }

    .c7f5c4081c {
        --bui_stack_compensation: var(--bui_spacing_2x)
    }

    .e6f36945a5 {
        --bui_stack_compensation: var(--bui_spacing_3x)
    }

    .ad26f01921 {
        --bui_stack_compensation: var(--bui_spacing_4x)
    }

    .ef9ac3ecf9 {
        --bui_stack_compensation: var(--bui_spacing_6x)
    }

    .e8d6093793 {
        --bui_stack_compensation: var(--bui_spacing_8x)
    }
}

.cd2e7d62b0>:nth-child(n) {
    margin-block-end:var( --bui_stack_gap_last_child,var(--bui_stack_gap,initial) );margin-block-start: var(--bui_stack_column_item_split,initial);
    margin-inline-end:0;margin-inline-start:0}

.cc828c00a1>:nth-child(n) {
    margin-block-end:var(--bui_stack_column_item_split,initial);margin-block-start: var( --bui_stack_gap_last_child,var(--bui_stack_gap,initial) );
    margin-inline-end:0;margin-inline-start:0}

    .c2931f4182>:nth-child(n), .d79d3de292>:nth-child(n) {
        -webkit-margin-after: 0;
        -webkit-margin-before: initial;
        -webkit-margin-before: var(--bui_stack_gap,initial);
        -webkit-margin-end: initial;
        -webkit-margin-end: var(--bui_stack_gap,initial);
        -webkit-margin-start: initial;
        -webkit-margin-start: var(--bui_stack_column_item_split,initial);
        margin-block-end: 0;
        margin-block-start: 0;
        margin-block-start: var(--bui_stack_gap,initial);
        margin-inline-end: 0;
        margin-inline-end: var(--bui_stack_gap,initial);
        margin-inline-start: 0;
        margin-inline-start: var(--bui_stack_column_item_split,initial);
    }



@media (min-width: 576px) {
    .a6e307ba46>:nth-child(n) {
        margin-block-end:var( --bui_stack_gap_last_child,var(--bui_stack_gap,initial) );
        margin-block-start:var(--bui_stack_column_item_split,initial);margin-inline-end: 0;
        margin-inline-start:0}

    .b8d73ea726>:nth-child(n) {
        margin-block-end:var(--bui_stack_column_item_split,initial);margin-block-start: var( --bui_stack_gap_last_child,var(--bui_stack_gap,initial) );
        margin-inline-end:0;margin-inline-start:0}

    .d8bec7798b>:nth-child(n),.e84d2575c7>:nth-child(n) {
        margin-block-end:0;margin-block-start:var(--bui_stack_gap,initial);margin-inline-end: var(--bui_stack_gap,initial);
        margin-inline-start:var(--bui_stack_column_item_split,initial)}
    }


@media (min-width: 1024px) {
    .a1560f391a>:nth-child(n) {
        margin-block-end:var( --bui_stack_gap_last_child,var(--bui_stack_gap,initial) );
        margin-block-start:var(--bui_stack_column_item_split,initial);margin-inline-end: 0;
        margin-inline-start:0}

    .b8840498b3>:nth-child(n) {
        margin-block-end:var(--bui_stack_column_item_split,initial);margin-block-start: var( --bui_stack_gap_last_child,var(--bui_stack_gap,initial) );
        margin-inline-end:0;margin-inline-start:0}

    .bf9c5d52a6>:nth-child(n),.c7aa57535e>:nth-child(n) {
        margin-block-end:0;margin-block-start:var(--bui_stack_gap,initial);margin-inline-end: var(--bui_stack_gap,initial);
        margin-inline-start:var(--bui_stack_column_item_split,initial)}
    }

@media (min-width: 1280px) {
    .eac5af1ad5>:nth-child(n) {
        margin-block-end:var( --bui_stack_gap_last_child,var(--bui_stack_gap,initial) );
        margin-block-start:var(--bui_stack_column_item_split,initial);margin-inline-end: 0;
        margin-inline-start:0}

    .b463ad3a69>:nth-child(n) {
        margin-block-end:var(--bui_stack_column_item_split,initial);margin-block-start: var( --bui_stack_gap_last_child,var(--bui_stack_gap,initial) );
        margin-inline-end:0;margin-inline-start:0}

    .d35cd58b2c>:nth-child(n),.e03bb5a8e1>:nth-child(n) {
        margin-block-end:0;margin-block-start:var(--bui_stack_gap,initial);margin-inline-end: var(--bui_stack_gap,initial);
        margin-inline-start:var(--bui_stack_column_item_split,initial)}




.c2931f4182>:last-child,.e1ffac4e41>:last-child {
    --bui_stack_gap_last_child: initial
}

}
::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}
ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
@media (min-width: 576px) {
    .a6e307ba46>:last-child,.b8d73ea726>:last-child {
        --bui_stack_gap_last_child:0px
    }

    .d8bec7798b>:last-child,.f3759b332b>:last-child {
        --bui_stack_gap_last_child: initial
    }
}

@media (min-width: 1024px) {
    .a1560f391a>:last-child,.b8840498b3>:last-child {
        --bui_stack_gap_last_child:0px
    }

    .c7aa57535e>:last-child,.f2ce81e2ee>:last-child {
        --bui_stack_gap_last_child: initial
    }
}

@media (min-width: 1280px) {
    .b463ad3a69>:last-child,.eac5af1ad5>:last-child {
        --bui_stack_gap_last_child:0px
    }

    .d35cd58b2c>:last-child,.e36f3a2499>:last-child {
        --bui_stack_gap_last_child: initial
    }
}

.cc828c00a1:only-child,.cd2e7d62b0:only-child {
    --bui_stack_height: 100%
}

@media (min-width: 576px) {
    .a6e307ba46:only-child,.b8d73ea726:only-child {
        --bui_stack_height:100%
    }
}

@media (min-width: 1024px) {
    .a1560f391a:only-child,.b8840498b3:only-child {
        --bui_stack_height:100%
    }
}

@media (min-width: 1280px) {
    .b463ad3a69:only-child,.eac5af1ad5:only-child {
        --bui_stack_height:100%
    }
}

.cc828c00a1:nth-child(n),.cd2e7d62b0:nth-child(n) {
    margin-block-start:0;margin-inline-end:0}

.c2931f4182:nth-child(n) {
    margin-inline-end:calc(var(--bui_stack_compensation, 0)*-1);margin-inline-start: 0
}

.c2931f4182:nth-child(n), .e1ffac4e41:nth-child(n) {
    -webkit-margin-before: 0;
    -webkit-margin-before: calc(var(--bui_stack_compensation, 0)*-1);
    margin-block-start: 0;
    /* margin-block-start: calc(var(--bui_stack_compensation, 0)*-1); */
}



@media (min-width: 576px) {
    .a6e307ba46:nth-child(n),.b8d73ea726:nth-child(n) {
        margin-block-start:0;
        margin-inline-end:0}

    .d8bec7798b:nth-child(n) {
        margin-inline-end:calc(var(--bui_stack_compensation, 0)*-1);margin-inline-start: 0
    }

    .d8bec7798b:nth-child(n),.f3759b332b:nth-child(n) {
        margin-block-start:calc(var(--bui_stack_compensation, 0)*-1)}

    
}

@media (min-width: 1024px) {
    .a1560f391a:nth-child(n),.b8840498b3:nth-child(n) {
        margin-block-start:0;
        margin-inline-end:0}

    .c7aa57535e:nth-child(n) {
        margin-inline-end:calc(var(--bui_stack_compensation, 0)*-1);margin-inline-start: 0
    }

    .c7aa57535e:nth-child(n),.f2ce81e2ee:nth-child(n) {
        margin-block-start:calc(var(--bui_stack_compensation, 0)*-1)}

  
}

@media (min-width: 1280px) {
    .b463ad3a69:nth-child(n),.eac5af1ad5:nth-child(n) {
        margin-block-start:0;
        margin-inline-end:0}

    .d35cd58b2c:nth-child(n) {
        margin-inline-end:calc(var(--bui_stack_compensation, 0)*-1);margin-inline-start: 0
    }

    .d35cd58b2c:nth-child(n),.e36f3a2499:nth-child(n) {
        margin-block-start:calc(var(--bui_stack_compensation, 0)*-1)}

   
}

.a4b06a3b76,.d79e71457a {
    flex-wrap: nowrap
}

.f59a53b0a7 {
    flex-wrap: wrap
}

.b8a00cf838 {
    flex-wrap: wrap-reverse
}

@media (min-width: 576px) {
    .d7c55919e1 {
        flex-wrap:nowrap
    }

    .c4b6e22635 {
        flex-wrap: wrap
    }

    .ad56a26206 {
        flex-wrap: wrap-reverse
    }
}

@media (min-width: 1024px) {
    .fc790c5059 {
        flex-wrap:nowrap
    }

    .df084aad65 {
        flex-wrap: wrap
    }

    .dfaf9b4733 {
        flex-wrap: wrap-reverse
    }
}

@media (min-width: 1280px) {
    .b18eba329e {
        flex-wrap:nowrap
    }

    .f8f3a164ef {
        flex-wrap: wrap
    }

    .da78c25174 {
        flex-wrap: wrap-reverse
    }
}

.aaee4e7cd3 {
    flex-basis: 100%;
    flex-grow: 1
}

.e7a57abb1e {
    flex-shrink: 1
}

.ff77598a4c {
    align-self: flex-start
}

.afd4cc3363 {
    align-self: center
}

.b4b99576df {
    align-self: flex-end
}

.a3989c1938 {
    --bui_stack_column_item_split: auto
}

.e9588ce37d {
    margin-block-start:calc(var(--bui_spacing_2x)*-1);overflow: hidden;
    padding-block-start:var(--bui_spacing_2x)}

.d27447b84e {
    background: var(--bui_action_bar_container_background,initial);
    border-block-start:var( --bui_action_bar_container_border_block_start,initial );padding: var(--bui_action_bar_container_padding,initial);
    position: relative
}

.d27447b84e:before {
    bottom: 0;
    box-shadow: var(--bui_shadow_100);
    content: var(--bui_action_bar_container_before_content,none);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(180deg)
}

.bf852f3e08 .d27447b84e:before,[dir=rtl] .d27447b84e:before {
    transform: rotate(-180deg)
}

.de3cb18704 {
    --bui_action_bar_container_padding: var(--bui_spacing_4x)
}

.f6a23f1c15 {
    --bui_action_bar_container_padding: var(--bui_spacing_6x)
}

@media (min-width: 576px) {
    .a3ba370c55 {
        --bui_action_bar_container_padding:var(--bui_spacing_4x)
    }

    .a9f097e500 {
        --bui_action_bar_container_padding: var(--bui_spacing_6x)
    }
}

@media (min-width: 1024px) {
    .b3522f2146 {
        --bui_action_bar_container_padding:var(--bui_spacing_4x)
    }

    .e0990e2f59 {
        --bui_action_bar_container_padding: var(--bui_spacing_6x)
    }
}

@media (min-width: 1280px) {
    .f9004246f6 {
        --bui_action_bar_container_padding:var(--bui_spacing_4x)
    }

    .b117ed9596 {
        --bui_action_bar_container_padding: var(--bui_spacing_6x)
    }
}

.c80e85253b {
    --bui_action_bar_container_background: var( --bui_color_background_elevation_two );
    --bui_action_bar_container_border_block_start: 0px;
    --bui_action_bar_container_before_content: ""
}

.a7bff28489 {
    --bui_action_bar_container_background: var( --bui_color_background_elevation_one );
    --bui_action_bar_container_border_block_start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
    --bui_action_bar_container_before_content: none
}

@media (min-width: 576px) {
    .bbe36bcf41 {
        --bui_action_bar_container_background:var( --bui_color_background_elevation_two );
        --bui_action_bar_container_border_block_start: 0px;
        --bui_action_bar_container_before_content: ""
    }

    .a094d850fc {
        --bui_action_bar_container_background: var( --bui_color_background_elevation_one );
        --bui_action_bar_container_border_block_start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
        --bui_action_bar_container_before_content: none
    }
}

@media (min-width: 1024px) {
    .f9ad23a7b8 {
        --bui_action_bar_container_background:var( --bui_color_background_elevation_two );
        --bui_action_bar_container_border_block_start: 0px;
        --bui_action_bar_container_before_content: ""
    }

    .a53617b8fa {
        --bui_action_bar_container_background: var( --bui_color_background_elevation_one );
        --bui_action_bar_container_border_block_start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
        --bui_action_bar_container_before_content: none
    }
}

@media (min-width: 1280px) {
    .f44296dca8 {
        --bui_action_bar_container_background:var( --bui_color_background_elevation_two );
        --bui_action_bar_container_border_block_start: 0px;
        --bui_action_bar_container_before_content: ""
    }

    .ae0ef4dc10 {
        --bui_action_bar_container_background: var( --bui_color_background_elevation_one );
        --bui_action_bar_container_border_block_start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
        --bui_action_bar_container_before_content: none
    }
}

@media (max-width: 575px) {
    .b68dc84f99 {
        border-inline-end:0!important;
        border-inline-start:0!important;border-radius: 0!important;
        margin-inline-end:calc(var(--bui_spacing_4x)*-1)!important;margin-inline-start: calc(var(--bui_spacing_4x)*-1)!important
    }
}

.e6208ee469 {
    color: var(--bui_color_foreground)
}

.f45d8e4c32 {
    color: var(--bui_color_foreground_alt)
}

.eb44a03838 {
    color: var(--bui_color_action_foreground)
}

.d068504c75 {
    color: var(--bui_color_constructive_foreground)
}

.d41d25ca26 {
    color: var(--bui_color_destructive_foreground)
}

.e8e9be5fe0 {
    color: var(--bui_color_accent_foreground)
}

.c9bffdb28a {
    color: var(--bui_color_callout_foreground)
}

.fb4fcd9c72 {
    color: #fff
}

.b3139ea094 {
    color: var(--bui_color_foreground_disabled)
}

.c8b48a4179 {
    color: #70bbe9
}

.b0e3176faf {
    color: var(--bui_color_brand_genius_secondary_foreground)
}

.a6a383700c {
    color: currentcolor
}

.c5b233285a {
    font-family: var(--bui_font_display_1_font-family);
    font-size: var(--bui_font_display_1_font-size);
    font-weight: var(--bui_font_display_1_font-weight);
    line-height: var(--bui_font_display_1_line-height)
}

.f6e057fc80 {
    font-family: var(--bui_font_display_2_font-family);
    font-size: var(--bui_font_display_2_font-size);
    font-weight: var(--bui_font_display_2_font-weight);
    line-height: var(--bui_font_display_2_line-height)
}

.f49c3e3e97 {
    font-family: var(--bui_font_display_3_font-family);
    font-size: var(--bui_font_display_3_font-size);
    font-weight: var(--bui_font_display_3_font-weight);
    line-height: var(--bui_font_display_3_line-height)
}

.f9466e1d93 {
    font-family: var(--bui_font_featured_1_font-family);
    font-size: var(--bui_font_featured_1_font-size);
    font-weight: var(--bui_font_featured_1_font-weight);
    line-height: var(--bui_font_featured_1_line-height)
}

.d034a4c0c7 {
    font-family: var(--bui_font_featured_2_font-family);
    font-size: var(--bui_font_featured_2_font-size);
    font-weight: var(--bui_font_featured_2_font-weight);
    line-height: var(--bui_font_featured_2_line-height)
}

.dd5c42561a {
    font-family: var(--bui_font_featured_3_font-family);
    font-size: var(--bui_font_featured_3_font-size);
    font-weight: var(--bui_font_featured_3_font-weight);
    line-height: var(--bui_font_featured_3_line-height)
}

.f829e04eec {
    font-family: var(--bui_font_headline_1_font-family);
    font-size: var(--bui_font_headline_1_font-size);
    font-weight: var(--bui_font_headline_1_font-weight);
    line-height: var(--bui_font_headline_1_line-height)
}

.af8fbdf136 {
    font-family: var(--bui_font_headline_2_font-family);
    font-size: var(--bui_font_headline_2_font-size);
    font-weight: var(--bui_font_headline_2_font-weight);
    line-height: var(--bui_font_headline_2_line-height)
}

.f6431b446c {
    font-family: var(--bui_font_headline_3_font-family);
    font-size: var(--bui_font_headline_3_font-size);
    font-weight: var(--bui_font_headline_3_font-weight);
    line-height: var(--bui_font_headline_3_line-height)
}

.e1eebb6a1e {
    font-family: var(--bui_font_strong_1_font-family);
    font-size: var(--bui_font_strong_1_font-size);
    font-weight: var(--bui_font_strong_1_font-weight);
    line-height: var(--bui_font_strong_1_line-height)
}

.a3332d346a {
    font-family: var(--bui_font_strong_2_font-family);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height)
}

.a3b8729ab1 {
    font-family: var(--bui_font_emphasized_1_font-family);
    font-size: var(--bui_font_emphasized_1_font-size);
    font-weight: var(--bui_font_emphasized_1_font-weight);
    line-height: var(--bui_font_emphasized_1_line-height)
}

.ccb65902b2 {
    font-family: var(--bui_font_emphasized_2_font-family);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height)
}

.bcdcb105b3 {
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height)
}

.a53cbfa6de {
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height)
}

.abf093bdfe {
    font-family: var(--bui_font_small_1_font-family);
    font-size: var(--bui_font_small_1_font-size);
    font-weight: var(--bui_font_small_1_font-weight);
    line-height: var(--bui_font_small_1_line-height)
}

.c8079eaf8c {
    font-family: var(--bui_font_small_2_font-family);
    font-size: var(--bui_font_small_2_font-size);
    font-weight: var(--bui_font_small_2_font-weight);
    line-height: var(--bui_font_small_2_line-height)
}

.d5ee208f51 {
    text-align: start
}

.e2c7c084e5 {
    text-align: center
}

.efcd70b4c4 {
    text-align: end
}

.a2b61757a9 {
    text-decoration: underline
}

.f753a37ca4 {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

.f2ce4336b0 {
    text-decoration: line-through
}

.f20ed5c8c3 {
    border-radius: var(--bui_border_radius_200)
}

.c8dedacbe7 {
    display: var(--bui_alert_title_display);
    margin-bottom: var(--bui_spacing_2x)
}

.e545991276 {
    margin-top: 0
}

.fd95ac74dd {
    align-items: center;
    display: inline-flex;
    height: var(--bui_alert_icon_height)
}

.aea2275e99 {
    --bui_alert_icon_height: var(--bui_font_body_2_line-height)
}

.a6f20c9523 {
    --bui_alert_background: var(--bui_color_constructive_background_alt);
    --bui_alert_border: 1px solid var(--bui_color_constructive_border);
    --bui_alert_color: var(--bui_color_constructive_foreground)
}

.a5fec831ec {
    --bui_alert_background: var(--bui_color_destructive_background_alt);
    --bui_alert_border: 1px solid var(--bui_color_destructive_border);
    --bui_alert_color: var(--bui_color_destructive_foreground)
}

.b5e29283bd {
    --bui_alert_background: #70bbe9;
    --bui_alert_border: 1px solid #70bbe9;
    --bui_alert_color: var(--bui_color_foreground)
}

.b302736b7b {
    --bui_alert_title_display: none;
    --bui_alert_icon_height: var(--bui_font_body_2_line-height);
    background-color: transparent;
    border: none;
    color: var(--bui_alert_color);
    padding: 0
}

.d4f5a8cb27 {
    --bui_alert_title_display: block;
    --bui_alert_icon_height: var(--bui_font_strong_1_line-height);
    background-color: var(--bui_alert_background);
    border: var(--bui_alert_border);
    color: var(--bui_color_foreground);
    padding: var(--bui_spacing_4x)
}

@media (min-width: 576px) {
    .faf1b3855e {
        --bui_alert_title_display:none;
        --bui_alert_icon_height: var(--bui_font_body_2_line-height);
        background-color: transparent;
        border: none;
        color: var(--bui_alert_color);
        padding: 0
    }

    .f30cb25233 {
        --bui_alert_title_display: block;
        --bui_alert_icon_height: var(--bui_font_strong_1_line-height);
        background-color: var(--bui_alert_background);
        border: var(--bui_alert_border);
        color: var(--bui_color_foreground);
        padding: var(--bui_spacing_4x)
    }
}

@media (min-width: 1024px) {
    .a8762c465f {
        --bui_alert_title_display:none;
        --bui_alert_icon_height: var(--bui_font_body_2_line-height);
        background-color: transparent;
        border: none;
        color: var(--bui_alert_color);
        padding: 0
    }

    .b6fda47fc0 {
        --bui_alert_title_display: block;
        --bui_alert_icon_height: var(--bui_font_strong_1_line-height);
        background-color: var(--bui_alert_background);
        border: var(--bui_alert_border);
        color: var(--bui_color_foreground);
        padding: var(--bui_spacing_4x)
    }
}

@media (min-width: 1280px) {
    .d4236122e6 {
        --bui_alert_title_display:none;
        --bui_alert_icon_height: var(--bui_font_body_2_line-height);
        background-color: transparent;
        border: none;
        color: var(--bui_alert_color);
        padding: 0
    }

    .d9d4eefcd3 {
        --bui_alert_title_display: block;
        --bui_alert_icon_height: var(--bui_font_strong_1_line-height);
        background-color: var(--bui_alert_background);
        border: var(--bui_alert_border);
        color: var(--bui_color_foreground);
        padding: var(--bui_spacing_4x)
    }
}

.b795ec59f5 {
    max-width: 100%
}

.aea002392a {
    position: relative
}

.f8e5e86e33 {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.f8e5e86e33>img,.f8e5e86e33>picture,.f8e5e86e33>picture img {
    display: block;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.b795ec59f5 {
    --_bui_aspect_ratio_width--s: var(--bui_aspect_ratio_width--s,var(--bui_aspect_ratio_width--reset));
    --bui_aspect_ratio_width: var(--_bui_aspect_ratio_width--xl,var(--_bui_aspect_ratio_width--l,var(--_bui_aspect_ratio_width--m,var(--_bui_aspect_ratio_width--s) ) ) );
    width: var(--bui_aspect_ratio_width)
}

@media (min-width: 576px) {
    .b795ec59f5 {
        --_bui_aspect_ratio_width--m:var(--bui_aspect_ratio_width--m)
    }
}

@media (min-width: 1024px) {
    .b795ec59f5 {
        --_bui_aspect_ratio_width--l:var(--bui_aspect_ratio_width--l)
    }
}

@media (min-width: 1280px) {
    .b795ec59f5 {
        --_bui_aspect_ratio_width--xl:var(--bui_aspect_ratio_width--xl)
    }
}

.aea002392a {
    --_bui_aspect_ratio_padding-top--s: var(--bui_aspect_ratio_padding-top--s,var(--bui_aspect_ratio_padding-top--reset));
    --bui_aspect_ratio_padding-top: var(--_bui_aspect_ratio_padding-top--xl,var(--_bui_aspect_ratio_padding-top--l,var(--_bui_aspect_ratio_padding-top--m,var(--_bui_aspect_ratio_padding-top--s) ) ) );
    padding-top: var(--bui_aspect_ratio_padding-top)
}

@media (min-width: 576px) {
    .aea002392a {
        --_bui_aspect_ratio_padding-top--m:var(--bui_aspect_ratio_padding-top--m)
    }
}

@media (min-width: 1024px) {
    .aea002392a {
        --_bui_aspect_ratio_padding-top--l:var(--bui_aspect_ratio_padding-top--l)
    }
}

@media (min-width: 1280px) {
    .aea002392a {
        --_bui_aspect_ratio_padding-top--xl:var(--bui_aspect_ratio_padding-top--xl)
    }
}

.e5a3812a75 {
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    position: relative
}

.e3fa9175ee {
    display: block;
    max-height: 100%;
    max-width: 100%
}

.ba6d792fd4 {
    width: 100%
}

.b1a5e281e7 {
    height: 100%
}

.e5a3812a75 {
    --_bui_image_width--s: var(--bui_image_width--s,var(--bui_image_width--reset));
    --bui_image_width: var(--_bui_image_width--xl,var(--_bui_image_width--l,var(--_bui_image_width--m,var(--_bui_image_width--s) ) ) );
    --_bui_image_height--s: var(--bui_image_height--s,var(--bui_image_height--reset));
    --bui_image_height: var(--_bui_image_height--xl,var(--_bui_image_height--l,var(--_bui_image_height--m,var(--_bui_image_height--s) ) ) );
    height: var(--bui_image_height);
    width: var(--bui_image_width)
}

@media (min-width: 576px) {
    .e5a3812a75 {
        --_bui_image_width--m:var(--bui_image_width--m);
        --_bui_image_height--m: var(--bui_image_height--m)
    }
}

@media (min-width: 1024px) {
    .e5a3812a75 {
        --_bui_image_width--l:var(--bui_image_width--l);
        --_bui_image_height--l: var(--bui_image_height--l)
    }
}

@media (min-width: 1280px) {
    .e5a3812a75 {
        --_bui_image_width--xl:var(--bui_image_width--xl);
        --_bui_image_height--xl: var(--bui_image_height--xl)
    }
}

.d354f8f44f {
    -o-object-fit: cover!important;
    object-fit: cover!important
}

.ac59045dae {
    -o-object-fit: scale-down!important;
    object-fit: scale-down!important
}

.bc0bcd4e57 {
    visibility: hidden
}

.ee5449dbd9 {
    border-radius: var(--bui_border_radius_100)
}

.fcc0196855 {
    border-radius: var(--bui_border_radius_200)
}

.c2accc9870 {
    border-radius: var(--bui_border_radius_300)
}

.df979ce2a2 {
    background-color: var(--bui_color_background_alt);
    box-sizing: border-box;
    height: 100%;
    padding: var(--bui_spacing_6x);
    position: absolute;
    width: 100%
}

[data-bui-keyboard] .c82435a4b8:focus {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.f53e278e95 {
    overflow: hidden
}

.c82435a4b8 {
    padding: calc(var(--bui_spacing_1x)*var(--bui_box_padding--s, var(--bui_box_padding--reset)));
    --bui_box_padding: var(--_bui_box_padding--xl,var(--_bui_box_padding--l,var(--_bui_box_padding--m,var(padding) ) ) );
    padding: var(--bui_box_padding)
}

@media (min-width: 576px) {
    .c82435a4b8 {
        --_bui_box_padding--m:calc(var(--bui_spacing_1x)*var(--bui_box_padding--m))
    }
}

@media (min-width: 1024px) {
    .c82435a4b8 {
       padding:calc(var(--bui_spacing_1x)*var(--bui_box_padding--l))
    }
}

@media (min-width: 1280px) {
    .c82435a4b8 {
        --_bui_box_padding--xl:calc(var(--bui_spacing_1x)*var(--bui_box_padding--xl))
    }
}

.a18aeea94d {
    border: var(--bui_border_width_100) solid
}

.eda30a72cc {
    border-radius: var(--bui_border_radius_100)
}

.d794b7a0f7 {
    border-radius: var(--bui_border_radius_200)
}

.e4a650fb42 {
    border-radius: var(--bui_border_radius_300)
}

.b7e47c7391 {
    border-radius: 9999px
}

.af61db342c {
    background-color: var(--bui_color_destructive_background_alt)
}

.fe0ab89004 {
    background-color: var(--bui_color_constructive_background_alt)
}

.ae029cf100 {
    background-color: var(--bui_color_callout_background_alt)
}

.f5be08bbe2 {
    background-color: var(--bui_color_accent_background_alt)
}

.a93e0e54e8 {
    background-color: var(--bui_color_action_background_alt)
}

.bed745e358 {
    background-color: var(--bui_color_background_alt)
}

.a178069f51 {
    background-color: var(--bui_color_background_elevation_one)
}

.c0fdac86a6 {
    background-color: var(--bui_color_background_elevation_two)
}

.f2fd2561c9 {
    background-color:#70bbe9;
    color: #70bbe9
}

.b3a904144c {
    background-color: var(--bui_color_brand_primary_background_dynamic);
    color: var(--bui_color_on_brand_primary_background_dynamic)
}

.f36a496177 {
    border-color: #70bbe9
}

.c3fa3887ed {
    border-color: var(--bui_color_destructive_border)
}

.ca826427e5 {
    border-color: var(--bui_color_constructive_border)
}

.d4368d542f {
    border-color: var(--bui_color_accent_border)
}

.bbbfb8fdc4 {
    border-color: var(--bui_color_callout_border)
}

.a6ae3c2b40 {
    border-color: var(--bui_color_border_alt)
}

.d01af75260 {
    border-color: var(--bui_color_border)
}

.d26cb44391 {
    padding-block-end:0;padding-block-start:0}

.f21c71b39d {
    padding-inline-end:0;padding-inline-start:0}

.f89f0b5683 {
    align-items: center;
    background-color: var(--bui_color_background);
    box-sizing: border-box;
    color: var(--bui_color_on_background);
    display: flex;
    justify-content: center;
    position: relative;
    text-transform: uppercase
}

.b3f31bb1be {
    background-color: var(--bui_color_background_alt);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0
}

.b3f31bb1be,.ed3971de08 {
    height: 100%;
    width: 100%
}

.bf1d1f48fe {
    font-family: var(--bui_font_small_1_font-family);
    font-size: var(--bui_font_small_1_font-size);
    font-weight: var(--bui_font_small_1_font-weight);
    height: var(--bui_spacing_6x);
    line-height: var(--bui_font_small_1_line-height);
    width: var(--bui_spacing_6x)
}

.e6eb2de0dc {
    font-family: var(--bui_font_strong_2_font-family);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    height: var(--bui_spacing_8x);
    line-height: var(--bui_font_strong_2_line-height);
    width: var(--bui_spacing_8x)
}

.f101498cd4 {
    font-family: var(--bui_font_headline_3_font-family);
    font-size: var(--bui_font_headline_3_font-size);
    font-weight: var(--bui_font_headline_3_font-weight);
    height: calc(var(--bui_spacing_1x)*12);
    line-height: var(--bui_font_headline_3_line-height);
    width: calc(var(--bui_spacing_1x)*12)
}

.d489afa5c8 {
    font-family: var(--bui_font_headline_2_font-family);
    font-size: var(--bui_font_headline_2_font-size);
    font-weight: var(--bui_font_headline_2_font-weight);
    height: calc(var(--bui_spacing_1x)*16);
    line-height: var(--bui_font_headline_2_line-height);
    width: calc(var(--bui_spacing_1x)*16)
}

.a1a2323797 {
    font-family: var(--bui_font_headline_1_font-family);
    font-size: var(--bui_font_headline_1_font-size);
    font-weight: var(--bui_font_headline_1_font-weight);
    height: calc(var(--bui_spacing_1x)*32);
    line-height: var(--bui_font_headline_1_line-height);
    width: calc(var(--bui_spacing_1x)*32)
}

@media (min-width: 576px) {
    .a7ca54d3c7 {
        font-family:var(--bui_font_small_1_font-family);
        font-size: var(--bui_font_small_1_font-size);
        font-weight: var(--bui_font_small_1_font-weight);
        height: var(--bui_spacing_6x);
        line-height: var(--bui_font_small_1_line-height);
        width: var(--bui_spacing_6x)
    }

    .b292bae0e6 {
        font-family: var(--bui_font_strong_2_font-family);
        font-size: var(--bui_font_strong_2_font-size);
        font-weight: var(--bui_font_strong_2_font-weight);
        height: var(--bui_spacing_8x);
        line-height: var(--bui_font_strong_2_line-height);
        width: var(--bui_spacing_8x)
    }

    .a1ad281b22 {
        font-family: var(--bui_font_headline_3_font-family);
        font-size: var(--bui_font_headline_3_font-size);
        font-weight: var(--bui_font_headline_3_font-weight);
        height: calc(var(--bui_spacing_1x)*12);
        line-height: var(--bui_font_headline_3_line-height);
        width: calc(var(--bui_spacing_1x)*12)
    }

    .ccc95fc18c {
        font-family: var(--bui_font_headline_2_font-family);
        font-size: var(--bui_font_headline_2_font-size);
        font-weight: var(--bui_font_headline_2_font-weight);
        height: calc(var(--bui_spacing_1x)*16);
        line-height: var(--bui_font_headline_2_line-height);
        width: calc(var(--bui_spacing_1x)*16)
    }

    .edd0dd2e8d {
        font-family: var(--bui_font_headline_1_font-family);
        font-size: var(--bui_font_headline_1_font-size);
        font-weight: var(--bui_font_headline_1_font-weight);
        height: calc(var(--bui_spacing_1x)*32);
        line-height: var(--bui_font_headline_1_line-height);
        width: calc(var(--bui_spacing_1x)*32)
    }
}

@media (min-width: 1024px) {
    .f6cac9550f {
        font-family:var(--bui_font_small_1_font-family);
        font-size: var(--bui_font_small_1_font-size);
        font-weight: var(--bui_font_small_1_font-weight);
        height: var(--bui_spacing_6x);
        line-height: var(--bui_font_small_1_line-height);
        width: var(--bui_spacing_6x)
    }

    .bc1380bcbc {
        font-family: var(--bui_font_strong_2_font-family);
        font-size: var(--bui_font_strong_2_font-size);
        font-weight: var(--bui_font_strong_2_font-weight);
        height: var(--bui_spacing_8x);
        line-height: var(--bui_font_strong_2_line-height);
        width: var(--bui_spacing_8x)
    }

    .bddd9d5624 {
        font-family: var(--bui_font_headline_3_font-family);
        font-size: var(--bui_font_headline_3_font-size);
        font-weight: var(--bui_font_headline_3_font-weight);
        height: calc(var(--bui_spacing_1x)*12);
        line-height: var(--bui_font_headline_3_line-height);
        width: calc(var(--bui_spacing_1x)*12)
    }

    .acf2243e46 {
        font-family: var(--bui_font_headline_2_font-family);
        font-size: var(--bui_font_headline_2_font-size);
        font-weight: var(--bui_font_headline_2_font-weight);
        height: calc(var(--bui_spacing_1x)*16);
        line-height: var(--bui_font_headline_2_line-height);
        width: calc(var(--bui_spacing_1x)*16)
    }

    .a10d150a19 {
        font-family: var(--bui_font_headline_1_font-family);
        font-size: var(--bui_font_headline_1_font-size);
        font-weight: var(--bui_font_headline_1_font-weight);
        height: calc(var(--bui_spacing_1x)*32);
        line-height: var(--bui_font_headline_1_line-height);
        width: calc(var(--bui_spacing_1x)*32)
    }
}

@media (min-width: 1280px) {
    .fd8895dd41 {
        font-family:var(--bui_font_small_1_font-family);
        font-size: var(--bui_font_small_1_font-size);
        font-weight: var(--bui_font_small_1_font-weight);
        height: var(--bui_spacing_6x);
        line-height: var(--bui_font_small_1_line-height);
        width: var(--bui_spacing_6x)
    }

    .a419249714 {
        font-family: var(--bui_font_strong_2_font-family);
        font-size: var(--bui_font_strong_2_font-size);
        font-weight: var(--bui_font_strong_2_font-weight);
        height: var(--bui_spacing_8x);
        line-height: var(--bui_font_strong_2_line-height);
        width: var(--bui_spacing_8x)
    }

    .db57877e48 {
        font-family: var(--bui_font_headline_3_font-family);
        font-size: var(--bui_font_headline_3_font-size);
        font-weight: var(--bui_font_headline_3_font-weight);
        height: calc(var(--bui_spacing_1x)*12);
        line-height: var(--bui_font_headline_3_line-height);
        width: calc(var(--bui_spacing_1x)*12)
    }

    .f6259916fe {
        font-family: var(--bui_font_headline_2_font-family);
        font-size: var(--bui_font_headline_2_font-size);
        font-weight: var(--bui_font_headline_2_font-weight);
        height: calc(var(--bui_spacing_1x)*16);
        line-height: var(--bui_font_headline_2_line-height);
        width: calc(var(--bui_spacing_1x)*16)
    }

    .f959a49a71 {
        font-family: var(--bui_font_headline_1_font-family);
        font-size: var(--bui_font_headline_1_font-size);
        font-weight: var(--bui_font_headline_1_font-weight);
        height: calc(var(--bui_spacing_1x)*32);
        line-height: var(--bui_font_headline_1_line-height);
        width: calc(var(--bui_spacing_1x)*32)
    }
}

.bd079ec83b {
    background-color: var(--bui_color_destructive_background);
    color: var(--bui_color_on_destructive_background)
}

.b846a3a53f {
    background-color: var(--bui_color_callout_background);
    color: var(--bui_color_on_callout_background)
}

.df76b08e0c {
    background-color: var(--bui_color_accent_background);
    color: var(--bui_color_on_accent_background)
}

.cee561ff7e {
    background-color: var(--bui_color_constructive_background);
    color: var(--bui_color_on_constructive_background)
}

.ef4e509fe0 {
    background: transparent;
    color: currentcolor
}

.ef4e509fe0:before {
    background-color: currentcolor;
    border-radius: 50%;
    bottom: 0;
    content: "";
    left: 0;
    opacity: .15;
    position: absolute;
    right: 0;
    top: 0
}

.c92cfba0d0 {
    border: var(--bui_border_width_200) solid #fff
}

.c646bf9659 {
    border: var(--bui_border_width_200) solid var(--bui_color_accent_border)
}

.d4c540ef63 {
    border: var(--bui_border_width_200) solid var(--bui_color_callout_border)
}

.cd5909e0f0 {
    border: var(--bui_border_width_200) solid var(--bui_color_destructive_border)
}

.a1984fdf09 {
    border: var(--bui_border_width_200) solid var(--bui_color_constructive_border)
}

@media (max-width: 575px) {
    .a4cbc7b9f5 {
        display:block
    }

    .f3942475b4 {
        display: none
    }
}

@media (min-width: 576px) {
    .b7efa77ac6 {
        display:block
    }

    .a312d3fe67 {
        display: none
    }
}

@media (min-width: 1024px) {
    .bd4784f2d6 {
        display:block
    }

    .b5156fd448 {
        display: none
    }
}

@media (min-width: 1280px) {
    .d6b5cc954e {
        display:block
    }

    .b88ad0398a {
        display: none
    }
}

.c147fc6dd1 {
    align-items: center;
    background-color: var(--bui_color_background);
    border: var(--bui_border_width_100) solid transparent;
    border-radius: var(--bui_border_radius_100);
    color: var(--bui_color_on_background);
    display: inline-flex;
    padding: calc(var(--bui_spacing_half) - var(--bui_border_width_100)) var(--bui_spacing_1x)
}

.a106f16a04 {
    padding: calc(var(--bui_spacing_1x) - var(--bui_border_width_100))
}

.b30f8eb2d6 {
    margin-inline-start:var(--bui_spacing_1x)}

.b30f8eb2d6 first-child {
    margin-inline-start:0
}

.b047c3a9e4 {
    margin-inline-start:var(--bui_spacing_1x)}

.b81491d8c4 {
    background-color: var(--bui_color_background_alt);
    border-color: transparent;
    color: var(--bui_color_foreground)
}

.bbb18af3e3 {
    background-color: var(--bui_color_destructive_background_dynamic);
    border-color: var(--bui_color_destructive_border);
    color: var(--bui_color_on_destructive_background_dynamic)
}

.d0c751f549 {
    background-color: var(--bui_color_callout_background_dynamic);
    border-color: var(--bui_color_callout_border);
    color: var(--bui_color_on_callout_background_dynamic)
}

.d8d1f2a629 {
    background-color: var(--bui_color_accent_background_dynamic);
    border-color: var(--bui_color_accent_border);
    color: var(--bui_color_on_accent_background_dynamic)
}

.d18b4a6026 {
    background-color: var(--bui_color_constructive_background_dynamic);
    color: var(--bui_color_on_constructive_background_dynamic)
}

.a0878f35a0,.d18b4a6026 {
    border-color: transparent
}

.a0878f35a0 {
    background-color:#70bbe9;
    color: #70bbe9
}

.c8f17f7dbd {
    background-color: #70bbe9;
    border-color: transparent;
    color: #70bbe9
}

.d516b1d73e {
    background: var(--bui_color_background_elevation_one);
    border-color: var(--bui_color_border);
    color: var(--bui_color_foreground)
}

.ab7c60350d {
    background: var(--bui_color_background_base);
    border-color: transparent;
    color: var(--bui_color_foreground_alt)
}

.bbb18af3e3.b81491d8c4 {
    background-color: var(--bui_color_destructive_background_alt);
    border-color: transparent;
    color: var(--bui_color_destructive_foreground)
}

.d0c751f549.b81491d8c4 {
    background-color: var(--bui_color_callout_background_alt);
    border-color: transparent;
    color: var(--bui_color_callout_foreground)
}

.d8d1f2a629.b81491d8c4 {
    background-color: var(--bui_color_accent_background_alt);
    border-color: transparent;
    color: var(--bui_color_accent_foreground)
}

.d18b4a6026.b81491d8c4 {
    background-color: var(--bui_color_constructive_background_alt);
    border-color: transparent;
    color: var(--bui_color_constructive_foreground)
}

.a0878f35a0.b81491d8c4 {
    background-color: #70bbe9;
    border-color: transparent;
    color: #70bbe9
}

.d516b1d73e.b81491d8c4 {
    background: var(--bui_color_background_elevation_one);
    border-color: var(--bui_color_border_alt);
    color: var(--bui_color_foreground_alt)
}

.ab7c60350d.b81491d8c4 {
    background: var(--bui_color_black_with_alpha);
    border-color: transparent;
    color: #fff
}

.ffd93a9ecb {
    padding-inline-end:calc(var(--bui_spacing_1x)*9);position: relative
}

.f11eccb5e8 {
    position: absolute;
    right: 0;
    top: var(--bui_spacing_half);
    z-index: 5
}

.e694727c61 .f11eccb5e8,[dir=rtl] .f11eccb5e8 {
    left: 0;
    right: auto
}

.e91f709929 .f11eccb5e8 {
    right: var(--bui_spacing_4x);
    top: var(--bui_spacing_4x)
}

.e694727c61 .e91f709929 .f11eccb5e8,[dir=rtl] .e91f709929 .f11eccb5e8 {
    left: var(--bui_spacing_4x);
    right: auto
}

.b66086baab,.e91f709929 {
    padding-inline-end:0}

.ba9417274c,.c651ca389e {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.c651ca389e {
    pointer-events: none
}

.ba9417274c {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: var(--bui_spacing_4x);
    transform: translateZ(0)
}

.a089bea0a0 {
    pointer-events: all
}

.a552d3eb99 .a089bea0a0 {
    align-items: center;
    display: flex;
    justify-content: center
}

.b932011302 .ba9417274c {
    background-color: var(--bui_color_black_with_alpha)
}

.b932011302 .a089bea0a0 {
    text-align: center
}

.b932011302 .a089bea0a0,.e12b2684e7 .a089bea0a0,.fa2bb6146b .a089bea0a0 {
    width: 100%
}

.e7ecad7317 .a089bea0a0,.f781816f0f .a089bea0a0 {
    height: 100%
}

.e12b2684e7 .ba9417274c {
    background: linear-gradient(0,rgba(0,0,0,.349),rgba(0,0,0,.424) 13.81%,rgba(0,0,0,.494) 28.27%,rgba(0,0,0,.561) 42.12%,rgba(0,0,0,.616) 56.28%,rgba(0,0,0,.663) 70.13%,rgba(0,0,0,.69) 84.88%,rgba(0,0,0,.702));
    bottom: auto;
    justify-content: start
}

.fa2bb6146b .ba9417274c {
    background: linear-gradient(180deg,rgba(0,0,0,.349),rgba(0,0,0,.424) 13.81%,rgba(0,0,0,.494) 28.27%,rgba(0,0,0,.561) 42.12%,rgba(0,0,0,.616) 56.28%,rgba(0,0,0,.663) 70.13%,rgba(0,0,0,.69) 84.88%,rgba(0,0,0,.702));
    justify-content: start;
    top: auto
}

.afc53a614a .fa2bb6146b .ba9417274c,[dir=rtl] .fa2bb6146b .ba9417274c {
    background: linear-gradient(-180deg,rgba(0,0,0,.349),rgba(0,0,0,.424) 13.81%,rgba(0,0,0,.494) 28.27%,rgba(0,0,0,.561) 42.12%,rgba(0,0,0,.616) 56.28%,rgba(0,0,0,.663) 70.13%,rgba(0,0,0,.69) 84.88%,rgba(0,0,0,.702))
}

.e12b2684e7 .ba9417274c:before,.fa2bb6146b .ba9417274c:before {
    content: "";
    height: calc(var(--bui_spacing_1x)*13);
    left: 0;
    position: absolute;
    right: 0
}

.e12b2684e7 .ba9417274c:before {
    background: linear-gradient(0,transparent,rgba(0,0,0,.012) 13.54%,rgba(0,0,0,.039) 27.6%,rgba(0,0,0,.082) 42.19%,rgba(0,0,0,.137) 56.77%,rgba(0,0,0,.204) 71.35%,rgba(0,0,0,.275) 85.94%,rgba(0,0,0,.349));
    top: 100%
}

.fa2bb6146b .ba9417274c:before {
    background: linear-gradient(180deg,transparent,rgba(0,0,0,.012) 13.54%,rgba(0,0,0,.039) 27.6%,rgba(0,0,0,.082) 42.19%,rgba(0,0,0,.137) 56.77%,rgba(0,0,0,.204) 71.35%,rgba(0,0,0,.275) 85.94%,rgba(0,0,0,.349));
    bottom: 100%
}

.afc53a614a .fa2bb6146b .ba9417274c:before,[dir=rtl] .fa2bb6146b .ba9417274c:before {
    background: linear-gradient(-180deg,transparent,rgba(0,0,0,.012) 13.54%,rgba(0,0,0,.039) 27.6%,rgba(0,0,0,.082) 42.19%,rgba(0,0,0,.137) 56.77%,rgba(0,0,0,.204) 71.35%,rgba(0,0,0,.275) 85.94%,rgba(0,0,0,.349))
}

.f781816f0f .ba9417274c {
    align-items: start;
    background: linear-gradient(-90deg,rgba(0,0,0,.349),rgba(0,0,0,.424) 13.81%,rgba(0,0,0,.494) 28.27%,rgba(0,0,0,.561) 42.12%,rgba(0,0,0,.616) 56.28%,rgba(0,0,0,.663) 70.13%,rgba(0,0,0,.69) 84.88%,rgba(0,0,0,.702));
    justify-content: start;
    left: 0;
    right: auto;
    width: auto
}

.afc53a614a .f781816f0f .ba9417274c,.e7ecad7317 .ba9417274c,[dir=rtl] .f781816f0f .ba9417274c {
    background: linear-gradient(90deg,rgba(0,0,0,.349),rgba(0,0,0,.424) 13.81%,rgba(0,0,0,.494) 28.27%,rgba(0,0,0,.561) 42.12%,rgba(0,0,0,.616) 56.28%,rgba(0,0,0,.663) 70.13%,rgba(0,0,0,.69) 84.88%,rgba(0,0,0,.702));
    left: auto;
    right: 0
}

.e7ecad7317 .ba9417274c {
    align-items: start;
    justify-content: end;
    width: auto
}

.afc53a614a .e7ecad7317 .ba9417274c,[dir=rtl] .e7ecad7317 .ba9417274c {
    background: linear-gradient(-90deg,rgba(0,0,0,.349),rgba(0,0,0,.424) 13.81%,rgba(0,0,0,.494) 28.27%,rgba(0,0,0,.561) 42.12%,rgba(0,0,0,.616) 56.28%,rgba(0,0,0,.663) 70.13%,rgba(0,0,0,.69) 84.88%,rgba(0,0,0,.702));
    left: 0;
    right: auto
}

.e7ecad7317 .ba9417274c:before,.f781816f0f .ba9417274c:before {
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    width: calc(var(--bui_spacing_1x)*13)
}

.f781816f0f .ba9417274c:before {
    background: linear-gradient(-90deg,transparent,rgba(0,0,0,.012) 13.54%,rgba(0,0,0,.039) 27.6%,rgba(0,0,0,.082) 42.19%,rgba(0,0,0,.137) 56.77%,rgba(0,0,0,.204) 71.35%,rgba(0,0,0,.275) 85.94%,rgba(0,0,0,.349));
    left: 100%;
    right: auto
}

.afc53a614a .f781816f0f .ba9417274c:before,.e7ecad7317 .ba9417274c:before,[dir=rtl] .f781816f0f .ba9417274c:before {
    background: linear-gradient(90deg,transparent,rgba(0,0,0,.012) 13.54%,rgba(0,0,0,.039) 27.6%,rgba(0,0,0,.082) 42.19%,rgba(0,0,0,.137) 56.77%,rgba(0,0,0,.204) 71.35%,rgba(0,0,0,.275) 85.94%,rgba(0,0,0,.349));
    left: auto;
    right: 100%
}

.afc53a614a .e7ecad7317 .ba9417274c:before,[dir=rtl] .e7ecad7317 .ba9417274c:before {
    background: linear-gradient(-90deg,transparent,rgba(0,0,0,.012) 13.54%,rgba(0,0,0,.039) 27.6%,rgba(0,0,0,.082) 42.19%,rgba(0,0,0,.137) 56.77%,rgba(0,0,0,.204) 71.35%,rgba(0,0,0,.275) 85.94%,rgba(0,0,0,.349));
    left: 100%;
    right: auto
}

.d8f4d0b504 .ba9417274c {
    padding: 0
}

.db9f504deb {
    position: relative
}

.d963552ea0 {
    align-items: center;
    display: inline-flex;
    height: var(--bui_font_strong_1_line-height)
}

.f6b7707932 {
    height: var(--bui_font_body_2_line-height)
}

.b8115ac520 {
    width: calc(var(--bui_spacing_1x)*16)
}

.c63ea29cad,.e0a7dcceb5 {
    margin: 0
}

.e50acbb05b {
    border-radius: 99px;
    box-sizing: border-box;
    display: inline-block;
    min-width: calc(var(--bui_spacing_4x) + var(--bui_spacing_half));
    padding: 0 var(--bui_spacing_1x);
    text-align: center;
    vertical-align: top
}

.d7d1d71b5a {
    background-color: var(--bui_color_background);
    color: var(--bui_color_on_background)
}

.fac1953f33 {
    background-color: var(--bui_color_destructive_background);
    color: var(--bui_color_on_destructive_background)
}

.b745f44c27 {
    background-color: #70bbe9;
    color: var(--bui_color_on_action_background)
}

.e50acbb05b:empty {
    height: var(--bui_spacing_2x);
    min-width: auto;
    padding: 0;
    width: var(--bui_spacing_2x)
}

.a6066ac361 {
    display: inline-block;
    position: relative;
    vertical-align: top
}

.c778b3e5b0 {
    left: 100%;
    position: absolute;
    top: calc(var(--bui_spacing_half)*-1);
    transform: translateX(calc(var(--bui_spacing_1x)*-3))
}

.d51d1b34c8 .c778b3e5b0,[dir=rtl] .c778b3e5b0 {
    left: auto;
    right: 100%;
    transform: translateX(calc(var(--bui_spacing_1x)*-3*-1))
}

.c778b3e5b0:empty {
    left: auto;
    right: 0;
    top: 0;
    transform: none
}

.d51d1b34c8 .c778b3e5b0:empty,[dir=rtl] .c778b3e5b0:empty {
    left: 0;
    right: auto
}

.baf4d95135 {
    background: var(--bui_color_background_elevation_one);
    border-block-start:var(--bui_border_width_100) solid var(--bui_color_border_alt);display: flex
}

.c7d6711455 {
    align-items: center;
    color: var(--bui_color_foreground_alt);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-height: calc(var(--bui_spacing_1x)*13);
    padding: var(--bui_spacing_2x) 0;
    text-align: center;
    text-decoration: none;
    transition: var(--bui_animation_press);
    transition-property: background-color,color
}

.c7d6711455:hover {
    background-color: var(--bui_color_highlighted_alt)
}

.d10164115a {
    color: var(--bui_color_action_foreground)
}

.fd80dce11a {
    display: block;
    margin-block-start:var(--bui_spacing_1x)}

.f88a5204c2 {
    display: inline
}

.f88a5204c2,.f88a5204c2:active,.f88a5204c2:focus,.f88a5204c2:hover {
    color: var(--bui_color_action_foreground);
    text-decoration: underline
}

.f88a5204c2:active,.f88a5204c2:visited {
    color: var(--bui_color_action_foreground)
}

.d492a4b73f {
    margin-inline-end:var(--bui_spacing_2x);margin-inline-start: 0
}

.ea532e735c {
    margin-inline-end:0;margin-inline-start:var(--bui_spacing_2x)}

.b98133fb50,.fde1badfe6 {
    text-decoration: none
}

p .fde1badfe6 {
    text-decoration: underline
}

.d8ea675e30 {
    align-items: center;
    display: inline-flex
}

.fde1badfe6,.fde1badfe6:active,.fde1badfe6:focus,.fde1badfe6:hover,.fde1badfe6:visited {
    color: var(--bui_color_foreground)
}

.d208decf04,.d208decf04:active,.d208decf04:focus,.d208decf04:hover,.d208decf04:visited {
    color: var(--bui_color_foreground_disabled);
    text-decoration: none
}

.a857d92783 {
    list-style-type: none;
    margin-block-end:0;padding: 0
}

.c3ccde2c36 {
    align-items: center;
    display: inline-flex
}

.fea50ae303 {
    margin-inline-end:var(--bui_spacing_half)}

.a10b0e2d13 {
    position: relative
}

.ee7ec6b631 {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    text-align: center
}

.a10b0e2d13:not(.df51f2f03a) .ee7ec6b631 {
    line-height: calc(var(--bui_spacing_1x)*12)
}

.dc72a8413c {
    position: absolute;
    top: 0
}

.f073249358 {
    right: 0
}

.f799921d2f .f073249358,[dir=rtl] .f073249358 {
    left: 0;
    right: auto
}

.c9804790f7 {
    left: 0
}

.f799921d2f .c9804790f7,[dir=rtl] .c9804790f7 {
    left: auto;
    right: 0
}

.d4babb55ef {
    box-sizing: border-box;
    display: flex
}

.d358556c65 {
    padding-inline-start:var(--bui_spacing_4x)}

.d358556c65  first-child {
    padding-inline-start:0
}

.eb03f3f27f {
    border-spacing: 0;
    table-layout: fixed;
    width: 100%
}

.b766923492 {
    border-block-start:var(--bui_spacing_2x) solid transparent;border-spacing: 0;
    padding: var(--bui_spacing_half);
    text-align: center
}

.b80d5adb18 {
    border-block-start:var(--bui_spacing_1x) solid transparent;border-spacing: 0;
    height: calc(var(--bui_spacing_1x)*11);
    outline: none;
    padding: 0
}

.cf06f772fa {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 var(--bui_spacing_1x);
    position: relative;
    text-align: center;
    width: 100%
}

.cf06f772fa:hover:after {
    background: var(--bui_color_highlighted_alt);
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0
}

[data-bui-keyboard] .cf06f772fa:focus:not(.fa1e2a2fae) {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none;
    position: relative;
    z-index: 10
}

.a416e86ed7 {
    background: var(--bui_color_highlighted_alt)
}

.e4862a187f,.e4862a187f:focus-within,.e4862a187f:hover {
    background: #70bbe9;
    color: var(--bui_color_on_action_background)
}

.a8b15b729e,.a8b15b729e:after {
    border-bottom-left-radius: var(--bui_border_radius_100);
    border-top-left-radius: var(--bui_border_radius_100)
}

.f799921d2f .a8b15b729e,.f799921d2f .a8b15b729e:after,[dir=rtl] .a8b15b729e,[dir=rtl] .a8b15b729e:after {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.f65bd68ffb,.f65bd68ffb:after,.f799921d2f .a8b15b729e,.f799921d2f .a8b15b729e:after,[dir=rtl] .a8b15b729e,[dir=rtl] .a8b15b729e:after {
    border-bottom-right-radius: var(--bui_border_radius_100);
    border-top-right-radius: var(--bui_border_radius_100)
}

.f799921d2f .f65bd68ffb,.f799921d2f .f65bd68ffb:after,[dir=rtl] .f65bd68ffb,[dir=rtl] .f65bd68ffb:after {
    border-bottom-left-radius: var(--bui_border_radius_100);
    border-bottom-right-radius: 0;
    border-top-left-radius: var(--bui_border_radius_100);
    border-top-right-radius: 0
}

.a8b15b729e.f65bd68ffb,.a8b15b729e.f65bd68ffb:after {
    border-radius: var(--bui_border_radius_100)
}

.fa1e2a2fae,.fa1e2a2fae:focus,.fa1e2a2fae:hover {
    background: none;
    color: var(--bui_color_foreground_disabled);
    cursor: default
}

.b0e227d988 {
    max-width: 100%
}

.d806211e69 {
    margin-block-start:var(--bui_spacing_1x)}

.b290d72c9a {
    border-block-start: var(--bui_border_width_100) solid var(--bui_color_border_alt);
    margin-block-start:var(--bui_spacing_2x);padding-block-start: var(--bui_spacing_2x);
    text-align: center
}

.b290d72c9a:empty {
    display: none
}

.df51f2f03a {
    display: flex;
    flex-direction: column;
    max-height: 340px;
    overflow: hidden
}

.fb5fd21c44 {
    background: var(--bui_color_background_elevation_one);
    border-block-end:var(--bui_border_width_100) solid var(--bui_color_border_alt);flex-shrink: 0;
    padding: 0 var(--bui_spacing_4x)
}

.c31abddca0 {
    border-spacing: 0;
    table-layout: fixed;
    width: 100%
}

.df51f2f03a .b766923492 {
    border-block-start:0;padding-block-end:calc(var(--bui_spacing_2x) + var(--bui_spacing_half));padding-block-start: calc(var(--bui_spacing_2x) + var(--bui_spacing_half))
}

.df51f2f03a .d4babb55ef {
    display: block;
    flex-grow: 1;
    overflow: auto;
    padding-block-end:var(--bui_spacing_4x);padding-block-start: var(--bui_spacing_8x);
    padding-inline-end:var(--bui_spacing_4x);padding-inline-start: var(--bui_spacing_4x)
}

.df51f2f03a .d358556c65 {
    padding-block-end:0;padding-block-start:var(--bui_spacing_4x);padding-inline-end: 0;
    padding-inline-start:0}

.df51f2f03a .d358556c65:first-child {
    padding-block-start:0}

.df51f2f03a .dc72a8413c {
    display: none
}

.df51f2f03a .ee7ec6b631 {
    justify-content: flex-start;
    text-align: start
}

.ec4dd7d9ac {
    height: 100%;
    max-height: none
}

.b66a28e575,.c6e6416179 {
    display: block
}

.b66a28e575 {
    border-radius: var(--bui_border_radius_100);
    height: var(--bui_spacing_4x);
    opacity: var(--bui_skeleton_loader--opacity);
    overflow: hidden;
    position: relative
}

.b66a28e575:before {
    background-color: var(--bui_skeleton_loader--background_color);
    content: "";
    height: 100%;
    left: 0;
    opacity: var(--bui_skeleton_loader--color_opacity);
    position: absolute;
    top: 0;
    width: 100%
}

.c659dd650d .b66a28e575:before,[dir=rtl] .b66a28e575:before {
    left: auto;
    right: 0
}

.b66a28e575:after {
    animation: c019ddacb5 1.6s linear infinite;
    background-image: linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.8) 40px,hsla(0,0%,100%,0) 80px);
    content: "";
    height: 100%;
    left: -80px;
    opacity: var(--bui_skeleton_loader--color_opacity);
    position: absolute;
    top: 0;
    width: 80px
}

.c659dd650d .b66a28e575:after,[dir=rtl] .b66a28e575:after {
    background-image: linear-gradient(-90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.8) 40px,hsla(0,0%,100%,0) 80px);
    left: auto;
    right: -80px
}

.b66a28e575+.b66a28e575 {
    margin-block-start:var(--bui_spacing_2x)}

.e8d11bdbf5 {
    width: 70%
}

.a9e568d2ee {
    width: 100%
}

.cdba43297e {
    width: 50%
}

.b08f636d3e {
    display: block;
    height: 100%;
    width: 100%
}

.afb63279fb {
    --bui_skeleton_loader--background_color: var(--bui_color_background);
    --bui_skeleton_loader--opacity: 0.15;
    --bui_skeleton_loader--color_opacity: 1
}

.f58631f933 {
    --bui_skeleton_loader--background_color: currentcolor;
    --bui_skeleton_loader--opacity: 0.30;
    --bui_skeleton_loader--color_opacity: 0.50
}

@media (prefers-reduced-motion) {
    .b66a28e575:after {
        animation-duration: 1ms;
        animation-iteration-count: 1
    }
}

@keyframes c019ddacb5 {
    0% {
        left: -80px
    }

    50%,to {
        left: 100vw
    }
}

.ffae958e14 {
    box-shadow: var(--bui_shadow_100)
}

.ba4306d436 {
    -ms-overflow-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: scroll;
    overflow: -moz-scrollbars-none;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    white-space: nowrap
}

.ba4306d436::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0
}

.c89fe5969e {
    overflow-x: hidden
}

.b0932df2e7 {
    white-space: normal
}

.a27b97ef1e {
    cursor: default;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--bui_animation_duration) var(--bui_easing-subtle-in-out);
    visibility: hidden
}

.a27b97ef1e.f8a72bc8dd {
    visibility: visible
}

.a27b97ef1e.ea1fad350f {
    cursor: pointer;
    opacity: 1;
    pointer-events: all
}

.a27b97ef1e.ea1fad350f:active {
    opacity: 1
}

.e567fd4fff {
    margin-block-end:calc(var(--bui_spacing_2x)*-1);margin-block-start: calc(var(--bui_spacing_2x)*-1);
    margin-inline-end:calc(var(--bui_spacing_4x)*-1);margin-inline-start: calc(var(--bui_spacing_4x)*-1);
    position: relative
}

.bcf40034c1 {
    margin-block-end:var(--bui_spacing_4x)}

.fc49408fea {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    overflow-x: scroll;
    overflow: -moz-scrollbars-none;
    padding-block-end:var(--bui_spacing_2x);padding-block-start: var(--bui_spacing_2x);
    padding-inline-end:var(--bui_spacing_4x);padding-inline-start: var(--bui_spacing_4x);
    scroll-behavior: smooth;
    scroll-padding-left: var(--bui_spacing_4x);
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    width: 100%
}

[dir=rtl] .fc49408fea {
    scroll-padding-left: 0;
    scroll-padding-right: var(--bui_spacing_4x)
}

.fc49408fea::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0
}

.c7a2b2cb75 {
    display: none;
    pointer-events: none
}

.f7e840d934 {
    pointer-events: all
}

.e375bc2404 {
    box-sizing: border-box;
    flex: 0 0 auto;
    margin-inline-end:var(--bui_spacing_3x);scroll-snap-align: start
}

.e375bc2404:last-child {
    margin-inline-end:0}

.cfb5f903fa .e375bc2404 {
    width: 28%
}

.d70ca2af9c .e375bc2404 {
    width: 42%
}

.e64b686da7 .e375bc2404 {
    width: 64%
}

.b85ec662a7 .e375bc2404,.ddb4170071 .e375bc2404 {
    width: 89%
}

@media (min-width: 576px) {
    .cfb5f903fa .e375bc2404 {
        width:18%
    }

    .d70ca2af9c .e375bc2404 {
        width: 28%
    }

    .e64b686da7 .e375bc2404 {
        width: 40%
    }

    .b85ec662a7 .e375bc2404,.ddb4170071 .e375bc2404 {
        width: 67%
    }
}

@media (min-width: 1024px) {
    .e567fd4fff {
        margin-inline-end:calc(var(--bui_spacing_2x)*-1);
        margin-inline-start:calc(var(--bui_spacing_2x)*-1)}

    .fc49408fea {
        padding-inline-end: var(--bui_spacing_2x);
        padding-inline-start:var(--bui_spacing_2x);scroll-padding-left: var(--bui_spacing_2x)
    }

    .c7a2b2cb75 {
        display: flex;
        justify-content: space-between;
        left: calc(var(--bui_spacing_2x)*-1);
        position: absolute;
        right: calc(var(--bui_spacing_2x)*-1);
        top: 50%;
        transform: translateY(-50%);
        z-index: var(--bui_z_index_1)
    }

    .e375bc2404 {
        margin-inline-end:var(--bui_spacing_4x)}

    .e375bc2404 last-child {
        margin-inline-end:0
    }

    .cfb5f903fa .e375bc2404 {
        width: calc(16.66667% - var(--bui_spacing_4x)*5/6)
    }

    .d70ca2af9c .e375bc2404 {
        width: calc(25% - var(--bui_spacing_4x)*3/4)
    }

    .b85ec662a7 .e375bc2404,.e64b686da7 .e375bc2404 {
        width: calc(33.33333% - var(--bui_spacing_4x)*2/3)
    }

    .ddb4170071 .e375bc2404 {
        width: calc(50% - var(--bui_spacing_4x)*1/2)
    }
}

.ffb9c3d6a3 {
    box-sizing: border-box;
    padding: var(--bui_spacing_4x);
    position: relative;
    width: 1000px;
}
.ffb9c3d6a31 {
    box-sizing: border-box;
    padding: var(--bui_spacing_4x);
    
    position: relative;
    width: "100%";
}
.ffb9c3d6a32 {
    box-sizing: border-box;
    padding: var(--bui_spacing_4x);
    position: relative;
    height: 700px;
    width: 800px;

}
.ffb9c3d6a33 {
    box-sizing: border-box;
    padding: var(--bui_spacing_4x);
    position: relative;
    /* height: 700px; */
    width: 500px;

}
.dbd3b41eae {
    margin: 0 auto;
    max-width: var(--bui_container)
}

.fd0c3f4521 {
    position: relative;
    white-space: pre-line
}

.fc409351f3 {
    --bui_collapsed_text_line_percentage: calc(100%/var(--bui_collapsed_text_visible_lines));
    -webkit-line-clamp: var(--bui_collapsed_text_visible_lines);
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-mask-image: linear-gradient(to top,transparent,#000 calc(var(--bui_collapsed_text_line_percentage) + var(--bui_collapsed_text_line_percentage)/2));
    mask-image: linear-gradient(to top,transparent,#000 calc(var(--bui_collapsed_text_line_percentage) + var(--bui_collapsed_text_line_percentage)/2));
    overflow: hidden
}

.e8bb16538d {
    -webkit-line-clamp: none;
    -webkit-mask-image: none;
    mask-image: none
}

.d216d548e4 {
    display: inline-block;
    position: relative;
    text-align: center
}

.efe5b0b855 {
    margin-block-end:var(--bui_spacing_1x)}

.e529fc4b86 .b80bba4aba {
    color: var(--bui_color_action_foreground)
}

.cb3a289efa {
    text-align: start
}

.b9bfeba2b4 {
    background: var(--bui_color_border_alt);
    border: 0;
    margin: 0
}

.b288f61df6 {
    height: var(--bui_border_width_100);
    width: auto
}

.d448706938 {
    height: 100%;
    width: var(--bui_border_width_100)
}

@media (min-width: 576px) {
    .b497db0dac {
        height:var(--bui_border_width_100);
        width: auto
    }

    .f9cb073f91 {
        height: 100%;
        width: var(--bui_border_width_100)
    }
}

@media (min-width: 1024px) {
    .be1dd46d86 {
        height:var(--bui_border_width_100);
        width: auto
    }

    .f4f8c365fd {
        height: 100%;
        width: var(--bui_border_width_100)
    }
}

@media (min-width: 1280px) {
    .dc12b90551 {
        height:var(--bui_border_width_100);
        width: auto
    }

    .d79dd3a670 {
        height: 100%;
        width: var(--bui_border_width_100)
    }
}

.d63ebe78db {
    display: flex;
    flex-wrap: nowrap
}

.c4ee32b588:first-child {
    padding-inline-end:var(--bui_spacing_4x)}

.c4ee32b588 last-child {
    padding-inline-start:var(--bui_spacing_4x)
}

@media (max-width: 575px) {
    .ca7823fbd4 .c4ee32b588 {
        flex-grow:1;
        width: 50%
    }
}

.aee4999c52 {
    border-radius: 0;
    box-sizing: border-box;
    color: var(--bui_color_foreground);
    cursor: default;
    display: block;
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height);
    text-decoration: none;
    transform: none;
    width: 100%
}

.fb60b9836d {
    overflow: hidden;
    text-overflow: ellipsis
}

.ac7953442b:hover {
    background-color: var(--bui_color_highlighted_alt);
    cursor: pointer
}

.ac7953442b.abced745f1,.ac7953442b.abced745f1:focus,.ac7953442b.abced745f1:hover {
    background: var(--bui_color_action_highlighted_alt);
    color: var(--bui_color_action_foreground)
}

.ac7953442b[disabled],.ac7953442b[disabled]:hover {
    background: none;
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed
}

.c39dd9701b {
    border-radius: var(--bui_border_radius_100)
}

.ffc914f84a.ac7953442b,.ffc914f84a.fa17e8b941 {
    padding: var(--bui_spacing_3x)
}

.d4c8bef76c.ac7953442b,.d4c8bef76c.fa17e8b941 {
    padding: var(--bui_spacing_3x) var(--bui_spacing_4x)
}

.eafa40e84f.ac7953442b,.eafa40e84f.fa17e8b941 {
    padding: var(--bui_spacing_4x) var(--bui_spacing_6x)
}

.ec5d8a4630 {
    background: var(--bui_color_background_elevation_two);
    border-radius: var(--bui_border_radius_200);
    box-shadow: var(--bui_shadow_100);
    box-sizing: border-box;
    color: var(--bui_color_foreground);
    max-width: 100%;
    padding: var(--bui_spacing_4x);
    position: relative
}

.f1f06e3074 {
    padding-inline-end:calc(var(--bui_spacing_1x)*9)}

.ed9055eada {
    max-width: calc(var(--bui_spacing_1x)*60);
    min-width: calc(var(--bui_spacing_1x)*50)
}

.df939b32e1 {
    width: calc(var(--bui_spacing_1x)*90)
}

.f7367c4c81 {
    width: auto
}

.b68e3415d6 {
    width: 100%
}

.fdd69bca14 {
    display: none
}

@media (max-width: 575px) {
    .f25cad9d36 {
        display:none
    }

    .df939b32e1,.f7367c4c81 {
        max-width: 100%
    }
}

.da4aa3251d {
    padding: 0
}

.ea58e09059 {
    margin-inline-start:var(--bui_spacing_2x)}

.ad7c39949a {
    list-style-type: none;
    margin: 0;
    padding: var(--bui_spacing_1x) 0
}

.ce4e276cf4 {
    margin: var(--bui_spacing_1x) 0
}

.ca37e69864 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: calc(var(--bui_spacing_1x)*175);
    text-align: center
}

.fd02cccbe2 {
    margin-block-end:var(--bui_spacing_4x);margin-block-start: 0;
    margin-inline-end:0;margin-inline-start:0}

.bde1db3fb4,.fef819d0b3 {
    max-height: 256px
}

.bde1db3fb4 {
    margin-block-end:var(--bui_spacing_6x)}

.c2bb3a21b4 {
    align-items: center;
    display: flex;
    flex-direction: column
}

.ffeb8338c5 {
    margin-block-start:var(--bui_spacing_2x)}

@media (min-width: 576px) {
    .ce4c0a551e {
        flex-direction:row;
        text-align: start
    }

    .ce4c0a551e .e980bd9808 {
        align-items: flex-start
    }

    .ce4c0a551e .c2bb3a21b4 {
        flex-direction: row
    }

    .ce4c0a551e .ffeb8338c5 {
        margin-block-start:0;margin-inline-start:var(--bui_spacing_2x)}

    .bde1db3fb4 {
        margin-block-end: 0;
        margin-inline-end:var(--bui_spacing_6x);max-height: none;
        max-width: 256px
    }
}

.ac9267e216 {
    border: 0;
    margin: 0;
    padding: 0
}

.f555271986 {
    align-items: flex-end;
    display: flex;
    padding: 0;
    padding-block-end:var(--bui_spacing_1x)}

.b60c942c9a,.bb88063a28 {
    display: inline-block;
    margin-inline-start:var(--bui_spacing_1x)}

.f3e02dc3c5 {
    flex-grow: 1;
    text-align: end
}

.f017a108d7 {
    margin-block-start:var(--bui_spacing_1x)}

.db150fece4,.db150fece4>* {
    box-sizing: border-box;
    clear: both
}

.b232a3502b {
    width: calc(8.33333% - var(--bui_stack_gap))
}

.b1e9f8a61d {
    width: calc(16.66667% - var(--bui_stack_gap))
}

.c44c37515e {
    width: calc(25% - var(--bui_stack_gap))
}

.a7cf1a6b1d {
    width: calc(33.33333% - var(--bui_stack_gap))
}

.b8d011b59c {
    width: calc(41.66667% - var(--bui_stack_gap))
}

.b268b61489,.df1befa4db {
    width: calc(50% - var(--bui_stack_gap))
}

.e7f103ee9e {
    width: calc(58.33333% - var(--bui_stack_gap))
}

.e0ad3ea0c7 {
    width: calc(66.66667% - var(--bui_stack_gap))
}

.d6cb5ce5de {
    width: calc(75% - var(--bui_stack_gap))
}

.d4e36dbd77 {
    width: calc(83.33333% - var(--bui_stack_gap))
}

.bb4ce0a491 {
    width: calc(91.66667% - var(--bui_stack_gap))
}

.b448d446fd,.b736e9e3f4 {
    width: calc(100% - var(--bui_stack_gap))
}

@media (min-width: 576px) {
    .f5604e25b8 {
        width:calc(8.33333% - var(--bui_stack_gap))
    }

    .b22a36df4a {
        width: calc(16.66667% - var(--bui_stack_gap))
    }

    .f09a70f559 {
        width: calc(25% - var(--bui_stack_gap))
    }

    .c67cf8c745 {
        width: calc(33.33333% - var(--bui_stack_gap))
    }

    .f10bbcad92 {
        width: calc(41.66667% - var(--bui_stack_gap))
    }

    .b6bae83606,.c8d2ddc53e {
        width: calc(50% - var(--bui_stack_gap))
    }

    .f7d9f4dc5c {
        width: calc(58.33333% - var(--bui_stack_gap))
    }

    .cb6cafa5d2 {
        width: calc(66.66667% - var(--bui_stack_gap))
    }

    .a773541cf1 {
        width: calc(75% - var(--bui_stack_gap))
    }

    .a888a7e0de {
        width: calc(83.33333% - var(--bui_stack_gap))
    }

    .a8b25b1c2b {
        width: calc(91.66667% - var(--bui_stack_gap))
    }

    .cada18c7af,.dc18e1e302 {
        width: calc(100% - var(--bui_stack_gap))
    }
}

@media (min-width: 1024px) {
    .cd0db00c18 {
        width:calc(8.33333% - var(--bui_stack_gap))
    }

    .cef0b84269 {
        width: calc(16.66667% - var(--bui_stack_gap))
    }

    .da2b04b499 {
        width: calc(25% - var(--bui_stack_gap))
    }

    .d768eb1b65 {
        width: calc(33.33333% - var(--bui_stack_gap))
    }

    .f603bd2380 {
        width: calc(41.66667% - var(--bui_stack_gap))
    }

    .d5f4c96e0f,.f96eb7aedf {
        width: calc(50% - var(--bui_stack_gap))
    }

    .a04efaa663 {
        width: calc(58.33333% - var(--bui_stack_gap))
    }

    .f5a38c1d23 {
        width: calc(66.66667% - var(--bui_stack_gap))
    }

    .f1b25674ae {
        width: calc(75% - var(--bui_stack_gap))
    }

    .bf6a63ccf5 {
        width: calc(83.33333% - var(--bui_stack_gap))
    }

    .c43fe9d008 {
        width: calc(91.66667% - var(--bui_stack_gap))
    }

    .c9c114540b,.d0077a7516 {
        width: calc(100% - var(--bui_stack_gap))
    }
}

@media (min-width: 1280px) {
    .c64655ea17 {
        width:calc(8.33333% - var(--bui_stack_gap))
    }

    .c872d271f0 {
        width: calc(16.66667% - var(--bui_stack_gap))
    }

    .fe487cb6a2 {
        width: calc(25% - var(--bui_stack_gap))
    }

    .ff7dfe757e {
        width: calc(33.33333% - var(--bui_stack_gap))
    }

    .ee05c951cc {
        width: calc(41.66667% - var(--bui_stack_gap))
    }

    .aa67a6fda2,.e6325c78a2 {
        width: calc(50% - var(--bui_stack_gap))
    }

    .f144cce617 {
        width: calc(58.33333% - var(--bui_stack_gap))
    }

    .ab0e39a0ad {
        width: calc(66.66667% - var(--bui_stack_gap))
    }

    .fe9129c8d1 {
        width: calc(75% - var(--bui_stack_gap))
    }

    .e508d9db28 {
        width: calc(83.33333% - var(--bui_stack_gap))
    }

    .c959f6a1aa {
        width: calc(91.66667% - var(--bui_stack_gap))
    }

    .a28ca78e64,.dbf47b0db1 {
        width: calc(100% - var(--bui_stack_gap))
    }
}

.aaad7c6096 {
    margin-inline-start:8.3333333333%!important}

.f2b157ac01 {
    margin-inline-start:16.6666666667%!important}

.abd29e4a4a {
    margin-inline-start:25%!important}

.e949c30f2b {
    margin-inline-start:33.3333333333%!important}

.acc80c6334 {
    margin-inline-start:41.6666666667%!important}

.c13eab9c4b,.ffd92e1714 {
    margin-inline-start:50%!important}

.dcad3d6fb2 {
    margin-inline-start:58.3333333333%!important}

.e5cc1f7f5a {
    margin-inline-start:66.6666666667%!important}

.cf63da9162 {
    margin-inline-start:75%!important}

.de2134a964 {
    margin-inline-start:83.3333333333%!important}

.b6cb27d76a {
    margin-inline-start:91.6666666667%!important}

.d0469e626e,.ea8578cc94 {
    margin-inline-start:100%!important}

.bbf67f6e46 {
    margin-inline-start:auto!important}

@media (min-width: 576px) {
    .e068688730 {
        margin-inline-start:8.3333333333%!important
    }

    .e774725303 {
        margin-inline-start:16.6666666667%!important}

    .f254208005 {
        margin-inline-start:25%!important}

    .daeb8cd20b {
        margin-inline-start:33.3333333333%!important}

    .cd40267848 {
        margin-inline-start:41.6666666667%!important}

    .adc54234ac,.f176fbb967 {
        margin-inline-start:50%!important}

    .d0df1c5de9 {
        margin-inline-start:58.3333333333%!important}

    .eb82a18ac6 {
        margin-inline-start:66.6666666667%!important}

    .d46d0deafe {
        margin-inline-start:75%!important}

    .dfe3c0246f {
        margin-inline-start:83.3333333333%!important}

    .c25b20d460 {
        margin-inline-start:91.6666666667%!important}

    .e8695f782f,.f32854f5be {
        margin-inline-start:100%!important}

    .a836224a57 {
        margin-inline-start:auto!important}
}

@media (min-width: 1024px) {
    .b8544596eb {
        margin-inline-start:8.3333333333%!important
    }

    .d67230fc99 {
        margin-inline-start:16.6666666667%!important}

    .f3cde6fbcc {
        margin-inline-start:25%!important}

    .c2e3a62e78 {
        margin-inline-start:33.3333333333%!important}

    .a97d24fff8 {
        margin-inline-start:41.6666666667%!important}

    .cf534928f3,.ef5efe3777 {
        margin-inline-start:50%!important}

    .de93126064 {
        margin-inline-start:58.3333333333%!important}

    .cb41a96795 {
        margin-inline-start:66.6666666667%!important}

    .d96aa87054 {
        margin-inline-start:75%!important}

    .ca97013094 {
        margin-inline-start:83.3333333333%!important}

    .f2c2fa55c5 {
        margin-inline-start:91.6666666667%!important}

    .aadfad0507,.aeb775de70 {
        margin-inline-start:100%!important}

    .dba30710ba {
        margin-inline-start:auto!important}
}

@media (min-width: 1280px) {
    .b8974b4cae {
        margin-inline-start:8.3333333333%!important
    }

    .bfbe609700 {
        margin-inline-start:16.6666666667%!important}

    .a5c26d2ed0 {
        margin-inline-start:25%!important}

    .ed2a882376 {
        margin-inline-start:33.3333333333%!important}

    .a53d1e7762 {
        margin-inline-start:41.6666666667%!important}

    .c62f5a2284,.f481dfcd84 {
        margin-inline-start:50%!important}

    .c2d208f380 {
        margin-inline-start:58.3333333333%!important}

    .c36f3ad150 {
        margin-inline-start:66.6666666667%!important}

    .d877197bdb {
        margin-inline-start:75%!important}

    .bbca4ebf8f {
        margin-inline-start:83.3333333333%!important}

    .b60b21c64f {
        margin-inline-start:91.6666666667%!important}

    .a94d2e77ff,.d2d3a39dd8 {
        margin-inline-start:100%!important}

    .abbc5b527b {
        margin-inline-start:auto!important}
}

.fce0879e41>.b817090550 {
    display: flex;
    width: auto
}

.d62289d305>.b817090550 {
    flex: 1 1 auto;
    width: auto
}

.fa6a0de302>.b817090550 {
    flex: 1 0 0;
    width: auto
}

.ac4a7896c7 {
    clip: rect(0,0,0,0);
    border: 0;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px
}


.d8eb520c4e {
    position: relative
}

.f0b6754c38 {
    border: 0;
    height: 1px;
    left: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 1px
}

.e08c57eb9f .f0b6754c38,[dir=rtl] .f0b6754c38 {
    left: auto;
    right: 0
}

.ff5328eb35 {
    display: none
}

@media (max-width: 575px) {
    .ff5328eb35 {
        display:block;
        height: 44px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%
    }
}

.bd597ff2d8 {
    cursor: pointer
}

.dd11f9a7df {
    align-items: center;
    display: inline-flex;
    height: var(--bui_font_body_2_line-height)
}

.ef785aa7f4 {
    background-color: #fff;
    background-color: var(--bui_color_background_elevation_one);
    border: 1px solid #949494;
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    border-radius: 2px;
    border-radius: var(--bui_border_radius_100);
    box-sizing: border-box;
    color: #fff;
    color: var(--bui_color_on_action_background);
    flex-shrink: 0;
    height: 20px;
    height: calc(var(--bui_spacing_1x)*5);
    position: relative;
    transition: .12s cubic-bezier(.2,0,.4,.8);
    transition: var(--bui_animation_press);
    transition-property: background-color,border-color;
    width: 20px;
    width: calc(var(--bui_spacing_1x)*5);
}

.b27b51da7f {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: opacity var(--bui_animation_press)
}

.e08c57eb9f .b27b51da7f,[dir=rtl] .b27b51da7f {
    left: auto;
    right: 50%;
    transform: translate(50%,-50%)
}

.d67840f13c {
    margin-block-start:var(--bui_spacing_1x)}

.cbee26a687 .ef785aa7f4 {
    border-color: var(--bui_color_destructive_border);
    border-width: var(--bui_border_width_200)
}

.f0b6754c38:indeterminate~.bd597ff2d8 .ef785aa7f4 {
    background-color: #70bbe9;
    border-color: #70bbe9
}

.f0b6754c38:indeterminate~.bd597ff2d8 .ef785aa7f4:before {
    background: var(--bui_color_on_action_background);
    border-radius: 1px;
    content: "";
    height: var(--bui_spacing_half);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: var(--bui_spacing_3x)
}

.e08c57eb9f .f0b6754c38:indeterminate~.bd597ff2d8 .ef785aa7f4:before,[dir=rtl] .f0b6754c38:indeterminate~.bd597ff2d8 .ef785aa7f4:before {
    left: auto;
    right: 50%;
    transform: translate(50%,-50%)
}

.f0b6754c38:checked:focus~.bd597ff2d8 .ef785aa7f4,.f0b6754c38:checked~.bd597ff2d8 .ef785aa7f4 {
    background: #70bbe9;
    border-color: #70bbe9
}

.f0b6754c38:checked~.bd597ff2d8 .b27b51da7f {
    opacity: 1
}

.f0b6754c38:disabled~.bd597ff2d8 .ef785aa7f4 {
    background-color: var(--bui_color_background_disabled_alt);
    border-color: var(--bui_color_border_disabled);
    border-width: var(--bui_border_width_100)
}

.f0b6754c38:disabled:checked~.bd597ff2d8 .ef785aa7f4,.f0b6754c38:disabled:indeterminate~.ef785aa7f4 {
    background-color: var(--bui_color_background_disabled);
    border-color: var(--bui_color_background_disabled)
}

.f0b6754c38:disabled~.bd597ff2d8 {
    cursor: not-allowed
}

[data-bui-keyboard] .f0b6754c38:focus~.bd597ff2d8 .ef785aa7f4 {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.ce8bf957e9 {
    display: block;
    transition: var(--bui_animation_press);
    transition-property: box-shadow
}

.ce8bf957e9:hover {
    cursor: pointer
}

.b14297444f {
    box-shadow: 0 0 0 var(--bui_border_width_200) #70bbe9
}

.b14297444f,.e6ba106e58 {
    border-color: transparent
}

.e6ba106e58 {
    box-shadow: 0 0 0 var(--bui_border_width_200) var(--bui_color_destructive_border)
}

.d4212305d4 {
    background-color: var(--bui_color_background_disabled_alt);
    border-color: var(--bui_color_border_disabled);
    box-shadow: none
}

.d4212305d4:hover {
    cursor: not-allowed
}

.c9557ffbfa.b14297444f {
    box-shadow: var(--bui_shadow_100),0 0 0 var(--bui_border_width_200) #70bbe9
}

.c9557ffbfa.e6ba106e58 {
    box-shadow: var(--bui_shadow_100),0 0 0 var(--bui_border_width_200) var(--bui_color_destructive_border)
}

.c9557ffbfa.d4212305d4 {
    box-shadow: var(--bui_shadow_100)
}

.faefc93c6f {
    display: inline-block;
    position: relative;
    transition: var(--bui_animation_press);
    transition-property: transform;
    vertical-align: top
}

.faefc93c6f:active {
    transform: scale(.96)
}

.b94d37c0c4,.dfb0833038 {
    align-items: center;
    background: var(--bui_color_background_elevation_one);
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    color: var(--bui_color_foreground);
    cursor: pointer;
    display: inline-flex;
    padding: calc(var(--bui_spacing_2x) - var(--bui_border_width_100)) calc(var(--bui_spacing_3x) - var(--bui_border_width_100));
    transition: var(--bui_animation_press);
    transition-property: border-color,color;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.b94d37c0c4:before,.dfb0833038:before {
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color var(--bui_animation_press)
}

.b94d37c0c4,.b94d37c0c4:before,.dfb0833038,.dfb0833038:before {
    border-radius: 999px
}

.b94d37c0c4:hover:before,.dfb0833038:hover:before {
    background-color: var(--bui_color_highlighted_alt)
}

.a07dcf7ef3 {
    margin-inline-end:var(--bui_spacing_2x)}

.d9c719e372 {
    margin-inline-start: var(--bui_spacing_2x)
}

.d373b06207 {
    margin-inline-start:var(--bui_spacing_1x)}

.d9229705b4 {
    margin-inline-start: var(--bui_spacing_2x)
}

.b94d37c0c4.bea2b87644,.b94d37c0c4.bea2b87644.b94d37c0c4.afda024858,.b94d37c0c4.bea2b87644.b94d37c0c4.afda024858:hover,.b94d37c0c4.bea2b87644:hover,.bea2b87644 .dfb0833038,.bea2b87644 .dfb0833038:hover,.bea2b87644.afda024858 .dfb0833038,.bea2b87644.afda024858 .dfb0833038:hover {
    background: var(--bui_color_background_elevation_two);
    border-color: transparent;
    box-shadow: var(--bui_shadow_100)
}

.b94d37c0c4.c737ebb2b7,.c737ebb2b7 .dfb0833038,.c737ebb2b7 .dfb0833038:hover {
    border-color: #70bbe9;
    color: var(--bui_color_action_foreground)
}

.b94d37c0c4.c737ebb2b7:before,.b94d37c0c4.c737ebb2b7:hover:before,.c737ebb2b7 .dfb0833038:before,.c737ebb2b7 .dfb0833038:hover:before {
    background-color: var(--bui_color_action_highlighted_alt)
}

.afda024858 .dfb0833038,.afda024858 .dfb0833038:hover,.b94d37c0c4.afda024858,.b94d37c0c4.afda024858:hover {
    background-color: var(--bui_color_background_disabled_alt);
    border-color: var(--bui_color_border_disabled);
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed
}

.afda024858 .dfb0833038:before,.afda024858 .dfb0833038:hover:before,.b94d37c0c4.afda024858:before,.b94d37c0c4.afda024858:hover:before {
    background: transparent
}

[data-bui-keyboard] .bdfe71a871:focus~.dfb0833038 {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}
.dfb08330381 {
    /* box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9; */
    outline: none;
    border-radius: 999px;
    align-items: center;
    background: var(--bui_color_background_elevation_one);
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    color: var(--bui_color_foreground);
    cursor: pointer;
    display: inline-flex;
    padding: calc(var(--bui_spacing_2x) - var(--bui_border_width_100)) calc(var(--bui_spacing_3x) - var(--bui_border_width_100));
    transition: var(--bui_animation_press);
    transition-property: border-color,color;
    
    
}
.cabad3c686 {
    position: relative;
    z-index: 0
}

.ebf4591c8e {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: var(--bui_color_background_elevation_one);
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    border-radius: var(--bui_border_radius_100);
    color: var(--bui_color_foreground);
    cursor: pointer;
    display: block;
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height);
    line-height: calc(var(--bui_spacing_1x)*7)!important;
    min-height: calc(var(--bui_spacing_2x)*6);
    outline: none;
    padding: calc(var(--bui_spacing_1x) - var(--bui_border_width_100));
    text-align: start;
    text-overflow: ellipsis;
    width: 100%;
    z-index: var(--bui_z_index_1)
}

.ebf4591c8e::-ms-expand {
    display: none
}

[data-bui-keyboard] .ebf4591c8e:focus {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.d4e187567c {
    fill: var(--bui_color_foreground);
    vertical-align: middle
}

.ad2c299542,.d4e187567c {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.ad2c299542 {
    align-items: center;
    display: flex;
    z-index: var(--bui_z_index_1)
}

.acd3c95ef0 .ebf4591c8e {
    border-color: transparent
}

.cab1c196b5 {
    margin-inline-start:var(--bui_spacing_2x)}

.aff44befa2 .ebf4591c8e {
    padding-inline-end: var(--bui_spacing_8x);
    padding-inline-start:var(--bui_spacing_2x)}

.aff44befa2 .d4e187567c {
    left: var(--bui_spacing_2x)
}

.d66d60916a .aff44befa2 .d4e187567c,[dir=rtl] .aff44befa2 .d4e187567c {
    left: auto;
    right: var(--bui_spacing_2x)
}

[dir=ltr][dir=ltr] .aff44befa2 .d4e187567c {
    left: var(--bui_spacing_2x);
    right: auto
}

.aff44befa2 .ad2c299542 {
    right: var(--bui_spacing_2x)
}

.d66d60916a .aff44befa2 .ad2c299542,[dir=rtl] .aff44befa2 .ad2c299542 {
    left: var(--bui_spacing_2x);
    right: auto
}

[dir=ltr][dir=ltr] .aff44befa2 .ad2c299542 {
    left: auto;
    right: var(--bui_spacing_2x)
}

.f0d0152965 .ebf4591c8e {
    line-height: calc(var(--bui_spacing_1x)*10)!important;
    min-height: calc(var(--bui_spacing_1x)*12);
    padding-inline-end:var(--bui_spacing_8x);padding-inline-start: var(--bui_spacing_3x)
}

.f0d0152965 .d4e187567c {
    left: var(--bui_spacing_3x)
}

.d66d60916a .f0d0152965 .d4e187567c,[dir=rtl] .f0d0152965 .d4e187567c {
    left: auto;
    right: var(--bui_spacing_3x)
}

[dir=ltr][dir=ltr] .f0d0152965 .d4e187567c {
    left: var(--bui_spacing_3x);
    right: auto
}

.f0d0152965 .ad2c299542 {
    right: var(--bui_spacing_3x)
}

.d66d60916a .f0d0152965 .ad2c299542,[dir=rtl] .f0d0152965 .ad2c299542 {
    left: var(--bui_spacing_3x);
    right: auto
}

[dir=ltr][dir=ltr] .f0d0152965 .ad2c299542 {
    left: auto;
    right: var(--bui_spacing_3x)
}

.d5442d4693 .cab1c196b5 {
    fill: var(--bui_color_foreground_disabled)
}

.d5442d4693 .ebf4591c8e,.ebf4591c8e[disabled] {
    background-color: var(--bui_color_background_disabled_alt);
    border-color: var(--bui_color_border_disabled);
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed
}

.acd3c95ef0.d5442d4693 .ebf4591c8e,.acd3c95ef0.ebf4591c8e[disabled] {
    border-color: transparent
}

.d5442d4693 .d4e187567c {
    fill: var(--bui_color_foreground_disabled)
}

.d73c2251a9 .ebf4591c8e {
    color: var(--bui_color_foreground_alt)
}

.b384726af0 .ebf4591c8e {
    border-color: var(--bui_color_destructive_border)
}

.aff44befa2.ad6e555548 .ebf4591c8e {
    padding-inline-start:var(--bui_spacing_8x)}

.f0d0152965.ad6e555548 .ebf4591c8e {
    padding-inline-start: calc(var(--bui_spacing_8x) + var(--bui_spacing_1x))
}

@media (min-width: 576px) {
    .ebf4591c8e {
        font-family:var(--bui_font_body_2_font-family);
        font-size: var(--bui_font_body_2_font-size);
        font-weight: var(--bui_font_body_2_font-weight);
        line-height: var(--bui_font_body_2_line-height)
    }
}

.eccc1f293d {
    max-height: calc(var(--bui_spacing_1x)*50);
    overflow: auto;
    padding: var(--bui_spacing_4x)
}

.b9b84f4305 {
    display: flex;
    isolation: isolate;
    position: relative
}

.e000754250 {
    align-items: center;
    display: flex;
    flex-grow: 1;
    width: 100%
}

.b93ef00bf5 {
    color: var(--bui_color_foreground_alt);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 var(--bui_spacing_2x);
    position: relative;
    text-align: center;
    white-space: nowrap;
    z-index: 1
}

.b93ef00bf5:after {
    background: var(--bui_color_border_alt);
    bottom: var(--bui_spacing_2x);
    content: "";
    position: absolute;
    top: var(--bui_spacing_2x);
    width: var(--bui_border_width_100)
}

.b93ef00bf5:first-child:after {
    right: 0
}

.c1a15c92af .b93ef00bf5:first-child:after,[dir=rtl] .b93ef00bf5:first-child:after {
    left: 0;
    right: auto
}

[dir=ltr][dir=ltr] .b93ef00bf5:first-child:after {
    left: auto;
    right: 0
}

.b93ef00bf5:last-child:after {
    left: 0
}

.c1a15c92af .b93ef00bf5:last-child:after,[dir=rtl] .b93ef00bf5:last-child:after {
    left: auto;
    right: 0
}

[dir=ltr][dir=ltr] .b93ef00bf5:last-child:after {
    left: 0;
    right: auto
}

.e7e5251f68 {
    background: var(--bui_color_background_elevation_one);
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    border-radius: var(--bui_border_radius_100);
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.eb46370fe1 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    border-radius: var(--bui_border_radius_100);
    box-sizing: border-box;
    display: block;
    flex-grow: 1;
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    height: calc(var(--bui_spacing_1x)*9);
    line-height: var(--bui_font_body_1_line-height);
    margin: 0;
    outline: none;
    padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
    position: relative;
    width: 100%;
    z-index: 5
}

.eb46370fe1::-ms-reveal {
    display: none
}

.eb46370fe1:-webkit-autofill {
    -webkit-text-fill-color: var(--bui_color_foreground);
    -webkit-background-clip: text
}

.eac0b6e5ba {
    display: flex;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    z-index: 5
}

.eac0b6e5ba:empty {
    display: none
}

.eac0b6e5ba:first-child {
    padding-inline-start:var(--bui_spacing_2x)}

.eac0b6e5ba last-child {
    margin-inline-start:var(--bui_spacing_1x);
    padding-inline-end:var(--bui_spacing_2x)}

[data-bui-keyboard] .eb46370fe1 focus+.e7e5251f68 {
    box-shadow:0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

body:not([data-bui-keyboard]) .a20293ec70:not(.d974f7747b) .eb46370fe1:focus+.e7e5251f68 {
    border-color: #70bbe9;
    border-width: var(--bui_border_width_200)
}

.eb46370fe1:disabled::-moz-placeholder {
    color: var(--bui_color_foreground_disabled)
}

.eb46370fe1:disabled:-ms-input-placeholder {
    color: var(--bui_color_foreground_disabled)
}

.eb46370fe1:disabled::placeholder {
    color: var(--bui_color_foreground_disabled)
}

.eb46370fe1:disabled {
    -webkit-text-fill-color: var(--bui_color_foreground_disabled);
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed
}

.eb46370fe1:-webkit-autofill:disabled {
    -webkit-text-fill-color: var(--bui_color_foreground_disabled)
}

.eb46370fe1:disabled+.e7e5251f68 {
    background-color: var(--bui_color_background_disabled_alt);
    border-color: var(--bui_color_border_disabled);
    cursor: not-allowed
}

.a9b24fcd8e .b93ef00bf5 {
    padding: 0 var(--bui_spacing_3x)
}

.a9b24fcd8e .eb46370fe1 {
    height: calc(var(--bui_spacing_1x)*12);
    padding: calc(var(--bui_spacing_2x) + var(--bui_spacing_half)) var(--bui_spacing_3x)
}

.a9b24fcd8e .eac0b6e5ba:first-child {
    padding-inline-start:var(--bui_spacing_3x)}

.a9b24fcd8e .eac0b6e5ba last-child {
    margin-inline-start:var(--bui_spacing_2x);
    padding-inline-end:var(--bui_spacing_3x)}

.a9b24fcd8e .b93ef00bf5 after {
    bottom:var(--bui_spacing_3x);
    top: var(--bui_spacing_3x)
}

.b68d24fd9f .e7e5251f68,.d974f7747b.b68d24fd9f .e7e5251f68 {
    border-color: var(--bui_color_destructive_border)
}

.dfb8c7097e .b93ef00bf5 {
    color: var(--bui_color_foreground_disabled)
}

.d974f7747b .e7e5251f68,.d974f7747b .eb46370fe1:disabled+.e7e5251f68 {
    border-color: transparent
}

@media (min-width: 576px) {
    .eb46370fe1 {
        font-family:var(--bui_font_body_2_font-family);
        font-size: var(--bui_font_body_2_font-size);
        font-weight: var(--bui_font_body_2_font-weight);
        line-height: var(--bui_font_body_2_line-height)
    }
}

.ad8af0911d {
    position: relative
}

.f5bf4afa62 {
    border: 0;
    height: 1px;
    left: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 1px
}

.e46f39b188 .f5bf4afa62,[dir=rtl] .f5bf4afa62 {
    left: auto;
    right: 0
}

.ada5be5921 {
    display: none
}

@media (max-width: 575px) {
    .ada5be5921 {
        display:block;
        height: 44px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%
    }
}

.f8208e9f83 {
    cursor: pointer
}

.b089c040c2 {
    align-items: center;
    display: inline-flex;
    height: var(--bui_font_body_2_line-height)
}

.c907c67d20 {
    background-color: var(--bui_color_background_elevation_one);
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    border-radius: 50%;
    box-sizing: border-box;
    height: calc(var(--bui_spacing_1x)*5);
    position: relative;
    transition: var(--bui_animation_press);
    transition-property: background-color,border-color;
    width: calc(var(--bui_spacing_1x)*5)
}

.c907c67d20:before {
    background: var(--bui_color_on_action_background);
    border-radius: 50%;
    content: "";
    height: calc(var(--bui_spacing_2x) + var(--bui_spacing_half));
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: opacity var(--bui_animation_press);
    width: calc(var(--bui_spacing_2x) + var(--bui_spacing_half))
}

.e46f39b188 .c907c67d20:before,[dir=rtl] .c907c67d20:before {
    left: auto;
    right: 50%;
    transform: translate(50%,-50%)
}

.f5bf4afa62:checked:focus~.f8208e9f83 .c907c67d20,.f5bf4afa62:checked~.f8208e9f83 .c907c67d20 {
    border-color: #70bbe9;
    border-width: var(--bui_border_width_200)
}

.f5bf4afa62:checked~.f8208e9f83 .c907c67d20:before {
    background-color: var(--bui_color_action_foreground);
    opacity: 1
}

.b06ba88615 .c907c67d20 {
    border-color: var(--bui_color_destructive_border);
    border-width: var(--bui_border_width_200)
}

.f5bf4afa62:disabled~.f8208e9f83 {
    cursor: not-allowed
}

.f5bf4afa62:disabled~.f8208e9f83 .c907c67d20 {
    background-color: var(--bui_color_background_disabled_alt);
    border-color: var(--bui_color_border_disabled);
    border-width: var(--bui_border_width_100)
}

.f5bf4afa62:disabled~.f8208e9f83 .c907c67d20:before {
    background-color: var(--bui_color_border_disabled)
}

[data-bui-keyboard] .f5bf4afa62:focus~.f8208e9f83 .c907c67d20 {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.aabf012b69 {
    border: 0;
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px
}

.f3c828a390 {
    border-radius: var(--bui_border_radius_100);
    cursor: grab;
    padding: var(--bui_spacing_4x) 0;
    position: relative;
    width: 100%
}

.f3c828a390:before {
    background-color: var(--bui_color_border_alt);
    content: "";
    width: 100%
}

.d819a91462 {
    background-color: #70bbe9;
    left: 0;
    right: 0;
    z-index: 1
}

.d819a91462,.f3c828a390:before {
    border-radius: var(--bui_border_radius_100);
    height: var(--bui_spacing_1x);
    margin-block-start:calc(var(--bui_spacing_half)*-1);position: absolute
}

.b18036920b {
    height: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    z-index: 2
}

.b18036920b:after,.b18036920b:before {
    border-radius: 50%;
    content: "";
    display: block;
    height: var(--bui_spacing_4x);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    transform-origin: 50% 50%;
    transition: var(--bui_animation_hover);
    transition-property: transform,box-shadow;
    width: var(--bui_spacing_4x)
}

.ecd6724db3 .b18036920b:after,.ecd6724db3 .b18036920b:before,[dir=rtl] .b18036920b:after,[dir=rtl] .b18036920b:before {
    left: auto;
    right: 50%;
    transform: translate(50%,-50%)
}

.b18036920b:before {
    background-color: #70bbe9;
    outline: none;
    z-index: 1
}

.b18036920b:after {
    box-shadow: 0 0 0 var(--bui_spacing_3x) #70bbe9;
    opacity: .24;
    transform: translate(-50%,-50%) scale(0)
}

.ecd6724db3 .b18036920b:after,[dir=rtl] .b18036920b:after {
    transform: translate(50%,-50%) scale(0)
}

.eec336755c {
    z-index: 3
}

.eec336755c:before {
    background-color: var(--bui_color_action_highlighted);
    transform: translate(-50%,-50%) scale(1.25)
}

.ecd6724db3 .eec336755c:before,[dir=rtl] .eec336755c:before {
    transform: translate(50%,-50%) scale(1.25)
}

.eec336755c:after {
    transform: translate(-50%,-50%) scale(1)
}

.ecd6724db3 .eec336755c:after,[dir=rtl] .eec336755c:after {
    transform: translate(50%,-50%) scale(1)
}

.aabf012b69:focus+.b18036920b:before {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.aabf012b69:focus+.b18036920b:after {
    content: none
}

.c90994f8d6 {
    font-variant-numeric: tabular-nums;
    line-height: 20px
}

.f08445d9cd {
    bottom: 100%;
    left: 50%;
    opacity: 0;
    padding-block-end:calc(var(--bui_spacing_1x)*10);position: absolute;
    transform: translate(-50%,calc(var(--bui_spacing_1x)*5));
    visibility: hidden
}

.ecd6724db3 .f08445d9cd,[dir=rtl] .f08445d9cd {
    left: auto;
    right: 50%;
    transform: translate(50%,calc(var(--bui_spacing_1x)*5))
}

.cbdb519e34 .e034756178 {
    opacity: 0
}

.e034756178 {
    background: var(--bui_color_background_inverted);
    border-radius: var(--bui_border_radius_100);
    box-shadow: var(--bui_shadow_100);
    color: var(--bui_color_foreground_inverted);
    display: inline-block;
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-variant-numeric: tabular-nums;
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height);
    max-width: calc(var(--bui_spacing_8x)*10);
    padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    z-index: var(--bui_z_index_1)
}

.f08445d9cd:before {
    border: calc(var(--bui_spacing_3x)/2) solid var(--bui_color_background_inverted);
    border-left-color: transparent;
    border-top-color: transparent;
    bottom: calc(var(--bui_spacing_1x)*9);
    content: "";
    height: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    transform: rotate(45deg);
    width: 0
}

.ecd6724db3 .f08445d9cd:before,[dir=rtl] .f08445d9cd:before {
    border-left-color: currentcolor;
    border-right-color: transparent;
    transform: rotate(-45deg)
}

.a748c6d844 .f08445d9cd,.aabf012b69:focus+.b18036920b .f08445d9cd,.b18036920b:hover .f08445d9cd {
    display: inherit;
    opacity: 1;
    visibility: visible
}

.aabf012b69[disabled]~.d819a91462 {
    background-color: var(--bui_color_foreground_disabled_alt)
}

.aabf012b69[disabled]~.b18036920b,.aabf012b69[disabled]~.b18036920b:active {
    background-color: var(--bui_color_foreground_disabled_alt);
    cursor: not-allowed
}

.aabf012b69[disabled]~.b18036920b:hover .f08445d9cd {
    display: none
}

.aad03c608c .f3c828a390 {
    margin-block-start:calc(var(--bui_spacing_1x)*7)}

.a7a72174b8 {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.ebb9f563b4 {
    height: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 0
}

.ebb9f563b4:focus~.bfb38641b0 {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.e91c91fa93,.f4d78af12a {
    height: calc(var(--bui_spacing_8x) + var(--bui_spacing_2x));
    margin-inline-end:0;min-width: auto;
    width: calc(var(--bui_spacing_8x) + var(--bui_spacing_2x))
}

.bfb38641b0 {
    align-items: center;
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    border-radius: var(--bui_border_radius_100);
    display: flex
}

.d723d73d5f {
    display: block;
    font-family: var(--bui_font_emphasized_2_font-family);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height);
    margin: 0 var(--bui_spacing_1x);
    min-width: calc(var(--bui_spacing_8x) + var(--bui_spacing_1x));
    text-align: center
}

.f4878764f1 {
    margin-inline-end:var(--bui_spacing_2x)}

.a984a491d9 {
    font-family: var(--bui_font_emphasized_2_font-family);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height)
}

.ebb9f563b4[disabled]~.bfb38641b0 {
    background-color: var(--bui_color_background_disabled_alt);
    border: var(--bui_border_width_100) solid var(--bui_color_border_disabled)
}

.ebb9f563b4[disabled]~.f4878764f1 .a984a491d9,.ebb9f563b4[disabled]~.f4878764f1 .b76b1e28fc {
    color: var(--bui_color_foreground_disabled)
}

.ebb9f563b4[disabled]~.bfb38641b0 .d723d73d5f {
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed
}

.d87fd4472a {
    --bui-input-switch-min-tabbable-area: 48px;
    display: flex;
    position: relative;
    z-index: 0
}

.c640a7fa88 {
    margin-inline-start:var(--bui_spacing_3x)}

.f43c0d9153 {
    border: 0;
    height: 1px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    width: 1px
}

.dbe832aef0 {
    align-items: center;
    display: inline-flex;
    position: relative;
    vertical-align: top
}

.f9bbc6d198 {
    display: none
}

@media (hover: none) and (pointer:coarse) {
    .f9bbc6d198 {
        display:inline-block;
        height: max(var(--bui-input-switch-min-tabbable-area),100%);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        width: max(var(--bui-input-switch-min-tabbable-area),100%)
    }

    .acab122e02 .f9bbc6d198,[dir=rtl] .f9bbc6d198 {
        left: auto;
        right: 50%;
        transform: translate(50%,-50%)
    }
}

.ade77cee67 {
    background: var(--bui_color_border);
    border-radius: 99px;
    cursor: pointer;
    height: calc(var(--bui_spacing_1x)*5);
    min-width: calc(var(--bui_spacing_1x)*9);
    position: relative;
    transition: background var(--bui_animation_press);
    width: calc(var(--bui_spacing_1x)*9)
}

.ade77cee67:before {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: var(--bui_shadow_100);
    content: "";
    display: block;
    height: calc(var(--bui_spacing_3x) + var(--bui_spacing_half));
    left: calc(var(--bui_spacing_1x)*.75);
    position: absolute;
    top: calc(var(--bui_spacing_1x)*.75);
    transition: var(--bui_animation_press);
    transition-property: background,transform;
    width: calc(var(--bui_spacing_3x) + var(--bui_spacing_half));
    z-index: var(--bui_z_index_1)
}

.acab122e02 .ade77cee67:before,[dir=rtl] .ade77cee67:before {
    left: auto;
    right: calc(var(--bui_spacing_1x)*.75)
}

.f43c0d9153:checked+.dbe832aef0 .ade77cee67:before {
    background-color: #fff;
    transform: translateX(var(--bui_spacing_4x))
}

.acab122e02 .f43c0d9153:checked+.dbe832aef0 .ade77cee67:before,[dir=rtl] .f43c0d9153:checked+.dbe832aef0 .ade77cee67:before {
    transform: translateX(calc(var(--bui_spacing_4x)*-1))
}

.f43c0d9153:checked+.dbe832aef0 .ade77cee67 {
    background-color: #70bbe9
}

[data-bui-keyboard] .f43c0d9153:focus+.dbe832aef0 .ade77cee67 {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.f43c0d9153[disabled]+.dbe832aef0 {
    cursor: not-allowed;
    opacity: .5
}

.f43c0d9153[disabled]+.dbe832aef0 .ade77cee67 {
    cursor: inherit
}

.f43c0d9153[disabled]+.dbe832aef0 .c640a7fa88 {
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed
}

.ff72e81a8b .dbe832aef0 {
    flex-direction: row-reverse
}

.ff72e81a8b .c640a7fa88 {
    margin-inline-end:var(--bui_spacing_3x);margin-inline-start: 0
}

.a13c861d3c {
    isolation: isolate;
    position: relative
}

.cbe63b2c88 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    box-sizing: border-box;
    color: var(--bui_color_foreground);
    display: block;
    font-family: var(--bui_font_body_1_font-family);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height);
    max-width: 100%;
    min-width: 100%;
    outline: none;
    padding: var(--bui_spacing_2x);
    position: relative;
    text-align: start;
    width: 100%;
    z-index: 5
}

.eaf2c4abaf {
    background: var(--bui_color_background_elevation_one);
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    border-radius: var(--bui_border_radius_100);
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0
}

[data-bui-keyboard] .cbe63b2c88:focus+.eaf2c4abaf {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

body:not([data-bui-keyboard]) .cbe63b2c88:focus+.eaf2c4abaf {
    border-color: #70bbe9;
    border-width: var(--bui_border_width_200)
}

.f970a39033 .cbe63b2c88 {
    padding: calc(var(--bui_spacing_2x) + var(--bui_spacing_half)) var(--bui_spacing_3x)
}

.cbe63b2c88[disabled] {
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed
}

.cbe63b2c88[disabled]+.eaf2c4abaf {
    background-color: var(--bui_color_background_disabled_alt);
    border-color: var(--bui_color_border_disabled)
}

.cbe63b2c88::-moz-placeholder {
    color: var(--bui_color_foreground_alt)
}

.cbe63b2c88:-ms-input-placeholder {
    color: var(--bui_color_foreground_alt)
}

.cbe63b2c88::placeholder {
    color: var(--bui_color_foreground_alt)
}

.cbe63b2c88[disabled]::-moz-placeholder {
    color: var(--bui_color_foreground_disabled)
}

.cbe63b2c88[disabled]:-ms-input-placeholder {
    color: var(--bui_color_foreground_disabled)
}

.cbe63b2c88[disabled]::placeholder {
    color: var(--bui_color_foreground_disabled)
}

.fe8708915e.db406f5965 .cbe63b2c88+.eaf2c4abaf {
    border-color: var(--bui_color_destructive_border)
}

@media (min-width: 576px) {
    .cbe63b2c88 {
        font-family:var(--bui_font_body_2_font-family);
        font-size: var(--bui_font_body_2_font-size);
        font-weight: var(--bui_font_body_2_font-weight);
        line-height: var(--bui_font_body_2_line-height)
    }
}

.c807d72881 {
    margin-block-end:var(--bui_spacing_4x);margin-block-start: 0;
    margin-inline-end:0;margin-inline-start:0;padding-inline-end:var(--bui_spacing_4x)}

.c807d72881 last-child {
    margin-block-end:0
}

.e6a35f2092 {
    margin-block-end:var(--bui_spacing_1x)}

.c7a5a1307a {
    margin-block-end: var(--bui_spacing_2x)
}

.fd727d5f06 {
    margin-block-end:var(--bui_spacing_3x)}

.a8b57ad3ff last-child,.fb0e52b99b {
    margin-block-end:0
}

.ed10fb328d {
    list-style-type: disc
}

.c566662fcc {
    list-style-type: decimal
}

.bb607e913f {
    list-style-type: upper-alpha
}

.bb607e913f .a8b57ad3ff,.c566662fcc .a8b57ad3ff,.ed10fb328d .a8b57ad3ff {
    display: list-item
}

.e10711a42e {
    list-style-type: none;
    padding-inline-start:0}

.aaa34d1d9d>.a8b57ad3ff {
    border-block-end:var(--bui_border_width_100) solid var(--bui_color_border_alt);margin: 0;
    padding: var(--bui_spacing_4x) 0
}

.aaa34d1d9d>.a8b57ad3ff:first-child {
    padding-block-start:0}

.aaa34d1d9d>.a8b57ad3ff:last-child {
    border-block-end:0;padding-block-end:0}

.ea94fb98d4 {
    margin-inline-end:var(--bui_spacing_4x)}

.f2876b84ec {
    flex: 1
}

.ff372597a4 {
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin: 0
}

.d5651298c4 {
    margin-inline-start:var(--bui_spacing_4x)}

.d50c412d31 {
    align-items: center;
    display: flex
}

.fb9a5438f9 {
    display: block
}

@media (max-width: 575px) {
    .eaa02c469d,.ff372597a4 {
        align-items:flex-start
    }

    .ff372597a4 {
        flex-direction: column
    }

    .d5651298c4 {
        margin-block-start:var(--bui_spacing_2x);margin-inline-start: 0
    }
}

.c251698e74 {
    line-height: 0
}

.a9883fe675 {
    display: inline-flex;
    position: relative;
    transition: transform var(--bui_animation_press)
}

.a9883fe675:after,.a9883fe675:before {
    background: var(--bui_color_border);
    border-radius: 50%;
    content: "";
    height: var(--bui_spacing_2x);
    left: calc(var(--bui_spacing_4x)*-1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transition: var(--bui_animation_press);
    transition-property: transform,opacity,color;
    width: var(--bui_spacing_2x)
}

.a266efba5a .a9883fe675:after,.a266efba5a .a9883fe675:before,[dir=rtl] .a9883fe675:after,[dir=rtl] .a9883fe675:before {
    left: auto;
    right: calc(var(--bui_spacing_4x)*-1)
}

.db7bf77dce {
    border-radius: 50%;
    height: var(--bui_spacing_2x);
    margin-inline-start:var(--bui_spacing_2x);transition: var(--bui_animation_press);
    transition-property: transform,opacity,color;
    width: var(--bui_spacing_2x)
}

.db7bf77dce:first-child {
    margin-inline-start:0}

.ae649ec8cf {
    transform: scale(1.25)
}

.a4bd25bd3c {
    transform: scale(.75)
}

.b784fe69b1 {
    transform: scale(.5)
}

.fb661b6015 {
    transform: scale(0)
}

.c4d8777f47 .db7bf77dce {
    background: var(--bui_color_border)
}

.c4d8777f47 .ae649ec8cf {
    background: #70bbe9
}

.d9df9f5a0c .db7bf77dce {
    background: #fff;
    opacity: .5
}

.d9df9f5a0c .ae649ec8cf {
    opacity: 1
}

.e7d4a4f05b .a9883fe675 {
    transform: translateX(var(--bui_spacing_4x))
}

.aaf318bef7 .a9883fe675 {
    transform: translateX(calc(var(--bui_spacing_4x)*-1))
}

.a266efba5a .aaf318bef7 .a9883fe675,[dir=rtl] .aaf318bef7 .a9883fe675 {
    transform: translateX(calc(var(--bui_spacing_4x)*-1*-1))
}

.aaf318bef7 .a9883fe675:after,.e7d4a4f05b .a9883fe675:before {
    transform: translateY(-50%) scale(.5)
}

.e7d4a4f05b .a9883fe675:before {
    left: calc(var(--bui_spacing_4x)*-1);
    right: auto
}

.a266efba5a .e7d4a4f05b .a9883fe675:before,.aaf318bef7 .a9883fe675:after,[dir=rtl] .e7d4a4f05b .a9883fe675:before {
    left: auto;
    right: calc(var(--bui_spacing_4x)*-1)
}

.a266efba5a .aaf318bef7 .a9883fe675:after,[dir=rtl] .aaf318bef7 .a9883fe675:after {
    left: calc(var(--bui_spacing_4x)*-1);
    right: auto
}

.aaf318bef7.eb0155f4a2:after,.e7d4a4f05b.eb0155f4a2:before {
    opacity: .5
}

.e019d2785d .a9883fe675,.e019d2785d .a9883fe675:after,.e019d2785d .a9883fe675:before,.e019d2785d .db7bf77dce {
    transition: none
}

.edef731dad {
    overflow: hidden;
    position: relative
}

.ba30a27694 {
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0
}

.ec2b24b828 {
    display: inline-block;
    height: 100%;
    scroll-snap-align: start;
    scroll-snap-stop:always;vertical-align: top;
    white-space: normal;
    width: 100%
}

.bb53f0c419 {
    background-size: cover;
    height: 100%
}

.d08458be51 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: var(--bui_animation_hover);
    transition-property: opacity,visibility
}

.ea1062320a {
    left: var(--bui_spacing_2x)
}

.feb4c2639b .ea1062320a,[dir=rtl] .ea1062320a {
    left: auto;
    right: var(--bui_spacing_2x)
}

.f8038782c4 {
    right: var(--bui_spacing_2x)
}

.feb4c2639b .f8038782c4,[dir=rtl] .f8038782c4 {
    left: var(--bui_spacing_2x);
    right: auto
}

.e20cb24922 {
    display: flex;
    justify-content: center
}

.cc842ed0c5 {
    background: var(--bui_color_background_alt);
    color: #fff;
    padding-block-start:66.66%}

.cc842ed0c5 .f5681c6d71 {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.cc842ed0c5 .ba30a27694 {
    background: var(--bui_color_background_alt)
}

.bdb625a79b {
    border-radius: var(--bui_border_radius_100)
}

.a65ae2af13 {
    border-radius: var(--bui_border_radius_200)
}

.bfdc393236 {
    border-radius: var(--bui_border_radius_300)
}

.d4859b2cf7 .d08458be51 {
    opacity: 0;
    visibility: hidden
}

.d4859b2cf7:hover .d08458be51 {
    opacity: 1;
    visibility: visible
}

.ba8d1d4506 {
    list-style: none;
    margin: 0;
    padding: 0
}

.ba8d1d4506,.eee5335a6e {
    align-items: center;
    display: flex
}

.eee5335a6e {
    flex-grow: 1
}

.eee5335a6e:last-child {
    flex-grow: 0
}

.c3aab6eaa8 {
    align-items: center;
    background: #70bbe9;
    border: var(--bui_border_width_200) solid #70bbe9;
    border-radius: 50%;
    box-sizing: border-box;
    color: var(--bui_color_on_action_background);
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: var(--bui_spacing_6x);
    justify-content: center;
    line-height: 1;
    margin-inline-end:var(--bui_spacing_2x);width: var(--bui_spacing_6x)
}

.f10ea6f035,.f10ea6f035 .c3aab6eaa8 {
    color: var(--bui_color_foreground_alt)
}

.f10ea6f035 .c3aab6eaa8 {
    background: transparent;
    border-color: var(--bui_color_border)
}

.f6ec7a5ae5 {
    background: var(--bui_color_border);
    flex: 1;
    height: var(--bui_border_width_100);
    margin: 0 var(--bui_spacing_2x);
    min-width: var(--bui_spacing_4x)
}

.e902483b71 {
    display: none
}

@media (max-width: 575px) {
    .f9afd6c440 .c3aab6eaa8,.f9afd6c440 .eee5335a6e,.f9afd6c440 .f6ec7a5ae5 {
        display:none
    }

    .f9afd6c440 .c828e90cf0 {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: space-between
    }

    .f9afd6c440 .c828e90cf0 .e902483b71 {
        display: block;
        margin-inline-start:var(--bui_spacing_2x)}
}

.c74a98bc90 {
    align-items: flex-start;
    flex-direction: column
}

.c74a98bc90 .eee5335a6e {
    display: block;
    padding-block-end:var(--bui_spacing_8x);position: relative;
    width: 100%
}

.c74a98bc90 .c828e90cf0:not(:last-child) .e9fc5a1ca3 {
    margin-block-end:calc(var(--bui_spacing_4x)*-1)}

.c74a98bc90 .eee5335a6e last-child {
    padding-block-end:0
}

.c74a98bc90 .fcaded776e {
    align-items: center;
    display: flex
}

.c74a98bc90 .e9fc5a1ca3 {
    display: block;
    margin-block-start:var(--bui_spacing_4x);margin-inline-start: var(--bui_spacing_8x)
}

.c74a98bc90 .f6ec7a5ae5 {
    display: none
}

.c74a98bc90 .c3aab6eaa8 {
    position: relative
}

.c74a98bc90 .eee5335a6e:before {
    background: var(--bui_color_border);
    bottom: var(--bui_spacing_2x);
    content: "";
    left: calc(var(--bui_spacing_3x) - .5px);
    position: absolute;
    top: var(--bui_spacing_8x);
    width: var(--bui_border_width_100)
}

.be93927e47 .c74a98bc90 .eee5335a6e:before,[dir=rtl] .c74a98bc90 .eee5335a6e:before {
    left: auto;
    right: calc(var(--bui_spacing_3x) - .5px)
}

.c74a98bc90 .eee5335a6e:last-child:before {
    content: none
}

.b9720ed41e {
    -webkit-overflow-scrolling: touch;
    background: transparent;
    border: none;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    outline: none;
    overflow: auto;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: var(--bui_animation_fade_in);
    transition-property: background-color,visibility;
    visibility: hidden;
    z-index: var(--bui_z_index_2)
}

.eb33ef7c47 {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    min-height: 100%;
    width: 100%
}

.cdf0a9297c {
    background-color: var(--bui_color_black_with_alpha);
    transition-duration: var(--bui_animation_fade_in_duration);
    transition-property: background-color;
    transition-timing-function: var(--bui_animation_fade_in_timing_function);
    visibility: visible
}

.d39ab0cca1 {
    background-color: transparent;
    pointer-events: none
}

.f05eeb65a7 {
    background: transparent;
    visibility: hidden
}

.e49b423746 {
    align-items: center;
    display: flex;
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    justify-content: center;
    line-height: var(--bui_font_body_2_line-height);
    padding: var(--bui_spacing_1x) 0
}

.faf15ac93e {
    flex: 1
}

.ef2dbaeb17 {
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0
}

.ab95b25344,.ef2dbaeb17 {
    display: flex
}

.a37e5e1982 {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0 var(--bui_spacing_4x);
    text-align: center
}

.d03a1e3e45 {
    margin: 0 var(--bui_spacing_1x)
}

.d03a1e3e45:first-child {
    margin-inline-end:var(--bui_spacing_1x);margin-inline-start: 0
}

.d03a1e3e45:last-child {
    margin-inline-end:0;margin-inline-start:var(--bui_spacing_1x)}

.a16ddf9c57 {
    font-family: var(--bui_font_emphasized_2_font-family);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height)
}

.b01f80d01e {
    align-items: center;
    display: inline-flex;
    margin-inline-end:var(--bui_spacing_1x)}

.b16a89683f+.b16a89683f {
    margin-inline-start: var(--bui_spacing_2x)
}

.cab1524053 {
    margin-inline-start:var(--bui_spacing_1x)}

.a2f4a5e019,.a2028338ea,.a2028338ea link,.a2028338ea:visited {
    align-items:center;
    border: var(--bui_border_width_100) solid transparent;
    border-radius: var(--bui_border_radius_100);
    color: var(--bui_color_action_foreground);
    display: flex;
    height: var(--bui_spacing_6x);
    justify-content: center;
    min-width: var(--bui_spacing_6x);
    padding: 0 var(--bui_spacing_1x)
}

.a2f4a5e019 {
    color: var(--bui_color_foreground_disabled)
}

.b16a89683f:hover .a2028338ea {
    background-color: var(--bui_color_action_highlighted_alt);
    border-color: transparent
}

.ce06be16cb .a2028338ea,.ce06be16cb:hover .a2028338ea {
    background-color: transparent;
    border-color: var(--bui_color_border);
    color: var(--bui_color_foreground);
    cursor: default
}

.a959f03bba .a2028338ea,.a959f03bba:hover .a2028338ea {
    fill: var(--bui_color_foreground_disabled);
    background-color: transparent;
    border-color: transparent;
    color: var(--bui_color_foreground_disabled)
}

.dc278d04cb {
    border-color: transparent
}

.be933f8f9e {
    align-items: center;
    display: flex
}

.f7bada3df3 {
    background-color: var(--bui_color_border_alt);
    height: var(--bui_spacing_6x);
    margin: 0 var(--bui_spacing_4x);
    width: 1px
}

.a7c436bd0b:last-child {
    padding-inline-end:var(--bui_spacing_4x)}

.f7bada3df3+.a7cf14598c {
    margin-inline-start: calc(var(--bui_spacing_2x)*-1)
}

@media (min-width: 1024px) {
    .ef2dbaeb17 {
        justify-content:flex-start
    }

    .faf15ac93e {
        flex: 0
    }

    .e49b423746 {
        justify-content: space-between
    }
}

.ab4f62cc7e:not(.a1902a1d4e) {
    display: none
}

@media print {
    .ab4f62cc7e:not(.a1902a1d4e) {
        display: block
    }

    .a1902a1d4e {
        display: none!important
    }
}

.a455730030 {
    display: flex
}

.d31eda6efc {
    fill: var(--bui_color_accent_background);
    margin-inline-end:0;margin-inline-start:var(--bui_spacing_half)}

.d31eda6efc  first-child {
    margin-inline-start:0
}

.d86cee9b25 {
    align-items: center;
    background:#70bbe9;
    border-radius: calc(var(--bui_spacing_8x)/5.5) calc(var(--bui_spacing_8x)/5.5) calc(var(--bui_spacing_8x)/5.5) 0;
    color: #70bbe9;
    display: flex;
    height: var(--bui_spacing_8x);
    justify-content: center;
    min-width: var(--bui_spacing_8x);
    vertical-align: baseline;
    width: var(--bui_spacing_8x)
}

.abe5b204e9 .d86cee9b25,[dir=rtl] .d86cee9b25 {
    border-radius: calc(var(--bui_spacing_8x)/5.5) calc(var(--bui_spacing_8x)/5.5) 0 calc(var(--bui_spacing_8x)/5.5)
}

.d6be994243 .d86cee9b25 {
    border-radius: calc(var(--bui_spacing_1x)*7/5.5) calc(var(--bui_spacing_1x)*7/5.5) calc(var(--bui_spacing_1x)*7/5.5) 0;
    height: calc(var(--bui_spacing_1x)*7);
    min-width: calc(var(--bui_spacing_1x)*7);
    width: calc(var(--bui_spacing_1x)*7)
}

.abe5b204e9 .d6be994243 .d86cee9b25,[dir=rtl] .d6be994243 .d86cee9b25 {
    border-radius: calc(var(--bui_spacing_1x)*7/5.5) calc(var(--bui_spacing_1x)*7/5.5) 0 calc(var(--bui_spacing_1x)*7/5.5)
}

.cbf4befc54 .d86cee9b25 {
    border-radius: calc(var(--bui_spacing_6x)/5.5) calc(var(--bui_spacing_6x)/5.5) calc(var(--bui_spacing_6x)/5.5) 0;
    height: var(--bui_spacing_6x);
    min-width: var(--bui_spacing_6x);
    width: var(--bui_spacing_6x)
}

.abe5b204e9 .cbf4befc54 .d86cee9b25,[dir=rtl] .cbf4befc54 .d86cee9b25 {
    border-radius: calc(var(--bui_spacing_6x)/5.5) calc(var(--bui_spacing_6x)/5.5) 0 calc(var(--bui_spacing_6x)/5.5)
}

.dc7f26e57f .d935416c47 {
    margin-block-start:calc(var(--bui_spacing_1x)*-1)}

.dc7f26e57f.cbf4befc54 .d935416c47,.dc7f26e57f.d6be994243 .d935416c47 {
    margin-block-start: calc(var(--bui_spacing_half)*-1)
}

.c64b5449d6 .d86cee9b25 {
    background: transparent;
    border: var(--bui_border_width_100) solid var(--bui_color_border);
    color: var(--bui_color_foreground)
}

.e28bd40400 .d86cee9b25 {
    background: transparent;
    border: var(--bui_border_width_100) solid #fff
}

.e28bd40400 .cb2cbb3ccb,.e28bd40400 .d86cee9b25,.e28bd40400 .d935416c47 {
    color: #fff
}

.d5fd510f01 .a29749fd9f {
    text-align: end
}

.a21be65b3f {
    background: var(--bui_color_background_alt);
    border-radius: 999px;
    overflow: hidden
}

.c294c28088 {
    background:#70bbe9;
    border-radius: 999px;
    display: block;
    height: var(--bui_spacing_2x)
}

.f42005b03c .c294c28088 {
    background-color: var(--bui_color_destructive_background)
}

.e1067a9db6 .c294c28088 {
    background-color: var(--bui_color_constructive_background)
}

.fde0290e05 .c294c28088 {
    background-color: var(--bui_color_accent_background)
}

.b8abb9b191 .c294c28088 {
    background-color: var(--bui_color_callout_background)
}

.fa7a939a10 .c294c28088 {
    background-color: #70bbe9
}

.cdadcf1b05 {
    align-items: center;
    background-color: var(--bui_color_background_alt);
    border: var(--bui_border_width_100) solid var(--bui_color_border_alt);
    border-radius: 999px;
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: calc(var(--bui_spacing_1x) - var(--bui_border_width_100));
    vertical-align: top
}

.b9f34dca24 {
    display: inline-block;
    margin-block-end:var(--bui_spacing_1x)}

.a454023a93 {
    align-items: center;
    border: var(--bui_border_width_100) solid transparent;
    border-radius: 999px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: calc(var(--bui_spacing_1x) - var(--bui_border_width_100)) calc(var(--bui_spacing_3x) - var(--bui_border_width_100));
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.a454023a93:before {
    background: var(--bui_color_border);
    bottom: calc(var(--bui_spacing_1x) + var(--bui_spacing_half));
    content: "";
    left: calc(var(--bui_border_width_100)*-1);
    opacity: .3;
    position: absolute;
    top: calc(var(--bui_spacing_1x) + var(--bui_spacing_half));
    transition: opacity var(--bui_animation_hover);
    width: var(--bui_border_width_100)
}

.f420a3e0c7 .a454023a93:before,[dir=rtl] .a454023a93:before {
    left: auto;
    right: calc(var(--bui_border_width_100)*-1)
}

.a454023a93:hover+.b7cdcf5f57+.a454023a93:before,.a454023a93:hover:before,.b7cdcf5f57:checked+.a454023a93+.b7cdcf5f57+.a454023a93:before,.b7cdcf5f57:checked+.a454023a93:before,.b7cdcf5f57:first-child+.a454023a93:before {
    opacity: 0;
    transition: none
}

.a454023a93:hover {
    background: var(--bui_color_highlighted_alt);
    border-color: var(--bui_color_background_alt)
}

.b7cdcf5f57 {
    border: 0;
    height: 1px;
    opacity: 0;
    position: absolute;
    width: 1px
}

.b7cdcf5f57:checked+.a454023a93,.b7cdcf5f57:checked+.a454023a93:hover {
    background: var(--bui_color_background_elevation_one);
    border-color: var(--bui_color_border)
}

[data-bui-keyboard] .b7cdcf5f57:focus+.a454023a93 {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none;
    z-index: 2
}

.b54d92f5d3 .cdadcf1b05 {
    display: flex
}

.b54d92f5d3 .a454023a93,.b54d92f5d3 .dcabb4f6b0 {
    justify-content: center;
    width: 100%
}

.f2cf178bcd {
    display: flex;
    flex-direction: column
}

.d0caee4251,.df64fda51b {
    margin: 0;
    padding: 0
}

.d92e91fad2 {
    flex-direction: column-reverse
}

.d74ea6e4a7 .df64fda51b {
    opacity: .8
}

.d991e1216a .df64fda51b {
    margin-block-start:var(--bui_spacing_half)}

.d991e1216a.d92e91fad2 .df64fda51b {
    margin-block-end: var(--bui_spacing_half);
    margin-block-start:0}

.df11f444aa .df64fda51b {
    margin-block-start:var(--bui_spacing_1x)}

.df11f444aa.d92e91fad2 .df64fda51b {
    margin-block-end: var(--bui_spacing_1x);
    margin-block-start:0}

.b28743071e .df64fda51b,.e09531ce21 .df64fda51b {
    margin-block-start:var(--bui_spacing_2x)}

.b28743071e.d92e91fad2 .df64fda51b,.e09531ce21.d92e91fad2 .df64fda51b {
    margin-block-end: var(--bui_spacing_2x);
    margin-block-start:0}

.f0c216ee26 {
    --bui_sheet_container_content_padding_fallbacked: var( --bui_sheet_container_fill_content_padding,var(--bui_sheet_container_content_padding,var(--bui_spacing_4x)) );
    box-sizing: border-box;
    pointer-events: none;
    text-align: start;
    transition: var(--bui_animation_fade_out);
    transition-property: opacity,transform,box-shadow;
    width: var(--bui_sheet_container_width,initial);
    will-change: transform,opacity,box-shadow
}

.dd5dccd82f {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding-block-end:var(--bui_sheet_container_content_padding_fallbacked)}

.ea5ceff2ee {
    word-break: break-word
}

.ff74db973c {
    right: var(--bui_sheet_container_content_padding_fallbacked);
    top: calc(var(--bui_sheet_container_content_padding_fallbacked) + var(--bui_spacing_half))
}

.d65ab6d474 .ff74db973c,[dir=rtl] .ff74db973c {
    left: var(--bui_sheet_container_content_padding_fallbacked);
    right: auto
}

.c0528ecc22 {
    background: var(--bui_color_background_elevation_one);
    border-radius: var(--bui_sheet_container_inner_border-radius-start-start,0) var(--bui_sheet_container_inner_border-radius-start-end,0) var(--bui_sheet_container_inner_border-radius-end-end,0) var(--bui_sheet_container_inner_border-radius-end-start,0);
    box-shadow: var(--bui_shadow_100);
    display: flex;
    flex-direction: column;
    height: var(--bui_sheet_container_inner_height,100%);
    max-height: var(--bui_sheet_container_inner_max-height,initial);
    outline: none;
    pointer-events: all;
    width: var(--bui_sheet_container_inner_width,initial)
}

.d65ab6d474 .c0528ecc22,[dir=rtl] .c0528ecc22 {
    border-radius: var(--bui_sheet_container_inner_border-radius-start-end,0) var(--bui_sheet_container_inner_border-radius-start-start,0) var(--bui_sheet_container_inner_border-radius-end-start,0) var(--bui_sheet_container_inner_border-radius-end-end,0)
}

.f0c216ee26[dir=rtl] .c0528ecc22 {
    border-radius: var( --bui_sheet_container_inner_border-radius-start-start_rtl,0 ) var(--bui_sheet_container_inner_border-radius-start-end_rtl,0) var(--bui_sheet_container_inner_border-radius-end-end_rtl,0) var(--bui_sheet_container_inner_border-radius-end-start_rtl,0)
}

.dc19f70f85 {
    margin-block-end:var( --bui_sheet_container_heading_margin_block_end,var(--bui_spacing_2x) );padding-block-start: var(--bui_sheet_container_content_padding_fallbacked);
    padding-inline-start:var(--bui_sheet_container_content_padding_fallbacked)}

.bb8c4da903 {
    background: var(--bui_color_background_elevation_one);
    margin-block-end:0;padding-block-end:var(--bui_sheet_container_content_padding_fallbacked);position: sticky;
    top: 0
}

.eb67815534 {
    min-height: var(--bui_font_headline_3_line-height)
}

.f7c2c6294c {
    flex-grow: 1;
    height: var(--bui_sheet_container_body_height,initial);
    padding-block-start:var(--bui_sheet_container_content_padding_fallbacked);padding-inline-end: var(--bui_sheet_container_content_padding_fallbacked);
    padding-inline-start:var(--bui_sheet_container_content_padding_fallbacked)}

.dc19f70f85+.f7c2c6294c {
    padding-block-start: 0
}

.c1af8b38aa {
    background: var(--bui_sheet_container_footer_background,initial);
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    bottom: var(--bui_sheet_container_footer_bottom,initial);
    flex-shrink: 0;
    overflow: var(--bui_sheet_container_footer_overflow,hidden);
    position: var(--bui_sheet_container_footer_position,initial)
}

.dfd96e08ba {
    --bui_sheet_container_inner_border-radius-end-end: initial;
    --bui_sheet_container_inner_border-radius-end-end_rtl: initial;
    --bui_sheet_container_inner_border-radius-end-start: initial;
    --bui_sheet_container_inner_border-radius-end-start_rtl: initial;
    --bui_sheet_container_width: initial;
    --bui_sheet_container_footer_position: initial;
    --bui_sheet_container_footer_bottom: initial;
    --bui_sheet_container_footer_background: initial;
    --bui_sheet_container_footer_overflow: initial;
    --bui_sheet_container_body_height: initial;
    --bui_sheet_container_inner_height: auto;
    --bui_sheet_container_inner_width: 100%;
    --bui_sheet_container_inner_max-height: 100%;
    --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
    --bui_sheet_container_content_padding: initial;
    --bui_sheet_container_heading_margin_block_end: initial;
    align-items: flex-end;
    bottom: 0;
    display: flex;
    height: 100%;
    padding-block-start:var(--bui_spacing_8x);padding-inline-end: 0;
    position: fixed;
    right: 0;
    transform: translateY(100%)
}

.cef042fad7,.dfd96e08ba {
    left: 0;
    max-height: none;
    max-width: none;
    opacity: 1;
    padding-block-end:0;padding-inline-start:0;top: auto;
    vertical-align: initial;
    z-index: auto
}

.cef042fad7 {
    --bui_sheet_container_inner_height: initial;
    --bui_sheet_container_inner_width: initial;
    --bui_sheet_container_inner_max-height: initial;
    --bui_sheet_container_inner_border-radius-start-start: initial;
    --bui_sheet_container_inner_border-radius-start-start_rtl: initial;
    --bui_sheet_container_content_padding: initial;
    --bui_sheet_container_heading_margin_block_end: initial;
    --bui_sheet_container_inner_border-radius-end-start: initial;
    --bui_sheet_container_inner_border-radius-end-start_rtl: initial;
    --bui_sheet_container_width: initial;
    --bui_sheet_container_footer_position: initial;
    --bui_sheet_container_footer_bottom: initial;
    --bui_sheet_container_footer_background: initial;
    --bui_sheet_container_footer_overflow: initial;
    --bui_sheet_container_body_height: initial;
    --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-end-end: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-end-end_rtl: var( --bui_border_radius_300 );
    align-items: normal;
    bottom: auto;
    display: initial;
    height: auto;
    padding-block-start:0;padding-inline-end:var(--bui_spacing_8x);position: static;
    right: auto;
    transform: translateX(-100%)
}

.c1744def95,.d65ab6d474 .cef042fad7,[dir=rtl] .cef042fad7 {
    left: auto;
    right: 0;
    transform: translateX(100%)
}

.c1744def95 {
    --bui_sheet_container_inner_height: initial;
    --bui_sheet_container_inner_width: initial;
    --bui_sheet_container_inner_max-height: initial;
    --bui_sheet_container_inner_border-radius-start-end: initial;
    --bui_sheet_container_inner_border-radius-start-end_rtl: initial;
    --bui_sheet_container_content_padding: initial;
    --bui_sheet_container_heading_margin_block_end: initial;
    --bui_sheet_container_inner_border-radius-end-end: initial;
    --bui_sheet_container_inner_border-radius-end-end_rtl: initial;
    --bui_sheet_container_width: initial;
    --bui_sheet_container_footer_position: initial;
    --bui_sheet_container_footer_bottom: initial;
    --bui_sheet_container_footer_background: initial;
    --bui_sheet_container_footer_overflow: initial;
    --bui_sheet_container_body_height: initial;
    --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-end-start: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-end-start_rtl: var( --bui_border_radius_300 );
    align-items: normal;
    bottom: auto;
    display: initial;
    height: auto;
    max-height: none;
    max-width: none;
    opacity: 1;
    padding-block-end:0;padding-block-start:0;padding-inline-end:0;padding-inline-start:var(--bui_spacing_8x);position: static;
    top: auto;
    vertical-align: initial;
    z-index: auto
}

.d65ab6d474 .c1744def95,[dir=rtl] .c1744def95 {
    left: 0;
    right: auto;
    transform: translateX(-100%)
}

.c1744def95,.cef042fad7 {
    --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,calc(var(--bui_spacing_1x)*88) );
    bottom: 0;
    max-height: 100%;
    max-width: 100%;
    position: fixed;
    top: 0
}

.b5018b639f {
    --bui_sheet_container_inner_height: initial;
    --bui_sheet_container_inner_width: initial;
    --bui_sheet_container_inner_max-height: initial;
    --bui_sheet_container_content_padding: initial;
    --bui_sheet_container_heading_margin_block_end: initial;
    --bui_sheet_container_footer_overflow: initial;
    --bui_sheet_container_body_height: initial;
    --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-end-end: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-end-start: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-end-end_rtl: var( --bui_border_radius_300 );
    --bui_sheet_container_inner_border-radius-end-start_rtl: var( --bui_border_radius_300 );
    --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,calc(var(--bui_spacing_1x)*144) );
    --bui_sheet_container_footer_position: sticky;
    --bui_sheet_container_footer_bottom: 0;
    --bui_sheet_container_footer_background: var( --bui_color_background_elevation_one );
    height: auto;
    left: auto;
    padding-block-end:var(--bui_spacing_6x);padding-block-start: var(--bui_spacing_6x);
    padding-inline-end:var(--bui_spacing_6x);padding-inline-start: var(--bui_spacing_6x);
    position: relative;
    top: auto;
    transform: none
}

.b5018b639f,.b89375fcba {
    align-items: normal;
    bottom: auto;
    display: inline-block;
    max-height: none;
    max-width: 100vw;
    opacity: 0;
    right: auto;
    vertical-align: middle;
    z-index: var(--bui_z_index_4)
}

.b89375fcba {
    --bui_sheet_container_inner_height: initial;
    --bui_sheet_container_inner_width: initial;
    --bui_sheet_container_inner_max-height: initial;
    --bui_sheet_container_content_padding: initial;
    --bui_sheet_container_heading_margin_block_end: initial;
    --bui_sheet_container_footer_position: initial;
    --bui_sheet_container_footer_bottom: initial;
    --bui_sheet_container_footer_background: initial;
    --bui_sheet_container_inner_border-radius-start-start: 0;
    --bui_sheet_container_inner_border-radius-start-end: 0;
    --bui_sheet_container_inner_border-radius-end-end: 0;
    --bui_sheet_container_inner_border-radius-end-start: 0;
    --bui_sheet_container_inner_border-radius-start-start_rtl: 0;
    --bui_sheet_container_inner_border-radius-start-end_rtl: 0;
    --bui_sheet_container_inner_border-radius-end-end_rtl: 0;
    --bui_sheet_container_inner_border-radius-end-start_rtl: 0;
    --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,100% );
    --bui_sheet_container_footer_overflow: auto;
    --bui_sheet_container_body_height: 0;
    height: 100%;
    left: 0;
    padding-block-end:0;padding-block-start:0;padding-inline-end:0;padding-inline-start:0;position: absolute;
    top: 0;
    transform: translateY(var(--bui_spacing_6x))
}

.d65ab6d474 .b89375fcba,[dir=rtl] .b89375fcba {
    left: auto;
    right: 0
}

@media (min-width: 576px) {
    .c9c2fbdd5a {
        --bui_sheet_container_inner_border-radius-end-end:initial;
        --bui_sheet_container_inner_border-radius-end-end_rtl: initial;
        --bui_sheet_container_inner_border-radius-end-start: initial;
        --bui_sheet_container_inner_border-radius-end-start_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_height: auto;
        --bui_sheet_container_inner_width: 100%;
        --bui_sheet_container_inner_max-height: 100%;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        align-items: flex-end;
        bottom: 0;
        display: flex;
        height: 100%;
        padding-block-start:var(--bui_spacing_8x);padding-inline-end: 0;
        position: fixed;
        right: 0;
        transform: translateY(100%)
    }

    .c9c2fbdd5a,.fa439e0817 {
        left: 0;
        max-height: none;
        max-width: none;
        opacity: 1;
        padding-block-end:0;padding-inline-start:0;top: auto;
        vertical-align: initial;
        z-index: auto
    }

    .fa439e0817 {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_inner_border-radius-start-start: initial;
        --bui_sheet_container_inner_border-radius-start-start_rtl: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_inner_border-radius-end-start: initial;
        --bui_sheet_container_inner_border-radius-end-start_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end_rtl: var( --bui_border_radius_300 );
        align-items: normal;
        bottom: auto;
        display: initial;
        height: auto;
        padding-block-start:0;padding-inline-end:var(--bui_spacing_8x);position: static;
        right: auto;
        transform: translateX(-100%)
    }

    .d65ab6d474 .fa439e0817,.fc4b9bb48a,[dir=rtl] .fa439e0817 {
        left: auto;
        right: 0;
        transform: translateX(100%)
    }

    .fc4b9bb48a {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_inner_border-radius-start-end: initial;
        --bui_sheet_container_inner_border-radius-start-end_rtl: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_inner_border-radius-end-end: initial;
        --bui_sheet_container_inner_border-radius-end-end_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start_rtl: var( --bui_border_radius_300 );
        align-items: normal;
        bottom: auto;
        display: initial;
        height: auto;
        max-height: none;
        max-width: none;
        opacity: 1;
        padding-block-end:0;padding-block-start:0;padding-inline-end:0;padding-inline-start:var(--bui_spacing_8x);position: static;
        top: auto;
        vertical-align: initial;
        z-index: auto
    }

    .d65ab6d474 .fc4b9bb48a,[dir=rtl] .fc4b9bb48a {
        left: 0;
        right: auto;
        transform: translateX(-100%)
    }

    .fa439e0817,.fc4b9bb48a {
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,calc(var(--bui_spacing_1x)*88) );
        bottom: 0;
        max-height: 100%;
        max-width: 100%;
        position: fixed;
        top: 0
    }

    .ce21d13598 {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,calc(var(--bui_spacing_1x)*144) );
        --bui_sheet_container_footer_position: sticky;
        --bui_sheet_container_footer_bottom: 0;
        --bui_sheet_container_footer_background: var( --bui_color_background_elevation_one );
        height: auto;
        left: auto;
        padding-block-end:var(--bui_spacing_6x);padding-block-start: var(--bui_spacing_6x);
        padding-inline-end:var(--bui_spacing_6x);padding-inline-start: var(--bui_spacing_6x);
        position: relative;
        top: auto;
        transform: none
    }

    .bdeea2c3e9,.ce21d13598 {
        align-items: normal;
        bottom: auto;
        display: inline-block;
        max-height: none;
        max-width: 100vw;
        opacity: 0;
        right: auto;
        vertical-align: middle;
        z-index: var(--bui_z_index_4)
    }

    .bdeea2c3e9 {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_inner_border-radius-start-start: 0;
        --bui_sheet_container_inner_border-radius-start-end: 0;
        --bui_sheet_container_inner_border-radius-end-end: 0;
        --bui_sheet_container_inner_border-radius-end-start: 0;
        --bui_sheet_container_inner_border-radius-start-start_rtl: 0;
        --bui_sheet_container_inner_border-radius-start-end_rtl: 0;
        --bui_sheet_container_inner_border-radius-end-end_rtl: 0;
        --bui_sheet_container_inner_border-radius-end-start_rtl: 0;
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,100% );
        --bui_sheet_container_footer_overflow: auto;
        --bui_sheet_container_body_height: 0;
        height: 100%;
        left: 0;
        padding-block-end:0;padding-block-start:0;padding-inline-end:0;padding-inline-start:0;position: absolute;
        top: 0;
        transform: translateY(var(--bui_spacing_6x))
    }

    .d65ab6d474 .bdeea2c3e9,[dir=rtl] .bdeea2c3e9 {
        left: auto;
        right: 0
    }
}

@media (min-width: 1024px) {
    .a66821409e {
        --bui_sheet_container_inner_border-radius-end-end:initial;
        --bui_sheet_container_inner_border-radius-end-end_rtl: initial;
        --bui_sheet_container_inner_border-radius-end-start: initial;
        --bui_sheet_container_inner_border-radius-end-start_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_height: auto;
        --bui_sheet_container_inner_width: 100%;
        --bui_sheet_container_inner_max-height: 100%;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        align-items: flex-end;
        bottom: 0;
        display: flex;
        height: 100%;
        padding-block-start:var(--bui_spacing_8x);padding-inline-end: 0;
        position: fixed;
        right: 0;
        transform: translateY(100%)
    }

    .a66821409e,.dedcf60bcc {
        left: 0;
        max-height: none;
        max-width: none;
        opacity: 1;
        padding-block-end:0;padding-inline-start:0;top: auto;
        vertical-align: initial;
        z-index: auto
    }

    .dedcf60bcc {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_inner_border-radius-start-start: initial;
        --bui_sheet_container_inner_border-radius-start-start_rtl: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_inner_border-radius-end-start: initial;
        --bui_sheet_container_inner_border-radius-end-start_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end_rtl: var( --bui_border_radius_300 );
        align-items: normal;
        bottom: auto;
        display: initial;
        height: auto;
        padding-block-start:0;padding-inline-end:var(--bui_spacing_8x);position: static;
        right: auto;
        transform: translateX(-100%)
    }

    .d65ab6d474 .dedcf60bcc,.df972bb901,[dir=rtl] .dedcf60bcc {
        left: auto;
        right: 0;
        transform: translateX(100%)
    }

    .df972bb901 {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_inner_border-radius-start-end: initial;
        --bui_sheet_container_inner_border-radius-start-end_rtl: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_inner_border-radius-end-end: initial;
        --bui_sheet_container_inner_border-radius-end-end_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start_rtl: var( --bui_border_radius_300 );
        align-items: normal;
        bottom: auto;
        display: initial;
        height: auto;
        max-height: none;
        max-width: none;
        opacity: 1;
        padding-block-end:0;padding-block-start:0;padding-inline-end:0;padding-inline-start:var(--bui_spacing_8x);position: static;
        top: auto;
        vertical-align: initial;
        z-index: auto
    }

    .d65ab6d474 .df972bb901,[dir=rtl] .df972bb901 {
        left: 0;
        right: auto;
        transform: translateX(-100%)
    }

    .dedcf60bcc,.df972bb901 {
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,calc(var(--bui_spacing_1x)*88) );
        bottom: 0;
        max-height: 100%;
        max-width: 100%;
        position: fixed;
        top: 0
    }

    .cfe547ff91 {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,calc(var(--bui_spacing_1x)*144) );
        --bui_sheet_container_footer_position: sticky;
        --bui_sheet_container_footer_bottom: 0;
        --bui_sheet_container_footer_background: var( --bui_color_background_elevation_one );
        height: auto;
        left: auto;
        padding-block-end:var(--bui_spacing_6x);padding-block-start: var(--bui_spacing_6x);
        padding-inline-end:var(--bui_spacing_6x);padding-inline-start: var(--bui_spacing_6x);
        position: relative;
        top: auto;
        transform: none
    }

    .a2129a1537,.cfe547ff91 {
        align-items: normal;
        bottom: auto;
        display: inline-block;
        max-height: none;
        max-width: 100vw;
        opacity: 0;
        right: auto;
        vertical-align: middle;
        z-index: var(--bui_z_index_4)
    }

    .a2129a1537 {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_inner_border-radius-start-start: 0;
        --bui_sheet_container_inner_border-radius-start-end: 0;
        --bui_sheet_container_inner_border-radius-end-end: 0;
        --bui_sheet_container_inner_border-radius-end-start: 0;
        --bui_sheet_container_inner_border-radius-start-start_rtl: 0;
        --bui_sheet_container_inner_border-radius-start-end_rtl: 0;
        --bui_sheet_container_inner_border-radius-end-end_rtl: 0;
        --bui_sheet_container_inner_border-radius-end-start_rtl: 0;
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,100% );
        --bui_sheet_container_footer_overflow: auto;
        --bui_sheet_container_body_height: 0;
        height: 100%;
        left: 0;
        padding-block-end:0;padding-block-start:0;padding-inline-end:0;padding-inline-start:0;position: absolute;
        top: 0;
        transform: translateY(var(--bui_spacing_6x))
    }

    .d65ab6d474 .a2129a1537,[dir=rtl] .a2129a1537 {
        left: auto;
        right: 0
    }
}

@media (min-width: 1280px) {
    .a10caa4d4f {
        --bui_sheet_container_inner_border-radius-end-end:initial;
        --bui_sheet_container_inner_border-radius-end-end_rtl: initial;
        --bui_sheet_container_inner_border-radius-end-start: initial;
        --bui_sheet_container_inner_border-radius-end-start_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_height: auto;
        --bui_sheet_container_inner_width: 100%;
        --bui_sheet_container_inner_max-height: 100%;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        align-items: flex-end;
        bottom: 0;
        display: flex;
        height: 100%;
        padding-block-start:var(--bui_spacing_8x);padding-inline-end: 0;
        position: fixed;
        right: 0;
        transform: translateY(100%)
    }

    .a10caa4d4f,.d257dfc01e {
        left: 0;
        max-height: none;
        max-width: none;
        opacity: 1;
        padding-block-end:0;padding-inline-start:0;top: auto;
        vertical-align: initial;
        z-index: auto
    }

    .d257dfc01e {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_inner_border-radius-start-start: initial;
        --bui_sheet_container_inner_border-radius-start-start_rtl: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_inner_border-radius-end-start: initial;
        --bui_sheet_container_inner_border-radius-end-start_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end_rtl: var( --bui_border_radius_300 );
        align-items: normal;
        bottom: auto;
        display: initial;
        height: auto;
        padding-block-start:0;padding-inline-end:var(--bui_spacing_8x);position: static;
        right: auto;
        transform: translateX(-100%)
    }

    .cde676ea90,.d65ab6d474 .d257dfc01e,[dir=rtl] .d257dfc01e {
        left: auto;
        right: 0;
        transform: translateX(100%)
    }

    .cde676ea90 {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_inner_border-radius-start-end: initial;
        --bui_sheet_container_inner_border-radius-start-end_rtl: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_inner_border-radius-end-end: initial;
        --bui_sheet_container_inner_border-radius-end-end_rtl: initial;
        --bui_sheet_container_width: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start_rtl: var( --bui_border_radius_300 );
        align-items: normal;
        bottom: auto;
        display: initial;
        height: auto;
        max-height: none;
        max-width: none;
        opacity: 1;
        padding-block-end:0;padding-block-start:0;padding-inline-end:0;padding-inline-start:var(--bui_spacing_8x);position: static;
        top: auto;
        vertical-align: initial;
        z-index: auto
    }

    .d65ab6d474 .cde676ea90,[dir=rtl] .cde676ea90 {
        left: 0;
        right: auto;
        transform: translateX(-100%)
    }

    .cde676ea90,.d257dfc01e {
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,calc(var(--bui_spacing_1x)*88) );
        bottom: 0;
        max-height: 100%;
        max-width: 100%;
        position: fixed;
        top: 0
    }

    .f424f05f2e {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_footer_overflow: initial;
        --bui_sheet_container_body_height: initial;
        --bui_sheet_container_inner_border-radius-start-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-start-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-end_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_inner_border-radius-end-start_rtl: var( --bui_border_radius_300 );
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,calc(var(--bui_spacing_1x)*144) );
        --bui_sheet_container_footer_position: sticky;
        --bui_sheet_container_footer_bottom: 0;
        --bui_sheet_container_footer_background: var( --bui_color_background_elevation_one );
        height: auto;
        left: auto;
        padding-block-end:var(--bui_spacing_6x);padding-block-start: var(--bui_spacing_6x);
        padding-inline-end:var(--bui_spacing_6x);padding-inline-start: var(--bui_spacing_6x);
        position: relative;
        top: auto;
        transform: none
    }

    .dbf3625cd9,.f424f05f2e {
        align-items: normal;
        bottom: auto;
        display: inline-block;
        max-height: none;
        max-width: 100vw;
        opacity: 0;
        right: auto;
        vertical-align: middle;
        z-index: var(--bui_z_index_4)
    }

    .dbf3625cd9 {
        --bui_sheet_container_inner_height: initial;
        --bui_sheet_container_inner_width: initial;
        --bui_sheet_container_inner_max-height: initial;
        --bui_sheet_container_content_padding: initial;
        --bui_sheet_container_heading_margin_block_end: initial;
        --bui_sheet_container_footer_position: initial;
        --bui_sheet_container_footer_bottom: initial;
        --bui_sheet_container_footer_background: initial;
        --bui_sheet_container_inner_border-radius-start-start: 0;
        --bui_sheet_container_inner_border-radius-start-end: 0;
        --bui_sheet_container_inner_border-radius-end-end: 0;
        --bui_sheet_container_inner_border-radius-end-start: 0;
        --bui_sheet_container_inner_border-radius-start-start_rtl: 0;
        --bui_sheet_container_inner_border-radius-start-end_rtl: 0;
        --bui_sheet_container_inner_border-radius-end-end_rtl: 0;
        --bui_sheet_container_inner_border-radius-end-start_rtl: 0;
        --bui_sheet_container_width: var( --bui_sheet_container_size_large_width,100% );
        --bui_sheet_container_footer_overflow: auto;
        --bui_sheet_container_body_height: 0;
        height: 100%;
        left: 0;
        padding-block-end:0;padding-block-start:0;padding-inline-end:0;padding-inline-start:0;position: absolute;
        top: 0;
        transform: translateY(var(--bui_spacing_6x))
    }

    .d65ab6d474 .dbf3625cd9,[dir=rtl] .dbf3625cd9 {
        left: auto;
        right: 0
    }
}

.adbc8765ed {
    --bui_sheet_container_size_large_width: calc(var(--bui_spacing_1x)*207)
}

.b79c2287fb {
    --bui_sheet_container_content_padding: var( --bui_sheet_container_content_padding_s,var(--bui_spacing_6x) );
    --bui_sheet_container_heading_margin_block_end: var( --bui_sheet_container_heading_margin_block_end_s,var(--bui_spacing_4x) )
}

.de4fbb6ed0 {
    --bui_sheet_container_fill_content_padding: 0
}

@media (max-width: 575px) {
    .b79c2287fb {
        --bui_sheet_container_content_padding_s:var(--bui_spacing_4x);
        --bui_sheet_container_heading_margin_block_end_s: var(--bui_spacing_2x)
    }
}

.c676dd76fe {
    opacity: 1;
    transition-duration: var(--bui_animation_fade_in_duration);
    transition-timing-function: var(--bui_animation_fade_in_timing_function)
}

.c676dd76fe,.d65ab6d474 .c676dd76fe,[dir=rtl] .c676dd76fe {
    transform: translate(0)
}

.b4db3523c5,.c6e9d1599e {
    position: relative
}

.c6e9d1599e {
    -ms-overflow-style: none;
    display: block;
    list-style: none;
    margin: 0;
    overflow: -moz-scrollbars-none;
    padding: 0;
    scrollbar-width: none
}

.c6e9d1599e::-webkit-scrollbar {
    display: none
}

.d37611a2e0 {
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: bottom
}

.c187c54338 {
    display: none
}

.b920e43bfe {
    display: block
}

.f3441ccb97 {
    align-items: center;
    background: none;
    border: 0;
    box-sizing: border-box;
    color: var(--bui_color_foreground);
    display: flex;
    font-family: var(--bui_font_emphasized_2_font-family);
    font-family: inherit;
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    justify-content: center;
    line-height: var(--bui_font_emphasized_2_line-height);
    max-width: none;
    outline: none;
    padding: var(--bui_spacing_4x);
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%
}

.d37611a2e0:not(.b4dfbcc93e) .f3441ccb97:after {
    background: #70bbe9;
    bottom: calc(var(--bui_border_width_100)*-1);
    content: "";
    display: none;
    height: var(--bui_border_width_200);
    left: 0;
    position: absolute;
    right: 0
}

.f3441ccb97:hover {
    background-color: var(--bui_color_highlighted_alt);
    cursor: pointer;
    text-decoration: none
}

.a861df9be1 .d37611a2e0:not(.b4dfbcc93e) .a9c42d2cff:after {
    color: var(--bui_color_action_foreground);
    display: block
}

[data-bui-keyboard] .f3441ccb97:focus {
    box-shadow: 0 0 0 2px var(--bui_color_background_base),0 0 0 4px #70bbe9;
    outline: none
}

.f3441ccb97:link {
    font-family: var(--bui_font_emphasized_2_font-family);
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height);
    text-decoration: none
}

.a9c42d2cff {
    color: var(--bui_color_action_foreground)
}

.a9c42d2cff:hover {
    background-color: transparent
}

.cda1d6440d,.cda1d6440d:active,.cda1d6440d:focus,.cda1d6440d:hover {
    background-color: transparent;
    color: var(--bui_color_foreground_disabled);
    cursor: not-allowed;
    outline: none
}

.dae4be712d {
    width: 100%
}

.ff511256ff {
    font-family: var(--bui_font_strong_2_font-family);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height)
}

.bfe53e640d,.ff511256ff {
    display: inline-block;
    margin-inline-end:var(--bui_spacing_2x);vertical-align: middle
}

.b3c76ca974 {
    display: none
}

.b4dfbcc93e .f3441ccb97 {
    margin: 0
}

.eed881e674 {
    margin-inline-start:var(--bui_spacing_1x)}

.b4dfbcc93e {
    display: none;
    margin: 0;
    position: relative
}

.ac2309e98f {
    display: inline-block
}

.cea5e9e78c .f3441ccb97 {
    flex-direction: column
}

.cea5e9e78c .ff511256ff {
    display: block;
    margin-inline-end:0;margin-inline-start:0}

.cea5e9e78c .bfe53e640d {
    display: block;
    margin-block-end:var(--bui_spacing_1x);margin-block-start: 0;
    margin-inline-end:auto;margin-inline-start:auto}

.cea5e9e78c .b4dfbcc93e .f3441ccb97:after {
    content: none
}

.cea5e9e78c .b4dfbcc93e .f3441ccb97:before {
    /* content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='3.375' cy='12.023' r='2.625' fill='none' stroke='currentcolor' stroke-width='1.5'/%3E%3Ccircle cx='20.625' cy='12.023' r='2.625' fill='none' stroke='currentcolor' stroke-width='1.5'/%3E%3Ccircle cx='12' cy='12.023' r='2.625' fill='none' stroke='currentcolor' stroke-width='1.5'/%3E%3C/svg%3E"); */
    display: block;
    height: var(--bui_spacing_4x);
    margin-block-end:var(--bui_spacing_1x);margin-block-start: 0;
    margin-inline-end:auto;margin-inline-start:auto;width: var(--bui_spacing_4x)
}

.b87d41ba67 .d37611a2e0:not(.b4dfbcc93e) .f3441ccb97:after {
    bottom: 0
}

.b87d41ba67 .b4db3523c5:before {
    content: none
}

.d3ce2af2cd .c6e9d1599e {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap
}

.d1b8520945 .d37611a2e0 .f3441ccb97:hover,.d1b8520945 .d0607887c7 .f3441ccb97 {
    background-color: transparent
}

.d1b8520945 .a9c42d2cff,.d1b8520945 .f3441ccb97 {
    color: currentcolor
}

.d1b8520945 .f3441ccb97:hover:before {
    background-color: currentcolor;
    content: "";
    height: 100%;
    left: 0;
    opacity: .06;
    position: absolute;
    width: 100%
}

.e8f39ee543 .d1b8520945 .f3441ccb97:hover:before,[dir=rtl] .d1b8520945 .f3441ccb97:hover:before {
    left: auto;
    right: 0
}

.d1b8520945.b73ab8cdf8 .d37611a2e0:not(.b4dfbcc93e) .a9c42d2cff:after {
    background-color: currentcolor;
    color: currentcolor
}

.a861df9be1 {
    margin-block-end:calc(var(--bui_border_width_100)*-1)}

.a861df9be1 before {
    background:var(--bui_color_border_alt);
    bottom: var(--bui_border_width_100);
    content: "";
    height: var(--bui_border_width_100);
    left: 0;
    position: absolute;
    right: 0
}

.a861df9be1 .c6e9d1599e {
    padding-block-end:var(--bui_border_width_200);padding-block-start: 0;
    padding-inline-end:0;padding-inline-start:0}

.f6dc3df772 {
    margin-block-end:calc(var(--bui_border_width_100)*-1)}

.f6dc3df772 .c6e9d1599e {
    padding-block-end: var(--bui_border_width_100)
}

.d48f1af7a3:not(.d3ce2af2cd) .f6dc3df772 {
    margin-inline-end:calc(var(--bui_spacing_4x)*-1);margin-inline-start: calc(var(--bui_spacing_4x)*-1)
}

.d48f1af7a3:not(.d3ce2af2cd) .f6dc3df772 .c6e9d1599e {
    padding-inline-end:var(--bui_spacing_4x);padding-inline-start: var(--bui_spacing_4x)
}

.f6dc3df772 .d37611a2e0:not(:first-child) {
    padding-inline-start:var(--bui_spacing_1x)}

.f6dc3df772 .d37611a2e0 .f3441ccb97 {
    border: var(--bui_border_width_100) solid transparent;
    border-radius: 100px;
    padding: calc(var(--bui_spacing_3x) - var(--bui_border_width_100)) var(--bui_spacing_4x)
}

.f6dc3df772 .d0607887c7 .f3441ccb97 {
    background-color: var(--bui_color_action_highlighted_alt);
    border-color: #70bbe9
}

.d1b8520945 .f6dc3df772 .d37611a2e0 .f3441ccb97:hover:before,.d1b8520945 .f6dc3df772 .d0607887c7 .f3441ccb97:before {
    background-color: currentcolor;
    border-radius: 100px;
    content: "";
    height: 100%;
    left: 0;
    opacity: .1;
    position: absolute;
    width: 100%
}

.e8f39ee543 .d1b8520945 .f6dc3df772 .d37611a2e0 .f3441ccb97:hover:before,.e8f39ee543 .d1b8520945 .f6dc3df772 .d0607887c7 .f3441ccb97:before,[dir=rtl] .d1b8520945 .f6dc3df772 .d37611a2e0 .f3441ccb97:hover:before,[dir=rtl] .d1b8520945 .f6dc3df772 .d0607887c7 .f3441ccb97:before {
    left: auto;
    right: 0
}

.d1b8520945 .f6dc3df772 .d0607887c7 .f3441ccb97 {
    background-color: transparent;
    border-color: currentcolor
}

.d1b8520945 .f6dc3df772 .a9c42d2cff,.d1b8520945 .f6dc3df772 .f3441ccb97 {
    color: currentcolor
}

@media (max-width: 575px) {
    .d48f1af7a3:not(.d3ce2af2cd) .b4db3523c5 {
        flex-wrap:nowrap;
        overflow: hidden;
        white-space: nowrap
    }

    .d48f1af7a3:not(.d3ce2af2cd) .c6e9d1599e {
        overflow-x: auto
    }

    .d48f1af7a3:not(.d3ce2af2cd).b4dfbcc93e {
        display: none
    }
}

.fc6dca6b24 {
    position: relative
}

.f06f94c1d8 {
    overflow: auto
}

.c77dacb70f {
    background: var(--bui_color_background_elevation_one);
    border: var(--bui_border_width_100) solid var(--bui_color_border_alt);
    border-collapse: separate;
    border-radius: var(--bui_border_radius_200);
    border-spacing: 0;
    width: 100%
}

.bf8f49b3ae,.d9c9873b22 .ec3a46e883:last-child .bf8f49b3ae {
    border-block-end:var(--bui_border_width_100) solid var(--bui_color_border_alt)}

.bf8f49b3ae {
    box-sizing: border-box;
    padding: var(--bui_spacing_4x);
    vertical-align: top
}

.f3b5d1b18a {
    font-family: var(--bui_font_strong_2_font-family);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height);
    text-align: start
}

.cad6284049 {
    padding-inline-end:0;padding-inline-start:var(--bui_spacing_4x);width: 0
}

.c599ddff50 {
    text-align: center
}

.d84ddbd0fb {
    text-align: end
}

.ec3a46e883:last-child .bf8f49b3ae {
    border-block-end:0}

.bb7d11a47d .bf8f49b3ae {
    vertical-align: middle
}

.b3de06af96 .bf8f49b3ae {
    display: none;
    padding-block-start:0}

.a85c6838db .bf8f49b3ae,.ab5ce1e3f6:nth-last-child(2) .bf8f49b3ae {
    border-block-end:0}

.a85c6838db+.b3de06af96 .bf8f49b3ae {
    display: table-cell
}

.a85c6838db .e5fbce23df {
    transform: rotate(180deg);
    transform-origin: 50% 50%
}

.a6ecc37d2a .a85c6838db .e5fbce23df,[dir=rtl] .a85c6838db .e5fbce23df {
    transform: rotate(-180deg)
}

.d1b1d1bd96 {
    background: var(--bui_color_action_background_alt)
}

.f3f41326b3 .c77dacb70f {
    background: transparent;
    border: 0
}

.f3f41326b3 .bf8f49b3ae:first-child {
    padding-inline-start:0}

.f3f41326b3 .bf8f49b3ae:last-child {
    padding-inline-end:0}

.f77db3fb95 .bf8f49b3ae {
    padding: var(--bui_spacing_2x)
}

.f77db3fb95 .cad6284049 {
    padding-inline-end:0;padding-inline-start:var(--bui_spacing_2x)}

.bb0f10507e .d9c9873b22 {
    background: var(--bui_color_background_elevation_two);
    position: sticky;
    top: 0;
    z-index: var(--bui_z_index_4)
}

.ebc6b37d58,[dir=rtl] .d586f3e29f {
    mask-image: linear-gradient(90deg,#000 0,#000 80%,rgba(0,0,0,.1) 95%,transparent);
    -webkit-mask-image: linear-gradient(90deg,#000 0,#000 80%,rgba(0,0,0,.1) 95%,transparent)
}

.d586f3e29f,[dir=rtl] .ebc6b37d58 {
    mask-image: linear-gradient(270deg,#000 0,#000 80%,rgba(0,0,0,.1) 95%,transparent);
    -webkit-mask-image: linear-gradient(270deg,#000 0,#000 80%,rgba(0,0,0,.1) 95%,transparent)
}

.a0367e0be1 {
    mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.1) 5%,#000 20%,#000 80%,rgba(0,0,0,.1) 95%,transparent);
    -webkit-mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.1) 5%,#000 20%,#000 80%,rgba(0,0,0,.1) 95%,transparent)
}

@media (min-width: 1024px) {
    .f06f94c1d8 {
        overflow:visible
    }
}

.aa0e7ac7ea {
    list-style: none;
    margin: 0;
    padding: 0
}

.e5263321c0 {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: var(--bui_spacing_1x) 0;
    position: absolute;
    top: 0;
    width: var(--bui_spacing_6x)
}

.f13a7f5966 .e5263321c0,[dir=rtl] .e5263321c0 {
    left: auto;
    right: 0
}

.e5263321c0:empty:before {
    border: var(--bui_border_width_200) solid;
    border-radius: 50%;
    box-sizing: border-box;
    content: "";
    display: block;
    height: var(--bui_spacing_3x);
    width: var(--bui_spacing_3x)
}

.e5263321c0:after {
    border-inline-start:var(--bui_border_width_100) solid var(--bui_color_border);content: "";
    flex-grow: 1;
    margin-block-start:var(--bui_spacing_2x);position: relative
}

.ff4311f886 {
    padding-block-end:var(--bui_spacing_6x);padding-inline-start: calc(var(--bui_spacing_1x)*10);
    position: relative
}

.ff4311f886:last-child {
    padding-block-end:0}

.ff4311f886:last-child .e5263321c0:after {
    content: none
}

.fa1574643f .e5263321c0:after {
    border-left-style: dashed
}

.f13a7f5966 .fa1574643f .e5263321c0:after,[dir=rtl] .fa1574643f .e5263321c0:after {
    border-left-style: none;
    border-right-style: dashed
}

.d2c9977847 {
    background: var(--bui_color_background_inverted);
    border-radius: var(--bui_border_radius_200);
    bottom: var(--bui_spacing_6x);
    box-shadow: var(--bui_shadow_100);
    color: var(--bui_color_foreground_inverted);
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    left: var(--bui_spacing_6x);
    line-height: var(--bui_font_body_2_line-height);
    padding: var(--bui_spacing_4x);
    position: fixed;
    width: calc(var(--bui_spacing_8x)*10);
    z-index: var(--bui_z_index_4)
}

.fa98fb946c .d2c9977847,[dir=rtl] .d2c9977847 {
    left: auto;
    right: var(--bui_spacing_6x)
}

.daf5df6fe1 {
    color: var(--bui_color_action_foreground_inverted);
    margin-inline-start:auto}

@media (max-width: 575px) {
    .d2c9977847 {
        bottom:var(--bui_spacing_4x);
        width: auto
    }

    .d2c9977847,.fa98fb946c .d2c9977847,[dir=rtl] .d2c9977847 {
        left: var(--bui_spacing_4x);
        right: var(--bui_spacing_4x)
    }
}

.c2d0e84e47 {
    opacity: 0;
    transform: translateY(100%)
}

.dd56a7ee86 {
    transition: var(--bui_animation_enter);
    transition-property: opacity,transform
}

.cecd778007,.feff06afe0:not(:last-child) {
    opacity: 0;
    pointer-events: none;
    transform: scale(.84)
}

.e3dbc8a4b5,.feff06afe0:not(:last-child) {
    transition: var(--bui_animation_exit);
    transition-property: opacity,transform
}

.a76f300d2e {
    background: var(--bui_color_background_inverted);
    border-radius: var(--bui_border_radius_100);
    box-shadow: var(--bui_shadow_100);
    box-sizing: border-box;
    color: var(--bui_color_foreground_inverted);
    display: inline-block;
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height);
    padding: var(--bui_spacing_1x) var(--bui_spacing_2x);
    pointer-events: none;
    position: relative
}

.b87605b550 {
    display: none
}

@media (max-width: 575px) {
    .dedd5eb617 {
        display:none
    }
}

@media (min-width: 576px) {
    .a76f300d2e {
        max-width:calc(var(--bui_spacing_8x)*10)
    }
}

:root {
    --bui_container: 1140px;
    --bui_z_index_0: 0;
    --bui_z_index_1: 100;
    --bui_z_index_2: 200;
    --bui_z_index_3: 300;
    --bui_z_index_4: 400;
    --bui_animation_duration: 0.15s;
    --bui_easing-slow-in: cubic-bezier(0,0,0.2,1);
    --bui_easing-slow-out: cubic-bezier(0.4,0,1,1);
    --bui_easing-slow-in-out: cubic-bezier(0.4,0,0.2,1);
    --bui_easing-subtle-in: cubic-bezier(0,0,0.2,1);
    --bui_easing-subtle-out: cubic-bezier(0.4,0,1,1);
    --bui_easing-subtle-in-out: cubic-bezier(0.4,0,0.2,1);
    --bui_easing-bounce-in: cubic-bezier(0.6,-0.28,0.735,0.045);
    --bui_easing-bounce-out: cubic-bezier(0.175,0.885,0.32,1.275);
    --bui_timing-instant: 100ms;
    --bui_timing-fast: 150ms;
    --bui_timing-deliberate: 250ms;
    --bui_timing-slow: 300ms;
    --bui_timing-slower: 600ms;
    --bui_timing-slowest: 1000ms;
    --bui_timing-paused: 1600ms
}

button,input,optgroup,select,textarea {
    color: var(--bui_color_foreground);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0
}

body {
    background-color: var(--bui_color_background_base);
    color: var(--bui_color_foreground);
    font-family: var(--bui_font_body_2_font-family);
    font-size: var(--bui_font_body_2_font-size);
    font-weight: var(--bui_font_body_2_font-weight);
    line-height: var(--bui_font_body_2_line-height);
    margin: 0
}

h1 {
    font-family: var(--bui_font_headline_2_font-family);
    font-size: var(--bui_font_headline_2_font-size);
    font-weight: var(--bui_font_headline_2_font-weight);
    line-height: var(--bui_font_headline_2_line-height)
}

h2 {
    font-family: var(--bui_font_headline_3_font-family);
    font-size: var(--bui_font_headline_3_font-size);
    font-weight: var(--bui_font_headline_3_font-weight);
    line-height: var(--bui_font_headline_3_line-height)
}

h3 {
    font-family: var(--bui_font_strong_1_font-family);
    font-size: var(--bui_font_strong_1_font-size);
    font-weight: var(--bui_font_strong_1_font-weight);
    line-height: var(--bui_font_strong_1_line-height)
}

h4,h5,h6 {
    font-family: var(--bui_font_strong_2_font-family);
    font-size: var(--bui_font_strong_2_font-size);
    font-weight: var(--bui_font_strong_2_font-weight);
    line-height: var(--bui_font_strong_2_line-height)
}

h1,h2,h3,h4,h5,h6 {
    margin-block-end:.67em}

h1:first-child,h2:first-child,h3:first-child,h4:first-child,h5:first-child,h6:first-child,p:first-child {
    margin-block-start:0}

h1:last-child,h2:last-child,h3:last-child,h4:last-child,h5:last-child,h6:last-child,p:last-child {
    margin-block-end:0}

:root,[data-bui-theme=legacy-light] {
    --bui_color_border_alt: #e7e7e7;
    --bui_color_action_border: #ff5c5c;
    --bui_color_border_disabled: #d9d9d9;
    --bui_color_destructive_border: #c00;
    --bui_color_constructive_border: #008009;
    --bui_color_background: #494949;
    --bui_color_background_rgb: 73,73,73;
    --bui_color_background_alt: #f2f2f2;
    --bui_color_background_alt_rgb: 242,242,242;
    --bui_color_background_base: #fff;
    --bui_color_background_base_rgb: 255,255,255;
    --bui_color_background_base_alt: #f2f2f2;
    --bui_color_background_base_alt_rgb: 242,242,242;
    --bui_color_background_disabled: #d9d9d9;
    --bui_color_background_disabled_rgb: 217,217,217;
    --bui_color_background_inverted: #171717;
    --bui_color_background_inverted_rgb: 23,23,23;
    --bui_color_accent_background_alt: #fdf4d8;
    --bui_color_accent_background_alt_rgb: 253,244,216;
    --bui_color_action_background_alt: #e4f4ff;
    --bui_color_action_background_alt_rgb: 228,244,255;
    --bui_color_callout_background_alt: #fff0e0;
    --bui_color_callout_background_alt_rgb: 255,240,224;
    --bui_color_background_disabled_alt: #f2f2f2;
    --bui_color_background_disabled_alt_rgb: 242,242,242;
    --bui_color_destructive_background_alt: #fff0f0;
    --bui_color_destructive_background_alt_rgb: 255,240,240;
    --bui_color_constructive_background_alt: #e7fde9;
    --bui_color_constructive_background_alt_rgb: 231,253,233;
    --bui_color_foreground: #262626;
    --bui_color_foreground_alt: #6b6b6b;
    --bui_color_accent_foreground: #8e6601;
    --bui_color_action_foreground: #ff5c5c;
    --bui_color_callout_foreground: #923e01;
    --bui_color_foreground_disabled: #949494;
    --bui_color_foreground_inverted: #f2f2f2;
    --bui_color_destructive_foreground: #c00;
    --bui_color_constructive_foreground: #008009;
    --bui_color_foreground_disabled_alt: #d9d9d9;
    --bui_color_brand_primary_foreground: #a9063c;
    --bui_color_action_foreground_inverted: #3daeff;
    --bui_color_highlighted: #cbcbcb;
    --bui_color_action_focus: rgba(0,113,194,0.24);
    --bui_color_highlighted_alt: rgba(0,0,0,0.06);
    --bui_color_destructive_focus: rgba(204,0,0,0.24);
    --bui_color_action_highlighted_alt: rgba(0,113,194,0.06);
    --bui_color_destructive_highlighted_alt: rgba(204,0,0,0.06);
    --bui_color_on_background: #fff;
    --bui_color_background_elevation_one: #fff;
    --bui_color_background_elevation_two: #fff;
    --bui_color_background_elevation_two_rgb: 255,255,255;
    --bui_color_accent_background_dynamic: #febb02;
    --bui_color_callout_background_dynamic: #ff8000;
    --bui_color_destructive_background_dynamic: #c00;
    --bui_color_constructive_background_dynamic: #008009;
    --bui_color_brand_primary_background_dynamic: #a1063a;
    --bui_color_on_accent_background_dynamic: #262626;
    --bui_color_on_callout_background_dynamic: #262626;
    --bui_color_on_destructive_background_dynamic: #fff;
    --bui_color_on_constructive_background_dynamic: #fff
}

[data-bui-theme=legacy-dark] {
    --bui_color_border_alt: #494949;
    --bui_color_action_border: #3daeff;
    --bui_color_border_disabled: #6b6b6b;
    --bui_color_destructive_border: #ff5c5c;
    --bui_color_constructive_border: #69ce6f;
    --bui_color_background: #d9d9d9;
    --bui_color_background_rgb: 217,217,217;
    --bui_color_background_alt: #262626;
    --bui_color_background_alt_rgb: 38,38,38;
    --bui_color_background_base: #000;
    --bui_color_background_base_rgb: 0,0,0;
    --bui_color_background_base_alt: #000;
    --bui_color_background_base_alt_rgb: 0,0,0;
    --bui_color_background_disabled: #494949;
    --bui_color_background_disabled_rgb: 73,73,73;
    --bui_color_background_inverted: #f2f2f2;
    --bui_color_background_inverted_rgb: 242,242,242;
    --bui_color_accent_background_alt: #3d2b01;
    --bui_color_accent_background_alt_rgb: 61,43,1;
    --bui_color_action_background_alt: #001e33;
    --bui_color_action_background_alt_rgb: 0,30,51;
    --bui_color_callout_background_alt: #3c1901;
    --bui_color_callout_background_alt_rgb: 60,25,1;
    --bui_color_background_disabled_alt: #262626;
    --bui_color_background_disabled_alt_rgb: 38,38,38;
    --bui_color_destructive_background_alt: #300;
    --bui_color_destructive_background_alt_rgb: 51,0,0;
    --bui_color_constructive_background_alt: #003303;
    --bui_color_constructive_background_alt_rgb: 0,51,3;
    --bui_color_foreground: #f2f2f2;
    --bui_color_foreground_alt: #d9d9d9;
    --bui_color_accent_foreground: #febb02;
    --bui_color_action_foreground: #3daeff;
    --bui_color_callout_foreground: #ff8000;
    --bui_color_foreground_disabled: #6b6b6b;
    --bui_color_foreground_inverted: #171717;
    --bui_color_destructive_foreground: #ff5c5c;
    --bui_color_constructive_foreground: #69ce6f;
    --bui_color_foreground_disabled_alt: #494949;
    --bui_color_brand_primary_foreground: #a3d7fc;
    --bui_color_action_foreground_inverted: #ff5c5c;
    --bui_color_highlighted: #434343;
    --bui_color_action_focus: rgba(61,174,255,0.24);
    --bui_color_highlighted_alt: hsla(0,0%,100%,0.12);
    --bui_color_destructive_focus: rgba(255,92,92,0.24);
    --bui_color_action_highlighted_alt: rgba(61,174,255,0.12);
    --bui_color_destructive_highlighted_alt: rgba(255,92,92,0.12);
    --bui_color_on_background: #262626;
    --bui_color_background_elevation_one: #171717;
    --bui_color_background_elevation_two: #262626;
    --bui_color_background_elevation_two_rgb: 38,38,38;
    --bui_color_accent_background_dynamic: #262626;
    --bui_color_callout_background_dynamic: #262626;
    --bui_color_destructive_background_dynamic: #262626;
    --bui_color_constructive_background_dynamic: #262626;
    --bui_color_brand_primary_background_dynamic: #262626;
    --bui_color_on_accent_background_dynamic: #febb02;
    --bui_color_on_callout_background_dynamic: #ff8000;
    --bui_color_on_destructive_background_dynamic: #ff5c5c;
    --bui_color_on_constructive_background_dynamic: #69ce6f
}

:root,[data-bui-theme=legacy-dark],[data-bui-theme=legacy-light] {
    --bui_color_black: #000;
    --bui_color_black_rgb: 0,0,0;
    --bui_color_white: #fff;
    --bui_color_white_rgb: 255,255,255;
    --bui_color_border: #949494;
    --bui_color_accent_border: #febb02;
    --bui_color_callout_border: #ff8000;
    --bui_color_transparent: transparent;
    --bui_color_cta_background: #ca3e6d;
    --bui_color_cta_background_rgb: 0,113,194;
    --bui_color_black_with_alpha: rgba(0,0,0,0.5);
    --bui_color_accent_background: #febb02;
    --bui_color_accent_background_rgb: 254,187,2;
    --bui_color_action_background: #e7326f;
    --bui_color_action_background_rgb: 0,113,194;
    --bui_color_callout_background: #ff8000;
    --bui_color_callout_background_rgb: 255,128,0;
    --bui_color_destructive_background: #c00;
    --bui_color_destructive_background_rgb: 204,0,0;
    --bui_color_constructive_background: #008009;
    --bui_color_constructive_background_rgb: 0,128,9;
    --bui_color_brand_primary_background: #70bbe9
    --bui_color_brand_primary_background_rgb: 0,53,128;
    --bui_color_brand_genius_primary_background: #d10d4f;
    --bui_color_brand_genius_primary_background_rgb: 0,76,184;
    --bui_color_brand_genius_secondary_foreground: #febb02;
    --bui_color_cta_highlighted: #70bbe9;
    --bui_color_action_highlighted: #70bbe9;
    --bui_color_destructive_highlighted: #8a0000;
    --bui_color_on_cta_background: #fff;
    --bui_color_on_accent_background: #262626;
    --bui_color_on_action_background: #fff;
    --bui_color_on_callout_background: #262626;
    --bui_color_on_destructive_background: #fff;
    --bui_color_on_constructive_background: #fff;
    --bui_color_on_brand_primary_background: #fff;
    --bui_color_on_brand_genius_primary_background: #fff;
    --bui_color_on_brand_primary_background_dynamic: #fff;
    --bui_shadow_100: 0px 2px 8px 0px rgba(0,0,0,0.16);
    --bui_shadow_200: 0px 2px 16px 0px rgba(0,0,0,0.24);
    --bui_shadow_300: 0px 4px 16px 0px rgba(0,0,0,0.32);
    --bui_spacing_1x: 4px;
    --bui_spacing_2x: 8px;
    --bui_spacing_3x: 12px;
    --bui_spacing_4x: 16px;
    --bui_spacing_6x: 24px;
    --bui_spacing_8x: 32px;
    --bui_spacing_12x: 48px;
    --bui_spacing_16x: 64px;
    --bui_spacing_24x: 96px;
    --bui_spacing_half: 2px;
    --bui_border_width_100: 1px;
    --bui_border_width_200: 2px;
    --bui_border_radius_100: 2px;
    --bui_border_radius_200: 2px;
    --bui_border_radius_300: 2px;
    --bui_animation_exit: 0.12s cubic-bezier(0.2,0,1,0.8);
    --bui_animation_exit_duration: 0.12s;
    --bui_animation_exit_timing_function: cubic-bezier(0.2,0,1,0.8);
    --bui_animation_enter: 0.12s cubic-bezier(0,0,0.4,0.8);
    --bui_animation_enter_duration: 0.12s;
    --bui_animation_enter_timing_function: cubic-bezier(0,0,0.4,0.8);
    --bui_animation_hover: 0.12s cubic-bezier(0.2,0,0.4,0.8);
    --bui_animation_hover_duration: 0.12s;
    --bui_animation_hover_timing_function: cubic-bezier(0.2,0,0.4,0.8);
    --bui_animation_press: 0.12s cubic-bezier(0.2,0,0.4,0.8);
    --bui_animation_press_duration: 0.12s;
    --bui_animation_press_timing_function: cubic-bezier(0.2,0,0.4,0.8);
    --bui_animation_appear: 0.24s cubic-bezier(0,0,0.4,1);
    --bui_animation_appear_duration: 0.24s;
    --bui_animation_appear_timing_function: cubic-bezier(0,0,0.4,1);
    --bui_animation_fade_in: 0.24s cubic-bezier(0,0,0.4,1);
    --bui_animation_fade_in_duration: 0.24s;
    --bui_animation_fade_in_timing_function: cubic-bezier(0,0,0.4,1);
    --bui_animation_fade_out: 0.12s cubic-bezier(1,0,1,1);
    --bui_animation_fade_out_duration: 0.12s;
    --bui_animation_fade_out_timing_function: cubic-bezier(1,0,1,1);
    --bui_animation_disappear: 0.12s cubic-bezier(0.2,0,1,0.8);
    --bui_animation_disappear_duration: 0.12s;
    --bui_animation_disappear_timing_function: cubic-bezier(0.2,0,1,0.8);
    --bui_animation_page_transition_exit: 0.48s cubic-bezier(1,0,1,1);
    --bui_animation_page_transition_exit_duration: 0.48s;
    --bui_animation_page_transition_exit_timing_function: cubic-bezier(1,0,1,1);
    --bui_animation_page_transition_enter: 0.48s cubic-bezier(0,0,0.4,1);
    --bui_animation_page_transition_enter_duration: 0.48s;
    --bui_animation_page_transition_enter_timing_function: cubic-bezier(0,0,0.4,1);
    --bui_font_body_1_font-size: 16px;
    --bui_font_body_1_font-weight: 400;
    --bui_font_body_1_line-height: 24px;
    --bui_font_body_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_body_2_font-size: 14px;
    --bui_font_body_2_font-weight: 400;
    --bui_font_body_2_line-height: 20px;
    --bui_font_body_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_small_1_font-size: 12px;
    --bui_font_small_1_font-weight: 400;
    --bui_font_small_1_line-height: 18px;
    --bui_font_small_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_small_2_font-size: 10px;
    --bui_font_small_2_font-weight: 500;
    --bui_font_small_2_line-height: 16px;
    --bui_font_small_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_strong_1_font-size: 16px;
    --bui_font_strong_1_font-weight: 700;
    --bui_font_strong_1_line-height: 24px;
    --bui_font_strong_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_strong_2_font-size: 14px;
    --bui_font_strong_2_font-weight: 700;
    --bui_font_strong_2_line-height: 20px;
    --bui_font_strong_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_display_1_font-size: 40px;
    --bui_font_display_1_font-weight: 700;
    --bui_font_display_1_line-height: 52px;
    --bui_font_display_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_display_2_font-size: 36px;
    --bui_font_display_2_font-weight: 700;
    --bui_font_display_2_line-height: 48px;
    --bui_font_display_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_display_3_font-size: 32px;
    --bui_font_display_3_font-weight: 700;
    --bui_font_display_3_line-height: 40px;
    --bui_font_display_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_featured_1_font-size: 20px;
    --bui_font_featured_1_font-weight: 400;
    --bui_font_featured_1_line-height: 28px;
    --bui_font_featured_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_featured_2_font-size: 20px;
    --bui_font_featured_2_font-weight: 400;
    --bui_font_featured_2_line-height: 28px;
    --bui_font_featured_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_featured_3_font-size: 20px;
    --bui_font_featured_3_font-weight: 400;
    --bui_font_featured_3_line-height: 28px;
    --bui_font_featured_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_headline_1_font-size: 28px;
    --bui_font_headline_1_font-weight: 700;
    --bui_font_headline_1_line-height: 36px;
    --bui_font_headline_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_headline_2_font-size: 24px;
    --bui_font_headline_2_font-weight: 700;
    --bui_font_headline_2_line-height: 32px;
    --bui_font_headline_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_headline_3_font-size: 20px;
    --bui_font_headline_3_font-weight: 700;
    --bui_font_headline_3_line-height: 28px;
    --bui_font_headline_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_emphasized_1_font-size: 16px;
    --bui_font_emphasized_1_font-weight: 500;
    --bui_font_emphasized_1_line-height: 24px;
    --bui_font_emphasized_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    --bui_font_emphasized_2_font-size: 14px;
    --bui_font_emphasized_2_font-weight: 500;
    --bui_font_emphasized_2_line-height: 20px;
    --bui_font_emphasized_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif
}

@media (min-width: 576px) {
    :root,[data-bui-theme=legacy-dark],[data-bui-theme=legacy-light] {
        --bui_font_body_1_font-size:16px;
        --bui_font_body_1_font-weight: 400;
        --bui_font_body_1_line-height: 24px;
        --bui_font_body_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_body_2_font-size: 14px;
        --bui_font_body_2_font-weight: 400;
        --bui_font_body_2_line-height: 20px;
        --bui_font_body_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_small_1_font-size: 12px;
        --bui_font_small_1_font-weight: 400;
        --bui_font_small_1_line-height: 18px;
        --bui_font_small_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_small_2_font-size: 12px;
        --bui_font_small_2_font-weight: 500;
        --bui_font_small_2_line-height: 18px;
        --bui_font_small_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_strong_1_font-size: 16px;
        --bui_font_strong_1_font-weight: 700;
        --bui_font_strong_1_line-height: 24px;
        --bui_font_strong_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_strong_2_font-size: 14px;
        --bui_font_strong_2_font-weight: 700;
        --bui_font_strong_2_line-height: 20px;
        --bui_font_strong_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_1_font-size: 64px;
        --bui_font_display_1_font-weight: 700;
        --bui_font_display_1_line-height: 72px;
        --bui_font_display_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_2_font-size: 56px;
        --bui_font_display_2_font-weight: 700;
        --bui_font_display_2_line-height: 64px;
        --bui_font_display_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_3_font-size: 48px;
        --bui_font_display_3_font-weight: 700;
        --bui_font_display_3_line-height: 62px;
        --bui_font_display_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_1_font-size: 32px;
        --bui_font_featured_1_font-weight: 400;
        --bui_font_featured_1_line-height: 40px;
        --bui_font_featured_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_2_font-size: 24px;
        --bui_font_featured_2_font-weight: 400;
        --bui_font_featured_2_line-height: 32px;
        --bui_font_featured_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_3_font-size: 20px;
        --bui_font_featured_3_font-weight: 400;
        --bui_font_featured_3_line-height: 28px;
        --bui_font_featured_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_1_font-size: 32px;
        --bui_font_headline_1_font-weight: 700;
        --bui_font_headline_1_line-height: 40px;
        --bui_font_headline_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_2_font-size: 24px;
        --bui_font_headline_2_font-weight: 700;
        --bui_font_headline_2_line-height: 32px;
        --bui_font_headline_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_3_font-size: 20px;
        --bui_font_headline_3_font-weight: 700;
        --bui_font_headline_3_line-height: 28px;
        --bui_font_headline_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_emphasized_1_font-size: 16px;
        --bui_font_emphasized_1_font-weight: 500;
        --bui_font_emphasized_1_line-height: 24px;
        --bui_font_emphasized_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_emphasized_2_font-size: 14px;
        --bui_font_emphasized_2_font-weight: 500;
        --bui_font_emphasized_2_line-height: 20px;
        --bui_font_emphasized_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif
    }
}

@media (min-width: 1024px) {
    :root,[data-bui-theme=legacy-dark],[data-bui-theme=legacy-light] {
        --bui_font_body_1_font-size:16px;
        --bui_font_body_1_font-weight: 400;
        --bui_font_body_1_line-height: 24px;
        --bui_font_body_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_body_2_font-size: 14px;
        --bui_font_body_2_font-weight: 400;
        --bui_font_body_2_line-height: 20px;
        --bui_font_body_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_small_1_font-size: 12px;
        --bui_font_small_1_font-weight: 400;
        --bui_font_small_1_line-height: 18px;
        --bui_font_small_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_small_2_font-size: 12px;
        --bui_font_small_2_font-weight: 500;
        --bui_font_small_2_line-height: 18px;
        --bui_font_small_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_strong_1_font-size: 16px;
        --bui_font_strong_1_font-weight: 700;
        --bui_font_strong_1_line-height: 24px;
        --bui_font_strong_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_strong_2_font-size: 14px;
        --bui_font_strong_2_font-weight: 700;
        --bui_font_strong_2_line-height: 20px;
        --bui_font_strong_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_1_font-size: 96px;
        --bui_font_display_1_font-weight: 700;
        --bui_font_display_1_line-height: 108px;
        --bui_font_display_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_2_font-size: 64px;
        --bui_font_display_2_font-weight: 700;
        --bui_font_display_2_line-height: 72px;
        --bui_font_display_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_3_font-size: 48px;
        --bui_font_display_3_font-weight: 700;
        --bui_font_display_3_line-height: 62px;
        --bui_font_display_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_1_font-size: 32px;
        --bui_font_featured_1_font-weight: 400;
        --bui_font_featured_1_line-height: 40px;
        --bui_font_featured_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_2_font-size: 24px;
        --bui_font_featured_2_font-weight: 400;
        --bui_font_featured_2_line-height: 32px;
        --bui_font_featured_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_3_font-size: 20px;
        --bui_font_featured_3_font-weight: 400;
        --bui_font_featured_3_line-height: 28px;
        --bui_font_featured_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_1_font-size: 32px;
        --bui_font_headline_1_font-weight: 700;
        --bui_font_headline_1_line-height: 40px;
        --bui_font_headline_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_2_font-size: 24px;
        --bui_font_headline_2_font-weight: 700;
        --bui_font_headline_2_line-height: 32px;
        --bui_font_headline_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_3_font-size: 20px;
        --bui_font_headline_3_font-weight: 700;
        --bui_font_headline_3_line-height: 28px;
        --bui_font_headline_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_emphasized_1_font-size: 16px;
        --bui_font_emphasized_1_font-weight: 500;
        --bui_font_emphasized_1_line-height: 24px;
        --bui_font_emphasized_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_emphasized_2_font-size: 14px;
        --bui_font_emphasized_2_font-weight: 500;
        --bui_font_emphasized_2_line-height: 20px;
        --bui_font_emphasized_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif
    }
}

.f8bde84211 {
    padding: var(--bui_spacing_2x) var(--bui_spacing_4x);
    border-radius: var(--bui_spacing_half);
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    border: 1px solid color(var(--bui_color_border) l(75%))
}

.a596d1fffe:hover:after {
    content: "→";
    position: absolute;
    margin-left: 5px
}

.rtl .a596d1fffe:hover:after,[dir=rtl] .a596d1fffe:hover:after {
    content: "←";
    position: absolute;
    margin-right: 5px;
    margin-left: 0
}

.bui-button.f73a8d977d {
    background: transparent;
    color: var(--bui_color_white);
    border: 1px solid var(--bui_color_white)
}

.bui-button.f73a8d977d:not(.f256e81e24):hover {
    background: hsla(0,0%,100%,.1);
    text-decoration: none;
    color: var(--bui_color_white);
    border: 1px solid var(--bui_color_white)
}

.bui-button.f73a8d977d:not(.f256e81e24):active {
    background: hsla(0,0%,100%,.2);
    text-decoration: none;
    color: var(--bui_color_white)
}

.bui-button.f73a8d977d:not(.f256e81e24):focus {
    text-decoration: none;
    box-shadow: 0 0 0 1px color(var(--bui_color_action_border) l(68%));
    outline: none;
    color: var(--bui_color_white);
    border: 1px solid color(var(--bui_color_action_border) l(68%))
}

.b55ddba7e7 {
    padding-top: 72px;
    padding-bottom: 72px
}

.b58f626213 {
    padding-top: 72px
}

.b28393abaf {
    background-color: #f9f9f9
}

@media screen and (max-width: 575px) {
    .e2ad95c8dd.b28393abaf {
        background-color:transparent
    }

    .e2ad95c8dd .b55ddba7e7 {
        padding-top: 0;
        padding-bottom: 0
    }

    .e2ad95c8dd .b55ddba7e7.e4715ac4e6 {
        padding-top: var(--bui_spacing_8x)
    }
}

.d33b85a65c .bui-title__text,.d33b85a65c.bui-title--display-one .bui-title__text {
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height);
    font-family: var(--bui_font_body_1_font-family);
    color: var(--bui_color_foreground)
}

.fdc29c9dfc .bui-title__text {
    color: var(--bui_color_foreground);
    font-weight: 700
}

.bc02a6a0d5:after {
    content: " ";
    height: 1px;
    background-color: var(--bui_color_border_alt);
    position: absolute;
    left: var(--bui_spacing_6x);
    right: var(--bui_spacing_6x)
}

.bui-spacer--smaller {
    margin-bottom: var(--bui_spacing_half)
}

.bui-spacer--small {
    margin-bottom: var(--bui_spacing_1x)
}

.bui-spacer--medium {
    margin-bottom: var(--bui_spacing_2x)
}

.bui-spacer--large {
    margin-bottom: var(--bui_spacing_4x)
}

.bui-spacer--larger {
    margin-bottom: var(--bui_spacing_6x)
}

.bui-spacer--largest {
    margin-bottom: var(--bui_spacing_8x)
}

.ab64ff081b {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: var(--bui_font_display_3_font-size);
    font-weight: var(--bui_font_display_3_font-weight);
    line-height: var(--bui_font_display_3_line-height);
    font-family: var(--bui_font_display_3_font-family)
}

@media (min-width: 992px) {
    .ab64ff081b:lang(bg),.ab64ff081b:lang(ru) {
        font-size:var(--bui_font_headline_1_font-size)
    }
}

.f560139817 {
    height: var(--bui_font_display_3_line-height);
    overflow: hidden;
    position: relative
}

@media (max-width: 992px) {
    .ab64ff081b {
        font-size:var(--bui_font_headline_1_font-size);
        font-weight: var(--bui_font_headline_1_font-weight);
        line-height: var(--bui_font_headline_1_line-height);
        font-family: var(--bui_font_headline_1_font-family)
    }

    .f560139817 {
        height: var(--bui_font_headline_1_line-height)
    }
}

@media (max-width: 767px) {
    .ab64ff081b {
        font-size:var(--bui_font_headline_2_font-size);
        font-weight: var(--bui_font_headline_2_font-weight);
        line-height: var(--bui_font_headline_2_line-height);
        font-family: var(--bui_font_headline_2_font-family)
    }

    .f560139817 {
        height: var(--bui_font_headline_2_line-height)
    }
}

.b7cbbbdb07 {
    overflow: hidden;
    color: #0095ff;
    margin: 0
}

.b9bac14a73,.f6925559df {
    transition: transform 1s linear;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    transform: translateY(0);
    will-change: transform
}

.b9bac14a73 {
    animation-duration: 12s;
    animation-name: ca062393d2
}

@keyframes ca062393d2 {
    0% {
        transform: translateY(0)
    }

    18% {
        transform: translateY(0)
    }

    20% {
        transform: translateY(calc(var(--bui_font_display_3_line-height)*-1))
    }

    38% {
        transform: translateY(calc(var(--bui_font_display_3_line-height)*-1))
    }

    40% {
        transform: translateY(calc(var(--bui_font_display_3_line-height)*-2))
    }

    58% {
        transform: translateY(calc(var(--bui_font_display_3_line-height)*-2))
    }

    60% {
        transform: translateY(calc(var(--bui_font_display_3_line-height)*-3))
    }

    68% {
        transform: translateY(calc(var(--bui_font_display_3_line-height)*-3))
    }

    70% {
        transform: translateY(calc(var(--bui_font_display_3_line-height)*-4))
    }

    to {
        transform: translateY(calc(var(--bui_font_display_3_line-height)*-4))
    }
}

@media (max-width: 992px) {
    @keyframes ca062393d2 {
        0% {
            transform:translateY(0)
        }

        18% {
            transform: translateY(0)
        }

        20% {
            transform: translateY(calc(var(--bui_font_headline_1_line-height)*-1))
        }

        38% {
            transform: translateY(calc(var(--bui_font_headline_1_line-height)*-1))
        }

        40% {
            transform: translateY(calc(var(--bui_font_headline_1_line-height)*-2))
        }

        58% {
            transform: translateY(calc(var(--bui_font_headline_1_line-height)*-2))
        }

        60% {
            transform: translateY(calc(var(--bui_font_headline_1_line-height)*-3))
        }

        68% {
            transform: translateY(calc(var(--bui_font_headline_1_line-height)*-3))
        }

        70% {
            transform: translateY(calc(var(--bui_font_headline_1_line-height)*-4))
        }

        to {
            transform: translateY(calc(var(--bui_font_headline_1_line-height)*-4))
        }
    }
}

@media (max-width: 767px) {
    @keyframes ca062393d2 {
        0% {
            transform:translateY(0)
        }

        18% {
            transform: translateY(0)
        }

        20% {
            transform: translateY(calc(var(--bui_font_headline_2_line-height)*-1))
        }

        38% {
            transform: translateY(calc(var(--bui_font_headline_2_line-height)*-1))
        }

        40% {
            transform: translateY(calc(var(--bui_font_headline_2_line-height)*-2))
        }

        58% {
            transform: translateY(calc(var(--bui_font_headline_2_line-height)*-2))
        }

        60% {
            transform: translateY(calc(var(--bui_font_headline_2_line-height)*-3))
        }

        68% {
            transform: translateY(calc(var(--bui_font_headline_2_line-height)*-3))
        }

        70% {
            transform: translateY(calc(var(--bui_font_headline_2_line-height)*-4))
        }

        to {
            transform: translateY(calc(var(--bui_font_headline_2_line-height)*-4))
        }
    }
}

.f6925559df {
    animation-duration: 8s;
    animation-name: c719db48cf
}

@keyframes c719db48cf {
    0% {
        transform: translateY(0)
    }

    33% {
        transform: translateY(0)
    }

    35% {
        transform: translateY(-56px)
    }

    68% {
        transform: translateY(-56px)
    }

    70% {
        transform: translateY(-112px)
    }

    to {
        transform: translateY(-112px)
    }
}

@media (max-width: 992px) {
    @keyframes c719db48cf {
        0% {
            transform:translateY(0)
        }

        33% {
            transform: translateY(0)
        }

        35% {
            transform: translateY(-38px)
        }

        68% {
            transform: translateY(-38px)
        }

        70% {
            transform: translateY(-76px)
        }

        to {
            transform: translateY(-76px)
        }
    }
}

@media (max-width: 767px) {
    @keyframes c719db48cf {
        0% {
            transform:translateY(0)
        }

        33% {
            transform: translateY(0)
        }

        35% {
            transform: translateY(-28px)
        }

        68% {
            transform: translateY(-28px)
        }

        70% {
            transform: translateY(-56px)
        }

        to {
            transform: translateY(-56px)
        }
    }
}

.f42f308029 {
    position: relative
}

.a1a525e0a9 div {
    color: #0095ff;
    display: block;
    height: 56px;
    overflow: hidden
}

@media (max-width: 992px) {
    .a1a525e0a9 div {
        height:38px
    }
}

@media (max-width: 767px) {
    .a1a525e0a9 div {
        height:28px
    }
}

.d62ced7487 {
    display: block;
    animation: aa9b09c972 .6s linear
}

.bcb3b5354a {
    display: none
}

@keyframes aa9b09c972 {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.b46bcee73c {
    border: none;
    background-color: transparent
}

.b46bcee73c {
    padding-bottom: 32px
}

.d680da777a {
    border-top: 1px solid #e6e6e6
}

.e86905e34c {
    margin-bottom: var(--bui_spacing_8x)
}

.e86905e34c>li {
    margin-bottom: var(--bui_spacing_8x);
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height);
    font-family: var(--bui_font_body_1_font-family)
}

.f52b8baa3a {
    flex-direction: column-reverse
}

@media (min-width: 1024px) {
    .f52b8baa3a {
        flex-direction:row
    }
}

.fadc31e111 {
    padding: var(--bui_spacing_6x)
}

.e1564ae89a {
    margin-top: var(--bui_spacing_16x);
    background-color: var(--bui_color_background_alt);
    border: none;
    border-radius: var(--bui_spacing_1x);
    overflow: visible
}

.e1564ae89a .d85af7a83d {
    padding: var(--bui_spacing_8x) var(--bui_spacing_12x)
}

@media (min-width: 576px) {
    .d85af7a83d {
        padding:var(--bui_spacing_12x)
    }

    .e1564ae89a {
        background-color: var(--bui_color_background_base);
        border: 1px solid var(--bui_color_border_alt);
        margin-top: var(--bui_spacing_6x)
    }
}

.cd60990c9a {
    margin: calc(var(--bui_spacing_12x)*-1) var(--bui_spacing_6x) 0
}

.cd60990c9a img {
    border-radius: var(--bui_spacing_1x)
}

.c906e3f5b9 {
    margin-bottom: var(--bui_spacing_16x)
}

.e859295c4e {
    font-size: 48px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: var(--bui_spacing_4x)
}

.a8733e45e9 {
    height: 100px;
    display: flex;
    align-items: flex-end
}

.e146229a08 {
    height: var(--bui_spacing_24x);
    display: flex;
    align-items: flex-end
}

.e146229a08 img,.e146229a08 picture {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.c0ef612066 {
    font-size: var(--bui_font_headline_2_font-size);
    font-weight: var(--bui_font_headline_2_font-weight);
    line-height: var(--bui_font_headline_2_line-height);
    font-family: var(--bui_font_headline_2_font-family)
}

.e1de4e5848 {
    font-size: var(--bui_font_body_1_font-size);
    font-weight: var(--bui_font_body_1_font-weight);
    line-height: var(--bui_font_body_1_line-height);
    font-family: var(--bui_font_body_1_font-family);
    color: var(--bui_color_foreground)
}

.ddee1a3b1a {
    margin-block-end:var(--bui_spacing_12x)}

@media (min-width: 576px) {
    .ddee1a3b1a {
        margin-block-end:0
    }
}

.b434f8317c {
    margin-bottom: var(--bui_spacing_6x)
}

.b67a4cccf1 .bui-carousel__item:not(:last-child) {
    padding: 0 32px 0 0
}

.b67a4cccf1 .bui-carousel__item:not(:first-child) {
    padding: 0 0 0 32px
}

.fd87c57de4 {
    width: 90vw
}

.fd87c57de4.d5af00c6f7 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.e9c7692607 {
    text-align: center
}

.b93dc7af55 {
    background: no-repeat 50% #fff;
    background-size: contain;
    overflow: hidden
}

.b93dc7af55.f1acb1eb99 {
    background-size: cover
}

.fde160114a {
    max-width: 340px
}

.d022d313ae {
    background-color: var(--bui_color_brand_primary_background);
    color: var(--bui_color_on_brand_primary_background);
    -webkit-font-smoothing: antialiased;
    padding-top: 34px;
    padding-bottom: 18px
}

.c4f6cfa80a {
    text-align: center;
    padding: 0 0 var(--bui_spacing_6x) 0;
    margin: 0;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500
}

@media screen and (min-width: 992px) {
    .c4f6cfa80a {
        text-align:left;
        font-size: 48px;
        line-height: 64px;
        padding: 0
    }

    .rtl .c4f6cfa80a,[dir=rtl] .c4f6cfa80a {
        text-align: right
    }
}

.a9cf2ea607 {
    position: relative
}

.fa1e8d74e8 .d97e163068 .bui-title__text {
    font-size: 12px;
    line-height: 20px;
    color: var(--bui_color_black);
    padding: 0 0 var(--bui_spacing_1x) 0;
    margin: 0 0 var(--bui_spacing_1x) 0;
    border-bottom: 1px solid rgba(0,0,0,.07);
    font-weight: 700
}

.fa1e8d74e8 .bui-list__icon {
    margin-right: var(--bui_spacing_half)
}

.fa1e8d74e8 .c5a47e9549 {
    padding: 12px var(--bui_spacing_4x);
    font-size: 14px;
    line-height: 20px
}

.fa1e8d74e8 .c5a47e9549 .bui-button__icon,.fa1e8d74e8 .c5a47e9549 .bui-icon {
    width: var(--bui_spacing_6x);
    height: 9px
}

.fa1e8d74e8 .c5a47e9549:hover {
    background-color: #006bb8
}

.fa1e8d74e8 .c5a47e9549 svg {
    position: relative;
    transition: right .2s;
    right: 0
}

.fa1e8d74e8 .c5a47e9549:hover svg {
    right: -5px
}

.rtl .fa1e8d74e8 .c5a47e9549 svg,[dir=rtl] .fa1e8d74e8 .c5a47e9549 svg {
    position: relative;
    right: auto;
    left: 0;
    transition: left .2s
}

.rtl .fa1e8d74e8 .c5a47e9549:hover svg,[dir=rtl] .fa1e8d74e8 .c5a47e9549:hover svg {
    left: -5px;
    right: auto
}

.fa1e8d74e8 .deea9d9d1b .d9dea29fc0 {
    margin-bottom: 4px
}

.fa1e8d74e8 .deea9d9d1b .d9dea29fc0 .bui-title__text {
    font-size: 16px;
    line-height: 18px
}

.fa1e8d74e8 .a80c7ce9a0 .bui-title__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px
}

.fa1e8d74e8 .cb5c18b3f6 {
    margin-bottom: 24px
}

.fa1e8d74e8 .a45c6fc2d3 {
    padding: 0;
    margin: 0;
    min-height: auto;
    color: #0091fd;
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px
}

.fa1e8d74e8 .a45c6fc2d3:hover {
    background-color: transparent;
    text-decoration: none
}

.a3a6b7d90c {
    margin-top: var(--bui_spacing_4x);
    margin-bottom: var(--bui_spacing_2x);
    line-height: 18px;
    font-size: 12px
}

.b135aa957f {
    margin-left: -22px;
    margin-right: -22px
}

.b6e5a2224d {
    position: fixed;
    bottom: var(--bui_spacing_2x);
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 3
}

.ef21a40c13 {
    transition: transform .2s ease-in-out;
    display: none
}

.b23e962816,.f4672fbd61 {
    transform: translateY(150%)
}

.b23e962816,.e548bf90f3,.f095d313c2 {
    display: block
}

.cbfa22e9ce {
    margin: 0;
    min-height: auto;
    color: #0091fd;
    text-decoration: underline;
    font-size: var(--bui_font_emphasized_2_font-size);
    font-weight: var(--bui_font_emphasized_2_font-weight);
    line-height: var(--bui_font_emphasized_2_line-height);
    font-family: var(--bui_font_emphasized_2_font-family)
}

.cbfa22e9ce:hover {
    background-color: transparent;
    text-decoration: none
}

.cbfa22e9ce.a9df6f852d {
    padding: 0
}

.f9fe1a2a7b {
    max-height: 190px;
    overflow-y: scroll
}

.a38b68625c {
    border: none
}

.a38b68625c tr {
    border: none
}

.a38b68625c .bui-table__head {
    display: none
}

.c22b69a6d9,.d76732e1d4 {
    vertical-align: middle;
    padding: var(--bui_spacing_2x) 0;
    border-bottom: 1px solid rgba(0,0,0,.07);
    display: table-cell
}

.d76732e1d4 {
    padding-left: var(--bui_spacing_1x)
}

@media screen and (max-width: 575px) {
    .c22b69a6d9 {
        border-left:0;
        border-right: 0;
        width: 70%
    }

    .d76732e1d4 {
        border-left: 0;
        border-right: 0
    }
}

.db8e858d2a {
    color: var(--bui_color_black);
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0
}

.c5b9f712e8 {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    color: var(--bui_color_foreground_alt);
    margin-top: var(--bui_spacing_1x)
}

.ef6753444c {
    width: 416px;
    margin: 0 0 0 auto;
    color: var(--bui_color_foreground)
}

.rtl .ef6753444c,[dir=rtl] .ef6753444c {
    margin: 0 auto 0 0
}

@media (max-width: 512px) {
    .ef6753444c {
        width:100%
    }
}

@media (max-width: 992px) {
    .ef6753444c {
        margin:0 auto
    }
}

.eb11e57202 {
    padding: 22px;
    background-color: var(--bui_color_white);
    overflow: visible;
    border-radius: 3px;
    border: 1px solid #f2f2f2
}

.bd7a4497c4 {
    background-color: #fff0f0!important;
    color: #a30000!important
}

.ab124e27cc {
    text-decoration: underline
}

.c65992143f {
    margin-top: 24px
}

.f5acccdbf4 {
    padding-left: 0;
    padding-right: 0
}

.ed632ba876 {
    margin-top: var(--bui_spacing_4x);
    color: var(--bui_color_foreground)
}

.ed632ba876 ul {
    padding-left: var(--bui_spacing_4x)
}

.f3de505a8e {
    margin-bottom: 16px
}

.c5f3e2ceb4 {
    margin-top: 16px
}

.f4c73d7de5 {
    padding-left: 0;
    padding-right: 0
}

.afdeeb05f7 {
    overflow-wrap: break-word
}

.dd79e10289 {
    background-color: var(--bui_color_brand_primary_background);
    color: var(--bui_color_on_brand_primary_background);
    -webkit-font-smoothing: antialiased;
    padding-bottom: var(--bui_spacing_6x)
}

.c562685b25,.c562685b25:active,.c562685b25:hover,.c562685b25:visited {
    color: var(--bui_color_white)
}

a.c562685b25,a.c562685b25:visited {
    text-decoration: underline!important
}

.c562685b25:hover {
    text-decoration: none!important
}

.aa9d8a0299 {
    flex-direction: row
}

@media (max-width: 575px) {
    .db009d0c7a {
        display:none
    }
}

.bd3f7e9411 .bui-list {
    display: flex
}

.bd3f7e9411 .bui-list__item {
    flex-basis: auto;
    margin: 0
}

@media (-ms-high-contrast:none),screen and (-ms-high-contrast:active) {
    .bd3f7e9411 .bui-list__item .bui-list__description {
        flex: none
    }
}

.bd3f7e9411 .a5d2c11452 {
    flex-direction: row-reverse
}

.e6e7fb77cd:before {
    content: "|"
}

.f3a782eef9 {
    padding-top: var(--bui_spacing_2x)
}

.c2a131c87f {
    border-color: hsla(0,0%,100%,.5)
}

@media (max-width: 575px) {
    .bd3f7e9411 .bui-list {
        flex-direction:column
    }

    .bd3f7e9411 .bui-list__item {
        flex-basis: 100%;
        margin-bottom: 10px
    }

    .e6e7fb77cd {
        display: none
    }

    .bd3f7e9411 .a5d2c11452 {
        flex-direction: row
    }
}

@media (min-width: 576px) {
    .a99d90c992 {
        align-items:flex-end
    }
}

.a5e23de06b .bui-accordion {
    border-left: 0;
    border-right: 0
}

.a5e23de06b .bui-accordion__row-inner:active,.a5e23de06b .bui-accordion__row-inner:focus,.a5e23de06b .bui-accordion__row-inner:hover,.a5e23de06b .bui-accordion__row-inner:visited {
    background-color: transparent
}

.a5e23de06b .bui-accordion__title {
    font-size: 16px;
    line-height: 24px
}

.a5e23de06b .bui-accordion__content {
    font-size: 14px;
    line-height: 20px
}

@media screen and (min-width: 992px) {
    .a5e23de06b .bui-accordion__row-inner {
        padding-left:0;
        padding-right: 0
    }

    .a5e23de06b .bui-accordion__content {
        padding-left: 0;
        padding-right: 0
    }

    .a5e23de06b .bui-accordion__title {
        font-size: 20px;
        line-height: 28px
    }

    .a5e23de06b .bui-accordion__content {
        font-size: 16px;
        line-height: 24px
    }
}

@media screen and (max-width: 991px) {
    .a5e23de06b div:not(:first-child) {
        padding-top:0;
        margin-top: -1px
    }
}

.b76e26fa97 {
    padding-top: var(--bui_spacing_4x)
}

.f054b6e97f {
    background-color: var(--bui_color_brand_primary_background_dynamic);
    overflow: hidden;
    padding: 0px 0
}

.f71e209ea8 {
    background-size: cover;
    background-position: 0
}

.a0188c155f {
    background-color: var(--bui_color_background_alt);
    color: var(--bui_color_black)
}

.c277eab6bf {
    display: table;
    width: 100%
}

.c0d0e448a8 {
    display: table-cell;
    padding-right: 160px;
    vertical-align: middle
}

.rtl .c0d0e448a8,[dir=rtl] .c0d0e448a8 {
    padding-right: 0;
    padding-left: 60px
}

.f33f32dcf3 {
    display: table-cell;
    vertical-align: middle
}

.ab307327fc {
    z-index: 3;
    position: relative
}

@media (max-width: 992px) {
    .c277eab6bf {
        display:block
    }

    .c0d0e448a8 {
        display: block;
        padding-right: 0;
        text-align: center
    }

    .rtl .c0d0e448a8,[dir=rtl] .c0d0e448a8 {
        padding-left: 0
    }

    .f33f32dcf3 {
        display: block
    }
}

.be54f7e491 {
    color: var(--bui_color_white)
}

.daedef9356 {
    font-size: 32px;
    line-height: 40px
}

.a0188c155f .be54f7e491 {
    color: var(--bui_color_black)
}

.b14e7782c1 {
    color: #ebf3ff;
    font-size: 22px;
    line-height: 28px;
    margin-top: var(--bui_spacing_2x);
    padding-bottom: var(--bui_spacing_2x);
    font-weight: 500
}

.a0188c155f .b14e7782c1 {
    color: var(--bui_color_black)
}

@media (max-width: 992px) {
    .b14e7782c1 {
        padding-bottom:var(--bui_spacing_6x)
    }
}

.d4a27efaa4 {
    color: var(--bui_color_white);
    font-size: var(--bui_font_headline_2_font-size);
    font-weight: var(--bui_font_headline_2_font-weight);
    line-height: var(--bui_font_headline_2_line-height);
    font-family: var(--bui_font_headline_2_font-family);
    font-weight: 400
}

.b6b18f3e4d,.b6b18f3e4d:focus {
    color: #bdbdbd;
    border-bottom: 1px solid var(--bui_color_border)
}

.b6b18f3e4d:visited {
    color: var(--bui_color_white)
}

.b6b18f3e4d:hover {
    text-decoration: none;
    color: #6b6b6b
}

.b96604507f,.b96604507f:hover,.b96604507f:visited {
    color: currentColor;
    text-decoration: underline
}

.ff616b441d {
    text-align: center
}

.e5840f6f1b {
    position: relative
}

.b8eb689e26 {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.ad9e95ae64 {
    box-shadow: 0 0 var(--bui_spacing_2x) rgba(0,0,0,.4)
}

.ad9e95ae64 td {
    padding: 2px;
    width: 25%
}

.ad9e95ae64 .c2e67fd05d {
    background-color: var(--bui_color_white);
    padding: var(--bui_spacing_2x) var(--bui_spacing_4x) var(--bui_spacing_2x) var(--bui_spacing_6x);
    text-align: left;
    justify-content: left;
    width: 100%;
    border: 0;
    color: var(--bui_color_black)
}

.ad9e95ae64 .c2e67fd05d:hover {
    background: url(https://q.bstatic.com/static/img/join/join-icon-check-gray.svg) no-repeat 0 transparent;
    background-size: var(--bui_spacing_4x) auto;
    color: var(--bui_color_black)
}

.ad9e95ae64 .c2e67fd05d .bui-button__text {
    color: inherit;
    width: 100%
}

.ad9e95ae64 .c2e67fd05d .bui-flag__text {
    padding-left: var(--bui_spacing_1x)
}

.ad9e95ae64 .fdb9b8c75d {
    background: url(https://q.bstatic.com/static/img/join/join-icon-check-black.svg) no-repeat 0 transparent;
    background-size: var(--bui_spacing_4x) auto
}

.d34ae6b3f9 {
    width: calc(100vw - var(--bui_spacing_8x));
    max-width: 900px
}

.ca7f27ae60 {
    color: var(--bui_color_black);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
    padding: var(--bui_spacing_6x) var(--bui_spacing_6x) 0 var(--bui_spacing_6x)
}

.abda8a042f {
    margin: var(--bui_spacing_6x)
}

.ce7550ed39 thead {
    display: none
}

.ce7550ed39,.ce7550ed39 td,.ce7550ed39 tr {
    border: 0
}

.f4cce87389 {
    background-color: transparent;
    border: none;
    display: flex
}

.f4cce87389.ba0646f747 {
    padding: 0
}

.f4cce87389:not(.aaa6794ccc):active,.f4cce87389:not(.aaa6794ccc):hover {
    background-color: transparent;
    border: none
}

.f4cce87389 .bui-button__icon {
    width: auto;
    height: 20px
}

.dd57287a35 {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-position: 0 0;
    background-size: cover
}

.e59e2b47c2 {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-position: 0 0;
    background-size: cover
}

.flag_ar {
    background-image: url(//static.booking.com/static/img/flags/24/arab_league.png)
}

.flag_bg {
    background-image: url(//static.booking.com/static/img/flags/24/bg.png)
}

.flag_ca {
    background-image: url(//static.booking.com/static/img/flags/24/catalonia.png)
}

.flag_cs {
    background-image: url(//static.booking.com/static/img/flags/24/cz.png)
}

.flag_da {
    background-image: url(//static.booking.com/static/img/flags/24/dk.png)
}

.flag_de {
    background-image: url(//static.booking.com/static/img/flags/24/de.png)
}

.flag_el {
    background-image: url(//static.booking.com/static/img/flags/24/gr.png)
}

.flag_en,.flag_en-gb,.flag_gb {
    background-image: url(//static.booking.com/static/img/flags/24/gb.png)
}

.flag_en-us,.flag_xu {
    background-image: url(//static.booking.com/static/img/flags/24/us.png)
}

.flag_es,.flag_es-mx {
    background-image: url(//static.booking.com/static/img/flags/24/es.png)
}

.flag_es-ar,.flag_xa {
    background-image: url(//static.booking.com/static/img/flags/24/ar.png)
}

.flag_et {
    background-image: url(//static.booking.com/static/img/flags/24/ee.png)
}

.flag_fi {
    background-image: url(//static.booking.com/static/img/flags/24/fi.png)
}

.flag_fr,.flag_fr-ca {
    background-image: url(//static.booking.com/static/img/flags/24/fr.png)
}

.flag_he {
    background-image: url(//static.booking.com/static/img/flags/24/il.png)
}

.flag_hr {
    background-image: url(//static.booking.com/static/img/flags/24/hr.png)
}

.flag_hu {
    background-image: url(//static.booking.com/static/img/flags/24/hu.png)
}

.flag_id {
    background-image: url(//static.booking.com/static/img/flags/24/id.png)
}

.flag_is {
    background-image: url(//static.booking.com/static/img/flags/24/is.png)
}

.flag_it {
    background-image: url(//static.booking.com/static/img/flags/24/it.png)
}

.flag_ja {
    background-image: url(//static.booking.com/static/img/flags/24/jp.png)
}

.flag_ko {
    background-image: url(//static.booking.com/static/img/flags/24/kr.png)
}

.flag_lt {
    background-image: url(//static.booking.com/static/img/flags/24/lt.png)
}

.flag_lv {
    background-image: url(//static.booking.com/static/img/flags/24/lv.png)
}

.flag_ms {
    background-image: url(//static.booking.com/static/img/flags/24/my.png)
}

.flag_nl {
    background-image: url(//static.booking.com/static/img/flags/24/nl.png)
}

.flag_no {
    background-image: url(//static.booking.com/static/img/flags/24/no.png)
}

.flag_pl {
    background-image: url(//static.booking.com/static/img/flags/24/pl.png)
}

.flag_tl {
    background-image: url(//static.booking.com/static/img/flags/24/ph.png)
}

.flag_pt-br,.flag_xb {
    background-image: url(//static.booking.com/static/img/flags/24/br.png)
}

.flag_pt,.flag_pt-pt {
    background-image: url(//static.booking.com/static/img/flags/24/pt.png)
}

.flag_ro {
    background-image: url(//static.booking.com/static/img/flags/24/ro.png)
}

.flag_ru {
    background-image: url(//static.booking.com/static/img/flags/24/ru.png)
}

.flag_sk {
    background-image: url(//static.booking.com/static/img/flags/24/sk.png)
}

.flag_sl {
    background-image: url(//static.booking.com/static/img/flags/24/si.png)
}

.flag_sr {
    background-image: url(//static.booking.com/static/img/flags/24/rs.png)
}

.flag_sv {
    background-image: url(//static.booking.com/static/img/flags/24/se.png)
}

.flag_th {
    background-image: url(//static.booking.com/static/img/flags/24/th.png)
}

.flag_tr {
    background-image: url(//static.booking.com/static/img/flags/24/tr.png)
}

.flag_uk {
    background-image: url(//static.booking.com/static/img/flags/24/ua.png)
}

.flag_vi {
    background-image: url(//static.booking.com/static/img/flags/24/vn.png)
}

.flag_zh,.flag_zh-cn {
    background-image: url(//static.booking.com/static/img/flags/24/cn.png)
}

.flag_xt,.flag_zh-tw {
    background-image: url(//static.booking.com/static/img/flags/24/z4.png)
}

.b2ba06b52d {
    border: var(--bui_border_width_100) solid var(--bui_color_border_alt)
}

.c65f254cc3 button {
    text-align: left
}

.c65f254cc3 .bui-title__text {
    color: var(--bui_color_foreground);
    font-size: 20px;
    margin-bottom: var(--bui_spacing_2x)
}

.c65f254cc3 .bui-title__subtitle {
    font-size: 14px;
    line-height: 20px;
    color: var(--bui_color_foreground)
}

.c65f254cc3 .cde0038a54 {
    display: block
}

.c65f254cc3 .cde0038a54>div {
    display: table-cell;
    vertical-align: middle
}

.c65f254cc3 .cde0038a54>div:first-child {
    padding-right: var(--bui_spacing_2x)
}

.c65f254cc3 .bui-list__item {
    border-bottom: 1px solid var(--bui_color_border_alt)
}

.c65f254cc3 .bui-divider {
    border-top: 1px solid var(--bui_color_border_alt)
}

.cc0340fe12 {
    min-width: 54px;
    min-height: 38px
}

.cc0340fe12 .bui-button__icon {
    width: 20px;
    height: 20px
}

.cc0340fe12 .bui-icon {
    width: 20px;
    height: 20px
}

.bdccd3740e {
    cursor: pointer
}

.bdccd3740e .f10af5e920 {
    display: block;
    margin-top: calc(var(--bui_spacing_1x)*-1)
}

.db43c8e881 .bdccd3740e {
    font-size: 12px;
    padding-left: var(--bui_spacing_4x)
}

.db43c8e881 .bdccd3740e .bui-icon {
    width: var(--bui_spacing_6x);
    height: var(--bui_spacing_6x)
}

.a78229c9df {
    color: var(--bui_color_foreground_inverted);
    border: 1px solid var(--bui_color_border_alt)
}

.fc9773a9bb {
    position: absolute;
    left: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0
}

@media (min-width: 576px) {
    .fc9773a9bb {
        margin:0 var(--bui_spacing_3x)
    }
}

.afc3183e98 {
    z-index: 3
}

.rtl .fc9773a9bb,[dir=rtl] .fc9773a9bb {
    right: 0;
    left: inherit
}

.dc8b5a1467 {
    padding: 6px 10px;
    border-radius: 3px;
    border: 1px solid var(--bui_color_constructive_border);
    box-shadow: 0 0 5px 0 rgba(0,27,66,.5);
    background: var(--bui_color_constructive_background);
    color: var(--bui_color_white);
    position: relative;
    font-size: 13px;
    font-weight: 700;
    line-height: 24px
}

.dc8b5a1467:before {
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid var(--bui_color_constructive_border);
    top: -9px;
    left: 20px
}

.rtl .dc8b5a1467:before,[dir=rtl] .dc8b5a1467:before {
    left: inherit;
    right: 20px
}

.f910da2602 {
    padding: var(--bui_spacing_2x);
    border-radius: var(--bui_spacing_half)
}

.bef99bf958,.c4dc6e1fe4 {
    opacity: 0;
    transform: translateY(-50%)
}

.c1eb877ee0,.f2854ff3f2 {
    transition: .5s ease-in;
    transform: translateY(0);
    opacity: 1
}

.b9148cc5c0,.b9b6788ad2 {
    opacity: 1
}

.af36686211 {
    opacity: 1
}

.e9c51c2259 {
    opacity: 0;
    transform: translateY(50%);
    transition: .5s ease-in
}

.b707179efb {
    opacity: 0;
    transform: translateY(50%);
    display: none
}

.f7c51dd6d5 {
    position: relative;
    background-color: var(--bui_color_brand_primary_background_dynamic)
}

.f9312f3c17 {
    display: inline-block;
    padding: var(--bui_spacing_2x) 0
}

@media (min-width: 576px) and (min-width:1024px) {
    .f9312f3c17 {
        margin:0 calc(var(--bui-spacing_4x)*-1)
    }
}

.ff9ee5c6b4 {
    z-index: 3
}

.f12fb3dfe2 {
    -webkit-font-smoothing: antialiased
}

.f12fb3dfe2 .bui-header {
    background-color: var(--bui_color_brand_primary_background)
}

.f12fb3dfe2 .bui-header__tab {
    display: none
}

.f12fb3dfe2 .bui-header__bar {
    max-width: 1170px;
    padding: 5px var(--bui_spacing_6x);
    position: relative
}

@media screen and (min-width: 480px) {
    .f12fb3dfe2 .bui-header {
        background-color:#00224f
    }

    .f12fb3dfe2 .bui-header__bar {
        max-width: 1170px;
        padding: 14px var(--bui_spacing_6x)
    }
}

@media screen and (min-width: 1171px) {
    .f12fb3dfe2 .bui-header__bar {
        padding:14px 0
    }
}

.f12fb3dfe2 img.a049b55c68 {
    width: 150px;
    height: auto
}

.f12fb3dfe2 img.b7d5589ada {
    width: 96px;
    padding-top: 5px
}

@media (min-width: 480px) {
    .f12fb3dfe2 img.b7d5589ada {
        padding-top:5px
    }
}

.ac0c65648f>nav {
    position: relative;
    box-sizing: border-box
}

@media (min-width: 1024px) {
    .ac0c65648f>nav {
        padding-right:var(--bui_spacing_4x);
        padding-left: var(--bui_spacing_4x)
    }
}

.b4ea8459d6 {
    background: var(--bui_color_brand_primary_background_dynamic);
    color: var(--bui_color_on_brand_primary_background_dynamic);
    padding: var(--bui_spacing_2x) var(--bui_spacing_4x) 0
}

.b4ea8459d6.ddd5130c85 {
    background: transparent;
    color: var(--bui_color_white)
}

.c20fd9b542 {
    display: flex;
    align-items: center;
    padding: 0 0 var(--bui_spacing_2x)
}

.f61b048379 {
    padding: 0 0 var(--bui_spacing_2x)
}

.f9966e6860 {
    flex-grow: 1;
    padding: var(--bui_spacing_2x) var(--bui_spacing_4x) var(--bui_spacing_2x) 0
}

.a945757098 img,.a945757098 svg {
    display: block;
    height: calc(var(--bui_spacing_1x)*5)
}

@media (min-width: 1024px) {
    .a945757098 img,.a945757098 svg {
        height:calc(var(--bui_spacing_1x)*6)
    }

    .c20fd9b542,.f61b048379 {
        max-width: calc(var(--bui_container) - var(--bui_spacing_4x)*2);
        margin: 0 auto;
        box-sizing: border-box
    }

    .c20fd9b542 {
        padding: var(--bui_spacing_1x) var(--bui_spacing_4x) var(--bui_spacing_2x)
    }

    .f61b048379 {
        padding-bottom: var(--bui_spacing_3x)
    }
}

.f575b79502 {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: rgba(0,27,66,.8);
    display: none
}

.a6c3079e90,.f12acbe25c,.f5424b3cc0 {
    background: rgba(0,27,66,0);
    display: block
}

.cd2bd21d87,.dbadb17561,.e4f2afc6ee {
    background: rgba(0,27,66,.8);
    transition: background .2s ease-out
}

.bce79121d8,.bdb33e05a9,.de85f70e47 {
    background: rgba(0,27,66,.8);
    display: block
}

.f12acbe25c {
    display: none
}

.bf2ba84b96 {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 20px;
    text-align: center
}

.f5557352e0,.f5e769ca39 {
    transform: translateY(100px);
    opacity: 0
}

.a75eb9af94,.f62c78d0d8 {
    transition: transform .3s ease-in .2s,opacity .2s .3s
}

.a75eb9af94,.c1b259b3bf,.c977c7983f,.f62c78d0d8 {
    transform: translateY(0);
    opacity: 1
}

.d2bbc21e29 {
    opacity: 1;
    transform: (0)
}

.e69bbaf3ed {
    transition: transform .3s ease-in .2s,opacity .2s .3s
}

.e62fed1ada,.e69bbaf3ed {
    transform: translateY(100px);
    opacity: 0
}

.fbe108bca7 {
    color: var(--bui_color_white);
    margin-bottom: var(--bui_spacing_2x)
}

.e498a9e662 {
    z-index: 3;
    position: relative
}

.cbb7009d32 {
    margin-bottom: var(--bui_spacing_12x)
}

.cfe4c165dc {
    padding-top: var(--bui_spacing_12x);
    padding-bottom: var(--bui_spacing_12x)
}

.efd3b6bf6a {
    background-color: var(--bui_color_foreground_inverted)
}

@media (min-width: 576px) {
    .cfe4c165dc {
        padding-top:var(--bui_spacing_24x)
    }
}

.ca953a4857 {
    margin-block-end:var(--bui_spacing_8x)}

    :root,[data-bui-theme=legacy-light] {
        --bui_color_border_alt: #e7e7e7;
        --bui_color_action_border: #0071c2;
        --bui_color_border_disabled: #d9d9d9;
        --bui_color_destructive_border: #c00;
        --bui_color_constructive_border: #008009;
        --bui_color_background: #494949;
        --bui_color_background_rgb: 73,73,73;
        --bui_color_background_alt: #f2f2f2;
        --bui_color_background_alt_rgb: 242,242,242;
        --bui_color_background_base: #fff;
        --bui_color_background_base_rgb: 255,255,255;
        --bui_color_background_base_alt: #f2f2f2;
        --bui_color_background_base_alt_rgb: 242,242,242;
        --bui_color_background_disabled: #d9d9d9;
        --bui_color_background_disabled_rgb: 217,217,217;
        --bui_color_background_inverted: #171717;
        --bui_color_background_inverted_rgb: 23,23,23;
        --bui_color_accent_background_alt: #fdf4d8;
        --bui_color_accent_background_alt_rgb: 253,244,216;
        --bui_color_action_background_alt: #e4f4ff;
        --bui_color_action_background_alt_rgb: 228,244,255;
        --bui_color_callout_background_alt: #fff0e0;
        --bui_color_callout_background_alt_rgb: 255,240,224;
        --bui_color_background_disabled_alt: #f2f2f2;
        --bui_color_background_disabled_alt_rgb: 242,242,242;
        --bui_color_destructive_background_alt: #fff0f0;
        --bui_color_destructive_background_alt_rgb: 255,240,240;
        --bui_color_constructive_background_alt: #e7fde9;
        --bui_color_constructive_background_alt_rgb: 231,253,233;
        --bui_color_foreground: #262626;
        --bui_color_foreground_alt: #6b6b6b;
        --bui_color_accent_foreground: #8e6601;
        --bui_color_action_foreground: #0071c2;
        --bui_color_callout_foreground: #923e01;
        --bui_color_foreground_disabled: #949494;
        --bui_color_foreground_inverted: #f2f2f2;
        --bui_color_destructive_foreground: #c00;
        --bui_color_constructive_foreground: #008009;
        --bui_color_foreground_disabled_alt: #d9d9d9;
        --bui_color_brand_primary_foreground: #70bbe9;
        --bui_color_action_foreground_inverted: #3daeff;
        --bui_color_highlighted: #cbcbcb;
        --bui_color_action_focus: rgba(0,113,194,.24);
        --bui_color_highlighted_alt: rgba(0,0,0,.06);
        --bui_color_destructive_focus: rgba(204,0,0,.24);
        --bui_color_action_highlighted_alt: rgba(0,113,194,.06);
        --bui_color_destructive_highlighted_alt: rgba(204,0,0,.06);
        --bui_color_on_background: #fff;
        --bui_color_background_elevation_one: #fff;
        --bui_color_background_elevation_two: #fff;
        --bui_color_background_elevation_two_rgb: 255,255,255;
        --bui_color_accent_background_dynamic: #febb02;
        --bui_color_callout_background_dynamic: #ff8000;
        --bui_color_destructive_background_dynamic: #c00;
        --bui_color_constructive_background_dynamic: #008009;
        --bui_color_brand_primary_background_dynamic: #70bbe9;
        --bui_color_on_accent_background_dynamic: #262626;
        --bui_color_on_callout_background_dynamic: #262626;
        --bui_color_on_destructive_background_dynamic: #fff;
        --bui_color_on_constructive_background_dynamic: #fff
    }
    
    [data-bui-theme=legacy-dark] {
        --bui_color_border_alt: #494949;
        --bui_color_action_border: #3daeff;
        --bui_color_border_disabled: #6b6b6b;
        --bui_color_destructive_border: #ff5c5c;
        --bui_color_constructive_border: #69ce6f;
        --bui_color_background: #d9d9d9;
        --bui_color_background_rgb: 217,217,217;
        --bui_color_background_alt: #262626;
        --bui_color_background_alt_rgb: 38,38,38;
        --bui_color_background_base: #000;
        --bui_color_background_base_rgb: 0,0,0;
        --bui_color_background_base_alt: #000;
        --bui_color_background_base_alt_rgb: 0,0,0;
        --bui_color_background_disabled: #494949;
        --bui_color_background_disabled_rgb: 73,73,73;
        --bui_color_background_inverted: #f2f2f2;
        --bui_color_background_inverted_rgb: 242,242,242;
        --bui_color_accent_background_alt: #3d2b01;
        --bui_color_accent_background_alt_rgb: 61,43,1;
        --bui_color_action_background_alt: #001e33;
        --bui_color_action_background_alt_rgb: 0,30,51;
        --bui_color_callout_background_alt: #3c1901;
        --bui_color_callout_background_alt_rgb: 60,25,1;
        --bui_color_background_disabled_alt: #262626;
        --bui_color_background_disabled_alt_rgb: 38,38,38;
        --bui_color_destructive_background_alt: #300;
        --bui_color_destructive_background_alt_rgb: 51,0,0;
        --bui_color_constructive_background_alt: #003303;
        --bui_color_constructive_background_alt_rgb: 0,51,3;
        --bui_color_foreground: #f2f2f2;
        --bui_color_foreground_alt: #d9d9d9;
        --bui_color_accent_foreground: #febb02;
        --bui_color_action_foreground: #3daeff;
        --bui_color_callout_foreground: #ff8000;
        --bui_color_foreground_disabled: #6b6b6b;
        --bui_color_foreground_inverted: #171717;
        --bui_color_destructive_foreground: #ff5c5c;
        --bui_color_constructive_foreground: #69ce6f;
        --bui_color_foreground_disabled_alt: #494949;
        --bui_color_brand_primary_foreground: #a3d7fc;
        --bui_color_action_foreground_inverted: #0071c2;
        --bui_color_highlighted: #434343;
        --bui_color_action_focus: rgba(61,174,255,.24);
        --bui_color_highlighted_alt: hsla(0,0%,100%,.12);
        --bui_color_destructive_focus: rgba(255,92,92,.24);
        --bui_color_action_highlighted_alt: rgba(61,174,255,.12);
        --bui_color_destructive_highlighted_alt: rgba(255,92,92,.12);
        --bui_color_on_background: #262626;
        --bui_color_background_elevation_one: #171717;
        --bui_color_background_elevation_two: #262626;
        --bui_color_background_elevation_two_rgb: 38,38,38;
        --bui_color_accent_background_dynamic: #262626;
        --bui_color_callout_background_dynamic: #262626;
        --bui_color_destructive_background_dynamic: #262626;
        --bui_color_constructive_background_dynamic: #262626;
        --bui_color_brand_primary_background_dynamic: #262626;
        --bui_color_on_accent_background_dynamic: #febb02;
        --bui_color_on_callout_background_dynamic: #ff8000;
        --bui_color_on_destructive_background_dynamic: #ff5c5c;
        --bui_color_on_constructive_background_dynamic: #69ce6f
    }
    
    :root,[data-bui-theme=legacy-dark],[data-bui-theme=legacy-light] {
        --bui_color_black: #000;
        --bui_color_black_rgb: 0,0,0;
        --bui_color_white: #fff;
        --bui_color_white_rgb: 255,255,255;
        --bui_color_border: #949494;
        --bui_color_accent_border: #febb02;
        --bui_color_callout_border: #ff8000;
        --bui_color_transparent: transparent;
        --bui_color_cta_background: #0071c2;
        --bui_color_cta_background_rgb: 0,113,194;
        --bui_color_black_with_alpha: rgba(0,0,0,.5);
        --bui_color_accent_background: #febb02;
        --bui_color_accent_background_rgb: 254,187,2;
        --bui_color_action_background: #0071c2;
        --bui_color_action_background_rgb: 0,113,194;
        --bui_color_callout_background: #ff8000;
        --bui_color_callout_background_rgb: 255,128,0;
        --bui_color_destructive_background: #c00;
        --bui_color_destructive_background_rgb: 204,0,0;
        --bui_color_constructive_background: #008009;
        --bui_color_constructive_background_rgb: 0,128,9;
        --bui_color_brand_primary_background: #70bbe9;
        --bui_color_brand_primary_background_rgb: 0,53,128;
        --bui_color_brand_genius_primary_background: #004cb8;
        --bui_color_brand_genius_primary_background_rgb: 0,76,184;
        --bui_color_brand_genius_secondary_foreground: #febb02;
        --bui_color_cta_highlighted: #00487a;
        --bui_color_action_highlighted: #00487a;
        --bui_color_destructive_highlighted: #af0e19;
        --bui_color_on_cta_background: #fff;
        --bui_color_on_accent_background: #262626;
        --bui_color_on_action_background: #fff;
        --bui_color_on_callout_background: #262626;
        --bui_color_on_destructive_background: #fff;
        --bui_color_on_constructive_background: #fff;
        --bui_color_on_brand_primary_background: #fff;
        --bui_color_on_brand_genius_primary_background: #fff;
        --bui_color_on_brand_primary_background_dynamic: #fff;
        --bui_shadow_100: 0px 2px 8px 0px rgba(0,0,0,.16);
        --bui_shadow_200: 0px 2px 16px 0px rgba(0,0,0,.24);
        --bui_shadow_300: 0px 4px 16px 0px rgba(0,0,0,.32);
        --bui_spacing_1x: 4px;
        --bui_spacing_2x: 8px;
        --bui_spacing_3x: 12px;
        --bui_spacing_4x: 16px;
        --bui_spacing_6x: 24px;
        --bui_spacing_8x: 32px;
        --bui_spacing_12x: 48px;
        --bui_spacing_16x: 64px;
        --bui_spacing_24x: 96px;
        --bui_spacing_half: 2px;
        --bui_border_width_100: 1px;
        --bui_border_width_200: 2px;
        --bui_border_radius_100: 2px;
        --bui_border_radius_200: 2px;
        --bui_border_radius_300: 2px;
        --bui_animation_exit_duration: var(--bui_animation_reduced_motion_duration,0.12s);
        --bui_animation_exit_timing_function: cubic-bezier(0.2,0,1,0.8);
        --bui_animation_exit: var(--bui_animation_exit_duration) var(--bui_animation_exit_timing_function);
        --bui_animation_enter_duration: var(--bui_animation_reduced_motion_duration,0.12s);
        --bui_animation_enter_timing_function: cubic-bezier(0,0,0.4,0.8);
        --bui_animation_enter: var(--bui_animation_enter_duration) var(--bui_animation_enter_timing_function);
        --bui_animation_hover_duration: var(--bui_animation_reduced_motion_duration,0.12s);
        --bui_animation_hover_timing_function: cubic-bezier(0.2,0,0.4,0.8);
        --bui_animation_hover: var(--bui_animation_hover_duration) var(--bui_animation_hover_timing_function);
        --bui_animation_press_duration: var(--bui_animation_reduced_motion_duration,0.12s);
        --bui_animation_press_timing_function: cubic-bezier(0.2,0,0.4,0.8);
        --bui_animation_press: var(--bui_animation_press_duration) var(--bui_animation_press_timing_function);
        --bui_animation_appear_duration: var(--bui_animation_reduced_motion_duration,0.24s);
        --bui_animation_appear_timing_function: cubic-bezier(0,0,0.4,1);
        --bui_animation_appear: var(--bui_animation_appear_duration) var(--bui_animation_appear_timing_function);
        --bui_animation_fade_in_duration: var(--bui_animation_reduced_motion_duration,0.24s);
        --bui_animation_fade_in_timing_function: cubic-bezier(0,0,0.4,1);
        --bui_animation_fade_in: var(--bui_animation_fade_in_duration) var(--bui_animation_fade_in_timing_function);
        --bui_animation_fade_out_duration: var(--bui_animation_reduced_motion_duration,0.12s);
        --bui_animation_fade_out_timing_function: cubic-bezier(1,0,1,1);
        --bui_animation_fade_out: var(--bui_animation_fade_out_duration) var(--bui_animation_fade_out_timing_function);
        --bui_animation_disappear_duration: var(--bui_animation_reduced_motion_duration,0.12s);
        --bui_animation_disappear_timing_function: cubic-bezier(0.2,0,1,0.8);
        --bui_animation_disappear: var(--bui_animation_disappear_duration) var(--bui_animation_disappear_timing_function);
        --bui_animation_page_transition_exit_duration: var(--bui_animation_reduced_motion_duration,0.48s);
        --bui_animation_page_transition_exit_timing_function: cubic-bezier(1,0,1,1);
        --bui_animation_page_transition_exit: var(--bui_animation_page_transition_exit_duration) var(--bui_animation_page_transition_exit_timing_function);
        --bui_animation_page_transition_enter_duration: var(--bui_animation_reduced_motion_duration,0.48s);
        --bui_animation_page_transition_enter_timing_function: cubic-bezier(0,0,0.4,1);
        --bui_animation_page_transition_enter: var(--bui_animation_page_transition_enter_duration) var(--bui_animation_page_transition_enter_timing_function)
    }
    
    @media (prefers-reduced-motion) {
        :root {
            --bui_animation_reduced_motion_duration: 1ms
        }
    }
    
    :root,[data-bui-theme=legacy-dark],[data-bui-theme=legacy-light] {
        --bui_font_body_1_font-size: 16px;
        --bui_font_body_1_font-weight: 400;
        --bui_font_body_1_line-height: 24px;
        --bui_font_body_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_body_2_font-size: 14px;
        --bui_font_body_2_font-weight: 400;
        --bui_font_body_2_line-height: 20px;
        --bui_font_body_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_small_1_font-size: 12px;
        --bui_font_small_1_font-weight: 400;
        --bui_font_small_1_line-height: 18px;
        --bui_font_small_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_small_2_font-size: 10px;
        --bui_font_small_2_font-weight: 500;
        --bui_font_small_2_line-height: 16px;
        --bui_font_small_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_strong_1_font-size: 16px;
        --bui_font_strong_1_font-weight: 700;
        --bui_font_strong_1_line-height: 24px;
        --bui_font_strong_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_strong_2_font-size: 14px;
        --bui_font_strong_2_font-weight: 700;
        --bui_font_strong_2_line-height: 20px;
        --bui_font_strong_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_1_font-size: 40px;
        --bui_font_display_1_font-weight: 700;
        --bui_font_display_1_line-height: 52px;
        --bui_font_display_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_2_font-size: 36px;
        --bui_font_display_2_font-weight: 700;
        --bui_font_display_2_line-height: 48px;
        --bui_font_display_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_display_3_font-size: 32px;
        --bui_font_display_3_font-weight: 700;
        --bui_font_display_3_line-height: 40px;
        --bui_font_display_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_1_font-size: 20px;
        --bui_font_featured_1_font-weight: 400;
        --bui_font_featured_1_line-height: 28px;
        --bui_font_featured_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_2_font-size: 20px;
        --bui_font_featured_2_font-weight: 400;
        --bui_font_featured_2_line-height: 28px;
        --bui_font_featured_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_featured_3_font-size: 20px;
        --bui_font_featured_3_font-weight: 400;
        --bui_font_featured_3_line-height: 28px;
        --bui_font_featured_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_1_font-size: 28px;
        --bui_font_headline_1_font-weight: 700;
        --bui_font_headline_1_line-height: 36px;
        --bui_font_headline_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_2_font-size: 24px;
        --bui_font_headline_2_font-weight: 700;
        --bui_font_headline_2_line-height: 32px;
        --bui_font_headline_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_headline_3_font-size: 20px;
        --bui_font_headline_3_font-weight: 700;
        --bui_font_headline_3_line-height: 28px;
        --bui_font_headline_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_emphasized_1_font-size: 16px;
        --bui_font_emphasized_1_font-weight: 500;
        --bui_font_emphasized_1_line-height: 24px;
        --bui_font_emphasized_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
        --bui_font_emphasized_2_font-size: 14px;
        --bui_font_emphasized_2_font-weight: 500;
        --bui_font_emphasized_2_line-height: 20px;
        --bui_font_emphasized_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif
    }
    
    @media (min-width: 576px) {
        :root,[data-bui-theme=legacy-dark],[data-bui-theme=legacy-light] {
            --bui_font_body_1_font-size:16px;
            --bui_font_body_1_font-weight: 400;
            --bui_font_body_1_line-height: 24px;
            --bui_font_body_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_body_2_font-size: 14px;
            --bui_font_body_2_font-weight: 400;
            --bui_font_body_2_line-height: 20px;
            --bui_font_body_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_small_1_font-size: 12px;
            --bui_font_small_1_font-weight: 400;
            --bui_font_small_1_line-height: 18px;
            --bui_font_small_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_small_2_font-size: 12px;
            --bui_font_small_2_font-weight: 500;
            --bui_font_small_2_line-height: 18px;
            --bui_font_small_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_strong_1_font-size: 16px;
            --bui_font_strong_1_font-weight: 700;
            --bui_font_strong_1_line-height: 24px;
            --bui_font_strong_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_strong_2_font-size: 14px;
            --bui_font_strong_2_font-weight: 700;
            --bui_font_strong_2_line-height: 20px;
            --bui_font_strong_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_display_1_font-size: 64px;
            --bui_font_display_1_font-weight: 700;
            --bui_font_display_1_line-height: 72px;
            --bui_font_display_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_display_2_font-size: 56px;
            --bui_font_display_2_font-weight: 700;
            --bui_font_display_2_line-height: 64px;
            --bui_font_display_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_display_3_font-size: 48px;
            --bui_font_display_3_font-weight: 700;
            --bui_font_display_3_line-height: 62px;
            --bui_font_display_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_featured_1_font-size: 32px;
            --bui_font_featured_1_font-weight: 400;
            --bui_font_featured_1_line-height: 40px;
            --bui_font_featured_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_featured_2_font-size: 24px;
            --bui_font_featured_2_font-weight: 400;
            --bui_font_featured_2_line-height: 32px;
            --bui_font_featured_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_featured_3_font-size: 20px;
            --bui_font_featured_3_font-weight: 400;
            --bui_font_featured_3_line-height: 28px;
            --bui_font_featured_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_headline_1_font-size: 32px;
            --bui_font_headline_1_font-weight: 700;
            --bui_font_headline_1_line-height: 40px;
            --bui_font_headline_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_headline_2_font-size: 24px;
            --bui_font_headline_2_font-weight: 700;
            --bui_font_headline_2_line-height: 32px;
            --bui_font_headline_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_headline_3_font-size: 20px;
            --bui_font_headline_3_font-weight: 700;
            --bui_font_headline_3_line-height: 28px;
            --bui_font_headline_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_emphasized_1_font-size: 16px;
            --bui_font_emphasized_1_font-weight: 500;
            --bui_font_emphasized_1_line-height: 24px;
            --bui_font_emphasized_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_emphasized_2_font-size: 14px;
            --bui_font_emphasized_2_font-weight: 500;
            --bui_font_emphasized_2_line-height: 20px;
            --bui_font_emphasized_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif
        }
    }
    
    @media (min-width: 1024px) {
        :root,[data-bui-theme=legacy-dark],[data-bui-theme=legacy-light] {
            --bui_font_body_1_font-size:16px;
            --bui_font_body_1_font-weight: 400;
            --bui_font_body_1_line-height: 24px;
            --bui_font_body_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_body_2_font-size: 14px;
            --bui_font_body_2_font-weight: 400;
            --bui_font_body_2_line-height: 20px;
            --bui_font_body_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_small_1_font-size: 12px;
            --bui_font_small_1_font-weight: 400;
            --bui_font_small_1_line-height: 18px;
            --bui_font_small_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_small_2_font-size: 12px;
            --bui_font_small_2_font-weight: 500;
            --bui_font_small_2_line-height: 18px;
            --bui_font_small_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_strong_1_font-size: 16px;
            --bui_font_strong_1_font-weight: 700;
            --bui_font_strong_1_line-height: 24px;
            --bui_font_strong_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_strong_2_font-size: 14px;
            --bui_font_strong_2_font-weight: 700;
            --bui_font_strong_2_line-height: 20px;
            --bui_font_strong_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_display_1_font-size: 96px;
            --bui_font_display_1_font-weight: 700;
            --bui_font_display_1_line-height: 108px;
            --bui_font_display_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_display_2_font-size: 64px;
            --bui_font_display_2_font-weight: 700;
            --bui_font_display_2_line-height: 72px;
            --bui_font_display_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_display_3_font-size: 48px;
            --bui_font_display_3_font-weight: 700;
            --bui_font_display_3_line-height: 62px;
            --bui_font_display_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_featured_1_font-size: 32px;
            --bui_font_featured_1_font-weight: 400;
            --bui_font_featured_1_line-height: 40px;
            --bui_font_featured_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_featured_2_font-size: 24px;
            --bui_font_featured_2_font-weight: 400;
            --bui_font_featured_2_line-height: 32px;
            --bui_font_featured_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_featured_3_font-size: 20px;
            --bui_font_featured_3_font-weight: 400;
            --bui_font_featured_3_line-height: 28px;
            --bui_font_featured_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_headline_1_font-size: 32px;
            --bui_font_headline_1_font-weight: 700;
            --bui_font_headline_1_line-height: 40px;
            --bui_font_headline_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_headline_2_font-size: 24px;
            --bui_font_headline_2_font-weight: 700;
            --bui_font_headline_2_line-height: 32px;
            --bui_font_headline_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_headline_3_font-size: 20px;
            --bui_font_headline_3_font-weight: 700;
            --bui_font_headline_3_line-height: 28px;
            --bui_font_headline_3_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_emphasized_1_font-size: 16px;
            --bui_font_emphasized_1_font-weight: 500;
            --bui_font_emphasized_1_line-height: 24px;
            --bui_font_emphasized_1_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
            --bui_font_emphasized_2_font-size: 14px;
            --bui_font_emphasized_2_font-weight: 500;
            --bui_font_emphasized_2_line-height: 20px;
            --bui_font_emphasized_2_font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif
        }
    }
    