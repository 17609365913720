.mail {
  width: 100%;
  margin-top: 50px;
  background-color: #70bbe9;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px 0;
}

.mailInputContainer > input {
  width: 300px;
  height: 30px;
  padding: 10px 10px 10px 15px;
  border: none;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.mailInputContainer > button {
  height: 50px;
  background-color: #70bbe9;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
