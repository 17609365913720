/* media size definitions */
/* fonts definitions */
:root,
.bui-theme-legacy-light {
  /* colors definitions */

  /* shadows definitions */

  /* units definitions */
  

  /* fonts values variable definitions */

}
/* NOTE: This is the "master file" for the my_properties page. It should only contain `@import` directives. */
/* media size definitions */
/* fonts definitions */
:root,
.bui-theme-legacy-light {
  /* colors definitions */

  /* shadows definitions */

  /* units definitions */


  /* fonts values variable definitions */
}
:root {
  /* Grid */

  /* Animation */

  /* z-index */
}
/* media size definitions */
/* fonts definitions */
:root,
.bui-theme-legacy-light {
  /* colors definitions */

  /* shadows definitions */

  /* units definitions */


  /* fonts values variable definitions */
}
:root {
  /* Grid */

  /* Animation */

  /* z-index */
}
/* Color */
.bui-f-color-destructive {
  color: #c00 !important;
}
.bui_color_destructive {
  color: #c00 !important;
}
.bui-f-color-callout {
  color: #ff8000 !important;
}
.bui_color_callout {
  color: #ff8000 !important;
}
.bui-f-color-constructive {
  color: #008009 !important;
}
.bui_color_constructive {
  color: #008009 !important;
}
.bui-f-color-complement {
  color: #febb02 !important;
}
.bui_color_complement {
  color: #febb02 !important;
}
.bui-f-color-primary {
  color: #70bbe9 !important;
}
.bui_color_primary {
  color: #70bbe9 !important;
}
.bui-f-color-grayscale {
  color: #6b6b6b !important;
}
.bui_color_grayscale {
  color: #6b6b6b !important;
}
.bui-f-color-grayscale-darker {
  color: #333 !important;
}
.bui-f-color-black {
  color: #000 !important;
}
.bui_color_black {
  color: #000 !important;
}
.bui-f-color-white {
  color: #fff !important;
}
.bui_color_white {
  color: #fff !important;
}
.bui-f-color-action {
  color: #70bbe9 !important;
}
.bui_color_action {
  color: #70bbe9 !important;
}
/* Fill */
.bui-f-fill-destructive {
  background-color: #c00 !important;
  fill: #c00 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui_fill_destructive {
  background-color: #c00 !important;
  fill: #c00 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui-f-fill-callout {
  background-color: #ff8000 !important;
  fill: #ff8000 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui_fill_callout {
  background-color: #ff8000 !important;
  fill: #ff8000 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui-f-fill-complement {
  background-color: #febb02 !important;
  fill: #febb02 !important;
  border-color: #333 !important;
  color: #333 !important;
}
.bui_fill_complement {
  background-color: #febb02 !important;
  fill: #febb02 !important;
  border-color: #333 !important;
  color: #333 !important;
}
.bui-f-fill-constructive {
  background-color: #008009 !important;
  fill: #008009 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui_fill_constructive {
  background-color: #008009 !important;
  fill: #008009 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui-f-fill-primary {
  background-color: #70bbe9 !important;
  fill: #70bbe9 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui_fill_primary {
  background-color: #70bbe9 !important;
  fill: #70bbe9 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui-f-fill-grayscale {
  background-color: #6b6b6b !important;
  fill: #6b6b6b !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui_fill_grayscale {
  background-color: #6b6b6b !important;
  fill: #6b6b6b !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui-f-fill-black {
  background-color: #000 !important;
  fill: #000 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui_fill_black {
  background-color: #000 !important;
  fill: #000 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui-f-fill-white {
  background-color: #fff !important;
  fill: #fff !important;
  border-color: #333 !important;
  color: #333 !important;
}
.bui_fill_white {
  background-color: #fff !important;
  fill: #fff !important;
  border-color: #333 !important;
  color: #333 !important;
}
.bui-f-fill-action {
  background-color: #70bbe9 !important;
  fill: #70bbe9 !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui_fill_action {
  background-color: #70bbe9 !important;
  fill: #70bbe9 !important;
  border-color: #fff !important;
  color: #fff !important;
}
/* Outline */
.bui-f-outline-destructive {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #c00 !important;
  color: #c00 !important;
}
.bui_fill_destructive_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #c00 !important;
  color: #c00 !important;
}
.bui-f-outline-callout {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #ff8000 !important;
  color: #ff8000 !important;
}
.bui_fill_callout_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #ff8000 !important;
  color: #ff8000 !important;
}
.bui-f-outline-complement {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #febb02 !important;
  color: #febb02 !important;
}
.bui_fill_complement_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #febb02 !important;
  color: #febb02 !important;
}
.bui-f-outline-constructive {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #008009 !important;
  color: #008009 !important;
}
.bui_fill_constructive_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #008009 !important;
  color: #008009 !important;
}
.bui-f-outline-primary {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #70bbe9 !important;
  color: #70bbe9 !important;
}
.bui_fill_primary_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #70bbe9 !important;
  color: #70bbe9 !important;
}
.bui-f-outline-grayscale {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #6b6b6b !important;
  color: #6b6b6b !important;
}
.bui_fill_grayscale_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #6b6b6b !important;
  color: #6b6b6b !important;
}
.bui-f-outline-black {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #000 !important;
  color: #000 !important;
}
.bui_fill_black_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #000 !important;
  color: #000 !important;
}
.bui-f-outline-white {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui_fill_white_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}
.bui-f-outline-action {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #70bbe9 !important;
  color: #70bbe9 !important;
}
.bui_fill_action_outline {
  background-color: transparent !important;
  fill: transparent !important;
  border-color: #70bbe9 !important;
  color: #70bbe9 !important;
}
/* media size definitions */
/* fonts definitions */
:root,
.bui-theme-legacy-light {
  /* colors definitions */

  /* shadows definitions */

  /* units definitions */


  /* fonts values variable definitions */
}
:root {
  /* Grid */

  /* Animation */

  /* z-index */
}
/* media size definitions */
/* fonts definitions */
:root {
  /* Focus states */
}
:root,
.bui-theme-legacy-light {
  /* colors definitions */

  /* shadows definitions */

  /* units definitions */


  /* fonts values variable definitions */
}
:root {
  /* Grid */

  /* Animation */

  /* z-index */
}
/* media size definitions */
/* fonts definitions */
:root,
.bui-theme-legacy-light {
  /* colors definitions */

  /* shadows definitions */

  /* units definitions */


  /* fonts values variable definitions */
}
:root {
  /* Grid */

  /* Animation */

  /* z-index */
}
:root {
  /* Grid */

  /* Animation */

  /* z-index */
}
/* media size definitions */
/* fonts definitions */
:root,
.bui-theme-legacy-light {
  /* colors definitions */

  /* shadows definitions */

  /* units definitions */


  /* fonts values variable definitions */
}
:root {
  /* Grid */

  /* Animation */

  /* z-index */
}
/*  NOTE: This is the "master file" for common CSS imports. It should only contain `@import` directives. */
/* Update: this file was forked from Extranet base.css. A lot of things were cleanup that are not needed for joinapp, but we need to cleanup this file better (bring the styles to build.scss maybe? */
/* Media queries go in the media query css file :P */
/* bootstrap default overrides */
label {
    font-weight: normal;
}
legend {
    font-size: 23px;
}
.container {
    max-width: 100%;
}
.modal-body .loading {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(255, 255, 255, 0.8) url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader.gif) no-repeat 50% 50%;
}
.content {
    padding-left: 30px;
}
.contact-form {
    padding-bottom: 60px;
}
#content{
    background: #eee;
}
#content:before {
    content: "";
    width: 34%;
    background: #70bbe9;
    height: 100%;
    position: absolute;
    top: 0;
    left: -34%;
    z-index: 0;
}
/* base.css */
body {
    margin: 0;
    background-color: #f5f5f5;
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
}
/* Mobile JS detection module */
body:before {
    content: 'normal';
    display: none;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
}
h1 {
    font-weight: normal;
    /* border-bottom: 1px solid #ddd; */
    padding: 0.2em 0 0.3em;
    margin: 0.2em 0 0.5em;
    position: relative;
    font-size: 38.5px;
    line-height: 1.37;
}
a {
    color: #70bbe9;
}
a:hover {
    color: #bad4f7;
}
.page_description.row {
	margin: 0;
}
.page_description p {
    font-size: 15px;
    color: #525252;
    padding: 0 0 15px;
    font-weight: normal;
    margin-bottom: 15px;
}
/* Menu */
.menu {
    background: #355E97;
    padding-left: 0;
    padding-right: 0;
    text-rendering: optimizeLegibility;
    z-index:1;
}
.menu .nav li a {
    font-size: 20px;
    font-weight: normal;
    color: #fff;
    border-top: 1px solid #6984b4;
    padding: 16px 10px 20px 60px;
    min-height: 0;
    position: relative;
    line-height: 1.15;
    transition: background 0.2s linear;
    word-wrap: break-word;
}
.menu .nav li a:hover,
.menu .nav li a:focus {
    background: #313e57;
}
.menu .nav .active a,
.menu .nav .menu-selected a {
    background: #EFEFEF !important;
    color: #495d82;
    text-shadow: 1px 0 1px rgba(255,255,255,0.7);
    position: relative;
    border-right: 1px solid #DDD;
}
.menu .nav .menu-selected a {
    background: #f5f5f5 !important;
    border-top: 1px solid #DDD;
}
.menu .nav .disabled > a {
    pointer-events: none;
}
.menu .nav li.active a:after,
.menu .nav li.menu-selected a:after,
.menu .nav li.active a:before,
.menu .nav li.menu-selected a:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    right: -1px;
    position: absolute;
    pointer-events: none;
}
.menu .nav li.active a:after {
    border-color: rgba(238, 238, 238, 0);
    border-right-color: #f5f5f5;
    border-width: 9px;
    top: 35%;
    margin-top: 0px;
}
.is_right_to_left .menu .nav .active a,
.is_right_to_left .menu .nav .menu-selected a {
    border-right: 0;
    border-left: 1px solid #DDD;
}
.is_right_to_left .menu .nav li.active a:after,
.is_right_to_left .menu .nav li.menu-selected a:after,
.is_right_to_left .menu .nav li.active a:before,
.is_right_to_left .menu .nav li.menu-selected a:before {
    left: -1px;
    right: initial;
}
.is_right_to_left .menu .nav li.active a:after {
    border-left-color: #EEE;
    border-right-color: transparent;
}
.is_right_to_left .menu .nav li.active a:before {
    border-left-color: #DDD;
    border-right-color: transparent;
}
.menu .nav li.active a:before {
    border-color: rgba(204, 204, 204, 0);
    border-right-color: #DDD;
    border-width: 11px;
    top: 35%;
    margin-top: -2px;
}
.menu .subnav li a:after,
.menu .subnav li a:before {
    display: none;
}
/* no icon menu */
.menu.no_icons .nav > li > a {
    padding-left: 17px;
}
.menu.no_icons .nav > li > a.completed {
    padding-left: 60px;
}
.menu.no_icons .nav > li > a > .glyphicon {
    display: none;
}
.menu.no_icons .nav > li > a.completed > .glyphicon {
    display: block;
}
.menu a em {
    font-size: 13px;
    font-style: normal;
    display: block;
    margin: 7px 5px 5px 0;
    line-height: 1.5;
}
.menu li ul {
    padding: 0 0 10px 50px;
    margin: 0;
    background: #EEE;
    list-style: none;
    display: none;
}
.menu .menu-selected ul {
    background: #fafcff;
}
.menu .active ul,
.menu .menu-selected ul {
    display: block;
}
.menu .nav li li a {
    padding: 10px 25px 10px 10px;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    display: block;
    min-height: 20px;
    border-radius: 2px;
    position: relative;
    border-bottom: 1px solid #E2E2E2;
}
.menu .nav li li a .glyphicon {
    position: absolute;
    top: 25%;
    right: 10px;
    color: #ebf3ff;
    text-shadow: none;
}
.menu .state-opened {
    display: none;
}
.menu .state-collapsed {
    display: block;
}
.menu .menu-selected > a > .state-opened {
    display: block;
}
.menu .menu-selected > a > .state-collapsed {
    display: none;
}
.menu .subnav .pull-left {
    /* for messages sub nav */
    position: relative;
    margin-left: 10px;
}
.menu li li a.active {
    background: #bad4f7 !important;
    color: #fff;
    text-shadow: none;
}
.menu li li a.active {
    background: #bad4f7 !important;
    color: #fff;
    text-shadow: none;
}
.nav > li > a > .glyphicon {
    font-size: 22px;
    text-align: center;
    position: absolute;
    top: 14px;
    left: 10px;
    width: 40px;
}
.user {
    color: #fff;
    background: #70bbe9;
}
.user p {
    margin-bottom: 0;
}
.user p:first-of-type {
    font-weight: 600;
}
.user hr {
    margin-top: 10px;
    margin-bottom: 8px;
    border-color: #bad4f7;
}
.navbar-fixed-top h1 {
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.1em;
}
.mobile-nav {
    display: none;
    position: relative;
    z-index: 9;
}
/* Menu ends */
/* Logo */
#logo{
    display:block;
    width:170px;
    padding:1em 10px 10px;
}
#logo>img{
    display:block;
    width:150px;
}
.join_logo{
    float: left;
}
.join_logo img {
    width: 150px;
    margin: 14px 0 9px 10px;
}
/* hotel name block above nav / below logo */
nav .block_details {
    background-color: #70bbe9;
    border: 0;
    border-top: 1px solid #70bbe9;
    padding: 10px 10px 10px 10px;
    margin-bottom: 0;
}
nav .sb {
    clear: both;
}
nav .block_details a.btn {
    color: #fff;
    margin: 8px 0 5px 0;
}
nav .block_details a.btn i {
    padding-left: 10px;
}
nav .block_details p {
    color: #ebf3ff;
}
/* progress */
p.progress_status {
	font-size: 85%;
	margin-bottom: 5px;
	font-weight: 500;
	display: block;
}
nav .block_details p.progress_status {
    color: #fff;
}
nav .block_details .progress {
	margin-bottom: 5px;
}
nav .block_details p span {
    color: #fafcff;
    font-weight: normal;
}
/* forms */
fieldset {
	padding: 1em;
	margin-bottom: 1.5em;
	border-radius: 4px;
	border: 1px solid #e7e7e7;
	background-color: #fff;
}
legend {
	background-color: #fff;
	display: inline-block;
	width: auto;
	padding: 5px 15px 0 12px;
	margin: 0 0 5px -14px;
	border-radius: 4px 4px 0 0;
	line-height: 1.3em;
	border-bottom: none;
}
label {
    font-weight: 600;
    color: #555;
}
.form-control[disabled] {
    color: #bdbdbd;
}
.form-control[readonly] {
    color: #bdbdbd;
}
fieldset[disabled] .form-control {
    color: #bdbdbd;
}
option:disabled {
    color: #bdbdbd;
}
optgroup:disabled {
    color: #bdbdbd;
}
.form-control option:disabled {
    color: #bdbdbd;
}
.is-block {
    /* override for occasional layout gremlins where it doesn't make sense to have to insert another row */
    display: block !important;
    clear: both;
}
label.is-block {
    margin-top: 10px;
}
/* radio toggle blocks */
.radio-block .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
.radio-block .radio label {
    display: inline-block;
    position: relative;
}
.radio-block label span {
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 7px 8px 7px 30px;
    margin: 0;
    min-width: 150px;
    background-color: #F9F9F9;
    font-weight: 600;
}
.radio-block input[type="radio"] {
    position: absolute;
    top: 30%;
    left: 10px;
    margin: 0;
    float: none;
}
.radio-block input:checked ~ span {
    background-color: #e5f7d9;
    border-color: #b5ea96;
    text-shadow: 1px 0 rgba(255,255,255,0.7);
}
.radio-block .radio+.radio {
    margin-left: 8px;
}
/* General content styles */
h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
    margin: 0 0 10px;
}
.describe-block {
    background: #fff8f0;
    padding: 1em;
    color: #2e2e2e;
    font-weight: normal;
    border-top: 1px solid #ffc489;
    border-bottom: 1px solid #ffc489;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    margin-bottom: 15px;
}
.describe-block.secondary {
    margin: 15px 0 0;
}
.describe-text {
    margin-left: 25px;
    margin-bottom: 0;
}
.describe-block .glyphicon-hand-right {
    margin-right: 10px;
    float: left;
}
.describe-block.action {
    background: #fff0f0;
    border-top: 1px solid #fff0f0;
    border-bottom: 1px solid #fff0f0;
}
.remove_lang_link {
    color: #a30000;
}
.remove_breakfast_type_link {
    color: #a30000;
}
.edit {
    padding-left: 12px;
    margin: -5px 0 10px 0;
    font-size: 12px;
    background: url("https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/edit.png") no-repeat 0;
    white-space: nowrap;
    display: block;
    clear: both;
}
#calendar .btn-info {
    width: 15px;
    height: 15px;
    line-height: 1px;
    padding: 0;
    position: relative;
    top: 25px;
    left: 20px;
}
#calendar .count + .btn-info {
    left: -20px;
}
/* Styling the success messages */
/* Errors */
.has-error label {
    font-weight: 600;
}
.help-block,
.warning-block {
    font-size: 12px;
}
.has-error .input-group-addon {
	color: #a30000;
	background-color: #fff0f0;
	border-color: #a30000;
}
.alert-info {
    color: #bad4f7;
    background-color: #fafcff;
    border-color: #ebf3ff;
}
.has-error .form-control:focus {
    border-color: #a30000;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 6px #ffebeb;
}
/* hiding the Plack thing */
#plDebug {
    display: none;
}
.dropdown-menu.is-active {
    display: block;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
}
.dropdown-menu.is-active a:focus {
    outline: none;
}
.dropdown-menu.is-active a span {
    font-size: 0.85em;
}
.disambiguation-modal-options {
    list-style: none;
    margin: 0;
    padding: 0;
}
.disambiguation-modal-options li {
    margin: 0;
    padding: 0;
}
.disambiguation-modal-options input {
    margin-right: 0.5em;
}
.map-wrapper {
    height: 350px;
    position: relative;
    overflow: hidden;
}
.map-wrapper #city-map {
    height: 350px;
    width: 100%;
    direction: ltr;
}
/* Creditcard logos */
.has_select_none .select_all{
    display: none;
}
.has_select_none .select_none{
    display: block;
}
.creditcard_section .radio,
.creditcard_section .checkbox{ /* This overrides Bootstrap default styling to include CC logos */
    padding-left: 57px;
}
.creditcard_section .radio input[type="radio"],
.creditcard_section .radio-inline input[type="radio"],
.creditcard_section .checkbox input[type="checkbox"],
.creditcard_section .checkbox-inline input[type="checkbox"]{
    margin: 7px 0 0 -75px;
}
.cc_remote_accepted {
    margin-top: 8px;
}
.cc_remote_accepted .radio input[type="radio"],
.cc_remote_accepted .radio-inline input[type="radio"],
.cc_remote_accepted .checkbox input[type="checkbox"],
.cc_remote_accepted .checkbox-inline input[type="checkbox"]{
    margin: 0;
}
.is_right_to_left .creditcard_section .checkbox input[type="checkbox"],
.is_right_to_left .creditcard_section .checkbox-inline input[type="checkbox"]{
    margin: inherit;
    margin-right: -75px;
    margin-top: 7px;
}
.creditcard {
    background: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/cc_vert.png) no-repeat;
    height: 28px;
    position: relative;
    margin: 0 0 10px 18px;
    line-height: 25px;
}
.americanexpress {
    background-position: 0px -216px;
}
.visa {
    background-position: 0px 0px;
}
.euromastercard {
    background-position: 0px -36px;
}
.dinersclub {
    background-position: 0px -144px;
}
.discover {
    background-position: 0px -108px;
}
.maestro {
    background-position: 0px -72px;
}
.switch {
    background-position: 0px -252px;
}
.solo {
    background-position: 0 -468px;
}
.jcb {
    background-position: 0px -180px;
}
.cartebleue{
    background-position: 0 -396px;
}
.hipercard{
    background-position: 0 -360px;
}
.argencard{
    background-position: 0px -432px;
}
.unionpaycreditcard,
.unionpaydebitcard {
    background-position: 0px -288px;
}
.eccard {
    background-position: 0 -324px;
}
.cartasi {
    background-position: 0 -504px;
}
.carteblanche {
    background-position: 0 -540px;
}
.eftpos {
    background-position: 0 -576px;
}
.uc {
    background-position: 0 -612px;
}
.bancontact {
    background-position: 0 -648px;
}
.nicos {
    background-position: 0 -684px;
}
.redcompra {
    background-position: 0 -720px;
}
.bccard {
    background-position: 0 -756px;
}
.cabal {
    background-position: 0 -792px;
}
.australianbankcard {
    background-position: 0 -828px;
}
.no_cc{
    background-position: 0 -900px;
}
.elocreditcard{
    background-position: 0 -934px;
}
.mir {
    background: url('https://q.bstatic.com/static/img/payments/payment_icons_redesign/mir.svg') no-repeat left/contain;
}
.atmcard,
.pin,
.jinsui,
.dragon,
.bankcard,
.red6000,
.chipper,
.greatwall,
.peony,
.pacific,
.khszechenyipihenokartya,
.mkbszechenyipihenokartya,
.mkbszchnyipihenkrtya,
.khszchnyipihenkrtya,
.otpszchnyipihenkrtya,
.otpszechenyipihenokartya,
.othercards,
.greatwall{
    background-position: 0 -864px;
    min-width: 17px;
    height: 28px;
    line-height: 25px;
}
.clear-left{
    clear: left;
}
.form-loading {
    background-image: url('https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader-button.gif') !important;
    background-repeat: no-repeat;
    background-position: 98% 48%;
}
.is_right_to_left .form-loading {
    background-position: 2% 48%;
}
/* Settings page */
.lang_spoken_line {
    clear: both;
}
.remove_bed_link {
    color: #a30000;
}
.remove_bed_link:hover {
    color: #a30000;
}
.text_not_bold {
    font-weight: normal;
}
/* Footer */
footer {
    background-color: #fafcff;
    text-align: right;
    padding: 15px;
}
footer li {
    margin: 0 10px 10px 0;
}
.is_right_to_left footer li.pull-left{
    float:right!important;
}
/* Dev/test env changes */
.test .user {
	background: #888;
}
/* Error validation styling for SM  ---  Potentialy, build could share this code. */
.form-group.has-error .help-block {
    border: 1px solid #a30000;
    margin-top: -2px;
    padding-top: 0.6em;
    width: 100%;
}
.has-error .help-block {
    padding: 0.5em;
    background-color: #FFF8F8;
    border-radius: 0 0 4px 4px;
    display: inline-block;
}
.special-chars-lang #photo-tips li {
    font-weight: 700;
}
.special-chars-lang .page_description p,
.special-chars-lang .describe-block,
.special-chars-lang h1,
.special-chars-lang h1 .submenu_title,
.special-chars-lang .menu li a,
.special-chars-lang .menu li a em,
.special-chars-lang .photo-counter,
.special-chars-lang #photo-tips span,
.special-chars-lang .message-empty,
.special-chars-lang .no-invoices {
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}
/* Direct payment styling for sb/sm/migration */
#direct_payment_fields {
    transition-duration: 700ms;
}
#direct_payment_fields.with-summary {
    border: 1px solid #fafcff;
    padding: 15px;
    border-bottom: none;
    transition-duration: 700ms;
}
body.aa-font, body.aa-font h1, body.aa-font h2, body.aa-font h3, body.aa-font h4, body.aa-font h5, body.aa-font h6, body.aa-font .h1, body.aa-font .h2, body.aa-font .h3, body.aa-font .h4, body.aa-font .h5, body.aa-font .h6{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* <JS Rotation Module CSS> */
.rotation90 {
    transform: rotate(90deg);
}
.rotation180 {
    transform: rotate(180deg);
}
.rotation270 {
    transform: rotate(-90deg);
}
/* </JS Rotation Module CSS> */
/* Body antialiased fonts */
.aa_fonts {
    -webkit-font-smoothing: antialiased;
}
/* language selector */
.language-selector {
    padding: 15px 15px 10px;
    border-left: 1px solid rgba(83, 123, 180, 0.3);
    border-right: 1px solid rgba(83, 123, 180, 0.3);
    float: right;
    width: 3.75em;
    height: 3.5em;
    cursor: pointer;
    position: relative;
}
.flag_icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-position: 0 0;
    background-size: cover;
}
.ontoplang {
    float: right;
    position: absolute;
    width: 3.75em;
    height: 3.5em;
    top: 0;
    left: 0;
    display: block;
    border: 10px solid transparent;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    cursor: pointer;
}
.ontoplang:active {
    width: auto;
}
.flag_ar { /* Arabic */
    background-image: url('//xx.bstatic.com/static/img/flags/24/arab_league.png');
}
.flag_bg { /* Bulgarian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/bg.png');
}
.flag_ca { /* Catalan */
    background-image: url('//xx.bstatic.com/static/img/flags/24/catalonia.png');
}
.flag_cs { /* Czech */
    background-image: url('//xx.bstatic.com/static/img/flags/24/cz.png');
}
.flag_da { /* Danish */
    background-image: url('//xx.bstatic.com/static/img/flags/24/dk.png');
}
.flag_de { /* German */
    background-image: url('//xx.bstatic.com/static/img/flags/24/de.png');
}
.flag_el { /* Greek */
    background-image: url('//xx.bstatic.com/static/img/flags/24/gr.png');
}
.flag_en { /* EN-GB */
    background-image: url('//xx.bstatic.com/static/img/flags/24/gb.png');
}
.flag_en-us,
.flag_xu { /* EN-US */
    background-image: url('//xx.bstatic.com/static/img/flags/24/us.png');
}
.flag_es { /* Spanish */
    background-image: url('//xx.bstatic.com/static/img/flags/24/es.png');
}
.flag_es-ar,
.flag_xa { /* Spanish Argentinian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/ar.png');
}
.flag_et { /* Estonian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/ee.png');
}
.flag_fi { /* Finnish/Suomi */
    background-image: url('//xx.bstatic.com/static/img/flags/24/fi.png');
}
.flag_fr { /* French */
    background-image: url('//xx.bstatic.com/static/img/flags/24/fr.png');
}
.flag_he { /* Hebrew */
    background-image: url('//xx.bstatic.com/static/img/flags/24/il.png');
}
.flag_hr { /* Croatian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/hr.png');
}
.flag_hi { /*  Hindi */
    background-image: url('//xx.bstatic.com/static/img/flags/24/in.png');
}
.flag_hu { /* Hungarian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/hu.png');
}
.flag_id { /* Bahasa Indonesia */
    background-image: url('//xx.bstatic.com/static/img/flags/24/id.png');
}
.flag_is { /* Icelandic */
    background-image: url('//xx.bstatic.com/static/img/flags/24/is.png');
}
.flag_it { /* Italian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/it.png');
}
.flag_ja { /* Japanese */
    background-image: url('//xx.bstatic.com/static/img/flags/24/jp.png');
}
.flag_ka { /* Georgian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/ge.png');
}
.flag_ko { /* Korean */
    background-image: url('//xx.bstatic.com/static/img/flags/24/kr.png');
}
.flag_lt { /* Lithuanian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/lt.png');
}
.flag_lv { /* Latvian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/lv.png');
}
.flag_ms { /* Bahasa Malaysia */
    background-image: url('//xx.bstatic.com/static/img/flags/24/my.png');
}
.flag_nl { /* Dutch */
    background-image: url('//xx.bstatic.com/static/img/flags/24/nl.png');
}
.flag_no { /* Norwegian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/no.png');
}
.flag_pl { /* Polish */
    background-image: url('//xx.bstatic.com/static/img/flags/24/pl.png');
}
.flag_tl { /* Filipino */
    background-image: url('//xx.bstatic.com/static/img/flags/24/ph.png');
}
.flag_pt-br,
.flag_xb { /* Brazillian Portugese */
    background-image: url('//xx.bstatic.com/static/img/flags/24/br.png');
}
.flag_pt { /* Portugese */
    background-image: url('//xx.bstatic.com/static/img/flags/24/pt.png');
}
.flag_ro { /* Romanian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/ro.png');
}
.flag_ru { /* Russian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/ru.png');
}
.flag_sk { /* Slovak */
    background-image: url('//xx.bstatic.com/static/img/flags/24/sk.png');
}
.flag_sl { /* Slovenian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/si.png');
}
.flag_sr { /* Serbian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/rs.png');
}
.flag_sv { /* Swedish */
    background-image: url('//xx.bstatic.com/static/img/flags/24/se.png');
}
.flag_th { /* Thai */
    background-image: url('//xx.bstatic.com/static/img/flags/24/th.png');
}
.flag_tr { /* Turkish */
    background-image: url('//xx.bstatic.com/static/img/flags/24/tr.png');
}
.flag_uk { /* Ukranian */
    background-image: url('//xx.bstatic.com/static/img/flags/24/ua.png');
}
.flag_vi { /* Vietnamese */
    background-image: url('//xx.bstatic.com/static/img/flags/24/vn.png');
}
.flag_zh { /* Chinese */
    background-image: url('//xx.bstatic.com/static/img/flags/24/cn.png');
}
.flag_zh-tw,
.flag_xt { /* Traditional Chinese */
    background-image: url('//xx.bstatic.com/static/img/flags/24/z4.png');
}
.tooltip .tooltip-inner {
    text-align: left;
}
/*  npx_joinapp_landing_use_connect_help_icon
    These styles have been copied/pasted from manage.css. We are trying to unify Connect and joinapp headers.
    Last step would be to unify also this code
*/
.topnav__menu_section {
    background: none;
    border: 0;
    display: block;
    height: 32px;
    padding: 26px 22px;
    position: relative;
    text-align: center;
    width: 32px;
    overflow: visible;
}
.topnav__menu_section.active:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 14px 12px;
    border-color: transparent transparent color(#70bbe9 s(5.6%) l(92.9%)) transparent;
    z-index: 3;
}
.topnav__menu_section span {
    display: none;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 2.4;
}
@media screen and (min-width: 768px) {
    .menu .user {
        display: block;
    }
    .topnav__menu_section {
        padding: 35px 0 0;
        width: 80px;
    }
    .topnav__menu_section span {
        display: inline-block;
        width: 100%;
    }
    .topnav__menu_section.active:after {
        bottom: -8px;
    }
}
.topnav__menu_section:before {
    content: '';
    position: absolute;
    background: color(#70bbe9 s(5.5%) l(82.2%));
    border-radius: 50%;
    color: #fff;
    box-shadow: 0 0 0 1px #fff inset;
    top: 10px;
    left: 50%;
    overflow: hidden;
    width: 32px;
    height: 32px;
    margin-left: -16px;

    font-family: 'biconset-buddy';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.topnav__menu_section--help:before {
    background: #febb02;
    content: "?";
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    top: 7px;
}
.topnav__menu_section--tour:before {
    background: #febb02;
    content: "?";
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    top: 7px;
}
.topnav__menu_section--help.active{
    outline: none;
}
.topnav__menu_section--tour.active:after {
    display: none;
}
/* Cookie warning */
.cookie-warning {
    background: #333;
    color: #fff;
    padding: 8px 24px;
    font-size: 12px;
    font-weight: 300;
    margin: 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    z-index: 9999;
}
.cookie-warning p {
    max-width: 1100px;
    margin: 0 auto;
    display: block;
}
.cookie-warning a {
    color: #ebf3ff;
}
.cookie-warning__close {
    background: transparent;
    border: 0;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    font-family: Arial;
    font-size: 22px;
    font-weight: bold;
    opacity: .9;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 0;
    -webkit-appearance: none;
}
.cookie-warning__close:hover,
.cookie-warning__close:focus {
    opacity: 1;
}
@font-face {
    font-family: 'biconset-buddy';
    src:url('https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/bootstrap/fonts/biconset-buddy.eot');
    src:url('https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/bootstrap/fonts/biconset-buddy.eot?#iefix') format('embedded-opentype'),
    url('https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/bootstrap/fonts/biconset-buddy.woff') format('woff'),
    url('https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/bootstrap/fonts/biconset-buddy.ttf') format('truetype'),
    url('https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/bootstrap/fonts/biconset-buddy.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
	font-family: 'biconset-buddy';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga";
    font-feature-settings: "liga";

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-bed:before {
    content: "\b396";
}
.icon-boost:before {
    content: "\d001";
}
.icon-bookingsuite:before {
    content: "\d000";
}
.icon-arrowright:before {
    content: "\b177";
}
.icon-messagecircle:before {
    content: "\b16b";
}
.icon-geniussquare:before {
    content: "\b164";
}
.icon-genius:before {
    content: "\b163";
}
.icon-ge:before {
    content: "\b162";
}
.icon-star:before {
    content: "\b161";
}
.icon-exclamation:before {
    content: "\b160";
}
.icon-nonsmoking:before {
    content: "\b06c";
}
.icon-tick:before {
    content: "\b058";
}
.icon-tickdot:before {
    content: "\b057";
}
.icon-camera:before {
    content: "\b044";
}
.icon-mybooking:before {
    content: "\b015";
}
.icon-circle:before {
    content: "\b007";
}
.icon-plus:before {
    content: "\b0ef";
}
.icon-users:before {
    content: "\b088";
}
.icon-suitcasevert:before {
    content: "\b089";
}
.icon-speech:before {
    content: "\b08a";
}
.icon-signal:before {
    content: "\b08b";
}
.icon-mobilephone:before {
    content: "\b08c";
}
.icon-location:before {
    content: "\b08d";
}
.icon-key:before {
    content: "\b08e";
}
.icon-graph:before {
    content: "\b08f";
}
.icon-flag:before {
    content: "\b091";
}
.icon-openmail:before {
    content: "\b092";
}
.icon-plusmail:before {
    content: "\b093";
}
.icon-crop:before {
    content: "\b094";
}
.icon-coins:before {
    content: "\b095";
}
.icon-bbooking:before {
    content: "\b097";
}
.icon-arrowleft2:before {
    content: "\b096";
}
.icon-message:before {
    content: "\b086";
}
.icon-share:before {
    content: "\b085";
}
.icon-refresh:before {
    content: "\b084";
}
.icon-alarm:before {
    content: "\b083";
}
.icon-dashboard:before {
    content: "\b082";
}
.icon-warning:before {
    content: "\b117";
}
@media (min-width: 991px) {
    .row .menu .nav .lang-select {
        padding-left: 20px;
    }
}
/* down to medium screen - 1170px */
@media (max-width: 1170px) {

    #calendar .date-info {
	    margin-right: 0.7em;
    }

    #calendar .date-rooms .single-unit {
	    font-size: 1.1em;
    }

    .mobile_property_status.label {
        display: none;
    }
}
/* Tablet size and under */
@media screen and (max-width: 991px) {
    body:before { content: 'small'; }

    .menu + .content {
        padding-left: 15px;
        clear: both;
    }

    .menu {
        width: 100%;
        font-weight: 400;
        background-color: transparent;
    }

    .menu em {
        font-size: 1.35rem;
    }

    .unread_notification {
        left: 23px;
        font-size: 12px;
        padding: 0 3px;
    }

    .mobile-nav {
        padding: 10px 0 0 10px;
    }

    .env-self-manage .user {
        overflow: hidden;
    }

    .hamburger {
        border-radius: 4px;
        border: 1px solid #537BB4;
        display: block;
        float: left;
        padding: 0.35em 0.5em;
        text-align: center;
        margin-right: 10px;
    }

    .b_logo {
        float: left;
        background-position: left center;
        margin: 25px 0px;
    }

    #logo {
        float: left;
        margin: 13px 0px 15px 0;
    }

    .join_logo {
        margin: 13px 0px 15px 13px;
    }

    .join_logo img {
        margin-left: 0;
    }

    nav .block_details {
        float: right;
        background-color: #70bbe9;
        text-align: right;
        padding: 8px 10px 12px 10px;
        border-top: none;
        font-size: 14px;
    }

    nav .block_details.sb {
        width: 50%;
        text-align: left;
        padding-top: 15px;
        clear: none;
    }

    nav .block_details a.btn {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        margin-top: 6px;
        margin-bottom: 0;
    }

    .icon-bar {
        background-color: #FFF;
        display: block;
        width: 28px;
        height: 3px;
        border-radius: 1px;
        margin: 6px auto;
    }

    .mobile-nav {
        display: block;
        float: left;
        height: 50px;
        margin: 3px 0 0 0;
    }

    .menu ul.nav {
        width: 90%;
        background-color: #537BB4;
        display: none;
    }

    .menu .nav > li > a > .glyphicon {
        left: 0px;
        font-size: 2rem;
    }

    .menu.no_icons .nav > li > a.completed {
        padding-left: 42px;
    }

    .menu.no_icons .nav > li > a {
        padding-left: 9px;
    }

    .menu .nav li a .bicon-key {
        font-size: 2.6rem;
    }

    nav.menu-opened ul.nav {
        display: block;
        position: relative;
    }

    nav.menu-opened .hamburger {
        background-color: #EEE;
    }

    nav.menu-opened .hamburger .icon-bar {
        background: #70bbe9;
    }

    nav .hamburger:active .icon-bar {
        background: #96D99D;
    }

    nav .sidebar-usps {
        display: none;
    }

    nav.menu-opened .sidebar-usps {
        display: block;
        width: 90%;
    }

    .menu .nav .selected {
        background-color: #eee;
    }

    .menu .nav .subnav {
        padding-left: 45px;
    }

    .menu .nav .subnav a {
        border-radius: 0;
    }

    .menu .nav .subnav a:hover {
        background-color: #E9F0FA !important;
    }

    .menu .nav.actions,
    .menu .nav.actions>li {
    }

    .thankyousb .list-unstyled li {
       margin: 0 10px 40px 130px;
       font-size: 17px;
    }

    .thankyousb .glyphicon {
        margin-bottom: 25px;
    }

    .radio-block .radio+.radio {
        margin-left: 0;
    }

    .footer-language-selector {
        display: none !important;
    }

    /* progress bar on top */
    .progress_bar_on_top {
        clear: both;
    }

    .join_logo.progress_on_top {
        float: none;
        width: 150px;
        margin: 13px auto 0 auto;
    }

    .progress_bar_on_top + .content {
        clear: both;
    }

    .menu.no_icons .nav > li > a.completed {
        padding-left: 42px;
    }

    .menu.no_icons .nav > li > a {
        padding-left: 9px;
    }

    .bottom-sticky-save-button-container {
        width: 100%;
        left: 0;
    }
}
/* iPad specific tweaks */
.ua-hw-ipad .btn-lg {
    font-weight: 600;
    font-size: 23px;
}
.ua-hw-ipad .menu .nav > li > a > .glyphicon {
    left: 0px;
}
.ua-hw-ipad .menu {
    font-size: 1.2em;
    font-weight: 400;
}
.ua-hw-ipad .menu a {
    font-weight: 400;
}
.ua-hw-ipad .menu em {
    font-size: 1.35rem;
}
.ua-hw-ipad #content {
    background-attachment: inherit;
    background-position-y: 250px;
}
html .ua-hw-ipad {
    font-size: 17px;
}
.ua-hw-ipad h3 {
    margin-bottom: 0.6em;
}
.ua-hw-ipad .page_description p {
    font-size: 1em;
    font-weight: normal;
}
.ua-hw-ipad .describe-block {
    font-weight: 400;
    margin-left: -3px;
    margin-right: -3px;
}
.ua-hw-ipad legend {
    margin-left: -17px;
    font-size: 1.4em;
}
.ua-hw-ipad .form-control {
    font-size: 0.9em;
    height: 2.5em;
}
.ua-hw-ipad .btn-sm,
.ua-hw-ipad .btn-xs {
    font-size: 0.9em;
}
.ua-hw-ipad .top-lang-picker {
    font-size: 14px;
}
@media screen and (orientation:landscape) {
    /* Landscape-specific styles */
    .ua-hw-ipad .menu {
        font-size: 1em;
        font-weight: 400;
        line-height: 1.3;
    }

    .ua-hw-ipad .menu a {
        font-weight: 400;
    }

    .ua-hw-ipad .menu em {
        font-size: 1.35rem;
        line-height: 1.3;
    }

    .menu .nav .submenu li a {
        font-size: 1em;
    }

    nav .block_details {
        font-size: 14px;
    }

    .ua-hw-ipad .top-lang-picker {
        font-size: 14px;
    }
}
@media screen and (orientation:portrait) {
    /* Portrait-specific styles */
    .ua-hw-ipad .menu {
        background-color: transparent;
    }

    .ua-hw-ipad ul.nav {
        width: 90%;
        background-color: #537BB4;
        display: none;
    }

    nav.menu-opened ul.nav {
        display: block;
        position: relative;
    }

    .ua-hw-ipad .menu .nav:before {
        border: 7px solid transparent;
        border-bottom-color: #537BB4;
        position: absolute;
        top: -13px;
        left: 4%;
        z-index: 2;
    }

    .ua-hw-ipad .menu .nav .selected {
        background-color: #eee;
    }

    .mobile-nav {
        display: block;
        float: left;
        height: 50px;
        margin: 3px 0 0 0;
    }

    .ua-hw-ipad .thankyousb .list-unstyled li {
       margin: 0 10px 40px 130px;
       font-size: 17px;
    }

    .ua-hw-ipad .thankyousb .glyphicon {
        margin-bottom: 25px;
    }

    .ua-hw-ipad nav .actions li.lang-select {
        max-height: 60px;
    }

    .ua-hw-ipad .top-lang-picker {
        font-size: 14px;
    }
}
/* end iPad */
/* Phone size 'xs' screens */
@media screen and (max-width: 767px) {
    .user {
        padding: 10px 10px 5px 10px;
    }

    #logo {
        display: none;
    }

    .join_logo {
        display: none;
    }

    .hamburger {
        height: auto;
        width: auto;
    }

    .mobile-nav {
        margin: 0;
        padding: 0;
    }

    nav .block_details {
        float: none;
        text-align: left;
        padding: 2px 0 0 0;
    }

    nav .block_details.sb {
        padding: 0;
        width: 100%;
    }

    nav .block_details span {
        display: block;
    }

    .menu a em {
        margin-top: 2px;
        line-height: 1.25;
    }

    .menu .nav:before {
        border: 7px solid transparent;
        border-bottom-color: #537BB4;
        position: absolute;
        top: -13px;
        left: 7%;
        z-index: 2;
    }

    ul.nav {
        width: 97%;
    }

    .menu .nav > li > a > .glyphicon {
        top: 13px;
    }

    .menu .nav li a .bicon-key {
        margin-top: -4px;
    }

    .menu .nav .subnav {
        padding-left: 35px;
    }

    .menu .nav .submenu li a {
		font-size: 1em;
    }

    .menu .nav.actions,
    .menu .nav.actions>li {
        background-color: #70bbe9;
    }

    .menu .nav.actions:before {
        display: none;
    }

    .menu .nav.actions li a {
        font-size: 0.9em;
        font-weight: 600;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .menu .nav.actions .glyphicon {
        font-size: 1.5rem;
        opacity: 0.75;
        top: 13px;
        color: #FFF;
        position: absolute;
    }

    .menu .nav .lang-select {
        padding: 15px 15px 15px 10px;
        border-top: 1px solid #537BB4;
    }

    nav #lang_selector {
        margin: -3px 0 -3px 35px;
    }

    h1 {
        font-size: 2em;
    }

    h1 .submenu_arrow {
	font-size:0.8em;
    }

    h1 span {
        display: block;
    }

    h1 a.btn.pull-right {
        float: none !important;
    }

    .page_description {
        display: none;
    }

    #error_header ul {
        margin-left: -20px;
        line-height: 1.2;
    }

    #error_header li {
        margin-bottom: 0.5em;
    }

    .room-size-card-header {
        margin-bottom: 15px;
    }

    .room-size-card-input {
        padding-right: 15px;
    }

    #footer_wrapper ul {
	font-size:0.8em;
	text-align: left;
	margin:0;
    }

    #btn_availability span, #btn_availability {
	cursor:pointer;
    }

    .survey-topics > li {
        border: none;
    }

    .survey-topics > li label,
    .survey-topics .radio-block {
        float: none;
        width: 100%;
    }

    .survey-topics .radio-block {
        padding: 0;
        text-align: left;
    }

    .is_right_to_left .survey-topics .radio-block {
        padding: 0;
        text-align: right;
    }

    #facilities_section .row:last-child ul {
        padding-top: 0;
        padding-bottom: 0;
    }

    #facilities_section li {
        padding: 0;
        border: none;
    }

    #facilities_section .form-control {
        margin-bottom: 10px;
    }

    #facilities_section .checkbox {
        margin-bottom: 2px;
    }

    /* progress bar on top */
    .join_logo.progress_on_top {
        display: block;
    }

    .join_logo.progress_on_top img {
        margin: 0;
    }

    .progress_bar_on_top .block_details.sb {
        padding-top: 0;
    }

    .progress_bar_on_top .block_details.sb .progress_status {
        float: none;
        margin-right: 0;
        text-align: left;
        line-height: normal;
    }
}
/* Typography */
/* This file is where our main font call for joinapp happens. If you wish to experiment with fonts, this is the place. Mind that the styles below should override base.scss. Happy styling! */
/* Font System */
body {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body h1 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body h2 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body h3 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body h4 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body h5 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body h6 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body .h1 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body .h2 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body .h3 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body .h4 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body .h5 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
body .h6 {
    font-family: "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
/* Standard classes for headers */
.bui_font_display_three { @apply(--bui_font_display_three); }
.bui_font_display_two   { @apply(--bui_font_display_two); }
.bui_font_display_one   { @apply(--bui_font_display_one); }
.bui_font_heading       { @apply(--bui_font_heading); }
.bui_font_featured      { @apply(--bui_font_featured); }
.bui_font_body          { @apply(--bui_font_body); }
.bui_font_emphasized    { @apply(--bui_font_emphasized); }
.bui_font_caption       { @apply(--bui_font_caption); }
.bui_font_strong        { @apply(--bui_font_strong); }
/* Copy base color bui_color_grayscale_dark */
.message-empty {
	color: #333;
}
.content-compact .page_description p {
	color: #333;
}
.photo-counter {
	color: #333;
}
.content-compact label {
	color: #333;
}
.js-upload.btn-default {
	color: #333;
}
.form-control {
	color: #333;
}
.btn-default {
	color: #333;
}
.no-web-font .content-compact .page_description p {
	color: #333;
}
.drag-and-drop-photos .upload-form-drop-instruction {
	color: #333;
}
.accomAgreement h3 {
	color: #333;
}
.accomAgreement #bottom_message h4 {
	color: #333;
}
.timeline dl dt {
	color: #333;
}
.thankyou-block--color {
	color: #333;
}
.thankyou-block h4 {
	color: #333;
}
.roomlist-footer {
	color: #333;
}
.roomlist-items {
	color: #333;
}
.font-hierarchy-updated .content-compact legend {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: #333;
}
.font-hierarchy-updated .content-compact h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}
.font-hierarchy-updated .room_block h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}
.font-hierarchy-updated .side-container span {
    color: #70bbe9;
}
.font-hierarchy-updated .content-compact .thankyou-block h1 {
    font-weight: normal;
}
/* Start reset bootstrap */
.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:visited,
.btn.btn-primary:focus,
.btn.btn-default,
.btn.btn-default:hover,
.btn.btn-default:active,
.btn.btn-default:visited,
.btn.btn-default:focus {
    background-image: none;
    box-shadow: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(enabled=false)";
    text-shadow: none;
}
/* End reset bootstrap */
.btn-primary {
    background-color: #70bbe9;
    padding: 8px 16px;
    border: none;
    color: #fff;
    border-radius: 2px;
    display: inline-block;
    transition: background-color .3s ease;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.btn-primary:hover {
    background-color: color(#70bbe9 l(35.9%));
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 2px;
    transition: background-color .3s ease;
}
.btn-primary:active {
    background-color: color(#70bbe9 l(32%));
    border-radius: 2px;
    border: none;
}
.btn-primary:visited {
    background-color: color(#70bbe9 l(32%));
    border-radius: 2px;
    border: none;
}
.btn-primary:focus {
    box-shadow: 0 0 0 2px color(#70bbe9 l(68%));
    outline: none;
    color: #fff;
    text-decoration: none;
    border-radius: 2px;
    border: none;
}
.btn-primary.btn-lg {
    padding: calc(24px/2) 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
.btn-default {
    background-color: #f5f5f5;
    border: 1px solid #333;
    color: #333;
    border-radius: 2px;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.btn-default:hover {
    background-color: #e6e6e6;
    color: #333;
    text-decoration: none;
    border: 1px solid #333;
    border-radius: 2px;
}
.btn-default:active {
    background-color: #e6e6e6;
    border-radius: 2px;
    border: 1px solid #333;
}
.btn-default:visited {
    background-color: #e6e6e6;
    border-radius: 2px;
    border: 1px solid #333;
}
.btn-default:focus {
    box-shadow: 0 0 0 2px #5CAFFF;
    outline: none;
    color: #333;
    text-decoration: none;
    border-radius: 2px;
    border: 1px solid #333;
    background-color: #e6e6e6;
}
.btn-default.btn-lg {
    padding: calc(24px/2) 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
h1 {
    /* border-bottom: 1px solid #e6e6e6; */
    padding: 8px 0 8px;
    margin: 8px 0 8px;
    position: relative;
    font-size: 32px;
}
h1 .semibold {
    font-weight: 500;
}
h5 {
    font-weight: 400;
}
.describe-block {
    background: #fefbf0;
    padding: 8px 8px 8px 16px;
    color: #333;
    font-weight: normal;
    border-top: 1px solid #fefbf0;
    border-bottom: 1px solid #fefbf0;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    margin-bottom: 16px;
    margin-left: -3px;
}
.describe-block.secondary {
    margin: 16px 0 0;
}
.describe-text {
    margin-left: 0;
    margin-bottom: 0;
}
.describe-block .glyphicon-hand-right {
    margin-right: 8px;
    font-size: 16px;
    float: left;
}
.offset-p1 { padding-left: 4px }
.offset-p2 { padding-left: 8px }
.offset-p3 { padding-left: 16px }
/* nav actions */
nav .actions {
    display:none;
}
.nav.actions {
    background-color: #70bbe9;
}
.nav.actions>li {
    background-color: #70bbe9;
}
.nav.actions:before {
    display: none;
}
.nav.actions li a {
    font-size: 12px;
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 16px;
}
.nav.actions .glyphicon {
    font-size: 20px;
    opacity: 0.75;
    top: 16px;
    color: #fff;
    position: absolute;
}
.nav .lang-select {
    padding: 16px 16px 16px 8px;
    border-top: 1px solid #bad4f7;
}
nav #lang_selector {
    margin: calc(2px * -1) 0 calc(2px * -1) 32px;
}
nav .actions li {
    max-height: 50px;
}
/* forms */
label {
    font-weight: 500;
    color: #333;
}
.radio strong {
    font-weight: 500;
    color: #333;
}
legend {
    display: inline-block;
    width: auto;
    padding: 4px 16px 0 16px;
    margin: 0 0 4px calc(16px * -1);
    border-radius: 4px 4px 0 0;
    line-height: 1.3em;
    border-bottom: none;
    background: transparent;
}
fieldset {
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
}
fieldset .text-info {
    font-size: 16px;
    padding-bottom: 8px;
}
#content_wrapper{
    background: inherit;
}
#content {
    background: transparent url("https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader.gif") no-repeat 63% 40%;
    background-attachment: fixed;
    min-height: 400px;
}
@media (max-width: 991px) {
    #content {
        background-position: 50% 40%;
    }
}
#content > .row,
#content > .show-when-ready {
    opacity: 0;
}
body.content-ready #content {
    background: inherit;
    min-height: inherit;
}
body.content-ready #content > .row,
body.content-ready #content > .show-when-ready {
    opacity: 1;
    transform: translate3d(1, 0, 0);
    -webkit-animation: showMe .7s linear;
       -moz-animation: showMe .7s linear;
         -o-animation: showMe .7s linear;
            animation: showMe .7s linear;
}
@-webkit-keyframes showMe { from { opacity: 0; } to { opacity: 1; }  }
@-moz-keyframes showMe { from { opacity: 0; } to { opacity: 1; }  }
@-o-keyframes showMe { from { opacity: 0; } to { opacity: 1; }  }
@keyframes showMe { from { opacity: 0; } to { opacity: 1; }  }
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.content .row {
    padding-right: 0;
}
.content legend + .row {
    clear: both;
}
.content form {
    padding-bottom: 60px;
}
.content .modal-content form{
    padding: 0;
}
/* Errors */
.has-error label {
    font-weight: 500;
}
.has-error .help-block { /* extend out to other areas? */
    padding: 0.5em;
    margin-top: 0;
    background-color: #fff0f0;
    border-radius: 0 0 4px 4px;
    display: inline-block;
}
.form-group.has-error .help-block {
    border: none;
    font-weight: 500;
    margin-top: calc(2px * -1);
    padding: 8px 0px;
    width: 100%;
    background-color: transparent;
    color: #a30000;
}
/* Removed right margin for RTL languages */
.is_right_to_left .is-block.form-group.has-error .help-block {
    margin-right: 0;
}
.is-block.form-group.has-error .help-block {
    /* avoid blocks stretching the entire width of the form (inc what should be margin) */
    margin-right: 16px;
    width: auto;
    display: block;
}
.checkbox.has-error .help-block{
    padding-left: 0;
    padding-bottom: 0;
    border: none;
}
/* TODO(rbezerra): check if output in live is like that (just font-size?) */
.checkbox-subtitle {
    font-size: 12px;
}
/* prominent error messaging */
#error_header {
    border: 1px solid #c00;
    font-weight: 700;
    background-color: #fff0f0;
    color: #a30000;
    background-image: none;
}
.error-block {
    border: 1px solid #c00;
    font-weight: 700;
    background-color: #fff0f0;
    color: #a30000;
    background-image: none;
}
@media (min-width: 768px) {
    .update_error_message_style #error_header ul {
        padding: 0px 24px;
    }
}
/* menu states */
.completed .glyphicon-info-sign:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-file:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-home:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-key:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .icon-key:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-camera:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-briefcase:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-list:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-cog:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-question-sign:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon-eye-open:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.completed .glyphicon.bicon-key:before {
    /* FIX ME, this will probably fail horribly for older browsers */
    content: "\e013" !important;
    color: #008009;
    font-family: 'Glyphicons Halflings';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 24px;
}
.menu .completed a {
    background-color: #70bbe9;
}
.menu .disabled {
    opacity: 0.7;
}
/* feature on view tracking */
/* alert mid */
.alert-attention {
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
    margin-top: calc(16px * -1);
}
.alert-attention p {
    color: #70bbe9 !important;
}
/* about */
#acco-type-help-small {
    background-color: #e6e6e6;
    padding: 8px;
    border-radius: 2px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    display: block;
    margin-bottom: 4px;
    position: relative;
}
#acco-type-help {
    background-color: #e6e6e6;
    padding: 8px;
    border-radius: 2px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    display: block;
    margin-bottom: 4px;
    position: relative;
}
.room-type-help {
    background-color: #e6e6e6;
    padding: 8px;
    border-radius: 2px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    display: block;
    margin-bottom: 4px;
    position: relative;
}
#acco-type-help {
    top: 16px;
}
.room-type-help {
    top: 16px;
}
#acco-type-help-small {
    top: -1px;
    margin-left: 16px;
    margin-right: 16px;
}
#acco-type-help-small:before {
    top: calc(8px * -1);
    left: 16px;
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #e6e6e6 transparent;
}
#acco-type-help:before {
    top: calc(8px * -1);
    left: 16px;
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #e6e6e6 transparent;
}
.room-type-help:before {
    content:'';
    position: absolute;
    border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid #e6e6e6;
    top: 20%;
    left: calc(8px * -1);
}
.is_right_to_left .room-type-help:before {
    left: auto;
    right: calc(16px * -1);
    border-right: 8px solid transparent;
    border-left: 8px solid #e6e6e6;
}
@media screen and (max-width:700px) {
    .room-type-help {
        top: 4px;
    }
    .room-type-help:before {
        top: calc(16px * -1);
        left: 8px;
        transform: rotate(90deg);
    }
}
.is_right_to_left #acco-type-help:before {
    left: auto;
    right: 16px;
}
.is_right_to_left #acco-type-help-small:before {
    left: auto;
    right: 16px;
}
#acco-type-help-small .help_title {
    font-weight: 500;
}
#acco-type-help .help_title {
    font-weight: 500;
}
#acco-type-help-small p,
#acco-type-help p,
.room-type-help p {
    margin: 0;
}
.acco-type-help-2:before {
    display: none;
}
.acco-type-help-2:after {
    display: none;
}
#city {
    padding-right: 40px;
}
.is_right_to_left #city {
    padding-right: 8px;
    padding-left: 40px;
}
.is_right_to_left input.search-running {
    background: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader.gif) no-repeat 3% 50%;
}
input.search-running {
    background: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader.gif) no-repeat 97% 50%;
}
#property-name .edit{
    cursor: pointer;
    position: relative;
    top: calc(4px * -1);
    left: 8px;
    display: inline-block;
    text-transform: lowercase;
}
#property-name input{
    width: 90%;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
}
/* radio toggle blocks */
.radio-block .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
.radio-block .radio label {
    display: inline-block;
    position: relative;
}
.radio-block label span {
    display: inline-block;
    border-radius: 4px;
    padding: 8px 8px 8px 30px;
    margin: 0;
    min-width: 150px;
    font-weight: 400;
}
.radio-block input[type="radio"] {
    position: absolute;
    top: 30%;
    left: 8px;
    margin: 0;
    float: none;
}
.radio-block input:checked ~ span {
    border: 1px solid #70bbe9;
    background: #fafcff;
    box-shadow: inset 0 1px 4px rgba(0,0,0,0.3);
}
.radio-block label span {
    border: 1px solid #fafcff;
    background-color: #fff;
    background-image: linear-gradient(to bottom, #fff 0,#fff0f0 100%);
}
.radio-block .radio+.radio {
    margin-left: 8px;
}
/* photo page */
.thumbnail.add img{
    width: 100px;
    height: 100px;
    background: #bdbdbd url("https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/add_icon.png") center center;
}
/* Create room page */
#room_form h4 {
    margin-bottom: 4px;
}
.edit-room.busy-loading::after {
    background: url("https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader.gif") no-repeat;
    background-size: 100% 100%;
    content: '';
    display: inline-block;
    height: 16px;
    margin-left: 16px;
    vertical-align: middle;
    width: 16px;
}
/* bedding section */
.room_block {
    /* background-color: var(--bui_color_primary_lightest); */
    /* padding: 1px 10px var(--bui_unit_small); */
    /* border-radius: var(--bui_unit_small); */
    /* margin-bottom: 20px; */
}
.room_block h3 {
    margin-top: 8px;
    margin-bottom: 16px;
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 400;
}
.bed_line {
    overflow: auto;
}
.select-bed {
    margin-left: 8px;
}
.multiplier {
    float: left;
    margin-left: calc(16px * -1);
    margin-top: 8px;
}
/* you have added too many (or not enough) rooms */
#room_totals_section {
    background-color: rgba(255, 210, 162, 0.8);
    padding: 16px 16px 8px;
    border-radius: 4px 4px 2px 2px;
    position: relative;
    margin: 0 0 32px;
    box-shadow: 1px 1px 2px #e6e6e6;
}
#room_totals_section:after {
    position: absolute;
    content: "";
    border: 10px solid transparent;
    border-top-color: #ffc489;
    top: 100%;
    left: 26%;
}
#room_totals_section .table thead>tr>th,
#room_totals_section .table tbody>tr>th,
#room_totals_section .table tfoot>tr>th,
#room_totals_section .table thead>tr>td,
#room_totals_section .table tbody>tr>td,
#room_totals_section .table tfoot>tr>td  {
    border-color: rgba(0,0,0, 0.05);
}
#room_totals_section .table tfoot>tr>td {
    background-color: rgba(254, 187, 2, 0.2);
    font-weight: 400;
}
#bottom_buttons .btn-block+.btn-block {
    margin-top: 0;
}
#bottom_buttons .btn {
    white-space: normal;
}
/* #extra_bed_checkboxes .checkbox { */
/*	padding-left: 57px; */
/* } */
#extra_bed_checkboxes label {
    font-weight: 400;
}
/* Map Notification */
#map-notification {
    display: none;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, .8);
    height: 250px;
    position: absolute;
    top: 10%;
    left: 20%;
    right: 20%;
    z-index: 999;
    border-radius: 8px;
    padding: 16px 50px;
    text-align: center;
    color: #e6e6e6;
}
.map-notification-message {
    margin-top: 32px;
    margin-bottom: 32px;
}
/* Adjust Google logo to display on Maps */
.map-wrapper-fix {
    height: 400px !important;
}
@media screen and (max-width: 302px) {
    .map-wrapper-fix {
        height: 440px !important;
    }
}
@media screen and (min-width: 303px) and (max-width: 397px) {
    .map-wrapper-fix {
        height: 420px !important;
    }
}
/* Animation classes */
.shake {
    animation-name: shake;
    animation-duration: 900ms;
    animation-fill-mode: both;
}
@-webkit-keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-10px);
    }
    20%, 40%, 60%, 80% {
        transform: translateX(10px);
    }
}
@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-10px);
    }
    20%, 40%, 60%, 80% {
        transform: translateX(10px);
    }
}
/* Thank you */
.table-responsive {
    border: none !important;
}
/* ---- Start squize content------ */
.content-compact h1 {
    font-size: 24px;
    border-bottom: none;
    padding: 0;
    font-weight: normal;
    margin: 16px 0 8px;
}
.content-compact h3 {
    font-size: 16px;
    margin-top: 8px;
}
.content-compact .page_description p {
    font-weight: normal;
    margin-bottom: 0;
    font-size: 14px;
    padding-bottom: 4px;
}
.content-compact fieldset {
    padding-top: 24px;
}
.content-compact fieldset {
    border: none;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 0em;
    padding-bottom: 8px;
}
.content-compact .roomfacilities_form fieldset {
    border: none;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 0em;
    padding-bottom: 8px;
}
.content-compact input[type="submit"] {
    margin-top: 8px;
}
.content-compact #room-cta .btn {
    margin-top: 8px;
}
.content-compact .roomfacilities_form fieldset.roomfacilities_form_header {
    border-bottom: none;
    margin-top: 8px;
}
.content-compact .roomfacilities_form fieldset:first-child,
.content-compact .roomfacilities_form fieldset:nth-child(2),
.content-compact .roomfacilities_form fieldset:nth-child(3) {
    margin-top: 0px;
}
.content-compact legend {
    font-size: 16px;
    font-weight: 700;
    color: #70bbe9;
    margin-bottom: 8px;
    display: block;
    width: 100%;
    position: relative;
    top: 16px;
}
.content-compact label {
    margin-bottom: 8px;
    font-weight: 400;
}
.ua-internet_explorer .content-compact legend {
    top: 8px;
}
.ua-internet_explorer .content-compact fieldset {
    padding-top: 8px;
}
.ua-internet_explorer .content-compact .page_description p {
    margin-bottom: 8px;
}
.ua-internet_explorer .content-compact fieldset {
    margin-bottom: 8px;
}
.content-compact .describe-block {
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    color: #6b6b6b;
    padding: 5px 8px;
    font-size: 12px;
}
.content-compact #acco-type-help-small {
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    color: #6b6b6b;
    padding: 5px 8px;
    font-size: 12px;
}
.content-compact #acco-type-help {
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    color: #6b6b6b;
    padding: 5px 8px;
    font-size: 12px;
}
.content-compact .room-type-help {
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    color: #6b6b6b;
    padding: 5px 8px;
    font-size: 12px;
}
.content-compact .describe-block {
    margin-right: 16px;
    display: inline-block;
    margin-bottom: 8px;
}
.is_right_to_left .content-compact .describe-block {
    margin-right: 0;
}
.describe-block_full,
.content-compact .describe-block_full{
    display: block;
    margin-right: 0;
}
.content-compact .cc_desc {
    color: #bad4f7;
}
.content-compact .cc_accepted .radio-block .radio{
    padding-bottom: 8px;
}
.content-compact .creditcard_section {
    padding-top: 0;
}
.content-compact>.row {
    max-width: 1100px;
}
.content-compact>.photo-content-row {
    max-width: 100%;
}
.content-compact #property-name .edit {
    margin-bottom: 0;
}
.content-compact #children, .content-compact #breakfast, .content-compact #pets {
    margin-top: 0;
}
.content-compact .lang_spoken_line {
    margin-top: 8px;
}
.content-compact .roomfacilities_form ul.row {
    box-shadow: none;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
}
.content-compact .roomfacilities_form .measurement_unit_wrapper {
    margin-bottom: 0;
}
.content-compact .roomfacilities_form label.room_name_label {
    margin-bottom: 8px;
}
.content-compact .roomfacilities_form .room_sizes.has-error {
    margin-bottom: 32px;
}
.content-compact .roomfacilities_form .room_size_wrapper {
    margin-bottom: 0;
}
.content-compact .room_size_header label {
    font-weight: 400;
}
.content-compact .room_size_header {
    margin-bottom: 4px;
}
.content-compact .roomfacilities_form ul.row .size_first {
    font-size: 16px;
}
.content-compact .message-empty {
    font-weight: 400;
}
.content-compact .photo-counter {
    font-weight: 400;
}
.content-compact #facilities_section .col-sm-6 {
    padding-top: 0;
}
.content-compact #facilities_section .describe-block {
    margin-bottom: 0;
}
.payment-policies-fields .help-block {
    margin-top: 8px;
}
.content-compact .payment-policies-fields .form-group {
    margin-bottom: 24px;
}
.payment-policies-fields.sc-input{
    padding-top: 16px;
    padding-bottom: 16px;
}
@media screen and (min-width: 1300px) {
    .content-compact {
        padding-left: 32px;
        padding-right: 45px;
    }
}
/* Start increased-contrast styles */
.alert-danger {
    border-color: #a30000;
}
.form-control {
    border: 1px solid #bdbdbd;
    height: 40px;
    box-shadow: none;
    filter: none;
    border-radius: 2px;
    background-size: 30px;
}
@media screen and (max-width: 768px) {
    .form-control {
        padding: 8px;
    }
}
select.form-control { /* Fix to prevent drop-down blinking on initial-open, on Chrome */
    transition: none;
}
.radio-block label span {
    border: 1px solid #bdbdbd;
}
.describe-block {
    background: #e6e6e6;
    color: #000;
    font-weight: normal;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}
.has-error .form-control {
    border: 1px solid #c00;
}
.has-error .form-control:focus {
    border: 1px solid #c00;
}
.has-error .form-control {
    border-color: #c00;
    box-shadow: none;
}
.has-error .help-block {
    color: #a30000;
}
.has-error .control-label {
    color: #a30000;
}
.roomfacilities_form ul.row:hover {
    background-color: #e6e6e6;
}
.roomfacilities_form ul.row .sizes label:hover,
.roomfacilities_form ul.row .sizes label:focus {
    cursor: pointer;
}
.blank-slate-content .btn-default {
    background-image: linear-gradient(to bottom, #70bbe9 0%, #bad4f7 100%);
    background-repeat: repeat-x;
    border-color: #bad4f7;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff428bca', endColorstr='#ff3071a9', GradientType=0);
    color: #fff;
    text-shadow: none;
}
.photo-upload .btn-default {
    background-image: linear-gradient(to bottom, #70bbe9 0%, #bad4f7 100%);
    background-repeat: repeat-x;
    border-color: #bad4f7;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff428bca', endColorstr='#ff3071a9', GradientType=0);
    color: #fff;
    text-shadow: none;
}
.add_photos.btn-default {
    background-image: linear-gradient(to bottom, #70bbe9 0%, #bad4f7 100%);
    background-repeat: repeat-x;
    border-color: #bad4f7;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff428bca', endColorstr='#ff3071a9', GradientType=0);
    color: #fff;
    text-shadow: none;
}
.blank-slate-content .btn-default:hover {
    background-image: none;
    background-color: #bad4f7;
    border-color: #bad4f7;
}
.blank-slate-content .btn-default:focus {
    background-image: none;
    background-color: #bad4f7;
    border-color: #bad4f7;
}
.photo-upload .btn-default:hover {
    background-image: none;
    background-color: #bad4f7;
    border-color: #bad4f7;
}
.photo-upload .btn-default:focus {
    background-image: none;
    background-color: #bad4f7;
    border-color: #bad4f7;
}
.thumbnail {
    border-color: #bdbdbd;
}
.thumbnail-wrapper .has-warnings-message .replace-photo {
    background-color: #ff8000;
}
.thumbnail-wrapper .has-warnings-message .replace-photo:hover {
    background-color: #ff8000;
}
/* End increased-contrast styles */
.social-proof {
    color: #006607;
    margin: -0.5em 0 16px;
}
/* Redesigning checkin/checkout START */
#separator {
    line-height: 30px;
    text-align: center;
    padding: 0;
    width: auto;
}
#optional {
    color: #6b6b6b;
}
#checkin .help-block,
#checkout .help-block {
    margin-bottom: 0;
}
#checkin_end + .help-block,
#checkout_start + .help-block {
    display: none !important;
}
#checkin_end + .join_require_checkin_until {
    display: block !important;
}
@media screen and (max-width: 768px) {
    .checkin_redesign {
        margin-bottom: 24px;
    }
}
/* Redesigning checkin/checkout END */
/* Compact about page */
.compact-about-form #nr_rooms {
    max-width: 60px;
}
.compact-about-form .radio-block label span{
    min-width: 100px;
}
.compact-about-form #phone, .compact-about-form #phone2 {
    max-width: 230px;
}
.compact-about-form fieldset .address-row{
    max-width: 800px;
}
/* END Compact about page */
/* welcome message — about page */
.welcome-banner {
    background: #fafcff;
    border: 1px solid #ebf3ff;
    color: #70bbe9;
    padding: 8px;
    border-radius: 2px;
}
.ua-internet_explorer .welcome-banner {
    margin-bottom: 8px;
}
.welcome-banner img {
    width: 120px;
    height: 120px;
    float: left;
    margin-right: 8px;
}
.welcome-banner-content {
    overflow: hidden;
    min-height: 120px;
}
.welcome-banner h1 {
    color: #70bbe9;
    font-size: 16px;
    font-weight: 700;
    margin: 0 32px 8px 0;
}
.close-banner {
    position: absolute;
    right: 24px;
    top: 8px;
}
.is_right_to_left .close-banner {
    right: auto;
    left: 24px;
    top: 8px;
}
@media screen and (max-width: 400px) {
   .welcome-banner img {
        display: none;
   }
}
/* END welcome message — about page */
.highlight-radio-block .radio-block input:checked ~ span {
       background-color: #e7fde9;
       text-shadow: 1px 0 rgba(255,255,255,0.7);
       border-color: #97e59c;
       box-shadow: none;
}
/* inline editable */
.inline-editable .edit {
    display: inline;
    margin: 0 0 0 4px;
}
.inline-editable .bigger_edit {
    font-size: 14px;
    font-weight: 700;
}
.inline-editing input {
    width: 100%;
}
.inline-editing .edit {
    display: none;
}
.content-compact fieldset {
    padding-top: 16px;
    margin-top: 8px;
}
.content-compact legend {
    float: left;
    padding-top: 0;
    position: static;
}
input.form-control:focus {
    border-color: #70bbe9 !important;
}
.form-control:focus {
    border-color: #70bbe9 !important;
}
.form-control:focus {
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 4px rgba(0,0,0,0.2);
}
/* highlight active fieldset */
.highlight-fieldsets fieldset.active {
    box-shadow: 0px 0px 0px 2px #70bbe9;
}
.highlight-fieldsets fieldset.active .describe-block {
    border-left: 2px solid #70bbe9;
    padding-left: 8px;
    margin-left: -3px;
}
.is_right_to_left .highlight-fieldsets fieldset.active .describe-block {
    border-right: 2px solid #70bbe9;
    border-left: 1px solid #fafcff;
    margin-left: 16px;
}
/* END highlight active fieldset */
/* sticky score */
.is-sticky .to-stick {
    width: 25%;
    z-index: 1;
    box-shadow: rgba(0, 27, 66, 0.5) 0px 1px 4px;
}
.menu .sticky-wrapper {
    z-index: 1;
}
.top_progress .block_details {
        display: none;
}
.top_progress .block_details.to_top {
    display: block;
}
.top_progress .progress_top_block {
    padding-top: 8px;
    color: #fff;
    background: #70bbe9;
    z-index: 100;
}
.top_progress .progress_top_block .progress {
    margin-bottom: 8px;
}
.top_progress .progress_top_block.checklist_v2 {
    background: #70bbe9;
    height: 50px;
}
.top_progress .progress_top_block.checklist_v2 .block_details {
    display: none;
}
.top_progress .progress_top_block.checklist_v2 .progress_top_block_checklist {
    text-align: right;
}
.top_progress .progress_top_block.checklist_v2 .progress_top_block_checklist a {
    border: 1px solid rgba(#fff, .2);
    border-radius: 4px;
    color: rgba(#fff, .85);
    display: inline-block;
    font-size: 8px;
    margin-top: 4px;
    padding: 4px 8px;
}
.top_progress .progress_top_block.checklist_v2 .progress_top_block_checklist a:hover {
    background: rgba(#fff, .1);
    color: #fff;
    text-decoration: none;
}
.top_progress .progress_top_block p.progress_status {
    margin-bottom: 0;
}
.top_progress .user {
    height: 65px;
}
.user-content-wrapper {
    padding-top: 4px;
}
.top_progress .progress_less {
    width: 75%;
    float: left;
}
@media screen and (min-width: 992px) {
    .with-sticky-nav .block_details {
        display: none;
    }
    .with-sticky-nav .block_details.to-stick {
        display: block;
    }
}
@media screen and (max-width: 992px) {
    .with-sticky-nav .block_details.to-stick, .menu .sticky-wrapper {
        display: none;
    }
    .top_progress .block_details {
        display: block;
    }
    .top_progress .progress_top_block {
        display: none;
    }
    .top_progress .user {
        height: 74px;
    }
    .top_progress .show_email {
        height: 94px;
    }
    .top_progress .progress_less {
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .top_progress .progress_less {
        width: 80%
    }
}
.agreement_copy {
    margin: 8px 0 24px 0;
    font-size: 12px;
    padding: 0;
}
.agreement_copy_ch {
    margin-top: 8px;
    font-size: 8px;
}
.notification-message:hover {
    opacity: 1;
}
.notification-message {
    position: fixed;
    top: -220px;
    right: 24px;
    z-index: 1;
    max-width: 250px;
    background: #70bbe9;
    border: 1px solid #70bbe9;
    color: #fff;
    padding: 8px;
    border-radius: 2px;
    padding-right: 24px;
    opacity: 0.9;
    font-size: 12px;
}
.notification-message .close-banner:hover {
    color: #fff;
}
.notification-message .close-banner {
    right: 4px;
    color: #5CB9FF;
}
/* hide poking image */
.hide-poke-icon .describe-text{
    margin-left: 0;
}
.hide-poke-icon .glyphicon-hand-right{
    display: none;
}
.no-web-font .content-compact .describe-block {
    color: #6b6b6b;
    font-weight: normal;
}
.no-web-font .content-compact #acco-type-help-small {
    color: #6b6b6b;
    font-weight: normal;
}
.no-web-font .content-compact #acco-type-help {
    color: #6b6b6b;
    font-weight: normal;
}
.no-web-font .content-compact .room-type-help {
    color: #6b6b6b;
    font-weight: normal;
}
.no-web-font .help-block {
    color: #6b6b6b;
    font-size: 12px;
}
.no-web-font .form-group.has-error .help-block {
    color: #a30000;
}
.no-web-font .nav>li.disabled>a {
    color: #fafcff;
}
.no-web-font .menu .active a {
    color: #70bbe9;
}
.no-web-font .menu .menu-selected a {
    color: #70bbe9;
}
.no-web-font .thumbnail-wrapper .error {
    font-weight: bold;
}
.no-web-font .edit {
    color: #70bbe9;
    font-size: 12px;
}
.form-group+.help-block {
    margin-top: -5px;
}
/* Compact footer */
.compact-footer footer {
    border-top: 1px solid #fafcff;
    background: #fafcff;
    padding-bottom: 8px;
    font-size: 13px;
}
.compact-footer ul.list-inline {
    margin-bottom: 0;
}
.compact-footer form {
    padding-bottom: 30px;
}
/* Compact nav */
.menu-small.menu ul.nav li a {
  font-size: 16px;
  font-weight: normal;
}
.menu-small .nav > li > a > .glyphicon {
    width: 20px;
}
.menu-small .glyphicon:before {
    font-size: 20px !important;
}
.menu.menu-small.no_icons .nav > li > a.completed {
  padding-left: 40px;
}
@media screen and (min-width: 992px) {
    .menu-small {
        max-width: 400px ;
    }
}
/* Progress bar with text and socre inside */
.progress-bar-success {
    background-image: none;
    background-color: #008009;
}
/* End progress bar with text and socre inside */
/* Different message for phone number */
.inline-control>div {
    float: left;
    margin-right: 8px;
}
/* Channel manager explanation */
.modal-description {
    display: inline-block;
    margin-left: 8px;
}
.modal-description a {
    border-bottom: 1px dashed #70bbe9;
    font-size: 14px;
    font-weight: normal;
}
.modal-description a:hover {
    border-bottom: 1px dashed #bad4f7;
    color: #bad4f7;
    text-decoration: none;
}
.modal-description a:active {
    border-bottom: 1px dashed #bad4f7;
    color: #bad4f7;
    text-decoration: none;
}
.modal-description a:focus {
    border-bottom: 1px dashed #bad4f7;
    color: #bad4f7;
    text-decoration: none;
}
.modal-explain p {
    line-height: 1.7;
}
/* END Channel manager explanation */
/* join_loader_sidebar_buttons */
.item-loading-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader-button.gif);
    top: 0px;
    right: 5%;
    /* To prevent overrides by other rules */
    padding: 0px !important;
    margin: 0px !important;
    border: 0px !important;
}
.item-loading-icon.item-loading-icon-light-background {
    background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader.gif);
}
/* end join_loader_sidebar_buttons */
/* Feature highlight_valid_fields */
.is_right_to_left.highlight-valid-fields .form-control {
    background-position: center left;
}
.highlight-valid-fields .form-control {
    position: relative;
    background-image: url('https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/inline_icon_yes.png');
    background-repeat: repeat-y;
    background-position: center right;
    background-size: 30px;
}
.highlight-valid-fields.enchance-correct-fields .form-control {
    background-color: #f1fef2;
    border: 1px solid #008009;
}
.optional-empty.field-optional{
  position: relative;
  background-image: none;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #fff !important;
  border: 1px solid #bdbdbd !important;
  background-size: 30px;
  height: 40px;
  border-radius: 2px;
}
.optional-empty.field-optional:focus {
    border-color: #0077cc !important;
}
.highlight-valid-fields .has-error .form-control {
    position: relative;
    background-image: none;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-color: #fff;
    border: 1px solid #A4B1C1;
}
.highlight-valid-fields select.form-control {
    position: relative;
    background-image: none;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-color: #fff;
    border: 1px solid #A4B1C1;
}
.highlight-valid-fields .has-error .form-control {
    border: 1px solid #a30000;
}
/* Feature highlight_valid_fields */
.small-cc .cc_accepted .radio-block {
    margin-bottom: -15px;
}
/* years of experience */
.nav-usp span {
    display: table-cell;
}
span.icon-tick {
    padding-right: 4px;
}
@media (max-width: 991px) {
    #content {
        background-position: 50% 40%;
    }

    .user { height: 74px; }

    .menu { margin-bottom: 8px; }
}
/* end years of experience */
/* Booking numbers for for join_booking_stats */
.b-stats-container {
    padding: 1.6em 10% .5em;
    background: #fafcff;
    border-bottom: 1px solid #fafcff;
    border-top: 1px solid #fafcff;
}
.b-stats-container:empty {
    padding-bottom: 0;
}
.b-stats-container li {
    list-style: none;
}
.b-stats {
    font-size: 1em;
    color: #70bbe9;
    line-height: 1.2;
    text-shadow: 0px 1px 0px rgba(255,255,255,0.50);
    font-weight: normal;
    border-bottom: none;
    position: relative;
    line-height: 1.3;
}
.is_right_to_left .b-stats em {
    margin-left: 0;
    margin-right: 1em;
}
.b-stats em {
    font-size: 2.2em;
    color: #70bbe9;
    line-height: 1;
    display: block;
    font-style: normal;
    margin-left: 1.5em;
    margin-bottom: .5em;

    @media (min-width: 768px) {
      margin-left: 1.1em;
      margin-bottom: .1em;
    }
}
.b-stats-icon {
    width:31px;
    height: 35px;
    position: absolute;
    left: 0;
    top: 0;
}
.is_right_to_left .b-stats-icon {
    left: auto;
    right: 0;
}
.b-stats-with-more {
    margin-top: -1em;
}
.b-stats-with-more .b-stats-icon {
    top: 1.2em;
}
@media screen and (max-width: 800px) {
    .b-stats-container li:not(:last-child) {
        margin-bottom: 0.5em;
        border-bottom: 1px solid #fafcff;
    }
}
/* End booking numbers */
/* Feature to show explanation that rooms can be edited later msg */
.compact_menu .nav li a {
    font-size: 16px;
    padding-right: 40px;
    border: none;
    border-bottom: 1px solid #235D8E;
}
.compact_menu .nav li.selected a,
.compact_menu .nav li.active a,
.compact_menu .nav li.disabled a {
    text-decoration: none;
}
.compact_menu .completed .glyphicon:before {
    font-size: 20px;
}
.compact_menu .nav > li > a > .glyphicon {
    top: 11px;
}
.compact_menu.menu.no_icons .nav > li > a.completed {
    padding-left: 40px;
}
.with-compact-menu #content:before,
.with-compact-menu .menu {
    background: #3071a9;
}
.compact_menu.menu .completed a {
    background: #1C548C;
    border-bottom: 1px solid #154873;
}
.compact_menu.menu .nav li a:hover,
.compact_menu.menu .nav li a:focus {
    background: #255E90;
}
.compact_menu .nav > li > a > .glyphicon {
    left: 0;
}
.compact_menu .nav li.active a {
    font-weight: normal;
}
@media (min-width: 1200px) {
    .col-content {
        width: 80%;
    }

    .compact_menu {
        width: 20%;
    }
}
@media (min-width: 1600px) {
    .col-content {
        width: 83%;
    }

    .compact_menu {
        width: 17%;
    }

    .compact_menu .nav li a {
        font-size: 16px;
    }
}
.update-later-message {
    position: relative;
    font-size: 12px;
    background: #e7fde9;
    z-index: 0;
    padding: 0;
}
.update-later-message--floated,
.update-later-message--floated p {
    max-width: 275px;
    width: 275px;
}
.compact-about-form ~ .update-later-message--floated {
    margin: 30px 0 0 0;
}
.update-later-message p {
    background: inherit;
    padding: 8px;
    margin: 0;
}
.update-later-message--floated[stick="true"] p {
    position: fixed;
    left: 0;
    top: 8px;
}
.update-later-message--block {
    margin-top: 8px;
    margin-bottom: 8px;
}
@media screen and (max-width: 1200px) {
    .update-later-message--floated {
        display: none;
    }

    .update-later-message--block {
        display: block;
    }
}
@media screen and (min-width: 1200px) {
    .update-later-message--block {
        display: none;
    }

    .update-later-message--floated {
        display: block;
    }
}
.photos-update-later-message .update-later-message--block {
    display: block;
}
/* End feature to show explanation that rooms can be edited later msg */
/* Feature that makes preview link available on all pages */
.preview-link-available {
    text-decoration: none !important;
    position: absolute;
    right: 16px;
    margin-top: 20px;
}
.is_right_to_left .preview-link-available {
    left: 16px;
    right: inherit;
}
.preview-link-available i {
    font-style: normal;
    font-size: 12px;
    padding-right: 4px;
}
.is_right_to_left .preview-link-available i {
    padding-left: 4px;
    padding-right: 0px;
}
/* End feature that makes preview link available on all pages */
/* Feature hat prevents preview button with long text overlaps heading */
.preview-link-full-width {
    position: initial;
    right: 0;
}
@media (max-width: 480px) {
    .preview-link-full-width {
        display: block;
    }
}
@media (max-width: 768px) {
    .preview-link-full-width {
        margin-top: 0;
    }
}
/* End feature that prevents preview button with long text overlaps heading */
nav .context-link {
    color: #ebf3ff;
    text-decoration: none;
    border-bottom: 1px dashed #ebf3ff;
}
nav .context-link:hover {
    color: #ebf3ff;
    text-decoration: none;
    border-bottom: 1px dashed #ebf3ff;
}
nav .context-link:hover {
    color: #fff
}
.modal-explain p+h4 {
    margin-top: 20px;
}
/* END What's next link */
/* New styles from radio btns */
.new-radio-style .radio-block input:checked ~ span,
.new-radio-style .radio-block span:hover {
    background: #f2f4f6;
}
.is_right_to_left .radio-block label span {
    padding-right: 30px;
}
.new-radio-style .radio-block label span {
    border: 1px solid #bdbdbd;
    background: transparent;
    box-shadow: none;
    min-width: 100px;
    display: block;
}
.payments-by-booking-explained{
    color: #70bbe9;
}
.pbb-that-is-no-problem {
    clear: both;
}
.pbb-how-it-works-question {
    margin-top: 20px;
    font-weight: bold;
}
.pbb-how-it-works .row {
    background-color: #fafcff;
    margin: 0 -14px -7px -14px;
    padding: 35px 0 20px 0;
    border-radius: 0 0 5px 4px;
}
.pbb-how-it-works__image-container {
    text-align: center;
    height: 60px;
}
.pbb-how-it-works__image-container img {
    width: 120px;
}
.no-radio-style #cc_accepted .cc_desc {
    margin-top: 2px;
}
/* End Remove styles from radio btns */
/* End Remove styles from radio btns */
.tel-input input {
    max-width: 180px;
}
/* Always available preview */
.menu .nav .active.disabled a:before, .menu .nav .active.disabled a:after {
    display: none;
}
.menu .nav .active.disabled a {
    background: transparent  !important;
    text-shadow: none;
}
/* Start experiment on changing the address structure */
.adjust-address-input-structure {
    padding: 0;
}
.adjust-address-input-structure {
    padding-right: 4px;
}
.adjust-address-image-container {
    margin: 0 auto;
    margin-top: 26px;
    padding: 15px 15px 15px 0;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #989898;
    font-size: 12px;
    width: 95%;
    text-align: center;
}
.adjust-address-image-container img {
    max-width: 180px;
    padding: 16px;
    user-drag: none;
    user-select: none;
}
.adjust-address-image-container legend {
    margin: 0;
    padding-bottom: 8px;
    color: #70bbe9;
}
@media screen and (max-width: 768px) {
    .adjust-address-image-container{
        text-align: left;
        margin-bottom: 20px;
    }

    .is_right_to_left .adjust-address-image-container {
        text-align: right;
    }

    .adjust-address-image-container p {
        margin-top: 40px;
    }

    .adjust-address-image-container img {
        display: block;
        max-width: 100%;
        padding: 0;
    }

    .adjust-address-image-container legend {
        padding-left: 0;
        padding-right: 0;
    }
}
/* End experiment on changing the address structure */
/* Start Cancellation Policies as timeline */
/* Specifications for RTL */
.is_right_to_left .cancellation-timeline-timeframe li {
    float: right;
    min-width: 16.6%;
    max-width: 16.6%;
    padding-right: 0px 10px 0px 0px;
}
.is_right_to_left .cancellation-timeline-timeframe li:first-of-type {
    margin-right: -40px;
}
.is_right_to_left .personalized-cancellation,
.is_right_to_left .nonref-cancellation {
    float: right;
}
.is_right_to_left .policy-legend {
    position: relative;
    list-style: none;
    display: block;
    float: right;
}
.is_right_to_left .policy-legend-container li:before {
    content: "";
    margin: 2px -20px 0px 0px;
}
.is_right_to_left .modal-description-cancellation-policies {
    text-align: left;
}
.is_right_to_left .cancellation-summary-left{ float: right; }
.is_right_to_left .cancellation-summary-right{ float: left; }
.free-policy {
    background-color: #008009;
}
.nonref-policy {
    background-color: #fcb4b4;
}
.personalized-policy {
    background-color: #febb02;
}
.cancellation-policy-explanation {
    position: relative;
    margin: 10px 0 0px 0;
    padding: 16px;
    background-color: #f5f5f5;
    border: 1px solid rgba(189, 189, 189, 0.3);
    border-radius: 4px;
    min-height: 273px;
}
.cancellation-policy-explanation:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -20px;
    border-bottom: 10px solid #f5f5f5;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}
.cancellation-policy-explanation-free:before { left: 40px; }
.cancellation-policy-explanation-flexible:before { left: 146px; }
.cancellation-policy-explanation-medium:before { left: 246px; }
.cancellation-policy-explanation-firm:before { left: 346px; }
.cancellation-policy-explanation-strict:before { left: 455px; }
.is_right_to_left .cancellation-policy-explanation-free:before {
    right: 40px;
}
.is_right_to_left .cancellation-policy-explanation-flexible:before {
    right: 146px;
}
.is_right_to_left .cancellation-policy-explanation-medium:before {
    right: 246px;
}
.is_right_to_left .cancellation-policy-explanation-firm:before {
    right: 346px;
}
.is_right_to_left .cancellation-policy-explanation-strict:before {
    right: 455px;
}
.cancellation-policy-explanation label {
    font-weight: bold;
}
.cancellation-timeline {
    position: relative;
    width: 100%;
    min-height: 100px;
    display: block;
    margin-top: 20px;
    margin-bottom: 40px;
}
.cancellation-timeline > ul {
    margin-bottom: 8px;
    margin-left: -40px;
}
.cancellation-timeline-timeframe li {
    min-width: 16.6%;
    max-width: 16.6%;
    min-height: 40px;
    position: relative;
    display: inline-block;
    float: left;
    color: #6D6D6D;
    font-size: 12px;
    margin-left: 0;
    padding-left: 0px 0px 0px 8px;
}
.cancellation-timeline-timeframe li:after {
    content: "";
  display: block;
  height: 20px;
  border: 1px dashed #c2c2c2;
  position: absolute;
}
.cancellation-timeline-timeframe li:first-of-type:after {
    border: none;
}
.personalized-cancellation {
    height: 16px;
    float: left;
}
.nonref-cancellation {
    height: 16px;
    float: left;
}
.personalized-cancellation {
    border-radius: 10px 0px 0px 8px;
}
.nonref-cancellation {
    background-color: #fcb4b4;
    border-radius: 0px 10px 10px 0px;
}
#nonref-cancellation:before {
    position: relative;
    display: block;
    content: "";
    width: 3px;
    height: 20px;
    background-color: #A5A5A5;
    border-right: 1px solid #f5f5f5;
    border-left: 1px solid #f5f5f5;
    top: -2px;
    left: -2px;
    float: left;
}
.nonref-cancellation:before {
    position: relative;
    display: block;
    content: "";
    width: 3px;
    height: 20px;
    background-color: #A5A5A5;
    border-right: 1px solid #f5f5f5;
    border-left: 1px solid #f5f5f5;
    top: -2px;
    left: -2px;
    float: left;
}
/* --- RTL changes for timeline */
.is_right_to_left #personalized-cancellation,
.is_right_to_left .personalized-cancellation {
    border-radius: 0px 10px 10px 0px;
}
.is_right_to_left #nonref-cancellation {
    border-radius: 10px 0px 0px 8px;
}
.is_right_to_left .nonref-cancellation {
    border-radius: 10px 0px 0px 8px;
}
.is_right_to_left #nonref-cancellation:before,
.is_right_to_left .nonref-cancellation:before {
    right: -2px;
    float: right;
}
.compare-cancellation-policy div span {
    display: block;
}
.cancellation-summary {
    color: #6D6D6D;
    font-size: 12px;
    margin-top: 8px;
}
.cancellation-summary-left { float: left; }
.cancellation-summary-right { float: right; }
.cancellation-policies-select {
    display: none;
}
.cancellation-policies-radio {
    display: block;
}
/* --- Modal for Comparing Cancellation */
.compare-cancellation-container > legend:first-of-type {
    margin-top: 20px;
}
.compare-cancellation-container > legend {
    margin-top: 16px;
    margin-bottom: 0;
}
.compare-cancellation-container > ul {
    margin-left: -40px;
}
.compare-cancellation-container span {
    color: #fff;
    font-size: 8px;
    padding: 0 8px;
}
.modal-description-cancellation-policies {
    margin: 10px 0px 10px 0px;
    position: relative;
    display: inline-block;
    text-align: right;
    width: 100%;
}
.modal-description-cancellation-policies a {
    border-bottom: 1px dashed #70bbe9;
    color: #70bbe9;
    font-size: 14px;
    font-weight: normal;
    position: relative;
}
.modal-description-cancellation-policies a:hover {
    border-bottom: 1px dashed #bad4f7;
    color: #bad4f7;
    text-decoration: none;
}
.modal-description-cancellation-policies a:active {
    border-bottom: 1px dashed #bad4f7;
    color: #bad4f7;
    text-decoration: none;
}
.modal-description-cancellation-policies a:focus {
    border-bottom: 1px dashed #bad4f7;
    color: #bad4f7;
    text-decoration: none;
}
.cancellation-timeline-timeframe-modal li:after {
    height: 300px;
    border: 1px dashed rgba(229, 229, 229, 0.5)
}
/* Mobile exceptions */
.cancellation-policy-select-fee {
    display: inline;
    width: 200px;
}
.policy-legend-container {
    display: none;
}
@media screen and (max-width: 768px) {
    .cancellation-distribution {
        width: 16px;
        height: 400px;
    }

    .personalized-cancellation {
        width: 16px;
        height: 83%;
        float: none;
        border-radius: 10px 10px 0px 0px;
    }

    .nonref-cancellation {
        background-color: #fcb4b4;
        width: 16px;
        height: 16%;
        float: none;
        border-radius: 0px 0px 10px 8px;
    }

    .cancellation-timeline-timeframe {
        float: left;
        height: 400px;
        margin-left: -10px;
    }

    #nonref-cancellation:before {
        position: relative;
        display: block;
        content: "";
        width: 20px;
        height: 3px;
        background-color: #A5A5A5;
        border-top: 1px solid #f5f5f5;
        border-bottom: 1px solid #f5f5f5;
        left: -2px;
        top: -2px;
        float: none;
    }

    .cancellation-timeline > ul {
        margin-left: -10px;
    }

    .cancellation-timeline-timeframe li {
        display: block;
        float: none;
        min-width: 100px;
        max-width: 100%;
        min-height: 16.6%;
        max-height: 16.6%;
        border-top: 1px dashed #c2c2c2;
    }

    .cancellation-timeline-timeframe li:first-of-type {
        border: 0;
    }

    .cancellation-timeline-timeframe li:after {
        content: "";
        display: block;
        width: 0;
        height: 0px;
        border: none;
    }

    /* --- RTL for mobile */

    .is_right_to_left .cancellation-timeline > ul {
        margin: 0;
        float: right;
    }

    .is_right_to_left .cancellation-timeline-timeframe li {
        float: none;
        min-width: 100px;
    }

    .is_right_to_left .cancellation-timeline-timeframe li:first-of-type {
        margin-right: 0px;
    }

    .is_right_to_left .cancellation-distribution {
        float: right;
        right: 0;
        margin-right: -165px;
    }

    .is_right_to_left #personalized-cancellation {
            border-radius: 10px 10px 0px 0px;
    }

    .is_right_to_left #nonref-cancellation {
            border-radius: 0px 0px 10px 8px;
    }

    .cancellation-timeline-timeframe-mobile { display: none; }
    .cancellation-summary{ display: none; }
    .cancellation-policies-select { display: block; }
    .cancellation-policies-radio { display: none; }
    .cancellation-policy-explanation-mobile { display: inline-block;}
    .cancellation-policy-explanation { overflow: hidden; }
    .compare-cancellation-container div { display: none; }

    /* Legend on mobile version */
    .policy-legend-container {
        margin-left: -20px;
        padding-top: 20px;
        display: block;
    }

    .policy-legend-container li {
        list-style: none;
    }

    .policy-legend-container li:before {
        width: 16px;
        height: 16px;
        position: absolute;
        border-radius: 8px;
        margin: 0 4px;
        content: "";
        margin: 2px 0px 0px -20px;
    }

    .free-policy-legend:before {
        background-color: #008009;
    }

    .personalized-policy-legend:before {
        background-color: #febb02;
    }

    .nonref-policy-legend:before {
        background-color: #fcb4b4;
    }

    .policy-legend-modal-container {
        display: none !important;
    }
}
/* End Cancellation Policies as timeline */
/* Start Styles for follow up on cancellation policies for non-payments */
.free-policy-legend:before {
    background-color: #008009;
}
.personalized-policy-legend:before {
    background-color: #febb02;
}
.nonref-policy-legend:before {
    background-color: #fcb4b4;
}
/* End Styles for follow up on cancellation policies for non-payments */
/* Start experiment photo tips as block on the right */
.block-faq-inside-funnel {
    padding: 0;
    margin-top: 45px;
    margin-bottom: 16px;
}
.block-faq-inside-funnel fieldset {
    position: absolute;
    margin-right: 16px;
}
.is_right_to_left .block-faq-inside-funnel fieldset {
    margin-left: 16px;
    margin-right: 0;
}
fieldset.message_later_settings.active {
    box-shadow: none;
}
.block-faq-inside-funnel .message_later_settings {
    font-size: 12px;
    max-width: 255px;
    background: #fff;
    border: 3px solid #E4E4E4;
    z-index: 2;
}
.block-faq-inside-funnel .message_later_settings[stick="true"] {
    position: fixed;
    margin-right: 30px;
    max-width: 15% \0; /* for IE8 */
    top: 4px;
}
.block-faq-inside-funnel .message_later_settings[stick="false"] {
    position: absolute;
}
.message_later_settings_block_bottom .message_later_settings {
    font-size: 12px;
    background: #E4FAE6;
}
.block-faq-inside-funnel .message_later_settings ul,
.message_later_settings_block_bottom .message_later_settings ul {
    padding-left: 20px;
}
.message_later_settings_block_bottom {
    display: none;
}
.proceed-button-adjustment-photo {
    padding-left: 0px;
    padding-right: 0px;
}
@media screen and (max-width: 991px) {
    .block-faq-inside-funnel fieldset {
        position: relative;
        margin-left: 16px;
        margin-top: -20px;
    }

    .block-faq-inside-funnel .message_later_settings {
        max-width: 100%;
    }
}
@media screen and (min-width: 991px) and (max-width: 1370px) {
    .block-faq-inside-funnel {
        margin-top: 65px;
    }
}
@media screen and (max-width: 1300px) {
    .block-faq-inside-funnel .message_later_settings[stick="false"] {
        margin-right: 8px;
    }
    .block-faq-inside-funnel .message_later_settings[stick="true"] {
        margin-right: 8px;
    }
}
@media screen and (max-width: 1200px) {
    .compact-about-form.col-md-9 {
        width: 100%;
    }

    .message_later_settings_block_bottom {
        display: block;
    }
}
.icon-block-faq img {
    max-width: 80px;
    margin: 0 auto;
    display: block;
    user-drag: none;
    user-select: none;
}
/* End experiment photo tips as block on the right */
.about-phone .help-block {
    max-width: 230px;
}
/* Start agreement help block */
.agreement-block_assurance_close {
    margin: 15px 35px;
}
.agreement-block_assurance_close_title {
    font-weight: bold;
}
/* End agreement help block */
.is_right_to_left .phone-container_single div:first-child,
.is_right_to_left .phone-container_double {
    float: right;
    margin-bottom: 20px;
}
.is_right_to_left .phone-container_double {
    padding-left: 16px;
}
/* Start changes for inside funnel in Accounts experiment */
/* --------- top bar changes for account experiment */
.nav_account .user {
    height: 65px;
    background-color: #70bbe9;
}
.menu .user .internal-indicator {
    background-color: #006607;
    padding: 2px 6px;
    font-weight: 700;
    font-size: 12px;
    border-radius: 2px;
    margin-left: 2px;
}
.menu .user .internal-indicator.staging {
    background-color: #ff8000;
}
.nav_account .user .join_logo {
    margin-left: 8px;
    margin-top: 5px;
}
.is_right_to_left .nav_account .user .join_logo {
    margin-right: 8px;
}
.nav_account .user .join_logo img {
    width: 130px;
}
.nav_account .language-selector {
    border: none;
    margin-top: 8px;
}
.top_progress .progress_top_block.content-compact_account{
    background-color: #70bbe9;
    height: 65px;
}
.top_progress .content-compact_account .block_details.to_top {
    display: inline-block;
    width: 90%;
    margin-top: 16px;
}
.top_progress .progress_top_block.content-compact_account p.progress_status {
    font-weight: 100;
}
.top_progress .progress_top_block.content-compact_account .progress {
    border-radius: 20px;
    height: 8px;
    margin-bottom: 2px;
}
.top_progress .progress_top_block.content-compact_account .progress .progress-bar {
    box-shadow: none;
}
.top_progress .progress_top_block.content-compact_account .progress {
    margin-top: 0px;
}
/* --------- account menu desktop */
.my-properties_access {
    position: absolute;
    top: 0px;
    right: 50px;
    height: 100%;
    border: none;
    transition: background-color 0.3s ease;
    cursor: pointer;
}
.is_right_to_left .my-properties_access {
    left: 60px;
    right: inherit;
}
.my-properties_access:hover {
    border-bottom: 2px solid #70bbe9;
    transition: background-color 0.3s ease;
}
.my-properties_access_icon {
    display: inline-block;
    width: 23px;
    height: 23px;
    background-image: url("https://q.bstatic.com/static/img/join/icon_user_login_white.png");
    background-size: cover;
    margin-top: 20px;
}
.is_right_to_left .my-properties_access_icon {
    left: 50px;
    right: inherit;
}
/* My properties menu on thankyou page */
.join-section--header.join-section--header--account {
    background-color: #70bbe9;
    border-bottom-color: red;
    border-bottom-width: 5px;
}
.my-properties_access_thankyou {
    float: right;
    position: relative;
    right: 16px;
}
.is_right_to_left .my-properties_access_thankyou {
    left: 16px;
    right: inherit;
    float: left;
}
.my-properties_access_thankyou .my-properties_access_icon {
    margin-top: 16px;
}
.my-properties_menu1 {
    background-color: #fff;
    width: 250px;
    right: 50px;
    position: absolute;
    margin-top: 13px;
    box-shadow: 1px 1px 3px #6b6b6b;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    transition: background-color 0.3s ease;
}
.is_right_to_left .my-properties_menu1 {
    left: 50px;
    right: inherit;
}
.my-properties_menu_thankyou {
    z-index: 1000;
    margin-top: 50px;
    right: 60px;
}
.is_right_to_left .my-properties_menu_thankyou {
    left: 60px;
    right: inherit;
}
.my-properties_menu_thankyou.my-properties_menu ul li a {
    padding: 0px 16px;
}
.my-properties_menu ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
}
.my-properties_menu ul li a {
    border-bottom: 1px solid #ccc;
    padding: 10px 16px;
    display: inline-block;
    width: 100%;
    color: #333;
}
.my-properties_menu ul li a:hover {
    background-color: #e6e6e6;
    text-decoration: none;
}
.my-properties_menu ul li a:last-child {
    border: none;
}
.my-properties_menu .my-properties_menu_myproperties:before {
    content: "\e021";
    font-family: 'Glyphicons Halflings';
}
.my-properties_menu .my-properties_menu_logout:before {
    content: "\e163";
    font-family: 'Glyphicons Halflings';
}
.my-properties_menu .my-properties_menu_myproperties span {
    padding-left: 8px;
}
.my-properties_menu .my-properties_menu_logout span {
    padding-left: 8px;
}
.is_right_to_left .my-properties_menu .my-properties_menu_myproperties span {
    padding-right: 8px;
}
.is_right_to_left .my-properties_menu .my-properties_menu_logout span {
    padding-right: 8px;
}
/* --------- account menu mobile/tablet */
@media screen and (max-width: 991px) {
    nav.menu-opened.nav_account ul.nav.actions {
        width: 90%;
    }

    .menu ul.nav.account-menu_mobile {
        margin-top: 0px;
    }

    .nav_account .block_details.sb {
        background-color: #70bbe9;
    }

    .nav_account.dev .block_details.sb {
        background-color: #9D2124;
    }

    .nav_account .block_details.sb .progress {
        border-radius: 20px;
        height: 8px;
    }

    .nav_account .block_details.sb .progress .progress-bar-success {
        box-shadow: none;
    }

    .menu ul.nav.account-menu_mobile li a {
        padding-left: 25px;
        font-size: 16px;
    }

    .is_right_to_left .menu ul.nav.account-menu_mobile li a {
        padding-right: 16px;
    }

    .menu.no_icons .nav.account-menu_mobile > li > a.completed {
        padding-left: 60px;
    }

    .menu.no_icons .nav.account-menu_mobile > li > a.completed:before {
        content:"\e013";
        color: #008009;
        font-family: 'Glyphicons Halflings';
        position: absolute;
        margin-left: -26px;
        margin-top: -4px;
        font-size: 16px;
    }

    .is_right_to_left .menu.no_icons .nav.account-menu_mobile > li > a.completed:before {
        margin-left: inherit;
        margin-right: -26px;
    }

    .is_right_to_left .menu.no_icons .nav.account-menu_mobile > li > a.completed {
        padding-right: 60px;
    }

    .menu.no_icons .nav.account-menu_mobile > li > a.completed > .glyphicon {
        padding-left: 25px;
    }

    .is_right_to_left .menu.no_icons .nav.account-menu_mobile > li > a.completed > .glyphicon {
        padding-right: 8px;
    }

    .menu ul.nav.account-menu_mobile li .account-menu_mobile_item {
        background-color: #70bbe9;
    }

    .menu ul.nav.account-menu_mobile .language-picker_mobile {
        color: #fff;
    }

    .nav_account ul.nav.actions li {
        max-height: 90px;
    }

    .nav_account ul.nav.actions li a {
        font-size: 16px;
        font-weight: normal;
        padding-left: 60px;
        border: none;
    }

    .is_right_to_left .nav_account ul.nav.actions li a {
        margin-right: 48px;
    }

    .menu .nav.actions .actions_myproperties .glyphicon {
        opacity: 1;
        margin-left: 25px;
        top: 16px;
    }

    .menu .nav.actions .actions_logout .glyphicon {
        opacity: 1;
        margin-left: 25px;
        top: 16px;
    }

    .menu .nav.actions .lang-select_account .glyphicon {
        opacity: 1;
        margin-left: 25px;
        top: 16px;
    }

    .menu .nav.actions .lang-select_account .glyphicon {
        margin-left: 16px;
    }

    .is_right_to_left .menu .nav.actions .actions_myproperties .glyphicon,
    .is_right_to_left .menu .nav.actions .actions_logout .glyphicon,
    .is_right_to_left .menu .nav.actions .lang-select_account .glyphicon {
        margin-right: 20px;
        padding-right: 0px;
    }

    .is_right_to_left .menu .nav.actions .lang-select_account .glyphicon {
        margin-left: 8px;
    }

    .is_right_to_left .menu .nav.actions .lang-select_account .glyphicon {
        margin-right: 8px;
    }

    .lang-select_account select {
        color: #fff;
        font-size: 16px;
        padding: 0px 10px 0px 45px;
    }

    nav .lang-select_account #lang_selector {
        margin: 0px;
    }

    .lang-select_account:after {
        content: "\e114";
        font-family: 'Glyphicons Halflings';
        color: #fff;
    }

    .lang-select_account form {
        display: inline;
    }

    .menu .nav.account-menu_mobile:before {
        border: none;
    }

    .my-properties_access_thankyou .my-properties_access_icon {
        margin-top: 30px;
    }

    .my-properties_menu_thankyou {
        margin-top: 78px;
        left: 20px;
    }

    .is_right_to_left .my-properties_menu_thankyou {
        right: 20px;
    }

    .my-properties_access_thankyou.my-properties_access:hover {
        border: none;
    }

    .join-section--header--account .mobile-join-logo.join_logo {
        padding-left: 60px;
    }

    .is_right_to_left .join-section--header--account .mobile-join-logo.join_logo {
        padding-right: 60px;
    }

    .join-section--header--account .my-properties_access_thankyou {
        left: 30px;
        position: absolute;
    }

    .is_right_to_left .join-section--header--account .my-properties_access_thankyou {
        right: 30px;
    }

    .my-properties_menu1 {
        left: 30px;
    }

    .is_right_to_left .my-properties_menu1 {
        right: 30px;
        left: inherit;
    }
}
.is_right_to_left .join_logo {
    float: right;
}
.is_right_to_left .language-selector {
    float: left;
}
.join-thankyou__center_container .content-compact_account .my-properties_access {
    display: none;
}
.top_progress .join-thankyou__center_container .progress_top_block.content-compact_account {
    background-color: #70bbe9;
}
/* End changes for inside funnel in Accounts experiment */
.footer-language-selector {
    text-align: left;
}
.is_right_to_left .footer-language-selector {
    text-align: right;
}
/* Start join_account_update_icons */
.top_progress .content-compact_account .progress-account.block_details.to_top {
    width: 74%;
}
.account-menu_updated {
    display: inline;
    float: right;
    padding: 0;
    text-align: center;
    margin-top: 4px;
}
.is_right_to_left .account-menu_updated {
    float: left;
}
.account-menu_updated li {
    list-style: none;
    display: inline-block;
}
.account-menu_updated li:first-child {
    margin-right: 4px;
}
.is_right_to_left .account-menu_updated li:first-child {
    margin-left: 4px;
    margin-right: inherit;
}
.account-menu_updated li a {
    color: #fff;
    text-decoration: none;
}
.account-menu_updated .glyphicon {
    display: block;
    padding-bottom: 4px;
}
.account-new-icons .account-menu_updated {
    margin-right: 8px;
}
.is_right_to_left .account-new-icons .account-menu_updated {
    margin-left: 8px;
    margin-right: inherit;
}
/* End join_account_update_icons */
.input-with-dropdown input {
    display: inline !important; /* We need this so the input element are inline. IE bug report: https://u.booking.com/MSexSj; */
    width: 50% !important;
    position: relative !important;
    z-index: 1;
}
.input-with-dropdown select {
    display: inline !important; /* We need this so the input element are inline. IE bug report: https://u.booking.com/MSexSj; */
    width: 50% !important;
    border-left-color: transparent !important;
    margin-left: -4px !important;
    margin-top: 1px;
}
.is_right_to_left .input-with-dropdown select {
    margin-right: -4px !important;
    border-left-color: #A4B1C1 !important;
}
.highlight-valid-fields .input-with-dropdown:before {
    content: unset;
}
.highlight-valid-fields .input-with-dropdown .form-control {
    background-image: none;
}
/* join_mpp_message_basicinfo */
.basic-info-fields #basicinfo-mpp.describe-block {
    margin-left: -16px;
    border-left: 2px solid #EEE;
}
.highlight-fieldsets fieldset.basic-info-fields.active #basicinfo-mpp.describe-block {
    border-left: 2px solid #5CB9FF;
}
.is_right_to_left .basic-info-fields #basicinfo-mpp.describe-block {
    margin-right: -16px;
    border-right: 2px solid #EEE;
}
.is_right_to_left .highlight-fieldsets fieldset.basic-info-fields.active #basicinfo-mpp.describe-block  {
    border-right: 2px solid #5CB9FF;
    border-left: none;
}
/* End: join_mpp_message_basicinfo */
/* joinapp_room_amenities_list */
.top-amenities-wrapper {
    border:1px solid #e6e6e6;
    background-color: #fff;
    margin-bottom:16px;
    padding: 8px;
}
.top-amenity-wrapper {
    font-size: 16px;
    cursor:pointer;
    padding: 8px;
    list-style: none;
}
.top-amenities-list {
    padding-left:0;
}
.ac_li_wrapper .rooms_radio_label{
    margin-bottom:0;
    padding:12px 8px;
}
.show-all-amenities-btn {
    margin:10px 0 10px 0 !important;
}
.amenities-btn-icon-closed, .amenities-btn-icon-open {
    margin-top:2px;
    display:inline-block;
}
.amenities-btn-text-closed, .amenities-btn-text-open {
    display:inline-block;
}
.amenities-title {
    margin:30px 0 20px !important;
}
.all-amenities-wrapper {
    display:none;
}
#amenities-list-wrapper {
    border:1px solid #e6e6e6;
}
.accordion_wrapper {
    border-bottom:1px solid #e6e6e6;
}
.accordion_wrapper:last-child {
    border-bottom:none;
}
.accordion_header {
    background-color: #fff;
    height: 60px;
    padding: 15px 20px;
    font-size: 20px;
}
.accordion_options_wrapper {
    max-height:400px;
    overflow-y:scroll;
    background-color:#fff;
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
    transition: height .35s;
    height: 0;
}
.accordion_options_wrapper:first-child {
    display:block;
}
.accordion_options_wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
}
.accordion_options_wrapper::-webkit-scrollbar:vertical {
    width: 11px;
}
.accordion_options_wrapper::-webkit-scrollbar:horizontal {
    height: 11px;
}
.accordion_options_wrapper::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}
.accordion_options_wrapper-top-amenities {
    height: auto;
    min-height: 0;
    min-height: 450px;
    min-height: max-content; /*https://caniuse.com/#search=fit-content*/
    padding: 0;
    margin: 0;
    overflow: auto;
}
.accordion_options_wrapper-top-amenities .accordion_row_wrapper {
    background: transparent;
    border-bottom: none;
}
.accordion_options_list {
    margin: 0;
    padding: 0;
}
.accordion_header_wrapper {
    background: #fff;
    padding: 14px 16px;
    font-size: 16px;
    margin:0;
    cursor:pointer;
    position:relative;
}
.accordion_header_wrapper:hover {
    background: #fafcff;
}
.accordion_row_wrapper {
    background: #f5f5f5;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 0px;
    overflow:auto;
}
.accordion_row_wrapper:hover {
    background: #fafcff;
}
.arw_selected {
    background: #fafcff;
}
.accordion_rooms {
    display: inline-block;
    text-align: right;
}
.accordion_somerooms_list {
    padding-bottom:10px;
}
.accordion_somerooms_list_header {
    margin-bottom:10px;
    color:#6b6b6b;
}
.accordion_icon_style i {
    color:#bdbdbd;
    position: absolute;
    right:16px;
    top:20px;
}
.top-amenities-title {
    margin-top:30px;
}
.ac_minus {
    display:none;
}
.ac_label {
    display: block;
    min-height: 100%;
    cursor:pointer;
    margin: 0 !important;
    padding:12px 0;
}
.ac_li_wrapper {
    overflow:auto;
    cursor:pointer;
}
.ac_nr_incat {
    font-size:14px;
    color:#6b6b6b;
    display: inline-block;
    padding: 0 8px;
    border-radius: 20px;
    float:right;
    margin-right:20px;
}
.accordion_wrapper:first-child ul {
    display:block;
}
.accordion_wrapper:first-child .accordion_header_wrapper .ac_plus {
    display:none;
}
.accordion_wrapper:first-child .accordion_header_wrapper .ac_minus {
    display:inline;
}
.ac_padding {
    padding:0 16px;
}
.amenities_accordion_wrapper .all-rooms-wrapper {
    margin-right: 20px;
}
.amenities_accordion_wrapper_aligned.amenities_accordion_wrapper {
    margin-top: 16px;
}
@media screen and (max-width: 991px) {
    .ac_nr_incat {
        padding:0;
        text-align:left !important;
        margin-right:0 !important;
        width:100% !important;
    }

    .is_right_to_left .ac_nr_incat {
        text-align:right !important;
    }

    .ac_cat_title {
        width:100% !important;
    }


}
/* Start join_replace_agreement_page_with_checkboxes_in_settings_page */
.updated_checkbox input[type='checkbox'] {
    -webkit-appearance: none;
    background-color: #f5f5f5;
    border: 2px solid #6b6b6b;
    padding: 7px;
    border-radius: 2px;
    display: inline-block;
    position: relative;
    outline: none;
    margin-right: 6px;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
.is_right_to_left .updated_checkbox input[type='checkbox'] {
    margin-left: 6px;
    right: 0px;
}
.updated_checkbox input[type='checkbox']:active,
.updated_checkbox input[type='checkbox']:checked:active {
    box-shadow: none;
    outline: none;
}
.updated_checkbox input[type='checkbox']:checked {
    background-color: #70bbe9;
    border: 2px solid #70bbe9;
    color: #fff;
    outline: none;
    background-size: 100% 100%;
    color: #fff;
}
.updated_checkbox input[type='checkbox']:checked:after {
    content:"\e013";
    font-family: 'Glyphicons Halflings';
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 8px;
    color: #fff;
}
.updated_checkbox .checkbox {
    display: inline-block;
    margin-bottom: 16px;
    line-height: 170%;
    width: 100%;
}
.updated_checkbox_ie,
.updated_checkbox_ie .checkbox {
    display: inline-block;
    line-height: inherit;
}
/* End: joinapp_room_amenities_list */
.join-funnel-promo-code {
    background: white;
    padding: 16px;
    padding-left: 60px;
    position: relative;
}
.join-funnel-promo-code--warning {
        margin-top: 8px;
    }
.join-funnel-promo-code--expired .join-funnel-promo-code__code {
    color: #333;
}
h3.join-funnel-promo-code__header {
    margin-top: 0;
    margin-bottom: 4px;
}
.join-funnel-promo-code__code {
    color: #008009;
    font-weight: bold;
}
.join-funnel-promo-code__title {
    margin-bottom: 0;
}
.is_right_to_left .join-funnel-promo-code {
    padding-left: 16px;
    padding-right: 60px;
}
.join-funnel-promo-code__svg {
    position: absolute;
    left: 16px;
}
.is_right_to_left .join-funnel-promo-code__svg {
    left: auto;
    right: 16px;
}
.join-funnel-promo-code--notification {
    background: transparent;
}
.join-funnel-promo-code--notification .join-funnel-promo-code__header {
        font-size: 14px;
        font-weight: bold;
    }
.join-funnel-promo-code--notification .join-funnel-promo-code__title {
        font-size: 8px;
    }
.join-funnel-promo-code__hint {
    font-size: 8px;
    color: #6b6b6b;
    margin-top: 4px;
    margin-bottom: 0;
}
/* Start: join_tablet_funnel_use_partner_help_centre_help_link */
.block_details.sb.help-link-funnel-tablet {
    width: 50%;
    height: 74px;
}
.block_details.sb.help-link-funnel-tablet .progress {
    width: 85%;
}
.topnav__menu_section.topnav__menu_section_tablet {
    float: right;
    top: -53px;
    right: -10px;
}
.join-help-block.join-help-block_tablet {
    right: inherit;
    top: 65%;
    width: 95%;
}
.help-link-funnel-tablet .join-help-block {
    right: 22px;
}
.help-link-funnel-tablet .join-help-block-title {
    color: #333;
}
@media screen and (min-width: 992px) {
    .block_details.sb.help-link-funnel-tablet {
        width: 100%;
    }
    .block_details.sb.help-link-funnel-tablet .progress,
    .block_details.sb.help-link-funnel-tablet .progress_status {
        width: 70%;
    }
    .help-link-funnel-tablet .join-help-block {
        top: 120px;
        left: 8px;
    }
    .help-link-funnel-tablet .join-help-block:before {
        left: 50%;
    }
}
/* End: join_tablet_funnel_use_partner_help_centre_help_link */
/* Chatbox css */
#join-chat-container {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 3;
}
body #content.min-height-setter {
  min-height: calc(100vh - 55px);
}
/* End Chatbox css */
.submit_persuasion {
    margin-top: 16px;
    color: #6b6b6b;
    text-align: center;
}
/* Start navigation bar for selfbuild in desktop */
@media screen and (max-width: 991px) {
    .desktop-hnav .account-menu_updated,
    .desktop-hnav .topnav__menu_section_account_1,
    .desktop-hnav .my-properties_access {
        display: none;
    }
}
@media screen and (min-width: 992px) {

    #content.desktop-hnav {
        text-align: center;
    }

    #content.desktop-hnav > .sticked-header,
    #content.desktop-hnav > .row {
        display: inline-block;
        text-align: left;
        width: 100%;
        padding: 0;
    }

    #content.desktop-hnav > .sticked-header {
        max-width: 1100px;
        padding: 0 16px;
    }

    .is_right_to_left #content.desktop-hnav > .sticked-header,
    .is_right_to_left #content.desktop-hnav > .row {
        text-align: right;
    }

    .desktop-hnav-clear-line {
        width: 100%;
        display: block;
    }

    .menu.desktop-hnav {
        text-align: center;
        background-color: transparent;
    }

    .menu.desktop-hnav > .user {
        width: 100%;
    }

    .menu.desktop-hnav .user-content-wrapper,
    .menu.desktop-hnav .nav-steps {
        display: inline-block;
        width: 100%;
        max-width: 1100px;
    }

    .desktop-hnav {
        width: 100%;
        margin: 0 auto;
    }

    .with-compact-menu .desktop-hnav {
        background-color: transparent;
    }

    .desktop-hnav .user .join_logo {
        margin-left: 22px;
        margin-right: 8px;
    }

    .desktop-hnav .account-menu_updated {
        margin: 0 8px;
    }

    .desktop-hnav .account-menu_updated .glyphicon {
        padding: 12px 0 4px 0;
    }

    .is_right_to_left .desktop-hnav .account-menu_updated {
        margin-left: 24px;
    }

    .desktop-hnav .main-nav {
        padding: 0 5px 0 21px;
        margin-bottom: 20px;
        white-space: nowrap;
        display: table;
        table-layout: fixed;
        width: 100%;
        max-width: 1116px;
        border-collapse: separate;
        border-spacing: 3px 0;
    }

    .desktop-hnav .main-nav:before,
    .desktop-hnav .main-nav:after {
        display: none;
    }

    .desktop-hnav .main-nav > li {
        display: table-cell;
        white-space: normal;
        text-align: center;
        border-bottom: 4px solid #6b6b6b;
        height: 50px;
    }

    .desktop-hnav .main-nav > li.active {
        background-color: #e6e6e6;
    }

    .desktop-hnav .main-nav > li.active a:before,
    .desktop-hnav .main-nav > li.active a:after {
        display: none;
    }

    .desktop-hnav .main-nav > li > a {
        color: #333;
        padding: 16px;
        border-bottom: 0;
        font-size: 13px;
        border-right: none;
        height: 100%;
    }

    .desktop-hnav .main-nav > li.active > a {
        color: #333;
        padding: 16px;
        border-bottom: 0;
        font-size: 13px;
        border-right: none;
        height: 100%;
    }

    .desktop-hnav .main-nav > li.disabled > a {
        color: #333;
        padding: 16px;
        border-bottom: 0;
        font-size: 13px;
        border-right: none;
        height: 100%;
    }

    .desktop-hnav .main-nav > li.completed > a {
        background-color: transparent;
        border-bottom: 0;
    }

    .desktop-hnav .main-nav > li.completed {
        border-bottom: 4px solid #70bbe9;
    }

    .desktop-hnav.menu .main-nav > li > a:focus {
        background-color: transparent;
        color: #70bbe9;
    }

    .desktop-hnav.menu .main-nav > li > a:hover {
        background-color: transparent;
        color: #70bbe9;
    }

    .desktop-hnav .main-nav > li.disabled {
        opacity: 0.5;
    }

    .desktop-hnav .main-nav > li > a > .glyphicon {
        left: 6px;
    }

    .is_right_to_left .desktop-hnav .main-nav > li > a > .glyphicon {
        left: auto;
        right: 6px;
    }

    .desktop-hnav.menu.no_icons .main-nav > li > a.completed {
        padding-left: 40px;
    }

    .desktop-hnav.menu.no_icons .main-nav > li > a.completed:before {
        content:"\e013";
        color: #008009;
        font-family: 'Glyphicons Halflings';
        position: absolute;
        margin-left: -26px;
        margin-top: -4px;
        font-size: 16px;
    }

    .is_right_to_left .desktop-hnav.menu.no_icons .main-nav > li > a.completed:before {
        margin-left: inherit;
        margin-right: -26px;
    }

    .is_right_to_left .desktop-hnav.menu.no_icons .main-nav > li > a.completed {
        padding-left: inherit;
        padding-right: 40px;
    }

    .desktop-hnav-tblock {
        position: absolute;
        top: 0;
        left: 80%;
        width: 200px;
    }

    .desktop-hnav-tblock .progress_bar_shorter {
        display: none;
    }

    .desktop-hnav .item-loading-icon {
        left: 0;
        width: 100%;
        height: 100%;
        top: 0 !important;
        background-color: #f5f5f5;
        background-position: center;
        background-repeat: no-repeat;
    }

    .desktop-hnav .item-loading-icon {
        background-image: url(https://q.bstatic.com/backend_static/common/dist/extranet_ng/static/images/loader.gif);
    }

    .desktop-hnav .my-properties_access {
        display: none;
    }

    #content.desktop-hnav .hotelpreview-wrapper {
        padding: 0 16px;
    }

    .desktop-hnav .hotelpreview {
        width: 100%;
        max-width: initial;
    }

    .desktop-hnav .hotelpreview .nophotoscontainer .col-lg-8 {
        width: 66.6666%;
    }

    .desktop-hnav #photo-upload-suggestions {
        text-align: left;
    }

    .is_right_to_left .desktop-hnav #photo-upload-suggestions {
        text-align: right;
    }

    .desktop-hnav .block_details {
        display: none;
    }

}
@media screen and (min-width: 1300px) {
    .desktop-hnav .main-nav {
        padding: 0 17px 0 7px;
    }
    .desktop-hnav .user .join_logo {
        margin-left: 3px;
    }
    .is_right_to_left .desktop-hnav .user .join_logo {
        margin-right: 21px;
    }
    .desktop-hnav .account-menu_updated {
        margin-right: 20px;
    }
    .is_right_to_left .desktop-hnav .account-menu_updated {
        margin-left: 8px;
    }
}
/* IE8 and below */
.desktop-hnav .main-nav > li {
    display: inline-block\9;
}
.desktop-hnav .main-nav > li > a {
    display: inline-block\9;
}
/* Finish navigation bar for selfbuild in desktop */
.join-section--header {
    background: #70bbe9;
}
.join-section--header  .join_logo img {
    margin-left: 0;
}
.join-content-layout {
    padding: 0;
    max-width: 1110px;
    margin: 0 auto;
    position: relative;
}
.join-header {
  min-height: 50px;
  height: 50px;
  padding: 0 8px;
  margin: 0;
  position: relative;
  line-height: 50px;
}
.join-section .language-selector {
    padding: 5px 15px 8px;
}
.join-thankyou__center_container {
    overflow: auto;
}
.join-thankyou__center_container .progress_top_block  {
    padding: 16px;
    border-radius: 4px;
    margin: 24px auto;
    float: none;
    width: 100%;
    max-width: 1100px;

}
.join-thankyou__center_container #content {
    background: transparent !important;
    float: none;
    width: 100%;
}
.join-thankyou__center_container .row {
    margin: 0 auto;

}
.join-thankyou__center_container #content.content {
    padding-left: 16px;
    padding-right: 16px;
}
.join-thankyou__center_container .regulation-link {
    text-align: center;
}
.thankyou-block--full-width {
    margin: 0 auto;
    padding: 0 !important;
}
.thankyou-block--full-width .thankyou-block {
    width: 100%;
    margin: 24px auto;
    padding: 16px;
}
.thankyou-block--full-width .row {
    padding-right: 0;
}
.thankyou-block--full-width .col-md-12 {
    padding: 0 !important;
}
#footer_wrapper {
    margin-right: 0;
}
.auto-width {
    width: auto;
}
.float-left {
    float: left;
}
@media screen and (max-width: 767px) {

    .mobile-join-logo.join_logo {
        display: block;
    }

}
/* Override the flags sprite use by IntlTellInput library */
.iti-flag {
  background-image: url("https://q.bstatic.com/static/img/join/flags.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .iti-flag {
      background-image: url("https://q.bstatic.com/static/img/join/flags%402x.png");
    }
}
.intl-tel-input {
    display: block;
}
/* alignment of header on desktop and tablet */
.alignment_header {
    padding-bottom:10px;
    overflow:auto;
}
.alignment_header_main {
    margin:0 !important;
}
.alignment_header_message {
    width:auto;
    min-width: 0;
    margin-top:0.5em;
}
.alignment_header_message p{
    width:auto;
    min-width: 0;
    margin-top:0.5em;
}
/* End alignment of header */
.width_column_same {
    max-width:245px !important;
    margin-left:15px !important;
    box-shadow: 0px 0px 0px 20px rgba(246,246,246,1);
}
/* Start join_settings_improve_help_on_page */
.side-help-block {
    background-color: #F9FBFD;
    display: block;
    padding: 16px 16px 10px 16px;
    margin-top: 8px;
    border: 1px solid #ebf3ff;
    border-radius: 2px;
    margin-left: -18px;
    font-size: 12px;
}
.side-help-block_title {
    font-weight: 500;
}
.taxes_block_spacing {
    margin-top: 16px;
}
.fees_block_spacing {
    margin-top: 32px;
}
.pbb-how-it-works.payments_unified_block .row {
    background-color: #fafcff;
    margin: 0 -16px -9px;
    padding: 0 0 24px;
    border-radius: 0 0 5px 4px;
}
.pbb-how-it-works.payments_unified_block .row.payments_unified_block_row {
    margin-top: 8px;
    padding-top: 16px;
}
.payments_unified_block .pbb-how-it-works-question {
    font-weight: normal;
    margin-top: 8px;
}
.payments_unified_block .pbb-that-is-no-problem {
    margin-top: 16px;
    font-size: 16px;
}
.invoice_address_row {
    margin-top: 16px;
}
.invoice_recipient_row {
    margin-top: 16px;
}
/* Fixes for commission usps side block */
.commission-usps-section_wrap ul.commission-usps-list {
    padding-left: 24px;
}
.is_right_to_left .commission-usps-section_wrap ul.commission-usps-list {
    padding-right: 24px;
    padding-left: inherit;
}
.commission-usps-section_wrap .commission-usps-section h4 {
    font-size: 12px;
}
.commission-usps-section_wrap .commission-usps-list li:before {
    left: 12px;
    font-size: 10px;
    line-height: 140%;
}
.is_right_to_left .side-help-block.commission-usps-section_wrap .commission-usps-list li:before {
    right: 30px;
    left: inherit;
}
#cc_accepted .cc_desc.cc_desc_new {
    float: none;
    margin: 0px;
    clear: both;
    color: #6b6b6b;
}
/* End join_settings_improve_help_on_page */
.unify-topbar-color .user {
    background-color: #70bbe9;
}
.unify-topbar-color nav .block_details {
    background-color: #70bbe9;
}
.unify-topbar-color .join-section--header {
    background-color: #70bbe9;
}
.unify-update-later-message .update-later-message {
    background: #F9FBFD;
    z-index: 0;
    padding: 8px;
    border: 1px solid #ebf3ff;
    padding: 16px;
    width: auto;
    min-width: 0;
    margin-top: 0px;
}
.unify-update-later-message .update-later-message p {
    width: auto;
    min-width: 0;
    margin-top: .5em;
    padding: 0px;
    margin: 0px;
}
.is_right_to_left.unify-update-later-message .update-later-message {
    margin-left: inherit;
    margin-right: -18px;
}
.unify-update-later-message .alignment_header_message p {
    margin-top: 0px;
    padding: 0px;
}
@media screen and (min-width: 1200px) {
    .unify-update-later-message .update-later-message {
        margin-left: -18px;
    }
}
/* ***************************************************************
 * Mobile navigation
 * *************************************************************** */
/* NEW HEADER STYLING */
@media screen and (max-width: 991px){
    .join-section--header--account.join-section--unified_header .mobile-join-logo.join_logo {
        padding-left: 0px;
        width: 120px;
    }

    .is_right_to_left .join-section--header--account.join-section--unified_header .mobile-join-logo.join_logo {
        padding-right: 0px;
    }

    .join-section--header--account.join-section--unified_header .my-properties_access {
        right: 15px;
    }

    .is_right_to_left .join-section--header--account.join-section--unified_header .my-properties_access {
        float: left;
        left: 15px;
        right: auto;
    }

    .join-section--header--account.join-section--unified_header .my-properties_access_thankyou {
        left: auto;
    }

    .join-section--header--account.join-section--unified_header .my-properties_access_icon {
        margin-top: 14px;
    }

    .join-section--header--account.join-section--unified_header .my-properties_access:hover {
        border-bottom: none;
    }

    .join-section--header--account.join-section--unified_header .my-properties_menu1 {
        right: 0;
        left: auto;
        top: 40px;
        z-index: 2;
    }

    .join-section--header--account.join-section--unified_header .my-properties_menu:before {
        content: " ";
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #fff;
        position: absolute;
        right: 16px;
        top: -10px;
        transform: rotate(45deg);
    }

    .is_right_to_left .join-section--header--account.join-section--unified_header .my-properties_menu1 {
        left: 0;
        right: auto;
    }

    .is_right_to_left .join-section--header--account.join-section--unified_header .my-properties_menu:before {
        left: 16px;
        right: auto;
    }

    .join-section--unified_header .join-header{
        height: 52px;
        line-height: initial;
    }

    .join-section--unified_header .join_logo img {
        width: 120px;
        margin-top: 20px;
    }

    .join-section--unified_header .join_logo {
        margin: 0;
    }
}
/* NEW NAV STYLING */
@media screen and (max-width: 991px){
    .menu.hor_nav_mobile .user {
        display: none;
    }

    .menu.hor_nav_mobile .menu_hamburguer {
        background-color: #70bbe9;
        padding: 0 15px;
        color: white;
    }

    .menu.hor_nav_mobile .menu_hamburguer h3 {
     /*   @apply --bui_font_display_one; */
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin: 10px 0;
    }


    .menu.hor_nav_mobile.menu-opened .menu_hamburguer .glyphicon-chevron-down {
        display: none;
    }

    .menu.hor_nav_mobile.menu-opened .menu_hamburguer .glyphicon-chevron-up {
        display: block;
    }

    .menu.hor_nav_mobile .menu_hamburguer .glyphicon {
        top: 2px;
    }

    .menu.hor_nav_mobile .menu_hamburguer .glyphicon-chevron-up {
        display: none;
    }

    .menu.hor_nav_mobile .menu_hamburguer .bk-icon {
        margin-right: 5px;
        vertical-align: -5px;
    }

    .is_right_to_left .menu.hor_nav_mobile .menu_hamburguer .bk-icon {
        margin-right: 0;
        margin-left: 5px;
    }

    .menu.hor_nav_mobile .nav:before {
        border: none;
    }

    nav.hor_nav_mobile.menu-opened ul.nav {
        width: 100%;
    }

    nav.hor_nav_mobile.menu-opened.nav_account ul.nav.actions {
        width: 100%;
    }

    .menu.hor_nav_mobile .block_details {
        background-color: transparent;
    }

    .menu.hor_nav_mobile .block_details .progress {
        margin-bottom: 0px;
        border-radius: 0px;
        height: 10px;
    }

    .menu.hor_nav_mobile .block_details .progress_status {
        display: none;
    }

    .menu.hor_nav_mobile.menu-opened .block_details {
        display: none;
    }

    .menu.hor_nav_mobile ul.nav{
        background-color: #e6e6e6;
    }

    .menu.hor_nav_mobile .nav li a:hover{
        background-color: #e6e6e6;
    }

    .menu.hor_nav_mobile .nav li a:focus{
        background-color: #e6e6e6;
    }

    .menu.hor_nav_mobile .nav li a {
        border-color: color(#6b6b6b l(85%));
    }

    .menu.hor_nav_mobile .my-properties_access {
        height: 23px;
    }

    .menu.hor_nav_mobile .nav li{
        background-color: #e6e6e6;
    }

    .menu.hor_nav_mobile .nav li .completed{
        background-color: #e6e6e6;
    }

    .menu.hor_nav_mobile .nav .active a{
        background-color: color(#6b6b6b l(85%)) !important;
        font-weight: 500;
    }

    .menu.hor_nav_mobile .nav li a{
        /*@apply --bui_font_featured*/
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #333;
        text-shadow: none;

        padding: 12px 10px 12px 42px;
    }

    .menu.hor_nav_mobile .nav li.active a:after { /* overwriting base behaviour*/
        border: none;
        top: 0;
    }

    .menu.hor_nav_mobile .nav-steps .nav .disabled {
        opacity: 1;
        color: color(#6b6b6b l(78%));
    }

    .menu.hor_nav_mobile .actions li{
        background-color: #70bbe9;
    }

    .menu.hor_nav_mobile .actions .actions_logout a {
        color: #fff;
    }

    .menu.hor_nav_mobile .block_details{
        padding: 0;
        width: 100%;
    }

    .menu.hor_nav_mobile .progress-bar-success{
        background-color: #70bbe9;
        background-image: none;
    }

    .menu.hor_nav_mobile .lang-select select {
        padding: 0;
        margin-left: 15px;
    }

    .menu.hor_nav_mobile .lang-select .glyphicon-comment,
    .menu.hor_nav_mobile .lang-select_account:after {
        display: none;
    }

}
@media screen and (min-width: 991px) {
    .join-section--unified_header {
        display: none;
    }

    .menu.hor_nav_mobile .menu_hamburguer {
        display: none;
    }

    .hor_nav_mobile > .block_details {
        display: none;
    }
}
.join-section--unified_header .internal-indicator {
    background-color: #077812;
    padding: 2px;
    font-weight: 900;
    font-size: 14px;
    border-radius: 2px;
    margin: 2px;
    color: white;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    opacity: 0.7;
}
.reward-block-wrapper {
  background: #537bb4;
}
.reward-block {
  padding: 16px 12px;
  color: #fff;
  display: table;
  table-layout: fixed;
  max-width: 1100px;
  margin: 0 auto;
}
@media (min-width: 500px) and (orientation:landscape), (min-width: 1024px) {
  .reward-block {
      padding-right: 36px;
  }

  .is_right_to_left .reward-block {
    padding-left: 36px;
    padding-right: auto;
  }
}
.reward-block__cell {
    display: table-cell;
    width: 1%;
    vertical-align: middle;
  }
.reward-block__cell--text {
    width: 98%;
    padding: 0 12px;
    text-align: left;
  }
.is_right_to_left .reward-block__cell--text {
    text-align: right;
  }
.reward-block__close-trigger {
    font-size: 22px;
    line-height: 22px;
    cursor: pointer;
  }
/* Page structure with grid */
.g-recaptcha {
    margin-bottom: 8px;
}
@media (max-width: 768px) {
    .g-recaptcha {
        transform: scale(0.77);
        transform-origin:0 0;
    }

    .is_right_to_left .g-recaptcha {
        transform-origin: 100% 0;
    }
}
.join-section.join-section--header {
    background-color: #70bbe9;
    height: 65px;
}
.with-menu_timeline.with-compact-menu #content:before {
	background: #f5f5f5;
}
.with-menu_timeline.with-compact-menu .menu {
	background: #f5f5f5;
}
.content.content-compact.col-content.content-compact_account {
    display: none;
}
.join_logo img {
    width: 130px;
    margin: 24px 0 0px 0px;
}
.is_right_to_left .join_logo img {
    padding-right: 24px;
    width: 160px;
}
.language-selector.top-lang-picker {
    display: none;
}
/* Layout for property card */
.property-card {
    background-color: #fff;
    width: 100%;
    margin-bottom: 24px;
    box-shadow: 0px 1px 1px #bdbdbd;
    margin-left: 0;
    margin-right: 0;
}
.property-card_photo {
    height: 200px;
    padding: 0px;
    background-size: cover;
}
.property-card_photo--center {
    background-position: center;
}
.is_right_to_left .property-card_photo {
    margin-right: 0px;
}
.property-card_content {
    margin-top: 16px;
    background-color: #fff;
}
.property-card_content h2 {
    margin: 0px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #333;
}
.property-card_content .address {
    color: #6b6b6b;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}
.property-card_content .property-card_progress .progress_status {
    color: #6b6b6b;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}
.property-card_content .property-card_last_change {
    color: #6b6b6b;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}
.property-card_progress {
    margin-bottom: 16px;
}
.property-card_progress .progress {
    margin-bottom: 0px;
    margin-top: 16px;
    height: 12px;
    border-radius: 16px;
}
.property-card_progress .progress .progress-bar-success {
    box-shadow: none;
}
.property-card_footer {
    width: 100%;
    position: relative;
    margin-bottom: 16px;
}
.property-card_footer .property-card_last_change {
    bottom: 0px;
    position: absolute;
    right: 0px;
    color: #bdbdbd;
}
.is_right_to_left .property-card_footer .property-card_last_change {
    left: 0px;
    right: inherit;
}
.property-card_timeline {
    width: 100%;
    overflow: auto;
    margin-bottom: 16px;
}
.property-card_timeline > span {
    display: inline-block;
    width: 33%;
    border-top: 1px solid #bdbdbd;
    margin-top: 16px;
    white-space: normal;
    padding-top: 8px;
    float: left;
    color: #6b6b6b;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}
.is_right_to_left .property-card_timeline > span {
    float: right;
}
.property-card_timeline span > span {
    width: 100%;
    display: block;
}
.property-card_timeline span > span:before {
    content: "";
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 1px solid #bdbdbd;
    position: relative;
    border-radius: 25px;
    margin-top: -21px;
    float: left;
    margin-left: 45%;
}
.property-card_timeline .property-card_timeline__done:before {
    background-color: #e6e6e6;
    border: 1px solid #bdbdbd;
}
.property-card_timeline .property-card_timeline__inprogress.property-card_timeline__done:before {
    background-color: #e6e6e6;
    border: 1px solid #bdbdbd;
}
.property-card_timeline .property-card_timeline__inprogress:before {
    background-color: #febb02;
    border: 1px solid #febb02;
}
.property-card_timeline .property-card_timeline__unavailable:before {
    background-color: #fcb4b4;
    border: 1px solid #fcb4b4;
}
.property-card_timeline_message {
    border-radius: 4px;;
    padding: 10px;
    margin-bottom: 16px;
    box-shadow: 1px 1px 2px #bdbdbd;
}
.property-card_timeline_message p {
    margin-bottom: 0px;
    color: #333;
}
.property-card_timeline_arrow {
    width: 100%;
    height: 5px;
}
.property-card_timeline_arrow div {
    width: 33%;
    height: 100%;
    float: left;
    position: relative;
}
.property-card_timeline_arrow div:before {
    content: "\A";
    border-style: solid;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid #008009;
    position: absolute;
    margin-left: 45%;
    top: 24px;;
    visibility: hidden;
}
.is_right_to_left .property-card_timeline_arrow div:before {
    margin-right: 45%;
    margin-left: inherit;
}
.property-card_timeline_arrow .timeline_current-status:before {
    visibility: visible;
}
.property-card_timeline__done_message {
    border-top: 4px solid #008009;
}
.property-card_timeline__inprogress_message.property-card_timeline__done_message {
    border-top: 4px solid #008009;
}
.property-card_timeline__done_message .property-card_timeline_arrow div:before {
    border-bottom: 8px solid #008009;
}
.property-card_timeline__inprogress_message.property-card_timeline__done_message .property-card_timeline_arrow div:before {
    border-bottom: 8px solid #008009;
}
.property-card_timeline__inprogress_message {
    border-top: 4px solid #febb02;
}
.property-card_timeline__inprogress_message .property-card_timeline_arrow div:before {
    border-bottom: 8px solid #febb02;
}
.property-card_timeline__unavailable_message {
    border-top: 4px solid #a30000;
}
.property-card_timeline__unavailable_message .property-card_timeline_arrow div:before {
    border-bottom: 8px solid #a30000;
}
@media screen and (max-width: 768px) {
    .property-card_footer a {
        width: 100%;
        margin-bottom: 8px;
    }

    .property-card_footer .property-card_last_change {
        position: relative;
        right: inherit;
    }
}
@media (min-width: 1200px) {
    .col-content {
        width: 100%;
    }
}
.with-compact-menu #content:before {
    background-color: #f5f5f5;
}
.with-compact-menu .menu {
    background-color: #f5f5f5;
}
.property-card_nextsteps a {
    margin-top: 8px;
}
.add-another-property-button_my_properties {
    width: 100%;
    height: 50px;
    text-align: right;
}
.add-another-property-button_my_properties .btn.btn-primary {
    background-color: #f5f5f5;
    color: #70bbe9;
    text-shadow: none;
    border: 1px solid #70bbe9;
}
.add-another-property-button_my_properties .btn.btn-primary:hover {
    background-color: #70bbe9;
    color: #fff;
}
.is_right_to_left .add-another-property-button_my_properties {
    text-align: left;
    padding-left: 16px;
}
.property-card_nextsteps .property-card_nextsteps_title {
    color: #333;
    padding-top: 8px;;
    padding-bottom: 0px;
    margin-bottom: 0px;
    display: inline-block;
}
/* Fixes for top bar in properties page */
.language-selector {
    height: 65px;
}
.flag_icon {
    margin-top: 8px;;
}
.my-properties_access {
    float: right;
    margin-right: 24px;
    height: 65px;
}
.is_right_to_left .my-properties_access#account-menu-access {
    float: left;
    margin-left: 0px;
}
.join-section.join-section--header.join-section--header--account1 {
    height: 90px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: red;
}
/* .my-properties_menu1 {
    z-index: 1000;
    margin-top: calc(32px*2);
} */
.my-properties_menu1 {
    margin-top: 64px;
    z-index: 1000;
}
@media screen and (max-width: 767px) {
    .join-section--header--account .join_logo {
        display: inline-block;
        margin: 4px 0px 16px 8px;
    }

    .content--wrapper_adjust .content {
        padding-left: 0px;
    }

    .is_right_to_left .my-properties_access#account-menu-access {
        float: left;
        margin-left: 0px;
        left: 24px;
    }
}
@media screen and (max-width: 991px) {
    .container.my-properties_listing {
        padding-right: 0px;
        padding-left: 0px;
    }

    .join-section--header--account .my-properties_access {
        left: 32px;
        position: absolute;
        width: 32px;
        margin-right: 0px;
    }

    .is_right_to_left .join-section--header--account .my-properties_access {
        right: 32px;
    }
}
#content:before {
    background-color: transparent;
}
/* Wrap page content and header to grid at the center of page */
#content_wrapper.content--wrapper_adjust {
    margin: 0 auto;
    max-width: 1110px;
    padding: 0px;
    position: relative;
}
.join-content-layout {
    background-color: transparent;
    padding: 0;
    max-width: 1110px;
    margin: 0 auto;
    position: relative;
}
.join-section--header.join-section--header_darker {
    background: #70bbe9;
    z-index: 1;
    color: #fff;
    padding: 0px !important;
}
/* Mare sure footer is stick to bottom of my properties page */
html {
    height: 100%;
}
body {
    min-height: 100%;
    position: relative;
}
.container.my-properties_listing {
    margin-bottom: 80px;
}
.join_thankyou__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
footer ul {
    max-width: 1100px;
    margin: 0 auto;
}
.add-property-modal-header {
    border-bottom: 0;
    margin-bottom: 0;
    padding: 24px;
    padding-bottom: 4px;
}
.add-property-modal-body {
    padding: 24px;
    padding-top: 4px;
}
.add-property-modal-button-container {
    text-align: right;
}
.is_right_to_left .add-property-modal-button-container {
    text-align: left;
}
/* Start join_account_update_icons */
.account-new-icons .account-menu_updated {
    margin-top: 8px;
}
/* End join_account_update_icons */
#content.desktop-hnav .my-properties_listing {
    text-align: left;
}
.is_right_to_left #content.desktop-hnav .my-properties_listing {
    text-align: right;
}

