
@media (max-width: 991px) {


  .header1 {
    padding: 1.5rem 2rem;
  }

  section {
    padding: 2rem;
  }
 
}

@media (max-width: 768px) {
  #line-page-body #detials {
    height: 1200px !important;
  }
  #menu-btn {
    display: inline-block;
  }
  .line-scroll-btn .bounce {
   
    display: none;
}
  .header1 .navbar {
    position: absolute;
    top: 100%;
    right: -100%;
    background: #000;
    width: 30rem;
    height: calc(100vh - 9.5rem);
  }
  .bg1 {
    /* Adjust styles for smaller screens */
    height: 300px !important; /* Adjust the height or other properties as needed for smaller screens */
    background-position: 50% 50% !important; /* Adjust background position */
}

  .line-middle {
     
      margin-top: -100px; /* Add some top margin or adjust as needed */
  }

.line-parallax {
    /* Adjust styles for smaller screens */
    background-attachment: scroll !important; /* Change background attachment for smaller screens */
    /* Adjust background size */
}
.line-wrap1 {
 display: flex;
 
 flex-direction: column;
 margin-left: 20px !important;
 width: 1200px;
}
.line-middle {
 
  top: 100%;
}
  .header1 .navbar.active {
    right: 0;
  }

  .header1 .navbar a {
    color: #fff;
    display: block;
    margin: 1.5rem;
    padding: 0.5rem;
    font-size: 2rem;
  }

  .header1 .search-form {
    width: 90%;
    right: 2rem;
  }

  .home1 {
    background-position: left;
    justify-content: center;
    text-align: center;
  }

  .home1 .content h3 {
    font-size: 4.5rem;
  }

  .home1 .content p {
    font-size: 1.5rem;
  }
}


.box-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 1.5rem;
  }
  
  .menu .box-container .box {
    padding: 5rem;
    text-align: center;
    border: 0.2rem solid #ffc107;
  }
  
  .menu .box-container .box img {
    height: 10rem;
    border-radius: 50%;
  }
  
  .menu .box-container .box h3 {
    color: #fff;
    font-size: 2rem;
    padding: 1rem 0;
  }
  
  .menu .box-container .box .price {
    color: #fff;
    font-size: 2.5rem;
    padding: 0.5rem 0;
  }
  
  .menu .box-container .box .price span {
    font-size: 1.5rem;
    text-decoration: line-through;
    font-weight: lighter;
  }
  
  .menu .box-container .box:hover {
    background: #443300;
    transition: 0.5s;
  }
  
  .products .box-container .box {
    text-align: center;
    border: 0.2rem solid #ffc107;
    padding: 2rem;
  }
  
  .products .box-container .box .icons a {
    height: 5rem;
    width: 5rem;
    line-height: 5rem;
    font-size: 2rem;
    border: 0.2rem solid #ffc107;
    color: #fff;
    margin: 0.3rem;
  }
  
  .products .box-container .box .icons a:hover {
    background: #ffc107;
  }
  
  .products .box-container .box .image {
    padding: 2.5rem 0;
  }
  
  .products .box-container .box .image img {
    height: 25rem;
  }
  
  .products .box-container .box .content h3 {
    color: #fff;
    font-size: 2.5rem;
  }
  
  .products .box-container .box .content .stars {
    padding: 1.5rem;
  }
  
  .products .box-container .box .content .stars i {
    font-size: 1.7rem;
    color: #ffc107;
  }
  
  .products .box-container .box .content .price {
    color: #fff;
    font-size: 2.5rem;
  }
 
* {
  margin: 0;
  padding: 0;
  outline: 0 none;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

  text-rendering: optimizeLegibility;
}
body {
  font: 14px/1.8 'Roboto', sans-serif;
  color: #333333;
  background: #ffffff;
  overflow-x: hidden;
}
.clear:before,
.clear:after {
  content: ' ';
  display: table;
}
.clear:after {
  clear: both;
}
.clear {
  zoom: 1;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input.input-text {
  border: 2px solid #e6e6e6;
  background: #ffffff;
  padding: 5px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: bold;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-boxshadow: none;
  font-family: 'Roboto', sans-serif;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input.input-text:focus {
  border-color: #000000;
  outline: 0;
}
textarea.line-disabled,
input[type="text"].line-disabled,
input[type="password"].line-disabled,
input[type="datetime"].line-disabled,
input[type="datetime-local"].line-disabled,
input[type="date"].line-disabled,
input[type="month"].line-disabled,
input[type="time"].line-disabled,
input[type="week"].line-disabled,
input[type="number"].line-disabled,
input[type="email"].line-disabled,
input[type="url"].line-disabled,
input[type="search"].line-disabled,
input[type="tel"].line-disabled,
input[type="color"].line-disabled,
input.input-text.line-disabled,
textarea:disabled,
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="date"]:disabled,
input[type="month"]:disabled,
input[type="time"]:disabled,
input[type="week"]:disabled,
input[type="number"]:disabled,
input[type="email"]:disabled,
input[type="url"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="color"]:disabled,
input.input-text:disabled {
  background: #f2f2f2;
  cursor: no-drop;
}
textarea {
  height: auto;
  line-height: 1.8em;
}
select {
  border: 2px solid #e6e6e6;
  height: 50px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #b3b3b3;
  font-size: 14px;
  padding: 13px;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/caret.png");
  background-position: 93% center;
  background-repeat: no-repeat;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
}
select:focus {
  border-color: #000000;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: #97c13f;
}
a:hover {
  color: #cccccc;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #cccccc;
}
a h1:hover,
a h2:hover,
a h3:hover,
a h4:hover,
a h5:hover,
a h6:hover {
  color: #cccccc;
}
p {
  margin-bottom: 20px;
}
p:last-child {
  margin-bottom: 0;
}
img {
  max-width: 100%;
  border: none;
  height: auto;
  vertical-align: top;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
img.line-thumbnail {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
img.line-thumbnail:hover {
  border-color: rgba(0, 0, 0, 0.7);
}
/* ============================================================ 
    2. LAYOUT
=============================================================*/
.line-table {
  display: table;
}
.line-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.line-left {
  float: left;
}
.line-right {
  float: right;
}
.line-center {
  text-align: center;
}
.line-wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.line-wrap:after {
  clear: both;
  content: "";
  display: block;
}
.line-wrap1 {
  /* width: 100%; */
  margin: 0 auto;
  /* position: relative; */
}
.line-wrap1:after {
  clear: both;
  content: "";
  display: block;
}
.line-wrapall {
  width: 1240px;
  margin: 0 auto;
  background: #ffffff;
}
.line-col-1 article,
.line-col-1 li,
.line-col-1 div.line-col {
  width: 100%;
}
.line-col-2 article,
.line-col-2 li,
.line-col-2 div.line-col {
  width: 50%;
}
.line-col-3 article,
.line-col-3 li,
.line-col-3 div.line-col {
  width: 33.33%;
}
.line-col-4 article,
.line-col-4 li,
.line-col-4 div.line-col {
  width: 25%;
}
.line-title {
  text-transform: uppercase;
  margin: 0 0 15px 0;
}
.line-column {
  float: left;
  padding: 0 20px;
}
.line-column.line1 {
  width: 8.33333%;
}
.line-column.line2 {
  width: 16.6667%;
}
.line-column.line3 {
  width: 25%;
}
.line-column.line4 {
  width: 33.3333%;
}
.line-column.line5 {
  width: 41.6667%;
}
.line-column.line6 {
  width: 50%;
}
.line-column.line7 {
  width: 58.3333%;
}
.line-column.line8 {
  width: 66.6667%;
}
.line-column.line9 {
  width: 75%;
}
.line-column.line10 {
  width: 83.3333%;
}
.line-column.line11 {
  width: 91.6667%;
}
.line-column.line12 {
  width: 100%;
}
.line-content,
.line-container {
  position: relative;
  padding-top: 1px;
  margin-top: -1px;
}
.line-content:after,
.line-container:after {
  clear: both;
  content: "";
  display: block;
}
.line-full {
  overflow: auto;
}
.line-space-elements {
  margin-bottom: 20px;
}
.line-demo-column {
  background: #eee;
  padding: 10px;
  text-align: center;
  display: block;
}
.line-cover {
  background-size: cover;
  background-position: center center;
}
.line-parallax {
  background-attachment: fixed;
  background-position: 50% 0;
  background-size: 100% 100%;
}
.line-middle {
  position: relative;
  text-align: center;
  left: 50%;
  top: 50%;
  z-index: 9;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
}
.line-scroll-btn a {
  width: 50px;
  height: 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -25px;
  z-index: 9;
}
.line-scroll-btn a i {
  color: #ffffff;
  line-height: 50px;
}
.line-scroll-btn .animated {
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-10px);
  }
}
.line-scroll-btn .bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
/* ============================================================ 
    3. COLOR
=============================================================*/
.red {
  background: #e74c3c;
}
.blue {
  background: #009eca;
}
.green {
  background: #97c13f;
}
.lightblue {
  background: #58c5c0;
}
.darkblue {
  background: #42555c;
}
.orange {
  background: #f6ba70;
}
.grey {
  background: #cccccc;
}
.black {
  background: #000000;
}
.white {
  background: #ffffff;
}
/* ============================================================ 
    4. BACKGROUND
=============================================================*/
.bg1 {
  background-image: url("../../assets/logo2.jpg");
  background-position: 50% 0px;
   height: 557px;
}
.bg2 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/8.jpg");
}
.bg3 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/3.jpg");
}
.bg4 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/4.jpg");
}
.bg5 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/5.jpg");
}
.bg6 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/6.jpg");
}
.bg7 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/9.jpg");
}
.bg8 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/10.jpg");
}
.bg9 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/1.jpg");
}
.bg10 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/16.jpg");
}
.bg11 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/11.jpg");
}
.bg12 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/13.jpg");
}
.bg13 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/7.jpg");
}
.bg14 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/12.jpg");
}
.bg15 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/15.jpg");
}
.bg16 {
  background-image: url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/sample/bg/14.jpg");
}
.bg-grey {
  background-color: #eee;
}
/* ============================================================ 
    5. HEADER
=============================================================*/
#line-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 36px 0;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-header.line-fixed {
  background: rgba(0, 0, 0, 0.8);
  padding: 18px 0;
}
#line-header.line-fixed .line-logo {
  top: 14px;
}
#line-header.line-fixed .line-logo img {
  width: 34px;
}
#line-header.line-fixed .line-social {
  top: 16px;
}
#line-header.line-fixed .line-icon-menu {
  top: 6px;
  right: 10px;
  border-color: transparent;
  background: none;
}
#line-header.line-fixed .line-icon-menu-x {
  top: 6px;
  border-right: none;
}
#line-header .line-logo {
  position: fixed;
  left: 50px;
  top: 15px;
}
#line-header .line-logo img {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  width: 68px;
}
#line-header .line-icon-menu {
  position: fixed;
  right: 20px;
  top: 32px;
  display: none;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #ffffff;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-header .line-icon-menu span {
  position: relative;
  top: 19px;
  height: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background: #ffffff;
  width: 24px;
  display: block;
  left: 7px;
}
#line-header .line-icon-menu span:after {
  position: absolute;
  content: "";
  width: 24px;
  height: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background: #ffffff;
  top: 10px;
  left: 0;
}
#line-header .line-icon-menu span:before {
  position: absolute;
  content: "";
  width: 24px;
  height: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background: #ffffff;
  top: -10px;
  left: 0;
}
#line-header .line-icon-menu-x {
  position: fixed;
  right: -40px;
  z-index: 99;
  top: -1px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background: #000000;
  border: 1px solid #ffffff;
  border-right: none;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-header .line-icon-menu-x span {
  position: relative;
  top: 15px;
  height: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background: #ffffff;
  width: 30px;
  display: block;
  left: 5px;
  top: 19px;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
#line-header .line-icon-menu-x span:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background: #ffffff;
  top: 0;
  left: 0;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
#line-header .line-menu {
  text-align: center;
}
#line-header .line-menu li {
  display: inline;
  position: relative;
  padding: 21px 5px;
}
#line-header .line-menu li.current-menu-item a,
#line-header .line-menu li:hover a {
  color: #97c13f;
}
#line-header .line-menu li a {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  margin: 0 10px;
  padding: 21px 0;
}
#line-header .line-menu li.parent:hover .sub-menu {
  visibility: visible;
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  display: block\9;
  top: 100%;
}
#line-header .line-menu li.parent .sub-menu {
  position: absolute;
  width: 200px;
  background: #ffffff;
  top: 120%;
  left: 50%;
  margin-left: -100px;
  padding: 5px 0;
  visibility: hidden;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  display: none\9;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-header .line-menu li.parent .sub-menu:after {
  content: "";
  position: absolute;
  border-bottom: 7px solid #ffffff;
  border-left: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid rgba(0, 0, 0, 0);
  top: -7px;
  left: 50%;
  margin-left: -7px;
}
#line-header .line-menu li.parent .sub-menu li {
  padding: 0;
}
#line-header .line-menu li.parent .sub-menu li a {
  text-transform: none;
  font-weight: normal;
  color: #000000;
  display: block;
  text-align: left;
  padding: 10px 0;
  margin: 0 15px;
}
#line-header .line-menu li.parent .sub-menu li a:hover {
  color: #97c13f;
}
#line-header .line-social {
  position: fixed;
  right: 50px;
  top: 33px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-header .line-social a {
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 0 10px;
}
#line-header .line-social a:hover {
  color: #97c13f;
}
/* ============================================================ 
    6. PAGE TITLE
=============================================================*/
#line-page-title {
  position: relative;
  margin-bottom: 50px;
  height: 544px;
}
#line-page-title .line-page-title-inner .line-title-captions h1,
#line-page-title .line-page-title-inner .line-title-captions h2,
#line-page-title .line-page-title-inner .line-title-captions h3,
#line-page-title .line-page-title-inner .line-title-captions h4,
#line-page-title .line-page-title-inner .line-title-captions h5,
#line-page-title .line-page-title-inner .line-title-captions h6 {
  color: #ffffff;
  line-height: 100%;
  margin: 0 0 10px 0;
}
#line-page-title .line-page-title-inner .line-title-captions h1:last-child,
#line-page-title .line-page-title-inner .line-title-captions h2:last-child,
#line-page-title .line-page-title-inner .line-title-captions h3:last-child,
#line-page-title .line-page-title-inner .line-title-captions h4:last-child,
#line-page-title .line-page-title-inner .line-title-captions h5:last-child,
#line-page-title .line-page-title-inner .line-title-captions h6:last-child {
  margin-bottom: 0;
}
/* ============================================================ 
    7. CONTAINER
=============================================================*/
#line-page-body {
  /* ============================================================ 
	    7.1. Portfolio
	=============================================================*/
  /* ============================================================ 
	    7.2. Blog
	=============================================================*/
  /* ============================================================ 
	    7.3. Sidebar
	=============================================================*/
  /* ============================================================ 
	    7.4. Paginator
	=============================================================*/
}
#line-page-body .line-portfolio .line-filter {
  margin-bottom: 40px;
}
#line-page-body .line-portfolio .line-filter li {
  display: inline-block;
}
#line-page-body .line-portfolio .line-filter li a {
  font-weight: bold;
  color: #b3b3b3;
  padding: 5px;
  margin: 0 10px;
}
#line-page-body .line-portfolio .line-filter li a.selected {
  color: #000000;
}
#line-page-body .line-portfolio .line-list-article .entry {
  overflow: hidden;
}
#line-page-body .line-portfolio .line-list-article .entry:hover .line-thumb {
  transform: translate3d(0px, -30px, 0px);
  -moz-transform: translate3d(0px, -30px, 0px);
  -webkit-transform: translate3d(0px, -30px, 0px);
  -ms-transform: translate3d(0px, -30px, 0px);
  -o-transform: translate3d(0px, -30px, 0px);
}
#line-page-body .line-portfolio .line-list-article .entry:hover .line-thumb img {
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
}
#line-page-body .line-portfolio .line-list-article .entry:hover .line-article-content {
  transform: translate3d(0px, -30px, 0px);
  -moz-transform: translate3d(0px, -30px, 0px);
  -webkit-transform: translate3d(0px, -30px, 0px);
  -ms-transform: translate3d(0px, -30px, 0px);
  -o-transform: translate3d(0px, -30px, 0px);
}
#line-page-body .line-portfolio .line-list-article .entry:hover .line-article-content .line-title {
  color: #000000;
}
#line-page-body .line-portfolio .line-list-article .entry:hover .line-article-content .line-title a {
  color: #000000;
}
#line-page-body .line-portfolio .line-list-article .entry .line-thumb {
  transition: transform 300ms ease-in-out 0s;
  -moz-transition: -moz-transform 300ms ease-in-out 0s;
  -webkit-transition: -webkit-transform 300ms ease-in-out 0s;
  -ms-transition: -ms-transform 300ms ease-in-out 0s;
  -o-transition: -o-transform 300ms ease-in-out 0s;
  display: block;
  background: #000000;
}
#line-page-body .line-portfolio .line-list-article .entry .line-thumb img {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-page-body .line-portfolio .line-list-article .entry .line-article-content {
  background: #ffffff;
  padding: 25px 0;
  transition: transform 300ms ease-in-out 0s;
  -moz-transition: -moz-transform 300ms ease-in-out 0s;
  -webkit-transition: -webkit-transform 300ms ease-in-out 0s;
  -ms-transition: -ms-transform 300ms ease-in-out 0s;
  -o-transition: -o-transform 300ms ease-in-out 0s;
}
#line-page-body .line-portfolio .line-list-article .entry .line-article-content .line-title {
  line-height: 100%;
  margin-bottom: 0;
  color: #b3b3b3;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 0 20px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-page-body .line-portfolio .line-list-article .entry .line-article-content .line-title a {
  color: #b3b3b3;
}
#line-page-body .line-portfolio .line-list-article .entry .line-article-content .incategory a {
  font-size: 12px;
}
#line-page-body .line-portfolio .line-content-single-portfolio .line-meta {
  text-align: left;
}
#line-page-body .line-portfolio .line-content-single-portfolio .line-meta .line-title {
  font-size: 30px;
  line-height: 100%;
  margin-bottom: 10px;
}
#line-page-body .line-portfolio .line-content-single-portfolio .line-meta .line-author {
  font-weight: bold;
  margin-bottom: 20px;
}
#line-page-body .line-portfolio .line-content-single-portfolio .line-meta .line-link {
  margin-bottom: 30px;
}
#line-page-body .line-portfolio .line-content-single-portfolio .line-meta .line-description {
  margin-bottom: 30px;
}
#line-page-body .line-portfolio .line-content-single-portfolio .line-meta .line-tags {
  display: inline-block;
  width: 100%;
}
#line-page-body .line-portfolio .line-content-single-portfolio .line-meta .line-tags a {
  border: 2px solid #e6e6e6;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 8px 20px;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  float: left;
  margin: 0 10px 10px 0;
}
#line-page-body .line-portfolio .line-content-single-portfolio .line-meta .line-tags a:hover {
  border-color: #000000;
}
#line-page-body .line-portfolio .line-nav-ajax {
  padding: 50px 0 70px 0;
  border-bottom: 2px solid #e6e6e6;
  margin-bottom: 100px;
}
#line-page-body .line-portfolio .line-nav-ajax .line-nav-hide {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 2px solid #000000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  color: #000000;
}
#line-page-body .line-portfolio .line-nav-ajax .line-nav-hide:hover {
  border-color: #97c13f;
  color: #97c13f;
}
#line-page-body .line-portfolio .line-nav-ajax .line-nav-hide i {
  line-height: 50px;
}
#line-page-body .line-portfolio .load-more {
  position: relative;
  overflow: hidden;
}
#line-page-body .line-portfolio .load-more:after {
  content: '';
  display: block;
  position: absolute;
  opacity: 0;
  top: 100%;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff url("https://www.3jon.com/demo/themeforest/wp/m/asstes/img/loader.gif") no-repeat center center;
  background-size: 32px 32px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
#line-page-body .line-portfolio.line-portfolio-loading .load-more {
  position: relative;
  overflow: hidden;
}
#line-page-body .line-portfolio.line-portfolio-loading .load-more:after {
  top: 0;
  opacity: 1;
}
#line-page-body .line-portfolio .line-portfolio-media iframe {
  border: none;
  width: 100%;
}
#line-page-body .line-portfolio .line-portfolio-media .line-portfolio-media-inner {
  width: 100%;
}
#line-page-body #line-load-ajax {
  width: 1200px;
  margin: 0 auto;
}
#line-page-body .line-blog {
  margin-bottom: 70px;
}
#line-page-body .line-blog .entry {
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 40px;
  margin-top: 70px;
}
#line-page-body .line-blog .entry:first-child {
  margin-top: 0;
}
#line-page-body .line-blog .entry .line-thumb {
  display: block;
  margin-bottom: 40px;
  background: #000000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
#line-page-body .line-blog .entry .line-thumb:hover img {
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
}
#line-page-body .line-blog .entry .line-thumb img {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
#line-page-body .line-blog .entry .entry-header .entry-title {
  font-size: 24px;
  text-transform: none;
}
#line-page-body .line-blog .entry .entry-header .entry-title a {
  color: #000000;
}
#line-page-body .line-blog .entry .entry-header .entry-title a:hover {
  color: #97c13f;
}
#line-page-body .line-blog .entry .line-entry-meta {
  margin-bottom: 20px;
}
#line-page-body .line-blog .entry .line-entry-meta .line-info,
#line-page-body .line-blog .entry .line-entry-meta .line-time,
#line-page-body .line-blog .entry .line-entry-meta .line-comments {
  display: inline-block;
  position: relative;
}
#line-page-body .line-blog .entry .line-entry-meta .line-info:last-child:after,
#line-page-body .line-blog .entry .line-entry-meta .line-time:last-child:after,
#line-page-body .line-blog .entry .line-entry-meta .line-comments:last-child:after {
  display: none;
}
#line-page-body .line-blog .entry .line-entry-meta .line-info:after,
#line-page-body .line-blog .entry .line-entry-meta .line-time:after,
#line-page-body .line-blog .entry .line-entry-meta .line-comments:after {
  content: "â€¢";
  font-size: 24px;
  color: #cccccc;
  vertical-align: middle;
  padding: 0 15px;
}
#line-page-body .line-blog .entry .line-entry-meta .line-info a,
#line-page-body .line-blog .entry .line-entry-meta .line-time a,
#line-page-body .line-blog .entry .line-entry-meta .line-comments a {
  font-weight: bold;
}
#line-page-body .line-blog .entry .more-link {
  font-weight: bold;
  color: #ffffff;
  background: #97c13f;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  display: block;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 30px;
}
#line-page-body .line-blog .entry .more-link:hover {
  background: #000000;
}
#line-page-body .line-blog .entry .entry-footer .line-section {
  padding: 50px 0;

  border-bottom: 2px solid #e6e6e6;
}
#line-page-body .line-blog .entry .entry-footer .line-section .line-title {
  text-transform: none;
  font-size: 18px;
  font-weight: normal;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-tags ul {
  display: inline-block;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-tags ul li {
  display: inline-block;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-tags ul li a {
  font-weight: bold;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-recent-post ul {
  margin: 0 -10px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-recent-post ul li {
  float: left;
  padding: 0 10px;
  margin-bottom: 50px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-recent-post ul li .line-title {
  font-size: 16px;
  line-height: 20px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-about-author .line-author-content {
  display: inline-block;
  width: 100%;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-about-author .line-author-content .line-ava {
  float: left;
  width: 100px;
  margin: 0 20px 0 0;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-about-author .line-author-content .line-ava img {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border-radius: 100px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comments .commentlist li {
  margin-bottom: 50px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comments .commentlist li .comment {
  display: inline-block;
  width: 100%;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comments .commentlist li .comment .avatar {
  float: left;
  width: 50px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comments .commentlist li .comment .avatar img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comments .commentlist li .comment .comment-container {
  margin: 0 0 0 80px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comments .commentlist li .comment .comment-container .comment-author {
  margin-bottom: 20px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comments .commentlist li .comment .comment-container .comment-author .comment-reply-link {
  color: #cccccc;
  font-weight: bold;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comments .commentlist li .children {
  margin-left: 80px;
  margin-top: 50px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comment-form {
  padding-bottom: 0;
  border: none;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comment-form input {
  width: 50%;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comment-form textarea {
  width: 100%;
  height: 100px;
}
#line-page-body .line-blog .entry .entry-footer .line-section.line-comment-form button {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: bold;
}
#line-page-body .line-has-sidebar.line-sidebar-right .line-page-content {
  float: left;
  padding-right: 30px;
}
#line-page-body .line-has-sidebar.line-sidebar-right .line-page-sidebar {
  float: right;
  padding-left: 30px;
}
#line-page-body .line-has-sidebar.line-sidebar-left .line-page-content {
  float: right;
  padding-left: 30px;
}
#line-page-body .line-has-sidebar.line-sidebar-left .line-page-sidebar {
  float: left;
  padding-right: 30px;
}
#line-page-body .line-has-sidebar .line-page-content {
  width: 70%;
}
#line-page-body .line-has-sidebar .line-page-sidebar {
  width: 30%;
}
#line-page-body .line-paginator {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
}
#line-page-body .line-paginator .page-numbers {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 35px;
  border: 2px solid #cccccc;
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin: 0 10px 0 0;
  color: #cccccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
#line-page-body .line-paginator .page-numbers:hover {
  border-color: #97c13f;
}
#line-page-body .line-paginator .page-numbers.next,
#line-page-body .line-paginator .page-numbers.prev {
  overflow: hidden;
}
#line-page-body .line-paginator .page-numbers.next:before,
#line-page-body .line-paginator .page-numbers.prev:before {
  content: "\f105";
  display: block;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
}
#line-page-body .line-paginator .page-numbers.prev:before {
  content: "\f104";
}
#line-page-body .line-paginator .current {
  background: #97c13f;
  color: #ffffff;
  border-color: #97c13f;
}
#line-page-body .line-single-nav {
  position: fixed;
  height: 100px;
  top: 50%;
  margin-top: -50px;
  background: rgba(0, 0, 0, 0.2);
  padding: 20px 15px;
  z-index: 999;
  text-align: left;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-page-body .line-single-nav:hover {
  background: rgba(0, 0, 0, 0.9);
}
#line-page-body .line-single-nav:hover .post-entry {
  width: auto;
}
#line-page-body .line-single-nav i {
  font-size: 28px;
  color: #ffffff;
  position: absolute;
  height: 28px;
  line-height: 28px;
  top: 50%;
  margin-top: -14px;
}
#line-page-body .line-single-nav .post-entry {
  display: block;
  overflow: hidden;
  width: 0;
}
#line-page-body .line-single-nav .post-entry .post-entry-inner {
  display: table;
  width: 200px;
}
#line-page-body .line-single-nav .post-entry .post-entry-inner .line-title {
  color: #ffffff;
  display: table-cell;
  vertical-align: middle;
  font-family: 'Roboto', sans-serif;
  text-transform: none;
  font-size: 0.9em;
}
#line-page-body .line-single-nav .post-entry .post-entry-inner .img {
  display: table-cell;
  vertical-align: middle;
  width: 60px;
  height: 60px;
}
#line-page-body .line-single-nav .post-entry .post-entry-inner .img img {
  display: block;
}
#line-page-body .line-single-nav.post-next {
  right: 0;
  text-align: right;
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
  -ms-border-radius: 2px 0 0 2px;
  -o-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
}
#line-page-body .line-single-nav.post-next i {
  right: 10px;
}
#line-page-body .line-single-nav.post-next .post-entry .post-entry-inner {
  margin: 0 20px 0 0;
}
#line-page-body .line-single-nav.post-prev {
  left: 0;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  -ms-border-radius: 0 2px 2px 0;
  -o-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
#line-page-body .line-single-nav.post-prev i {
  left: 10px;
}
#line-page-body .line-single-nav.post-prev .post-entry .post-entry-inner {
  margin: 0 0 0 20px;
}
#line-page-body .line-section {
  padding-top: 100px;
}
#line-page-body #line-home {
  position: relative;
  background-color: #000000;
  overflow-x: hidden;
}
#line-page-body #line-home .line-hello h1,
#line-page-body #line-home .line-hello h2,
#line-page-body #line-home .line-hello h3,
#line-page-body #line-home .line-hello h4,
#line-page-body #line-home .line-hello h5,
#line-page-body #line-home .line-hello h6 {
  color: #3a93d7;
  line-height: 100%;
  margin: 0 0 10px 0;
}
#line-page-body #line-home .line-hello h1:last-child,
#line-page-body #line-home .line-hello h2:last-child,
#line-page-body #line-home .line-hello h3:last-child,
#line-page-body #line-home .line-hello h4:last-child,
#line-page-body #line-home .line-hello h5:last-child,
#line-page-body #line-home .line-hello h6:last-child {
  margin-bottom: 0;
  color: rgb(201, 93, 93);
}
#line-page-body #detials {
  position: relative;
  overflow: hidden;
  height: 617px;
}
#line-page-body #detials a:hover h1,
#line-page-body #detials a:hover h2,
#line-page-body #detials a:hover h3,
#line-page-body #detials a:hover h4,
#line-page-body #detials a:hover h5,
#line-page-body #detials a:hover h6 {
  color: #97c13f;
}
#line-page-body #detials a h1,
#line-page-body #detials a h2,
#line-page-body #detials a h3,
#line-page-body #detials a h4,
#line-page-body #detials a h5,
#line-page-body #detials a h6 {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
#line-page-body #line-funfact {
  padding: 100px 0;
}
#line-page-body #line-funfact h1,
#line-page-body #line-funfact h2,
#line-page-body #line-funfact h3,
#line-page-body #line-funfact h4,
#line-page-body #line-funfact h5,
#line-page-body #line-funfact h6 {
  color: #ffffff;
}
#line-page-body #line-funfact .line-funfact .line-title {
  line-height: 100%;
  font-size: 60px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
}
#line-page-body #line-funfact .line-funfact .line-title .line-count-numb {
  color: #97c13f;
  margin: 20px 0 5px 0;
  display: block;
}
#line-page-body #line-tw-feed {
  padding: 100px 0;
  color: #ffffff;
  font-size: 18px;
}
#line-page-body #line-tw-feed p {
  word-wrap: break-word;
}
#line-page-body #line-tw-feed .line-icons {
  margin-bottom: 50px;
}
#line-page-body #line-tw-feed .line-icons i {
  font-size: 56px;
}
#line-page-body .line-background-video {
  position: relative;
  overflow: hidden;
}
#line-page-body .line-background-video .YTPOverlay {
  cursor: pointer;
}
#line-page-body .line-background-video .line-controls h1,
#line-page-body .line-background-video .line-controls h2,
#line-page-body .line-background-video .line-controls h3,
#line-page-body .line-background-video .line-controls h4,
#line-page-body .line-background-video .line-controls h5,
#line-page-body .line-background-video .line-controls h6 {
  color: #ffffff;
}
#line-page-body .line-background-video .line-controls a {
  color: #ffffff;
  display: inline-block;
  margin: 10px;
  height: 50px;
  width: 50px;
  border: 3px solid #ffffff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
}
#line-page-body .line-background-video .line-controls a i {
  line-height: 45px;
}
#line-page-body .line-background-video .line-controls .line-pause a {
  border-color: #97c13f;
  color: #97c13f;
}
#line-page-body #line-subscribe {
  background: #97c13f;
  padding: 45px 0;
}
#line-page-body #line-subscribe p {
  margin: 0;
}
#line-page-body #line-subscribe .line-input {
  width: 65%;
  width: 60%\9;
  float: left;
  height: 60px;
  line-height: 60px;
  border: none;
  border: 2px solid #fff9;
  background: none;
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}
#line-page-body #line-subscribe .line-input::-webkit-input-placeholder {
  color: #ffffff;
}
#line-page-body #line-subscribe .line-input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}
#line-page-body #line-subscribe .line-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}
#line-page-body #line-subscribe .line-input:-ms-input-placeholder {
  color: #ffffff;
}
#line-page-body #line-subscribe .line-btn {
  width: 35%;
  float: right;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  border-color: #ffffff;
  background: rgba(0, 0, 0, 0.15);
  color: #ffffff;
  overflow: hidden;
}
#line-page-body #line-subscribe .line-btn:hover {
  background: rgba(0, 0, 0, 0.25);
}
#line-page-body #line-subscribe .line-alert {
  margin-bottom: 40px;
}
#line-page-body #line-subscribe .error {
  position: absolute;
  left: 20px;
  bottom: -25px;
}
#line-page-body .line-countdown > div {
  display: inline-block;
  font-size: 5em;
  padding: 0 30px;
  margin-top: 20px;
  line-height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #ffffff;
}
#line-page-body .line-countdown > div span {
  display: block;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
}
#line-page-body .line-recent-post .entry {
  text-align: left;
}
#line-page-body .line-recent-post .line-thumb {
  margin-bottom: 35px;
}
#line-page-body .line-recent-post .line-thumb img {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
#line-page-body .line-recent-post .line-entry-header .line-title {
  text-transform: none;
  font-size: 16px;
  font-weight: normal;
}
#line-page-body .line-recent-post .line-entry-header .line-title a {
  color: #000000;
}
#line-page-body .line-recent-post .line-entry-footer {
  border-top: 2px solid #e6e6e6;
  padding-top: 20px;
  margin-top: 20px;
}
#line-page-body .line-recent-post .line-entry-footer .line-meta a {
  font-weight: bold;
}
#line-page-body .line-recent-post .line-entry-footer .line-time {
  font-weight: bold;
  color: #b3b3b3;
}
#line-page-body #line-contact {
  padding: 100px 0;
  color: #ffffff;
  position: relative;
}
#line-page-body #line-contact .line-title {
  color: #ffffff;
}
#line-page-body #line-contact .contact-form {
  margin-top: 50px;
  text-align: left;
}
#line-page-body #line-contact .contact-form p {
  margin-bottom: 40px;
}
#line-page-body #line-contact .contact-form label.error {
  font-style: italic;
  margin-top: 7px;
}
#line-page-body #line-contact .contact-form input,
#line-page-body #line-contact .contact-form textarea {
  width: 100%;
  border-color: #ffffff;
  background: rgba(0, 0, 0, 0.15);
  color: #ffffff;
  padding: 0 15px;
}
#line-page-body #line-contact .contact-form input:focus,
#line-page-body #line-contact .contact-form textarea:focus {
  background: rgba(0, 0, 0, 0.45);
}
#line-page-body #line-contact .contact-form textarea {
  height: 230px;
  padding-top: 10px;
}
#line-page-body #line-contact .contact-form .line-btn {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  background: rgba(0, 0, 0, 0.15);
  border: none;
}
#line-page-body #line-contact .contact-form .line-btn:hover {
  background: rgba(0, 0, 0, 0.45);
}
#line-page-body #line-contact .contact-form .line-alert {
  margin-bottom: 40px;
}
#line-page-body .line-404 {
  color: #ffffff;
}
#line-page-body .line-404 .line-input {
  background: none;
  color: #ffffff;
  vertical-align: middle;
  border-color: #ffffff;
}
#line-page-body .line-404 .line-input:focus {
  background-color: rgba(0, 0, 0, 0.3);
}
#line-page-body .line-404 .line-btn {
  font-weight: bold;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  margin: 0 0 0 5px;
}

#line-content-bottom {
  border-bottom: 2px solid #e6e6e6;
  background: #ffffff;
  padding: 70px 0;
}
#line-content-bottom .line-title {
  font-size: 14px;
}
#line-content-bottom .widget {
  margin-bottom: 0;
}

#line-footer {
  background: #ffffff;
  padding: 50px 0;
}
#line-footer .copyright {
  float: left;
  margin-top: 12px;
}
#line-footer .copyright a {
  font-weight: bold;
}
#line-footer #line-gotop {
  width: 50px;
  height: 50px;
  text-align: center;
  border: 2px solid #000000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  color: #000000;
  display: inline-block;
  float: right;
}
#line-footer #line-gotop:hover {
  color: #97c13f;
  border-color: #97c13f;
}
#line-footer #line-gotop i {
  line-height: 50px;
}
/* ============================================================ 
    10. WIDGET STYLE
=============================================================*/
.widget {
  margin-bottom: 70px;
}
.widget ul li {
  margin-bottom: 10px;
}
.widget ul li a {
  font-weight: bold;
  color: #b3b3b3;
}
.widget ul li a:hover {
  color: #97c13f;
}
.widget.widget_search form:after {
  content: "\f002";
  font-family: 'FontAwesome';
  position: absolute;
  right: 15px;
  top: 15px;
  color: #cccccc;
}
.widget.widget_search form input {
  width: 100%;
}
.widget.widget_tag_cloud .tagcloud {
  display: inline-block;
  width: 100%;
}
.widget.widget_tag_cloud .tagcloud a {
  border: 2px solid #e6e6e6;
  color: #000000;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 7px 15px;
  float: left;
  margin: 0 10px 10px 0;
  font-size: 14px !important;
}
.widget.widget_tag_cloud .tagcloud a:hover {
  border-color: #97c13f;
}
.widget.widget_line_instagram .line-img .line-thumb {
  background: #000000;
  width: 48%;
  float: left;
  margin: 0 4% 4% 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.widget.widget_line_instagram .line-img .line-thumb:nth-child(2n) {
  margin-right: 0;
}
.widget.widget_line_instagram .line-img .line-thumb:hover img {
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  -webkit-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  filter: alpha(opacity=50);
}
.widget.widget_line_instagram .line-img .line-thumb img {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
/* ============================================================ 
    11. CUSTOM
=============================================================*/
#line-home h1 span {
  color: #e2d95e;
}
#detials .line-middle {
  width: 100%;
  
}
#line-work .line-space {
  padding: 40px 0 70px 0;
}
#line-services {
  padding-bottom: 80px;
}
#line-services .line-carousel {
  margin-top: 50px;
}
#line-pricing .line-pricing-table {
  margin: 50px 0 100px 0;
}
#line-bg-video {
  height: 500px;
}
#line-bg-video-2 {
  height: 100%;
}
#line-client {
  padding-bottom: 60px;
}
#line-client .line-carousel-one {
  margin-top: 50px;
}
#line-recent-post .line-carousel {
  margin-top: 50px;
}
#line-recent-post .line-space {
  padding: 50px 0 100px 0;
}
.isotope {
  -webkit-transition: all 0.8s ease-in-out 0s;
  -moz-transition: all 0.8s ease-in-out 0s;
  -o-transition: all 0.8s ease-in-out 0s;
  -ms-transition: all 0.8s ease-in-out 0s;
  transition: all 0.8s ease-in-out 0s;
}
.isotope .isotope-item {
  -webkit-transition: all 0.8s ease-in-out 0s;
  -moz-transition: all 0.8s ease-in-out 0s;
  -o-transition: all 0.8s ease-in-out 0s;
  -ms-transition: all 0.8s ease-in-out 0s;
  transition: all 0.8s ease-in-out 0s;
  z-index: 2;
}
.isotope .isotope-item.isotope-hidden {
  pointer-events: none;
  z-index: 1;
}
.line-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 999999999;
  background: #fff;
}
.line-loader img {
  width: 64px;
  height: 64px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
}
.loader {
  font-size: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -5em 0 0 -5em;
  text-indent: -9999em;
  border: 4px solid #ccc;
  border-left-color: #000;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
body .nivo-lightbox-theme-default .nivo-lightbox-close {
  width: 21px;
  height: 21px;
}

.line-accordion.line-style {
  background: #ffffff;
}
.line-accordion.line-style .accordion-title.active a {
  color: #000000;
  border-color: #000000;
}
.line-accordion.line-style .accordion-title.active a:after {
  border-right: 5px solid transparent;
  border-top: 5px solid #000000;
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;
  top: 25px;
}
.line-accordion.line-style .accordion-title a {
  padding: 10px 15px;
  display: block;
  color: #b3b3b3;
  position: relative;
  border: 2px solid #e6e6e6;
  font-weight: bold;
  margin-bottom: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.line-accordion.line-style .accordion-title a:after {
  border-bottom: 5px solid transparent;
  border-left: 5px solid #cccccc;
  border-top: 5px solid transparent;
  content: "";
  height: 0;
  margin-top: -5px;
  position: absolute;
  right: 15px;
  top: 50%;
  width: 0;
}
.line-accordion.line-style .accordion-container {
  padding: 15px 0 20px 0;
}

.line-alert {
  padding: 20px;
  color: #ffffff;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.line-alert.error {
  background-color: #e74c3c;
}
.line-alert.success {
  background-color: #97c13f;
}
.line-alert.info {
  background-color: #009eca;
}
.line-alert.notice {
  background-color: #f6ba70;
}
.line-alert a.close {
  width: 24px;
  height: 24px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -12px;
  color: #ffffff;
}
.line-alert a.close:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.line-alert a.close i {
  line-height: 22px;
}

.line-banner {
  text-align: center;
}
.line-banner a {
  display: block;
  position: relative;
  overflow: hidden;
}
.line-banner a:hover img {
  transform: scale(1.2) rotateZ(-5deg);
  -webkit-transform: scale(1.2) rotateZ(-5deg);
  -o-transform: scale(1.2) rotateZ(-5deg);
  -ms-transform: scale(1.2) rotateZ(-5deg);
  -moz-transform: scale(1.2) rotateZ(-5deg);
}
.line-banner a img {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.line-banner a .line-mask {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.line-banner a .line-mask:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.line-banner a .line-mask .line-mask-content {
  display: inline-block;
  height: auto;
  vertical-align: middle;
  width: 80%;
}
.line-banner a .line-mask .line-mask-content .line-title-banner {
  color: #ffffff;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  display: inline-block;
  font-size: 2.5em;
}

blockquote {
  padding: 40px;
  text-align: center;
  background: #97c13f;
  color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  position: relative;
}
blockquote:after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #97c13f;
  bottom: -10px;
  content: "";
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 0;
}

.line-elements .line-btn,
.line-elements input[type="submit"] {
  margin: 0 10px 10px 0;
}
.line-btn,
input[type="submit"],
button[type="submit"] {
  font-weight: bold;
  color: #000000;
  background-image: none;
  background-color: #ffffff;
  height: 50px;
  line-height: 50px;
  padding: 0 50px;
  cursor: pointer;
  border: 2px solid #000000;
  white-space: nowrap;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  display: inline-block;
}
.line-btn.small,
input[type="submit"].small,
button[type="submit"].small {
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
}
.line-btn.big,
input[type="submit"].big,
button[type="submit"].big {
  height: 60px;
  line-height: 60px;
  padding: 0 70px;
}
.line-btn.blue,
input[type="submit"].blue,
button[type="submit"].blue {
  background: #009eca;
  border: none;
  color: #ffffff;
}
.line-btn.lightblue,
input[type="submit"].lightblue,
button[type="submit"].lightblue {
  background: #58c5c0;
  border: none;
  color: #ffffff;
}
.line-btn.darkblue,
input[type="submit"].darkblue,
button[type="submit"].darkblue {
  background: #42555c;
  border: none;
  color: #ffffff;
}
.line-btn.red,
input[type="submit"].red,
button[type="submit"].red {
  background: #e74c3c;
  border: none;
  color: #ffffff;
}
.line-btn.green,
input[type="submit"].green,
button[type="submit"].green {
  background: #97c13f;
  border: none;
  color: #ffffff;
}
.line-btn.orange,
input[type="submit"].orange,
button[type="submit"].orange {
  background: #f6ba70;
  border: none;
  color: #ffffff;
}
.line-btn.dark,
input[type="submit"].dark,
button[type="submit"].dark {
  background: #333333;
  border: none;
  color: #ffffff;
}
.line-btn.light,
input[type="submit"].light,
button[type="submit"].light {
  background: #dddddd;
  color: #000000;
  border: none;
}
.line-btn.light:hover,
input[type="submit"].light:hover,
button[type="submit"].light:hover {
  color: #ffffff;
}
.line-btn:hover,
input[type="submit"]:hover,
button[type="submit"]:hover {
  background: #000000;
  color: #ffffff;
}
.line-btn.line-disabled,
input[type="submit"].line-disabled,
button[type="submit"].line-disabled,
.line-btn:disabled,
input[type="submit"]:disabled,
button[type="submit"]:disabled {
  background: #f2f2f2;
  color: #b3b3b3;
  cursor: no-drop;
  border: none;
}

.line-content-box {
  position: relative;
}
.line-content-box:hover .line-back {
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.line-content-box:hover .line-front {
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
}
.line-content-box .line-front {
  background: #ffffff;
  border: 2px solid #cccccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 30px;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.line-content-box .line-front .line-icons {
  margin-bottom: 20px;
}
.line-content-box .line-front .line-title {
  text-transform: none;
  font-size: 16px;
  margin-bottom: 10px;
}
.line-content-box .line-back {
  background: #97c13f;
  color: #ffffff;
  border: 2px solid #88ae38;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 30px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
}
.line-content-box .line-back:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.line-content-box .line-back .line-back-inner {
  display: inline-block;
  height: auto;
  vertical-align: middle;
  width: 90%;
}
.line-content-box .line-back .line-back-inner .line-btn {
  display: inline-block;
}

.line-carousel .line-carousel-item .line-carousel-item-inner,
.line-carousel-one .line-carousel-item .line-carousel-item-inner {
  padding: 0 20px;
}
.line-carousel .flex-control-nav,
.line-carousel-one .flex-control-nav {
  text-align: center;
  margin-top: 70px;
}
.line-carousel .flex-control-nav li,
.line-carousel-one .flex-control-nav li {
  display: inline-block;
}
.line-carousel .flex-control-nav li a,
.line-carousel-one .flex-control-nav li a {
  width: 7px;
  height: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.3);
  display: inline-block;
  text-indent: -9999px;
  cursor: pointer;
  margin: 0 5px;
}
.line-carousel .flex-control-nav li a.flex-active,
.line-carousel-one .flex-control-nav li a.flex-active {
  background: #97c13f;
}
.line-carousel-one .line-carousel-item .line-carousel-item-inner {
  padding: 0;
}

.one-half {
  width: 48%;
}
.one-third {
  width: 30.6666%;
}
.two-third {
  width: 65.3332%;
}
.one-fourth {
  width: 22%;
}
.three-fourth {
  width: 74%;
}
.one-half,
.one-third,
.two-third,
.three-fourth,
.one-fourth {
  float: left;
  margin-right: 4%;
  position: relative;
}
.one-half.last,
.one-third.last,
.two-third.last,
.three-fourth.last,
.one-fourth.last {
  margin-right: 0;
}
/* .one-half img,
.one-third img,
.two-third img,
.three-fourth img,
.one-fourth img {
  max-width: 100%;
} */
.one-half img, .one-third img, .two-third img, .three-fourth img, .one-fourth img {
  width: 500px;
  height: 200px;
}
.one-half.line-has-border:after,
.one-third.line-has-border:after,
.two-third.line-has-border:after,
.three-fourth.line-has-border:after,
.one-fourth.line-has-border:after {
  content: "";
  width: 1px;
  position: absolute;
  height: 100%;
  background: #e6e6e6;
  top: 0;
}
.one-half.line-has-border:after {
  right: -4.4%;
}
.one-third.line-has-border:after {
  right: -6.6%;
}
.one-fourth.line-has-border:after {
  right: -9.3%;
}
.two-third.line-has-border:after {
  right: -3%;
}
.three-fourth.line-has-border:after {
  right: -2.8%;
}

hr {
  border: none;
  background: rgba(0, 0, 0, 0.1);
  height: 2px;
  margin: 60px 0;
}

.line-dropcap {
  float: left;
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  margin: 0 10px 0 0;
  font-family: times new roman;
}
.line-dropcap.line-style1 {
  background: #cccccc;
  color: #ffffff;
}
.line-dropcap.line-style2 {
  background: #000000;
  color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1a1a1a;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  word-wrap: break-word;
}
h1 {
  font-size: 60px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}

.underline {
  text-decoration: underline;
}
.highlight {
  background: #97c13f;
  color: #ffffff;
  padding: 0 5px;
}
abbr[title],
abbr[data-original-title] {
  border-bottom: 1px dotted #737373;
  cursor: help;
}
.label {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  padding: 5px 10px;
  color: #ffffff;
  background: #cccccc;
}
.label.label-default {
  background: #cccccc;
}
.label.label-primary {
  background: #009eca;
}
.label.label-success {
  background: #97c13f;
}
.label.label-info {
  background: #58c5c0;
}
.label.label-warning {
  background: #f6ba70;
}
.label.label-danger {
  background: #e74c3c;
}

.flexslider {
  overflow: hidden;
  position: relative;
}
.flexslider a {
  font-weight: bold;
}
.flexslider:hover .flex-direction-nav a.flex-prev {
  left: 30px;
}
.flexslider:hover .flex-direction-nav a.flex-next {
  right: 30px;
}
.flexslider .flex-direction-nav a {
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}
.flexslider .flex-direction-nav a.flex-prev {
  left: -20px;
}
.flexslider .flex-direction-nav a.flex-next {
  right: -20px;
}
.flexslider .flex-direction-nav a i {
  font-size: 56px;
  color: #ffffff;
}
/* ============================================================ 
    14. Sosial
=============================================================*/
ul.social-networks {
  width: 100%;
}
ul.social-networks li {
  display: inline-block;
}
ul.social-networks li a {
  width: 32px;
  height: 32px;
  background: #000000;
  color: #ffffff;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  position: relative;
}
ul.social-networks li a:hover {
  background: #97c13f;
}
ul.social-networks li a:hover:before,
ul.social-networks li a:hover:after {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
ul.social-networks li a:before {
  content: attr(data-title);
  position: absolute;
  background: #000000;
  left: 0;
  top: -35px;
  padding: 0 10px;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
ul.social-networks li a:after {
  border-top: 7px solid #000000;
  border-right: 7px solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  left: 0;
  top: -10px;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
ul.social-networks li a i {
  line-height: 32px;
}

.line-progress-bar {
  position: relative;
  text-align: left;
}
.line-progress-bar p {
  margin-bottom: 5px;
  display: block;
}
.line-progress-bar p span {
  float: right;
}
.line-progress-bar .line-progress-bar-wrap {
  background: #f2f2f2;
  position: relative;
  height: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner {
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #97c13f;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner.blue {
  background: #009eca;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner.lightblue {
  background: #58c5c0;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner.darkblue {
  background: #42555c;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner.red {
  background: #e74c3c;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner.green {
  background: #97c13f;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner.orange {
  background: #f6ba70;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner.dark {
  background: #333333;
}
.line-progress-bar .line-progress-bar-wrap .line-progress-bar-inner.light {
  background: #dddddd;
}
.line-progress-bar .line-animation .line-progress-bar-inner:after,
.line-progress-bar .line-animation .line-progress-bar-inner span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, rgba(255, 255, 255, 0.2)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.75, rgba(255, 255, 255, 0.2)), color-stop(0.75, transparent), to(transparent));
  background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
  z-index: 1;
  -webkit-background-size: 50px 50px;
  -moz-background-size: 50px 50px;
  background-size: 50px 50px;
  -webkit-animation: move 2s linear infinite;
  -moz-animation: move 2s linear infinite;
}
.line-progress-bar .line-animation .line-progress-bar-inner:after {
  display: none;
}
@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
/* ============================================================ 
    16. Promo Box
=============================================================*/
.line-promobox {
  border: 5px solid #e6e6e6;
  padding: 50px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
  display: inline-block;
  position: relative;
}
.line-promobox .line-promobutton .line-btn {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -20px;
}
.line-promobox .line-promobutton .line-btn.small {
  margin-top: -15px;
}
.line-promobox .line-promobutton .line-btn.big {
  margin-top: -25px;
}
.line-promobox.has-button .line-promocontent {
  margin-right: 200px;
}
.line-promobox.no-button .line-promocontent {
  margin-right: 0;
}
.line-promobox.no-button .line-promobutton {
  display: none;
}

.line-tabs .line-filter-tabs {
  border-bottom: 2px solid #e6e6e6;
  display: inline-block;
  width: 100%;
}
.line-tabs .line-filter-tabs li {
  float: left;
}
.line-tabs .line-filter-tabs li a {
  padding: 15px 30px;
  color: #b3b3b3;
  background: #ffffff;
  font-weight: bold;
  line-height: 100%;
  border: 2px solid transparent;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  display: block;
  position: relative;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.line-tabs .line-filter-tabs li a:after {
  height: 4px;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
}
.line-tabs .line-filter-tabs li a.selected {
  color: #000000;
  border: 2px solid #e6e6e6;
}
.line-tabs .line-filter-tabs li a.selected:after {
  background: #fff;
}
.line-tabs .tab-container {
  padding: 20px 0;
  background: #ffffff;
  clear: both;
}
.line-tabs .tab-container aside {
  margin-bottom: 0;
}
/* ============================================================ 
    18. Table
=============================================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
table.line-table.line-style1 tr.header {
  color: #000000;
  text-align: left;
}
table.line-table.line-style1 tr td,
table.line-table.line-style1 tr th {
  border-bottom: 2px solid #e6e6e6;
  padding: 15px 10px;
}
table.line-table.line-style2 tr.header {
  color: #000000;
  text-align: left;
}
table.line-table.line-style2 tr.header th {
  background: #ffffff;
}
table.line-table.line-style2 tr:nth-child(2n-1) {
  background: #e6e6e6;
}
table.line-table.line-style2 tr td,
table.line-table.line-style2 tr th {
  padding: 15px 10px;
}
.line-pricing-table {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.line-pricing-table .pricing-table-wrap {
  display: table-cell;
  text-align: center;
}
.line-pricing-table .pricing-table-wrap ul {
  border: 2px solid #e6e6e6;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin: 0 10px;
  padding: 40px 20px;
  background: #ffffff;
}
.line-pricing-table .pricing-table-wrap ul li.line-head-row {
  font-size: 24px;
  line-height: 100%;
  font-weight: bold;
  padding-bottom: 5px;
}
.line-pricing-table .pricing-table-wrap ul li.line-price-row {
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 30px;
  margin-bottom: 35px;
}
.line-pricing-table .pricing-table-wrap ul li.line-price-row p {
  margin: 0;
}
.line-pricing-table .pricing-table-wrap ul li.line-price-row small {
  font-size: 18px;
  color: #b3b3b3;
}
.line-pricing-table .pricing-table-wrap ul li.line-price-row span {
  font-size: 72px;
  color: #97c13f;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  line-height: 100%;
}
.line-pricing-table .pricing-table-wrap ul li.line-price-row span.symbol {
  position: relative;
  font-size: 24px;
  color: #000000;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  top: -15px;
}
.line-pricing-table .pricing-table-wrap ul li.line-btn-row {
  margin-top: 30px;
}
.line-pricing-table .pricing-table-wrap ul.line-highlight {
  border-color: #97c13f;
  padding: 0;
}
.line-pricing-table .pricing-table-wrap ul.line-highlight li.line-price-row {
  background: #97c13f;
  border-color: #97c13f;
}
.line-pricing-table .pricing-table-wrap ul.line-highlight li.line-price-row small {
  color: #ffffff;
}
.line-pricing-table .pricing-table-wrap ul.line-highlight li.line-price-row span {
  color: #ffffff;
}
.line-pricing-table .pricing-table-wrap ul.line-highlight li.line-price-row span.symbol {
  color: #ffffff;
}
.line-pricing-table .pricing-table-wrap ul.line-highlight li.line-head-row {
  color: #ffffff;
  background: #97c13f;
  padding-top: 40px;
}
.line-pricing-table .pricing-table-wrap ul.line-highlight li.line-btn-row {
  padding-bottom: 40px;
}

.line-testimonials .line-testimonials-content {
  font-style: italic;
  margin-bottom: 30px;
  font-size: 16px;
}
.line-testimonials .line-testimonials-meta {
  font-weight: bold;
  font-size: 18px;
}
.line-testimonials .line-testimonials-meta span {
  color: #97c13f;
}
