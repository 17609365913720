/* Disle | Design Agency HTML Template

Template Name: Disle
Version: 1.0.0
License: copyright commercial
/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global Settings
4. Main Header / TwO / Three / Four
5. Sticky Header 
6. Mobile Menu
7. Hidden Sidebar style
8. Section Title
9. Main Slider
10. Banner Section / TwO / Three
11. Project Section / TwO / Three
12. Features Section
13. About Section / TwO / Three / Four / Five
14. Services Section / TwO / Three / Four
15. Call To Action / Two
16. FAQ's Sectiom / Two
17. Fun Fact Section / Two
18. Testimonial Section / Two / Three
19. team Section
20. Work Section
21, Contact Section / Two
22. Why Choose Us / Two / Three
23. News Section / Two
24. Map Section
25. Clients Section
26. Main Footer
**********************************************/
/* @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("animate.css");
@import url("owl.css");
@import url("swiper.min.css");
@import url("jquery.fancybox.min.css");
@import url("jquery-ui.css");
@import url("linear.css");
@import url("select2.min.css");
@import url("fontawesome.css");
@import url("flaticon.css");
@import url("tm-bs-mp.css");
@import url("tm-utility-classes.css"); */
:root {
  --theme-color-light: #ffffff;
  --theme-color-dark: #171717;
  --theme-color1: #ee763c;
  --bg-theme-color1: var(--theme-color1);
  --theme-color2: #171717;
  --bg-theme-color2: var(--theme-color2);
  --text-color-bg-theme-color1: #fff;
  --text-color-bg-theme-color2: #fff;
  --text-color: black;
  --headings-color: var(--theme-color2);
  --link-color: var(--theme-color2);
  --link-hover-color: var(--theme-color2);
  --text-font: "Manrope", sans-serif;
  --title-font: "Manrope", sans-serif;
  --body-font-size: 16px;
  --body-line-height: 30px;
  --body-font-weight: 500;
  --line-height-heading-h1: 1em;
  --line-height-heading: 1.2em;
  --line-height-heading-small: 1.4em;
  --h1-font-size: 90px;
  --h2-font-size: 50px;
  --h3-font-size: 28px;
  --h4-font-size: 24px;
  --h5-font-size: 20px;
  --h6-font-size: 18px;
  --h1-font-weight: 800;
  --h2-font-weight: 800;
  --h3-font-weight: 800;
  --h4-font-weight: 800;
  --h5-font-weight: 800;
  --h6-font-weight: 800;
  --sec-title-subtitle-color: var(--text-color);
  --sec-title-subtitle-font-size: 14px;
  --sec-title-subtitle-font-family: var(--text-font);
  --sec-title-subtitle-font-weight: 700;
  --sec-title-subtitle-line-height: 20px;
  --sec-title-color: var(--theme-color2);
  --sec-title-font-size: var(--h2-font-size);
  --sec-title-font-family: var(--title-font);
  --sec-title-font-weight: 800;
  --theme-light-background: #f8f6f1;
  --theme-light-background-text-color: var(--headings-color);
  --theme-black: #131313;
  --container-width: 1200px;
  --small-container-width: 1000px;
  --large-container-width: 1310px;
  --container-pt: 120px;
  --container-pb: 120px;
}

/*
 * typography.scss
 * -----------------------------------------------
*/
::-moz-selection {
  background: var(--theme-color2);
  color: #fff;
  text-shadow: none;
}
::selection {
  background: var(--theme-color2);
  color: #fff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}

::-webkit-input-placeholder {
  color: #7c858c;
}

::-moz-input-placeholder {
  color: #7c858c;
}

::-ms-input-placeholder {
  color: #7c858c;
}

body {
  background-color: #fff;
  background-attachment: fixed;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  counter-reset: my-sec-counter;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

p, .text {
  color: var(--text-color) !important;
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

/* -------- Headings ---------- */
h1, h2, h3, h4, h5, h6 {
  color: var(--headings-color);
  font-family: var(--title-font);
  position: relative;
  line-height: var(--line-height-heading-);
}
h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small {
  font-weight: normal;
  line-height: 1;
  color: var(--headings-color);
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--h1-font-weight);
  line-height: var(--line-height-heading-h1);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--h2-font-weight);
  line-height: var(--line-height-heading);
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--h3-font-weight);
  line-height: var(--line-height-heading);
}

h4 {
  font-size: var(--h4-font-size);
  font-weight: var(--h4-font-weight);
  line-height: var(--line-height-heading);
}

h5 {
  font-size: var(--h5-font-size);
  font-weight: var(--h5-font-weight);
  line-height: var(--line-height-heading);
}

h6 {
  font-size: var(--h6-font-size);
  font-weight: var(--h6-font-weight);
  line-height: var(--line-height-heading-small);
}

/* -------- Body Text ---------- */
table p {
  margin-bottom: 0;
}

p {
  margin-bottom: 20px;
}
p a:not(.button):not(.btn):hover, p a:not(.button):not(.btn):focus {
  text-decoration: underline;
}

/* -------- other ---------- */
a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: var(--body-font-weight);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}
a b, a strong {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a img {
  border: none;
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset {
  margin-bottom: 10px;
}

ol, ul {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

b, strong {
  color: #333;
  font-weight: var(--body-font-weight-bold);
}

iframe {
  border: none !important;
}

/*
 * container.scss
 * -----------------------------------------------
*/
.container .container {
  width: 100%;
}

.container .container,
.container .container-fluid,
.container-fluid .container,
.container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

section > .container,
section > .container-fluid {
  /* padding-top: var(--container-pt); */
  padding-bottom: var(--container-pt);
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: var(--container-width);
  }
}
/*=== Default Form ===*/
.form-control, .input-text {
  height: calc(2.25rem + 27px);
  padding: 14px 30px;
  outline: 0;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  color: #686a6f;
  font-size: 0.9rem;
  width: 100%;
}
.form-control::-webkit-input-placeholder, .input-text::-webkit-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::-moz-placeholder, .input-text::-moz-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder, .input-text:-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::-ms-input-placeholder, .input-text::-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::placeholder, .input-text::placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder, .input-text:-ms-input-placeholder {
  color: #686a6f;
}
.form-control::-ms-input-placeholder, .input-text::-ms-input-placeholder {
  color: #686a6f;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* -------- Dark Theme Styling ---------- */
.dark-layout {
  background-color: var(--theme-color2) !important;
  --sec-title-color: var(--theme-color-light);
}
.dark-layout .sticky-header .main-menu .navigation > li > a,
.dark-layout h1, .dark-layout h2, .dark-layout h3, .dark-layout h4, .dark-layout h5, .dark-layout h6 {
  color: var(--theme-color-light);
}
.dark-layout .preloader {
  background-color: var(--theme-color2);
}
.dark-layout .preloader:after {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/logo.png");
  
}
.dark-layout .sticky-header {
  background-color: var(--theme-color2);
}
.dark-layout .hidden-bar .upper-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dark-layout .hidden-bar .social-links {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.dark-layout .hidden-bar .social-links li {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/*
 *	boxed-layout.scss
 * -----------------------------------------------
*/
.tm-boxed-layout {
  background-color: #444;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}
.tm-boxed-layout.layer-overlay:before {
  z-index: -1;
}
.tm-boxed-layout .page-wrapper {
  margin: 0 auto;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .tm-boxed-layout .page-wrapper {
    width: 1170px;
  }
}
.tm-boxed-layout .container .container {
  width: 100%;
}
@media (min-width: 1200px) {
  .tm-boxed-layout .container {
    width: 1140px;
  }
}
@media (min-width: 1000px) {
  .tm-boxed-layout.tm-container-970px .page-wrapper {
    width: 970px;
  }
}
.tm-boxed-layout.tm-container-970px .container,
.tm-boxed-layout.tm-container-970px .container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}
.tm-boxed-layout.tm-container-970px .container .container,
.tm-boxed-layout.tm-container-970px .container .container-fluid,
.tm-boxed-layout.tm-container-970px .container-fluid .container,
.tm-boxed-layout.tm-container-970px .container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1300px) {
  .tm-boxed-layout.tm-container-1230px .page-wrapper {
    width: 1230px;
  }
}
.tm-boxed-layout.tm-container-1230px .container,
.tm-boxed-layout.tm-container-1230px .container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.tm-boxed-layout.tm-container-1230px .container .container,
.tm-boxed-layout.tm-container-1230px .container .container-fluid,
.tm-boxed-layout.tm-container-1230px .container-fluid .container,
.tm-boxed-layout.tm-container-1230px .container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1400px) {
  .tm-boxed-layout.tm-container-1300px .page-wrapper {
    width: 1300px;
  }
}
.tm-boxed-layout.tm-container-1300px .container,
.tm-boxed-layout.tm-container-1300px .container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.tm-boxed-layout.tm-container-1300px .container .container,
.tm-boxed-layout.tm-container-1300px .container .container-fluid,
.tm-boxed-layout.tm-container-1300px .container-fluid .container,
.tm-boxed-layout.tm-container-1300px .container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1400px) {
  .tm-boxed-layout.tm-container-1340px .page-wrapper {
    width: 1340px;
  }
}
.tm-boxed-layout.tm-container-1340px .container,
.tm-boxed-layout.tm-container-1340px .container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.tm-boxed-layout.tm-container-1340px .container .container,
.tm-boxed-layout.tm-container-1340px .container .container-fluid,
.tm-boxed-layout.tm-container-1340px .container-fluid .container,
.tm-boxed-layout.tm-container-1340px .container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1400px) {
  .tm-boxed-layout.tm-container-1440px .page-wrapper {
    width: 1440px;
  }
}
.tm-boxed-layout.tm-container-1440px .container,
.tm-boxed-layout.tm-container-1440px .container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.tm-boxed-layout.tm-container-1440px .container .container,
.tm-boxed-layout.tm-container-1440px .container .container-fluid,
.tm-boxed-layout.tm-container-1440px .container-fluid .container,
.tm-boxed-layout.tm-container-1440px .container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1560px) {
  .tm-boxed-layout.tm-container-1500px .page-wrapper {
    width: 1500px;
  }
}
.tm-boxed-layout.tm-container-1500px .container,
.tm-boxed-layout.tm-container-1500px .container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.tm-boxed-layout.tm-container-1500px .container .container,
.tm-boxed-layout.tm-container-1500px .container .container-fluid,
.tm-boxed-layout.tm-container-1500px .container-fluid .container,
.tm-boxed-layout.tm-container-1500px .container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1660px) {
  .tm-boxed-layout.tm-container-1600px .page-wrapper {
    width: 1600px;
  }
}
.tm-boxed-layout.tm-container-1600px .container,
.tm-boxed-layout.tm-container-1600px .container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.tm-boxed-layout.tm-container-1600px .container .container,
.tm-boxed-layout.tm-container-1600px .container .container-fluid,
.tm-boxed-layout.tm-container-1600px .container-fluid .container,
.tm-boxed-layout.tm-container-1600px .container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.tm-boxed-layout.tm-container-100pr .page-wrapper {
  width: 95%;
}
.tm-boxed-layout.tm-container-100pr .page-wrapper .container,
.tm-boxed-layout.tm-container-100pr .page-wrapper .container-fluid {
  width: 100%;
}
.tm-boxed-layout.container-shadow .page-wrapper {
  -webkit-box-shadow: 0 0 25px 0 #777;
          box-shadow: 0 0 25px 0 #777;
}
.tm-boxed-layout .header,
.tm-boxed-layout section,
.tm-boxed-layout .footer {
  background-color: #fff;
}
.tm-boxed-layout .header .navbar-default {
  background-color: #fff;
  border-color: #fff;
  padding: 0;
}
.tm-boxed-layout .header .navbar-default .navbar-nav > li > a {
  padding: 30px 5px;
}
.tm-boxed-layout .header .navbar-default .navbar-collapse {
  background-color: #fff;
  border-color: #fff;
  margin-right: 30px;
}
.tm-boxed-layout .header #header-logo {
  margin: 0;
  padding-top: 27px;
}
.tm-boxed-layout .banner-section .slide-item {
  min-height: 540px;
}
.tm-boxed-layout .banner-section .slide-item .content-box {
  padding: 150px 0 220px;
}
.tm-boxed-layout .banner-section-two .content-box {
  padding: 250px 0 150px;
}
.tm-boxed-layout .banner-section-three .content-box {
  padding: 250px 0 150px;
}
.tm-boxed-layout .banner-section-four .content-box {
  padding: 130px 0;
}
.tm-boxed-layout .feature-block-four .inner-box .content-box {
  min-height: initial;
  padding-left: 90px;
}
.tm-boxed-layout .feature-block-four .inner-box .icon-box {
  height: 64px;
  width: 64px;
}
.tm-boxed-layout .feature-block-four .inner-box .icon-box .icon {
  font-size: 32px;
}

@media (max-width: 1200px) {
  .tm-boxed-layout {
    padding-top: 15px;
  }
  .tm-boxed-layout .header .navbar-default .navbar-nav > li > a {
    padding: 10px;
  }
  .tm-boxed-layout .header .navbar-default .navbar-collapse {
    margin-right: 15px;
  }
  .tm-boxed-layout .navbar-header {
    padding: 15px 0;
  }
  .tm-boxed-layout .navbar-collapse .navbar-nav li a .caret {
    margin-right: 0;
  }
}
/*** 

====================================================================
Reset
====================================================================

***/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
}

/*** 

====================================================================
Global Settings
====================================================================

***/
textarea {
  overflow: hidden;
  resize: none;
}

button {
  outline: none !important;
  cursor: pointer;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.title a {
  color: inherit;
}

.color1 {
  color: var(--theme-color1);
}

.color2 {
  color: var(--theme-color2);
}

.color3 {
  color: var(--theme-color3);
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  z-index: 99;
  background-color: #ffffff;
}

.large-container {
  position: static;
  max-width: var(--large-container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.auto-container {
  position: static;
  max-width: var(--container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}
.row5 {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.small-container {
  position: static;
  max-width: var(--small-container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.dropdown-toggle::after {
  display: none;
}

/*=======================
    Preloader
=======================*/
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
}

.preloader:after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  margin-left: -75px;
  margin-top: -30px;
  height: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/favicon.png");
  content: "";
}

.preloader:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  color: var(--theme-color2);
  font-weight: 600;
  font-size: 14px;
  font-family: var(--title-font);
  letter-spacing: 2px;
  text-transform: uppercase;
  content: "Loading";
  -webkit-transition: none;
  transition: none;
}

/*=======================
Scroll To Top style
=======================*/
.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: var(--theme-color1);
  z-index: 100;
  display: none;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  background: var(--theme-color3);
  color: #ffffff;
}

.link-style-one {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-family: var(--title-font);
  color: var(--theme-color2);
}
.link-style-one:before {
  position: absolute;
  left: 0;
  right: 18px;
  bottom: 2px;
  height: 1px;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.link-style-one i {
  position: relative;
  top: 1px;
  display: block;
  font-size: 14px;
  margin-left: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.link-style-one:hover {
  color: var(--theme-color1);
}
.link-style-one:hover:before {
  right: 100%;
}

/*=== List Style One ===*/
.list-style-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 0;
}
.list-style-one li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  color: #848484;
  margin-right: 30px;
}
.list-style-one li i {
  position: relative;
  top: 2px;
  color: var(--theme-color1);
  font-size: 13px;
  line-height: 20px;
  margin-right: 10px;
}
.list-style-one li a {
  display: block;
  color: #848484;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.list-style-one li a:hover {
  color: var(--theme-color-light);
}

/*=== List Style Two ===*/
.list-style-two {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 30px;
}
.list-style-two li {
  position: relative;
  font-size: 18px;
  color: var(--theme-color2);
  line-height: 26px;
  font-weight: 700;
  letter-spacing: -0.02em;
  padding-left: 28px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-bottom: 10px;
}
.list-style-two li i {
  position: absolute;
  left: 0px;
  top: 0px;
  color: var(--theme-color1);
  font-size: 18px;
  line-height: 24px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.list-style-two li a {
  display: inline-block;
  font-weight: inherit;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.list-style-two li a:hover {
  color: var(--theme-color1);
}
.list-style-two.two-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.list-style-two.two-col li {
  max-width: 50%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  padding-right: 20px;
}
@media (max-width: 767.98px) {
  .list-style-two.two-col li {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
}

/*=== List Style Three ===*/
.list-style-three {
  position: relative;
}
.list-style-three li {
  position: relative;
  padding: 15px 30px;
  padding-left: 55px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color: var(--theme-color2);
  letter-spacing: -0.04em;
  background-color: #ebf1f5;
  margin-bottom: 8px;
}
.list-style-three li i {
  position: absolute;
  left: 30px;
  top: 15px;
  font-size: 15px;
  line-height: 30px;
  color: var(--theme-color1);
}
.list-style-three.dark li {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  font-weight: 400;
}
.list-style-three.dark li i {
  font-size: 12px;
}
.list-style-three.two-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.list-style-three.two-column li {
  max-width: 50%;
  margin-right: 30px;
}
@media (max-width: 575.98px) {
  .list-style-three.two-column li {
    max-width: 100%;
    margin-right: 0;
    width: 100%;
  }
}

/*=== List Style Four ===*/
.list-style-four {
  position: relative;
}
.list-style-four li {
  position: relative;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #2e2d2d;
  padding-left: 45px;
  margin-bottom: 18px;
}
.list-style-four li:before {
  position: relative;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 0;
  top: 0;
  content: "\f00c";
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  font-weight: 700;
  font-family: "Font Awesome 6 Pro";
  background: var(--gradient-1);
  border-radius: 50%;
}
.list-style-four li a {
  display: inline-block;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.list-style-four li a:hover {
  color: #ffffff;
}

/*Social Icon One*/
.social-icon-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.social-icon-one li {
  position: relative;
  margin-left: 27px;
}
.social-icon-one li:first-child {
  margin-left: 0;
}
.social-icon-one li a {
  position: relative;
  display: block;
  line-height: 40px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-one li a:hover {
  color: var(--theme-color1);
}

/*Social Icon Two*/
.social-icon-two {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.social-icon-two li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 7px;
}
.social-icon-two li:last-child {
  margin-right: 0;
}
.social-icon-two li a {
  position: relative;
  display: block;
  line-height: 42px;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  background-color: #0f0f0f;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-two li a i {
  position: relative;
}
.social-icon-two li a::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: scale(0);
          transform: scale(0);
  background-color: var(--theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 50px;
}
.social-icon-two li a:hover {
  color: #fff;
}
.social-icon-two li a:hover:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}

/*Social Icon Three*/
.social-icon-three {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.social-icon-three li {
  position: relative;
  margin-right: 30px;
}
.social-icon-three li a {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  letter-spacing: 0.05em;
  background: transparent;
  font-weight: 400;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-family: var(--title-font);
}
.social-icon-three li a:before {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  width: 0;
  background-color: #ffffff;
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-three li a:hover:before {
  left: 0;
  width: 100%;
}

/*Social Icon Four*/
.social-icon-four {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.social-icon-four li {
  position: relative;
  margin: 0 12px;
}
.social-icon-four li a {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: var(--theme-color2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-four li a:hover {
  color: var(--theme-color3);
}

/*Social Icon Five*/
.social-icon-five {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.social-icon-five li {
  position: relative;
  margin-right: 7px;
}
.social-icon-five li a {
  position: relative;
  display: block;
  height: 34px;
  width: 34px;
  border: 1px solid rgba(135, 140, 143, 0.32);
  font-size: 12px;
  text-align: center;
  line-height: 32px;
  color: #bcb5b5;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-five li a:hover {
  color: var(--theme-color3);
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-pattern-1 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-1.jpg");
}

.bg-pattern-2 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-2.jpg");
}

.bg-pattern-3 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-3.jpg");
}

.bg-pattern-4 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-4.png");
}

.bg-pattern-5 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-5.jpg");
}

.bg-pattern-6 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-6.jpg");
}

.bg-pattern-7 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-7.jpg");
}

.bg-pattern-8 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-8.jpg");
}

.bg-pattern-9 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-9.png");
}

.bg-pattern-10 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-10.jpg");
}

.bg-pattern-11 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-11.jpg");
}

.bg-pattern-12 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-12.png");
}

.bg-pattern-13 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-13.jpg");
}

.bg-pattern-14 {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-14.png");
}

.bg-pattern-12 {
  background-repeat: no-repeat;
  background-position: right top;
  background-size: auto;
}

.devider {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 80%;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 1199.98px) {
  .devider {
    width: 100%;
  }
}

/*======================
    Tabs Box
======================*/
.tabs-box {
  position: relative;
}

.tabs-box .tab {
  display: none;
}

.tabs-box .active-tab {
  display: block;
}

.play-btn {
  position: relative;
}
.play-btn:hover .icon {
  background-color: var(--theme-color1);
  color: var(--theme-color2);
}
.play-btn .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  font-size: 24px;
  color: var(--theme-color1);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.play-btn .circle-text img {
  position: relative;
  display: inline-block;
  -webkit-animation: fa-spin 60s infinite linear;
          animation: fa-spin 60s infinite linear;
}

/*======================
    Media Play Button 
======================*/
.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.play-now .icon {
  position: relative;
  display: inline-block;
  height: 85px;
  width: 85px;
  text-align: center;
  line-height: 85px;
  background-color: #ffffff;
  color: var(--bg-theme-color2);
  z-index: 1;
  font-size: 18px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
          box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
          transform-origin: center;
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0.6);
          box-shadow: 0 0 0 0 rgba(233, 35, 47, 0.6);
  -webkit-animation: ripple 3s infinite;
          animation: ripple 3s infinite;
}
.play-now .ripple.light,
.play-now .ripple:before.light,
.play-now .ripple:after.light {
  -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
          box-shadow: 0 0 0 0 rgb(255, 255, 255);
}

.play-now .ripple.light,
.play-now .ripple.light:before,
.play-now .ripple.light:after {
  -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
          box-shadow: 0 0 0 0 rgb(255, 255, 255);
}

.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.play-now .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
  }
}
.play-now-two {
  height: 150px;
  width: 150px;
  background-color: rgba(21, 21, 21, 0.5);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 34px;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-animation: zoom-one 3s infinite linear;
          animation: zoom-one 3s infinite linear;
}
.play-now-two:hover {
  color: #ff9205;
  background-color: #ffffff;
}

/*========================
  Select2 Dropdown Plugin
========================*/
.select2-dropdown {
  border: 1px solid #eee;
}

.select2-results__option {
  padding: 0 10px;
  color: #7c858c;
  border: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #7c858c;
  padding-left: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--bg-theme-color1);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  bottom: 1px;
  height: auto;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  color: #7c858c;
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before {
  position: relative;
  content: "\f107";
  font-family: "Font Awesome 6 Pro";
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--default .select2-selection--single {
  border-radius: 0;
}

.default-navs .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.default-navs .owl-next,
.default-navs .owl-prev {
  display: block;
  width: 76px;
  height: 60px;
  font-size: 16px;
  font-weight: 700;
  line-height: 60px;
  color: var(--theme-color2);
  background: #fff;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.default-navs .owl-next:hover,
.default-navs .owl-prev:hover {
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
}

.default-dots .owl-dots {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.default-dots .owl-dots .owl-dot {
  height: 5px;
  width: 40px;
  margin: 0 5px;
  border: 1px solid #d7d7d7;
}
@media (max-width: 575.98px) {
  .default-dots .owl-dots .owl-dot {
    width: 20px;
  }
}
.default-dots .owl-dots .owl-dot.active {
  background-color: var(--theme-color1);
  border: 1px solid var(--theme-color1);
}

/*==========================
	Nav Style One
===========================*/
.nav-style-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9;
}
.nav-style-one .next,
.nav-style-one .prev {
  margin-right: 38px;
  padding: 18px 25px;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.nav-style-one .next .icon,
.nav-style-one .prev .icon {
  position: relative;
  display: inline-block;
}
.nav-style-one .next .icon:before,
.nav-style-one .prev .icon:before {
  position: absolute;
  top: 0;
  margin-top: -20px;
  right: -25px;
  height: 52px;
  width: 52px;
  border: 1px dotted #fff;
  border-radius: 100px;
  content: "";
  z-index: -1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.nav-style-one .next:hover .icon:before,
.nav-style-one .prev:hover .icon:before {
  background-color: rgba(255, 255, 255, 0.15);
  width: 125px;
}
.nav-style-one .prev {
  margin-right: 0;
  padding-left: 25px;
  padding-right: 0;
}
.nav-style-one .prev .icon:before {
  left: -25px;
  right: auto;
}
.nav-style-one .swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.nav-style-one.dark .next .icon:before,
.nav-style-one.dark .prev .icon:before {
  border: 1px solid #797979;
}

/*==========================
	Default Tabs
===========================*/
.default-tabs {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.default-tabs .tab-buttons {
  position: relative;
  margin-bottom: 30px;
}

.default-tabs .tab-buttons li {
  position: relative;
  float: left;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 35px;
  color: var(--theme-color1);
  line-height: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.default-tabs .tab-buttons li:last-child {
  margin-right: 0;
}

.default-tabs .tab-buttons li.active-btn {
  background: var(--gradient-1);
  color: #ffffff;
}

.default-tabs .tabs-content {
  position: relative;
  width: 100%;
}

.blockquote-style-one {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-color2);
  padding: 13px 25px;
  background-color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-family: var(--title-font);
  font-weight: 800;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.blockquote-style-one:before {
  position: absolute;
  left: 0;
  top: 10px;
  bottom: 10px;
  width: 4px;
  z-index: 2;
  background-color: var(--theme-color1);
  content: "";
}

/*================================
    Progress Bar
=================================*/
.skills {
  position: relative;
  margin-bottom: 20px;
}
.skills .skill-item {
  position: relative;
  margin-bottom: 30px;
}
.skills .skill-item .skill-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 5px;
}
.skills .skill-item .skill-header .skill-title {
  font-size: 18px;
  color: var(--theme-color2);
  font-weight: 800;
}
.skills .skill-item .skill-header .skill-percentage {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: black;
}
.skills .skill-item .skill-bar {
  position: relative;
  width: 100%;
  height: 7px;
  border-radius: 7px;
}
.skills .skill-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 7px;
  background-color: #f4f4f4;
}
.skills .skill-item .skill-bar .bar-inner .bar {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 7px;
  width: 0px;
  -webkit-transition: all 3000ms ease;
  transition: all 3000ms ease;
  background-color: var(--bg-theme-color1);
}

.theme-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: var(--title-font);
}
.theme-btn .btn-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*Btn Style One*/
.btn-style-one {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding: 20px 50px;
  letter-spacing: 0.1em;
  font-weight: 700;
  overflow: hidden;
  z-index: 0;
  text-transform: uppercase;
  color: var(--theme-color-light);
  background: var(--theme-color1);
}
.btn-style-one:before {
  position: absolute;
  top: 0;
  left: -5%;
  right: auto;
  bottom: auto;
  width: 110%;
  height: 200%;
  background: var(--theme-color2);
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: skewY(-10deg) scale(1, 0);
          transform: skewY(-10deg) scale(1, 0);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  content: "";
  z-index: -1;
}
.btn-style-one i {
  position: relative;
  top: 1px;
  display: block;
  margin-left: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.btn-style-one:hover:before {
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transform: skewY(-10deg) scale(1, 1);
          transform: skewY(-10deg) scale(1, 1);
}
.btn-style-one:hover {
  color: #ffffff;
}
.btn-style-one.dark-bg {
  background-color: var(--theme-color2);
}
.btn-style-one.dark-bg::before {
  background-color: var(--bg-theme-color1);
}
.btn-style-one.light-bg {
  background-color: var(--theme-color-light);
  color: var(--theme-color2);
}
.btn-style-one.light-bg:hover {
  color: var(--theme-color-light);
}
.btn-style-one.light-bg::before {
  background-color: var(--theme-color2);
}
.btn-style-one.hover-light:hover {
  color: var(--theme-color2);
}
.btn-style-one.hover-light:before {
  background-color: var(--theme-color-light);
}

.theme-btn.small {
  padding: 10px 30px;
  line-height: 20px;
  font-size: 10px;
}

/*** 

====================================================================
  Anim Icons
====================================================================

***/
.anim-icons {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

[text-split] {
  opacity: 0;
}

.word {
  overflow: hidden;
  padding-bottom: 0.1em;
  margin-bottom: -0.1em;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.anim-icons.full-width {
  max-width: 100%;
}

.anim-icons .icon {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.icon-plane {
  height: 119px;
  width: 219px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/icon-plane.png");
}

.icon-plane-2 {
  height: 640px;
  width: 106px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/icon-plane-2.png");
}

.icon-plane-3 {
  width: 301px;
  height: 336px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/icon-plane-3.png");
}

.icon-plane-4 {
  width: 1627px;
  height: 272px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/icon-plane-4.png");
}

.icon-plane-5 {
  width: 242px;
  height: 270px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/icon-plane-5.png");
}

.icon-plane-6 {
  width: 162px;
  height: 976px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/icon-plane-6.png");
}

.icon-plane-7 {
  width: 373px;
  height: 194px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/icon-plane-7.png");
}

.icon-lines {
  width: 653px;
  height: 471px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/icon-lines.png");
}

.bounce-y {
  -webkit-animation: bounce-y 10s infinite linear;
          animation: bounce-y 10s infinite linear;
}

.bounce-x {
  -webkit-animation: bounce-x 10s infinite linear;
          animation: bounce-x 10s infinite linear;
}

.zoom-one {
  -webkit-animation: zoom-one 10s infinite linear;
          animation: zoom-one 10s infinite linear;
}

.zoom-two {
  -webkit-animation: zoom-two 5s infinite linear;
          animation: zoom-two 5s infinite linear;
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes zoom-one {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}
@keyframes zoom-one {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}
@-webkit-keyframes zoom-two {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes zoom-two {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.overlay-anim {
  position: relative;
}
.overlay-anim:after {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}
.overlay-anim:hover:after {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.circle {
  position: fixed;
  width: 10px;
  height: 10px;
  left: -10px;
  top: -10px;
  border-radius: 100%;
  z-index: 1;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(1);
          transform: scale(1);
}
@supports (mix-blend-mode: difference) {
  .circle {
    background-color: white;
    mix-blend-mode: difference;
  }
}
@media only screen and (max-width: 1023px) {
  .circle {
    display: none !important;
  }
}

.circle-follow {
  position: fixed;
  mix-blend-mode: difference;
  width: 30px;
  height: 30px;
  left: -21px;
  top: -21px;
  border-radius: 100%;
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(1);
          transform: scale(1);
}
@supports (mix-blend-mode: difference) {
  .circle-follow {
    border: 1px solid #fff;
    mix-blend-mode: difference;
  }
}
@media only screen and (max-width: 1023px) {
  .circle-follow {
    display: none !important;
  }
}

/*** 

====================================================================
  Search Popup
====================================================================

***/
.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  overflow: hidden;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.search-popup .search-back-drop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color2);
  opacity: 0.95;
}
.search-popup .close-search {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 26px;
  color: var(--theme-color-light);
  z-index: 3;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .search-inner {
  position: relative;
  display: block;
  top: 40%;
  height: auto;
  z-index: 1;
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto;
  opacity: 0;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.search-popup .form-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.search-popup .form-group input[type=search],
.search-popup .form-group input[type=text] {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 16px;
  width: 100%;
  height: 50px;
  border: 1px solid #e1e6dc;
  padding: 15px 20px;
  color: #707070;
  background: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .form-group input[type=search]:focus,
.search-popup .form-group input[type=text]:focus {
  border-color: var(--border-theme-color2);
}
.search-popup .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  display: block;
  font-size: 18px;
  color: var(--theme-color2);
  line-height: 40px;
  border-radius: 5px;
  font-weight: normal;
  background: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.search-popup .form-group button:hover {
  color: var(--theme-color2);
}
.search-popup textarea::-webkit-input-placeholder, .search-popup input::-webkit-input-placeholder {
  color: inherit;
}
.search-popup textarea::-moz-placeholder, .search-popup input::-moz-placeholder {
  color: inherit;
}
.search-popup textarea:-ms-input-placeholder, .search-popup input:-ms-input-placeholder {
  color: inherit;
}
.search-popup textarea::-ms-input-placeholder, .search-popup input::-ms-input-placeholder {
  color: inherit;
}
.search-popup textarea::placeholder,
.search-popup input::placeholder {
  color: inherit;
}

.moblie-search-active .search-popup {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 0%;
}
.moblie-search-active .search-popup .search-inner {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 500ms;
          transition-delay: 500ms;
}

/*** 

====================================================================
Main Header
====================================================================

***/
.header-span {
  position: relative;
  height: 110px;
  display: block;
  width: 100%;
}

.main-header {
  position: relative;
  width: 100%;
  z-index: 999;
}

.header-top {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--theme-color2);
}
.header-top .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding: 0 80px;
}
@media (max-width: 1399.98px) {
  .header-top .inner-container {
    padding: 0 20px;
  }
}
.header-top .top-left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-top .top-right {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-top .top-right .social-icon-one {
  margin-left: 41px;
}
.header-top .useful-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 0;
}
.header-top .useful-links li {
  position: relative;
  padding-left: 12px;
  margin-left: 12px;
  font-size: 14px;
  color: #8f8f8f;
  line-height: 20px;
}
.header-top .useful-links li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.header-top .useful-links li:first-child:before {
  display: none;
}
.header-top .useful-links li:before {
  position: absolute;
  left: -3px;
  top: 0;
  content: "/";
}
.header-top .useful-links li a {
  color: #808287;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.header-top .useful-links li a:hover {
  color: #ffffff;
}

.main-header .header-lower {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .header-lower .logo-box {
  position: relative;
  padding: 20px 0;
}

.main-header {
  width: 100%;
  z-index: 999;
}
.main-header .logo {
  position: relative;
  display: block;
}
.main-header .logo img {
  max-width: 100%;
  height: auto;
}
.main-header .main-box {
  position: relative;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .main-box .nav-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.main-menu {
  position: relative;
}
@media (max-width: 991.98px) {
  .main-menu {
    display: none;
  }
}

.main-menu .navbar-header {
  display: none;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  margin: 0px;
}

.main-menu .navigation > li {
  position: relative;
  float: left;
  padding: 25px 0px;
  margin-right: 72px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li:before {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 10px;
  height: 1px;
  width: 0%;
  background-color: var(--bg-theme-color1);
  z-index: 3;
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li:last-child {
  margin-right: 0;
}
.main-menu .navigation > li:hover:before, .main-menu .navigation > li.current:before {
  left: 0;
  width: 100%;
}
.main-menu .navigation > li > a {
  position: relative;
  display: block;
  text-align: center;
  opacity: 1;
  color: #ffffff;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: 0.1em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-transform: uppercase;
}
.main-menu .navigation > li > a .icon {
  position: relative;
  font-size: 22px;
  line-height: 24px;
  margin-left: 10px;
}
.main-menu .navigation > li > a:hover {
  color: #ffffff;
}
.main-menu .navigation > li.current > a {
  color: var(--theme-color-light);
  font-weight: 700;
}
.main-menu .navigation > li.dropdown > a {
  padding-right: 14px;
  margin-right: -14px;
}
.main-menu .navigation > li.dropdown > a:after {
  content: "\f107";
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
  height: 20px;
  display: block;
  line-height: 24px;
  font-size: 12px;
  z-index: 5;
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  margin-top: -2px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.main-menu .navigation > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
  margin-top: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li > ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 220px;
  z-index: 100;
  padding: 10px 0 0;
  background-color: #ffffff;
  margin-top: 30px;
  opacity: 0;
  display: none;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navigation > li > ul.from-right {
  left: auto;
  right: 0px;
}
.main-menu .navigation > li > ul > li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ebf1f5;
}
.main-menu .navigation > li > ul > li:last-child {
  border-bottom: none;
}
.main-menu .navigation > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 0px;
  line-height: 29px;
  font-weight: 400;
  font-size: 16px;
  color: var(--theme-color2);
  text-align: left;
  margin: 0 30px;
  text-transform: capitalize;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.main-menu .navigation > li > ul > li:hover > a {
  color: var(--theme-color1);
}
.main-menu .navigation > li > ul > li.dropdown > a:after {
  font-family: "Font Awesome 6 Pro";
  content: "\f105";
  position: absolute;
  right: 0;
  top: 11px;
  display: block;
  line-height: 24px;
  font-size: 14px;
  font-weight: 900;
  z-index: 5;
}
.main-menu .navigation > li > ul > li.dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0px;
  margin-top: 20px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation > li > ul > li > ul {
  position: absolute;
  left: 100%;
  top: 0px;
  width: 220px;
  z-index: 100;
  display: none;
  background-color: #ffffff;
  opacity: 0;
  padding: 10px 0 0;
  margin-top: 10px;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.main-menu .navigation > li > ul > li > ul > li {
  position: relative;
  border-bottom: 1px solid #ebf1f5;
  width: 100%;
}
.main-menu .navigation > li > ul > li > ul > li:last-child {
  border-bottom: none;
}
.main-menu .navigation > li > ul > li > ul > li > a {
  position: relative;
  display: block;
  padding: 10px 0;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  color: var(--theme-color2);
  text-align: left;
  margin: 0 30px;
  text-transform: capitalize;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-menu .navigation > li > ul > li > ul > li > a:hover {
  color: var(--theme-color1);
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  display: none;
}

.main-header .outer-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.main-header .outer-box .theme-btn {
  margin-left: 40px;
  font-size: 12px;
  text-transform: uppercase;
}

.main-header .ui-btn-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding-left: 17px;
  position: relative;
  right: 117px;
}

.main-header .ui-btn {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background: none;
  font-size: 26px;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-header .ui-btn:hover {
  color: var(--theme-color1);
}

.main-header .info-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  padding: 8px 0;
  color: #ffffff;
  text-align: left;
  font-weight: 600;
  white-space: nowrap;
  line-height: 20px;
  margin-left: 30px;
}
.main-header .info-btn .icon {
  position: relative;
  top: 2px;
  line-height: 45px;
  height: 45px;
  font-size: 24px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 20px;
  padding-right: 20px;
}
.main-header .info-btn:hover {
  color: var(--theme-color-light);
}

/*** 

====================================================================
Header Style One
====================================================================

***/
.header-style-one .header-top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 991.98px) {
  .header-style-one .header-top {
    display: none;
  }
}
.header-style-one .header-lower {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.15);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.15);
}
@media (max-width: 991.98px) {
  .header-style-one .header-lower {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.header-style-one .logo-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-style-one .logo-box .logo {
  min-height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 991.98px) {
  .header-style-one .logo-box .logo {
    min-height: auto;
  }
}
.header-style-one .logo-box .search-btn {
  margin-left: 60px;
  margin-right: 30px;
  height: 46px;
  width: 46px;
  text-align: right;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
@media (max-width: 1199.98px) {
  .header-style-one .logo-box .search-btn {
    display: none;
  }
}
.header-style-one .main-box {
  padding: 0 80px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1399.98px) {
  .header-style-one .main-box {
    padding: 0 20px;
  }
}
.header-style-one .main-box .nav-outer {
  width: auto;
}
@media (max-width: 1399.98px) {
  .header-style-one .main-box .nav-outer {
    margin-left: auto;
  }
}
@media only screen and (max-width: 1699px) {
  .header-style-one .main-box .main-menu .navigation > li {
    margin-right: 50px;
  }
  .header-style-one .main-box .main-menu .navigation > li:last-child {
    margin-right: 0;
  }
}
@media (max-width: 1399.98px) {
  .header-style-one .main-box .outer-box .info-btn {
    display: none;
  }
}

/*** 

====================================================================
    Header Style Two
====================================================================

***/
.header-style-two {
  position: relative;
}
.header-style-two .header-top {
  padding: 0 80px;
  background-color: var(--theme-color-light);
}
@media only screen and (max-width: 1699px) {
  .header-style-two .header-top {
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .header-style-two .header-top {
    display: none;
  }
}
.header-style-two .header-top .inner-container {
  padding: 0 30px;
  background-color: var(--bg-theme-color1);
}
.header-style-two .header-top .list-style-one li,
.header-style-two .header-top .useful-links li {
  color: var(--theme-color-light);
}
.header-style-two .header-top .list-style-one li a,
.header-style-two .header-top .useful-links li a {
  color: var(--theme-color-light);
}
.header-style-two .header-top .list-style-one li i,
.header-style-two .header-top .useful-links li i {
  color: var(--theme-color-light);
}
.header-style-two .main-box {
  padding: 0 80px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (max-width: 1699px) {
  .header-style-two .main-box {
    padding: 0 30px;
  }
}
@media (max-width: 991.98px) {
  .header-style-two .main-box {
    padding: 0 20px;
  }
}
.header-style-two .main-box .logo-box {
  padding: 32px 0;
  margin-right: 30px;
}
@media (max-width: 991.98px) {
  .header-style-two .main-box .logo-box {
    padding: 15px 0;
  }
}
.header-style-two .main-box .nav-outer {
  width: auto;
}
.header-style-two .main-box .main-menu .navigation > li {
  padding: 25px 0px;
}
@media only screen and (max-width: 1699px) {
  .header-style-two .main-box .main-menu .navigation > li {
    margin-right: 45px;
  }
}
@media (max-width: 1399.98px) {
  .header-style-two .main-box .main-menu .navigation > li {
    margin-right: 40px;
  }
}
.header-style-two .main-box .main-menu .navigation > li > a {
  color: var(--theme-color2);
}
.header-style-two .main-box .outer-box .info-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--theme-color2);
  padding: 0;
}
@media (max-width: 1399.98px) {
  .header-style-two .main-box .outer-box .info-btn {
    display: none;
  }
}
.header-style-two .main-box .outer-box .info-btn .icon {
  position: relative;
  top: 2px;
  padding-right: 0;
  margin-right: 20px;
}
.header-style-two .main-box .outer-box .ui-btn {
  color: var(--theme-color2);
  margin-left: 40px;
}
.header-style-two .main-box .outer-box .theme-btn {
  padding: 20px 50px;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-left: 30px;
}
@media (max-width: 1199.98px) {
  .header-style-two .main-box .outer-box .theme-btn {
    display: none;
  }
}
.header-style-two .sticky-header .main-menu .navigation > li.current > a,
.header-style-two .sticky-header .main-menu .navigation > li:hover > a {
  color: var(--theme-color2);
}

/*** 

====================================================================
    Header Style Three
====================================================================

***/
.header-style-three {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.header-style-three .logo-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 25px 0;
}
@media (max-width: 991.98px) {
  .header-style-three .logo-box {
    padding: 15px 0;
  }
}
.header-style-three .logo-box .logo {
  min-height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-style-three .logo-box .search-btn {
  margin-left: 60px;
  height: 46px;
  width: 46px;
  text-align: right;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.header-style-three .logo-box .search-btn:hover {
  color: #ffffff;
}
@media (max-width: 991.98px) {
  .header-style-three .logo-box .search-btn {
    display: none;
  }
}
.header-style-three .main-box {
  padding: 0 80px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (max-width: 1699px) {
  .header-style-three .main-box {
    padding: 0 20px;
  }
}
.header-style-three .main-box .nav-outer {
  width: auto;
}
@media only screen and (max-width: 1699px) {
  .header-style-three .main-box .main-menu .navigation > li {
    margin-right: 0;
    margin-left: 40px;
  }
}
@media (max-width: 1399.98px) {
  .header-style-three .main-box .outer-box .info-btn {
    display: none;
  }
}
.header-style-three .main-box .outer-box .mobile-nav-toggler {
  color: var(--theme-color-light);
}

/*** 

====================================================================
    Header Style Four
====================================================================

***/
.header-style-four {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .header-style-four {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
.header-style-four .main-box {
  padding: 0 80px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (max-width: 1699px) {
  .header-style-four .main-box {
    padding: 0 20px;
  }
}
.header-style-four .main-box .logo-box {
  padding: 32px 0;
  margin-right: 30px;
}
@media (max-width: 991.98px) {
  .header-style-four .main-box .logo-box {
    padding: 15px 0;
  }
}
.header-style-four .main-box .nav-outer {
  width: auto;
}
.header-style-four .main-box .main-menu .navigation > li {
  padding: 25px 0px;
}
@media only screen and (max-width: 1699px) {
  .header-style-four .main-box .main-menu .navigation > li {
    margin-right: 40px;
  }
}
.header-style-four .main-box .main-menu .navigation > li > a {
  color: var(--theme-color2);
}
.header-style-four .main-box .outer-box .ui-btn {
  color: var(--theme-color2);
  margin-left: 40px;
}
.header-style-four .main-box .outer-box .theme-btn {
  padding: 20px 50px;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-left: 30px;
}
@media (max-width: 1199.98px) {
  .header-style-four .main-box .outer-box .theme-btn {
    display: none;
  }
}
.header-style-four .sticky-header .main-menu .navigation > li.current > a,
.header-style-four .sticky-header .main-menu .navigation > li:hover > a {
  color: var(--theme-color2);
}

/*** 

====================================================================
Sticky Header
====================================================================

***/
.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: 99999;
  background: #ffffff;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.sticky-header.fixed-header {
  opacity: 1;
  z-index: 9999;
  visibility: visible;
}

.sticky-header .logo {
  padding: 10px 0;
}
.sticky-header .logo img {
  max-height: 40px;
}
.sticky-header .nav-outer {
  position: relative;
  background: none;
}
.sticky-header .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sticky-header .main-menu .navigation > li {
  margin: 0;
  margin-left: 60px;
  padding: 20px 0;
}

.sticky-header .main-menu .navigation > li > a {
  color: var(--theme-color2);
}

.sticky-header .main-menu .navigation > li.current > a,
.sticky-header .main-menu .navigation > li:hover > a {
  color: var(--theme-color2);
}

.sticky-header .outer-box,
.sticky-header .navbar-header {
  display: none;
}

.sticky-header .mobile-nav-toggler {
  color: var(--theme-color2);
}

@media only screen and (min-width: 768px) {
  .main-menu .navigation > li > ul,
.main-menu .navigation > li > ul > li > ul {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }
}
/*** 

====================================================================
      Mobile Menu
====================================================================

***/
.mobile-nav-toggler {
  position: relative;
  font-size: 28px;
  line-height: 20px;
  cursor: pointer;
  color: var(--theme-color1);
  display: none;
  top: 0;
  margin-left: 20px;
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}
@media (max-width: 991.98px) {
  .mobile-nav-toggler {
    display: block;
  }
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}
.mobile-menu .menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
}
.mobile-menu .upper-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px;
}
.mobile-menu .close-btn {
  position: relative;
  top: -10px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
}
.mobile-menu .close-btn:hover {
  opacity: 0.5;
}
.mobile-menu .nav-logo {
  position: relative;
  text-align: left;
  width: 100%;
}
.mobile-menu .nav-logo img {
  max-height: 40px;
}
.mobile-menu .menu-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: var(--theme-color2);
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(101%);
          transform: translateX(101%);
}

.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}
.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.4s ease 200ms;
  transition: all 0.4s ease 200ms;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.mobile-menu-visible .scroll-to-top {
  opacity: 0;
  visibility: hidden;
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li > ul > li:last-child {
  border-bottom: none;
}
.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
}
.mobile-menu .navigation li:hover > a, .mobile-menu .navigation li.current > a {
  color: #ffffff;
}
.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
}
.mobile-menu .navigation li.dropdown .dropdown-btn:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 10px;
  width: 1px;
  height: 24px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .navigation li.dropdown .dropdown-btn.active i:before {
  content: "\f106";
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}

.mobile-menu .navigation li > ul > li {
  padding-left: 20px;
}

.contact-list-one {
  position: relative;
  padding: 30px 20px 20px;
}
.contact-list-one li {
  position: relative;
  margin-bottom: 20px;
}
.contact-list-one li:last-child {
  margin-right: 0;
}
.contact-list-one li .contact-info-box {
  position: relative;
  padding-left: 54px;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}
.contact-list-one li .contact-info-box a {
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.contact-list-one li .contact-info-box .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 34px;
  line-height: 50px;
  color: #ffffff;
}
.contact-list-one li .contact-info-box .title {
  display: block;
  font-size: 12px;
  color: #b2c1c0;
  font-weight: 400;
  text-transform: uppercase;
}

.mobile-menu .social-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: var(--theme-color2);
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto;
}
.mobile-menu .social-links li {
  position: relative;
  text-align: center;
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-menu .social-links li a {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.mobile-menu .social-links li a:hover {
  color: var(--theme-color2);
}

/***

====================================================================
  Hidden Sidebar style
====================================================================

***/
.toggle-hidden-bar {
  position: relative;
  height: 24px;
  min-width: 38px;
}
.toggle-hidden-bar .line {
  position: absolute;
  left: 0;
  top: 10px;
  height: 3px;
  width: 100%;
  background-color: #ffffff;
}
.toggle-hidden-bar .line:nth-child(1) {
  width: 24px;
  top: 0;
}
.toggle-hidden-bar .line:nth-child(3) {
  width: 24px;
  left: auto;
  right: 0;
  top: 21px;
}

.hidden-bar {
  position: fixed;
  right: 0;
  top: 0px;
  height: 100%;
  overflow-y: auto;
  z-index: 99999;
  max-width: 400px ;
  width: 100%;
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  visibility: hidden;
  background: #ffffff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.hidden-bar::-webkit-scrollbar {
  width: 2px;
}
.hidden-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px #cecece;
  box-shadow: inset 0 0 2px #cecece;
  border-radius: 10px;
}
.hidden-bar::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 10px;
}
.hidden-bar::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a;
}
.hidden-bar .inner-box {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--theme-color2);
  height: 100%;
}
.hidden-bar .upper-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding: 30px 30px 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.hidden-bar .upper-box .close-btn {
  position: relative;
  top: -5px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  color: var(--theme-color1);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.hidden-bar .upper-box .close-btn:hover {
  opacity: 0.5;
}
.hidden-bar .upper-box .nav-logo {
  position: relative;
  text-align: left;
  width: 100%;
}
.hidden-bar .upper-box .nav-logo img {
  max-height: 40px;
}
.hidden-bar .text-box {
  position: relative;
  padding: 25px 30px 25px;
}

.contact-list-two {
  position: relative;
  padding: 30px 30px 20px;
}
.contact-list-two li {
  position: relative;
  margin-bottom: 20px;
}
.contact-list-two li:last-child {
  margin-right: 0;
}
.contact-list-two li .contact-info-box {
  position: relative;
  padding-left: 54px;
  font-size: 14px;
  line-height: 24px;
  color: var(--theme-color1);
}
.contact-list-two li .contact-info-box a {
  color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.contact-list-two li .contact-info-box .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 34px;
  line-height: 50px;
  color: var(--theme-color-light);
}
.contact-list-two li .contact-info-box .title {
  display: block;
  font-size: 12px;
  color: var(--theme-color-light);
  font-weight: 700;
  text-transform: uppercase;
}

.hidden-bar-back-drop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.active-hidden-bar .hidden-bar {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.active-hidden-bar .hidden-bar-back-drop {
  opacity: 1;
  visibility: visible;
}

.hidden-bar .social-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: var(--theme-color2);
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: auto;
}
.hidden-bar .social-links li {
  position: relative;
  text-align: center;
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.hidden-bar .social-links li a {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.hidden-bar .social-links li a:hover {
  color: var(--theme-color1);
}

/*** 

====================================================================
Section Title
====================================================================

***/
.sec-title {
  position: relative;
  margin-bottom: 50px;
}
.sec-title .sub-title {
  position: relative;
  display: inline-block;
  font-size: var(--sec-title-subtitle-font-size);
  line-height: var(--sec-title-subtitle-line-height);
  font-weight: var(--sec-title-subtitle-font-weight);
  font-family: var(--sec-title-subtitle-font-family);
  color: var(--sec-title-subtitle-color);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-top: 10px;
}
.sec-title .sub-title:before, .sec-title .sub-title:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 1px;
  background: var(--bg-theme-color1);
}
.sec-title .sub-title::before {
  top: 3px;
  margin-left: 10px;
}
.sec-title h1 {
  position: relative;
  font-size: var(--h1-font-size);
  font-weight: 900;
  line-height: 1em;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .sec-title h1 {
    font-size: 84px;
  }
}
@media (max-width: 991.98px) {
  .sec-title h1 {
    font-size: 72px;
  }
}
@media (max-width: 767.98px) {
  .sec-title h1 {
    font-size: 68px;
  }
}
@media (max-width: 575.98px) {
  .sec-title h1 {
    font-size: 54px;
  }
}
.sec-title h2 {
  position: relative;
  font-size: var(--sec-title-font-size);
  color: var(--sec-title-color);
  font-family: var(--sec-title-font-family);
  font-weight: var(--sec-title-font-weight);
  letter-spacing: -0.02em;
  line-height: 1.2em;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .sec-title h2 {
    font-size: 36px;
  }
  .sec-title h2 br {
    display: none;
  }
}
.sec-title .text {
  margin-top: 30px;
  font-weight: 500;
}
.sec-title.light .text,
.sec-title.light h2,
.sec-title.light h1 {
  color: #fff;
}
.sec-title.text-center .sub-title:after, .sec-title.text-center .sub-title:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/*** 

====================================================================
  Main Slider
====================================================================

***/
.main-slider {
  position: relative;
}
.main-slider h1 {
  position: relative;
  display: block;
  font-size: 100px;
  line-height: 90px;
  color: #ffffff;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
@media (max-width: 1399.98px) {
  .main-slider h1 {
    font-size: 80px;
    line-height: 1.2em;
  }
}
@media (max-width: 991.98px) {
  .main-slider h1 {
    font-size: 72px;
  }
}
@media (max-width: 767.98px) {
  .main-slider h1 {
    font-size: 64px;
  }
}
@media (max-width: 575.98px) {
  .main-slider h1 {
    font-size: 52px;
  }
}
@media only screen and (max-width: 576px) {
  .main-slider h1 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 424px) {
  .main-slider h1 {
    font-size: 32px;
  }
}
.main-slider .text {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 767.98px) {
  .main-slider .text {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 767.98px) {
  .main-slider .theme-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 14px 30px;
  }
}
.main-slider .tp-dottedoverlay {
  background-color: rgba(23, 23, 23, 0.65);
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/main-slider/overlay-shape.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

/*** 

====================================================================
    Banner Section
====================================================================

***/
.banner-section {
  position: relative;
  overflow: hidden;
}
.banner-section .slide-item {
  position: relative;
}
.banner-section .slide-item .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.banner-section .slide-item .bg-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/main-slider/overlay-shape2.png");
  background-color: rgba(23, 23, 23, 0.55);
  content: "";
}
.banner-section .content-box {
  position: relative;
  padding: 215px 0;
  text-align: center;
}
@media (max-width: 575.98px) {
  .banner-section .content-box {
    padding: 150px 0;
  }
}
.banner-section .content-box .title {
  font-size: 100px;
  line-height: 0.9em;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 18px;
}
@media (max-width: 991.98px) {
  .banner-section .content-box .title {
    font-size: 72px;
  }
}
@media (max-width: 767.98px) {
  .banner-section .content-box .title {
    font-size: 62px;
  }
}
@media (max-width: 575.98px) {
  .banner-section .content-box .title {
    font-size: 48px;
  }
}
.banner-section .content-box .text {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 28px;
}
.banner-section .content-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.banner-section .content-box .btn-box .theme-btn {
  margin: 0 5px;
}
@media (max-width: 767.98px) {
  .banner-section .content-box .btn-box .theme-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 14px 30px;
  }
}

/* Animate 1 */
.owl-carousel .animate-7,
.owl-carousel .animate-6,
.owl-carousel .animate-5,
.owl-carousel .animate-4,
.owl-carousel .animate-3,
.owl-carousel .animate-2,
.owl-carousel .animate-1 {
  opacity: 0;
  -webkit-transform: translateY(100px);
          transform: translateY(100px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.owl-carousel .animate-x {
  opacity: 0;
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.owl-carousel .active .animate-7,
.owl-carousel .active .animate-6,
.owl-carousel .active .animate-5,
.owl-carousel .active .animate-4,
.owl-carousel .active .animate-3,
.owl-carousel .active .animate-2,
.owl-carousel .active .animate-1 {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.owl-carousel .active .animate-2 {
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms;
}
.owl-carousel .active .animate-3 {
  -webkit-transition-delay: 600ms;
          transition-delay: 600ms;
}
.owl-carousel .active .animate-4 {
  -webkit-transition-delay: 900ms;
          transition-delay: 900ms;
}
.owl-carousel .active .animate-5 {
  -webkit-transition-delay: 1200ms;
          transition-delay: 1200ms;
}
.owl-carousel .active .animate-6 {
  -webkit-transition-delay: 1500ms;
          transition-delay: 1500ms;
}
.owl-carousel .active .animate-7 {
  -webkit-transition-delay: 1800ms;
          transition-delay: 1800ms;
}

.banner-carousel .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 991.98px) {
  .banner-carousel .owl-nav {
    display: none;
  }
}

/*** 

====================================================================
    Banner Section Two
====================================================================

***/
.banner-section-two {
  position: relative;
  overflow: hidden;
  padding: 105px 0 0 0;
}
@media (max-width: 991.98px) {
  .banner-section-two {
    padding-top: 85px;
  }
}
.banner-section-two .bg-pattern-9 {
  background-color: var(--bg-theme-color1);
  height: auto;
  bottom: 200px;
}
.banner-section-two .owl-nav {
  max-width: auto;
  left: 225px;
  right: auto;
}
@media only screen and (max-width: 1699px) {
  .banner-section-two .owl-nav {
    left: 0;
    right: 0;
  }
}
.banner-section-two .slide-item {
  position: relative;
}
.banner-section-two .slide-item .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: auto;
  right: 0;
  left: 240px;
}
@media only screen and (max-width: 1699px) {
  .banner-section-two .slide-item .bg-image {
    left: 0;
  }
}
.banner-section-two .slide-item .bg-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--theme-color2);
  opacity: 0.65;
  content: "";
}
.banner-section-two .content-box {
  position: relative;
  padding: 200px 0 205px;
}
@media (max-width: 991.98px) {
  .banner-section-two .content-box {
    padding: 150px 0;
  }
}
.banner-section-two .content-box .sub-title {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 15px;
}
.banner-section-two .content-box .sub-title:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  top: auto;
  bottom: 2px;
  border-bottom: 2px solid var(--theme-color1);
  content: "";
}
.banner-section-two .content-box .title {
  font-size: 110px;
  line-height: 0.9em;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 18px;
}
@media (max-width: 991.98px) {
  .banner-section-two .content-box .title {
    font-size: 86px;
  }
}
@media (max-width: 767.98px) {
  .banner-section-two .content-box .title {
    font-size: 72px;
  }
}
@media (max-width: 575.98px) {
  .banner-section-two .content-box .title {
    font-size: 54px;
  }
  .banner-section-two .content-box .title br {
    display: none;
  }
}
.banner-section-two .content-box .btn-box {
  margin-top: 40px;
}
@media (max-width: 767.98px) {
  .banner-section-two .content-box .btn-box .theme-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 14px 30px;
  }
}

/*** 

====================================================================
    Banner Section Three
====================================================================

***/
.banner-section-three {
  position: relative;
  overflow: hidden;
}
.banner-section-three .slide-item {
  position: relative;
}
.banner-section-three .slide-item .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.banner-section-three .slide-item .bg-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/main-slider/overlay-shape2.png");
  content: "";
}
@media (max-width: 1199.98px) {
  .banner-section-three .slide-item .bg-image:before {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
.banner-section-three .content-box {
  position: relative;
  padding: 275px 0 180px;
}
@media (max-width: 991.98px) {
  .banner-section-three .content-box {
    padding: 200px 0 150px;
  }
}
.banner-section-three .content-box .sub-title {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: var(--theme-color1);
  margin-bottom: 20px;
}
@media (max-width: 575.98px) {
  .banner-section-three .content-box .sub-title {
    font-size: 16px;
  }
}
.banner-section-three .content-box .title {
  font-size: 80px;
  line-height: 1em;
  letter-spacing: -0.04em;
  color: var(--theme-color2);
  margin-bottom: 40px;
}
@media (max-width: 991.98px) {
  .banner-section-three .content-box .title {
    font-size: 64px;
    line-height: 1.2em;
  }
}
@media (max-width: 767.98px) {
  .banner-section-three .content-box .title {
    font-size: 52px;
  }
}
@media (max-width: 575.98px) {
  .banner-section-three .content-box .title {
    font-size: 38px;
  }
}

/***

====================================================================
    Projects Section
====================================================================

***/
.projects-section {
  position: relative;
  padding: 120px 0 70px;
  z-index: 3;
}
.projects-section .outer-box {
  margin: 0 -200px;
}
@media only screen and (max-width: 1699px) {
  .projects-section .outer-box {
    margin: 0 -100px;
  }
}
@media (max-width: 1399.98px) {
  .projects-section .outer-box {
    margin: 0;
  }
}

.project-block {
  position: relative;
  margin-bottom: 30px;
}
.project-block .inner-box {
  position: relative;
  overflow: hidden;
}
.project-block .inner-box:hover .image-box .image:before {
  height: 100%;
}
.project-block .inner-box:hover .image-box .image a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.project-block .inner-box:hover .content-box {
  opacity: 1;
  left: 0;
  visibility: visible;
}
.project-block .image-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block .image-box .image {
  position: relative;
  margin-bottom: 0px;
}
.project-block .image-box .image a {
  display: block;
  width: 100%;
}
.project-block .image-box .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.project-block .content-box {
  position: absolute;
  left: -20px;
  bottom: 0;
  padding: 30px 40px 35px;
  z-index: 3;
  max-width: 400px;
  height: 270px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/project-hover.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block .content-box .cat {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  margin-bottom: 5px;
}
.project-block .content-box .title {
  color: var(--theme-color-light);
  margin-bottom: 0;
}
.project-block .content-box .title a:hover {
  text-decoration: underline;
}
.project-block .content-box .icon {
  position: absolute;
  right: 20px;
  top: 35px;
  height: 52px;
  width: 52px;
  font-size: 17px;
  color: var(--theme-color2);
  background-color: var(--theme-color-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
}
.project-block .content-box .icon:hover {
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
}

/***

====================================================================
    Projects Section Two
====================================================================

***/
.projects-section-two {
  position: relative;
  padding: 120px 0 70px;
}
.projects-section-two .sec-title {
  margin-bottom: 25px;
}
.projects-section-two .carousel-outer {
  margin: 0 -255px;
}
@media only screen and (max-width: 1699px) {
  .projects-section-two .carousel-outer {
    margin: 0 -100px;
  }
}
@media (max-width: 1399.98px) {
  .projects-section-two .carousel-outer {
    margin: 0;
  }
}

.project-block-two {
  position: relative;
  padding: 15px 15px;
}
.project-block-two .inner-box {
  position: relative;
  overflow: hidden;
  padding: 10px 10px 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.project-block-two .inner-box::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--bg-theme-color1);
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  content: "";
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.project-block-two .inner-box:hover::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.project-block-two .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.project-block-two .inner-box:hover .image-box .read-more {
  background-color: var(--bg-theme-color1);
  color: var(--theme-color-light);
}
.project-block-two .inner-box:hover .content-box .title {
  color: var(--theme-color-light);
}
.project-block-two .image-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block-two .image-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
}
.project-block-two .image-box .image a {
  display: block;
  width: 100%;
}
.project-block-two .image-box .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block-two .image-box .read-more {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 52px;
  width: 52px;
  font-size: 17px;
  color: var(--theme-color2);
  background-color: var(--theme-color-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block-two .content-box {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  padding: 0 30px 24px;
  text-align: center;
}
.project-block-two .content-box .cat {
  position: relative;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  background-color: var(--theme-color-light);
  display: block;
  text-transform: uppercase;
  text-align: center;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  line-height: 20px;
  padding: 10px 20px;
  max-width: 270px;
  margin: -20px auto 25px;
}
.project-block-two .content-box .title {
  color: var(--theme-color2);
  margin-bottom: 0;
  letter-spacing: -0.05em;
}

/***

====================================================================
    Projects Section Three
====================================================================

***/
.projects-section-three {
  position: relative;
  padding: 120px 0 90px;
}
.projects-section-three .sec-title {
  margin-bottom: 40px;
}
.projects-section-three .filters {
  margin-top: 105px;
}
@media (max-width: 1199.98px) {
  .projects-section-three .filters {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.sortable-masonry {
  position: relative;
}
.sortable-masonry .filter-tabs {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (max-width: 1199.98px) {
  .sortable-masonry .filter-tabs {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.sortable-masonry .filter-tabs .filter {
  position: relative;
  font-size: 18px;
  line-height: 25px;
  color: black;
  font-weight: 500;
  margin-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1199.98px) {
  .sortable-masonry .filter-tabs .filter {
    margin-left: 0;
    margin-right: 30px;
  }
}
.sortable-masonry .filter-tabs .filter.active {
  font-weight: 700;
  border-top: 1px solid var(--theme-color1);
  color: var(--theme-color2);
}

.project-block-three {
  position: relative;
  margin-bottom: 30px;
}
.project-block-three .inner-box {
  position: relative;
  overflow: hidden;
}
.project-block-three .inner-box:hover .image-box .image:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.project-block-three .inner-box:hover .image-box .read-more {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms;
}
.project-block-three .inner-box:hover .content-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms;
}
.project-block-three .image-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block-three .image-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
}
.project-block-three .image-box .image::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(var(--bg-theme-color1)), to(rgba(23, 23, 23, 0.05)));
  background: linear-gradient(to top, var(--bg-theme-color1), rgba(23, 23, 23, 0.05));
  content: "";
  -webkit-transform: scaleY(0.2);
          transform: scaleY(0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.project-block-three .image-box .image a {
  display: block;
  width: 100%;
}
.project-block-three .image-box .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block-three .image-box .read-more {
  position: absolute;
  right: 30px;
  top: 30px;
  height: 52px;
  width: 52px;
  font-size: 17px;
  color: var(--theme-color-light);
  background-color: var(--bg-theme-color1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block-three .content-box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 40px 35px 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.project-block-three .content-box .cat {
  position: relative;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  display: block;
  color: #fff;
  text-transform: uppercase;
  line-height: 20px;
  margin-bottom: 15px;
}
.project-block-three .content-box .title {
  color: var(--theme-color-light);
  margin-bottom: 0;
  letter-spacing: -0.05em;
}

/*** 

====================================================================
    Features Section
====================================================================

***/
.features-section {
  position: relative;
  padding: 100px 0 70px;
}
.features-section .bg {
  max-height: 375px;
}
@media (max-width: 767.98px) {
  .features-section .bg {
    min-height: 30%;
  }
}
.features-section .sec-title .text {
  padding-left: 70px;
  color: #848484;
  margin-top: 0;
}
@media (max-width: 991.98px) {
  .features-section .sec-title .text {
    padding-left: 0;
    margin-top: 30px;
  }
}

.feature-block {
  margin-bottom: 80px;
}
.feature-block .inner-box {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 50px 50px rgba(0, 0, 0, 0.07);
          box-shadow: 0 50px 50px rgba(0, 0, 0, 0.07);
}
.feature-block .inner-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--theme-color1);
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.feature-block .inner-box:hover:before {
  width: 0;
  left: 50%;
}
.feature-block .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.feature-block .inner-box:hover .content-box .icon-box {
  background-color: var(--theme-color2);
}
.feature-block .inner-box .image-box {
  position: relative;
}
.feature-block .inner-box .image-box .image {
  position: relative;
  overflow: hidden;
  margin: 0;
  z-index: 1;
}
.feature-block .inner-box .image-box .image::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(transparent));
  background: linear-gradient(to top, #fff, transparent);
  z-index: 2;
  content: "";
}
.feature-block .inner-box .image-box .image img {
  position: relative;
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.feature-block .inner-box .content-box {
  position: relative;
  width: 100%;
  padding: 0 50px 10px;
  text-align: center;
  margin-top: -45px;
  z-index: 4;
}
.feature-block .inner-box .content-box .title {
  font-weight: 900;
  max-width: 130px;
  letter-spacing: -0.04em;
  margin: 0 auto 6px;
}
.feature-block .inner-box .content-box .text {
  margin-bottom: 11px;
}
.feature-block .inner-box .content-box .icon-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100px;
  height: 100px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  text-align: center;
  font-size: 32px;
  color: #fff;
  border: 12px solid #fff;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.01);
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.01);
  z-index: 99;
  margin: 0 auto -60px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.feature-block-four .inner-box {
  position: relative;
  background-color: #fff;
  padding: 50px 60px;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
@media only screen and (max-width: 1699px) {
  .feature-block-four .inner-box {
    padding: 50px 50px;
  }
}
.feature-block-four .inner-box:hover .icon-box {
  border-radius: 50%;
  background-color: var(--bg-theme-color1);
}
.feature-block-four .inner-box:hover .icon-box .icon {
  color: var(--theme-color-light);
}
.feature-block-four .inner-box .icon-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 138px;
  width: 138px;
  background-color: #f4f4f4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 1399.98px) {
  .feature-block-four .inner-box .icon-box {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 20px;
  }
}
.feature-block-four .inner-box .icon-box .icon {
  color: var(--theme-color1);
  font-size: 64px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.feature-block-four .inner-box .content-box {
  position: relative;
  padding-left: 170px;
  min-height: 138px;
}
@media (max-width: 1399.98px) {
  .feature-block-four .inner-box .content-box {
    padding-left: 0;
    text-align: center;
  }
}
.feature-block-four .inner-box .content-box .text {
  margin-bottom: 11px;
}

/*** 

====================================================================
		About Section
====================================================================

***/
.about-section {
  position: relative;
  padding: 100px 0 50px;
}
.about-section:before {
  position: absolute;
  right: 0;
  top: -40px;
  width: 367px;
  height: 730px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/dots1.png");
  content: "";
}
.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .about-section .content-column {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
}
.about-section .content-column .inner-column {
  position: relative;
  padding-left: 70px;
}
@media (max-width: 1199.98px) {
  .about-section .content-column .inner-column {
    padding-left: 0;
  }
}
.about-section .content-column .sec-title {
  margin-bottom: 30px;
}
.about-section .content-column .other-info {
  position: relative;
  padding-right: 200px;
}
@media (max-width: 767.98px) {
  .about-section .content-column .other-info {
    padding-right: 0;
  }
  .about-section .content-column .other-info .theme-btn {
    width: 100%;
  }
}
.about-section .content-column .info-box {
  position: relative;
  border-top: 6px solid var(--theme-color1);
  padding-top: 14px;
  padding-left: 50px;
  margin-bottom: 30px;
}
.about-section .content-column .info-box:hover .icon {
  color: var(--theme-color2);
}
.about-section .content-column .info-box .icon {
  position: absolute;
  left: 0;
  top: 30px;
  color: var(--theme-color1);
  line-height: 1em;
  font-size: 32px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.about-section .content-column .info-box .title {
  display: block;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}
.about-section .content-column .info-box .info-text {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.about-section .content-column .info-box .info-text:hover {
  color: var(--theme-color1);
}
.about-section .content-column .founded-year {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f4f4f4;
  text-align: center;
  width: 170px;
  height: 136px;
  padding-top: 30px;
  margin-left: 30px;
}
@media (max-width: 767.98px) {
  .about-section .content-column .founded-year {
    position: relative;
    margin-left: 0;
    width: 100%;
    margin-bottom: 30px;
  }
}
.about-section .content-column .founded-year:before {
  content: "";
  position: absolute;
  top: 10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border: 1px solid #e6e6e6;
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.about-section .content-column .founded-year:before:hover {
  top: 0;
  left: 0;
}
.about-section .content-column .founded-year .icon {
  color: var(--theme-color1);
  font-size: 32px;
  margin-bottom: 6px;
}
.about-section .content-column .founded-year .title {
  text-transform: uppercase;
  letter-spacing: 2px;
}
.about-section .image-column {
  position: relative;
  margin-bottom: 50px;
}
.about-section .image-column .image-box {
  position: relative;
}
@media (max-width: 991.98px) {
  .about-section .image-column .image-box {
    text-align: center;
  }
}
.about-section .image-column .image-box .icon-shpaes {
  position: absolute;
  left: -80px;
  top: 15px;
  height: 556px;
  width: 247px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shapes.png");
}
.about-section .image-column .image {
  margin: 0;
}
.about-section .image-column .image img {
  -webkit-filter: drop-shadow(0 10px 100px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 10px 100px rgba(0, 0, 0, 0.1));
}

/*** 

====================================================================
    About Section Two
====================================================================

***/
.about-section-two {
  position: relative;
  padding: 120px 0 70px;
}
.about-section-two:before {
  position: absolute;
  right: 0;
  top: 90px;
  width: 367px;
  height: 730px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/dots1.png");
  content: "";
}
.about-section-two .content-column {
  position: relative;
  margin-bottom: 50px;
}
@media (max-width: 991.98px) {
  .about-section-two .content-column {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
}
.about-section-two .content-column .inner-column {
  position: relative;
  padding-left: 70px;
}
@media (max-width: 1199.98px) {
  .about-section-two .content-column .inner-column {
    padding-left: 0;
  }
}
.about-section-two .content-column .sec-title {
  margin-bottom: 25px;
}
.about-section-two .content-column .sec-title .other-title {
  font-weight: 700;
  margin-top: 35px;
  letter-spacing: -0.01em;
  color: var(--theme-color1);
  margin-bottom: 0;
}
.about-section-two .content-column .sec-title .text {
  margin-top: 12px;
}
.about-section-two .content-column .about-info-box {
  position: relative;
  margin-bottom: 25px;
}
.about-section-two .content-column .about-info-box:hover .icon {
  color: var(--theme-color2);
  -webkit-transform: rotate(180deg) scale(-1);
          transform: rotate(180deg) scale(-1);
}
.about-section-two .content-column .about-info-box .title {
  display: inline-block;
  margin-bottom: 20px;
  padding-bottom: 5px;
  letter-spacing: -0.05em;
  border-bottom: 2px solid var(--theme-color1);
}
.about-section-two .content-column .about-info-box .inner {
  position: relative;
  padding-left: 95px;
  padding-top: 10px;
  min-height: 76px;
}
@media (max-width: 575.98px) {
  .about-section-two .content-column .about-info-box .inner {
    padding-top: 0px;
  }
}
.about-section-two .content-column .about-info-box .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 76px;
  width: 76px;
  background-color: #f4f4f4;
  color: #ee763c;
  font-size: 42px;
 
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.about-section-two .content-column .about-info-box .text {
  font-weight: 500;
}
.about-section-two .content-column .theme-btn {
  margin-top: 15px;
}
.about-section-two .image-column {
  position: relative;
  margin-bottom: 0px;
}
.about-section-two .image-column .inner-column {
  position: relative;
  padding-bottom: 130px;
}
@media (max-width: 767.98px) {
  .about-section-two .image-column .inner-column {
    padding-bottom: 50px;
  }
}
.about-section-two .image-column .image-1 {
  margin: 0;
}
@media (max-width: 767.98px) {
  .about-section-two .image-column .image-1 img {
    width: 100%;
  }
}
.about-section-two .image-column .image-2 {
  position: absolute;
  right: 0;
  bottom: 0px;
  border: 20px solid #fff;
  border-right: 0;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .about-section-two .image-column .image-2 {
    display: none;
  }
}
.about-section-two .image-column .exp-box {
  position: absolute;
  left: -125px;
  bottom: 130px;
  padding: 35px 40px;
  background-color: var(--bg-theme-color1);
  font-size: 20px;
  line-height: 1.2em;
  color: var(--theme-color-light);
  letter-spacing: -0.04em;
  font-weight: 800;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/lines3.png");
  background-position: left bottom;
  background-repeat: no-repeat;
}
@media (max-width: 1399.98px) {
  .about-section-two .image-column .exp-box {
    left: 15px;
    bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  .about-section-two .image-column .exp-box {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .about-section-two .image-column .exp-box::before {
    display: none;
  }
}
.about-section-two .image-column .exp-box:before {
  position: absolute;
  right: -40px;
  bottom: 0;
  content: "";
  border-bottom: 50px solid var(--theme-color1);
  border-right: 40px solid transparent;
}

/*** 

====================================================================
    About Section Three
====================================================================

***/
.about-section-three {
  position: relative;
  padding: 120px 0 90px;
}
.about-section-three .bg::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: var(--theme-color2);
  opacity: 0.9;
}
.about-section-three .bg.pull-up {
  top: -160px;
  bottom: 0;
  height: auto;
}

.about-block {
  position: relative;
  margin-bottom: 30px;
}
.about-block .inner-box {
  position: relative;
  background-color: var(--theme-color-light);
  padding: 25px 20px 25px;
  padding-left: 155px;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
}
.about-block .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  border: 2px solid var(--theme-color1);
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.about-block .inner-box:hover::before {
  opacity: 1;
}
.about-block .image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 130px;
}
.about-block .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-block .title {
  margin-bottom: 12px;
}
@media (max-width: 575.98px) {
  .about-block .text {
    font-size: 14px;
    line-height: 24px;
  }
}

/*** 

====================================================================
    About Section Four
====================================================================

***/
.about-section-four {
  position: relative;
  padding: 120px 0 90px;
}
.about-section-four:before {
  position: absolute;
  left: 0;
  top: 40px;
  width: 367px;
  height: 730px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/dots1.png");
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  content: "";
}
@media (max-width: 991.98px) {
  .about-section-four:before {
    display: none;
  }
}
.about-section-four:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 532px;
  height: 544px;
  background-color: #f4f4f4;
  content: "";
  z-index: -1;
}
@media (max-width: 991.98px) {
  .about-section-four:after {
    display: none;
  }
}
.about-section-four .content-column {
  margin-bottom: 50px;
}
.about-section-four .content-column .inner-column {
  padding-right: 70px;
}
@media (max-width: 1199.98px) {
  .about-section-four .content-column .inner-column {
    padding-right: 0;
  }
}
.about-section-four .content-column .sec-title {
  margin-bottom: 30px;
}
.about-section-four .content-column .other-text {
  font-weight: 700;
  margin-bottom: 25px;
}
.about-section-four .content-column .list-style-two li {
  font-size: 16px;
  line-height: 24px;
}
.about-section-four .content-column .list-style-two li i {
  font-size: 14px;
}
.about-section-four .image-column {
  position: relative;
  margin-bottom: 50px;
}
.about-section-four .image-column .inner-column {
  position: relative;
}
.about-section-four .image-column .image {
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .about-section-four .image-column .image img {
    width: 100%;
  }
}
.about-section-four .image-column .exp-box {
  position: absolute;
  left: 30px;
  bottom: -30px;
  padding: 20px 20px;
  background-color: #f4f4f4;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
  min-height: 204px;
  width: 280px;
  text-align: center;
}
@media (max-width: 991.98px) {
  .about-section-four .image-column .exp-box {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
.about-section-four .image-column .exp-box:before {
  position: absolute;
  top: 18px;
  left: 18px;
  right: 18px;
  bottom: 18px;
  border: 2px solid var(--theme-color1);
  background-color: #fff;
  content: "";
}
.about-section-four .image-column .exp-box .icon {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 109px;
  height: 120px;
  font-size: 64px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape5.png");
  color: var(--theme-color-light);
  margin: -60px auto 20px;
}
.about-section-four .image-column .exp-box .count {
  position: relative;
  display: block;
  font-size: 36px;
  color: var(--theme-color2);
  font-weight: 800;
  margin-bottom: 5px;
}
.about-section-four .image-column .exp-box .text {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}
.about-section-four .blocks-outer {
  position: relative;
  padding: 70px 0 0;
}

/*** 

====================================================================
    About Section Five
====================================================================

***/
.about-section-five {
  position: relative;
  padding: 120px 0 90px;
}
.about-section-five:before {
  position: absolute;
  right: 0;
  top: 90px;
  width: 367px;
  height: 730px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/dots1.png");
  content: "";
}
.about-section-five .content-column {
  position: relative;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .about-section-five .content-column {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
}
.about-section-five .content-column .inner-column {
  position: relative;
  margin-top: 65px;
  margin-left: -70px;
  padding-top: 80px;
  padding-left: 80px;
  padding-bottom: 80px;
  background-color: #fff;
}
@media (max-width: 1199.98px) {
  .about-section-five .content-column .inner-column {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 40px;
  }
}
@media (max-width: 991.98px) {
  .about-section-five .content-column .inner-column {
    padding-left: 0;
    margin-left: 0;
  }
}
.about-section-five .content-column .sec-title {
  margin-bottom: 35px;
}
.about-section-five .content-column .skills {
  margin-bottom: 10px;
}
.about-section-five .image-column {
  position: relative;
}
.about-section-five .image-column .inner-column {
  position: relative;
  margin-right: -80px;
}
@media (max-width: 991.98px) {
  .about-section-five .image-column .inner-column {
    margin-right: 0;
    margin-top: 30px;
  }
}
.about-section-five .image-column .image-1 {
  position: relative;
  margin-bottom: 0;
}
.about-section-five .image-column .image-1 img {
  width: 100%;
}
.about-section-five .image-column .image-2 {
  position: absolute;
  left: -80px;
  bottom: -20px;
  border: 20px solid #fff;
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .about-section-five .image-column .image-2 {
    display: none;
  }
}

/*** 

====================================================================
    Services Section
====================================================================

***/
.services-section {
  position: relative;
  padding: 120px 0 70px;
}
.services-section .sec-title .text {
  margin-top: 40px;
}
@media (max-width: 991.98px) {
  .services-section .sec-title .text {
    margin-top: 20px;
  }
}
.services-section .services-column {
  margin-bottom: 40px;
}
.services-section .services-column .inner-column {
  position: relative;
  margin-right: -60px;
}
@media (max-width: 991.98px) {
  .services-section .services-column .inner-column {
    margin-right: 0;
  }
}
.services-section .services-column .row {
  margin: 0 -5px;
}
.services-section .services-column .row > div {
  padding: 0 5px;
}
.services-section .banner-column {
  position: relative;
  margin-bottom: 50px;
}

.service-block {
  margin-bottom: 10px;
}
.service-block .inner-box {
  position: relative;
  padding: 30px 30px 30px;
  background: #fff;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block .inner-box:hover:before {
  max-width: 100%;
}
.service-block .inner-box:hover::after {
  opacity: 0.2;
}
.service-block .inner-box:hover .count {
  opacity: 0.3;
}
.service-block .inner-box:hover .icon {
  background-color: #ffffff;
  color: var(--theme-color1);
}
.service-block .inner-box:hover .title {
  color: var(--theme-color-light);
}
.service-block .inner-box:after {
  content: "";
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 26px;
  background: -webkit-gradient(linear, left top, right top, from(#f4f4f4), to(transparent));
  background: linear-gradient(to right, #f4f4f4, transparent);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 0;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block .inner-box .count {
  position: absolute;
  top: 45px;
  right: 30px;
  color: #e6e6e6;
  font-size: 30px;
  font-weight: 200;
}
.service-block .inner-box .icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 66px;
  height: 66px;
  color: #fff;
  background: var(--theme-color1);
  font-size: 32px;
  border-radius: 50%;
  margin-bottom: 26px;
  z-index: 2;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block .inner-box .title {
  margin-bottom: 0;
  letter-spacing: -0.06em;
  font-weight: 800;
}
@media (max-width: 575.98px) {
  .service-block .inner-box .title br {
    display: none;
  }
}

.service-banner {
  position: relative;
  height: 100%;
  margin-left: 65px;
}
@media (max-width: 991.98px) {
  .service-banner {
    margin-left: 0;
    max-width: 400px;
    margin: 0 auto;
  }
}
.service-banner .bg-image {
  margin-bottom: 0;
  height: 100%;
}
.service-banner .bg-image::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color2);
  opacity: 0.8;
  content: "";
}
.service-banner .bg-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.service-banner .content {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  padding: 0 50px;
  padding-right: 40px;
}
@media (max-width: 575.98px) {
  .service-banner .content {
    padding: 30px 30px;
    bottom: 0;
  }
}
.service-banner .content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--theme-color1);
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
  margin-bottom: 4px;
}
.service-banner .content .title {
  color: #ffffff;
  font-weight: 800;
  letter-spacing: -0.04em;
  line-height: 1.3em;
  margin-bottom: 40px;
}

/*** 

====================================================================
    Services Section Two
====================================================================

***/
.services-section-two {
  position: relative;
  padding: 120px 0 80px;
}
.services-section-two .sec-title {
  margin-bottom: 40px;
}
.services-section-two .sec-title .text {
  margin-top: 40px;
}
.services-section-two .carousel-outer {
  margin: 0 -165px;
}
@media (max-width: 1399.98px) {
  .services-section-two .carousel-outer {
    margin: 0 -15px;
  }
}
.services-section-two .bottom-text {
  position: relative;
  padding: 10px 20px;
  background-color: var(--bg-theme-color1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 700px;
  margin: 45px auto 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 575.98px) {
  .services-section-two .bottom-text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    padding: 20px 20px;
  }
}
.services-section-two .bottom-text .text {
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-color-light);
}
@media (max-width: 575.98px) {
  .services-section-two .bottom-text .text {
    margin-bottom: 20px;
  }
}
.services-section-two .bottom-text .theme-btn {
  font-size: 12px;
  line-height: 20px;
  color: var(--theme-color2);
  background-color: var(--theme-color-light);
  padding: 5px 25px;
  font-weight: 800;
}
.services-section-two .bottom-text .theme-btn:hover {
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
}

.service-block-two {
  padding: 15px 15px;
}
.service-block-two .inner-box {
  position: relative;
  background: #fff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-two .inner-box:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}
.service-block-two .inner-box:hover .hover-content {
  opacity: 1;
  visibility: visible;
}
.service-block-two .inner-box:hover .hover-content .read-more {
  right: 40px;
}
.service-block-two .inner-box:after {
  content: "";
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 26px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 0;
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-two .inner-box .image-box {
  position: relative;
  min-height: 204px;
}
.service-block-two .inner-box .image-box .image {
  position: relative;
  margin-bottom: 0;
  background-color: var(--theme-color2);
}
.service-block-two .inner-box .image-box .image img {
  opacity: 0.25;
}
.service-block-two .inner-box .content-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 40px 40px 20px;
}
.service-block-two .inner-box .content-box .icon {
  position: relative;
  display: block;
  color: var(--theme-color1);
  font-size: 64px;
  z-index: 2;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-bottom: 20px;
}
.service-block-two .inner-box .content-box .title {
  margin-bottom: 0;
  color: var(--theme-color-light);
  letter-spacing: -0.01em;
  font-weight: 700;
}
.service-block-two .inner-box .hover-content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color-light);
  z-index: 2;
  padding: 35px 40px 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-two .inner-box .hover-content::before {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  border: 2px solid;
  -o-border-image: linear-gradient(to right, var(--theme-color1), transparent, transparent) 1;
     border-image: -webkit-gradient(linear, left top, right top, from(var(--theme-color1)), color-stop(transparent), to(transparent)) 1;
     border-image: linear-gradient(to right, var(--theme-color1), transparent, transparent) 1;
  content: "";
}
.service-block-two .inner-box .hover-content .icon {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 64px;
  color: #f4f4f4;
}
.service-block-two .inner-box .hover-content .title {
  margin-bottom: 20px;
}
.service-block-two .inner-box .hover-content .text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.service-block-two .inner-box .hover-content .read-more {
  position: absolute;
  right: 70px;
  bottom: -14px;
  height: 52px;
  width: 52px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  color: #fff;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-two .inner-box .hover-content .read-more:hover {
  background-color: var(--theme-color2);
}

/*** 

====================================================================
    Services Section Three
====================================================================

***/
.services-section-three {
  position: relative;
  padding: 120px 0 50px;
}
.services-section-three .bg {
  max-height: 490px;
}
.services-section-three .sec-title {
  margin-bottom: 50px;
}
.services-section-three .sec-title .text-box {
  position: relative;
  padding-right: 300px;
}
@media (max-width: 1199.98px) {
  .services-section-three .sec-title .text-box {
    padding-right: 0;
    margin-top: 20px;
  }
}
.services-section-three .sec-title .text-box .theme-btn {
  position: absolute;
  right: 0;
  top: 20px;
}
@media (max-width: 1199.98px) {
  .services-section-three .sec-title .text-box .theme-btn {
    position: relative;
    top: 0;
    margin-top: 20px;
  }
}
.services-section-three .sec-title .text-box .text {
  margin-top: 0;
  color: var(--body-text-color);
}
.services-section-three .sponsors-outer {
  border-top: 1px solid #e6e6e6;
  padding: 85px 0;
}

.service-block-three {
  margin-bottom: 70px;
}
.service-block-three .inner-box {
  position: relative;
  background: #fff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  padding: 50px 50px;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1199.98px) {
  .service-block-three .inner-box {
    padding: 40px 40px 50px;
  }
}
.service-block-three .inner-box:hover {
  background-color: var(--bg-theme-color1);
}
.service-block-three .inner-box:hover:before {
  opacity: 0.2;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}
.service-block-three .inner-box:hover .text,
.service-block-three .inner-box:hover .title,
.service-block-three .inner-box:hover .icon {
  color: #fff;
}
.service-block-three .inner-box:hover .icon {
  -webkit-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}
.service-block-three .inner-box:hover .read-more {
  background-color: var(--theme-color2);
}
.service-block-three .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape6.png");
  background-position: right top;
  background-repeat: no-repeat;
  content: "";
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.service-block-three .inner-box .icon {
  position: relative;
  display: inline-block;
  color: var(--theme-color1);
  font-size: 62px;
  z-index: 2;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-bottom: 20px;
}
.service-block-three .inner-box .title {
  margin-bottom: 20px;
}
.service-block-three .inner-box .text {
  font-weight: 500;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-three .inner-box .read-more {
  position: absolute;
  right: 40px;
  bottom: -26px;
  height: 52px;
  width: 52px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  color: #fff;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-three .inner-box .read-more:hover {
  background-color: var(--theme-color2);
}

/*** 

====================================================================
    Services Section Four
====================================================================

***/
.services-section-four {
  position: relative;
  padding: 120px 0 90px;
}
.services-section-four .bg {
  max-height: 440px;
}

.service-block-four {
  margin-bottom: 30px;
}
.service-block-four .inner-box {
  position: relative;
  background: #fff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-box-shadow: 0 0px 30px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0px 30px rgba(0, 0, 0, 0.05);
}
.service-block-four .inner-box:hover {
  background-color: var(--bg-theme-color1);
}
.service-block-four .inner-box:hover:before {
  opacity: 0.2;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}
.service-block-four .inner-box:hover .text,
.service-block-four .inner-box:hover .title {
  color: var(--theme-color-light);
}
.service-block-four .inner-box:hover .icon {
  color: var(--theme-color1);
}
.service-block-four .inner-box:hover .icon:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.service-block-four .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 0.7;
}
.service-block-four .inner-box:hover .read-more {
  background-color: var(--theme-color2);
}
.service-block-four .inner-box .image-box {
  position: relative;
}
.service-block-four .inner-box .image-box .image {
  margin-bottom: 0;
  background-color: var(--theme-color2);
  overflow: hidden;
}
.service-block-four .inner-box .image-box .image img {
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-four .inner-box .content-box {
  position: relative;
  padding: 25px 30px 30px;
}
.service-block-four .inner-box .icon {
  position: absolute;
  right: 20px;
  top: -39px;
  width: 70px;
  height: 78px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape8.png");
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: var(--theme-color-light);
  font-size: 36px;
  z-index: 2;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-bottom: 20px;
}
.service-block-four .inner-box .icon::before {
  position: relative;
  z-index: 2;
}
.service-block-four .inner-box .icon:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape8-light.png");
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  content: "";
}
.service-block-four .inner-box .title {
  margin-bottom: 15px;
}
.service-block-four .inner-box .text {
  font-weight: 500;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-four .inner-box .read-more {
  position: absolute;
  right: 40px;
  bottom: -26px;
  height: 52px;
  width: 52px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  color: #fff;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block-four .inner-box .read-more:hover {
  background-color: var(--theme-color2);
}

/*** 

====================================================================
		Call To Action
====================================================================

***/
.call-to-action {
  position: relative;
  z-index: 2;
}
.call-to-action.pull-down .outer-box {
  margin-bottom: -70px;
  display: inline-block;
  width: 100%;
}
.call-to-action .outer-box {
  position: relative;
  background-color: var(--bg-theme-color1);
  padding: 75px 60px;
  padding-left: 320px;
  margin-top: 60px;
}
@media (max-width: 767.98px) {
  .call-to-action .outer-box {
    padding: 75px 20px 0;
    text-align: center;
  }
}
.call-to-action .outer-box .title-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 991.98px) {
  .call-to-action .outer-box .title-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
@media (max-width: 767.98px) {
  .call-to-action .outer-box .title-box {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.call-to-action .outer-box .title-box .title {
  font-size: 40px;
  font-weight: 800;
  color: #fff;
  letter-spacing: -0.05em;
  margin-bottom: 0;
  padding-right: 30px;
}
@media (max-width: 991.98px) {
  .call-to-action .outer-box .title-box .title {
    padding-right: 0;
    font-size: 34px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .call-to-action .outer-box .title-box .title br {
    display: none;
  }
}
.call-to-action .outer-box .image-box {
  position: absolute;
  left: 50px;
  bottom: 0;
}
@media (max-width: 767.98px) {
  .call-to-action .outer-box .image-box {
    position: relative;
    left: 0;
    text-align: center;
    width: 100%;
    margin-top: 30px;
  }
}
.call-to-action .outer-box .image-box .image {
  margin-bottom: 0;
}

/*** 

====================================================================
    Call To Action Two
====================================================================

***/
.call-to-action-two {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 120px 0;
}
.call-to-action-two .bg:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(var(--theme-color1)), to(transparent));
  background: linear-gradient(to top, var(--theme-color1), transparent);
  opacity: 0.9;
  content: "";
}
.call-to-action-two .bg.pull-up {
  height: auto;
  bottom: 0;
  top: -275px;
}
.call-to-action-two .title {
  letter-spacing: -0.05em;
  margin-bottom: 10px;
}
.call-to-action-two .text {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  color: var(--theme-color2);
  margin-bottom: 40px;
}

/*** 

====================================================================
		FAQ's Sectiom
====================================================================

***/
.faqs-section {
  position: relative;
  padding: 120px 0 70px;
}
.faqs-section .faq-column {
  margin-bottom: 50px;
}
.faqs-section .faq-column .inner-column .sec-title {
  margin-bottom: 40px;
}
.faqs-section .image-column {
  margin-bottom: 50px;
}
@media (max-width: 991.98px) {
  .faqs-section .image-column {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
}
.faqs-section .image-column .inner-column {
  position: relative;
  padding: 100px 0;
  padding-right: 70px;
  margin-left: -260px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 1699px) {
  .faqs-section .image-column .inner-column {
    margin-left: 0;
  }
}
@media (max-width: 1399.98px) {
  .faqs-section .image-column .inner-column {
    padding: 0;
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.faqs-section .image-column .bg-image {
  width: auto;
  right: 185px;
  left: -115px;
}
@media (max-width: 1399.98px) {
  .faqs-section .image-column .bg-image {
    display: none;
  }
}
.faqs-section .image-column .bg-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: var(--theme-color2);
  opacity: 0.9;
}
.faqs-section .image-column .image-box {
  position: relative;
}
.faqs-section .image-column .image-box .image {
  margin-bottom: 0;
}
@media (max-width: 1399.98px) {
  .faqs-section .image-column .image-box .image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.faq-info-box {
  position: relative;
  margin-right: 20px;
  max-width: 260px;
}
@media only screen and (max-width: 1699px) {
  .faq-info-box {
    margin-right: 0;
    max-width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .faq-info-box {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .faq-info-box {
    text-align: center;
  }
}
.faq-info-box .inner {
  position: relative;
  background-color: var(--bg-theme-color1);
  padding: 40px 40px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  height: 100%;
}
@media (max-width: 991.98px) {
  .faq-info-box .inner {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 40px 20px;
  }
}
.faq-info-box .icon {
  display: block;
  font-size: 62px;
  color: var(--theme-color-light);
  margin-bottom: 20px;
}
.faq-info-box .title {
  font-size: 30px;
  line-height: 1.2em;
  font-weight: 700;
  color: #fff;
  letter-spacing: -0.05em;
  margin-bottom: 30px;
}
.faq-info-box .theme-btn {
  margin-top: auto;
  line-height: 20px;
  font-size: 12px;
  padding: 5px 20px;
}

.accordion-box {
  position: relative;
}
.accordion-box .block {
  position: relative;
  margin-bottom: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
}
.accordion-box .block:last-child {
  margin-bottom: 0;
}
.accordion-box .block .acc-btn {
  position: relative;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  font-weight: 800;
  cursor: pointer;
  font-family: var(--title-font);
  padding: 20px 30px;
  padding-right: 70px;
  letter-spacing: -0.04em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box .block .acc-btn .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 65px;
  width: 65px;
  font-size: 16px;
  line-height: 65px;
  color: var(--theme-color2);
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box .block .acc-btn.active {
  background-color: var(--theme-color1);
  color: #fff;
}
.accordion-box .block .acc-btn.active .icon {
  font-size: 14px;
  color: #fff;
  background-color: var(--bg-theme-color1);
}
.accordion-box .block .acc-btn.active .icon:before {
  content: "\f068";
}
.accordion-box .block .acc-content {
  position: relative;
  display: none;
}
.accordion-box .block .acc-content .content {
  position: relative;
  padding: 22px 30px 25px;
}
.accordion-box .block .acc-content .content .text {
  font-weight: 500;
  margin-bottom: 0;
}
.accordion-box .block .acc-content.current {
  display: block;
}

.faq-block {
  position: relative;
  margin-bottom: 40px;
}
.faq-block .inner-box {
  padding-left: 70px;
  position: relative;
}
.faq-block .inner-box:hover .icon {
  background-color: var(--bg-theme-color1);
}
.faq-block .icon {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  color: #fff;
  background-color: var(--bg-theme-color2);
  border-radius: 50%;
  font-size: 14px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.faq-block h4 {
  font-size: 18px;
  line-height: 1.2em;
  color: var(--theme-color1);
  font-weight: 700;
  margin-bottom: 10px;
}
.faq-block .text {
  font-size: 16px;
  line-height: 30px;
  color: var(--theme-color4);
}

/*** 

====================================================================
	Faqs Section Two
====================================================================

***/
.faq-section-two {
  position: relative;
  padding: 160px 0 150px;
}
@media (max-width: 991.98px) {
  .faq-section-two {
    padding: 120px 0;
  }
}
@media (max-width: 991.98px) {
  .faq-section-two .faq-column {
    margin-bottom: 50px;
  }
}
.faq-section-two .faq-column .inner-column {
  padding-left: 70px;
  padding-right: 100px;
  padding-bottom: 10px;
  border-left: 8px solid var(--theme-color2);
}
@media (max-width: 1199.98px) {
  .faq-section-two .faq-column .inner-column {
    padding: 0;
    border: 0;
  }
}
.faq-section-two .float-image {
  position: absolute;
  right: -170px;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .faq-section-two .float-image {
    display: none;
  }
}

/*** 

====================================================================
    Fun Fact Section
====================================================================

***/
.fun-fact-section {
  position: relative;
  padding: 100px 0 70px;
}
.fun-fact-section .bg-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color2);
  opacity: 0.7;
  content: "";
}
@media (max-width: 991.98px) {
  .fun-fact-section .bg-image:before {
    opacity: 0.95;
  }
}
.fun-fact-section .bg-lines {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/lines.png");
  background-repeat: repeat-x;
  background-size: auto;
  background-position: center bottom;
}

.counter-block {
  position: relative;
  margin-bottom: 50px;
}
.counter-block .inner {
  position: relative;
  text-align: center;
}
.counter-block .inner:hover .icon-box i {
  -webkit-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}
.counter-block .inner:hover .counter-title {
  opacity: 1;
}
.counter-block .icon-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 140px;
  width: 130px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape2.png");
  font-size: 52px;
  color: var(--theme-color1);
  margin: 0 auto 15px;
}
.counter-block .icon-box i {
  position: relative;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.counter-block .count-box {
  position: relative;
  display: inline-block;
  font-size: 36px;
  font-weight: 800;
  line-height: 1em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
}
.counter-block .count-box .count-text {
  font-size: 36px;
  font-weight: 800;
  line-height: 1em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.counter-block .counter-title {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: var(--theme-color-light);
  font-weight: 500;
  margin-top: 7px;
  margin-bottom: 0;
  opacity: 0.5;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*** 

====================================================================
    Fun Fact Section Two
====================================================================

***/
.fun-fact-section-two {
  position: relative;
}
.fun-fact-section-two .upper-box {
  position: relative;
  padding: 100px 0 100px;
}
@media (max-width: 991.98px) {
  .fun-fact-section-two .upper-box {
    padding-bottom: 50px;
  }
}
.fun-fact-section-two .upper-box .bg-image::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color2);
  opacity: 0.9;
  content: "";
}
@media (max-width: 1399.98px) {
  .fun-fact-section-two .upper-box .sec-title h2 br {
    display: none;
  }
}
.fun-fact-section-two .upper-box .title-column {
  margin-bottom: 60px;
}
.fun-fact-section-two .upper-box .content-column {
  margin-bottom: 40px;
}
.fun-fact-section-two .fact-counter {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
  z-index: 9;
}
@media (max-width: 991.98px) {
  .fun-fact-section-two .fact-counter {
    padding-top: 50px;
  }
}
.fun-fact-section-two .fact-counter .counter-block-two {
  position: relative;
  margin-top: -60px;
}
@media (max-width: 991.98px) {
  .fun-fact-section-two .fact-counter .counter-block-two {
    margin-top: 0;
  }
}

.about-us-tabs {
  position: relative;
}
.about-us-tabs .tab-btns {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 40px;
}
.about-us-tabs .tab-btns .tab-btn {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-weight: 700;
  padding: 10px 25px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .about-us-tabs .tab-btns .tab-btn {
    width: 100%;
    margin-right: 0;
  }
}
.about-us-tabs .tab-btns .tab-btn.active-btn {
  background-color: #fff;
  color: var(--theme-color2);
}
.about-us-tabs .content-box {
  position: relative;
  padding-left: 245px;
}
@media (max-width: 767.98px) {
  .about-us-tabs .content-box {
    padding-left: 0;
  }
}
.about-us-tabs .content-box .image-box {
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .about-us-tabs .content-box .image-box {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }
}
.about-us-tabs .content-box .text {
  position: relative;
  top: -8px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 17px;
}
.about-us-tabs .content-box .list-style-two li {
  padding-left: 35px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}

.counter-block-two {
  position: relative;
  margin-bottom: 50px;
}
.counter-block-two .inner {
  position: relative;
  text-align: center;
}
.counter-block-two .inner:hover .icon-box i {
  -webkit-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}
.counter-block-two .icon-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 122px;
  height: 135px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape7.png");
  font-size: 52px;
  color: var(--theme-color1);
  margin: 0 auto 15px;
}
.counter-block-two .icon-box i {
  position: relative;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.counter-block-two .count-box {
  position: relative;
  display: inline-block;
  font-size: 36px;
  font-weight: 800;
  line-height: 1em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: var(--theme-color2);
}
.counter-block-two .count-box .count-text {
  font-size: 36px;
  font-weight: 800;
  line-height: 1em;
}
.counter-block-two .counter-title {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: var(--theme-color2);
  font-weight: 500;
  margin-top: 7px;
  margin-bottom: 0;
  opacity: 0.5;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/
.testimonial-section {
  position: relative;
  padding: 190px 0 120px;
  background-color: #f4f4f4;
}
.testimonial-section .bg-lines {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/lines2.png");
  background-size: auto;
  background-position: center top;
  background-repeat: repeat-x;
}
.testimonial-section .testimonial-column .inner-column {
  margin: -15px -15px -15px;
  margin-right: -315px;
}
@media (max-width: 1199.98px) {
  .testimonial-section .testimonial-column .inner-column {
    margin-right: -15px;
  }
}
.testimonial-section .testimonial-column .owl-nav {
  position: absolute;
  left: -485px;
  bottom: 85px;
}
@media (max-width: 1199.98px) {
  .testimonial-section .testimonial-column .owl-nav {
    display: none;
  }
}

.testimonial-block {
  position: relative;
  overflow: hidden;
  padding: 15px 15px;
}
.testimonial-block .inner-box {
  position: relative;
}
.testimonial-block .inner-box:hover .content-box .icon {
  color: var(--theme-color2);
  background-color: var(--bg-theme-color2);
}
.testimonial-block .inner-box:hover .content-box .icon:after {
  border-bottom-color: #e7a72c;
}
.testimonial-block .inner-box:hover .content-box .info-box {
  background-color: var(--bg-theme-color1);
}
.testimonial-block .inner-box:hover .content-box .info-box .designation {
  color: #fff;
}
.testimonial-block .content-box {
  position: relative;
  background-color: #fff;
  padding: 45px 45px 75px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.04);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.04);
}
@media (max-width: 575.98px) {
  .testimonial-block .content-box {
    padding: 40px 25px 75px;
  }
}
.testimonial-block .content-box:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  background: -webkit-gradient(linear, right top, left top, from(transparent), to(var(--theme-color1)));
  background: linear-gradient(to left, transparent, var(--theme-color1));
  height: 8px;
  content: "";
}
.testimonial-block .content-box:after {
  position: absolute;
  right: 60px;
  top: 100%;
  border-right: 65px solid transparent;
  border-top: 44px solid #fff;
  content: "";
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.04));
          filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.04));
}
@media (max-width: 767.98px) {
  .testimonial-block .content-box:after {
    display: none;
  }
}
.testimonial-block .content-box .text {
  position: relative;
  font-size: 18px;
  line-height: 34px;
}
@media (max-width: 575.98px) {
  .testimonial-block .content-box .text {
    line-height: 30px;
  }
}
.testimonial-block .info-box {
  position: relative;
  margin-top: -50px;
  margin-left: 50px;
  min-height: 114px;
  padding-left: 125px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (max-width: 575.98px) {
  .testimonial-block .info-box {
    margin-left: 0px;
  }
}
.testimonial-block .info-box .thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 102px;
  height: 114px;
  padding: 5px;
  overflow: hidden;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape3.png");
}
.testimonial-block .info-box .thumb img {
  position: relative;
  top: 2px;
}
.testimonial-block .info-box .name {
  letter-spacing: -0.02em;
  margin-bottom: 4px;
}
.testimonial-block .info-box .designation {
  display: block;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}

/*** 

====================================================================
	Testimonial Section Two
====================================================================

***/
.testimonial-section-two {
  position: relative;
  padding: 120px 0 120px;
}
.testimonial-section-two .sec-title {
  margin-bottom: 35px;
}
.testimonial-section-two .carousel-outer {
  margin: 0 -15px 0;
}
.testimonial-section-two .sponsors-outer {
  padding: 85px 0;
  border-bottom: 1px solid #e6e6e6;
}

.testimonial-block-two {
  position: relative;
  overflow: hidden;
  padding: 15px 15px;
}
.testimonial-block-two .inner-box {
  position: relative;
}
.testimonial-block-two .inner-box:hover .info-box .thumb::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.testimonial-block-two .content-box {
  position: relative;
  background-color: #fff;
  padding: 30px 45px 35px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.testimonial-block-two .content-box:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 7px;
  background-color: var(--bg-theme-color1);
  content: "";
}
.testimonial-block-two .content-box .text {
  position: relative;
  font-size: 18px;
  line-height: 34px;
}
.testimonial-block-two .info-box {
  position: relative;
  margin-left: 50px;
  min-height: 114px;
  padding-left: 135px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 575.98px) {
  .testimonial-block-two .info-box {
    margin-left: 0;
  }
}
.testimonial-block-two .info-box .rating {
  position: relative;
  font-size: 12px;
  color: var(--theme-color1);
}
.testimonial-block-two .info-box .thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 102px;
  height: 114px;
  padding: 5px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape3.png");
}
.testimonial-block-two .info-box .thumb:before {
  position: absolute;
  left: 0;
  top: -10px;
  bottom: 40px;
  width: 100%;
  background-color: var(--bg-theme-color1);
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.testimonial-block-two .info-box .thumb img {
  position: relative;
  top: 2px;
}
.testimonial-block-two .info-box .name {
  letter-spacing: -0.02em;
  margin-bottom: 4px;
}
.testimonial-block-two .info-box .designation {
  display: block;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}

/*** 

====================================================================
	Testimonial Section Three
====================================================================

***/
.testimonial-section-three {
  position: relative;
  padding: 120px 0 120px;
}
.testimonial-section-three .sec-title {
  margin-bottom: 35px;
}
.testimonial-section-three .carousel-outer {
  max-width: 975px;
  margin: 0 auto;
  padding-bottom: 110px;
}
.testimonial-section-three .carousel-outer .owl-nav {
  display: none;
}
@media (max-width: 575.98px) {
  .testimonial-section-three .testimonial-block {
    padding: 0;
  }
}
.testimonial-section-three .testimonial-block .content-box {
  padding-top: 35px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.testimonial-section-three .sponsors-outer {
  border-top: 1px solid #e6e6e6;
  padding: 85px 0;
}

/***

====================================================================
    team Section
====================================================================

***/
.team-section {
  position: relative;
  padding: 120px 0 80px;
  overflow: hidden;
}
.team-section .sec-title {
  margin-bottom: 30px;
}

.team-block {
  position: relative;
  margin-bottom: 40px;
}
.team-block .inner-box {
  position: relative;
}
.team-block .inner-box:after {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  pointer-events: none;
}
.team-block .inner-box:hover:after {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.team-block .inner-box:hover .social-links {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.team-block .image-box {
  position: relative;
  padding: 2px 2px 0;
}
.team-block .image-box .image {
  padding: 18px 18px 0;
  background: -webkit-gradient(linear, left bottom, left top, from(transparent), color-stop(#fff), to(#fff));
  background: linear-gradient(to top, transparent, #fff, #fff);
  border-radius: 500px 500px 0 0;
  margin-bottom: 0;
}
.team-block .image-box .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), color-stop(#ffffff), to(var(--theme-color1)));
  background: linear-gradient(to top, #ffffff, #ffffff, var(--theme-color1));
  border-radius: 500px 500px 0 0;
  z-index: -1;
  content: "";
}
.team-block .image-box .image img {
  position: relative;
  width: 100%;
  border-radius: 500px 500px 0 0;
  z-index: 1;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block .image-box .share-icon {
  position: absolute;
  top: 38px;
  right: 40px;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: var(--theme-color1);
  border-radius: 50%;
  z-index: 2;
  cursor: default;
  color: #fff;
}
.team-block .image-box .social-links {
  position: absolute;
  right: 50px;
  top: 112px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #ffffff;
  padding: 10px 0;
  z-index: 3;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: top;
          transform-origin: top;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block .image-box .social-links:before {
  position: absolute;
  content: "";
  left: 50%;
  top: -5px;
  border-bottom: 5px solid #fff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: -7px;
}
.team-block .image-box .social-links a {
  position: relative;
  height: 35px;
  width: 40px;
  display: block;
  font-size: 14px;
  line-height: 35px;
  text-align: center;
  color: var(--theme-color2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.team-block .image-box .social-links a:hover {
  color: var(--theme-color1);
}
.team-block .info-box {
  position: relative;
  padding: 15px 20px 20px;
  background-color: #ffffff;
  text-align: center;
}
.team-block .info-box:before {
  position: absolute;
  left: 0;
  bottom: 0;
  top: -125px;
  bottom: 0;
  width: 100%;
  content: "";
  background: -webkit-gradient(linear, left bottom, left top, from(#f4f4f4), to(transparent));
  background: linear-gradient(to top, #f4f4f4, transparent);
}
.team-block .info-box .name {
  font-size: 22px;
  letter-spacing: -0.05em;
  margin-bottom: 0;
}
.team-block .info-box .name:hover {
  color: var(--theme-color1);
}
.team-block .info-box .designation {
  position: relative;
  display: block;
  font-size: 12px;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/*** 

====================================================================
		Work Section
====================================================================

***/
.work-section {
  position: relative;
  padding: 120px 0;
}
.work-section .bottom-text {
  position: relative;
  padding: 10px 20px;
  background-color: var(--theme-color2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 700px;
  margin: 0px auto 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767.98px) {
  .work-section .bottom-text {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px 20px;
    text-align: center;
    margin-top: 20px;
  }
}
.work-section .bottom-text .text {
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-color-light);
}
.work-section .bottom-text .theme-btn {
  font-size: 12px;
  line-height: 20px;
  color: var(--theme-color2);
  background-color: var(--theme-color-light);
  padding: 5px 25px;
  font-weight: 800;
}
@media (max-width: 767.98px) {
  .work-section .bottom-text .theme-btn {
    margin-top: 20px;
  }
}
.work-section .bottom-text .theme-btn:hover {
  background-color: var(--bg-theme-color1);
  color: var(--theme-color-light);
}

.work-block {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 70px;
}
.work-block:last-child .inner-box::before {
  display: none;
}
.work-block .inner-box {
  position: relative;
}
.work-block .inner-box:before {
  position: absolute;
  left: 100%;
  top: 110px;
  height: 8px;
  width: 140px;
  margin-left: -60px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/line4.png");
  content: "";
}
@media (max-width: 991.98px) {
  .work-block .inner-box:before {
    display: none;
  }
}
.work-block .inner-box:hover .icon-box .icon {
  color: var(--theme-color-light);
}
.work-block .inner-box:hover .icon-box .icon:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.work-block .icon-box {
  position: relative;
  width: 212px;
  height: 234px;
  margin: 0 auto 20px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/step-border.png");
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.work-block .icon-box:before {
  position: absolute;
  left: -10px;
  top: -10px;
  height: 115px;
  width: 115px;
  border-radius: 100px 100px;
  z-index: -1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  content: "";
}
.work-block .icon-box .icon {
  position: relative;
  top: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 194px;
  width: 175px;
  font-size: 68px;
  color: var(--theme-color1);
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/step.png");
  margin: 0 auto;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.work-block .icon-box .icon::before {
  position: relative;
  z-index: 3;
}
.work-block .icon-box .icon:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/step-hover.png");
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  content: "";
}
.work-block .icon-box .count {
  position: absolute;
  left: 18px;
  bottom: 10px;
  height: 53px;
  width: 53px;
  font-size: 14px;
  color: #fff;
  background-color: var(--theme-color2);
  border-radius: 50%;
  font-weight: 700;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.work-block .title {
  position: relative;
  color: var(--theme-color2);
  font-weight: 800;
  margin-bottom: 8px;
}
.work-block .text {
  max-width: 230px;
  margin: 0 auto;
}

/*** 

====================================================================
    Contact Section
====================================================================

***/
.contact-section {
  position: relative;
}
.contact-section .bg-outer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 50%;
}
@media (max-width: 991.98px) {
  .contact-section .bg-outer {
    max-width: 100%;
  }
}
.contact-section .bg-outer .bg {
  width: auto;
  right: -186px;
}
@media (max-width: 991.98px) {
  .contact-section .bg-outer .bg {
    right: 0;
  }
}
.contact-section .contact-column {
  position: relative;
}
.contact-section .contact-column .inner-column {
  position: relative;
  padding: 100px 0;
  padding-right: 200px;
}
@media (max-width: 1199.98px) {
  .contact-section .contact-column .inner-column {
    padding-right: 150px;
  }
}
@media (max-width: 991.98px) {
  .contact-section .contact-column .inner-column {
    padding-right: 0;
  }
}
.contact-section .contact-column .contact-info {
  position: absolute;
  right: 13px;
  top: 100px;
  bottom: 0;
  width: 100px;
  background-color: var(--theme-color1);
  text-align: center;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  text-orientation: mixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--theme-color-light);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
@media (max-width: 1399.98px) {
  .contact-section .contact-column .contact-info {
    top: 0;
  }
}
@media (max-width: 991.98px) {
  .contact-section .contact-column .contact-info {
    position: relative;
    right: 0;
    top: 0;
    -webkit-writing-mode: horizontal-tb;
        -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
    width: 100%;
    -webkit-transform: none;
            transform: none;
    padding: 20px 20px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 20px;
    background-color: var(--theme-color2);
  }
}
.contact-section .contact-column .contact-info .info {
  font-size: 20px;
  font-weight: 800;
  color: var(--theme-color-light);
  margin-top: 20px;
}
@media (max-width: 991.98px) {
  .contact-section .contact-column .contact-info .info {
    margin-top: 0;
  }
}
.contact-section .map-column .inner-column {
  position: relative;
  height: 100%;
  margin-left: -24px;
  margin-right: -375px;
}
.contact-section .map-column .map {
  position: absolute;
  left: 0;
  height: calc(100% - 100px);
  bottom: 0;
  width: 100%;
  display: block;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
@media (max-width: 1399.98px) {
  .contact-section .map-column .map {
    height: 100%;
  }
}
@media (max-width: 991.98px) {
  .contact-section .map-column .map {
    position: relative;
    min-height: 400px;
  }
}

.contact-form {
  position: relative;
}
.contact-form .row {
  margin: 0 -10px;
}
.contact-form .row > div {
  padding: 0 10px;
}
.contact-form .form-group {
  position: relative;
  margin-bottom: 20px;
}
.contact-form .form-group:last-child {
  margin-bottom: 0;
}
.contact-form .form-group label {
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  font-weight: 500;
  display: block;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.contact-form .form-group label.error {
  display: block;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  line-height: 24px;
  color: #ff0000;
  margin-bottom: 0;
}
.contact-form .form-group .select2-container--default .select2-selection--single,
.contact-form .form-group input:not([type=submit]),
.contact-form .form-group textarea,
.contact-form .form-group select {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  padding: 15px 30px;
  font-size: 14px;
  color: #717070;
  line-height: 28px;
  font-weight: 400;
  background-color: #f6f6f6;
  border: 1px solid transparent;
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.contact-form .form-group ::-webkit-input-placeholder {
  color: #717070;
}
.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
  border-color: var(--theme-color2);
}
.contact-form .form-group textarea {
  height: 150px;
  resize: none;
}
@media (max-width: 991.98px) {
  .contact-form .form-group input[type=submit],
.contact-form .form-group button {
    width: 100%;
  }
}

/*** 

====================================================================
    Contact Section Two
====================================================================

***/
.contact-section-two {
  position: relative;
  padding: 120px 0 0;
}
.contact-section-two .bg:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color2);
  opacity: 0.9;
  content: "";
}
.contact-section-two.pull-up {
  padding-top: 90px;
}
.contact-section-two.pull-up .bg {
  bottom: 0;
  top: -130px;
  height: auto;
}
.contact-section-two .content-column {
  position: relative;
}
.contact-section-two .content-column .inner-column {
  position: relative;
}
@media (max-width: 1199.98px) {
  .contact-section-two .content-column .inner-column {
    margin-right: -80px;
  }
}
@media (max-width: 991.98px) {
  .contact-section-two .content-column .inner-column {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.contact-section-two .content-column .sec-title {
  margin-bottom: 35px;
}
.contact-section-two .content-column .sec-title .text {
  color: #848484;
}
.contact-section-two .content-column .info-block {
  position: relative;
  margin-bottom: 30px;
}
.contact-section-two .content-column .info-block .inner {
  position: relative;
  padding-left: 120px;
  padding-top: 15px;
}
@media (max-width: 575.98px) {
  .contact-section-two .content-column .info-block .inner {
    padding-left: 100px;
    padding-top: 10px;
  }
}
.contact-section-two .content-column .info-block .inner:hover .icon {
  background-color: var(--theme-color-light);
  color: var(--theme-color1);
}
.contact-section-two .content-column .info-block .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 96px;
  width: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--bg-theme-color1);
  color: var(--theme-color-light);
  font-size: 42px;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media (max-width: 575.98px) {
  .contact-section-two .content-column .info-block .icon {
    height: 80px;
    width: 80px;
  }
}
.contact-section-two .content-column .info-block .title {
  color: #ffffff;
  letter-spacing: -0.04em;
}
.contact-section-two .form-column {
  position: relative;
  z-index: 3;
}
.contact-section-two .form-column .inner-column {
  position: relative;
  padding-left: 70px;
  margin-bottom: -50px;
}
@media (max-width: 991.98px) {
  .contact-section-two .form-column .inner-column {
    padding-left: 0;
  }
}
.contact-section-two .form-column .contact-form {
  padding: 60px 60px 50px;
  background-color: var(--bg-theme-color1);
}
@media (max-width: 767.98px) {
  .contact-section-two .form-column .contact-form {
    padding: 30px 20px 10px;
  }
}
.contact-section-two .form-column .contact-form .form-group {
  margin-bottom: 10px;
}

/***

====================================================================
    Why Choose Us
====================================================================

***/
.why-choose-us {
  position: relative;
  padding: 120px 0 70px;
}
.why-choose-us .content-column {
  margin-bottom: 50px;
}
.why-choose-us .content-column .inner-column {
  position: relative;
  padding-right: 85px;
}
@media (max-width: 1199.98px) {
  .why-choose-us .content-column .inner-column {
    padding-right: 0;
  }
}
.why-choose-us .content-column .sec-title {
  margin-bottom: 30px;
}
.why-choose-us .features-column {
  position: relative;
  margin-bottom: 20px;
}

.feature-block-two {
  position: relative;
  margin-bottom: 30px;
}
.feature-block-two .inner-box {
  position: relative;
  padding: 30px 30px;
  background-color: var(--theme-color-light);
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.feature-block-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape4.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  content: "";
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.feature-block-two .inner-box:hover {
  background-color: var(--theme-color2);
}
.feature-block-two .inner-box:hover::before {
  opacity: 0.1;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}
.feature-block-two .inner-box:hover .title {
  color: var(--theme-color-light);
}
.feature-block-two .icon {
  display: block;
  font-size: 62px;
  color: var(--theme-color1);
  margin-bottom: 15px;
}
.feature-block-two .title {
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/***

====================================================================
    Why Choose Us Two
====================================================================

***/
.why-choose-us-two {
  position: relative;
  padding: 120px 0 70px;
}
.why-choose-us-two .image-column {
  margin-bottom: 50px;
}
.why-choose-us-two .image-column .inner-column {
  position: relative;
}
.why-choose-us-two .image-column .sec-title {
  margin-bottom: 45px;
}
.why-choose-us-two .image-column .image-box {
  position: relative;
  margin-left: -90px;
}
@media (max-width: 1199.98px) {
  .why-choose-us-two .image-column .image-box {
    margin-left: 0;
  }
  .why-choose-us-two .image-column .image-box img {
    width: 100%;
  }
}
.why-choose-us-two .image-column .image-box .image-1 {
  position: relative;
  padding-left: 15px;
  margin-bottom: 0;
}
.why-choose-us-two .image-column .image-box .image-2 {
  position: absolute;
  bottom: -22px;
  left: 0;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .why-choose-us-two .image-column .image-box .image-2 {
    bottom: 0;
  }
}
.why-choose-us-two .features-column {
  position: relative;
}
.why-choose-us-two .features-column .inner-column {
  padding-left: 70px;
}
@media (max-width: 991.98px) {
  .why-choose-us-two .features-column .inner-column {
    padding-left: 0;
  }
}

.feature-block-three {
  position: relative;
  margin-bottom: 30px;
}
.feature-block-three:nth-child(even) {
  left: -60px;
}
@media (max-width: 991.98px) {
  .feature-block-three:nth-child(even) {
    left: 0;
  }
}
.feature-block-three .inner-box {
  position: relative;
  padding: 30px 30px 25px;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
  background-color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.feature-block-three .inner-box:hover::before {
  height: 100%;
  top: 0;
}
.feature-block-three .inner-box:before {
  position: absolute;
  right: 0;
  top: 50%;
  height: 0;
  width: 4px;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.feature-block-three .title-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 70px;
  min-height: 50px;
  margin-bottom: 10px;
}
.feature-block-three .title {
  margin-bottom: 0;
}
.feature-block-three .icon {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  background-color: var(--bg-theme-color1);
  color: var(--theme-color-light);
  font-size: 20px;
  border-radius: 50%;
}

/***

====================================================================
    Why Choose Us Three
====================================================================

***/
.why-choose-us-three {
  position: relative;
  padding: 120px 0 0;
  background-color: var(--bg-theme-color1);
}
.why-choose-us-three .content-column {
  margin-bottom: 50px;
}
.why-choose-us-three .content-column .inner-column {
  position: relative;
  padding-right: 50px;
  padding-bottom: 70px;
}
@media (max-width: 1199.98px) {
  .why-choose-us-three .content-column .inner-column {
    padding-right: 0;
  }
}
.why-choose-us-three .content-column .inner-column .sec-title {
  margin-bottom: 20px;
}
.why-choose-us-three .content-column .inner-column .sec-title .sub-title {
  color: var(--theme-color-light);
}
.why-choose-us-three .content-column .inner-column .sec-title .sub-title::before, .why-choose-us-three .content-column .inner-column .sec-title .sub-title::after {
  background-color: var(--theme-color2);
}
@media (max-width: 1199.98px) {
  .why-choose-us-three .content-column .inner-column .sec-title br {
    display: none;
  }
}
.why-choose-us-three .content-column .inner-column .list-style-two {
  margin-bottom: 35px;
}
.why-choose-us-three .content-column .inner-column .list-style-two li {
  padding-left: 30px;
  font-size: 18px;
  line-height: 24px;
  color: var(--theme-color-light);
  margin-bottom: 10px;
}
.why-choose-us-three .content-column .inner-column .list-style-two li i {
  font-size: 16px;
  color: var(--theme-color2);
}
.why-choose-us-three .image-column {
  position: relative;
  margin-bottom: 0;
}
.why-choose-us-three .image-column .inner-column {
  position: relative;
}
.why-choose-us-three .image-column .image-box {
  position: relative;
  margin-left: 20px;
  margin-right: -370px;
}
@media (max-width: 991.98px) {
  .why-choose-us-three .image-column .image-box {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.why-choose-us-three .image-column .image-box .image {
  margin-bottom: 0;
}
.why-choose-us-three .image-column .image-box .image img {
  width: 100%;
  min-height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
}
.why-choose-us-three .image-column .video-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 298px;
  height: 285px;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape9.png");
  padding: 50px 50px;
}
.why-choose-us-three .image-column .video-box .play-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 63px;
  width: 63px;
  background-color: var(--bg-theme-color1);
  color: var(--theme-color-light);
  font-size: 18px;
  border-radius: 50%;
  margin-bottom: 15px;
}
.why-choose-us-three .image-column .video-box .title {
  font-size: 26px;
  color: var(--theme-color-light);
}

/*** 

====================================================================
    News Section
====================================================================

***/
.news-section {
  position: relative;
  padding: 120px 0 90px;
  z-index: 3;
}
.news-section .bg.pull-up {
  height: auto;
  top: -215px;
  bottom: 0;
}

.news-block {
  position: relative;
  margin-bottom: 30px;
}
.news-block .inner-box {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.news-block .inner-box:hover .image-box .image a:after {
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.news-block .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.news-block .image-box {
  position: relative;
}
.news-block .image-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.news-block .image-box .image img {
  display: block;
  width: 100%;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.news-block .image-box .image a:after {
  background: rgba(255, 255, 255, 0.3);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 51%;
  top: 0;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.news-block .image-box .date {
  position: absolute;
  top: 20px;
  left: 20px;
  background: var(--theme-color1);
  color: #fff;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
}
.news-block .image-box .date:before {
  position: absolute;
  bottom: -10px;
  left: 0;
  border-top: 10px solid var(--theme-color1);
  border-right: 10px solid transparent;
  content: "";
}
.news-block .image-box .post-quote {
  position: relative;
  padding: 40px 50px 30px;
  min-height: 300px;
  background-color: var(--bg-theme-color1);
  margin-bottom: 20px;
}
@media (max-width: 1199.98px) {
  .news-block .image-box .post-quote {
    min-height: auto;
    padding: 40px 30px 60px;
  }
}
.news-block .image-box .post-quote .text {
  font-size: 24px;
  line-height: 36px;
  color: var(--theme-color-light);
  letter-spacing: -0.01em;
  font-weight: 700;
}
@media (max-width: 1199.98px) {
  .news-block .image-box .post-quote .text {
    font-size: 20px;
    line-height: 30px;
  }
}
.news-block .image-box .post-quote .icon {
  position: absolute;
  right: 30px;
  bottom: 30px;
  font-size: 62px;
  color: #fff;
}
@media (max-width: 767.98px) {
  .news-block .image-box .post-quote .icon {
    right: 20px;
    bottom: 20px;
    font-size: 42px;
  }
}
.news-block .content-box {
  position: relative;
  padding: 20px 35px 30px;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
@media (max-width: 575.98px) {
  .news-block .content-box {
    padding: 20px 25px 25px;
  }
}
.news-block .content-box .author-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}
.news-block .content-box .author-info .author-thumb {
  position: relative;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 2px solid var(--theme-color1);
  overflow: hidden;
  margin-bottom: 0;
  margin-right: 10px;
}
.news-block .content-box .author-info .author-name {
  font-size: 14px;
}
.news-block .content-box .title {
  margin-bottom: 17px;
}
.news-block .content-box .title:hover {
  color: var(--theme-color1);
}
.news-block .content-box h4.title {
  font-size: 22px;
  margin-top: 20px;
}
.news-block .content-box .text {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}
.news-block .content-box .post-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 7px 20px;
  background-color: #f6f6f6;
}
.news-block .content-box .post-info li {
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #777;
}
.news-block .content-box .post-info li i {
  margin-right: 5px;
}
.news-block .content-box .post-info li a {
  color: #777;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.news-block .content-box .post-info li a:hover {
  color: var(--theme-color1);
}

/*** 

====================================================================
    News Section Two
====================================================================

***/
.news-section-two {
  position: relative;
  padding: 120px 0 80px;
}
.news-section-two .bg-lines {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/lines4.png");
  background-repeat: repeat-x;
  background-position: center top;
  background-size: auto;
}
.news-section-two .call-to-action {
  margin-top: 90px;
}

.news-block-two {
  position: relative;
  margin-bottom: 30px;
}
.news-block-two .inner-box {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.news-block-two .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.news-block-two .image-box {
  position: relative;
}
.news-block-two .image-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.news-block-two .image-box .image img {
  display: block;
  width: 100%;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.news-block-two .image-box .image a:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  pointer-events: none;
  background: -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent));
  background: linear-gradient(to top, #000, transparent);
  opacity: 0.9;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.news-block-two .image-box .date {
  position: absolute;
  top: 20px;
  left: 20px;
  background: var(--theme-color1);
  color: #fff;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
}
.news-block-two .image-box .date:before {
  position: absolute;
  bottom: -10px;
  left: 0;
  border-top: 10px solid var(--theme-color1);
  border-right: 10px solid transparent;
  content: "";
}
.news-block-two .content-box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 35px 32px;
  border-radius: 0 0 10px 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.news-block-two .info-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2px;
}
.news-block-two .info-box .post-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.news-block-two .info-box .post-info li {
  position: relative;
  font-size: 12px;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
  margin-right: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.news-block-two .info-box .post-info li:before {
  position: absolute;
  right: -2px;
  top: 0;
  line-height: 30px;
  content: "";
}
.news-block-two .info-box .post-info li i {
  font-size: 12px;
  color: var(--theme-color1);
  margin-right: 5px;
}
.news-block-two .info-box .post-info li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.news-block-two .title {
  line-height: 32px;
  color: #fff;
  margin-bottom: 0px;
}
.news-block-two .title:hover {
  color: var(--theme-color1);
}

/*** 

====================================================================
    Map Section
====================================================================

***/
.map-section {
  position: relative;
  min-height: 470px;
}
.map-section .map {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: block;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

/*** 

====================================================================
    Clients Section
====================================================================

***/
.clients-section {
  position: relative;
  z-index: 1;
}
.clients-section.pull-up .sponsors-outer {
  margin-top: -30px;
}
.clients-section .sponsors-outer {
  padding: 80px 0px;
}

.client-block {
  position: relative;
  overflow: hidden;
  text-align: center;
}
.client-block a {
  position: relative;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
}
.client-block a:hover img {
  opacity: 1;
}
.client-block img {
  display: inline-block !important;
  opacity: 0.2;
  width: auto !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/***

====================================================================
    Products details
====================================================================

***/
.product-details .bxslider .image-box {
  position: relative;
  display: block;
  margin-right: 30px;
  margin-bottom: 10px;
}
.product-details .bxslider .image-box img {
  width: 100%;
}
.product-details .bxslider .thumb-box li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
  margin-bottom: 15px;
  width: 100px;
  height: 100px;
}
.product-details .bxslider .thumb-box li:last-child {
  margin: 0px !important;
}
.product-details .bxslider .thumb-box li a {
  position: relative;
  display: inline-block;
}
.product-details .bxslider .thumb-box li a:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.product-details .bxslider .thumb-box li a.active:before {
  opacity: 1;
}
.product-details .bx-wrapper {
  margin-bottom: 30px;
}
.product-details .product-info .product-details__top {
  position: relative;
  display: block;
  margin-top: -8px;
}
.product-details .product-info .product-details__title {
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  margin: 0;
}
.product-details .product-info .product-details__title span {
  position: relative;
  display: inline-block;
  color: var(--theme-color1);
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-left: 20px;
  letter-spacing: 0;
}
.product-details .product-info .product-details__reveiw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 22px;
  padding-bottom: 20px;
  margin-bottom: 31px;
  border-bottom: 1px solid #e0e4e8;
}
.product-details .product-info .product-details__reveiw i {
  font-size: 16px;
  color: var(--theme-color2);
}
.product-details .product-info .product-details__reveiw i + i {
  margin-left: 4px;
}
.product-details .product-info .product-details__reveiw span {
  position: relative;
  top: 1px;
  line-height: 1;
  font-size: 16px;
  color: var(--theme-color1);
  margin-left: 18px;
}
.product-details .product-info .product-details__quantity-title {
  margin: 0;
  color: #222;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-right: 20px;
}
.product-details .product-info .product-details__buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons {
    display: block;
  }
}
.product-details .product-info .product-details__buttons-1 {
  position: relative;
  display: block;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons-1 {
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.product-details .product-info .product-details__buttons-2 {
  position: relative;
  display: block;
  margin-left: 10px;
}
.product-details .product-info .product-details__buttons-2 .thm-btn {
  background-color: var(--theme-color2);
}
.product-details .product-info .product-details__buttons-2 .thm-btn:before {
  background-color: var(--theme-color1);
}
.product-details .product-info .product-details__buttons-2 .thm-btn:after {
  background-color: var(--theme-color1);
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons-2 {
    margin-left: 0;
    margin-top: 0;
  }
}
.product-details .product-info .product-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.product-details .product-info .product-details__social .title {
  position: relative;
  display: block;
}
.product-details .product-info .product-details__social .title h3 {
  color: #222;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}
.product-details .product-info .product-details__social .social-icon-one {
  margin-left: 30px;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__social {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
  .product-details .product-info .product-details__social .social-icon-one {
    margin-left: 0;
  }
}

.product-details__quantity {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}
.product-details__quantity .quantity-box {
  position: relative;
  width: 98px;
  border-radius: 10px;
  height: 60px;
}
.product-details__quantity .quantity-box input {
  width: 98px;
  border-radius: 10px;
  height: 60px;
  border: 1px solid #e0e4e8;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  padding-left: 30px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color1);
}
.product-details__quantity .quantity-box button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  color: var(--text-color-bg-theme-color1);
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: var(--theme-color1);
  border: none;
  border-left: 1px solid #e0e4e8;
  border-top-right-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  outline: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-details__quantity .quantity-box button:hover {
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}
.product-details__quantity .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
}

.product-discription {
  position: relative;
  display: block;
}
.product-discription .product-description__title {
  font-size: 30px;
  margin-bottom: 27px;
}
.product-discription .product-description__text1 {
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}
.product-discription .product-description__list {
  position: relative;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.product-discription .product-description__list ul {
  position: relative;
  display: block;
}
.product-discription .product-description__list ul li {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.product-discription .product-description__list ul li:last-child {
  margin-bottom: 0px;
}
.product-discription .product-description__list ul li p {
  margin: 0;
  font-weight: 600;
  color: var(--headings-color);
}
.product-discription .product-description__list ul li p span:before {
  position: relative;
  display: inline-block;
  color: var(--theme-color1);
  font-size: 17px;
  line-height: 17px;
  margin-right: 11px;
  top: 2px;
}
.product-discription .tabs-content .text p {
  margin-bottom: 17px;
}
.product-discription .tabs-content .text p:last-child {
  margin-bottom: 0px;
}
.product-discription .tab-btn-box {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 60px;
}
.product-discription .tab-btn-box:before {
  position: absolute;
  content: "";
  background-color: #e1e8e4;
  width: 100%;
  height: 1px;
  left: 0px;
  top: 28px;
}
.product-discription .tab-btn-box .tab-btns li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  color: #1e2434;
  text-align: center;
  padding: 14px 30px;
  background-color: #fff;
  border: 1px solid #e1e8e4;
  cursor: pointer;
  margin: 0px 8.5px;
  margin-bottom: 15px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.product-discription .tab-btn-box .tab-btns li.active-btn {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}
.product-discription .tab-btn-box .tab-btns li:last-child {
  margin-bottom: 0;
}
.product-discription .single-comment-box .inner-box {
  position: relative;
  display: block;
  background-color: #f4f5f4;
  padding: 34px 30px 34px 125px;
}
.product-discription .single-comment-box .inner-box .comment-thumb {
  position: absolute;
  left: 30px;
  top: 40px;
  border-radius: 50%;
  width: 80px;
}
.product-discription .single-comment-box .inner-box .comment-thumb img {
  width: 100%;
  border-radius: 50%;
}
.product-discription .single-comment-box .inner-box .rating {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.product-discription .single-comment-box .inner-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  float: left;
  margin-right: 4px;
  color: #fdc009;
}
.product-discription .single-comment-box .inner-box .rating li:last-child {
  margin: 0px !important;
}
.product-discription .single-comment-box .inner-box h5 {
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.product-discription .single-comment-box .inner-box h5 span {
  font-weight: 400;
  text-transform: capitalize;
}
.product-discription .customer-comment {
  position: relative;
  display: block;
  margin-bottom: 60px;
}
.product-discription .comment-box {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 51px 60px 60px 60px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.product-discription .comment-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 23px;
}
.product-discription .comment-box .form-group {
  position: relative;
  margin-bottom: 15px;
}
.product-discription .comment-box .form-group label {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #707582;
  margin-bottom: 8px;
}
.product-discription .comment-box .column:last-child .form-group {
  margin-bottom: 0px;
}
.product-discription .comment-box .review-box {
  position: relative;
  display: block;
  margin-top: 8px;
}
.product-discription .comment-box .review-box p {
  position: relative;
  float: left;
  margin-right: 10px;
}
.product-discription .comment-box .review-box .rating {
  position: relative;
  float: left;
}
.product-discription .comment-box .review-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  float: left;
  margin-right: 4px;
  color: #fdc009;
}
.product-discription .comment-box .review-box .rating li:last-child {
  margin: 0px !important;
}
.product-discription .comment-box .custom-controls-stacked {
  position: relative;
  float: left;
}

.related-product h3 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .product-details__img {
    margin-bottom: 50px;
  }
  .product-details__title span {
    margin-left: 0;
    display: block;
  }
  .product-details__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .product-details__buttons-2 {
    margin-left: 0;
    margin-top: 10px;
  }
  .product-details__social-link {
    margin-left: 0;
    margin-top: 20px;
  }
}
/***

====================================================================
 Categories Section
====================================================================

***/
.categories-section {
  position: relative;
  padding: 100px 0 70px;
}
.categories-section .bg-pattern {
  position: absolute;
  left: 0;
  top: -220px;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-7.png");
  background-repeat: no-repeat;
  background-position: left top;
  z-index: -1;
}
.categories-section:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  background: var(--theme-color3);
  content: "";
  z-index: 1;
}
.categories-section:after {
  position: absolute;
  left: 0;
  bottom: -50px;
  height: 70px;
  width: 100%;
  z-index: 2;
  content: "";
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-3.png");
  background-repeat: no-repeat;
  background-position: center bottom;
}

.category-block {
  position: relative;
  margin-bottom: 30px;
  z-index: 9;
}
.category-block .inner-box {
  position: relative;
  text-align: center;
  background: #ffffff;
  padding: 40px 30px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block .inner-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 190px;
  background-repeat: no-repeat;
  background-position: center bottom;
  content: "";
}
.category-block .inner-box:hover {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.category-block .inner-box:hover .image img {
  -webkit-transform: rotate(10deg) scale(1.2);
  transform: rotate(10deg) scale(1.2);
}
.category-block .inner-box.cat-bg-1:before {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/cat-1-bg.png");
}
.category-block .inner-box.cat-bg-2:before {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/cat-2-bg.png");
}
.category-block .inner-box.cat-bg-3:before {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/cat-3-bg.png");
}
.category-block .inner-box.cat-bg-4:before {
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/cat-4-bg.png");
}
.category-block .image {
  position: relative;
  display: inline-block;
  height: 180px;
  width: 180px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}
.category-block .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block h4 {
  font-size: 20px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 10px;
}
.category-block h4 a {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block h4 a:hover {
  color: var(--theme-color1);
}
.category-block p {
  font-size: 14px;
  color: #797f7d;
  line-height: 26px;
  margin-bottom: 0px;
}

/***

====================================================================
    Categories Section Two
====================================================================

***/
.categories-section-two {
  position: relative;
  padding: 120px 0 90px;
}

.category-block-two {
  position: relative;
  padding-top: 70px;
  margin-bottom: 30px;
  z-index: 9;
}
.category-block-two .inner-box {
  position: relative;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 18px;
}
.category-block-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 60px;
  width: 100%;
  border-radius: 10px;
  background: #e3eee5;
  content: "";
}
.category-block-two .inner-box:hover .image:before {
  left: 100%;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.category-block-two .inner-box:hover .image img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.category-block-two .inner-box:hover h4 a {
  color: var(--theme-color1);
}
.category-block-two .content {
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 40px 40px;
  z-index: 1;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
}
.category-block-two .content:before {
  position: absolute;
  top: -88px;
  left: 0;
  width: 180px;
  height: 180px;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  background: #e3eee5;
  content: "";
}
.category-block-two .content:after {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  font-size: 30px;
  line-height: 1em;
  color: #e8f3ea;
  height: 15px;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  content: attr(data-text);
}
.category-block-two .image {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: -70px;
}
.category-block-two .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 120%;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgb(255, 255, 255)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=1 );
  content: "";
  opacity: 0.3;
  left: -100%;
  pointer-events: none;
  z-index: 1;
}
.category-block-two h4 {
  font-size: 22px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 15px;
}
.category-block-two h4 a {
  color: var(--theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two p {
  font-size: 16px;
  color: #797f7d;
  line-height: 26px;
  margin-bottom: 0px;
}
.category-block-two .link {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #e8f3ea;
  border-radius: 50%;
  line-height: 50px;
  margin-top: 25px;
  color: #608174;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two .link:hover {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.category-block-two.child-two .link {
  background: #f0e2e3;
}
.category-block-two.child-two .inner-box:before {
  background: #f0e2e3;
}
.category-block-two.child-two .inner-box:hover h4 a {
  color: #e69da2;
}
.category-block-two.child-two .content:before {
  background: #f0e2e3;
}
.category-block-two.child-two .content:after {
  color: #f0e2e3;
}
.category-block-two.child-three .link {
  background: #f1ede1;
}
.category-block-two.child-three .inner-box:before {
  background: #f1ede1;
}
.category-block-two.child-three .inner-box:hover h4 a {
  color: #c9b579;
}
.category-block-two.child-three .content:before {
  background: #f1ede1;
}
.category-block-two.child-three .content:after {
  color: #f1ede1;
}

/***

====================================================================
    Products Section
====================================================================

***/
.products-section {
  position: relative;
  padding: 120px 0;
}

.products-section .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 670px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.products-section .bg-image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #113629;
  opacity: 0.7;
  content: "";
}

.products-section .bg-image:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-image: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-8.png");
  background-position: center bottom;
  content: "";
}

.products-section .sec-title h2 {
  font-size: 60px;
}

.products-section .sec-title .theme-btn {
  margin-top: 30px;
}

.products-box {
  max-width: 1530px;
  position: relative;
  padding: 120px 60px 90px;
  margin: 120px auto 0;
  background-color: #f7f5ee;
  overflow: hidden;
  border-radius: 10px;
}

.products-box:before {
  position: absolute;
  left: -90px;
  bottom: 0;
  height: 70%;
  width: 100%;
  background: url("https://kodesolution.com/html/2023/disle-html/images/icons/shape-7.png") top left no-repeat;
  content: "";
}

.products-box .sec-title {
  margin-bottom: 30px;
}

.products-box .outer-box {
  position: relative;
  padding-right: 400px;
}

.products-box .outer-box .banner-box-two {
  position: absolute;
  right: 0;
  top: 0;
}

.banner-box-two {
  position: relative;
}

.banner-box-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(22, 67, 51)), to(rgba(229, 229, 229, 0)));
  background: linear-gradient(to bottom, rgb(22, 67, 51) 0%, rgba(229, 229, 229, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="var(--theme-color3)", endColorstr="#00e5e5e5",GradientType=0 );
  content: "";
}

.banner-box-two .inner-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 370px;
  background: var(--theme-color3);
  border-radius: 10px;
  min-height: 440px;
  text-align: center;
  overflow: hidden;
  padding: 20px 20px;
}

.banner-box-two .title {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.banner-box-two .title strong {
  font-size: 60px;
  line-height: 1em;
  color: var(--theme-color2);
  font-weight: 400;
}

.banner-box-two h4 {
  font-size: 30px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}

.product-block-two {
  position: relative;
  margin-bottom: 30px;
}

.product-block-two .inner-box {
  position: relative;
  border: 2px solid transparent;
  border-radius: 10px;
  background: #ffffff;
  padding: 20px 20px;
  padding-left: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 150px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .inner-box:hover {
  border: 2px solid var(--theme-color1);
}

.product-block-two .image {
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 50%;
  overflow: hidden;
  height: 110px;
  width: 110px;
  border: 1px solid #e4e1d6;
  margin-bottom: 0px;
}

.product-block-two .image img {
  width: auto;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .inner-box:hover .image img {
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}

.product-block-two h4 {
  display: block;
  font-size: 22px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 5px;
}

.product-block-two h4 a {
  color: var(--theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block-two .price {
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: var(--theme-color1);
  font-weight: 600;
}

.product-block-two .price del {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #ff0000;
  line-height: 27px;
  opacity: 0.3;
}

.product-block-two .rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #ffc737;
}

.products-carousel .owl-nav {
  display: none;
}

.products-carousel .owl-dots {
  position: absolute;
  right: 0;
  top: -80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 8;
}

.products-carousel .owl-dot {
  position: relative;
  height: 7px;
  width: 7px;
  display: block;
  background: #879d91;
  margin-right: 5px;
  border-radius: 5px;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.products-carousel .owl-dot.active {
  background: var(--theme-color3);
}

/***

====================================================================
    Featured Products
====================================================================

***/
.featured-products {
  position: relative;
  padding: 120px 0 90px;
}

.featured-products .bg-shape {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url("https://kodesolution.com/html/2023/disle-html/images/icons/pattern-7.png") left bottom no-repeat;
  background-size: 700px;
}

/*=== Mixitup Gallery ===*/
.featured-products .filters {
  margin-bottom: 40px;
  text-align: center;
}

.featured-products .filters .filter-tabs {
  position: relative;
  display: inline-block;
}

.featured-products .filters li {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 0px 2px 10px;
  cursor: pointer;
  color: #797f7d;
  font-weight: 500;
  font-size: 18px;
  margin: 0 12px 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.featured-products .filters li:last-child {
  margin-right: 0;
}

.featured-products .filters .filter.active,
.featured-products .filters .filter:hover {
  color: var(--theme-color1);
}

.featured-products .filters li:before {
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 2px;
  width: 100%;
  content: "";
  background-color: var(--theme-color2);
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1); /* easeInOutQuint */
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); /* easeInOutQuint */
}

.featured-products .filters li.active:before,
.featured-products .filters li:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.product-block {
  position: relative;
  margin-bottom: 30px;
}

.product-block.mix {
  display: none;
}

.product-block .inner-box {
  position: relative;
  border: 2px solid #e4e1d5;
  border-radius: 10px;
  text-align: center;
  background: #ffffff;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover {
  border: 2px solid var(--theme-color1);
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.product-block .image {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0px;
}

.product-block .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgb(255, 255, 255)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=1 );
  content: "";
  opacity: 0.3;
  left: -100%;
  pointer-events: none;
  z-index: 1;
}

.product-block .inner-box:hover .image:before {
  left: 100%;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.product-block .image img {
  width: auto;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover .image img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.product-block .content {
  position: relative;
  padding: 30px 30px 30px;
}

.product-block h4 {
  display: block;
  font-size: 22px;
  color: var(--theme-color2);
  font-weight: 700;
  margin-bottom: 5px;
}

.product-block h4 a {
  color: var(--theme-color2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block h4 a:hover {
  color: var(--theme-color1);
}

.product-block .price {
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: var(--text-gray-silver);
  font-weight: 600;
}

.product-block .price del {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #ff0000;
  line-height: 27px;
  opacity: 0.3;
}

.product-block .rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #ffc737;
}

.product-block .tag {
  position: absolute;
  top: 30px;
  left: 20px;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  background: #FD5F5C;
  font-weight: 400;
  padding: 0 12px;
  border-radius: 3px;
  z-index: 9;
  font-style: italic;
  text-transform: uppercase;
}

.product-block .icon-box {
  position: absolute;
  right: 20px;
  top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover .icon-box {
  top: 20px;
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
}

.product-block .ui-btn {
  position: relative;
  display: block;
  height: 40px;
  width: 40px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  z-index: 9;
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  cursor: pointer;
  border-radius: 50px;
  margin-bottom: 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .ui-btn:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}

.product-block .cat {
  display: block;
  font-size: 18px;
  color: #707070;
  font-style: italic;
  font-family: "Jost", sans-serif;
  margin-bottom: 5px;
}

/*
 * shop-catalog-layouts.scss
 * -----------------------------------------------
*/
table.tbl-shopping-cart .product-thumbnail, table.cart-total .product-thumbnail {
  min-width: 64px;
}
table.tbl-shopping-cart img, table.cart-total img {
  width: 64px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
table.tbl-shopping-cart th,
table.tbl-shopping-cart td, table.cart-total th,
table.cart-total td {
  vertical-align: middle;
  border-left: 1px solid #e3e3e3;
  padding: 20px 30px;
}
table.tbl-shopping-cart .product-name a, table.cart-total .product-name a {
  color: var(--headings-color);
}
table.tbl-shopping-cart .product-name .variation, table.cart-total .product-name .variation {
  font-size: 0.9rem;
  list-style: none;
}
table.tbl-shopping-cart .product-remove a, table.cart-total .product-remove a {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  background-color: #757575;
  color: #ffffff;
  text-align: center;
}
table.tbl-shopping-cart .coupon-form .apply-button, table.cart-total .coupon-form .apply-button {
  position: relative;
  display: inline-block;
  color: #1e2434;
  background: #f4f5f4;
  padding: 15px 29px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
table.tbl-shopping-cart .coupon-form .apply-button:hover, table.cart-total .coupon-form .apply-button:hover {
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}

table.tbl-shopping-cart > thead > tr > th,
table.tbl-shopping-cart > tbody > tr > th,
table.tbl-shopping-cart > tfoot > tr > th {
  color: #444;
}

.payment-method .accordion-box .block {
  background: #f4f5f4;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 20px;
}
.payment-method .accordion-box .block .acc-content .payment-info {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 30px 30px 10px 30px;
}
.payment-method .accordion-box .block:last-child {
  margin-bottom: 0px;
}
.payment-method .accordion-box .block .acc-btn {
  padding: 19px 30px 22px 30px;
}
.payment-method .accordion-box .block .acc-btn .icon-outer {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 30px;
  height: auto;
  /* line-height: 65px; */
  font-size: 28px;
  color: #1e2434;
}
.payment-method .accordion-box .block .acc-btn.active .icon-outer {
  color: var(--text-color-bg-theme-color2);
}
.payment-method .payment-method h3 {
  margin-bottom: 32px;
}

.shop-sidebar {
  position: relative;
  display: block;
}
.shop-sidebar .sidebar-search {
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-search .search-form .form-group {
  position: relative;
  margin: 0px;
}
.shop-sidebar .sidebar-search .search-form .form-group input[type=search] {
  position: relative;
  width: 100%;
  height: 52px;
  background-color: var(--theme-light-background);
  border: 1px solid var(--theme-light-background);
  border-radius: 5px;
  color: #646578;
  padding: 10px 60px 10px 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .sidebar-search .search-form .form-group button {
  position: absolute;
  display: inline-block;
  top: 5px;
  right: 5px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 18px;
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .sidebar-search .search-form .form-group button:hover {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}
.shop-sidebar .sidebar-widget {
  position: relative;
  display: block;
  background-color: var(--theme-light-background);
  padding: 35px 30px 37px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-widget:last-child {
  margin-bottom: 0px;
}
.shop-sidebar .sidebar-widget .widget-title {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.shop-sidebar .category-widget .category-list li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}
.shop-sidebar .category-widget .category-list li:last-child {
  margin-bottom: 0px;
}
.shop-sidebar .category-widget .category-list li a {
  position: relative;
  display: inline-block;
  color: #646578;
  font-weight: 400;
  padding-left: 20px;
}
.shop-sidebar .category-widget .category-list li a:before {
  position: absolute;
  content: "\f0da";
  font-family: "Font Awesome 6 Pro";
  left: 0px;
  top: 0px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color2);
}
.shop-sidebar .category-widget .category-list li a:hover {
  color: var(--theme-color1);
}
.shop-sidebar .price-filters .widget-title {
  margin-bottom: 28px;
}
.shop-sidebar .post-widget {
  padding-bottom: 9px;
}
.shop-sidebar .post-widget .post {
  position: relative;
  padding-left: 90px;
  padding-bottom: 24px;
  margin-bottom: 23px;
  min-height: 108px;
  border-bottom: 1px solid #e1e1e1;
}
.shop-sidebar .post-widget .post:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}
.shop-sidebar .post-widget .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 7px;
  width: 70px;
  height: 70px;
  border: 1px solid #d0d4dd;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .post-widget .post .post-thumb img {
  width: 100%;
  border-radius: 5px;
}
.shop-sidebar .post-widget .post a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #646578;
  margin-bottom: 7px;
}
.shop-sidebar .post-widget .post .price {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0a267a;
}
.shop-sidebar .post-widget .post:hover .post-thumb {
  border-color: var(--theme-color1);
}
.shop-sidebar .post-widget .post:hover a {
  color: var(--theme-color1);
}

.range-slider {
  position: relative;
}
.range-slider .title {
  line-height: 26px;
  position: relative;
  display: inline-block;
  margin-right: 4px;
}
.range-slider .title:before {
  position: absolute;
  content: "$";
  left: -5px;
  top: -19px;
  color: #646578;
  font-size: 18px;
}
.range-slider p {
  position: relative;
  display: inline-block;
  color: #646578;
  margin-right: 10px !important;
}
.range-slider .input {
  color: #646578;
  max-width: 75px;
  font-size: 18px;
  margin-top: 5px;
  position: relative;
  display: inline-block;
}
.range-slider .input input {
  background: none;
  color: #646578;
  font-size: 15px;
  text-align: left;
}
.range-slider .ui-widget.ui-widget-content {
  height: 4px;
  border: none;
  margin-bottom: 14px;
  background-color: #d0d4dd;
  border-radius: 2px;
}
.range-slider .ui-slider .ui-slider-range {
  top: 0px;
  height: 4px;
  background-color: var(--theme-color1);
}
.range-slider .ui-state-default {
  top: -5px;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0px;
  background-color: var(--theme-color1);
}
.range-slider .ui-state-default:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 6px;
  height: 6px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}
.range-slider .ui-widget-content .ui-state-default {
  top: -5px;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0px;
  background-color: var(--theme-color1);
}
.range-slider .ui-widget-content .ui-state-default:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 6px;
  height: 6px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}
.range-slider input[type=submit] {
  position: relative;
  display: block;
  background: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  float: right;
  text-align: center;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  text-transform: capitalize;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.range-slider input[type=submit]:hover {
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}

/*** 

====================================================================
Page Title
====================================================================

***/
/* @-webkit-keyframes "ripple" {
  70% {
    -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes "ripple" {
    
  70% {
    -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
} */
.page-title {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 220px 0 110px;
  min-height: 250px;
}
@media only screen and (max-width: 768px) {
  .page-title {
    padding: 110px 0 110px;
  }
}
.page-title:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #131313;
  opacity: 0.6;
  content: "";
}
.page-title .title {
  font-size: 64px;
  color: #ffffff;
  margin-bottom: 17px;
}
.page-title .text {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.05em;
  max-width: 520px;
}

.page-breadcrumb {
  position: relative;
  margin-top: 5px;
}
.page-breadcrumb li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding-right: 13px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.page-breadcrumb li:after {
  position: absolute;
  content: "\f105";
  right: -6px;
  top: 1px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  font-family: "Font Awesome 6 Pro";
  color: #ffffff;
}
.page-breadcrumb li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}
.page-breadcrumb li:last-child::after {
  display: none;
}
.page-breadcrumb li a {
  color: var(--theme-color1);
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.page-breadcrumb li a:hover {
  color: #ffffff;
}

.page-title.style-two {
  background-position: center center;
}
.page-title.style-two .page-breadcrumb-outer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 8;
}
.page-title.style-two .page-breadcrumb li {
  color: rgb(7, 7, 16);
  font-weight: 600;
}
.page-title.style-two .page-breadcrumb li:after {
  color: rgb(7, 7, 16);
}
.page-title.style-two .page-breadcrumb li a {
  color: rgba(7, 7, 16, 0.6);
}
.page-title.style-two .page-breadcrumb li a:hover {
  color: rgb(7, 7, 16);
}

.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.play-now .icon {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background-color: #ffffff;
  color: #ff6d2e;
  z-index: 1;
  padding-left: 0px;
  font-size: 14px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -ms-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -o-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
  transform-origin: center;
}
.play-now .ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}
.play-now .ripple:before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.background-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

.error-page__inner {
  position: relative;
  display: block;
  text-align: center;
}
.error-page__inner .error-page__title-box {
  position: relative;
  display: block;
}
.error-page__inner .error-page__title {
  position: relative;
  display: inline-block;
  font-size: 280px;
  line-height: 280px;
  margin-bottom: 0;
  color: var(--theme-color1);
}
.error-page__inner .error-page__sub-title {
  font-size: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 50px;
  margin-top: -16px;
}
.error-page__inner .error-page__text {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.error-page__inner .error-page__form {
  position: relative;
  display: block;
  margin: 42px auto 20px;
}
.error-page__inner .error-page__form input[type=search] {
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #333;
  padding-left: 50px;
  padding-right: 75px;
  border-radius: 7px;
}
.error-page__inner .error-page__form button[type=submit] {
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.error-page__inner .error-page__form-input {
  position: relative;
  display: block;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
}

/***
=============================================
    Services Details
=============================================
***/
.service-sidebar {
  position: relative;
  display: block;
  max-width: 365px;
  width: 100%;
}
.service-sidebar .service-sidebar-single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.service-sidebar .service-sidebar-single-services {
  position: relative;
  display: block;
  background: #f6f4ec;
  border-radius: 10px;
  padding: 35px 30px 25px;
}
.service-sidebar .service-sidebar-single-services .title {
  position: relative;
  display: block;
  margin-bottom: 12px;
  padding-left: 20px;
}
.service-sidebar .service-sidebar-single-services .title h3 {
  color: var(--headings-color);
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
}
.service-sidebar .service-sidebar-single-services ul {
  position: relative;
  display: block;
  margin-top: 10px;
}
.service-sidebar .service-sidebar-single-services ul li {
  position: relative;
  display: block;
  margin-bottom: 5px;
  margin-top: -10px;
}
.service-sidebar .service-sidebar-single-services ul li:last-child {
  margin-bottom: 0;
}
.service-sidebar .service-sidebar-single-services ul li a {
  position: relative;
  display: block;
  color: var(--agriox-color-1, #687469);
  font-size: 18px;
  padding: 22px 20px 22px;
  border-radius: 10px;
  background: transparent;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.service-sidebar .service-sidebar-single-services ul li a:hover {
  color: var(--headings-color);
}
.service-sidebar .service-sidebar-single-services ul li a:hover::before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
          transform: perspective(400px) rotateX(0deg);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.service-sidebar .service-sidebar-single-services ul li a:hover i {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-services ul li a::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(90deg);
          transform: perspective(400px) rotateX(90deg);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  background: #ffffff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.service-sidebar .service-sidebar-single-services ul li a i {
  font-size: 16px;
}
.service-sidebar .service-sidebar-single-services ul li.current a::before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
          transform: perspective(400px) rotateX(0deg);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.service-sidebar .service-sidebar-single-services ul li.current a i {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-services ul li.current:first-child {
  margin-top: 20px;
}
.service-sidebar .service-sidebar-single-services ul li.current:last-child {
  margin-bottom: 35px;
}
.service-sidebar .service-sidebar-single-contact-box {
  position: relative;
  display: block;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 50px 0px 44px;
  z-index: 1;
}
.service-sidebar .service-sidebar-single-contact-box::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(109, 140, 84, 0.93);
  border-radius: 10px;
  content: "";
  z-index: -1;
}
.service-sidebar .service-sidebar-single-contact-box .icon {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
  background: var(--theme-color2);
}
.service-sidebar .service-sidebar-single-contact-box .icon:hover {
  background-color: var(--headings-color);
}
.service-sidebar .service-sidebar-single-contact-box .icon:hover span::before {
  color: #fff;
}
.service-sidebar .service-sidebar-single-contact-box .icon span::before {
  position: relative;
  display: inline-block;
  color: var(--headings-color);
  font-size: 30px;
  line-height: 60px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-sidebar .service-sidebar-single-contact-box .title {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 42px;
}
.service-sidebar .service-sidebar-single-contact-box .title h2 {
  color: #ffffff;
  font-size: 36px;
}
.service-sidebar .service-sidebar-single-contact-box .phone {
  font-size: 24px;
  line-height: 34px;
}
.service-sidebar .service-sidebar-single-contact-box .phone a {
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-sidebar .service-sidebar-single-contact-box .phone a:hover {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-contact-box p {
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
}
.service-sidebar .service-sidebar-single-btn {
  position: relative;
  display: block;
}
.service-sidebar .service-sidebar-single-btn .thm-btn {
  font-size: 16px;
  padding: 13px 50px 28px;
}
.service-sidebar .service-sidebar-single-btn .thm-btn span::before {
  position: relative;
  display: inline-block;
  top: 13px;
  color: #334b35;
  font-size: 40px;
  padding-right: 25px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  font-weight: 500;
}
.service-sidebar .service-sidebar-single-btn .thm-btn:hover span::before {
  color: #ffffff;
}
.service-sidebar .banner-widget {
  position: relative;
  display: block;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}
.service-sidebar .banner-widget .widget-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 45px 30px 40px 30px;
  background-size: cover;
  background-repeat: no-repeat;
}
.service-sidebar .banner-widget .widget-content .shape {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 278px;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-sidebar .banner-widget .content-box {
  position: relative;
  max-width: 200px;
  width: 100%;
}
.service-sidebar .banner-widget .content-box .icon-box {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 40px;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  text-align: center;
  border-radius: 5px;
  margin-bottom: 23px;
}
.service-sidebar .banner-widget .content-box .icon-box .icon-shape {
  position: absolute;
  top: -15px;
  right: -38px;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
}
.service-sidebar .banner-widget .content-box h3 {
  display: block;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 22px;
}
.service-sidebar .banner-widget .content-box .theme-btn-two:hover {
  background: #0a267a;
}
.service-sidebar .service-sidebar-single-btn .theme-btn {
  padding: 20px 50px;
}
.service-sidebar .service-sidebar-single-btn .theme-btn .btn-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.service-sidebar .service-sidebar-single-btn .theme-btn span::before {
  position: relative;
  display: inline-block;
  font-size: 36px;
  padding-right: 25px;
  margin-top: 7px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  font-weight: 500;
}

.services-details__content .feature-list .single-item {
  position: relative;
  display: block;
  border: 1px solid #e1e8e4;
  padding: 16px 30px 16px 53px;
  margin-bottom: 20px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.services-details__content .feature-list .single-item .icon-box {
  color: var(--theme-color1);
  position: absolute;
  left: 20px;
  top: 16px;
  font-size: 18px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.services-details__content .feature-list .single-item .title {
  display: block;
  margin: 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
}
.services-details__content .feature-list .single-item:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.services-details__content .feature-list .single-item:hover .icon-box {
  color: var(--text-color-bg-theme-color2);
}

.service-list li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}
.service-list li:last-child {
  margin-bottom: 0px;
}
.service-list li a {
  position: relative;
  display: block;
  font-size: 18px;
  color: var(--headings-color);
  font-weight: 600;
  background-color: #fff;
  padding: 17px 20px 17px 50px;
  -webkit-box-shadow: 20px 5px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 20px 5px 20px 0px rgba(0, 0, 0, 0.05);
}
.service-list li a:hover, .service-list li a.current {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
  padding-left: 80px;
}
.service-list li a:hover i, .service-list li a.current i {
  width: 60px;
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}
.service-list li i {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 100%;
  background-color: #f6f4ec;
  text-align: center;
  font-size: 16px;
  color: #707582;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar-service-list {
  margin: 0;
}
.sidebar-service-list li + li {
  margin-top: 10px;
}
.sidebar-service-list li a {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background-color: #f5faff;
  border-radius: 15px;
  padding: 19px 40px;
}
.sidebar-service-list li a:hover {
  color: var(--theme-color2);
}
.sidebar-service-list li a:hover i {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
  background-color: var(--theme-color2);
}
.sidebar-service-list li a i {
  height: 32px;
  width: 45px;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #191825;
  background-color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
  border-radius: 15px;
}
.sidebar-service-list li.current a {
  color: var(--theme-color2);
}
.sidebar-service-list li.current a i {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
  background-color: var(--theme-color2);
}

.service-details-help {
  position: relative;
  display: block;
  padding: 57px 55px 50px;
  margin-top: 30px;
  text-align: center;
  z-index: 1;
  background-color: var(--theme-color2);
  border-radius: 15px;
  overflow: hidden;
}

.help-shape-1 {
  position: absolute;
  bottom: -215px;
  left: -95px;
  width: 220px;
  height: 500px;
  background-color: #303030;
  mix-blend-mode: soft-light;
  border-radius: 150px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: -1;
}

.help-shape-2 {
  position: absolute;
  top: -118px;
  right: -130px;
  width: 180px;
  height: 350px;
  background-color: #fff;
  mix-blend-mode: soft-light;
  border-radius: 186px;
  -webkit-transform: rotate(48deg);
          transform: rotate(48deg);
}

.help-icon {
  height: 73px;
  width: 73px;
  background-color: #fff;
  color: #191825;
  font-size: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  margin: 0 auto 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.help-icon:hover {
  background-color: #191825;
  color: #fff;
}

.help-title {
  font-size: 38px;
  color: #fff;
  line-height: 40px;
  margin-bottom: 21px;
  font-weight: 700;
}

.help-contact {
  position: relative;
  display: block;
  margin-top: 21px;
}
.help-contact p {
  font-size: 14px;
  color: #fff;
  margin: 0;
  line-height: 32px;
  font-weight: 600;
  opacity: 0.7;
}
.help-contact a {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.help-contact a:hover {
  color: #191825;
}

/*--------------------------------------------------------------
# Project Details
--------------------------------------------------------------*/
.project-details__img {
  position: relative;
  display: block;
}
.project-details__img img {
  width: 100%;
  border-radius: 10px;
}

.project-details__content {
  position: relative;
  display: block;
}

.project-details__content-left {
  position: relative;
  display: block;
  margin-top: 31px;
}

.project-details__content-right {
  position: relative;
  display: block;
  margin-top: 40px;
}

.project-details__details-box {
  position: relative;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 43px 50px 50px;
  z-index: 1;
}

.project-details__details-list {
  position: relative;
  display: block;
}
.project-details__details-list li {
  position: relative;
  display: block;
}
.project-details__details-list li + li {
  margin-top: 24px;
}

.project-details__client {
  font-size: 16px;
  color: #838d9e;
  line-height: 24px;
  margin: 0;
}

.project-details__name {
  font-size: 16px;
  line-height: 24px;
}

.project-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.project-details__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.project-details__social a:hover {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
}
.project-details__social a + a {
  margin-left: 10px;
}

.project-details__pagination-box {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid #ece9e0;
  border-bottom: 1px solid #ece9e0;
  padding: 30px 0;
  margin-top: 117px;
}

.project-details__pagination {
  position: relative;
  display: block;
}
.project-details__pagination li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-details__pagination li a {
  font-size: 14px;
  color: #757873;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.project-details__pagination li a:hover {
  color: var(--theme-color1);
}
.project-details__pagination li a:hover i {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  border: 2px solid var(--theme-color2);
}
.project-details__pagination li .content {
  position: relative;
  display: block;
}
.project-details__pagination li.next {
  float: left;
  position: relative;
}
.project-details__pagination li.next i {
  position: relative;
  height: 52px;
  width: 52px;
  border: 2px solid var(--theme-color2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--theme-color2);
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-right: 20px;
  z-index: 1;
}
.project-details__pagination li.next .content {
  text-align: left;
}
.project-details__pagination li.previous {
  position: relative;
  float: right;
}
.project-details__pagination li.previous i {
  position: relative;
  height: 52px;
  width: 52px;
  border: 2px solid var(--theme-color2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--theme-color2);
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 20px;
  z-index: 1;
}
.project-details__pagination li.previous .content {
  text-align: right;
}

/***

====================================================================
    Blog Details
====================================================================

***/
.blog-details {
  position: relative;
  display: block;
}

.blog-details__left {
  position: relative;
  display: block;
}

.blog-details__img {
  position: relative;
  display: block;
  border-radius: 10px;
}
.blog-details__img img {
  width: 100%;
  border-radius: 10px;
}

.blog-details__date {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--theme-color1);
  text-align: center;
  padding: 21px 24px 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.blog-details__date .day {
  font-size: 16px;
  color: #fff;
  font-weight: var(--body-font-weight-bold);
  line-height: 16px;
}
.blog-details__date .month {
  position: relative;
  display: block;
  font-size: 10px;
  font-weight: var(--body-font-weight-bold);
  color: #fff;
  line-height: 12px;
  text-transform: uppercase;
}

.blog-details__content {
  position: relative;
  display: block;
  margin-top: 22px;
}

.blog-details__meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.blog-details__meta li + li {
  margin-left: 18px;
}
.blog-details__meta li a {
  font-size: 15px;
  color: #777;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.blog-details__meta li a:hover {
  color: var(--theme-color1);
}
.blog-details__meta li a i {
  color: var(--theme-color1);
  margin-right: 6px;
}

.blog-details__title {
  font-size: 30px;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 21px;
  font-weight: var(--body-font-weight-bold);
}

.blog-details__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 0 30px;
  margin-top: 49px;
  border-top: 1px solid #ece9e0;
}
@media only screen and (max-width: 767px) {
  .blog-details__bottom {
    gap: 30px;
  }
}
.blog-details__bottom p {
  margin: 0;
}

.blog-details__tags span {
  color: #0e2207;
  font-size: 20px;
  margin-right: 14px;
  font-weight: var(--body-font-weight-bold);
}
.blog-details__tags a {
  position: relative;
  font-size: 12px;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  display: inline-block;
  padding: 5px 30px 5px;
  font-weight: var(--body-font-weight-bold);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 35px;
}
.blog-details__tags a:hover {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  text-decoration: none;
}
.blog-details__tags a + a {
  margin-left: 6px;
}

.blog-details__social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-details__social-list a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.blog-details__social-list a:hover {
  color: var(--text-color-bg-theme-color2);
}
.blog-details__social-list a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.blog-details__social-list a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--theme-color2);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}
.blog-details__social-list a + a {
  margin-left: 10px;
}

.blog-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 53px;
}

.blog-details__pagenation {
  position: relative;
  display: block;
}
.blog-details__pagenation li {
  position: relative;
  float: left;
  font-size: 20px;
  color: #0e2207;
  font-weight: var(--body-font-weight-bold);
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  line-height: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 370px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 52px;
  padding-bottom: 52px;
  border-radius: 10px;
}
.blog-details__pagenation li:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.blog-details__pagenation li + li {
  margin-left: 30px;
}

/* Nav Links */
.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 53px;
}
@media only screen and (max-width: 767px) {
  .nav-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
  }
}
.nav-links .prev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: calc(50% - 15px);
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .nav-links .prev {
    width: 100%;
  }
}
.nav-links .prev .thumb {
  margin-right: 20px;
}
.nav-links .next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  width: calc(50% - 15px);
}
@media only screen and (max-width: 767px) {
  .nav-links .next {
    width: 100%;
  }
}
.nav-links .next .thumb {
  margin-left: 20px;
}
.nav-links > div {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.nav-links > div .thumb {
  display: inline-block;
  min-width: 60px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}
.nav-links > div .thumb a {
  display: inline-block;
}
.nav-links > div > a {
  display: inline-block;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  font-size: 20px;
  line-height: 1.637;
  font-weight: var(--body-font-weight-bold);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 52px 50px;
  border-radius: 10px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .nav-links > div > a {
    padding: 30px;
  }
}
.nav-links > div > a:hover {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}

/*** 

====================================================================
Sidebar
====================================================================

***/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}
.sidebar__single + .sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: var(--h4-font-weight);
}

.sidebar__search {
  position: relative;
  display: block;
}

.sidebar__search-form {
  position: relative;
}
.sidebar__search-form input[type=search] {
  display: block;
  border: none;
  outline: none;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  font-size: 16px;
  font-weight: 500;
  padding-left: 50px;
  height: 74px;
  width: 100%;
  padding-right: 80px;
  border-radius: 10px;
}
.sidebar__search-form input[type=search]::-webkit-input-placeholder {
  color: var(--text-color-bg-theme-color2);
  opacity: 1;
}
.sidebar__search-form input[type=search]::-webkit-input-placeholder, .sidebar__search-form input[type=search]:-ms-input-placeholder, .sidebar__search-form input[type=search]::-ms-input-placeholder, .sidebar__search-form input[type=search]::placeholder {
  color: var(--text-color-bg-theme-color2);
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__search-form input[type=search] {
    padding-left: 30px;
  }
}
.sidebar__search-form button[type=submit] {
  background-color: transparent;
  color: var(--text-color-bg-theme-color2);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .sidebar__search-form button[type=submit] {
    width: 42px;
  }
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 46px 30px 30px;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__post {
    padding: 30px;
  }
}
.sidebar__post .sidebar__title {
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .sidebar__post .sidebar__title {
    margin-left: 0;
  }
}
.sidebar__post .sidebar__post-list {
  margin: 0;
}
.sidebar__post .sidebar__post-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px 17px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
@media only screen and (max-width: 767px) {
  .sidebar__post .sidebar__post-list li {
    padding: 16px 0;
  }
}
.sidebar__post .sidebar__post-list li:hover {
  background-color: #ffffff;
  border-radius: 10px;
}
.sidebar__post .sidebar__post-list li + li {
  margin-top: 11px;
}
.sidebar__post .sidebar__post-list .sidebar__post-image {
  margin-right: 20px;
  -webkit-box-flex: 70px;
      -ms-flex: 70px 0 0px;
          flex: 70px 0 0;
}
.sidebar__post .sidebar__post-list .sidebar__post-image > img {
  width: 80px;
  border-radius: 10px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content {
  position: relative;
  top: -3px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content h3 {
  font-size: 18px;
  margin: 0;
  line-height: 26px;
  letter-spacing: 0;
}
.sidebar__post .sidebar__post-list .sidebar__post-content h3 a {
  color: #0e2207;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content-meta {
  font-size: 14px;
  font-weight: 500;
  color: #757873 !important;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.sidebar__post .sidebar__post-list .sidebar__post-content-meta i {
  color: var(--theme-color2);
  font-size: 14px;
  padding-right: 3px;
}

.sidebar__category {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  border-radius: 10px;
  padding: 45px 30px 38px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__category {
    padding: 30px 15px 30px;
  }
}
.sidebar__category .sidebar__title {
  padding-left: 20px;
  margin-bottom: 9px;
}
.sidebar__category-list {
  margin: 0;
}
.sidebar__category-list li + li {
  margin-top: 4px;
}
.sidebar__category-list li a {
  color: #757873;
  font-size: 16px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 12px 20px;
  font-weight: 500;
  border-radius: 10px;
}
.sidebar__category-list li a:hover {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: #0e2207;
  text-shadow: 1px 0 0 rgba(14, 34, 7, 0.5);
}
.sidebar__category-list li a:hover span {
  color: #ffcd1e;
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
.sidebar__category-list li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--theme-color2);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
}
.sidebar__category-list li.active a {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: #0e2207;
  border-radius: 10px;
  text-shadow: 1px 0 0 rgba(14, 34, 7, 0.5);
}
.sidebar__category-list li.active a span {
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  color: #ffcd1e;
}

.sidebar__tags {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 46px 45px 50px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__tags {
    padding: 30px;
  }
}
.sidebar__tags .sidebar__title {
  margin-left: 5px;
  margin-bottom: 25px;
}

.sidebar__tags-list {
  margin-top: -10px;
}
.sidebar__tags-list a {
  font-size: 14px;
  color: #0e2207;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #ffffff;
  display: inline-block;
  padding: 5px 28px 5px;
  margin-left: 5px;
  border-radius: 30px;
}
.sidebar__tags-list a:hover {
  color: var(--text-color-bg-theme-color1);
  background: var(--theme-color1);
}
.sidebar__tags-list a + a {
  margin-left: 5px;
  margin-top: 10px;
}

.sidebar__comments {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 46px 50px 43px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .sidebar__comments {
    padding: 30px;
  }
}
.sidebar__comments .sidebar__title {
  margin-bottom: 25px;
}

.sidebar__comments-list {
  position: relative;
  display: block;
}
.sidebar__comments-list li {
  position: relative;
  display: block;
  padding-left: 65px;
}
.sidebar__comments-list li:hover .sidebar__comments-icon {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.sidebar__comments-list li + li {
  margin-top: 23px;
}

.sidebar__comments-icon {
  height: 45px;
  width: 45px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  font-size: 15px;
  color: var(--text-color-bg-theme-color1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar__comments-text-box p {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  font-weight: 500;
}
.sidebar__comments-text-box p span {
  color: #0e2207;
}
.sidebar__comments-text-box h5 {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  color: #757873;
  font-weight: 500;
  letter-spacing: 0;
}

/*** 

====================================================================
    Comments
====================================================================

***/
.comment-one .comment-one__title {
  margin-bottom: 30px;
}
.comment-one .comment-one__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ece9e0;
  padding-bottom: 60px;
  margin-bottom: 60px;
  -webkit-box-align: top;
      -ms-flex-align: top;
          align-items: top;
}
@media only screen and (max-width: 767px) {
  .comment-one .comment-one__single {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.comment-one .comment-one__content {
  position: relative;
  margin-left: 45px;
}
@media only screen and (max-width: 767px) {
  .comment-one .comment-one__content {
    margin-top: 20px;
    margin-left: 0;
  }
}
.comment-one .comment-one__content h3 {
  margin: 0;
  font-size: 20px;
  color: var(--theme-black);
  margin-bottom: 24px;
}
.comment-one .comment-one__content p {
  font-size: 16px;
  font-weight: 500;
}
.comment-one .comment-one__btn {
  padding: 5px 30px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
}
.comment-one .comment-one__image {
  position: relative;
  display: block;
  border-radius: 50%;
  -webkit-box-flex: 100px;
      -ms-flex: 100px 0 0px;
          flex: 100px 0 0;
}
.comment-one .comment-one__image img {
  border-radius: 50%;
}

.comment-form .comment-form__title {
  margin-top: -7px;
}

/*--------------------------------------------------------------
# Team Details
--------------------------------------------------------------*/
.team-details {
  position: relative;
  display: block;
}

.team-details__top {
  padding: 0 0 120px;
}

.team-details-shape-1 {
  position: absolute;
  bottom: -270px;
  right: 0;
  opacity: 0.5;
  z-index: 2;
}
.team-details-shape-1 img {
  width: auto;
}

.team-details__top-left {
  position: relative;
  display: block;
  margin-right: 20px;
}

.team-details__top-img {
  position: relative;
  display: block;
  border-radius: 30px;
}
.team-details__top-img img {
  width: 100%;
  border-radius: 30px;
}

.team-details__big-text {
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  color: #eef0f6;
  letter-spacing: 0.35em;
  font-weight: 400;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 209px;
  left: -325px;
}

.team-details__top-right {
  position: relative;
  display: block;
  margin-left: 50px;
}
@media only screen and (max-width: 991px) {
  .team-details__top-right {
    margin-top: 70px;
    margin-left: 0;
  }
}

.team-details__top-content {
  position: relative;
  display: block;
  margin-top: -11px;
}

.team-details__top-name {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 3px;
}

.team-details__top-title {
  font-size: 16px;
  color: var(--theme-color2);
}

.team-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 28px;
}
.team-details__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  font-size: 15px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.team-details__social a:hover {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
}
.team-details__social a + a {
  margin-left: 10px;
}

.team-details__top-text-1 {
  font-size: 30px;
  color: var(--theme-color1);
  line-height: 45px;
  font-weight: 400;
  margin-bottom: 30px;
}

.team-details__top-text-2 {
  padding-top: 23px;
  padding-bottom: 35px;
}

.team-details__bottom {
  position: relative;
  display: block;
  border-top: 1px solid #e4e5ea;
  padding-top: 110px;
}

.team-details__bottom-left {
  position: relative;
  display: block;
  margin-right: 70px;
}

.team-details__bottom-left-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
}

.team-details__bottom-left-text {
  padding-top: 30px;
}

.team-details__bottom-right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 1px;
}
@media only screen and (max-width: 991px) {
  .team-details__bottom-right {
    margin-left: 0;
  }
}

.team-details__progress {
  position: relative;
  display: block;
  width: 100%;
}
.team-details__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: #eef0f6;
  border-radius: 7px;
  margin-bottom: 22px;
}
.team-details__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 7px;
  background-color: var(--theme-color2);
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}
.team-details__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 21px;
  color: var(--theme-color1);
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.team-details__progress .bar-inner.counted .count-text {
  opacity: 1;
}
.team-details__progress .bar.marb-0 {
  margin-bottom: 0;
}

.team-details__progress-single {
  position: relative;
  display: block;
}

.team-details__progress-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: var(--theme-color1);
  margin-bottom: 6px;
}

.team-contact-form {
  background-color: #eef0f6;
}
.team-contact-form input[type=text],
.team-contact-form input[type=email] {
  background-color: #fff;
}
.team-contact-form textarea {
  background-color: #fff;
  height: 180px;
}

/***

====================================================================
        Contact
====================================================================

***/
.contact-details__info {
  position: relative;
  display: block;
  margin-top: 41px;
}
.contact-details__info li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-details__info li .icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: var(--theme-color2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li .icon span {
  color: var(--text-color-bg-theme-color2);
  font-size: 25px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li:hover .icon {
  background-color: var(--theme-color1);
}
.contact-details__info li:hover .icon span {
  color: var(--text-color-bg-theme-color1);
}
.contact-details__info li .text {
  margin-left: 30px;
}
.contact-details__info li .text p {
  font-size: 14px;
  line-height: 24px;
}
.contact-details__info li .text a {
  font-size: 18px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.contact-details__info li .text span {
  font-size: 20px;
}
.contact-details__info li + li {
  margin-top: 30px;
}

/***

==================================================================
	Main Footer
==================================================================

***/
.main-footer {
  position: relative;
  background-color: var(--theme-color2);
  /* Widget Section */
}
.main-footer .footer-upper {
  position: relative;
  padding: 30px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.main-footer .widgets-section {
  position: relative;
  padding: 100px 0 40px;
}
.main-footer .footer-column {
  position: relative;
  margin-bottom: 50px;
}
.main-footer .footer-widget {
  position: relative;
}
.main-footer .widget-title {
  position: relative;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.2em;
  margin-bottom: 27px;
}

.contact-info-block {
  position: relative;
  margin-bottom: 30px;
}
.contact-info-block .inner {
  position: relative;
  padding-left: 80px;
}
@media (max-width: 575.98px) {
  .contact-info-block .inner {
    padding-left: 0;
    text-align: center;
  }
}
.contact-info-block .inner .icon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 48px;
  color: var(--theme-color1);
}
@media (max-width: 575.98px) {
  .contact-info-block .inner .icon {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }
}
.contact-info-block .inner .text {
  font-size: 15px;
  color: #848484;
  line-height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media (max-width: 575.98px) {
  .contact-info-block .inner .text {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.contact-info-block .inner .text a {
  color: #848484;
}
.contact-info-block .inner .text a:hover {
  color: var(--theme-color-light);
}

.main-footer .about-widget {
  position: relative;
}
.main-footer .about-widget .logo {
  margin-bottom: 25px;
}
.main-footer .about-widget .text {
  font-weight: 500;
  color: #848484;
  margin-bottom: 30px;
}
.main-footer .about-widget .contact-info {
  position: relative;
}
.main-footer .about-widget .contact-info li {
  position: relative;
  padding: 5px 0;
  padding-left: 50px;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-footer .about-widget .contact-info li a {
  color: inherit;
  font-weight: 700;
  -webkit-transition: none;
  transition: none;
}
.main-footer .about-widget .contact-info li i {
  position: absolute;
  left: 0;
  top: 0;
  height: 37px;
  width: 37px;
  background-color: #182838;
  color: var(--theme-color2);
  font-size: 16px;
  line-height: 37px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 50%;
}
.main-footer .about-widget .contact-info li:hover {
  color: var(--theme-color2);
}

/*=== User LInks ===*/
.user-links {
  position: relative;
}
.user-links li {
  position: relative;
  font-size: 15px;
  line-height: 24px;
  color: #8f8f8f;
  font-weight: 400;
  margin-bottom: 10px;
}
.user-links li:last-child {
  margin-bottom: 0;
}
.user-links li a {
  position: relative;
  display: inline-block;
  color: inherit;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.user-links li a:hover {
  color: #FFFFFF;
}
.user-links li a:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.user-links li a:hover:before {
  width: 100%;
}

/* Gallery Widget */
.gallery-widget {
  position: relative;
}
.gallery-widget .outer {
  position: relative;
  padding-top: 5px;
  margin: 0px -7px 0;
}
.gallery-widget .outer .image {
  position: relative;
  float: left;
  width: 33.3333%;
  padding: 0px 7px;
  margin-bottom: 14px;
}
.gallery-widget .image img {
  display: block;
  width: 100%;
}
.gallery-widget .image a {
  position: relative;
  display: block;
  overflow: hidden;
}
.gallery-widget .image a:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--bg-theme-color1) padding-box content-box;
  opacity: 0.8;
  content: "";
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.gallery-widget .image a:hover:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.gallery-widget .image a:hover:after {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.gallery-widget .image a:after {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
  content: "\f0c1";
  text-align: center;
  line-height: 20px;
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 900;
  font-family: "Font Awesome 6 Pro";
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.newsletter-widget {
  position: relative;
  padding-left: 70px;
}
@media (max-width: 1199.98px) {
  .newsletter-widget {
    padding-left: 20px;
  }
}
@media (max-width: 991.98px) {
  .newsletter-widget {
    padding-left: 0;
  }
}
.newsletter-widget .text {
  color: #8f8f8f;
  margin-bottom: 20px;
}

/* Subscribe Form */
.subscribe-form {
  position: relative;
}
.subscribe-form .form-group {
  position: relative;
  margin-bottom: 0;
}
.subscribe-form .form-group input[type=text],
.subscribe-form .form-group input[type=email] {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  color: #717070;
  padding: 20px 30px;
  padding-right: 60px;
  background: #fff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.subscribe-form .form-group input[type=text]::-webkit-input-placeholder, .subscribe-form .form-group input[type=email]::-webkit-input-placeholder {
  color: #717070;
}
.subscribe-form .form-group input[type=text]::-moz-placeholder, .subscribe-form .form-group input[type=email]::-moz-placeholder {
  color: #717070;
}
.subscribe-form .form-group input[type=text]:-ms-input-placeholder, .subscribe-form .form-group input[type=email]:-ms-input-placeholder {
  color: #717070;
}
.subscribe-form .form-group input[type=text]::-ms-input-placeholder, .subscribe-form .form-group input[type=email]::-ms-input-placeholder {
  color: #717070;
}
.subscribe-form .form-group input[type=text]::placeholder,
.subscribe-form .form-group input[type=email]::placeholder {
  color: #717070;
}
.subscribe-form .form-group .theme-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -20px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 14px;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  text-align: center;
  background: var(--bg-theme-color1);
}
.subscribe-form .form-group .theme-btn:hover {
  color: #FFFFFF;
}

/*=== Footer Bottom ===*/
.footer-bottom {
  position: relative;
  z-index: 3;
  background-color: #0f0f0f;
  padding: 35px 0;
}
.footer-bottom .copyright-text {
  position: relative;
  font-size: 15px;
  line-height: 24px;
  padding: 13px 15px;
  font-weight: 500;
  color: #848484;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .footer-bottom .copyright-text {
    text-align: center;
    padding-bottom: 0;
  }
}
.footer-bottom .copyright-text a {
  color: inherit;
}
.footer-bottom .copyright-text a:hover {
  color: #ffffff;
}
@media (max-width: 767.98px) {
  .footer-bottom .links-column,
.footer-bottom .logo-column {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
}
.footer-bottom .footer-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 50px;
}
.footer-bottom .footer-links {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 13px 0;
}
@media (max-width: 767.98px) {
  .footer-bottom .footer-links {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.footer-bottom .footer-links li {
  position: relative;
  font-size: 15px;
  line-height: 24px;
  color: #848484;
  padding-left: 17px;
  margin-left: 17px;
  font-weight: 500;
}
.footer-bottom .footer-links li:before {
  position: absolute;
  left: -3px;
  top: 0;
  font-weight: 500;
  content: "/";
}
.footer-bottom .footer-links li:first-child {
  padding-left: 0;
  margin-left: 0;
}
.footer-bottom .footer-links li:first-child:before {
  display: none;
}
.footer-bottom .footer-links li a {
  position: relative;
  display: inline-block;
  color: inherit;
  font-weight: 500;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.footer-bottom .footer-links li a:hover {
  color: #FFFFFF;
}