
.row13 {
    --mdb-gutter-x: 1.5rem;
    --mdb-gutter-y: 0;
    display: flex;
    max-width: 1200px;
    flex-wrap: wrap;
    margin-top: calc(var(--mdb-gutter-y)*-1);
    margin-right: calc(var(--mdb-gutter-x)*-0.5);
    margin-left: calc(var(--mdb-gutter-x)*-0.5);
}

.fpItem {
    cursor: pointer;
    box-shadow: -1px 0px 3px -1px rgba(103, 71, 54, 0.4);
    -webkit-box-shadow: -1px 0px 3px -1px rgba(103, 71, 54, 0.4);
    -moz-box-shadow: -1px 0px 3px -1px rgba(103, 71, 54, 0.4);
    flex: 1;
    gap: 5px;
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    border-radius: 5px;
    box-shadow: 0px 0px 28px 0px rgba(211, 211, 211, 0.5);
  }
  
  .fpItem:hover {
    transition: all ease-in 0.5s;
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.4);
  }
  
  .fpImg {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .fpName {
    color: #333;
    font-weight: bold;
  }
  
  .fpCity {
    font-weight: 300;
  }
  
  .fpPrice {
    font-weight: 500;
  }
  
  .fpRating > button {
    background-color: rgba(103, 71, 54);
    color: white;
    border: none;
    padding: 2px 8px;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .fpRating > span {
    font-size: 14px;
  }
  
  .res .homeTitle {
    font-size: 28px;
  }
  .section-decor {
    position: absolute;
    left: 0;
    bottom: 150px;
    width: 100%;
    height: 150px;
    z-index: -1;
    background: url(../../assets/city7.png) repeat;
    opacity: 0.7;
}